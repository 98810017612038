import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

interface Props {
  openModal: boolean;
  onClose: () => void;
}
export default function ShareSuccessModal(props: Props) {
  return (
    <Dialog fullWidth open={props.openModal} onClose={props.onClose}>
      <DialogTitle className="riverus-dialog-header">
        <div className="row">
          <div className="col-md-11">Delete Draft</div>
          <div onClick={props.onClose} className="col-md-1 close-icn">
            <img
              src="/static_images/close-analysismodal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-12">
            <p>Draft has been shared to the users on there specific mail</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
