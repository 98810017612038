import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useRef, useState } from "react";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";

import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import LinesLoader from "../../UniversalComponents/Loader/linesLoader";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

interface Props {
  fileId: string;
  selectedMenu: {
    selectedContractMenu: string;
    contract_id: string;
  };
}
const DocumentPdftronView = (props: Props) => {
  
  const documentContainer = useRef<HTMLDivElement>(null);

  const [fileLink, setFileLink] = useState("");
  const [contract_id, setcontract_id] = useState(
    props.selectedMenu.contract_id
  );

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    var url = SITEAPI + "download/" + props.selectedMenu.contract_id + "/";

    AxiosGateWay.get(url)
      .then((result) => {
        setFileLink(result.data.location);
        if (documentContainer.current != null) {
          WebViewer(
            {
              path: "/webviewer",
              disabledElements: ["header", "toolsHeader"],
            },
            documentContainer.current
          ).then((instance) => {
            // documentContainer.current?.remove();
            const { UI, Core } = instance;
            const { documentViewer, annotationManager, Tools, Annotations } =
              Core;
            // call methods from UI, Core, documentViewer and annotationManager as needed
            documentViewer.addEventListener("documentLoaded", () => {
              setLoader(false);
              // call methods relating to the loaded document
            });

            instance.UI.loadDocument(result.data.location);
          });
        }
      })
      .catch((error) => {});
  }, [props.selectedMenu.contract_id]);
  return (
    <>
      {loader ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <LinesLoader
                  animatedLines={[
                    {
                      width: 100,
                      height: 42,
                      marginTop: "5px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div
              ref={documentContainer}
              className="col-md-12  "
              id="viewer"
              style={{ height: "1000px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentPdftronView;
