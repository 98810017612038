import * as React from "react";
import Task from "./task";
import Reminder from "./reminder";
import Link from "./link";
import axios from "axios";
import {
  TaskData,
  UserData,
  TaskState,
  CurrentTask,
  BIType,
  BISentence,
  ClauseType,
  TaskEdit,
  CheckList,
  Comments,
} from "../State/taskManagementState";
import { InsightsInterface } from "../../../../DocumentView/State/documentState";
import { isNullOrUndefined } from "is-what";
import {
  reminderFrequencyType,
  reminderFrequencyTypeKey,
} from "../../../../Constants/const";
import { AllTasksData } from "../../../../TaskManagement/State/taskManagementPageState";
import Notes from "./notes";
import Checklist from "./checkList";
import CommentSection from "./commentSection";
import { Accordion } from "react-bootstrap";
import { SITE_API_BY_REALM_NAME } from "../../../../Configuration/global";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../../../Authentication/Actions/authentication";

import { connect } from "react-redux";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

interface Props {
  userData: UserData[];
  taskData: TaskData;
  taskState: TaskState[];
  taskProgress: TaskState[];
  insightsData: InsightsInterface[];
  currentTask: CurrentTask;
  biType: BIType[];
  biSentence: BISentence;
  fileID: number;
  getUserData: () => void;
  getTaskState: () => void;
  getTaskProgress: () => void;
  getBIType: (clauseType: string) => void;
  getBISentence: (fileiID: number, biColumnName: string) => void;
  createTask: (taskData: TaskData) => void;
  clauseType: ClauseType[];
  editMode?: boolean;
  currentEditTaskData?: AllTasksData;
  updateTask: (taskData: TaskEdit) => void;
  biTypeLoader: boolean;
  biTextLoader: boolean;
  origin?: string; // insights or infobar
  comments: Comments[];
  postComment: (requestid: string, comment: string) => void;
  getComments: (requestId: string) => void;
  commentLoader: boolean;
  getClauseType: () => void;
}

interface State {
  addedUsers: UserData[];
  taskData: TaskData;
  selectedTaskState: TaskState;
  selectedBITypeValue: SelectedBIType;
  selectedfrequencyType: SelectedBIType;
  requestID: number;
  displayErrorMessage: boolean;
  errorMessage: string;
  absentFields: string[];
  progressData: any;
  progressIDS: any;
  id: string;
  childFileList: any[];
}

interface SelectedBIType {
  value: string;
}

export default class TaskModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      addedUsers: [],
      taskData: {
        checklist: [],
        assignees: [],
        id: "",
        title: "",
        description: "",
        activityGroup: [],
        due_date: "",
        progressID: "1",
        reminder: {
          reminderType: "Before",
          reminderStart: 1,
          reminderStartType: "Days",
          reminderUntil: "",
          frequencyType: "never",
        },
        link: {
          fileID: 0,
          clauseType: "",
          biType: "",
          linkedTableID: 0,
          linkedText: "",
        },
        checkList: [],
      },
      selectedTaskState: {
        id: "1",
        name: "Not Started",
      },
      selectedBITypeValue: {
        value: "",
      },
      selectedfrequencyType: {
        value: "Never",
      },
      requestID: 0,
      displayErrorMessage: false,
      errorMessage: "Please enter all the mandatory fields",
      absentFields: [],
      progressData: {
        NOT_STARTED: "Not Started",
        IN_PROGRESS: "In Progress",
        COMPLETED: "Completed",
      },
      progressIDS: {
        NOT_STARTED: "1",
        IN_PROGRESS: "2",
        COMPLETED: "3",
      },
      id: "",
      childFileList: [],
    };

    this.insertUsers = this.insertUsers.bind(this);
    this.insertTitle = this.insertTitle.bind(this);
    this.insertDueDate = this.insertDueDate.bind(this);
    this.insertStatus = this.insertStatus.bind(this);
    this.insertReminderDuration = this.insertReminderDuration.bind(this);
    this.insertReminderDurationType =
      this.insertReminderDurationType.bind(this);
    this.insertReminderDurationBeforeAfter =
      this.insertReminderDurationBeforeAfter.bind(this);
    this.insertReminderRepeatWhen = this.insertReminderRepeatWhen.bind(this);
    this.insertReminderRepeatUntil = this.insertReminderRepeatUntil.bind(this);
    this.insertClauseType = this.insertClauseType.bind(this);
    this.insertBIType = this.insertBIType.bind(this);
    this.insertLinkedText = this.insertLinkedText.bind(this);
    this.insertDescription = this.insertDescription.bind(this);
    this.insertComment = this.insertComment.bind(this);

    this.insertCheckListItem = this.insertCheckListItem.bind(this);
    // this.updateCheckListItem = this.updateCheckListItem.bind(this);

    this.insertState = this.insertState.bind(this);
    this.createTask = this.createTask.bind(this);
  }

  insertTitle(title: string) {
    let newState = this.state;
    newState.taskData.title = title;
    this.setState(newState);
  }

  insertDueDate(due: string) {
    let newState = this.state;
    newState.taskData.due_date = due;
    this.setState(newState);
  }

  insertStatus(progressID: string) {
    let newState = this.state;
    newState.taskData.progressID = progressID;
    this.setState(newState);
  }

  insertReminderDuration(duration: string) {
    let newState = this.state;
    newState.taskData.reminder.reminderStart = parseInt(duration);
    this.setState(newState);
  }

  insertReminderDurationType(durationType: string) {
    let newState = this.state;
    newState.taskData.reminder.reminderStartType = durationType;
    this.setState(newState);
  }

  insertReminderDurationBeforeAfter(durationBeforeAfter: string) {
    let newState = this.state;
    newState.taskData.reminder.reminderType = durationBeforeAfter;
    this.setState(newState);
  }

  insertReminderRepeatWhen(repeatFrequency: string) {
    let newState = this.state;
    newState.selectedfrequencyType.value = repeatFrequency;
    newState.taskData.reminder.frequencyType =
      reminderFrequencyType[repeatFrequency];
    this.setState(newState);
  }

  insertReminderRepeatUntil(repeatUntil: string) {
    let newState = this.state;
    newState.taskData.reminder.reminderUntil = repeatUntil;
    this.setState(newState);
  }

  insertUsers(userData: UserData) {
    let newState = this.state;
    newState.addedUsers.push(userData);
    newState.taskData.activityGroup.push(userData.id);
    this.setState(newState);
  }

  insertDescription(description: string) {
    let newState = this.state;
    newState.taskData.description = description;
    this.setState(newState);
  }

  insertComment(description: string) {
    // let newState = this.state;
    // newState.taskData.description = description;
    // this.setState(newState);
  }

  removeUser = (userData: UserData) => {
    // let newState = this.state;
    let addedUsersCopy = this.state.addedUsers.map((user: UserData) => user);
    let filteredAD = addedUsersCopy.filter(
      (user: UserData) => userData.id !== user.id
    );

    let activityGroupCopy = this.state.taskData.activityGroup.map(
      (userid: string) => userid
    );
    let filteredAG = activityGroupCopy.filter(
      (userid) => userData.id !== userid
    );
    let newTaskData = {
      checklist: this.state.taskData.checklist,
      assignees: this.state.taskData.assignees,
      id: this.state.taskData.id,
      title: this.state.taskData.title,
      description: this.state.taskData.description,
      activityGroup: filteredAG,
      due_date: this.state.taskData.due_date,
      progressID: this.state.taskData.progressID,
      reminder: {
        reminderType: this.state.taskData.reminder.reminderType,
        reminderStart: this.state.taskData.reminder.reminderStart,
        reminderStartType: this.state.taskData.reminder.reminderStartType,
        reminderUntil: this.state.taskData.reminder.reminderUntil,
        frequencyType: this.state.taskData.reminder.frequencyType,
      },
      link: {
        fileID: this.state.taskData.link.fileID,
        clauseType: this.state.taskData.link.clauseType,
        biType: this.state.taskData.link.biType,
        linkedTableID: this.state.taskData.link.linkedTableID,
        linkedText: this.state.taskData.link.linkedText,
      },
      checkList: this.state.taskData.checkList,
    };
    this.setState({ addedUsers: filteredAD, taskData: newTaskData });
  };

  insertState(taskState: TaskState) {
    let newState = this.state;
    newState.taskData.progressID = taskState.id;
    newState.selectedTaskState.id = taskState.id;
    newState.selectedTaskState.name = taskState.name;

    this.setState(newState);
  }

  insertClauseType(clauseType: string) {
    let newState = this.state;
    newState.taskData.link.clauseType = clauseType;
    this.setState(newState);
  }

  insertBIType(biType: BIType) {
    let newState = this.state;
    newState.taskData.link.biType = biType.columnName;
    newState.selectedBITypeValue.value = biType.biType;
    this.setState(newState);
  }

  insertLinkedText(linkedText: string) {
    let newState = this.state;
    newState.taskData.link.linkedText = linkedText;
    this.setState(newState);
  }

  insertCheckListItem(item: string) {
    let checkListItem: CheckList = {
      id: "-1",
      title: item,
      flag: false,
      delete: false,
    };
    let newState = this.state;
    newState.taskData.checkList.push(checkListItem);
    this.setState(newState);
  }

  updateCheckListItem = (
    title: string,
    flag: boolean,
    updateType: string,
    id: string,
    editedTitle: string,
    index: number,
    attachment?: any[]
  ) => {
    switch (updateType) {
      case "checkbox": {
        // const itemsIndex = this.state.taskData.checkList.findIndex(item => item.title == title );

        let updatedCL = [...this.state.taskData.checkList];
        updatedCL[index] = { ...updatedCL[index], flag: flag };

        let newTaskData = {
          assignees: this.state.taskData.assignees,
          id: this.state.taskData.id,
          title: this.state.taskData.title,
          description: this.state.taskData.description,
          activityGroup: this.state.taskData.activityGroup,
          due_date: this.state.taskData.due_date,
          progressID: this.state.taskData.progressID,
          reminder: {
            reminderType: this.state.taskData.reminder.reminderType,
            reminderStart: this.state.taskData.reminder.reminderStart,
            reminderStartType: this.state.taskData.reminder.reminderStartType,
            reminderUntil: this.state.taskData.reminder.reminderUntil,
            frequencyType: this.state.taskData.reminder.frequencyType,
          },
          link: {
            fileID: this.state.taskData.link.fileID,
            clauseType: this.state.taskData.link.clauseType,
            biType: this.state.taskData.link.biType,
            linkedTableID: this.state.taskData.link.linkedTableID,
            linkedText: this.state.taskData.link.linkedText,
          },
          checkList: updatedCL,
          checklist: updatedCL,
        };
        this.setState({ taskData: newTaskData });
        break;
      }
      case "title": {
        // const itemsIndex = this.state.taskData.checkList.findIndex(item => item.id == id );

        let updatedCL = [...this.state.taskData.checkList];
        updatedCL[index] = { ...updatedCL[index], title: editedTitle };

        let newTaskData = {
          assignees: this.state.taskData.assignees,
          id: this.state.taskData.id,
          title: this.state.taskData.title,
          description: this.state.taskData.description,
          activityGroup: this.state.taskData.activityGroup,
          due_date: this.state.taskData.due_date,
          progressID: this.state.taskData.progressID,
          reminder: {
            reminderType: this.state.taskData.reminder.reminderType,
            reminderStart: this.state.taskData.reminder.reminderStart,
            reminderStartType: this.state.taskData.reminder.reminderStartType,
            reminderUntil: this.state.taskData.reminder.reminderUntil,
            frequencyType: this.state.taskData.reminder.frequencyType,
          },
          link: {
            fileID: this.state.taskData.link.fileID,
            clauseType: this.state.taskData.link.clauseType,
            biType: this.state.taskData.link.biType,
            linkedTableID: this.state.taskData.link.linkedTableID,
            linkedText: this.state.taskData.link.linkedText,
          },
          checkList: updatedCL,
          checklist: updatedCL,
        };
        this.setState({ taskData: newTaskData });
        break;
      }
      case "delete": {
        // const itemsIndex = this.state.taskData.checkList.findIndex(item => item.id == id );

        let updatedCL = [...this.state.taskData.checkList];
        updatedCL[index] = { ...updatedCL[index], delete: true };

        // let filteredCL = updatedCL.filter((item: CheckList) => item.id !== id);

        let newTaskData = {
          assignees: this.state.taskData.assignees,
          id: this.state.taskData.id,
          title: this.state.taskData.title,
          description: this.state.taskData.description,
          activityGroup: this.state.taskData.activityGroup,
          due_date: this.state.taskData.due_date,
          progressID: this.state.taskData.progressID,
          reminder: {
            reminderType: this.state.taskData.reminder.reminderType,
            reminderStart: this.state.taskData.reminder.reminderStart,
            reminderStartType: this.state.taskData.reminder.reminderStartType,
            reminderUntil: this.state.taskData.reminder.reminderUntil,
            frequencyType: this.state.taskData.reminder.frequencyType,
          },
          link: {
            fileID: this.state.taskData.link.fileID,
            clauseType: this.state.taskData.link.clauseType,
            biType: this.state.taskData.link.biType,
            linkedTableID: this.state.taskData.link.linkedTableID,
            linkedText: this.state.taskData.link.linkedText,
          },
          checkList: updatedCL,
          checklist: updatedCL,
        };

        this.setState({ taskData: newTaskData });
        break;
      }
    }
  };

  updateCheckListAttachment = (index: number, attachment: any[]) => {
    let updatedCL = [...this.state.taskData.checkList];
    updatedCL[index] = { ...updatedCL[index], attachment: attachment };
    let newTaskData = {
      assignees: this.state.taskData.assignees,
      id: this.state.taskData.id,
      title: this.state.taskData.title,
      description: this.state.taskData.description,
      activityGroup: this.state.taskData.activityGroup,
      due_date: this.state.taskData.due_date,
      progressID: this.state.taskData.progressID,
      reminder: {
        reminderType: this.state.taskData.reminder.reminderType,
        reminderStart: this.state.taskData.reminder.reminderStart,
        reminderStartType: this.state.taskData.reminder.reminderStartType,
        reminderUntil: this.state.taskData.reminder.reminderUntil,
        frequencyType: this.state.taskData.reminder.frequencyType,
      },
      link: {
        fileID: this.state.taskData.link.fileID,
        clauseType: this.state.taskData.link.clauseType,
        biType: this.state.taskData.link.biType,
        linkedTableID: this.state.taskData.link.linkedTableID,
        linkedText: this.state.taskData.link.linkedText,
      },
      checkList: updatedCL,
      checklist: updatedCL,
    };
    this.setState({ taskData: newTaskData });
  };

  componentDidMount() {
    this.props.getTaskProgress();
    this.props.getUserData();
  }

  fetchAllChildFiles = async () => {
    const accessToken = await getLocalStorage("accessToken");
    axios
      .get(SITEAPI + `library/file/${this.props.fileID}/child`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((result: any) => {
        const response: any = result.data;
        if (response.success) {
          this.setState({
            childFileList: response.allChildFiles,
          });
        } else {
          // alert('Comment Not Saved');
        }
      })
      .catch((error: any) => {});
  };

  dismissModal = () => {
    // let dismissBtn = document.getElementById('request-close-btn');
    // setTimeout(() => { dismissBtn?.click() }, 1500);
    this.setState({
      addedUsers: [],
      taskData: {
        checklist: [],
        assignees: [],
        id: "",
        title: "",
        description: "",
        activityGroup: [],
        due_date: "",
        progressID: "1",
        reminder: {
          reminderType: "Before",
          reminderStart: 1,
          reminderStartType: "Days",
          reminderUntil: "",
          frequencyType: "never",
        },
        link: {
          fileID: 0,
          clauseType: "",
          biType: "",
          linkedTableID: 0,
          linkedText: "",
        },
        checkList: [],
      },
      selectedTaskState: {
        id: "1",
        name: "Not Started",
      },
      selectedBITypeValue: {
        value: "",
      },
      selectedfrequencyType: {
        value: "Never",
      },
      requestID: 0,
      displayErrorMessage: false,
      id: "",
    });
  };

  passMandatoryFieldsCheck = () => {
    let { taskData } = this.state;
    if (
      taskData.title !== "" &&
      taskData.link.clauseType !== "" &&
      taskData.link.biType !== "" &&
      taskData.link.linkedText !== ""
    ) {
      return true;
    } else {
      let absentFields: string[] = [];
      taskData.title === "" && absentFields.push("Title");
      taskData.link.clauseType === "" && absentFields.push("Clause Type");
      taskData.link.biType === "" && absentFields.push("BI Type");
      taskData.link.linkedText === "" && absentFields.push("Link To Text");
      let message: string = "";

      // this.state.absentFields = [...absentFields];
      this.setState({ absentFields: [...absentFields] });

      switch (absentFields.length) {
        case 1: {
          message = "Please enter " + absentFields[0];
          break;
        }
        case 2: {
          message =
            "Please enter " + absentFields[0] + " and " + absentFields[1];
          break;
        }
        case 3: {
          message =
            "Please enter " +
            absentFields[0] +
            ", " +
            absentFields[1] +
            ", and " +
            absentFields[2];
          break;
        }
        case 4: {
          message =
            "Please enter " +
            absentFields[0] +
            ", " +
            absentFields[1] +
            ", " +
            absentFields[2] +
            ", and " +
            absentFields[3];
          break;
        }
      }
      this.setState({ errorMessage: message });
      return false;
    }
  };

  createTask = () => {
    if (this.passMandatoryFieldsCheck() === true) {
      let dismissModalLink = document.getElementById("dismissModalButton");
      !isNullOrUndefined(dismissModalLink) && dismissModalLink.click();

      this.setState({ displayErrorMessage: false });

      let newState = this.state;
      newState.taskData.link.fileID = this.props.fileID;

      this.setState(newState);

      if (this.props.editMode === true) {
        let taskData: TaskEdit = {
          requestID: this.state.requestID,
          title: this.state.taskData.title,
          description: this.state.taskData.description,
          activityGroup: this.state.taskData.activityGroup,
          due_date: this.state.taskData.due_date,
          progressID: this.state.taskData.progressID + "",
          reminder: this.state.taskData.reminder,
          link: this.state.taskData.link,
          checkList: this.state.taskData.checkList,
          id: this.state.taskData.id,
          assignees: this.state.taskData.assignees,
        };
        this.props.updateTask(taskData);
      } else {
        // Dispatch action through prop
        this.props.createTask(newState.taskData);
      }
      let link = document.getElementById("taskStatusModalButton");
      !isNullOrUndefined(link) && link.click();

      this.setState({
        addedUsers: [],
        taskData: {
          assignees: [],
          id: "",
          title: "",
          description: "",
          activityGroup: [],
          due_date: "",
          progressID: "1",
          reminder: {
            reminderType: "Before",
            reminderStart: 1,
            reminderStartType: "Days",
            reminderUntil: "",
            frequencyType: "never",
          },
          link: {
            fileID: 0,
            clauseType: "",
            biType: "",
            linkedTableID: 0,
            linkedText: "",
          },
          checkList: [],
          checklist: [],
        },
        selectedTaskState: {
          id: "1",
          name: "Not Started",
        },
        selectedBITypeValue: {
          value: "",
        },
        selectedfrequencyType: {
          value: "Never",
        },
        requestID: 0,
        displayErrorMessage: false,
        id: "",
      });
    } else {
      this.setState({ displayErrorMessage: true });
    }
  };

  componentWillReceiveProps(nextProps: Props) {
    let { editMode, currentEditTaskData, userData, currentTask, getComments } =
      this.props;
    if (nextProps.editMode === true) {
      if (
        !isNullOrUndefined(currentEditTaskData) &&
        !isNullOrUndefined(nextProps.currentEditTaskData)
      ) {
        if (nextProps.currentEditTaskData !== currentEditTaskData) {
          let progress = {
            id: "",
            name: "",
            progressID: "",
            checkList: [] as CheckList[],
            checklist: [] as CheckList[],
          };

          if (isNullOrUndefined(nextProps.currentEditTaskData.progressID)) {
            progress.id =
              this.state.progressIDS[nextProps.currentEditTaskData.progress];
            progress.name =
              this.state.progressData[nextProps.currentEditTaskData.progress];
            progress.progressID = this.props.taskData.progressID;
          } else {
            progress.id = nextProps.currentEditTaskData.progressID;
            progress.name = nextProps.currentEditTaskData.progressName;
            progress.progressID = nextProps.currentEditTaskData.progressID;
          }

          getComments(nextProps.currentEditTaskData.id);

          let addedUsersCopy: UserData[] = [];
          let activityGroupCopy: string[] = [];

          for (
            let i = 0;
            i < nextProps.currentEditTaskData.assignees.length;
            i++
          ) {
            let usersData: UserData = {
              id: nextProps.currentEditTaskData.assignees[i].id,

              username: nextProps.currentEditTaskData.assignees[i].username,
              first_name: nextProps.currentEditTaskData.assignees[i].first_name,
              last_name: nextProps.currentEditTaskData.assignees[i].last_name,
              email: nextProps.currentEditTaskData.assignees[i].email,
            };
            addedUsersCopy.push(usersData);
          }

          nextProps.currentEditTaskData.assignees.map((user) =>
            activityGroupCopy.push(user.id)
          );

          this.setState({
            addedUsers: addedUsersCopy,
            taskData: {
              assignees: nextProps.currentEditTaskData.assignees,
              id: nextProps.currentEditTaskData.id,
              title: nextProps.currentEditTaskData.title,
              description: nextProps.currentEditTaskData.notes,
              activityGroup: activityGroupCopy,
              due_date: nextProps.currentEditTaskData.due_date,
              progressID: progress.id,
              reminder: {
                reminderType:
                  nextProps.currentEditTaskData.reminder_before_or_after,
                reminderStart: nextProps.currentEditTaskData.reminder_number,
                reminderStartType:
                  nextProps.currentEditTaskData.reminder_period,
                reminderUntil: nextProps.currentEditTaskData.reminder_until,
                frequencyType: nextProps.currentEditTaskData.reminder_repeats,
              },
              link: {
                fileID: nextProps.currentEditTaskData.contractID,
                clauseType: nextProps.currentEditTaskData.contract_clause_type,
                biType: nextProps.currentEditTaskData.biColumnName,
                linkedTableID: 0,
                linkedText: nextProps.currentEditTaskData.link_to_contract_text,
              },
              checkList: nextProps.currentEditTaskData.checklist, //nextProps.currentEditTaskData.checkList,
              checklist: isNullOrUndefined(
                nextProps.currentEditTaskData.checklist
              )
                ? this.props.taskData.checklist
                : nextProps.currentEditTaskData.checklist,
            },
            selectedTaskState: {
              id: progress.id,
              name: progress.name,
            },
            selectedBITypeValue: {
              value: nextProps.currentEditTaskData.biType,
            },
            selectedfrequencyType: {
              value:
                //nextProps.currentEditTaskData.reminder_repeats,
                reminderFrequencyTypeKey[
                  nextProps.currentEditTaskData.reminder_repeats
                  //nextProps.currentEditTaskData.reminder.frequencyType
                ],
            },
            requestID: nextProps.currentEditTaskData.requestID,
            displayErrorMessage: false,
            id: nextProps.currentEditTaskData.id,
          });
        }
      }
    }
  }

  render() {
    let {
      userData,
      getUserData,
      taskProgress,
      currentTask,
      getBIType,
      getBISentence,
      biType,
      biSentence,
      fileID,
      clauseType,
      biTypeLoader,
      biTextLoader,
      origin,
      comments,
      postComment,
      getComments,
      commentLoader,
    } = this.props;

    let { displayErrorMessage, errorMessage, absentFields } = this.state;

    return (
      <div className="col-md-12 col-12">
        <div className="row modal" id="taskModal" aria-labelledby="taskModal">
          <div className="col-md-12 modal-dialog large-modal-dialog">
            <div className="row">
              <div className="col-md-12 modal-content">
                <div className="row">
                  <div
                    className="col-md-1"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "1.2rem",
                    }}
                  ></div>
                  <div
                    className="col-md-4 modal-title"
                    style={{ marginTop: "0.6rem", textAlign: "center" }}
                  >
                    {this.props.editMode === true
                      ? "Edit Task or Event"
                      : "Add Task or Event"}
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "1.2rem",
                    }}
                  ></div>
                  <div className="col-md-1" onClick={() => this.dismissModal()}>
                    <span
                      data-dismiss="modal"
                      style={{
                        float: "right",
                        cursor: "pointer",
                        marginTop: "0.5rem",
                      }}
                      id="request-close-btn"
                    >
                      <img
                        src="/static_images/close-modal-icn.svg"
                        alt="close"
                      />
                    </span>
                  </div>
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <div className="col-md-12 modal-body">
                      <Accordion.Item eventKey="0" key={1}>
                        <Accordion.Header>Task Information</Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-md-12 mt-12 ml-12 data-block">
                              <Task
                                getUserData={getUserData}
                                userData={userData}
                                insertUser={this.insertUsers}
                                addedUsers={this.state.addedUsers}
                                taskProgress={taskProgress}
                                selectedState={this.state.selectedTaskState}
                                insertState={this.insertState}
                                insertTitle={this.insertTitle}
                                insertDueDate={this.insertDueDate}
                                removeUser={this.removeUser}
                                title={this.state.taskData.title}
                                dueDate={this.state.taskData.due_date}
                                displayErrorMessage={displayErrorMessage}
                                absentFields={absentFields}
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" key={2}>
                        <Accordion.Header>Reminder</Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="row"
                            style={{ paddingBottom: "2rem" }}
                          >
                            <div className="col-md-12 mt-12 ml-12 data-block">
                              <Reminder
                                insertReminderDuration={
                                  this.insertReminderDuration
                                }
                                insertReminderDurationType={
                                  this.insertReminderDurationType
                                }
                                insertReminderDurationBeforeAfter={
                                  this.insertReminderDurationBeforeAfter
                                }
                                insertReminderRepeatWhen={
                                  this.insertReminderRepeatWhen
                                }
                                insertReminderRepeatUntil={
                                  this.insertReminderRepeatUntil
                                }
                                reminderStart={
                                  this.state.taskData.reminder.reminderStart
                                }
                                reminderStartType={
                                  this.state.taskData.reminder.reminderStartType
                                }
                                reminderType={
                                  this.state.taskData.reminder.reminderType
                                }
                                frequencyType={
                                  this.state.taskData.reminder.frequencyType
                                }
                                reminderUntil={
                                  this.state.taskData.reminder.reminderUntil
                                }
                                selectedfrequencyType={
                                  this.state.selectedfrequencyType.value
                                }
                              />
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" key={3}>
                        <Accordion.Header>Link</Accordion.Header>
                        <Accordion.Body>
                          {/* <div
                                                        className="row"
                                                        style={{
                                                            background: '#F5F5F5',
                                                            border: '1px solid #E1E1E1',
                                                            borderRadius: '0px',
                                                            height: '166px',
                                                        }}
                                                    >
                                                        <div className="col-md-12 mt-12 ml-12 data-block"> */}

                          <Link
                            currentTask={currentTask}
                            clauseType={clauseType}
                            getBIType={getBIType}
                            getBISentence={getBISentence}
                            biType={biType}
                            biSentence={biSentence}
                            fileID={fileID}
                            insertClauseType={this.insertClauseType}
                            insertBIType={this.insertBIType}
                            insertLinkedText={this.insertLinkedText}
                            clauseTypeState={
                              this.state.taskData.link.clauseType
                            }
                            biTypeState={this.state.taskData.link.biType}
                            linkedTextState={
                              this.state.taskData.link.linkedText
                            }
                            selectedBITypeValue={
                              this.state.selectedBITypeValue.value
                            }
                            editModeOn={this.props.editMode}
                            contractName={
                              this.props.currentEditTaskData?.contractName
                            }
                            contractID={
                              this.props.currentEditTaskData?.contractID
                            }
                            biTypeLoader={biTypeLoader}
                            biTextLoader={biTextLoader}
                            origin={origin}
                            displayErrorMessage={false} // We are making it non mandatory
                          />
                          {/* </div>
                                                    </div> */}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" key={4}>
                        <Accordion.Header>Notes</Accordion.Header>
                        <Accordion.Body>
                          <div
                            className="row"
                            style={{ paddingBottom: "2rem" }}
                          >
                            <Notes
                              description={this.state.taskData.description}
                              insertDescription={this.insertDescription}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4" key={5}>
                        <Accordion.Header>CheckList</Accordion.Header>
                        <Accordion.Body>
                          <div className="row" style={{}}>
                            <Checklist
                              checkList={this.state.taskData.checkList}
                              insertCheckListItem={this.insertCheckListItem}
                              updateCheckListItem={this.updateCheckListItem}
                              updateCheckListAttachment={
                                this.updateCheckListAttachment
                              }
                              userData={
                                userData
                                  ? userData.map((user) => {
                                      return {
                                        id: user.id,
                                        value: `${user.first_name} ${user.last_name}`,
                                      };
                                    })
                                  : []
                              }
                              childFileList={
                                this.state.childFileList
                                  ? this.state.childFileList.map((file) => {
                                      return {
                                        value: file.id,
                                        label: file.file_name,
                                      };
                                    })
                                  : []
                              }
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <br />
                      <br />
                      <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-4 float-right">
                          <span
                            className="upload-yellow-btn ml-4"
                            id="save-btn"
                            style={
                              this.props.editMode === true
                                ? { padding: "1.3% 6%" }
                                : {}
                            }
                            onClick={(e) => this.createTask()}
                          >
                            {this.props.editMode === true
                              ? "Save Changes"
                              : "Save"}
                          </span>
                          <button
                            style={{ display: "none" }}
                            type="button"
                            data-dismiss="modal"
                            id="dismissModalButton"
                          ></button>
                        </div>
                        {displayErrorMessage === true && (
                          <>
                            <div
                              className="col-md-12"
                              style={{ color: "red", textAlign: "end" }}
                            >
                              {errorMessage}
                            </div>
                          </>
                        )}
                      </div>
                      {this.state.id !== "" && (
                        <div className="row" style={{ marginBottom: "0px" }}>
                          <CommentSection
                            comments={comments}
                            postComment={postComment}
                            getComments={getComments}
                            requestID={this.state.requestID}
                            commentLoader={commentLoader}
                            id={this.state.id}
                            // userData={
                            //   userData
                            //     ? userData.map((user) => {
                            //         return {
                            //           id: user.id,
                            //           value: user.name,
                            //         };
                            //       })
                            //     : []
                            // }
                          />
                        </div>
                      )}
                    </div>
                  </Accordion>
                  {/* ______________________________________-- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
