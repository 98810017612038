import React, { useEffect, useState } from "react";
import { Approval } from "../State/ApprovalState";
import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "./TabPanel";
import { getContracts } from "../../Draft/DA";
import { ContractRes } from "../../Draft/State/DraftState";
import MyApprovalListView from "./MyApprovalListView";
import { getDateString } from "../../Utils/DataModifierUtil/dataModUtil";
import { approvalPendingStatuses, signatureStatuses } from "../../Constants/status";

interface Props {
    approvalsList: Approval[];
    getApprovals: () => void;
    currentUser: string;
}

const MyApprovalCard = (props: Props) => {
    const [value, setValue] = useState(0);
    const [forApproval, setforApproval] = useState<ContractRes[]>([]);
    const [forSignature, setForSignature] = useState<ContractRes[]>([]);
    const [inProgress, setInProgress] = useState<ContractRes[]>([]);
    const [forAll, setForAll] = useState<ContractRes[]>([]);
    const [loading, setloading] = useState(false)

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        console.log("a11yProps", index);
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        getContractDrafts()
    }, [])

    const setMyApprovalDetails = (contracts: any[], user_id: string | null) => {
        let approvalPending = contracts.filter(contract => 
            approvalPendingStatuses.includes(contract.status.toLowerCase()));

        let forSignature = contracts.filter(contract => 
            signatureStatuses.includes(contract.status.toLowerCase()));

        const forApprovalDetails = contracts.filter((detail: any) => {
            return detail.approvers.find((users: any) => users.id === user_id)
        })

        setForAll(contracts.reverse());
        setforApproval(forApprovalDetails.reverse());
        setForSignature(forSignature.reverse())
        setInProgress(approvalPending.reverse())
    }

    const getContractDrafts = async () => {
        try {
            setloading(true);
            let response = await getContracts(1);
            const user_id = localStorage.getItem("user_id")

            if (response && response.results.length > 0) {
                let contracts: ContractRes[] = response.results.map((contract: any) => {
                    let contractRes: ContractRes = {
                        ...contract,
                        created_on: getDateString(new Date(contract['created_on'])),
                        deadline: getDateString(new Date(contract['deadline'])),
                    };
                    return contractRes;
                });
                setMyApprovalDetails(contracts, user_id);

            } else {
                setForAll([]);
                setforApproval([]);
                setForSignature([]);
                setInProgress([]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setloading(false);
        }
    }

    return (
        <div className="ROOT">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab disableRipple label="All" {...a11yProps(0)} />
                    <Tab disableRipple label="Completed" {...a11yProps(1)} />
                    <Tab disableRipple label="In-progress" {...a11yProps(2)} />
                    <Tab disableRipple label="For Approval" {...a11yProps(3)} />
                    <Tab disableRipple label="For Signature" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <MyApprovalListView getApprovals={getContractDrafts} draftContractData={forAll} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MyApprovalListView getApprovals={getContractDrafts} draftContractData={forAll} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <MyApprovalListView getApprovals={getContractDrafts} draftContractData={inProgress} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <MyApprovalListView getApprovals={getContractDrafts} draftContractData={forApproval} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <MyApprovalListView getApprovals={getContractDrafts} draftContractData={forSignature} />
            </TabPanel>

            {loading && <CircularProgress className="loader" />}
        </div>

    );
}

export default MyApprovalCard;

