import React, { useEffect, useState } from "react";
import { FilterAPI } from "../DA";
import { ClauseType, FilterType, SavedFilters } from "../State";

const useFilters = () => {
  const [clauseTypes, setclauseTypes] = useState<ClauseType[]>([]);
  const [currency, setcurrency] = useState<FilterType[]>([]);
  const [groups, setgroups] = useState<FilterType[]>([]);
  const [nature, setnature] = useState<FilterType[]>([]);
  const [others, setothers] = useState<FilterType[]>([]);
  const [parties, setparties] = useState<FilterType[]>([]);
  const [count, setcount] = useState(0);
  const [payee, setpayee] = useState<FilterType[]>([]);
  const [payers, setpayers] = useState<FilterType[]>([]);
  const [extent_of_costs, setextent_of_costs] = useState<FilterType[]>([]);
  const [triggering_events, settriggering_events] = useState<FilterType[]>([]);
  const [confidentiality_nature, setconfidentiality_nature] = useState<
    FilterType[]
  >([]);
  const [termination_events, settermination_events] = useState<FilterType[]>(
    []
  );
  const [force_majeure_events, setforce_majeure_events] = useState<
    FilterType[]
  >([]);
  const [jurisdictions, setjurisdictions] = useState<FilterType[]>([]);
  const [dispute_res_venue, setdispute_res_venue] = useState<FilterType[]>([]);
  const [dispute_res_act_statute, setdispute_res_act_statute] = useState<
    FilterType[]
  >([]);
  const [dispute_res_panel, setdispute_res_panel] = useState<FilterType[]>([]);
  const [territory, setterritory] = useState<FilterType[]>([]);
  const [authority, setauthority] = useState<FilterType[]>([]);
  const [savedfilters, setsavedfilters] = useState<SavedFilters[]>([]);
  const [change_of_control, setchange_of_control] = useState<FilterType[]>([]);
  const [termination_at_convenience, settermination_at_convenience] = useState<
    FilterType[]
  >([]);
  const [dispute_res_other, setdispute_res_other] = useState<FilterType[]>([]);
  const [dispute_res_arbitration, setdispute_res_arbitration] = useState<
    FilterType[]
  >([]);
  const [dispute_res_negoation, setdispute_res_negoation] = useState<
    FilterType[]
  >([]);
  const [dispute_res_concillation, setdispute_res_concillation] = useState<
    FilterType[]
  >([]);
  const [dispute_res_mediation, setdispute_res_mediation] = useState<
    FilterType[]
  >([]);
  const [consent_clausetype, setconsent_clausetype] = useState<FilterType[]>(
    []
  );
  const [subletting_consent, setsubletting_consent] = useState<FilterType[]>(
    []
  );
  const [subletting_notice, setsubletting_notice] = useState<FilterType[]>([]);
  const [events_of_default, setevents_of_default] = useState<FilterType[]>([]);
  const [locked_in_period, setlocked_in_period] = useState<FilterType[]>([]);
  const [limited_liability_amount, setlimited_liability_amount] = useState<FilterType[]>([]);
  const [non_compete_duration, setnon_compete_duration] = useState<FilterType[]>([]);
  const [non_solicitation_duration, setnon_solicitation_duration] = useState<FilterType[]>([]);
  const [indemnity_cap, setindemnity_cap] = useState<FilterType[]>([]);
  const [confidentiality_duration, setconfidentiality_duration] = useState<FilterType[]>([]);
  const [cexcept, setcexcept] = useState<FilterType[]>([]);
  useEffect(() => {
    getClause();
    getCurrency();
    getGroups();
    getNature();
    getOthers();
    getParties();
    getCount();
    getPayee();
    getPayers();
    getExtentOfCosts();
    getTriggeringEvents();
    getConfidentialityNature();
    getConfidentialityDuration();
    getCexcept();
    getTerminationEvent();
    getForceMejureEvents();
    getJurisdictions();
    getDisputeResolutionVenue();
    getDisputeResolutionActOrState();
    getDisputeResolutionPanel();
    getTerritory();
    getAuthority();
    getSavedFilters();
    getChangeOfControl();
    getTerminationAtConvience();
    getDisputeResolutionOther();
    getDisputeResolutionArbitration();
    getDisputeResolutionNegoation();
    getDisputeResolutionConcillation();
    getDisputeResolutionMediation();
    getConsentClauseType();
    getSublettingConsent();
    getSublettingNotice();
    getEventsOfDefault();
    getLockInPeriod();
    getLimitedLiability();
    getNonCompeteDuration();
    getNonSolicitationDuration();
    getIndemnityCap();
  }, []);

  const getClause = async () => {
    const filters = await FilterAPI.getClauseTypes();
    setclauseTypes([...filters.results]);
  };

  const getCurrency = async () => {
    const filters = await FilterAPI.getCurrency();
    setcurrency([...filters.results]);
  };

  const getGroups = async () => {
    const groups = await FilterAPI.getGroups();
    setgroups([...groups]);
  };
  const getNature = async () => {
    const nature = await FilterAPI.getNature();
    setnature([...nature]);
  };
  const getOthers = async () => {
    const other = await FilterAPI.getOthers();
    setothers([...other]);
  };
  const getParties = async () => {
    const parties = await FilterAPI.getParties();
    setparties([...parties]);
  };

  const getCount = async () => {
    const count = await FilterAPI.getTotalContractsCount();
    setcount(count?.contracts_statistic?.contracts_count);
  };

  const getPayee = async () => {
    const payee = await FilterAPI.getPayee();
    setpayee([...payee]);
  };

  const getPayers = async () => {
    const payers = await FilterAPI.getPayers();
    setpayers([...payers]);
  };

  const getExtentOfCosts = async () => {
    const extent_of_costs = await FilterAPI.getExtentOfCosts();
    setextent_of_costs([...extent_of_costs]);
  };

  const getTriggeringEvents = async () => {
    const triggering_events = await FilterAPI.getTriggeringEvents();
    settriggering_events([...triggering_events]);
  };

  const getConfidentialityNature = async () => {
    const confidentiality_nature = await FilterAPI.getConfidentialityNature();
    setconfidentiality_nature([...confidentiality_nature]);
  };

  const getTerminationEvent = async () => {
    const termination_events = await FilterAPI.getTerminationEvent();
    settermination_events([...termination_events]);
  };

  const getForceMejureEvents = async () => {
    const force_majeure_events = await FilterAPI.getForceMejureEvents();
    setforce_majeure_events([...force_majeure_events]);
  };

  const getJurisdictions = async () => {
    const jurisdictions = await FilterAPI.getJurisdictions();
    setjurisdictions([...jurisdictions]);
  };

  const getDisputeResolutionVenue = async () => {
    const dispute_res_venue = await FilterAPI.getDisputeResolutionVenue();
    setdispute_res_venue([...dispute_res_venue]);
  };

  const getDisputeResolutionActOrState = async () => {
    const dispute_res_act_statute =
      await FilterAPI.getDisputeResolutionActOrState();
    setdispute_res_act_statute([...dispute_res_act_statute]);
  };

  const getDisputeResolutionPanel = async () => {
    const dispute_res_panel = await FilterAPI.getDisputeResolutionPanel();
    setdispute_res_panel([...dispute_res_panel]);
  };

  const getTerritory = async () => {
    const territory = await FilterAPI.getTerritory();
    setterritory([...territory]);
  };

  const getAuthority = async () => {
    const authority = await FilterAPI.getAuthority();
    setauthority([...authority]);
  };

  const getSavedFilters = async () => {
    const savedfilters = await FilterAPI.getSavedFilters();
    setsavedfilters([...savedfilters]);
  };

  const getChangeOfControl = () => {
    let change_of_control = [
      {
        id: "notice",
        type: "change_of_control",
        name: "Notice",
      },
      {
        id: "payment",
        type: "change_of_control",
        name: "Payments",
      },
      {
        id: "consent",
        type: "change_of_control",
        name: "Consent",
      },
      {
        id: "termination",
        type: "change_of_control",
        name: "Termination",
      },
    ];
    setchange_of_control([...change_of_control]);
  };

  const getTerminationAtConvience = () => {
    let termination_at_convenience: any = [
      {
        id: "yes",
        type: "termination_at_convenience",
        name: "Yes",
      },
      {
        id: "no",
        type: "termination_at_convenience",
        name: "No",
      },
    ];
    settermination_at_convenience([...termination_at_convenience]);
  };

  const getDisputeResolutionOther = () => {
    let dispute_res_other: any = [
      {
        id: "yes",
        type: "dispute_res_other",
        name: "Yes",
      },
      {
        id: "no",
        type: "dispute_res_other",
        name: "No",
      },
    ];
    setdispute_res_other([...dispute_res_other]);
  };

  const getDisputeResolutionArbitration = () => {
    let dispute_res_arbitration: any = [
      {
        id: "yes",
        type: "dispute_res_arbitration",
        name: "Yes",
      },
      {
        id: "no",
        type: "dispute_res_arbitration",
        name: "No",
      },
    ];
    setdispute_res_arbitration([...dispute_res_arbitration]);
  };

  const getDisputeResolutionNegoation = () => {
    let dispute_res_negoation = [
      {
        id: "yes",
        type: "dispute_res_negoation",
        name: "Yes",
      },
      {
        id: "no",
        type: "dispute_res_negoation",
        name: "No",
      },
    ];
    setdispute_res_negoation([...dispute_res_negoation]);
  };
  const getDisputeResolutionConcillation = () => {
    let dispute_res_concillation = [
      {
        id: "yes",
        type: "dispute_res_concillation",
        name: "Yes",
      },
      {
        id: "no",
        type: "dispute_res_concillation",
        name: "No",
      },
    ];
    setdispute_res_concillation([...dispute_res_concillation]);
  };
  const getDisputeResolutionMediation = () => {
    let dispute_res_mediation = [
      {
        id: "yes",
        type: "dispute_res_mediation",
        name: "Yes",
      },
      {
        id: "no",
        type: "dispute_res_mediation",
        name: "No",
      },
    ];
    setdispute_res_mediation([...dispute_res_mediation]);
  };

  const getConsentClauseType = () => {
    let consent_clausetype: any = [
      {
        id: "Regulatory",
        type: "consent_regulatory",
        name: "Regulatory",
      },
      {
        id: "Non-Regulatory",
        type: "consent_nonregulatory",
        name: "Non Regulatory",
      },
    ];
    setconsent_clausetype([...consent_clausetype]);
  };

  const getSublettingConsent = () => {
    let subletting_consent: any = [
      {
        id: "yes",
        type: "subletting_consent",
        name: "Yes",
      },
      {
        id: "no",
        type: "subletting_consent",
        name: "No",
      },
    ];
    setsubletting_consent([...subletting_consent]);
  };

  const getSublettingNotice = () => {
    let subletting_notice: any = [
      {
        id: "yes",
        type: "subletting_notice",
        name: "Yes",
      },
      {
        id: "no",
        type: "subletting_notice",
        name: "No",
      },
    ];
    setsubletting_notice([...subletting_notice]);
  };

  const getEventsOfDefault = async () => {
    const events_of_default = await FilterAPI.getEventsofdefault();
    setevents_of_default([...events_of_default]);
  };

  const getLockInPeriod = async () => {
    const locked_in_period = await FilterAPI.getLockInPeriod();
    setlocked_in_period([...locked_in_period])
  }

  const getLimitedLiability = async () => {
    const limited_liability_amount = await FilterAPI.getLimitedLiability();
    setlimited_liability_amount([...limited_liability_amount])
  }

  const getNonCompeteDuration = async () => {
    const non_compete_duration = await FilterAPI.getNonCompeteDuration();
    setnon_compete_duration([...non_compete_duration]);
    /*let non_compete_duration: any = [
      {
        id: "yes",
        type: "non_compete_duration",
        name: "Yes",
      },
      {
        id: "no",
        type: "non_compete_duration",
        name: "No",
      },
    ];
    setnon_compete_duration([...non_compete_duration]);*/
  }

  const getNonSolicitationDuration = async () => {
    const non_solicitation_duration = await FilterAPI.getNonSolicitationDuration();
    setnon_solicitation_duration([...non_solicitation_duration])

    /*let confidentiality_duration: any = [
      {
        id: "yes",
        type: "non_solicitation_duration",
        name: "Yes",
      },
      {
        id: "no",
        type: "non_solicitation_duration",
        name: "No",
      },
    ];
    setnon_solicitation_duration([...confidentiality_duration]);*/
  }

  const getIndemnityCap = async () => {
    const indemnity_cap = await FilterAPI.getIndemnityCap();
    setindemnity_cap([...indemnity_cap])
  }

  const getConfidentialityDuration = async () => {
    const confidentiality_duration = await FilterAPI.getConfidentialityDuration();
    
    /*
    let confidentiality_duration: any = [
      {
        id: "yes",
        type: "confidentiality_duration",
        name: "Yes",
      },
      {
        id: "no",
        type: "confidentiality_duration",
        name: "No",
      },
    ];
    */
    setconfidentiality_duration([...confidentiality_duration]);
  }

  const getCexcept = async () => {
    const cexcept = await FilterAPI.getCexcept();
    setcexcept([...cexcept])
  }

  return {
    clauseTypes,
    currency,
    groups,
    nature,
    others,
    parties,
    count,
    payee,
    payers,
    extent_of_costs,
    triggering_events,
    confidentiality_nature,
    termination_events,
    force_majeure_events,
    jurisdictions,
    dispute_res_venue,
    dispute_res_act_statute,
    dispute_res_panel,
    territory,
    authority,
    savedfilters,
    change_of_control,
    termination_at_convenience,
    dispute_res_other,
    dispute_res_arbitration,
    dispute_res_negoation,
    dispute_res_concillation,
    dispute_res_mediation,
    consent_clausetype,
    subletting_consent,
    subletting_notice,
    events_of_default,
    locked_in_period,
    limited_liability_amount,
    non_compete_duration,
    non_solicitation_duration,
    indemnity_cap,
    confidentiality_duration,
    cexcept
  };
};

export default useFilters;
