import { Button, CircularProgress, Drawer, Snackbar } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons';
import { AiOutlineReload } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { getLocalStorage } from '../../Authentication/Actions/authentication';
import { Sort } from '../State/DraftState';
import Avatarfordraft from './AvatarForDraft';

interface Props {
    open: boolean;
    onClose: () => void;
    selectedDraft: any | null;
}

const SignatureConfigDialog = (props:Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedDrafts, setselectedDrafts] = useState<string[]>([]);
  const [signatories, setsignatories] = useState<any[]>([]);
  const [signaturePending, setsignaturePending] = useState(0);
  const [signatureConfirmed, setsignatureConfirmed] = useState(0);
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  const [openSnackBar, setOpenSnackBar] = useState({ open: false, msg: "" });

  useEffect(() => {
    setopen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.selectedDraft && Object.keys(props.selectedDraft).length > 0) {
      getSignatories();
    }
  }, [props.selectedDraft]);

  useEffect(() => {
    setSorting(signatories);
  }, [sortData]);

  const handleClose = () => {
    setopen(false);
    props.onClose();
  };

  const handleSelectAllDrafts = () => {
    if (selectedDrafts.length > 0) {
      setselectedDrafts([]);
      return;
    }
    let selected: string[] = [];
    selected = signatories.map((user) => user.id);
    setselectedDrafts([...selected]);
  };

  const getSignatories = () => {
    if (props.selectedDraft.signatories && props.selectedDraft.signatories.length > 0) {
      const sign = [...props.selectedDraft.signatories];
      setsignatories([...sign]);
      let signaturePending = 0;
      let signatureConfirmed = 0;
      sign.forEach(user => {
        if(user?.isSigned){
          signatureConfirmed+=1;
        }else signaturePending+=1;
      });
      setsignaturePending(signaturePending);
      setsignatureConfirmed(signatureConfirmed);
    }
  }

  const selectDraft = (id: any) => {
    let selected = [...selectedDrafts];
    let index = selected.findIndex((x) => x==id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedDrafts([...selected]);
  };

  const isExternal = (user:any) => {
    if(user?.groups && user?.groups.length > 0){
      for (let index = 0; index < user.groups.length; index++) {
        const element = user.groups[index];
        if(element.toLowerCase().includes('ext')){
          return true;
        }
      }
    }
    return false;
  }

  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
  };

  const setSorting = (users: any[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...users];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "date") {
          if (sortData.sortType == "asc") {
            return (
              new Date(a[sortData.sortKey]).getTime() -
              new Date(b[sortData.sortKey]).getTime()
            );
          }
          if (sortData.sortType == "desc") {
            return (
              new Date(b[sortData.sortKey]).getTime() -
              new Date(a[sortData.sortKey]).getTime()
            );
          }
        }
        return sorted;
      });
      setsignatories([...filteredList]);
    }
  };

  const sendSelectedReminders = () => {
    let selected = [...selectedDrafts];
    let users = selected.map((id) => signatories.find((user) => user.id == id)).filter(d => d);
    if(!isPendingSign(users)){
      setOpenSnackBar({open:true,msg:'Cannot send reminder to signed user'});
      return;
    }
    sendSignatureEmail(props.selectedDraft,users);
  } 

  const isPendingSign = (users:any[]) => {
    if(users.length == 0) return false;
    for (let index = 0; index < users.length; index++) {
      const element = users[index];
      if(element.isSigned){
        return false;
      }
    }
    return true;
  }

  const handleSnackBarClose = () => {
    setOpenSnackBar({ open: false, msg: "" });
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        OK
      </Button>
    </React.Fragment>
  );

  const sendBulkReminders = () => {
    let users = signatories.filter((user) => !user?.isSigned);
    let allSigned = true;
    signatories.forEach((user) => {
      if(!user?.isSigned){
        allSigned = false;
      }
    });
    if(allSigned){
      setOpenSnackBar({open:true,msg:'All signatories have signed the document'});
      return;
    }
    sendSignatureEmail(props.selectedDraft,users);
  }


  const sendSignatureEmail = (draft:any,users:any[]) => {
    let link =
      process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
      `draftingreview/${draft?.id}`;
    users.forEach((user) => {
      let userName =`${user.first_name}  ${user.last_name}`;
      let emailPayload: any = {
        user_ids: [user.id],
        body_html: `Hello ${userName}<br/><p>Signature for the document <b>${draft?.contractName}</b>, requested on ${user?.assigned_date}, is still pending. Request you to sign the document to complete the process.</p><h4><b><a href='${link}'>Click here to be redirected to signature page</a></b></h4><p>Thank You</p>`,
        body_text: "",
        subject:`Signature Pending Reminder: ${draft?.contractName}`,
      };
      send_email(emailPayload);
    });
    setOpenSnackBar({open:true,msg:'Reminder Sent'})
  }
  const send_email = async (payload: any) => {
    try {
      setloading(true);
      const APIConfig = () => ({
        headers: {
          Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
        },
      });
      const URL = process.env.REACT_APP_RIVERUS_CLM_API + `sending_email/`;
      await axios.post(URL, payload, APIConfig());
    } catch (error) {
      console.log(error);
      setOpenSnackBar({open:true,msg:'Reminder Error'})
    } finally {
      setloading(false);
    }
  };

  return (
    <Drawer anchor='right' open={open} onClose={handleClose}>
      <div className="container version-history">
        {loading && <CircularProgress className="loader" />}
        <div className="row align-items-center">
          <div className="col-md-4 df align-items-center">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft onClick={handleClose} />
            </IconContext.Provider>
            <p className="m-0 ml-3 fw">Signature Record</p>
          </div>
          <div className="col-md-8 text-align-end">
            <button
              type="button"
              onClick={sendBulkReminders}
              className="btn btn-warning create-btn"
              style={{ fontWeight: "500" }}
            >
              <img
                src="/static_images/bulk-reminder.svg"
                alt="refresh"
                className="mr-1"
              />
              Send Bulk Reminder
            </button>
          </div>
        </div>
        <div className="d-flex mt-3 pl-0">
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {signatureConfirmed}
            </p>
            <p className="myDocument-uploadtype">SIGNATURE CONFIRMED</p>
          </div>
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {signaturePending}
            </p>
            <p className="myDocument-uploadtype">SIGNATURE PENDING</p>
          </div>
        </div>
        <div className="row justify-content-between" style={{ height: "33px" }}>
          <div className="col-md-8">
            <div
              className="row mt-1 mb-1 df align-items-center"
              style={{ marginLeft: "0" }}
            >
              <IconContext.Provider
                value={{
                  color: "#88305F",
                  className: "reload-icon cursor-pointer",
                }}
              >
                <AiOutlineReload />
              </IconContext.Provider>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {selectedDrafts.length > 0 && (
                <img
                  onClick={sendSelectedReminders}
                  className="cp"
                  style={{ top: "0" }}
                  src="/static_images/email_reminder.svg"
                  alt="checked"
                ></img>
              )}

            </div>
          </div>
        </div>
        <div className="row list-view-header version-history__table-header">
          <div
            className="col-md-1 df align-items-center"
            style={{ maxWidth: "6%" }}
          >
            {(selectedDrafts.length == 0) && (
              <input
                onChange={(e) => handleSelectAllDrafts()}
                style={{ width: "13px", height: "13px" }}
                type="checkbox"
                name="check"
                id="check"
              />
            )}
            {selectedDrafts.length > 0 && (
              <img
                onClick={() => handleSelectAllDrafts()}
                className="checked-icon"
                style={{ top: "0" }}
                src="/static_images/checked_icon.svg"
                alt="checked"
              ></img>
            )}
          </div>
          <div
            className="col-md-3"
          >
            Signatories
          </div>
          <div className="col-md-2">Status</div>
          <div
            className="col-md-3"
          >
            Assigned Date{" "}
            <span>
              <img
              onClick={() => applySort("assigned_date", "date")}
                src="static_images/calender_filter_inactive.svg"
                alt='date'
              />
            </span>
          </div>
          <div className="col-md-3">
            Signed Date{" "}
            <span>
              <img
              onClick={() => applySort("signed_date", "date")}
                src="static_images/calender_filter_inactive.svg"
                alt='date'
              />
            </span>
          </div>
        </div>
        {signatories.length > 0 &&
          signatories.map((user: any, index: any) => (
            <div
              key={index}
              className={`row version-history__table-list ${selectedDrafts.indexOf(user.id) > -1 ? "selected-draft" : ""
                }`}
              style={{ backgroundColor: index % 2 != 0 ? "white" : "#FAFAFA", margin: '0 -10px' }}
            >
              <div
                className="col-md-1 df align-items-center"
                style={{ maxWidth: "6%" }}
              >
                {selectedDrafts.indexOf(user.id) == -1 && (
                  <input
                    onChange={(e) => selectDraft(user?.id)}
                    style={{ width: "15px", height: "15px" }}
                    type="checkbox"
                    name="check"
                    id="check"
                  />
                )}
                {selectedDrafts.indexOf(user.id) > -1 && (
                  <img
                    onClick={() => selectDraft(user?.id)}
                    className="checked-icon"
                    style={{ top: "0" }}
                    src="/static_images/checked_icon.svg"
                    alt="checked"
                  ></img>
                )}
              </div>
              <div
                className="col-md-3 d-flex c-grey"
              >
                {isExternal(user) ? <span><img style={{ width: '17px' }}
                  src="/static_images/emailIcon.svg"
                  alt="checked"
                />&nbsp;{user?.first_name}</span> : <Avatarfordraft users={[user]} />}
              </div>
              <div className="col-md-2 c-grey">{user?.isSigned ? 'Signed' : 'Pending'}</div>
              <div
                className="col-md-3 c-grey"
              >
                {user?.assigned_date ? user?.assigned_date : ''}
              </div>
              <div className="col-md-3 c-grey">
                {user?.isSigned ? new Date(user?.signed_date).toLocaleDateString() : ''}
              </div>
            </div>
          ))}
      </div>
      <Snackbar
        open={openSnackBar.open}
        autoHideDuration={2000}
        message={openSnackBar.msg}
        onClose={handleSnackBarClose}
        key="bottomCenter"
        action={action}
      />
    </Drawer>
  )
}

export default SignatureConfigDialog