import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import WebViewer from "@pdftron/webviewer";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { getdraftbyid } from "../DA";
import { ContractRes } from "../State/DraftState";

interface Props {
  setComparisionDrawer: any;
  openComparisionDrawer: any;
  draftDetails: any;
  selectedDraftsLink: any;
  onClose: any;
  compareFileName: any;
}
export default function ComparisionDialog(props: Props) {
  const [inta, seInsta] = useState<any>("");
  const [enableKDP, setenableKDP] = React.useState(false);
  const [kdpClause_DataPoint, setkdpClause_DataPoint] = React.useState<any>({});
  const [annotationIds, setannotationIds] = React.useState<any>([]);
  const [showTemplateField, setshowTemplateField] = useState(false);
  const [templateKeyValue, settemplateKeyValue] = useState<any>(null);
  const [isSearch, setSearch] = useState<any>(false);
  const [link1, setLink1] = useState<string>("");
  const [link2, setLink2] = useState<string>("");

  const documentContainer = useRef<HTMLDivElement>(null);

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const getKDPDataPoint = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/draft/insights/${props.draftDetails?.id}/`;
    return axios
      .get(URL, APIConfig())
      .then((response: any) => {
        if (response.status == 200) {
          let clauseName: any[] = [];
          let clausewiseDataPoint: any = {};
          let highlight_text: any = [];
          let highlightedData: any = [];
          if (response.data["queryResult"].length > 0) {
            let parseData = response.data["queryResult"];
            console.log("parseData", parseData);
            setkdpClause_DataPoint(parseData);
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // getDeviationPolicyFromApprovals();
    getKDPDataPoint();
  }, []);
  const Kdp_Parse = () => {
    const { Core } = inta;
    const { annotationManager } = Core;
    if (!enableKDP) {
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
    } else {
      if (kdpClause_DataPoint && kdpClause_DataPoint.length) {
        let chain = Promise.resolve();
        for (const nextAnnotation of kdpClause_DataPoint) {
          chain = chain.then(() => {
            return drawAnnotation(nextAnnotation);
          });
          // console.log("chain", chain)
        }
      }
    }
  };

  function drawAnnotation(phrase: any) {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    return new Promise<void>((resolve) => {
      console.log("drawAnnotation(): " + phrase);
      const searchMode =
        inta.Core.Search.Mode.HIGHLIGHT | inta.Core.Search.PAGE_STOP;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          if (result.resultCode === inta.Core.Search.ResultCode.FOUND) {
            console.log(
              "onResult: " + result,
              result.quads,
              "---pagenumberss-----",
              result.pageNum
            );
            result.quads.map((quad: { getPoints: () => any }) => {
              console.log(phrase, "=======quad", quad.getPoints());
              // setresults([...quad.getPoints()])
              const highlight = new Annotations.TextHighlightAnnotation();
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              highlight.Quads = [quad.getPoints()] as any[];
              let annotationId: any = highlight.Id;
              let annotateId: any = {};
              annotateId[annotationId] = phrase["clause"];
              // setannotationIds({...annotationIds, annotateId});
              console.log("annotationId", annotationId);
              let newannotate: any[] = annotationIds;
              console.log(newannotate, "....New Annotate");
              newannotate.push(annotateId);
              setannotationIds(newannotate);
              // setfilterPayload({ ...filterPayload, end_date: change });
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
            });
          }
        },
        onDocumentEnd: (result: any) => {
          resolve();
        },
      };
      inta.UI.addSearchListener(searchListener);
      documentViewer.textSearchInit(
        phrase["highlighted_text"],
        searchMode,
        searchOptions
      );
    });
  }

  const searchListener = (searchPattern: any, options: any, results: any) => {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    // add redaction annotation for each search result

    const annotations = annotationManager.getAnnotationsList();
    annotationManager.deleteAnnotations(annotations);
    const newAnnotations = results.map(
      (result: { pageNum: any; quads: any[] }) => {
        const annotation = new Annotations.TextHighlightAnnotation();
        annotation.Author = annotationManager.getCurrentUser();
        annotation.PageNumber = result.pageNum;
        annotation.Quads = result.quads.map((quad) => quad.getPoints());
        annotation.StrokeColor = new Annotations.Color(255, 255, 0);

        return annotation;
      }
    );
    annotationManager.addAnnotations(newAnnotations);
  };

  const convertPDF = async (Core: any, fileURL: any) => {
    // perform the conversion with no optional parameters
    const buf = await Core.office2PDFBuffer(fileURL);

    //optionally save the blob to a file or upload to a server
    const blob = new Blob([buf], { type: "application/pdf" });
    return blob;
  };

  const draftAccessURL = async (id: string) => {
    const response = await getdraftbyid(id);
    if (response) {
      return response.access_url;
    }
    return "";
  };

  useEffect(() => {
    draftAccessURL(props.selectedDraftsLink[0]).then((value) => {
      if (value) {
        setLink1(value);
      }
    });
    draftAccessURL(props.selectedDraftsLink[1]).then((value) => {
      if (value) {
        setLink2(value);
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      if (documentContainer.current != null && link1 != "" && link2 != "") {
        WebViewer(
          {
            fullAPI: true,
            disableMultiViewerComparison: true,
            path: "/webviewer",
            disabledElements: ["header", "toolsHeader"],
            loadAsPDF: true,
          },
          documentContainer.current
        ).then((instance: any) => {
          const { UI, Core } = instance;
          const { Annotations, PDFNet } = Core;
          const { Color } = Annotations;

          seInsta(instance);
          instance.UI.openElements(["loadingModal"]);

          UI.addEventListener(UI.Events.MULTI_VIEWER_READY, async () => {
            const [documentViewer1, documentViewer2] =
              Core.getDocumentViewers();

            const startCompare = async () => {
              const shouldCompare =
                documentViewer1.getDocument() && documentViewer2.getDocument();

              if (shouldCompare) {
                // Check if both documents loaded before comparing
                const beforeColor = new Color(255, 73, 73, 0.4);
                const afterColor = new Color(21, 205, 131, 0.4);
                const options = {
                  beforeColor,
                  afterColor,
                };

                await documentViewer1.startSemanticDiff(
                  documentViewer2,
                  options
                );
              }
            };
            // const name1 = props.selectedDraftsLink[0]
            //   .split(".com/")[1]
            //   .split(".docx");
            // const name2 = props.selectedDraftsLink[1]
            //   .split(".com/")[1]
            //   .split(".docx");

            const options1 = { filename: `${props.compareFileName[0]}.pdf` };
            const options2 = { filename: `${props.compareFileName[1]}.pdf` };

            documentViewer1.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );
            documentViewer2.addEventListener(
              UI.Events.DOCUMENT_LOADED,
              startCompare
            );

            const iframeDoc = instance.UI.iframeWindow.document;

            //access the container element by id of each view
            const leftView = iframeDoc.getElementById("container1");
            const rightView: any = iframeDoc.getElementById("container2");

            //drop area is the first child of the container when document is not loaded
            leftView.firstChild.style.display = "none";
            rightView.firstChild.style.display = "none";

            const leftCross = iframeDoc.getElementById("header1");
            const rightCross = iframeDoc.getElementById("header2");
            leftCross.lastChild.lastChild.style.display = "none";
            rightCross.lastChild.lastChild.style.display = "none";

            const blob1 = await convertPDF(Core, link1);
            const blob2 = await convertPDF(Core, link2);

            documentViewer1.loadDocument(blob1, options1);
            documentViewer2.loadDocument(blob2, options2);
            instance.UI.closeElements(["loadingModal"]);
          });
          UI.enableFeatures([UI.Feature.MultiViewerMode]);
        });
      }
    }, 200);
    console.log(props.selectedDraftsLink);
  }, [documentContainer.current, props.selectedDraftsLink, link1, link2]);

  const [isDark, setTheme] = useState(false);

  useEffect(() => {}, [isDark]);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };

  const search = () => {
    setSearch(true);
    inta.UI.searchText("", {
      caseSensitive: true,
      wholeWord: true,
    });
  };

  useEffect(() => {
    if (props.draftDetails && props.draftDetails.optional_field) {
      let flatArr = props.draftDetails.optional_field?.flatMap(
        (section: any) => section.field_data
      );
      let keyValuePair: any = flatArr?.reduce((acc: any, curr: any) => {
        if (curr?.dataType == "select") {
          acc = {
            ...acc,
            [curr.fieldName]: curr.fieldValue + " " + curr.fieldLength,
          };
        } else acc = { ...acc, [curr.fieldName]: curr.fieldValue };
        return acc;
      }, {});
      settemplateKeyValue(keyValuePair);
    }
  }, [props.draftDetails]);
  useEffect(() => {}, [isSearch]);
  return (
    <div>
      <Dialog
        fullScreen={true}
        fullWidth={true}
        open={props.openComparisionDrawer}
        onClose={() => {}}
        className="draft-main"
      >
        <DialogTitle>
          <div className="row m-0 p-0">
            <div className="col-md-11 p-0">
              <span style={{ fontSize: "16px" }}>
                <span style={{ marginRight: "10px", cursor: "pointer" }}>
                  <img
                    onClick={() => {
                      props.setComparisionDrawer(false);
                      props.onClose();
                    }}
                    style={{ width: "15px" }}
                    src="/static_images/back-arrow.svg"
                    alt="close_icon"
                  />
                </span>
                Compare Draft
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers className="m-0 pt-16 pb-16 pl-0 pr-0 h-100">
          <div className="h-100">
            <div className="d-flex flex-column h-100" style={{ gap: "20px" }}>
              <div
                className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                style={{
                  marginLeft: "17px",
                  padding: "10px",
                  width: "98%",
                  height: "42px",
                  borderRadius: "100px",
                }}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/settingiconnew.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTheme(!isDark);
                      if (isDark) {
                        lightTheme();
                      } else {
                        darkTheme();
                      }
                    }}
                  />
                  {/* {templateKeyValue && (
                    <img
                      src="/static_images/data-dictionary-icn.svg"
                      className="pl-1 w-10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setshowTemplateField(!showTemplateField);
                      }}
                    />
                  )}
                  {!templateKeyValue && (
                    <img
                      src="/static_images/template-disabled.svg"
                      className="pl-1 w-10"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <img
                    src="/static_images/kdp-icn.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setenableKDP(!enableKDP);
                      Kdp_Parse();
                    }}
                  /> */}
                </div>
                {/* <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/data-dictionary-icn.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {}}
                  />
                  <img
                    src="/static_images/kdp-icn.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setenableKDP(!enableKDP);
                      Kdp_Parse();
                    }}
                  />
                </div> */}
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/serchiconnew.svg"
                    className="pl-1 w-10"
                    onClick={() => {
                      if (isSearch) {
                        inta.UI.closeElements(["searchPanel"]);
                        setSearch(false);
                      } else {
                        search();
                      }
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <div
                ref={documentContainer}
                id="document-viewer"
                className="col-md-12 pr-4"
                style={{ height: "100%" }}
              ></div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
