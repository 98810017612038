import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";

interface Props {
  contractCardContent: any;
  sectionCardContent: any;
  fieldCardContent: any;
  setIsSectionView: any;
  setIsFieldView: any;
}

export const DefaultRenderSectionCard = (props: Props) => {
  const {
    contractCardContent,
    sectionCardContent,
    fieldCardContent,
    setIsSectionView,
    setIsFieldView,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      className="mb-2"
    >
      {contractCardContent.map((row: any, index: number) => (
        <Card
          key={index}
          style={{
            minWidth: "80px",
            fontSize: "14px",
            backgroundColor: "#E5E5E5",
            width: "32%",
            marginTop: "12px",
            height: "190px",
          }}
        >
          <CardContent style={{ fontWeight: 900, color: "#1D1D1D" }}>
            {row.contractSectionTitle}
          </CardContent>
          <CardContent
            style={{
              marginTop: "-20px",
              fontSize: "14px",
              color: "grey",
              fontStyle: "inherit",
            }}
            className="contractSectionContent"
          >
            <Scrollable maxHeight={100}>
              <div
                dangerouslySetInnerHTML={{ __html: row.contractSectionContent }}
              ></div>
            </Scrollable>
          </CardContent>
        </Card>
      ))}
      {sectionCardContent.map((row: any, index: number) => (
        <Card
          key={index}
          style={{
            minWidth: "80px",
            fontSize: "14px",
            backgroundColor: "#E5E5E5",
            marginLeft: "10px",
            width: "32%",
            marginTop: "12px",
            height: "190px",
          }}
        >
          <div className="d-flex justify-content-between">
            <CardContent
              style={{
                fontWeight: 900,
                color: "#1D1D1D",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {row.sectionTitle}
            </CardContent>
            <CardContent
              style={{ fontWeight: 500, textDecoration: "underline" }}
              className="c-primary"
            >
              <p
                className="cursor-pointer mb-0"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setIsSectionView(true);
                }}
              >
                View All
              </p>
            </CardContent>
          </div>
          <CardContent
            style={{
              marginTop: "-20px",
              fontSize: "14px",
              color: "grey",
              fontStyle: "inherit",
              paddingBottom: "0px",
            }}
          >
            <Scrollable maxHeight={100}>
              <div
                dangerouslySetInnerHTML={{ __html: row.sectionContent }}
              ></div>
            </Scrollable>
          </CardContent>
        </Card>
      ))}
      {fieldCardContent.map((row: any, index: number) => (
        <Card
          key={index}
          style={{
            minWidth: "80px",
            fontSize: "14px",
            backgroundColor: "#E5E5E5",
            marginLeft: "10px",
            width: "32%",
            marginTop: "12px",
            height: "190px",
          }}
        >
          <div className="d-flex justify-content-between">
            <CardContent
              style={{
                fontWeight: 900,
                color: "#1D1D1D",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {row.fieldCardTitle}
            </CardContent>
            <CardContent
              style={{ fontWeight: 500, textDecoration: "underline" }}
              className="c-primary"
            >
              <p
                className="cursor-pointer mb-0"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setIsFieldView(true);
                }}
              >
                View All
              </p>
            </CardContent>
          </div>
          <CardContent
            style={{
              marginTop: "-20px",
              fontSize: "14px",
              color: "grey",
              fontStyle: "inherit",
            }}
            // className="contractSectionContent"
          >
            <Scrollable maxHeight={100}>
              <div
                dangerouslySetInnerHTML={{ __html: row.fieldCardContent }}
              ></div>
            </Scrollable>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
