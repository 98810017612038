import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React, { Component } from "react";
import { FilterData } from "../../../Templates/State/templateState";
import HeimdallUtil from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
import { SectionClauseInfo } from "../../State/clauseLibraryState";
import SubSectionListView from "./SubSectionListView";

interface Props {
  projectData: [];
  sectionClauseData: any;
  selectedClause: any;
  updateCheckedSection: any;
  checkedSection: string[];
  updateCheckedClause: any;
  checkedClause: string[];
  sectionName: any;
  checkedTitle: string[];
  updateCheckedTitle: any;
  modifiedDate: string;
  wholeData: any;
  updateSectionClauseSuccess: any;
  isSort: any;
  tagsSorting: any;
  filterKeyVals: FilterData;
  applyFilter: (a: string[], b: string) => void;
}

interface State {}

export default class SectionListView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps: Props) {}

  render() {
    let {
      projectData,
      sectionClauseData,
      selectedClause,
      updateCheckedSection,
      checkedSection,
      updateCheckedClause,
      checkedClause,
      sectionName,
      checkedTitle,
      updateCheckedTitle,
      modifiedDate,
    } = this.props;
    return (
      <Accordion className="section-accordian-parent">
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="section-accordian"
          style={{
            backgroundColor: checkedTitle.length
              ? checkedTitle.filter(
                  (item) => item === sectionClauseData[0].section_name
                ).length
                ? "#F0E1E9"
                : "white"
              : checkedSection.filter(
                  (item) => item === sectionClauseData[0].section_name
                ).length
              ? "#F0E1E9"
              : "white",
            borderTop: "1px solid #C6C6C6",
            borderBottom: "1px solid #C6C6C6",
          }}
        >
          <div
            className="col-md-5"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <div className="row w-100" style={{ flexWrap: "nowrap" }}>
              <img
                onClick={() => {
                  updateCheckedSection(sectionClauseData[0].section_name);
                  if (checkedTitle.length > 1 && checkedSection.length > 1) {
                    if (checkedTitle.length === checkedSection.length) {
                      updateCheckedTitle(sectionClauseData);
                    }
                  }
                }}
                className="clause-folder-icn section-checkbox"
                src={`${
                  checkedTitle.length
                    ? checkedTitle.filter(
                        (item) => item === sectionClauseData[0].section_name
                      ).length
                      ? "/static_images/checkbox-section.svg"
                      : "/static_images/uncheck-section.svg"
                    : checkedSection.filter(
                        (item) => item === sectionClauseData[0].section_name
                      ).length
                    ? "/static_images/checkbox-section.svg"
                    : "/static_images/uncheck-section.svg"
                }`}
                alt="clause-folder"
              />
              <div
                className="col-md-10"
                style={{ whiteSpace: "nowrap", display: "flex", width: "50%" }}
              >
                <img
                  className="clause-folder-icn"
                  src="/static_images/section-folder.svg"
                  alt="clause-folder"
                />
                <label
                  className={"cursor-pointer m-0"}
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {sectionName}
                </label>
              </div>
            </div>
          </div>

          <p className="col-md-2 section-label m-0"></p>
          <div className="col-md-2 w-100 d-flex justify-content-start">
            {/* <div className="section-created-by">
              <p className="m-0">
                {HeimdallUtil.getNameForAvatar(
                  sectionClauseData[0].section_name.created_by
                )}
              </p>
            </div> */}
          </div>
          <p
            className="col-md-1 m-0"
            style={{
              color: "#6D6D6D",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {getDate(modifiedDate)}
          </p>
          <div className="col-md-2">
            <div className="row">
              <div className="col-md-5"></div>
            </div>
          </div>
        </AccordionSummary>
        {sectionClauseData &&
          sectionClauseData.length &&
          sectionClauseData.map((item: any) => {
            return (
              <AccordionDetails
                className="custom-class"
                style={{
                  backgroundColor: checkedClause.filter(
                    (items) => item.id === items
                  ).length
                    ? "#F0E1E9"
                    : "white",
                }}
              >
                <SubSectionListView
                  projectData={projectData}
                  sectionClauseData={item}
                  selectedClause={selectedClause}
                  updateCheckedClause={updateCheckedClause}
                  checkedClause={checkedClause}
                  tagsSorting={this.props.tagsSorting}
                  wholeData={this.props.wholeData}
                  updateSectionClauseSuccess={
                    this.props.updateSectionClauseSuccess
                  }
                  isSort={this.props.isSort}
                  applyFilter={this.props.applyFilter}
                  filterKeyVals={this.props.filterKeyVals}
                />
              </AccordionDetails>
            );
          })}
      </Accordion>
    );
  }
}
