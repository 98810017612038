import React, { useEffect, useState } from "react";
import { History } from "history";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import ApprovalListView from "./ApprovalListView";
import "../Design/approvals.scss";
import CreateApprovalDialog from "./CreateApprovalDialog";
import { getUserFromId, getUsers } from "../../Draft/DA";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { editApprovals, getApprovals } from "../DA";
import { Approval, approvalTypeList, CreateApprovalPostReq, Filter } from "../State/ApprovalState";
import { ContractTypeRes } from "../../ContractTypes/State/ContractTypeState";
import { getContractTypes } from "../../ContractTypes/DA";
import { TabPanel } from "../Component/TabPanel";
import MyApprovalCard from "./MyApproval";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface Props {
  history: History;
  pageWatcher: (page: string) => void;
}
const ApprovalsComponent = (props: Props) => {
  const [loading, setloading] = useState(false);
  const [approvalsList, setapprovalsList] = useState<Approval[]>([]);
  const [openCreateApproval, setOpenCreateApproval] = useState(false);
  const [currentUser, setcurrentUser] = useState<any>({});
  const [filters, setfilters] = useState<Filter>({
    applicable_for: [],
    approval_type: "",
  });
  const [contractTypeList, setcontractTypeList] = useState<ContractTypeRes[]>(
    []
  );
  const [contractTypes, setcontractTypes] = useState<string[]>([]);
  const [loadFromLocalStorage, setloadFromLocalStorage] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    props.pageWatcher("approvals");
    setFiltersFromLocalStorage();
    getCurrentUser();
    getContractType();
  }, []);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTabValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    localStorage.setItem("Approval_Filter", JSON.stringify(filters));
    if (loadFromLocalStorage) {
      getApprovalList();
    }
  }, [filters, loadFromLocalStorage]);

  useEffect(() => {
    if (filters.applicable_for.length > 0) {
      setcontractTypes([...getApplicableForByNames(filters.applicable_for)]);
    }
  }, [contractTypeList]);

  const setFiltersFromLocalStorage = () => {
    setloading(true);
    let response = localStorage.getItem("Approval_Filter");
    if (response != null) {
      let filter = JSON.parse(response) as Filter;
      setfilters({
        ...filters,
        approval_type: filter.approval_type,
        applicable_for: filter.applicable_for,
      });
    }
    setloadFromLocalStorage(true);
    setloading(false);
  };

  const getContractType = async () => {
    try {
      const response = await getContractTypes();
      if (response && response.results.length > 0) {
        setcontractTypeList(response.results);
      } else if (response && response.results.length == 0) {
        setcontractTypeList([]);
      }
    } catch (error) {
      console.error("getContractType", error);
    }
  };

  const getCurrentUser = async () => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      const user = await getUserFromId(user_id);
      if (user != null && user) {
        setcurrentUser(user);
      }
    }
  };

  const getApprovalList = async () => {
    try {
      setloading(true);
      const response = await getApprovals(filters).then(
        (response) => response.results
      );
      if (response.length > 0) {
        setapprovalsList(response);
      } else setapprovalsList([]);
    } catch (error) {
      console.error("getApproval error", error);
    } finally {
      setloading(false);
    }
  };

  const handleContractTypes = (event: any) => {
    const {
      target: { value },
    } = event;
    setcontractTypes(typeof value === "string" ? value.split(",") : value);
    setfilters({
      ...filters,
      applicable_for: getApplicableForByIds(
        typeof value === "string" ? value.split(",") : value
      ),
    });
  };

  const handleApprovalTypeFilter = (event: any) => {
    if (event.target.value == "clear") {
      setfilters({ ...filters, approval_type: "" });
    } else setfilters({ ...filters, approval_type: event.target.value });
  };

  const getApplicableForByIds = (data: string[]) => {
    if (data.length > 0) {
      return data.map((name) => {
        const obj = contractTypeList.find((value) => value.name == name);
        if (obj) {
          return obj.id;
        } else return "";
      });
    } else return [];
  };

  const getApplicableForByNames = (data: string[]) => {
    if (data.length > 0) {
      return data.map((id) => {
        const obj = contractTypeList.find((value) => value.id == id);
        if (obj) {
          return obj.name;
        } else return "";
      });
    } else return [];
  };

  const generateTopHeader = () => {
    return (
      <>
        <p className="stylus_section_header">Approvals</p>
        <div className="row mb-5 align-items-center">
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <FormControl
                  size="small"
                  variant="outlined"
                  className="formControl"
                  fullWidth={true}
                >
                  <InputLabel>Filter by contract type</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={contractTypes}
                    onChange={handleContractTypes}
                    renderValue={(selected: any) => selected.join(", ")}
                    MenuProps={MenuProps}
                    label="Filter by contract type"
                  >
                    {contractTypeList
                      .map((contract) => contract.name)
                      .map((name, index) => (
                        <MenuItem key={index} value={name}>
                          <Checkbox
                            checked={contractTypes.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-3">
                <FormControl
                  size="small"
                  variant="outlined"
                  className="formControl"
                  fullWidth={true}
                >
                  <InputLabel>Filter by approval type</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth={true}
                    label="Filter by approval type"
                    onChange={handleApprovalTypeFilter}
                    value={filters.approval_type}
                  >
                    {approvalTypeList.map((name, index) => (
                      <MenuItem
                        disabled={name == "Adhoc Approval"}
                        key={index}
                        value={name}
                      >
                        {name}
                      </MenuItem>
                    ))}
                    <MenuItem value={"clear"}>Clear Filter</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className="row justify-content-end"
              style={{ marginRight: "20px" }}
            >
              <button
                onClick={() => {
                  setOpenCreateApproval(true);
                }}
                type="button"
                className="btn btn-warning create-btn"
              >
                + Create approval
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleClose = () => {
    setOpenCreateApproval(false);
  };

  const getColor = (type: string, roles: any, isBackGroundColor: boolean) => {
    const owner = roles && roles.includes("owner");
    const collaborator = roles && roles.includes("Collaborators");
    const approver = roles && roles.includes("Approvers");
    const signatory = roles && roles.includes("Signatories");
    if (type == 'approval') {
        if (isBackGroundColor) {
            return owner ? "#FFEEF7" : collaborator ? "#FFEFE4" : approver ? "#D8F7E6" : signatory ? "#EEEDFF" : "#FFEEF7";
        } else {
            return owner ? "#88305F" : collaborator ? "#EE5800" : approver ? "#00692A" : signatory ? "#392494" : "#88305F";
        }
    } else {
        if (isBackGroundColor) {
            return collaborator ? "#FFEFE4" : signatory ? "#EEEDFF" : "#FFEEF7";
        } else {
            return collaborator ? "#EE5800" : signatory ? "#392494" : "#88305F";
        }
    }
}

  const removeApprover = async (id:string,approvalPayload:Approval) => {
    try {
      setloading(true);
      const payload: CreateApprovalPostReq = {
        section_tag: approvalPayload.section_tag,
        field_name: approvalPayload.field_name,
        conditions: approvalPayload.conditions,
        applicable_for: [],
        approvers: approvalPayload.approvers.map((data) => data.id),
        optional_data: approvalPayload.optional_data,
        value: approvalPayload.value,
        approval_name: approvalPayload.approval_name,
        approval_type: approvalPayload.approval_type,
        approval_number: approvalPayload.approval_number,
        include_all:approvalPayload.include_all,
        is_case_sensitive:approvalPayload.is_case_sensitive,
        scope:approvalPayload.scope
    }
      let contractTypes = [...approvalPayload.applicable_for];
      let index = contractTypes.findIndex((data) => data.id == id);
      if(index > -1){
        contractTypes.splice(index,1);
      }else return;
      payload.applicable_for = contractTypes.map((data) => data.id);
      const response = await editApprovals(payload, approvalPayload.id);
      if (response) {
        setTimeout(() => {
          getApprovalList();
        }, 500);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  }

  return (
    <div className="row">
      {loading && <CircularProgress className="loader" />}
      <div className="col-md-1" style={{ zIndex: 2, flex: "0 0 7.333333%" }}>
        <SideNavbar history={props.history} />
      </div>
      <div className="col-md-11 mt-6">
        {generateTopHeader()}
        <div className="ROOT">
          <AppBar position="static">
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
            >
              <Tab disableRipple label="Approval Matrix" {...a11yProps(0)} />
              {/* <Tab disableRipple label="My Approval" {...a11yProps(1)} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <ApprovalListView
              currentUser={currentUser}
              getApprovals={getApprovalList}
              approvalsList={approvalsList}
              getColor={getColor}
              removeApprover={removeApprover}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MyApprovalCard 
                 currentUser={currentUser}
                 getApprovals={getApprovalList}
                 approvalsList={approvalsList}
            />
          </TabPanel>
        </div>
      </div>
      {openCreateApproval && (
        <CreateApprovalDialog
          edit={false}
          approvalData={null}
          count={approvalsList.length}
          currentUser={currentUser}
          onClose={handleClose}
          open={openCreateApproval}
          getApprovals={getApprovalList}
          getColor={getColor}
        />
      )}
    </div>
  );
};

export default ApprovalsComponent;
