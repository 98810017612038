import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import { StoreTree } from "../../Utils/MainReducer/mainReducer";
import ClauseLibraryActionGenerator from "../Actions/gen";
import ClauseLibrary from "../Component/clauseLibrary";
import ClauseLibrary2 from "../Component/ClauseLibrary2";
import { ClauseInfo } from "../State/clauseLibraryState";

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    sectionClauseList: appState.clauseLibrary.sectionClauseList,
    updateSectionClauseList: appState.clauseLibrary.updateSectionClauseList,
    collapsedFolderIds: appState.clauseLibrary.collapsedFolderIds,
    clauseLibraryData: appState.clauseLibrary.clauseLibraryData,
    selectedClauseData: appState.clauseLibrary.selectedClauseData,
    clausesData: appState.clauseLibrary.clausesData,
    clauseLibraryLoader: appState.clauseLibrary.clauseLibraryLoader,
    sectionDictionaryData: appState.clauseLibrary.sectionDictionaryList,
    totalCount: appState.clauseLibrary.totalCount,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
    saveCollapsedFolderIds: (collapsedFolderIds: string[]) =>
      dispatch(
        ClauseLibraryActionGenerator.saveCollapsedFolderIds(collapsedFolderIds)
      ),
    createFolderHeading: (folderName: string) =>
      dispatch(ClauseLibraryActionGenerator.createFolderHeading(folderName)),
    createFolderSubHeading: (folderName: string, parentId: string) =>
      dispatch(
        ClauseLibraryActionGenerator.createFolderSubHeading(
          folderName,
          parentId
        )
      ),

    // createSectionClause: (
    //   section_name: string,
    //   clause_name: string,
    //   clause_type: string,
    //   clause_source: string,
    //   clause_text: string,
    //   description: string,
    //   creation_type: string,
    //   tags: any
    // ) =>
    //   dispatch(
    //     ClauseLibraryActionGenerator.createSectionClause(
    //       section_name,
    //       clause_name,
    //       clause_type,
    //       clause_source,
    //       clause_text,
    //       description,
    //       creation_type,
    //       tags
    //     )
    //   ),
    updateSectionClauseSuccess: (output: any) =>
      dispatch(ClauseLibraryActionGenerator.updateSectionClauseSuccess(output)),
    getFolderHeading: () =>
      dispatch(ClauseLibraryActionGenerator.getFolderHeading()),
    getSectionDictionary: () =>
      dispatch(ClauseLibraryActionGenerator.getSectionDictionary()),
    saveSelectedClauseData: (selectedClauseData: ClauseInfo) =>
      dispatch(
        ClauseLibraryActionGenerator.saveSelectedClauseData(selectedClauseData)
      ),
    getFolderSubHeading: (id: string) =>
      dispatch(ClauseLibraryActionGenerator.getFolderSubHeading(id)),
    deleteFolder: (folderId: string) =>
      dispatch(ClauseLibraryActionGenerator.deleteFolder(folderId)),
    deleteClause: (clauseId: string, folderId: string) =>
      dispatch(ClauseLibraryActionGenerator.deleteClause(clauseId, folderId)),
    getClauseLibraryData: () =>
      dispatch(ClauseLibraryActionGenerator.getClauseLibraryData()),
    getClausesData: (clauseIds: string[]) =>
      dispatch(ClauseLibraryActionGenerator.getClausesData(clauseIds)),
    getSectionClauseData: (page: number, type: string) =>
      dispatch(ClauseLibraryActionGenerator.getSectionClause(page, type)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClauseLibrary2);
