import { User } from "../../../HeimdallChild/HeimdallUtil/heimdallUtil";

export default interface ClauseErrorManagementState {
  clauseErrorData: ClauseErrorList[];
  clauseErrorList: ClauseErrorList[];
}

export interface ClauseErrorData {
  label: string;
  status?: string;
}

export interface ClauseErrorList {
  label: string;
  status?: string;
}

export function defaultClauseErrorManagementState(): ClauseErrorManagementState {
  return {
    clauseErrorData: [],
    clauseErrorList: [],
  };
}
