export interface ContractType {
    name: string;
    displayName: string;
};

export const defaultContractType: ContractType = {
    name: '',
    displayName: '',
}

export interface ContractTypeRes {
    name: string;
    displayName: string;
    comments: string;
    id: string;
    created_on: string;
};

export const defaultContractTypeRes: ContractTypeRes = {
    name: '',
    displayName: '',
    id: '',
    comments: '',
    created_on: ''
}