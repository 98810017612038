import { Button, Checkbox, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AddTagfromTagLibrary, updateTagInTemplate } from '../DA';
import { Tag, TemplateData } from '../State/templateState';

interface Props {
    open:boolean;
    handleClose: (data:any) => void;
    tags:Tag[];
    template:TemplateData;
    getTags:() => void;
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddTags = (props:Props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tagList, settagList] = useState<Tag[]>([]);
    const [selectedTags, setselectedTags] = useState<string[]>([]);
    const [toggleAdd, settoggleAdd] = useState(false);
    const [tagName, settagName] = useState("");

    useEffect(() => {
      settagList([...props.tags]);
      return () => {
        settagList([]);
      }
    }, [props.tags]);
    
    useEffect(() => {
        setOpen(props.open);
        setLoading(false);
    }, [props.open]);

    useEffect(() => {
      if(props.template && props.template.tags && props.template.tags.length){
        let selectedTags = props.template.tags.map((tag) => tag.tag_name);
        setselectedTags([...selectedTags]);
      }
    }, [props.template])
    

    const handleClose = () => {
        setOpen(false);
        props.handleClose(null);
    };

    const handleSubmit = async () => {
      try {
        setLoading(true);
        let tags:any[] = selectedTags.map((tag) => {
          let res = tagList.find((tagData) => tagData.tag_name == tag);
          if(res){
            return res.id
          } 
        }).filter((data:any) => {
          if (data) return data;
        });
        const response = await updateTagInTemplate(props.template.id || '',tags);
        if(response){
          setOpen(false);
          props.handleClose(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    const handleChange = (event: any) => {
        const {
          target: { value },
        } = event;
        setselectedTags(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const addTag = async () => {
      try {
        if(!tagName){
          return;
        }
        if(tagList.findIndex((value) =>value.tag_name == tagName) > -1){
          return;
        }
        setLoading(true);
        const response = await AddTagfromTagLibrary(tagName);
        if(response){
          settagList([...tagList,response]);
          setselectedTags([...selectedTags,response.tag_name])
          settoggleAdd(!toggleAdd);
          settagName("");
          props.getTags();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    const handleDeleteTag = (name:string) => {
      let tagList = [...selectedTags];
      let index = tagList.findIndex(value => value == name);
      if(index > -1){
        tagList.splice(index,1);
      }
      setselectedTags([...tagList]);
    }
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle className='riverus-dialog-header'>
            <div className="row">
                <div className="col-md-11">Add Tag</div>
                <div onClick={handleClose} className="col-md-1 close-icn"><img src="/static_images/close-analysismodal-icn.svg" alt="close_icon" /></div>
            </div>
        </DialogTitle>
        <DialogContent>
        {loading && <CircularProgress className="loader" />}
          <div className="row mt-3 mb-3">
            <div className="col-md-12">
              <div
                className="df justify-content-between"
                style={{ width: "243px" }}
              >
                <DialogContentText className="riverus-field-title">
                  Tags
                </DialogContentText>
                <Button
                  onClick={() => settoggleAdd(!toggleAdd)}
                  color="secondary"
                >
                  + Add New
                </Button>
              </div>
              <FormControl 
                  variant="outlined"
                  className="riverus-text-box mb-3"
                  size="small"
                  fullWidth={true}>
                  <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                  <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedTags}
                  onChange={handleChange}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected:any) => selected.join(', ')}
                  MenuProps={MenuProps}
                  label="Tag"
                  >
                  {tagList.map((tag) => (
                      <MenuItem key={tag.id} value={tag.tag_name}>
                      <Checkbox checked={selectedTags.indexOf(tag.tag_name) > -1} />
                      <ListItemText primary={tag.tag_name} />
                      </MenuItem>
                  ))}
                  </Select>
              </FormControl>
              {toggleAdd && <div className='row mb-3'>
                    <div className="col-md-5">
                    <TextField
                    size="small"
                    value={tagName}
                    onChange={(event: any) =>
                      settagName(event.target.value)
                    }
                    fullWidth={true}
                    id="outlined-basic"
                    style={{width:"243px"}}
                    variant="outlined"
                  />
                    </div>
                    <div className="col-md-6">
                        <Button className='create-btn ml-2' onClick={addTag}>Add to Tag List</Button>
                    </div>
                </div>}
                <div className="df">
                  {selectedTags.length > 0 && selectedTags.map((tag,index) => (
                    <Chip className='ml-2' label={tag} variant="default" onDelete={() => handleDeleteTag(tag)} />
                  ))}
                </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
            <Button className='create-btn' onClick={handleSubmit}>Add/Update Tags</Button>
        </DialogActions>
    </Dialog>
  )
}

export default AddTags