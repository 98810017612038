import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import Select from "react-select";

interface Props {
  showModal: boolean;
  setShowModal: (flag: boolean) => void;
  childFileList: any[];
  handleAttachFileListChange: (e: any[]) => void;
  fileValues: any[];
}
const ChildFileListModal = (props: Props) => {
  const { showModal, setShowModal, childFileList, fileValues } = props;
  const [values, setValues] = useState<any>(props.fileValues);
  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      fullscreen={"xl-down"}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Row>
            <Col>Attach Support Documents </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Select
                value={values}
                isMulti
                name="Attachment"
                className="basic-multi-select"
                classNamePrefix="Select Files"
                onChange={(e) => {
                  setValues(e);
                }}
                options={childFileList}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button
              size="sm"
              onClick={() => {
                props.handleAttachFileListChange(values);
                setShowModal(false);
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ChildFileListModal;
