import React from "react";
import {
  getUploadedTime,
  humanFileSize,
  truncateFileName,
} from "../../Utils/DataModifierUtil/dataModUtil";
import { FAILED, DONE } from "../../Constants/const";
import { FileInfo, FileState, FileList } from "../State/uploadState";
import { History } from "history";
import { isNullOrUndefined } from "is-what";
import DeleteFileModal from "../../UniversalComponents/Modals/DeleteFileModal/deleteFileCon";
import Tooltip from "@material-ui/core/Tooltip";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import UploadHeader from "./uploadHeader";
import moment from "moment";

interface Props {
  file: FileList;
  history: History;
  from: string;
  onItemCheck: any;
  SelectedList: any;

  saveDeleteDetails: (documentName: string, uniqueFileId: string) => void;
}
interface State {
  isChecked: boolean;
}

export default class FileListComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  render() {
    let { file, onItemCheck } = this.props;

    return (
      <>
        <div
          className={`row  ${file.selected && "row-checked-color"}`}
          style={{ borderBottom: "1px solid grey" }}
        >
          <div className="col-md-2 py-2   ">
            <div className="file-name-style-upload">
              <input
                type="checkbox"
                name=""
                id=""
                className=" myinput mr-3"
                style={{
                  color: "#6D6D6D",
                  height: "16px",
                  width: "16px",
                  marginTop: "3px",
                }}
                onChange={(e) => onItemCheck(e, file)}
                checked={file.selected}
              />

              <Tooltip
                title={file.file_name}
                placement="right-end"
                style={{ cursor: "pointer" }}
              >
                <span
                  className="dotted-line"
                  onClick={() =>
                    window.open(
                      "/document/documentlibrary/" + btoa(file.id.toString()),
                      "_blank"
                    )
                  }
                >
                  {file.uploaded_type === "Contract" ? (
                    <img
                      src="/static_images/Vector (1).svg"
                      className="pr-1  pb-1"
                    />
                  ) : file.uploaded_type === "Support Document" ? (
                    <img
                      src="/static_images/iconSupport.svg"
                      className="pr-1  pb-1"
                    />
                  ) : file.uploaded_type === "Amendment" ? (
                    <img
                      src="/static_images/Union.svg"
                      className="pr-1  pb-1"
                    />
                  ) : (
                    ""
                  )}

                  {truncateFileName(file.file_name)}
                </span>
              </Tooltip>
            </div>
          </div>
          {/* style={{background : "#FFEEF7", border:" 0.5px solid #88305F", width:"28px", height:"28px", borderRadius: "100px"}} */}
          <div className="col-md-2 py-2">
            <div className="  col-md-1  m-auto  uploadByDiv">
              <span className="initials uploadByIcon ">
                {HeimdallUtil.getNameForAvatar2(
                  file?.creator_details !== null
                    ? `${file?.creator_details.first_name} ${file?.creator_details.last_name}`
                    : ""
                )}
                {/* {file?.creator_details.first_name} */}
                {/* {file?.creator_details.last_name} */}
              </span>
            </div>
          </div>
          <div className="col-md-2 py-2 d-flex justify-content-center">
            {" "}
            <div className=" uploadedby-style-upload m-auto">
              {/* {getUploadedTime(file.created_on)} */}
              {moment(file.created_on).format("DD-MMM-YYYY")}
            </div>
          </div>
          <div className="col-md-1 py-2">
            <div className=" filesize-style d-flex justify-content-center">
              {humanFileSize(file.file_size, true)}
            </div>
          </div>
          <div className="col-md-2 py-2 d-flex justify-content-center">
            {file.status === "Not implemented" ? (
              <div className="uploadedby-style-upload"> Upload Success</div>
            ) : (
              <div
                className="uploadedby-style-upload"
                style={{ color: this.getColor(file.status) }}
              >
                {" "}
                {file.status}
              </div>
            )}
            {/* <div className="uploadedby-style-upload">{file.status}</div> */}

            {/* {file.fileState.progressState.process} */}
          </div>
          <div
            className="col-md-1 py-2 d-flex justify-content-center"
            style={{ paddingLeft: "2px" }}
          >
            <div className=" tooltip ">
              {/* {file.status === "Not implemented" ? ( */}
                <Tooltip title={"Delete file"} placement="right-end">
                  <img
                    onClick={() =>
                      this.displayDeleteModal(file.file_name, file.id)
                    }
                    style={{ cursor: "pointer" }}
                    src="/static_images/Delete_upload.svg"
                    alt="delete-icn"
                  />
                </Tooltip>
              {/* // ) : (
              //   <img src="/static_images/Delete_upload.svg" alt="delete-icn" />
              // )} */}
            </div>
            <button
              style={{ display: "none" }}
              type="button"
              data-toggle="modal"
              data-target="#deleteModal"
              id="deleteButton"
            ></button>
          </div>
          <div
            className="col-md-2 py-2 d-flex justify-content-center"
            style={{ paddingLeft: "2px" }}
          >
            <div className="uploadedby-style-upload"> OK</div>
          </div>
        </div>
        {this.props.file.status !== 'Done' && 
        (
          <div
            className="row nanobar"
            style={{ width: this.setNanoBarWidth(), color: "#88305f" }}
          ></div>
        )}
        <DeleteFileModal />
      </>
    );
  }

  setNanoBarWidth() {
    return this.props.file.percentage + "%";
  }

  displayDeleteModal = (name: string, fileId: string) => {
    this.props.saveDeleteDetails(name, fileId);
    let link = document.getElementById("deleteButton");
    !isNullOrUndefined(link) && link.click();
  };

  getColor(process: any) {
    let { file } = this.props;
    process = process.toUpperCase();
    /*let process = file.fileState.progressState.process.toUpperCase();*/
    switch (process) {
      case "UPLOADING": {
        return "#FFAC5F";
      }
      case "UPLOADED": {
        return "#FFAC5F";
      }
      case "PROCESSING": {
        return "#FFAC5F";
      }
      case "FAILED": {
        return "#FF685F";
      }
      case "DONE": {
        return "#43C888";
      }
      case "NOT STARTED": {
        return "#FF685F";
      }
      default: {
        return "#8D8D8D";
      }
    }
  }

  /*goToIndividualContract(fileState: FileState) {
    if (fileState.progressState.process === "Done") {
      let fileId = fileState.fileId as any;
      this.props.history.push(
        "/document/" + this.props.from + "/" + btoa(fileId)
      );
    }
  }*/
  goToIndividualContract(FileList: FileList) {
    let fileId = FileList.id;
    // console.log(fileId, "FieldID");

    this.props.history.push(
      "/document/" + this.props.from + "/" + btoa(fileId)
    );
  }
}
