import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Theme,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineReload } from "react-icons/ai";
import { BsArrowLeft, BsDownload, BsTrash } from "react-icons/bs";
interface Props {
  open: boolean;
  onClose: () => void;
  setopenAddAssigneeDialog: any;
  specificDraft: any;
  onPatch: any;
  setallspreaded: any;
  allspreaded: any;
  onreload: any;
  setonreload: any;
  showEdit: boolean;
}
const AssignedToDialog = (props: Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [isAllChecked, setAllChecked] = useState<any>(false);

  const [mastercheck, setmastercheck] = useState(false);
  const [selectedlist, setselectedlist] = useState([]);
  const [collo, setcollo] = useState([]);
  const [sign, setSign] = useState([]);
  const [pre_sign, setPreSign] = useState<any>([]);
  const [approval, setApproval] = useState([]);
  const [currentData, setCurrentData] = useState<any>();
  const [snackbarOpen, setSnackbarClose] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const handleClose = () => {
    props.onClose();
  };
  useEffect(() => {
    const collobrater = props?.specificDraft?.collaborators
      ? props?.specificDraft?.collaborators.map((i: any) => {
          i["assigned_role"] = ["Collaborators"];
          return i;
        })
      : [];
    const signatories = props.specificDraft?.signatories.length
      ? props.specificDraft?.signatories.map((i: any) => {
          i["assigned_role"] = ["Signatories"];
          return i;
        })
      : [];
    const approvers =
      props.specificDraft?.approvalTypes[2] &&
      props.specificDraft?.approvalTypes[2].approvers?.map((i: any) => {
        // i["role"]=["Approvers"]
        return i;
      });

    const pre_signatories = props.specificDraft?.pre_signatories?.length
      ? props.specificDraft?.pre_signatories?.map((i: any) => {
          i["assigned_role"] = ["Pre Signatories"];
          return i;
        })
      : [];

    if (!props.specificDraft?.approvalTypes[2]) {
      const spreadeddata = [...collobrater, ...signatories, ...pre_signatories];
      props.setallspreaded(spreadeddata);
    } else {
      const spreadeddata = [
        ...collobrater,
        ...props.specificDraft?.approvalTypes[2]?.approvers,
        ...signatories,
        ...pre_signatories,
      ];
      // const unique = spreadeddata?.filter(
      //   (value:any, index:any, self:any) =>
      //     index ===
      //     self?.findIndex(
      //       (t:any) =>
      //         t.username === value.username && t?.role.includes(value.role[0])
      //     )
      // );
      // console.log(unique, "hhhhhhh");
      props.setallspreaded(spreadeddata);
    }
  }, [props.onreload, props.specificDraft]);

  useEffect(() => {
    const data: any = localStorage.getItem("userData");
    const userDatanew = JSON.parse(data);
    const collo = userDatanew.users.filter((item: any) => {
      if (item.roles.includes("Collaborators")) {
        return item.username;
      }
    });
    const Approval = userDatanew.users.filter((item: any) => {
      if (item.roles.includes("Approvers")) {
        return item.username;
      }
    });
    const sign = userDatanew.users.filter((item: any) => {
      if (item.roles.includes("Signatories")) {
        return item.username;
      }
    });
    const preSign = userDatanew.users.filter((item: any) => {
      if (item.roles.includes("Pre Signatories")) {
        return item.username;
      }
    });
    setcollo(collo.map((val: any) => val.id));
    setApproval(Approval.map((val: any) => val.id));
    setSign(sign.map((val: any) => val.id));
    setPreSign(preSign.map((val: any) => val.id));
  }, []);

  useEffect(() => {
    setCurrentData(props.specificDraft);
  }, [props.specificDraft]);
  const [editUser, setEditUser] = React.useState<string[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<any>();

  const handleChange = (user: any, index: never, target: any) => {
    let newdata = [...props.allspreaded];
    const filterparticular = newdata[index];
    // const values=Object.values(data)
    const filter = props.allspreaded?.filter(
      (item: any) =>
        item.assigned_role.includes(target) &&
        item.username === filterparticular.username
    );
    if (filter.length === 0) {
      newdata[index].assigned_role = [target];
      props.setallspreaded(newdata);
    } else {
      setSnackbarClose(true);
    }
  };
  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => setSnackbarClose(false)}
      >
        OK
      </Button>
    </React.Fragment>
  );

  const onMasterCheck = (e: any) => {
    const { checked } = e.target;
    if (checked) {
      let tempList = [...props.allspreaded];
      const a: any = tempList.map((user: any, index: any) => index);
      setselectedlist(a);
      setmastercheck(true);
    } else {
      setselectedlist([]);
      setmastercheck(false);
    }
  };

  const addAssignee = (id: any, isAllChecked: boolean) => {
    if (typeof id === "string") {
      if (selectedAssignee && selectedAssignee.length > 0) {
        if (!selectedAssignee.includes(id)) {
          setSelectedAssignee([...selectedAssignee, id]);
        } else {
          const filteredData = selectedAssignee.filter((item: any) => {
            return item !== id;
          });
          setSelectedAssignee(filteredData);
        }
      } else {
        setSelectedAssignee([id]);
      }
    } else {
      if (!isAllChecked) {
        setSelectedAssignee([...id]);
      } else {
        setSelectedAssignee([]);
      }
    }
  };
  const handleSelect = (index: never) => {
    const seletctList = [...selectedlist];
    let tempList = [...props.allspreaded];
    const a: any = tempList.map((user: any, index: any) => index);
    if (selectedlist.includes(index)) {
      const data: any = seletctList.filter((val: any) => val !== index);
      setselectedlist(data);
      setmastercheck(false);
    } else {
      const data = [...seletctList, index];
      setselectedlist(data);
      if (data.length == tempList.length) {
        setmastercheck(true);
      } else {
        setmastercheck(false);
      }
    }
  };
  const handleDelete = () => {
    const seletctList: any = [...selectedlist];
    let tempList: any = [...props.allspreaded];
    let getSelectedData: any = tempList.filter((val: any, index: never) => {
      if (seletctList.includes(index)) {
        return val;
      }
    });
    const selectedColl = getSelectedData
      .filter((val: any) => {
        if (val.assigned_role.includes("Collaborators")) {
          return val;
        }
      })
      ?.map((item: any) => item.id);

    const selectedaprover = getSelectedData
      .filter((val: any) => {
        if (val.assigned_role.includes("Approvers")) {
          return val;
        }
      })
      ?.map((item: any) => item.id);

    const selectedsign = getSelectedData
      .filter((val: any) => {
        if (val.assigned_role.includes("Signatories")) {
          return val.id;
        }
      })
      ?.map((item: any) => item.id);

    const selectedpresign = getSelectedData
      .filter((val: any) => {
        if (val.assigned_role.includes("Pre Signatories")) {
          return val.id;
        }
      })
      ?.map((item: any) => item.id);

    let collaborators = [...currentData?.collaborators];
    let signatories = [...currentData?.signatories];
    let preSignatories = [...currentData?.pre_signatories];
    let approvers = [
      ...props.specificDraft?.approvalTypes[
        props.specificDraft?.approvalTypes.length - 1
      ]?.approvers,
    ];
    let updatedColl: any = [];
    updatedColl = collaborators.length
      ? collaborators
          .filter((val: any) => {
            if (selectedColl.includes(val.id)) {
              return;
            } else {
              return val;
            }
          })
          ?.map((item: any) => item.id)
      : [];
    let updatedsign: any = [];
    updatedsign = signatories.length
      ? signatories?.filter((val: any) => {
          if (selectedsign.includes(val.id)) {
            return;
          } else {
            return val;
          }
        })
      : [];

    let updatedpresign: any = [];
    updatedpresign = preSignatories.length
      ? preSignatories?.filter((val: any) => {
          if (selectedpresign.includes(val.id)) {
            return;
          } else {
            return val;
          }
        })
      : [];
    let updatedaprover: any = [];
    updatedaprover = approvers.length
      ? approvers?.filter((val: any) => {
          if (selectedaprover.includes(val.id)) {
            return;
          } else {
            return val;
          }
        })
      : [];
    let approvalTypes = [...currentData?.approvalTypes];
    if (approvalTypes.length == 3) {
      approvalTypes[1].approvers = updatedaprover;
      approvalTypes[2].approvers = updatedaprover;
    }
    let payload = {
      approvalTypes: approvalTypes,
      collaborators: updatedColl,
      signatories: updatedsign,
      pre_signatories: updatedpresign,
    };
    const type = currentData.docID.split("");
    props.onPatch(currentData.id, payload, type[0] + type[1] + type[2]);
    setmastercheck(false);
    setselectedlist([]);
  };
  const handleSave = () => {
    const filterapproval = props.allspreaded?.filter((item: any) =>
      item.assigned_role.includes("Approvers")
    );
    const collab = props.allspreaded?.filter((item: any) =>
      item.assigned_role.includes("Collaborators")
    );
    const signatory = props.allspreaded?.filter((item: any) =>
      item.assigned_role.includes("Signatories")
    );
    const pre_signatory = props.allspreaded?.filter((item: any) =>
      item.assigned_role.includes("Pre Signatories")
    );

    let payload = {
      approvalTypes: [
        props.specificDraft?.approvalTypes[0],
        {
          approval_sequence: "All",
          approval_type_name: "Adhoc",
          approvers: [...filterapproval],
        },
        {
          approval_sequence: "All",
          approval_type_name: "Conditional",
          approvers: [...filterapproval],
        },
      ],
      collaborators: collab?.map((val: any) => val.id),
      signatories: signatory,
      pre_signatories: pre_signatory,
    };
    const type = props.specificDraft.docID.split("");
    props.onPatch(props.specificDraft.id, payload, type[0] + type[1] + type[2]);
    setEdit(false);
  };

  useEffect(() => {}, [isAllChecked, editUser, isEdit]);

  return (
    <Drawer anchor={"right"} open={true} onClose={handleClose}>
      <div className="container support-doc">
        {loading && <CircularProgress className="loader" />}
        <div className="row align-items-center">
          <div className="col-md-4 df align-items-center">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft onClick={handleClose} />
            </IconContext.Provider>
            <p className="m-0 ml-4 fw" style={{ fontSize: "16px" }}>
              Assign People
            </p>
          </div>
          <div
            className={`${
              props.showEdit ? "col-md-6" : "col-md-8"
            } text-align-end pr-1`}
          >
            <button
              type="button"
              onClick={() => {
                props.setopenAddAssigneeDialog(true);
              }}
              className="btn btn-warning create-btn"
            >
              <img
                src="/static_images/add-clause.svg"
                alt="refresh"
                className="mr-1"
              />
              Add New Person
            </button>
          </div>
          {props.showEdit && (
            <div className="col-md-2 text-align-end pl-0">
              <button
                type="button"
                onClick={() => {
                  if (!isEdit) {
                    setEdit(true);
                  } else {
                    handleSave();
                  }
                }}
                className="btn btn-warning create-btn"
              >
                {!isEdit ? "Edit Role" : "Save Role"}
              </button>
            </div>
          )}
        </div>
        <div
          className="row justify-content-between mt-3"
          style={{ height: "33px" }}
        >
          <div className="col-md-12 mb-2">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {/* {selectedAssignee ? (
                selectedAssignee.length ? ( */}
              {selectedlist ? (
                selectedlist?.length ? (
                  <IconContext.Provider
                    value={{
                      color: "#FF685F",
                      className: "svg-delete-icon cp",
                    }}
                  >
                    <BsTrash onClick={() => handleDelete()} />
                  </IconContext.Provider>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <div className="row list-view-header support-doc__table-header">
          <div
            className="col-md-1 df align-items-center"
            style={{ maxWidth: "6%" }}
          >
            <input
              style={{ width: "15px", height: "15px", accentColor: "#88305F" }}
              type="checkbox"
              name="check"
              id="check"
              checked={mastercheck}
              onClick={
                (e: any) => onMasterCheck(e)
                //   {
                //   setAllChecked(!isAllChecked);
                //   let docID: any = [];
                //   props.approverType.length &&
                //     props.approverType.map((item: any) => {
                //       docID.push(item.id);
                //     });
                //   addAssignee(docID, isAllChecked);
                // }
              }
            />
          </div>
          <div className="col-md-3">Name</div>
          <div className="col-md-5" style={{ textAlign: "center" }}>
            Role
          </div>
          <div className="col-md-3">Email </div>
        </div>
        {props.allspreaded.length > 0 &&
          props.allspreaded?.map((doc: any, index: never) => {
            if (doc?.assigned_role?.includes("Signatories")) {
              doc.assigned_role[0] = "Signatories";
            } else if (doc?.assigned_role?.includes("Approvers")) {
              doc.assigned_role[0] = "Approvers";
            } else if (doc?.assigned_role?.includes("Collaborators")) {
              doc.assigned_role[0] = "Collaborators";
            }
            const id: any = doc?.id;
            return (
              <div
                key={index}
                className={`row version-history__table-list ${
                  selectedlist.length && selectedlist?.includes(index)
                    ? // selectedAssignee && selectedAssignee.includes(doc.id)
                      "selected-draft"
                    : ""
                }`}
                style={{
                  backgroundColor: index % 2 != 0 ? "white" : "#FAFAFA",
                  color: "#6D6D6D",
                }}
              >
                <div className="col-md-1 df align-items-center p-2">
                  <input
                    style={{
                      width: "15px",
                      height: "15px",
                      accentColor: "#88305F",
                    }}
                    type="checkbox"
                    name="check"
                    id="check"
                    checked={
                      selectedlist.length && selectedlist?.includes(index)
                        ? // selectedAssignee && selectedAssignee.includes(doc.id)
                          true
                        : false
                    }
                    // onClick={() => addAssignee(doc.id, false)}
                    onClick={() => handleSelect(index)}
                  />
                </div>
                <div className="col-md-3 df align-items-center p-0">
                  {doc.username}
                </div>
                <div className="col-md-5 c-grey p-0">
                  <div>
                    <Box>
                      <FormControl
                        style={{ width: "65%" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel
                          id="demo-simple-select-label"
                          style={{ color: "#88305F" }}
                        >
                          Select role
                        </InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={doc?.assigned_role[0]}
                          disabled={!isEdit}
                          // defaultValue={doc.roles[0]}
                          onChange={(e) => {
                            // doc.roles[0] = e.target.value;
                            handleChange(doc, index, e.target.value);
                          }}
                          label="Select role"
                        >
                          {collo?.find((item) => id == item) && (
                            <MenuItem value={"Collaborators"}>
                              {"Collaborators"}
                            </MenuItem>
                          )}
                          {approval?.find((item) => id == item) && (
                            <MenuItem value={"Approvers"}>
                              {"Approvers"}
                            </MenuItem>
                          )}
                          {sign?.find((item) => id == item) && (
                            <MenuItem value={"Signatories"}>
                              {"Signatories"}
                            </MenuItem>
                          )}
                          {pre_sign?.find((item: any) => id == item) && (
                            <MenuItem value={"Pre Signatories"}>
                              {"Pre Signatories"}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
                <div className="col-md-3 p-0">{doc.email}</div>
              </div>
            );
          })}
      </div>

      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        message="This role is already Assign for this approval"
        key="bottomCenter"
        action={action}
        // onClose={handleSnackBarClose}
      />
    </Drawer>
  );
};

export default AssignedToDialog;
