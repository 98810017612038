import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { deleteS3Template, deleteTemplate } from "../DA";
import "../Design/template.scss";
import { TemplateData } from "../State/templateState";
interface Props {
  open: boolean;
  handleClose: () => void;
  getTemplates: () => void;
  selectedTemplates: TemplateData[];
}
const DeleteTemplate = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setOpen(props.open);
    setLoading(false);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };
  const handleSubmit = async () => {
    try {
      if (props.selectedTemplates.length > 0) {
        setLoading(true);
        let response: any = await deleteTemplate(props.selectedTemplates.map((data) => data.id || ""));
        if (response.status == 204 || response.status == 200) {
          setLoading(false);
          handleClose();
          setTimeout(() => {
            props.getTemplates();
          }, 500);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("handleSubmit error ", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="riverus-dialog-header">
        <div className="row">
          <div className="col-md-11">Delete Template</div>
          <div onClick={handleClose} className="col-md-1 close-icn">
            <img
              src="/static_images/close-analysismodal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {loading && <CircularProgress className="loader" />}
        <div className="row">
          <div className="col-md-12">
            <p>Are you sure you want to delete these templates ?</p>
            <ol>
              {props.selectedTemplates.length > 0 &&
                props.selectedTemplates.map((template, index) => (
                  <li style={{ listStyle: "auto" }} key={index}>
                    {template.name}
                  </li>
                ))}
            </ol>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
        >
          Cancel
        </Button>
        <Button className="create-btn" onClick={handleSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTemplate;
