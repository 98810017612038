import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@material-ui/core";
import React, { Component, createRef, useState } from "react";
import { getUserUploads } from "../../UniversalComponents/Filter/DA";
import FilterInputModal from "../../UniversalComponents/Filter/filterInputModal";
import {
  BasicdocumentLibraryFileInfo,
  BasicFileInfo,
} from "../State/documentLibraryState";
import { getTagIconPath } from "./Utils/libraryUtils";

interface Props {
  archiveData: any;
  onMasterCheck: any;
  MasterChecked: any;
  applySort: any;
  applyFilter: any;
  FilterKeyVals: any;
  prodectData: any;
  addTagContractType: any;
  achiveUpdate: any;
  TableData: any;
  contractPageCount: number;
  groupData: any;
}

interface State {
  // sortBy: string;
  // titleOrder: boolean;
  // dateOrder: boolean;
  isActive: boolean;
  nameSort: any;
  filterDetails: any;
  filterControl: any;
  ContartType: any;
  contractList: any;
  project: any;
  projectList: any;
  contractTypeList: any;
  isPreview: any;
  preview: string;
  search: string;
  sortName:string;
  isTitlePreview: boolean;
}

export default class DocumentLibraryHeader extends Component<Props, State> {
  ContartType = createRef<HTMLInputElement>();
  project = createRef<HTMLInputElement>();
  preview: any = createRef();
  parties = createRef<HTMLInputElement>();
  titlePreview = createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);

    this.state = {
      // sortBy: 'date', // date or title
      // titleOrder: true, // false == descending or true == ascending
      // dateOrder: false, // false == descending or true == ascending
      isActive: false,
      nameSort: true,
      filterDetails: {},
      filterControl: {},
      ContartType: [],
      contractList: [],
      project: [],
      projectList: [],
      contractTypeList: [],
      isPreview: "",
      preview: "",
      search: "",
      sortName: "",
      isTitlePreview: false,
    };
  }

  // setSorting = (sortBy: string, titleOrder: boolean, dateOrder: boolean) => {
  //   // let { sortBy, titleOrder, dateOrder } = this.props;
  //   let { applySorting} = this.props;

  //   switch (sortBy) {
  //     case "date": {
  //       if (dateOrder === true) {
  //         applySorting(sortBy, "ascending", titleOrder, dateOrder);
  //         break;
  //       } else {
  //         applySorting(sortBy, "descending", titleOrder, dateOrder);
  //         break;
  //       }
  //     }
  //     case "title": {
  //       if (titleOrder === true) {
  //         applySorting(sortBy, "ascending", titleOrder, dateOrder);
  //         break;
  //       } else {
  //         applySorting(sortBy, "descending", titleOrder, dateOrder);
  //         break;
  //       }
  //     }
  //   }
  // };

  handleClick = (event: any, placeHolder: string, filterKey: string) => {
    this.setState({ filterDetails: { placeHolder, filterKey } });
    this.setState({
      filterControl: { ...this.state.filterControl, [filterKey]: true },
    });
  };

  handleClose = (key: string) => {
    this.setState({
      filterControl: { ...this.state.filterControl, [key]: false },
    });
  };

  fetchContract = async () => {
    // 
    try {
      const response = await getUserUploads(this.props.contractPageCount);
      console.log(response, "res");

      if (response && response.results.length > 0) {
        const uploadBy = response.results.map(
          (result: any) => result.created_by
        );

        uploadBy.filter((item: any, index: number) => {
          if (uploadBy.includes(item)) {
            this.setState({
              contractList: [item],
            });
          }
        });

        const ContartType = response.results.map(
          (result: any) => result.status
        );
        console.log(ContartType, "ContartType");

        ContartType.filter((item: any, index: number) => {
          if (ContartType.includes(item)) {
            this.setState({
              ContartType: [item],
            });
          }
        });
      } else if (response && response.results.length == 0) {
        this.setState({ contractList: [] });
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  componentDidMount() {
    let project_and_groups:any;
    project_and_groups = [...this.props.prodectData, ...this.props.groupData]
    let user_groups_data = [];
    for(let i=0;i<(project_and_groups.length);i++){
      if(project_and_groups[i]['name'] !== '/org' && project_and_groups[i]['name'] != '/file-manager'){
        let groups_data = project_and_groups[i];
        groups_data['name'] = groups_data['name'].split("/").pop();
        user_groups_data.push(groups_data);
      }
    }
    project_and_groups = user_groups_data;
    if (project_and_groups?.length) {
      var data = project_and_groups?.map((val: any) => val.name);
      var unique = data?.filter(function (elem: any, index: any, self: any) {
        return index === self.indexOf(elem) && elem !== "";
      });
      this.setState({ projectList: unique });
    }
    if (this.props.addTagContractType?.length) {
      var contractList = this.props.addTagContractType?.map(
        (val: any) => val.displayName
      );
      var unique = contractList?.filter(function (
        elem: any,
        index: any,
        self: any
      ) {
        return index === self.indexOf(elem) && elem !== null;
      });
      this.setState({ contractTypeList: unique });
    }
    // this.fetchContract();
  }

  onSearch = (val: any, type: any) => {
   
    this.setState({ preview: val });
    if (val) {
      if (type == "parties") {
        const data = this.props?.TableData?.map((values: any) => {
          let a = values?.contracting_parties?.filter((item: any) =>
            {
              return item?.content?.party?.toLowerCase()?.includes(val.toLowerCase())}
          );
          
          if (a?.length) {
              return values;
            }
          });
        
        const updatData = data?.filter((val: any) => val !== undefined);
        this.props.achiveUpdate(updatData);
      }
      if(type == "title"){
        this.props.achiveUpdate(this.props.TableData.filter(
          (user:any) => user.file_name.toLowerCase().includes(val.toLowerCase())
        ));
      }
    } else {
      this.props.achiveUpdate(this.props.TableData);
    }
  };

  showSearchBar = () => {
    let searchBar = <FormControl className="mb-2" fullWidth size="small" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        value={this.state.search}
                        placeholder={"Search Parties"}
                        onChange={(e) => {
                          this.setState({search: e.target.value})
                          this.onSearch(e.target.value, 'parties');
                        }}
                      />
                    </FormControl>;
  
      return searchBar
    
  }

  showSearchTitle = () => {
    let searchBar = <FormControl className="mb-2" fullWidth size="small" variant="outlined">
                      <OutlinedInput
                        id="outlined-adornment-password"
                        value={this.state.search}
                        placeholder={"Search Title"}
                        onChange={(e) => {
                          this.setState({search: e.target.value})
                          this.onSearch(e.target.value, 'title');
                        }}
                      />
                    </FormControl>;
  
      return searchBar
    
  }


  render() {
    let { archiveData } = this.props;
    let { isActive } = this.state;
    let { onMasterCheck, MasterChecked } = this.props;
    return (
      <>
      <div className="p-0 container-fluid mt-3" style={{width: '100%'}}>
        <div style={{marginLeft: '0rem', marginRight: '0rem', backgroundColor: 'white'}}>
          <div className="col-md-12 p-0">
            <div className="row documentlibrary-list-view-header m-0 p-0">
              <div className="col-md-1" style={{maxWidth: '2%'}}>
                <input
                  type="checkbox"
                  id="mastercheck"
                  onChange={(e: any) => onMasterCheck(e)}
                  className="mr-3 myinput"
                  style={{width: '15px', height: '15px'}}
                  checked={MasterChecked}
                />
              </div>
              <div className="col-md-1">ID</div>
              <div className="col-md-1" style={{paddingLeft: '4%', maxWidth: '12%', flex: '0 0 12%'}} ref={this.titlePreview}>
                Title
                <span>
                {this.state.nameSort === true ? (
                  <img
                    src="/static_images/text_filter_active.svg"
                    className="pl-1 w-10"
                    onClick={() =>
                      this.setState({ nameSort: !this.state.nameSort }, () => {
                        this.props.applySort(this.state.nameSort, "file_name");
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src="/static_images/namedec.svg"
                    className="pl-1 w-10"
                    onClick={() =>
                      this.setState({ nameSort: !this.state.nameSort }, () => {
                        this.props.applySort(this.state.nameSort, "file_name");
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
                </span>
                <span><img src="/static_images/section-search.svg" className="pl-1 w-10 " style={{cursor:"pointer"}}
                
                onClick={() => {
                  this.setState({ isTitlePreview: !this.state.isTitlePreview });
                }}
                
                />
                {this.state.isTitlePreview ? (
                  <>
                   <Popover
                   elevation={0}
                   
                  //  id={props.open ? new Date().toISOString() : undefined}
                   open={true}
                   anchorEl={this.titlePreview?.current}
                   onClose={()=>this.setState({ isTitlePreview: false })}
                   anchorOrigin={{
                     vertical: "bottom",
                     horizontal: "left",
                   }}
                   transformOrigin={{
                     vertical: "bottom",
                     horizontal: "left",
                   }}
                 >
                   <div className="filter">
                   {this.showSearchTitle()}
                    </div>
                    </Popover>

                
                  </>
                ) : null}
                
                </span>
              </div>
              {/* <div className="col-md-1">Support Docs</div> */}
              <div className="col-md-2 d-flex justify-content-center align-items-center" style={{maxWidth: '12%'}}
              
              >
                Connected Docs
                <span>
                {this.state.nameSort === true ? (
                  <img
                    src="/static_images/number_filter_active.svg"
                    className="pl-1 w-10"
                    onClick={() =>
                      this.setState({ nameSort: !this.state.nameSort }, () => {
                        this.props.applySort(this.state.nameSort, "connected_docs");
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src="/static_images/sizedec.svg"
                    className="pl-1 w-10"
                    onClick={() =>
                      this.setState({ nameSort: !this.state.nameSort }, () => {
                        this.props.applySort(this.state.nameSort, "connected_docs");
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
                </span>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center" ref={this.parties}>
                Parties
                <span>
                <img
                  src="/static_images/section-search.svg"
                  className="pl-1 w-10 "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ isPreview: !this.state.isPreview });
                  }}
                />
                {this.state.isPreview ? (
                  <>
                   <Popover
                   elevation={0}
                   
                  //  id={props.open ? new Date().toISOString() : undefined}
                   open={true}
                   anchorEl={this.parties?.current}
                   onClose={()=>this.setState({ isPreview: false })}
                   anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                 >
                   <div className="filter">
                   {this.showSearchBar()}
                    </div>
                    </Popover>

                  {/* <PopOver

                    elevation={0}

                    fieldRef={this.preview}
                    closePop={() => this.setState({ isPreview: false })}
                    onSearch={this.onSearch}
                    value={this.state.preview}
                    type={"parties"}
                    anchorEl={this.parties?.current}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  /> */}
                  </>
                ) : null}
                </span>
              </div>
              <div className="col-md-1 ">
                End Date
                <span>
                {this.state.nameSort === true ?(
                <img
                  src="/static_images/sortDate1.svg"
                  className="pl-1 w-10"
                  onClick={() =>
                    this.setState({ nameSort: !this.state.nameSort }, () => {
                      this.props.applySort(this.state.nameSort, "end_date");
                    })
                  }
                  style={{cursor:"pointer"}}
                />):
                <img
                  src="/static_images/calender_filter_active.svg"
                  className="pl-1 w-10"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ nameSort: !this.state.nameSort }, () => {
                      this.props.applySort(this.state.nameSort, "end_date");
                    })
                  }
                />}
                </span>
              </div>
              <div className="col-md-2 df justify-content-center align-items-center" ref={this.ContartType}>
                Contract Type
                <span>
                <img
                  src="/static_images/section-tags.svg"
                  className="pl-1 w-10"
                  onClick={(e) =>
                    this.handleClick(e, "contract_type", "contract_type")
                  }
                  style={{ cursor: "pointer" }}
                />
                <FilterInputModal
                  list={this.state.contractTypeList}
                  filterKey="contract_type"
                  filterKeyVals={this.props.FilterKeyVals}
                  open={this.state.filterControl["contract_type"]}
                  anchorEl={this.ContartType?.current}
                  handleClose={() => this.handleClose("contract_type")}
                  applyFilter={this.props.applyFilter}
                />
                </span>
                </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center" ref={this.project}>
                Project/Group
                <span>
                  <img
                    src="/static_images/section-tags.svg"
                    className="pl-1 w-10"
                    onClick={(e) => {
                      this.handleClick(e, "project", "project");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  <FilterInputModal
                    list={this.state.projectList}
                    filterKey="project"
                    filterKeyVals={this.props.FilterKeyVals}
                    open={this.state.filterControl["project"]}
                    anchorEl={this.project?.current}
                    handleClose={() => this.handleClose("project")}
                    applyFilter={this.props.applyFilter}
                  />
                </span>
              </div>
              
              
            </div>
          </div>
          
        </div>
      </div>
   
      </>
    );
  }
}

function onToggleCheckbox(
  isChecked: boolean,
  filesAtRootLevel: BasicFileInfo[],
  setActive: any
) {
  let editedIds: BasicFileInfo[] = [];
  if (isChecked === true) {
    editedIds = [];
  } else {
    editedIds = filesAtRootLevel;
  }
  setActive();
  return editedIds;
}

function documentonToggleCheckbox(
  isChecked: boolean,
  documentfilesAtRootLevel: BasicdocumentLibraryFileInfo[],
  setActive: any
) {
  let editedIds: BasicdocumentLibraryFileInfo[] = [];
  if (isChecked === true) {
    editedIds = [];
  } else {
    editedIds = documentfilesAtRootLevel;
  }
  setActive();
  return editedIds;
}

export const PopOver = (props: any) => {
  return (
    <Popover
      elevation={0}
      anchorEl={props.fieldRef?.current}
      open={true}
      onClose={() => props.closePop()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      className="parties-custom"
    >
      <div className="filter">
        <FormControl className="mb-2" fullWidth size="small" variant="outlined">
          <OutlinedInput
            placeholder={`Search ${props.type}`}
            id="outlined-adornment-password"
            value={props.value}
            onChange={(e) => props.onSearch(e.target.value, props.type)}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
          />
        </FormControl>
      </div>
    </Popover>
  );
};
