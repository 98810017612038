import React from 'react'

const headerDetails = [{
    headerName: "Projects",
    className: "col-md-2",
    style: { paddingRight: '0' }
},
{
    headerName: "Contract Type",
    className: "col-md-2",
},
{
    headerName: "Owner",
    className: "col-md-1",
},
{
    headerName: "Created on",
    className: "col-md-1",
    style: {
        paddingLeft: '4px'
    }
},
{
    headerName: "Source",
    className: "col-md-2",
},
{
    headerName: "Status",
    className: "col-md-2",
},
{
    headerName: "Assigned",
    className: "col-md-2",
},
]

const ListViewHeader = () => {

    return (
        <>
            <div className="col-md-12 p-0">
                <div className="row list-view-header">
                    {headerDetails.map(header =>
                        <div className={header.className} style={header.style}
                        >
                            {header.headerName}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ListViewHeader