import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

export default class TaskManagementPageAPI {
  public static getAllTasksData(
    url: string,
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) {
    // return AxiosGateWay.post(url, {fileID, requestID, sort, order, selfAssigned, clauseType}).then(
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static getLastUpdatedOn(url: string, selfAssigned: boolean) {
    // return AxiosGateWay.post(url, { selfAssigned })
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static getProgressNameList(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static updateProgress(
    url: string,
    requestID: number,
    progressID: string,
    progressName: string
  ) {
    // return AxiosGateWay.post(url, { requestID, progressID })
    return AxiosGateWay.patch(url, {
      progress: progressName,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static getTaskCount(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
}
