import React, { Component } from "react";
import { DarkTooltip } from "../../../../DocumentView/Component/documentInsights";
import {
  getUploadedTime,
  truncateFileName,
  truncateString,
} from "../../../../Utils/DataModifierUtil/dataModUtil";
import { NotificationData } from "../../State/notificationState";

interface Props {
  notification: NotificationData;
  removeNotification: (notificationID: string) => void;
}

interface State {
  isActive: boolean;
}

export default class AssignNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {}

  removeNotification = () => {};

  render() {
    let { notification, removeNotification } = this.props;
    let { isActive } = this.state;
    return (
      <>
        <div
          className="col-md-12"
          style={{
            borderLeft: "6px solid #FBCE2F",
            //   notification.isRead === false ? "6px solid #FBCE2F" : "",
          }}
          id="notification-type-container"
        >
          <div className="col-md-12 p-0" style={{ display: "inline-flex" }}>
            <div className="col-md-1">
              <img alt="assign-icon" src="/static_images/assign-icn.svg" />
            </div>
            <div
              className="col-md-10 mt-1 pb-2 task-title"
              // onClick={() => window.open('/document/tasks/' + btoa(notification.fileID.toString()) + '/' + btoa(notification.requestID.toString()), "_blank")}
            >
              {/* <span style={{ cursor: 'pointer' }}>{notification.detailsTitle}</span> */}
              {(notification.details.title === null ||
                notification.details.title === "") && (
                <img src="/static_images/empty-dash.svg"></img>
              )}
              {notification.details.title !== null &&
              notification.details.title.length > 25 ? (
                <DarkTooltip
                  title={notification.details.title}
                  placement="right-end"
                >
                  <span
                    style={{ display: "initial", cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "/document/tasks/" +
                          btoa(notification.details.contract_id.toString()) +
                          "/" +
                          btoa(notification.id.toString()),
                        "_blank"
                      )
                    }
                  >
                    {truncateString(notification.details.title, 25)}
                  </span>
                </DarkTooltip>
              ) : (
                <span
                  style={{ display: "initial", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/document/tasks/" +
                        btoa(notification.details.contract_id.toString()) +
                        "/" +
                        btoa(notification.id.toString()),
                      "_blank"
                    )
                  }
                >
                  {notification.details.title}
                </span>
              )}
            </div>
            <div className="col-md-1">
              <span
                style={{ float: "right", cursor: "pointer" }}
                id="close-btn"
                onClick={() => removeNotification(notification.id)}
              >
                <img src="/static_images/close-modal-icn.svg" alt="close" />
              </span>
            </div>
          </div>
          {/* _____ */}
          <div className="col-md-12 mt-2 clause-bi-name">
            <span>
              {notification.details.contract_clause_type} - Clause
              {/* - {notification.biType} */}
            </span>
          </div>
          <div
            className="col-md-12 mt-3 mb-2"
            style={{ display: "inline-flex" }}
          >
            <div
              className={
                "col-md-4 mr-2 " +
                notification.details.progress.toLowerCase().replaceAll("_", "-")
              }
            >
              <span>{notification.details.progress.replaceAll("_", " ")}</span>
            </div>
            <div className="col-md-4 status">
              <span>{notification.details.status.replaceAll("_", " ")}</span>
            </div>
            <div className="col-md-4 mt-1 pr-0 alert-date">
              <span>
                {/* {notification.details.reminder_until != ""
                  ? getUploadedTime(notification.details.reminder_until)
                  : ""} */}
                {/* {
              getUploadedTime(notification.details.reminder_until
              )} */}
              </span>
            </div>
          </div>
          {/* _____ */}
        </div>
      </>
    );
  }
}
