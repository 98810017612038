import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { isNullOrUndefined } from "is-what";
import moment from "moment";
import { truncateFileName } from "../../Utils/DataModifierUtil/dataModUtil";
import MyDocumentModal from "./mydocumentModal";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";

const supportTableStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  height: "600px",
  // width: 400,
  bgcolor: "#E5E5E5",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  openModal: string;
  handleCloseSupportDocumentTableBtn: any;
  contractData: any;
  handleopensupportmodal: any;
  refreshData: any;

  //   supportBtn: any;
  //   handleClosesupportBtn: any;

  //   supporttitle: any;
}
const SupportDocumentTableModal: React.FC<Props> = (props: Props) => {
  const [MasterChecked, setmasterchecked] = React.useState(false);
  const [archiveData, setarchivedata] = React.useState<any>([]);
  const [checkDocument, setcheckdocument] = React.useState<any>([]);
  const [deleteItemSelect, setdeleteitemselect] = React.useState<any>([]);
  const [list, setlist] = React.useState<any>([]);
  const [openModal, setopenModal] = React.useState("");
  const [selectedContracts, setselectedContracts] = useState<string[]>([]);
  const [supportdocument, setsupportdocuments] = React.useState<any>([]);
  const [loader, setloader] = React.useState(true);
  const [openSnackbar, setopenSnackbar] = React.useState(false);

  const getSupportDocs = () => {
    // AxiosGateWay.get(
    //   process.env.REACT_APP_RIVERUS_CLM_API +
    //     `contracts/${props.contractData[0]?.id}/`
    // )
    axios.get(process.env.REACT_APP_RIVERUS_CLM_API +`contracts/${props.contractData[0]?.id}/`, {
      headers: {
        Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
      }
    })
      .then((response) => {
        if (response.status == 200) {
          setloader(false);
          setsupportdocuments(response.data["support_document"]);
        }
        // this.setState({ statistics: response.data.contracts_statistic });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteSupportDocs = () => {
    const id = selectedContracts?.map((val: any) => val.id);
    axios
      .delete(
        process.env.REACT_APP_RIVERUS_CLM_API + `contracts/bulk-delete/`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
          },
          data: { id: id },
        }
      )
      .then((response) => {
        setopenSnackbar(true);
        getSupportDocs();
      })
      .catch((error) => {
        console.log(error);
        setloader(false);
      });
  };

  useEffect(() => {}, [openModal]);

  useEffect(() => {
    setloader(true);
    setsupportdocuments([]);
    setdeleteitemselect([]);
    setopenSnackbar(false);
    setselectedContracts([]);
    if(props.contractData.length>0){
      getSupportDocs();
    }
   
  }, [props.contractData]);

  const onMasterCheck = (e: any) => {
    if (selectedContracts.length > 0) {
      setselectedContracts([]);
      return;
    }
    let selected: string[] = [];
    selected = props.contractData[0].support_document;
    setselectedContracts([...selected]);

    const { checked } = e.target;
    let tempList = [...props.contractData[0].support_document];
    const a = tempList.map((user: any) => (user.selected = checked));
    let checkedItem = tempList.map((arr) => {
      return { id: arr.id, name: arr.file_name };
    });
    setmasterchecked(e.target.checked);
    setarchivedata(tempList);
    setcheckdocument(() => {
      const a = archiveData.filter((e: any) => e.selected);
      return a;
    });
    setdeleteitemselect(checkedItem);
  };
  const onItemCheck = (e: any, item: any) => {
    let selected = [...selectedContracts];
    let index = selected.findIndex((x: any) => x.id === item.id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(item);
    }
    setselectedContracts([...selected]);

    if (e.target.checked) {
      setdeleteitemselect([
        ...deleteItemSelect,
        { name: item.file_name, id: item.id },
      ]);
      // this.setState({
      //   deleteItemSelect: [
      //     ...this.state.deleteItemSelect,
      //     { name: item.file_name, id: item.id },
      //   ],
      // });
    } else {
      let a = deleteItemSelect.filter((i: any) => i.id !== item.id);
      setdeleteitemselect(a);
    }

    let tempList = [...archiveData];

    tempList?.map((user: any) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State

    const totalItems = archiveData?.length;

    const totalCheckedItems = tempList.filter((e: any) => e.selected).length;
    setmasterchecked(totalItems === totalCheckedItems);
    setlist(tempList);
    setcheckdocument(() => {
      const b = archiveData.filter((e: any) => e.selected);
      return b;
    });
  };

  const mydata = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-12  d-flex justify-content-space-between">
            <div className="col-md-6 pl-0">
              <img
                src={"/static_images/back_Arrow2.svg"}
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "8px",
                }}
                onClick={() => props.handleCloseSupportDocumentTableBtn()}
              />

              <span> Support Docs</span>
            </div>
            <div className="col-md-6 d-flex justify-content-end ">
              <span
                className="update-btn"
                onClick={() => {
                  props.handleCloseSupportDocumentTableBtn();
                  props.handleopensupportmodal();
                }}
              >
                <img
                  src={"/static_images/add-clause.svg"}
                  alt=""
                  // onClick={props.handleCloseSupportDocumentTableBtn}
                />
                <span
                  style={{
                    color: "#626262",
                    fontSize: "14px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  Upload New
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          {/* <div className="col-md-12"> */}
          <div className="col-md-8 ">
            {/* <img
                src={"/static_images/clause_drop.svg"}
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                }}
              /> */}

            <div>
              <img
                src={"/static_images/uploadRefreshIcon.svg"}
                alt=""
                style={{
                  width: "24px",
                  height: "24px",
                  marginLeft: "10px ",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setloader(true);
                  getSupportDocs();
                }}
              />
            </div>
          </div>
          <div className="col-md-4">
            {selectedContracts.length > 0 ? (
              <div
                className="df mt-1"
                style={{ justifyContent: "end" }}
                onClick={() => {
                  setopenModal("modalDelete");
                }}
              >
                <img
                  src={"/static_images/section-clause-delete.svg"}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginLeft: "10px ",
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : null}
          </div>
          {/* </div> */}
        </div>

        <Typography
          id="modal-modal-title"
          // variant="h6"

          component="div"

          //   style={{
          //     width: "100%",
          //     height: "325px",
          //     border: "1px dashed #D9D9D9",
          //     background: "rgba(217, 217, 217, 0.25)",

          //     overflowY: "scroll",
          //   }}
        >
          <div
            className="row upload-header-container mt-3 pl-0 "
            style={{ borderBottom: "1px solid grey" }}
          >
            {loader && <CircularProgress className="loader" />}
            <div className="col-md-6">
              <input
                type="checkbox"
                onChange={(e: any) => onMasterCheck(e)}
                // checked={MasterChecked}
                checked={selectedContracts.length > 0}
                id="mastercheck"
                className="mr-3 myinput"
                style={{ color: "#1D1D1D", height: "16px", width: "16px" }}
              />
              Name
            </div>
            <div className="col-md-3">Type</div>
            <div className="col-md-3">Uploaded On</div>
          </div>

          {supportdocument.map((contract: any, index: any) => {
            return (
              <div
                className={"row"}
                style={{ borderBottom: "1px solid grey" }}
                key={`supportdoc${contract.id}`}
              >
                <div className="col-md-6 py-3">
                  <div className="row">
                    <div className="col-md-1">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      onChange={(e) => onItemCheck(e, contract)}
                      // checked={contract.selected}
                      checked={
                        selectedContracts.filter(
                          (e: any) => e.id == contract.id
                        ).length == 1
                      }
                      className=" myinput mr-3"
                      style={{
                        color: "#6D6D6D",
                        height: "16px",
                        width: "16px",
                        marginTop: "3px",
                      }}
                    />
                    </div>
                    <div className="col-md-9">
                    <Tooltip title={(contract.file_name)} arrow>
                      <div
                        className="text-overflow file-name-style-upload"
                        onClick={() =>
                          window.open(
                            "/document/documentlibrary/" +
                              btoa(contract.id.toString()),
                            "_blank"
                          )
                        }
                        style={{cursor:"pointer"}}
                      >
                        {truncateFileName(contract.file_name?.toString())}
                      </div>
                    </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 py-3">
                  <div className="">
                    <span>
                      {!isNullOrUndefined(contract.support_document_type) ? (
                        <>
                          {truncateFileName(
                            contract.support_document_type?.toString()
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-md-3 py-3">
                  <div className="">
                    <span>
                      {" "}
                      {moment(contract.created_on).format("DD-MMM-YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </Typography>

        <MyDocumentModal
          openModal={openModal}
          handleClosedelete={() => {
            setopenModal("");
          }}
          p={`<p>Are you sure you want to delete this contract? </p> <br /> <p>You cannot undo the action</p>`}
          btn={"Delete Contract"}
          a={"Do you wish to continue?"}
          selectedContracts={selectedContracts}
          deleteContractLoader={false}
          deleteContracts={() => {
            setopenModal("");
            setloader(true);
            deleteSupportDocs();
            //this.deleteContracts();
          }}
        />

        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          onClose={()=>setopenSnackbar(false)}
          message={"Support document deleted successfully"}
          key="bottomCenter"
        />
      </>
    );
  };
  return (
    <Drawer
      anchor="right"
      open={props.openModal === "modalSupportDocTable"}
      onClose={props.handleCloseSupportDocumentTableBtn}
      className="muidrawer-custom"
    >
      {mydata()}
    </Drawer>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportDocumentTableModal);
