import React, { Component } from "react";

import { ContractRes } from "../State/DraftState";

interface Props {
  draft_details: ContractRes | undefined;
}

const getColor = (key: string) => {
  if (key === "group") return "black";
  else return "white";
};

interface State {}

export default class ApprovalModalView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  switchView = () => {
    return (
      <>
        {this.props.draft_details?.approval_status
          ?.split(",")
          .map((approval_status: string, key: number) => {
            return (
              <div className="row">
                <div className="col-md-12 member-name px-3">
                  {key + 1}.&nbsp;{approval_status}
                </div>
              </div>
            );
          })}
      </>
    );
  };

  getModalContainerTop(type: string) {
    let top: string = "20%";
    if (type === "List") {
      top = "23%";
    } else if (type === "List Dashboard") {
      top = "60%";
    } else if (type === "Tags") {
      top = "37%";
    } else {
      top = "42%";
    }

    return top;
  }

  getModalContainerLeft(type: string) {
    let left: string = "20%";
    if (type === "List") {
      left = "25%";
    } else if (type === "List Dashboard") {
      left = "25%";
    } else if (type === "Tags") {
      left = "25%";
    } else {
      left = "30%";
    }
    return left;
  }

  getModalContainerWidth(type: string) {
    let width: string = "20%";
    if (type === "List") {
      width = "14%";
    } else if (type === "List Dashboard") {
      width = "14%";
    } else if (type === "Tags") {
      width = "14%";
    } else {
      width = "14%";
    }
    return width;
  }

  render() {
    // let  draft_details = this.props;
    return (
      <>
        <div
          className="row modal"
          id="documentLibraryTableModal"
          aria-labelledby="documentLibraryTableModal"
          aria-hidden="true"
          data-backdrop="false"
          style={{ backdropFilter: "none", background: "transparent" }}
        >
          <div
            className="col-md-12 modal-dialog"
            style={{
              width: this.getModalContainerWidth("approvals"),
              top: this.getModalContainerTop("approvals"),
              left: this.getModalContainerLeft("approvals"),
            }}
          >
            <div className="row">
              <div
                className="col-md-12 modal-content"
                style={{ borderRadius: "10px" }}
              >
                <div className="row">
                  <div className="modal-body modal-title mb-0">
                    <div className="col-md-12 mt-2">
                      <div
                        className="row"
                        style={{ borderBottom: "1px solid #808080" }}
                      >
                        <div className="col-md-10">
                          <span className="modal-docname">
                            {this.props.draft_details?.contractName}
                          </span>
                        </div>
                        <div className="col-md-2" style={{ marginTop: "-4px" }}>
                          <span
                            id="analysis-btn-outside-click"
                            data-dismiss="modal"
                            style={{
                              outline: "none",
                              cursor: "pointer",
                              float: "right",
                            }}
                          >
                            <img
                              src="/static_images/gray-close-modal-icn.svg"
                              alt=""
                            />
                          </span>
                        </div>
                        <div
                          className="col-md-10"
                          style={{ marginTop: "-8px", textAlign: "left" }}
                        >
                          <span
                            className="label"
                            style={{ textDecoration: "underline" }}
                          >
                            Approval Status
                          </span>
                        </div>
                      </div>

                      {this.switchView()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
