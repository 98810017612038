import React from 'react'
import HeimdallUtil from '../../HeimdallChild/HeimdallUtil/heimdallUtil'
import '../Design/Avatar.scss'

interface Props {
    users: any[]
}
const Avatar = (props: Props) => {
    return (
        <div className='avatar-container'>
            {props.users.map((user, index) => (
                <div key={index} className="avatar-container_avatar fw d-flex align-items-center" >
                    <span className="initials">{HeimdallUtil?.getNameForAvatar(user)}</span>
                </div>
            ))}
        </div>
    )
}

export default Avatar