
import React, { Component } from "react";
import { isNullOrUndefined } from "is-what";
import { DarkTooltip } from "../../DocumentView/Component/documentInsights";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import { History } from "history";
import {
  truncateFileName,
  truncateString,
  getDateFormat,
  getNormalizedDateToDateString,
  getTermDate,
  getDateStringToDate,
} from "../../Utils/DataModifierUtil/dataModUtil";
import {
  BasicFileInfo,
  DocumentHierarchyData,
  documentLibraryInfo,
  LibraryTagFilterStructure,
  partyInfo,
  BasicdocumentLibraryFileInfo,
  ContractingPartiesInfo,
} from "../State/documentLibraryState";
import {
  addOrRemoveFileIds,
  addOrRemoveFiles,
  documentaddOrRemoveFiles,
  getFileIdsFromFiles,
} from "./Utils/libraryUtils";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import CustomChip from "../../UniversalComponents/CustomChip/Component/CustomChip";

type HierarchyListProps = {
  documentChildren: DocumentHierarchyData[];
};

type FileTitleProps = {
  currentFile: BasicFileInfo;
  fileName: string;
  level: number;
  savedMultipleSelectedFiles: BasicFileInfo[];
  saveMultipleSelectedFiles: (
    savedMultipleSelectedFiles: BasicFileInfo[]
  ) => void;
};

type documentFileTitleProps = {
  currentFile: BasicdocumentLibraryFileInfo;
  fileName: string;
  level: number;
  documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  documentsaveMultipleSelectedFiles: (
    documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[]
  ) => void;

  savedMultipleSelectedFiles: BasicFileInfo[];
  saveMultipleSelectedFiles: (
    savedMultipleSelectedFiles: BasicFileInfo[]
  ) => void;
};

interface Props {
  // documentChildren: DocumentHierarchyData[];
  // initialFileIds: string[];
  // savedMultipleSelectedFiles: BasicFileInfo[];
  // saveMultipleSelectedFiles: (
  //   savedMultipleSelectedFiles: BasicFileInfo[]
  // ) => void;
  // savedCollapsedFileIds: string[];
  // saveCollapsedFileIds: (savedCollapsedFileIds: string[]) => void;
  // appliedLibraryTagFilters: LibraryTagFilterStructure[];
  // applyLibraryTagFilters: (
  //   appliedLibraryTagFilters: LibraryTagFilterStructure[],
  //   initialFileIds: string[]
  // ) => void;
  setModal: (
    type: string,
    title: string,
    parties: ContractingPartiesInfo[],
    projects: any
  ) => void;
  onItemCheck:any
  // documentLibraryData: documentLibraryInfo[];
  // initalLevel: number;

  // documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  // documentsaveMultipleSelectedFiles: (
  //   documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[]
  // ) => void;
  openModal: any;
  archiveData: any;
  checkDocument: boolean;
  handlecheckDocument: any;
  handleCloseSupportDocumentTableBtn: any;

  handleSupportDocument: (contractData: any) => void;
  handleSupportDocumentId:any;
  applyFilter: any;

  openAmendmentModal: any;
  handleAmendmentDocument: (contractData: any) => void;
  handleAmendmentDocumentId: any;
}

interface State {
  filterVal: string;
  projectFilter: string;
}

export default class HierarchyList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {filterVal:'', projectFilter: ''}
  }

 

  componentDidUpdate(): void {}

  getExtraCount = (numberOfParties: number) => {
    let extraCount = numberOfParties - 1;
    return "+" + extraCount;
  };

  getExtraProjectsCount = (numberOfParties: number) => {
    let extraCount = numberOfParties - 2;
    return "+" + extraCount;
  };

  getStartDate = (content: any) => {
    if (content != null) {
      if (!content.hasOwnProperty("normalized_date")) {
        if (!content.hasOwnProperty("effective_date")) {
        } else {
          let startdate = getNormalizedDateToDateString(
            content["effective_date"]
          )
            .toUpperCase()
            .replaceAll(" ", "-");
          return startdate;
        }
      } else {
        let startdate = getNormalizedDateToDateString(
          content["normalized_date"]
        )
          .toUpperCase()
          .replaceAll(" ", "-");
        return startdate;
      }
    }
  };
  getEndDate = (date:any) =>{
    let normalized_date = date;
    let monthArray = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    if(isNaN(parseInt(normalized_date.split("-")[1]))){
      
      return normalized_date;
    }else{
      let enddate = getNormalizedDateToDateString(
        normalized_date
      ).toUpperCase()
      .replaceAll(" ", "-");
      enddate  = moment(enddate).format(
        "DD-MMM-YYYY"
      )
      return enddate.toUpperCase();
    }
  }
  getEndDate1 = (content: any) => {
    if (content != null) {
      if(content.hasOwnProperty('normalized_date')){
        let normalized_date = content['normalized_date'];
        let monthArray = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        if(isNaN(parseInt(normalized_date.split("-")[1]))){
          
          return normalized_date;
        }else{
          let enddate = getNormalizedDateToDateString(
            content["normalized_date"]
          ).toUpperCase()
          .replaceAll(" ", "-");
          enddate  = moment(enddate).format(
            "DD-MMM-YYYY"
          )
          return enddate;
        }

      }else{
        return "-";
      }
      
    }else{
    return "-"
    }
  };

  // setModal(check: boolean, type: string, title: string, parties: partyInfo[]) {
  setModal = (
    check: boolean,
    type: string,
    title: string,
    parties: ContractingPartiesInfo[],
    projects: any
  ) => {
    if (check) {
  
      this.props.setModal(type, title, parties, projects);
      let link = document.getElementById("documentLibraryTableButton");
      !isNullOrUndefined(link) && link.click();
    }
  };

  connectedDocsCount =(contract:any) =>{
    var connected_docs:number = 0;
    if(!isNullOrUndefined(contract.parent)){
      connected_docs = 1;
    }
    connected_docs = connected_docs + (contract.children.length);
    connected_docs = connected_docs + (contract.amendment.length);
    connected_docs = connected_docs + (contract.support_document.length);
    return connected_docs;
  }

  getParties = (parties:any) => {
    let party:any = "";
    for(let i=0;i<parties.length;i++){
      if(party == ""){
        party = parties[i]['content']['party']
      }else{
        party = party + "\n"+ parties[i]['content']['party']
      }
    }
    return party;
  }

  getProjectGroups = (projectGroups:any) => {
   let project_groups:any = "";
   for(let i=0;i<projectGroups.length;i++){
    if(project_groups == ""){
      project_groups = projectGroups[i]['name']
    }else{
      project_groups = project_groups + "\n"+ projectGroups[i]['name']
    }
  }
  return project_groups;
  }

  render() {
    let {
   
      setModal,
      handlecheckDocument,
      checkDocument,
      archiveData,
      onItemCheck,
      handleCloseSupportDocumentTableBtn,
    } = this.props;

    return (
      <>
        

        <div className="p-0 container-fluid" style={{width: '100%'}}>
          <div style={{marginLeft: '0rem', marginRight: '0rem', backgroundColor: 'white'}}>
          {archiveData.map((item: any) => {
            return (
              <>
              <div className="documentlibrary-list-view-container">
            <div className="row m-0 p-0 df">
            <div className="documentlibrary-list-view col-md-12 " style={{backgroundColor: 'rgb(250, 250, 250)'}}>
              <div className="row documentlibrary-list-view-table mb-2 mt-2">
                <div className="col-md-1" style={{maxWidth: '2%', paddingLeft: '8px', marginTop: '7px'}}>
                  <input
                      type="checkbox"
                      name=""
                      id=""
                      className=" myinput mr-1"
                      style={{width: '15px', height: '15px'}}
                      onChange={(e) => onItemCheck(e, item)}
                      checked={item.selected}
                    />
                </div>
                <div className="col-md-1">
                  <div className="">
                  <Tooltip title={item.docID } arrow>
                      
                      <div className="text-overflow c-primary">
                        
                        {item?.children.length>0?
                        <>
                        {/* Parent Doc */}
                          <img
                            src="/static_images/Vector (1).svg"
                            className="pr-1  pb-1"
                          />
                        </>:<>
                        {item?.children.length == 0 && item?.parent !== null?<>
                        {/* Child Document */}
                          <img
                            src="/static_images/Vector (1).svg"
                            className="pr-1  pb-1"
                          />
                        </>:<>
                              {item?.uploaded_type === "Contract" ? (
                                  <img
                                    src="/static_images/Vector (1).svg"
                                    className="pr-1  pb-1"
                                  />
                                ) : item?.uploaded_type === "Support Document" ? (
                                  <img
                                    src="/static_images/iconSupport.svg"
                                    className="pr-1  pb-1"
                                  />
                                ) : item?.uploaded_type === "Amendment" ? (
                                  <img
                                    src="/static_images/Union.svg"
                                    className="pr-1  pb-1"
                                  />
                                ) : (
                                  ""
                                )}
                          </>}
                          </>}
                          {item?.docID}</div>
                    </Tooltip>
                  </div>
                  {/* <div className="text-overflow" title="STC33D2F8F7/2023">STC33D2F8F7/2023</div> */}
                </div>
                <div className="col-md-1" style={{paddingLeft: '4%', maxWidth: '12%', flex: '0 0 12%'}}>
                  <div className="text-overflow c-primary">
                  <Tooltip title={(isNullOrUndefined(item?.title)?item?.file_name?.toString():item?.title?.toString())}
                  // {(item?.file_name?.toString())}
                   arrow>
                      <div
                        className="text-overflow"
                        onClick={() =>
                          window.open(
                            "/document/documentlibrary/" +
                              btoa(item.id.toString()),
                            "_blank"
                          )
                        }
                      >
                        
                        {(isNullOrUndefined(item?.title)?item?.file_name?.toString():item?.title?.toString())}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                {/* <div className="col-md-1 c-primary">0</div> */}
                
                <div className="col-md-2 c-primary d-flex  justify-content-center align-items-center" style={{maxWidth: '12%'}}
                   onClick={()=>{
                    this.props.handleAmendmentDocument(item);
                    this.props.openAmendmentModal();
                    this.props.handleAmendmentDocumentId(item.id);
                    }}
                >
                  <span style={{cursor:"pointer"}}>{item?.connected_docs}</span>
                </div>
                <div className="col-md-2 c-primary d-flex justify-content-center align-items-center" style={{paddingLeft: '10px', flex: '0 0 17%', maxWidth: '17%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                 {item.contracting_parties.length === 0 && (
                      <img src="/static_images/empty-dash.svg"></img>
                    )}

                    {item.contracting_parties.length < 1 &&
                      item.contracting_parties.map(
                        (party: any, i: any) =>
                          i < 1 && (
                            <span>
                              {party?.content?.party}
                              {party?.content?.party?.length > 15 ? (
                                <>
                                  {i > 0 && <>&nbsp;</>}
                                  {truncateString(party?.content?.party, 15)}
                                  {i < 1 && <>,</>}
                                </>
                              ) : (
                                <>
                                  {i > 0 && <>&nbsp;</>}
                                  {party?.content?.party}
                                  {/* {i < 1 && <>,</>} */}
                                </>
                              )}
                            </span>
                          )
                      )}

                    {item.contracting_parties.length >= 1 &&
                      item.contracting_parties.map((party: any, i: any) => (
                        <span key={i}>
                          {i < 1 ? (
                            party?.content?.party.length > 15 ? (
                              <>
                                {i > 0 && <>&nbsp;</>}
                                {truncateString(party?.content?.party, 15)}
                              </>
                            ) : (
                              <>
                                {i > 0 && <>&nbsp;</>}
                                {party?.content?.party}
                                {/* {i < 1 && <>,</>} */}
                              </>
                            )
                          ) : i === 1 ? (
                            <span
                              className="extra-count cursor-pointer"
                              onClick={() =>
                                this.setModal(
                                  true,
                                  "Parties",
                                  item.file_name,
                                  item.contracting_parties,
                                  item.projects
                                )
                              }
                            >
                              <Tooltip title={
                                <div style={{ whiteSpace: 'pre-line' }}>{this.getParties(item.contracting_parties)}</div>
                                 }>
                                <span>
                              {this.getExtraCount(
                                item.contracting_parties.length
                              )}
                              </span>
                              </Tooltip>
                            </span>
                          ) : (
                            <> </>
                          )}
                        </span>
                      ))}
                </div>
                <div className="col-md-1 d-flex" style={{padding: '0px', marginTop: '0px', cursor: 'pointer'}}>
                <span>{isNullOrUndefined(item.end)?"-":this.getEndDate(item.end)}</span>
                </div>
                <div className="col-md-2 df p-0 justify-content-center align-items-center">
                  {item?.contract_type.length > 0 ? (
                      
                      // <div className="row">
                      //   <div className="col-md-12 contracts-tags ">
                      //     {item.contract_type[0].displayName}
                      //   </div>
                      // </div>
                      <>
                      <Tooltip title={item.contract_type[0].displayName} arrow>
                        <div className="text-overflow m-0" style={{cursor:"pointer"}}>
                        <CustomChip
                          data={[item.contract_type[0].displayName]}
                          showItems={1}
                          addFilter={(type) =>{
                            this.props.applyFilter([type.toLowerCase()], "contract_type")
                            
                              this.setState({ filterVal: "contract_type" });
                            }
                          }
                          isCancel={
                            this.state.filterVal == 'contract_type'?true:false
                          }
                          cancelAction={() => {this.props.applyFilter([], "contract_type");this.setState({ filterVal: "" }); }}
                        />
                        </div>
                        </Tooltip>
                      <div className="df m-0 align-items-center" style={{display:"none"}}>
                      <div className='df m-0 mr-1'>
                        <div className='custom-chip m-0'>
                          {item.contract_type[0].displayName}
                          </div>
                        </div>
                        </div>
                        </>
                    ) : (
                      "-"
                    )}
                </div>
                <div className="col-md-2 df p-0 justify-content-center align-items-center">
                  
                {item.projects_groups.length === 0 && <>-</>}
                  {item.projects_groups.length < 2 &&
                    item.projects_groups.map(
                      (project: any, i: any) =>
                        i < 2 && (
                          
                          <Tooltip title= {project.name} arrow>
                            <>
                            <div className="text-overflow m-0" style={{cursor:"pointer"}}>
                              <CustomChip
                              backgroundColor={ project.type === "projects" ? "#EEEDFF"
                              : "#abe7bf"}
                              textColor={project.type === "projects" ? "#392494"
                              : ""}
                                data={[project.name]}
                                showItems={1}
                                addFilter={(type) =>{
                                  this.props.applyFilter([type.toLowerCase()], "project")
                                  
                                    this.setState({ projectFilter: "project" });
                                  }
                                }
                                isCancel={
                                  this.state.projectFilter == 'project'?true:false
                                }
                                cancelAction={() => {this.props.applyFilter([], "project");this.setState({ projectFilter: "" }); }}
                              />
                              </div>
                          <span
                            key={`projects${project.id}`}
                            className="contracts-tags1 custom-chip"
                            style={{
                              backgroundColor: project.type === "projects" ? "#EEEDFF"
                                : "#abe7bf",
                              color: project.type === "projects" ? "#392494"
                              : "",
                              display: "none"
                            }}
                            
                          //   style={
                          //   project.type == 'projects'?`{$}`:''
                          //   // {{backgroundColor:"#EEEDFF",color: "#392494"}
                          // }
                          >
                            {project.name.length > 15 ? (
                              <>
                                {i > 0 && <>&nbsp;</>}
                                {truncateString(project.name, 15)}
                                {i < 1 && <></>}
                              </>
                            ) : (
                              <>
                                {i > 0 && <>&nbsp;</>}
                                {project.name}
                                {i < 1 && <></>}
                              </>
                            )}
                          </span>
                          </>
                          </Tooltip>
                        )
                    )}
                  {item.projects_groups.length >= 2 &&
                    item.projects_groups.map((project: any, i: any) => (
                      <span key={`projects${project.id}`}>
                        {i < 2 ? (
                          project.name.length > 15 ? (
                            <>
                            <Tooltip title= {project.name} arrow>
                              <div className="text-overflow m-0" style={{cursor:"pointer"}}>
                              <CustomChip
                              backgroundColor={ project.type === "projects" ? "#EEEDFF"
                              : "#abe7bf"}
                              textColor={project.type === "projects" ? "#392494"
                              : ""}
                                data={[project.name]}
                                showItems={1}
                                addFilter={(type) =>{
                                  this.props.applyFilter([type.toLowerCase()], "project")
                                  
                                    this.setState({ projectFilter: "project" });
                                  }
                                }
                                isCancel={
                                  this.state.projectFilter == 'project'?true:false
                                }
                                cancelAction={() => {this.props.applyFilter([], "project");this.setState({ projectFilter: "" }); }}
                              />
                              </div>
                              </Tooltip>
                            <span className="contracts-tags1 custom-chip"
                            //  style={{backgroundColor:"#EEEDFF",color: "#392494"}}
                            style={{
                              backgroundColor: project.type === "projects" ? "#EEEDFF"
                                : "#abe7bf",
                              color: project.type === "projects" ? "#392494"
                              : "",
                              display: "none"
                            }}
                             >
                              {i > 0 && <>&nbsp;</>}
                              {truncateString(project.name, 15)}
                              {i < 1 && <></>}
                            </span>
                            </>
                          ) : (
                            <>
                            <Tooltip title= {project.name} arrow>
                              <div className="text-overflow m-0" style={{cursor:"pointer"}}>
                              <CustomChip
                              backgroundColor={ project.type === "projects" ? "#EEEDFF"
                              : "#abe7bf"}
                              textColor={project.type === "projects" ? "#392494"
                              : ""}
                                data={[project.name]}
                                showItems={1}
                                addFilter={(type) =>{
                                  this.props.applyFilter([type.toLowerCase()], "project")
                                  
                                    this.setState({ projectFilter: "project" });
                                  }
                                }
                                isCancel={
                                  this.state.projectFilter == 'project'?true:false
                                }
                                cancelAction={() => {this.props.applyFilter([], "project");this.setState({ projectFilter: "" }); }}
                              />
                              </div>
                              </Tooltip>
                            
                            <span className="contracts-tags1 custom-chip" 
                            // style={{backgroundColor:"#EEEDFF",color: "#392494"}}
                            style={{
                              backgroundColor: project.type === "projects" ? "#EEEDFF"
                                : "#abe7bf",
                              color: project.type === "projects" ? "#392494"
                              : "",
                              display:"none"
                            }}
                            
                            
                            >
                              {i > 0 && <>&nbsp;</>}
                              {project.name}
                              {i < 1 && <></>}
                            </span>
                           </>
                          )
                        ) : i === 2 ? (
                          <Tooltip title={
                            <div style={{ whiteSpace: 'pre-line' }}>{this.getProjectGroups(item.projects_groups)}</div>
                          }>
                          <span
                            className="extra-count cursor-pointer"
                            onClick={() =>
                              this.setModal(
                                true,
                                "Project/Group",
                                item.file_name,
                                item.contracting_parties,
                                item.projects_groups
                              )
                            }
                          >
                            {this.getExtraProjectsCount(item.projects_groups.length)}
                          </span>
                          </Tooltip>
                        ) : (
                          <> </>
                        )}
                      </span>
                    ))}
                </div>
                
               
              </div>
            </div>
          </div>
          </div>
              
              </>
            );
          })}

          {/* <DeleteFileModal /> */}
          </div>
        </div>
      </>
  
    );
  }
}

export function FileTitle({
  fileName,
  level,
  currentFile,
  documentsavedMultipleSelectedFiles,
  documentsaveMultipleSelectedFiles,
  savedMultipleSelectedFiles,
  saveMultipleSelectedFiles,
}: documentFileTitleProps) {
  //documentFileTitleProps

  //FileTitleProps

  let indents = [];
  for (let i = 0; i < level; i++) {
    indents.push(<IndentSpace key={i} />);
  }

  return (
    <span>
      <DarkTooltip title={fileName} placement="right-end">
        <span className="file-name">
          {level !== 0 && indents}
          {/* {level !== 0 && <img className="hierarchy-icon-style" src = "/static_images/hierarchy-tree-icon.svg" alt="tree-icon" />} */}
          &nbsp;&nbsp;
          <input
            type="checkbox"
            value={currentFile.fileId}
            checked={
              getFileIdsFromFiles(savedMultipleSelectedFiles).indexOf(
                currentFile.fileId
              ) > -1
                ? true
                : false
            }
            onChange={() => {
              {
                documentsaveMultipleSelectedFiles(
                  documentaddOrRemoveFiles(
                    currentFile,
                    documentsavedMultipleSelectedFiles
                  )
                );
                saveMultipleSelectedFiles(
                  addOrRemoveFiles(
                    {
                      fileId: currentFile.fileId,
                      fileName: currentFile.file_name,
                      levelId: 0,
                    },
                    savedMultipleSelectedFiles
                  )
                );
              }
            }}
          />
          &nbsp;&nbsp;
          {fileName.length > 25 ? (
            <span
              className="file-dotted-line"
              onClick={() =>
                window.open(
                  "/document/documentlibrary/" +
                    btoa(currentFile.fileId.toString()),
                  "_blank"
                )
              }
            >
              {truncateString(fileName, 25)}
            </span>
          ) : (
            <span
              onClick={() =>
                window.open(
                  "/document/documentlibrary/" +
                    btoa(currentFile.fileId.toString()),
                  "_blank"
                )
              }
            >
              {fileName === "" ? (
                <img src="/static_images/empty-dash-grey-icn.svg" alt="empty" />
              ) : (
                fileName
              )}
            </span>
          )}
        </span>
      </DarkTooltip>
    </span>
  );
}

export function IndentSpace() {
  return <>&nbsp;&nbsp;&nbsp;&nbsp;</>;
}
