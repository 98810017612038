export default interface NotificationState {
  profileID: string;
  notificationData: NotificationData;
  notificationDataArray: NotificationData[];
}

export interface TaskData {
  id: string;
  contract_id: string;
  contract_file_name: string;
  contract_clause_type: string;
  contract_business_intelligence_type: string;
  title: string;
  progress: string;
  status: string;
  reminder_until: string;
  created_by: string;
}

export interface NotificationDetailsData {
  id: string;
  contract_id: string;
  contract_file_name: string;
  contract_clause_type: string;
  contract_business_intelligence_type: string;
  title: string;
  progress: string;
  status: string;
  reminder_until: string;
  created_by: string;
  task: TaskData;
  message: string;
  file_name?: string;
}

export interface NotificationData {
  notificationID: number;
  requestID: number;
  method: string;
  type: string;
  profileID: number;
  taskTitle: string;
  fileID: number;
  fileName: string;
  clauseType: string;
  biType: string;
  progress: string;
  state: string;
  alertDate: string;
  isRead: boolean;
  task: TaskData;
  id: string;
  created_at: string;
  details: NotificationDetailsData;
  status: string;

}

// export interface NotificationsData {
//         notificationID: number;
//         requestID: number;
//         type: string;
//         taskTitle: string;
//         fileName: string;
//         clauseType: string;
//         biType: string;
//         progress: string;
//         state: string;
//         alertDate: string;
//         isRead: boolean;
//     }

export function defaultNotificationState(): NotificationState {
  return {
    profileID: "-1",
    notificationData: {
      notificationID: 0,
      requestID: 0,
      method: "",
      type: "",
      profileID: -1,
      taskTitle: "",
      fileID: 0,
      fileName: "",
      clauseType: "",
      biType: "",
      progress: "",
      state: "",
      alertDate: "",
      isRead: false,
      task: {
        id: "",
        contract_id: "",
        contract_file_name: "",
        contract_clause_type: "",
        contract_business_intelligence_type: "",
        title: "",
        progress: "",
        status: "",
        reminder_until: "",
        created_by: "",
      },
      id: "",
      created_at: "",
      details: {
        id: "",
        contract_id: "",
        contract_file_name: "",
        contract_clause_type: "",
        contract_business_intelligence_type: "",
        title: "",
        progress: "",
        status: "",
        reminder_until: "",
        created_by: "",
        task: {
          id: "",
          contract_id: "",
          contract_file_name: "",
          contract_clause_type: "",
          contract_business_intelligence_type: "",
          title: "",
          progress: "",
          status: "",
          reminder_until: "",
          created_by: "",
        },
        message: "",
      },
      status: "",
    },
    notificationDataArray: [],
  };
}
