import React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import DraftComponent from "../Component/DraftComponent";
import { File } from "../State/DraftState";
import UploadFileDA, { getContracts, uploadContractMetaData } from "../DA";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
const SparkMD5 = require("spark-md5");

export function mapStateToProps(appState: any, ownProps: any) {
  const handleOnChangeFileUpload = async (
    post: any,
    fileType: any,
    callback: Function
  ) => {
    try {
      let uploadedFiles: File[] = [];
      let uploadProgress: any = {};
      let preSignedResponse: any = null;
      let files_ = uploadedFiles;
      const files: any = post;
      const uploadProgress_ = uploadProgress;
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = post[i];
          let reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = async () => {
              const hexHash = SparkMD5.hash(reader.result);
              console.log(file);
              console.log(hexHash);
              files_.push({
                name: file.name,
                size: file.size,
                type: file.type,
                hash: hexHash,
                status: "uploading",
                template_type: file["template_type"],
                file_type: fileType ? "req_form" : "Contract_Draft",
              });
              uploadProgress_[hexHash] = 0;
              uploadProgress = { ...uploadProgress_ };
              try {
                const response = await UploadFileDA.get_s3_presigned_url({
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file["template_type"],
                  file_type: fileType ? "req_form" : "Contract_Draft",
                  creation_type: isRiverusAdmin() ? "system" : "custom",
                });
                if (response) {
                  const onHandleFileProgress = {
                    onUploadProgress: function (progressEvent: any) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      const uploadProgress_ = uploadProgress;
                      uploadProgress_[hexHash] = percentCompleted;
                      uploadProgress = {
                        ...uploadProgress_,
                      };
                    },
                  };
                  if (response && response.presigned_url) {
                    const s3Info = response.presigned_url;
                    console.log(
                      "🚀 ~ file: templatesContainer.tsx ~ line 83 ~ .then ~ s3Info",
                      s3Info
                    );
                    preSignedResponse = response;
                    try {
                      const response =
                        await UploadFileDA.upload_file_in_s3_bucket(
                          s3Info,
                          file,
                          onHandleFileProgress
                        );
                      if (response) {
                        console.log(
                          "🚀 ~ file: index.ts ~ line 45 ~ upload_file_in_s3_bucket ~ .then ~ response",
                          response
                        );
                        callback(s3Info, null);
                      }
                    } catch (error) {
                      console.log(error);
                      callback(null, error);
                    }
                  }
                }
                uploadedFiles = [...files_];
              } catch (error) {
                console.log(
                  "🚀 ~ file: templatesContainer.tsx ~ line 77 ~ handleOnChangeFileUpload ~ error",
                  error
                );
                callback(null, error);
              }
            };
          }
        }
      }
    } catch (error) {
      callback(null, error);
    }
  };

  const uploadContractData = (post: any) => {
    try {
      return uploadContractMetaData(post);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    uploadFile: (post: any, fileType: any, callback: Function) =>
      handleOnChangeFileUpload(post, fileType, callback),
    uploadContractData: (post: any) => uploadContractData(post),
    getContracts: () => getContracts(1),
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DraftComponent);
