import React, { Component } from "react";
import { isNullOrUndefined } from "is-what";
import LibraryAddTag from "../../../DocumentLibrary/Component/TaggingComponents/libraryAddTag";
import {
  BasicdocumentLibraryFileInfo,
  BasicFileInfo,
} from "../../../DocumentLibrary/State/documentLibraryState";
import { TagData } from "../../../DocumentView/State/documentState";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../../Authentication/Actions/authentication";
import axios from "axios";
import { SITE_API_BY_REALM_NAME } from "../../../Configuration/global";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from "react-select";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

interface Props {
  listTagNature: () => void;
  listTagType: () => void;
  tagNature: TagData[];
  tagType: TagData[];
  otherTags: TagData[];
  listOtherTags: () => void;
  createOtherTags: (name: string) => void;
  storeOtherTags: (storedOtherTags: any) => void;
  newTagData: TagData;
  storedOtherTags: any;
  savedMultipleSelectedFiles: BasicFileInfo[];
  saveMultipleSelectedFiles: (
    savedMultipleSelectedFiles: BasicFileInfo[]
  ) => void;
  selectedFile: BasicFileInfo | null;
  saveSelectedFile: (selectedFile: BasicFileInfo | null) => void;
  selectedLibraryAction: string;
  isBulkAction: boolean;
  saveSelectedLibraryAction: (
    selectedLibraryAction: string,
    isBulkAction: boolean
  ) => void;
  editTags: (
    editFileIds: string[],
    dataType: string,
    tagEditData: any,
    isBulkAction: boolean
  ) => void;
  savedFileTagData: any[];
  saveFileTagData: (savedFileTagData: any[]) => void;
  initialFileIds: string[];
  getDocumentHierarchy: (
    sort: string,
    order: string,
    fileIds: string[]
  ) => void;
  otherTagsLoader: boolean;
  documentselectedFile: BasicdocumentLibraryFileInfo | null;
  documentsaveSelectedFile: (
    documentselectedFile: BasicdocumentLibraryFileInfo | null
  ) => void;
  documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  documentsaveMultipleSelectedFiles: (
    documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[]
  ) => void;
}

interface groupOption {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

interface State {
  docName: string;
  successBtn: boolean;

  readonly allUserGroups: readonly groupOption[];
  readonly selectedUserGroups: readonly groupOption[];
  readonly originalselectedUserGroups: readonly groupOption[];
  fileId: string;
}
const orderOptions = (values: readonly any[]) => {
  return values
    .filter((v) => v.isFixed)
    .concat(values.filter((v) => !v.isFixed));
};
export default class AddTagModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      docName: "",
      successBtn: false,
      allUserGroups: [],
      selectedUserGroups: [
        // { isFixed: true, value: "/file-manager", label: "/file-manager" },
      ],
      originalselectedUserGroups: [],
      fileId: "",
    };
  }
  componentDidMount() {
    document.addEventListener("mouseup", this.handleOutsideClick, false);
    let { listTagType, listTagNature } = this.props;
    listTagNature();
    listTagType();
    this.handleGetAllUserGroups();
  }

  componentDidUpdate = () => {
    if (
      this.props.documentselectedFile &&
      this.props.documentselectedFile.fileId &&
      this.state.selectedUserGroups.length < 1
    ) {
      this.handleGetUserGroupsByFile(this.props.documentselectedFile.fileId);
    }
  };

  handleGetUserGroupsByFile = async (fileId: string) => {
    let { documentselectedFile } = this.props;
    const accessToken = await getLocalStorage("accessToken");

    axios
      .get(SITEAPI + "" + fileId + "/", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response?.status === 200) {
          const userGroups1 = response.data.groups;

          this.setState({
            fileId: fileId,
            selectedUserGroups: userGroups1
              ? userGroups1.map((group: any) => {
                  return {
                    value: group,
                    label: group,
                    isFixed: group === "/file-manager" ? true : false,
                  };
                })
              : [],
            originalselectedUserGroups: userGroups1
              ? userGroups1.map((group: any) => {
                  return {
                    value: group,
                    label: group,
                    isFixed: group === "/file-manager" ? true : false,
                  };
                })
              : [],
          });
        }
      })
      .catch((error) => {});
  };

  handleGetAllUserGroups = async () => {
    const accessToken = await getLocalStorage("accessToken");

    axios
      .get(process.env.REACT_APP_RIVERUS_CLM_API + "users/profile/", {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        if (response?.status === 200) {
          const userGroups = response.data.groups;

          this.setState({
            allUserGroups: userGroups
              ? userGroups.map((group: any) => {
                  return {
                    value: group,
                    label: group,
                    isFixed: group === "/file-manager" ? true : false,
                  };
                })
              : [],
          });
        }
      })
      .catch((error) => {});
  };

  onUserGroupChange = (
    value: OnChangeValue<any, true>,
    actionMeta: ActionMeta<any>
  ) => {
    switch (actionMeta.action) {
      case "remove-value":
      case "pop-value":
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = this.state.allUserGroups.filter((v) => v.isFixed);
        break;
    }

    value = orderOptions(value);
    this.setState({ selectedUserGroups: value });
  };

  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("addTagModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("add-tag-close-btn")?.click();
        //window.location.reload();
      }
    }
  };

  render() {
    let {
      newTagData,
      storedOtherTags,
      storeOtherTags,
      createOtherTags,
      listTagNature,
      listTagType,
      tagNature,
      tagType,
      listOtherTags,
      otherTags,
      savedFileTagData,
      isBulkAction,
      selectedLibraryAction,
      selectedFile,
      savedMultipleSelectedFiles,
      editTags,
      saveSelectedLibraryAction,
      saveSelectedFile,
      saveFileTagData,
      getDocumentHierarchy,
      initialFileIds,
      otherTagsLoader,
      documentselectedFile,
      documentsaveSelectedFile,
      documentsavedMultipleSelectedFiles,
    } = this.props;

    return (
      <div className="col-md-12 col-12">
        <div
          className="row modal"
          id="addTagModal"
          aria-labelledby="addTagModal"
        >
          <div className="col-md-12 modal-dialog">
            <div className="row">
              <div className="col-md-12 modal-content">
                <div className="row">
                  <div className="col-md-12 modal-body">
                    <span
                      data-dismiss="modal"
                      style={{ float: "right", cursor: "pointer" }}
                      id="add-tag-close-btn"
                      onClick={() => this.closeModal()}
                    >
                      <img
                        src="/static_images/close-modal-icn.svg"
                        alt="close"
                      />
                    </span>
                  </div>
                  <div
                    className="col-md-1"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "0.2rem",
                    }}
                  ></div>
                  <div className="col-md-4 modal-title">
                    {isBulkAction ? "Add to Groups" : "Add Tags"}
                  </div>
                  <div
                    className="col-md-7"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "0.2rem",
                    }}
                  ></div>
                  <div className="modal-body modal-subtitle">
                    <LibraryAddTag
                      storedOtherTags={storedOtherTags}
                      storeOtherTags={storeOtherTags}
                      newTagData={newTagData}
                      createOtherTags={createOtherTags}
                      tagNature={tagNature}
                      tagType={tagType}
                      listOtherTags={listOtherTags}
                      otherTags={otherTags}
                      tags={savedFileTagData}
                      isBulkAction={isBulkAction}
                      selectedLibraryAction={selectedLibraryAction}
                      selectedFile={selectedFile}
                      savedMultipleSelectedFiles={savedMultipleSelectedFiles}
                      listTagNature={listTagNature}
                      listTagType={listTagType}
                      editTags={editTags}
                      saveSelectedFile={saveSelectedFile}
                      saveSelectedLibraryAction={saveSelectedLibraryAction}
                      saveFileTagData={saveFileTagData}
                      initialFileIds={initialFileIds}
                      getDocumentHierarchy={getDocumentHierarchy}
                      otherTagsLoader={otherTagsLoader}
                      documentselectedFile={documentselectedFile}
                      documentsaveSelectedFile={documentsaveSelectedFile}
                      documentsavedMultipleSelectedFiles={
                        documentsavedMultipleSelectedFiles
                      }
                      allUserGroups={this.state.allUserGroups}
                      selectedUserGroups={this.state.selectedUserGroups}
                      onUserGroupChange={this.onUserGroupChange}
                      originalselectedUserGroups={
                        this.state.originalselectedUserGroups
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  dismissModal = () => {
    this.setState({ docName: "", successBtn: false });
    //window.location.reload();
  };

  closeModal = () => {
    this.setState({ docName: "", successBtn: false });
  };
}
