import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { FieldName, initialFieldName } from "../DataDictionaryState";

export const useStateManage = () => {
  const [tableValue, setTableValues] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [isEditScreen, setIsEditScreen] = useState(false);
  const [isSectionDataView, setIsSectionDataView] = useState(false);
  const [sectionDetailsData, setSectionDetailsData] = useState<any[]>([]);
  const [selectedContractType, SetSelectedContractType] = useState("");
  const [sectionDetailsParam, setSectionDetailsParam] = useState({
    sectionName: "",
    SectionDescription: "",
  });
  const [selectedContractTypeId, setSelectedContractTypeId] = useState("");
  const [isListView, setIsListView] = useState("ListView");
  const [loading, setloading] = useState(false);
  const [addField, setAddField] = useState<any | null>(null);
  const [selectedSection, setselectedSection] = useState<any | null>(null);
  const [newSection, setNewSectionData] = useState<any | null>(null);
  const [dataType, setDataType] = useState<any | null>(null);
  const [defaultResponse, setDefaultResponse] = useState<any | null>(null);
  const [sectionDictionary, setSectionDictionary] = useState<any>([]);
  const [isSectionSort, setSectionSort] = useState<any>(false);
  const [isSame, setIsSame] = useState(false);
  const [totalCount, settotalCount] = useState(0);
  const [pageWiseData, setpageWiseData] = useState<any>({});
  const [defaultPageWiseData, setDefaultPageWiseData] = useState<any>({});
  const [customPageWiseData, setCustomPageWiseData] = useState<any>({});

  const [defaultContractType, setDefaultContractType] = useState<any>([]);
  const [customContractType, setCustomContractType] = useState<any>([]);

  const [totalDefaultCount, setTotalDefaultCount] = useState(0);
  const [totalCustomCount, setTotalCustomCount] = useState(0);

  useEffect(() => {}, [
    sectionDictionary,
    defaultContractType,
    customContractType,
  ]);
  const APIPaths = {
    getContractTypeData:
      process.env.REACT_APP_RIVERUS_CLM_API + "admin/contracttype/",
    postSectionName:
      process.env.REACT_APP_RIVERUS_CLM_API +
      "data-dictionary/section_dictionary/",
  };

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  // const getContractTypeData = (page: number) => {
  //   setloading(true);
  //   return axios
  //     .get(APIPaths?.getContractTypeData + `?page=${page}`, APIConfig())
  //     .then((response) => {
  //       settotalCount(response?.data?.count);
  //       setTableValues(response?.data?.results);
  //       setpageWiseData({
  //         ...pageWiseData,
  //         [page]: [...response?.data?.results],
  //       });
  //       setloading(false);
  //       return response?.data;
  //     })
  //     .catch((error) => {
  //       console.error("getContractTypeData error", error);
  //     });
  // };

  const getContractTypeData = async (page: number) => {
    setloading(true);
    await getDefaultContractTypeData(page);
    await getCustomContractTypeData(page);
  };

  const getDefaultContractTypeData = (page: number) => {
    setloading(true);
    return axios
      .get(
        APIPaths?.getContractTypeData + `?page=${page}&creation_type=system`,
        APIConfig()
      )
      .then((response) => {
        setTotalDefaultCount(response?.data?.count);
        setDefaultContractType(response?.data?.results);
        setDefaultPageWiseData({
          ...defaultPageWiseData,
          [page]: [...response?.data?.results],
        });
        setloading(false);
        return response?.data;
      })
      .catch((error) => {
        console.error("getContractTypeData error", error);
      });
  };

  const getCustomContractTypeData = (page: number) => {
    setloading(true);
    return axios
      .get(
        APIPaths?.getContractTypeData + `?page=${page}&creation_type=custom`,
        APIConfig()
      )
      .then((response) => {
        setTotalCustomCount(response?.data?.count);
        setCustomContractType(response?.data?.results);
        setCustomPageWiseData({
          ...customPageWiseData,
          [page]: [...response?.data?.results],
        });
        setloading(false);
        return response?.data;
      })
      .catch((error) => {
        console.error("getContractTypeData error", error);
      });
  };

  const getSectionDictionary = () => {
    setloading(true);
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      "data-dictionary/section_dictionary/";
    axios
      .get(URL, APIConfig())
      .then((response) => {
        setloading(false);
        setSectionDictionary(response.data.data);
        getContractTypeBasedSectionDetails({
          selectedRow: response.data.data[0].contract_type.id,
        });
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const getFieldName = () => {
    setloading(true);
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/field_names/";
    axios
      .get(URL, APIConfig())
      .then((response) => {
        setloading(false);
        console.log(response.data.data, "setSectionDictionary");
        setSectionDictionary(response.data.data);
      })
      .catch((error) => {
        setloading(false);
        console.log(error);
      });
  };

  const getContractTypeBasedSectionDetails = (props: any) => {
    setloading(true);
    let { selectedRow, isCallBack, display_name } = props;
    let getContractBasedSectionDetails =
      process.env.REACT_APP_RIVERUS_CLM_API +
      "data-dictionary/get_field" +
      `?contract_type=${selectedRow}`;
    axios
      .get(getContractBasedSectionDetails, APIConfig())
      .then((response) => {
        let val = response?.data?.data?.map((value: any, index: any) => {
          return {
            ...value,
            isRowSectionInputKey: false,
            isRowSectionDescriptionInputKey: false,
          };
        });
        getDataType();
        setSectionDetailsData(val);
        setSectionSort(response?.data?.sorting);

        setAddField({ data: null, isEdit: false });
        return val;
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const createSection = (payload: any) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      "data-dictionary/section_dictionary/";

    axios
      .post(url, payload, APIConfig())
      .then((response) => {
        setloading(false);
        getContractTypeBasedSectionDetails({
          selectedRow: payload.contract_type[0],
        });
      })
      .catch((error) => setloading(false));
  };

  const deleteSection = (
    sectionId: string,
    contract_id: string,
    setDelete: any
  ) => {
    console.log(sectionId, "contract_id");

    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/section_dictionary/${sectionId}/`;

    axios
      .delete(url, APIConfig())
      .then((response) => {
        setloading(false);
        setselectedSection(null);
        getContractTypeBasedSectionDetails({
          selectedRow: contract_id,
        });
        setDelete(false);
      })
      .catch((error) => setloading(false));
  };

  const getDataType = () => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/data_type/";
    axios
      .get(url, APIConfig())
      .then((response) => {
        setloading(false);
        setDataType(response.data.results);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const getDefaultValue = (dataType: any) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/data_type_default_value/${dataType}`;
    axios
      .post(url, {}, APIConfig())
      .then((response) => {
        setloading(false);
        setDefaultResponse({
          default_field_unit: response.data.default_field_unit,
          default_field_value: response.data.default_field_value,
        });
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const postFieldName = (payload: any, contract_id: string) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/field_names/";

    axios
      .post(url, payload, APIConfig())
      .then((response) => {
        setloading(false);
        getContractTypeBasedSectionDetails({
          selectedRow: contract_id,
        });
      })
      .catch((error) => {
        setloading(false);
        setIsSame(true);
      });
  };

  const updateFieldName = (
    payload: any,
    fieldID: string,
    contract_id: string
  ) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/field_names/${fieldID}/`;

    axios
      .patch(url, payload, APIConfig())
      .then((response) => {
        getContractTypeBasedSectionDetails({
          selectedRow: contract_id,
        });
      })
      .catch((error) => {
        setloading(false);
        setIsSame(true);
      });
  };

  const viewAllFielUpdate = (payload: any, fieldID: string) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/field_names/${fieldID}/`;

    axios
      .patch(url, payload, APIConfig())
      .then((response) => {
        getFieldName();
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const viewAllSectionUpdate = (payload: any, sectionID: string) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/section_dictionary/${sectionID}/`;

    axios
      .patch(url, payload, APIConfig())
      .then((response) => {
        getSectionDictionary();
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const deleteFieldName = (fieldID: string, contract_id: string) => {
    setloading(true);
    let url =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `data-dictionary/field_names/${fieldID}/`;
    axios
      .delete(url, APIConfig())
      .then((response) => {
        setloading(false);
        getContractTypeBasedSectionDetails({
          selectedRow: contract_id,
        });
      })
      .catch((error) => setloading(false));
  };

  const reOrderSection = (payload: any, id: any, type: boolean) => {
    if (type) {
      let url =
        process.env.REACT_APP_RIVERUS_CLM_API +
        `data-dictionary/section_order/${id}/`;
      axios
        .patch(url, payload, APIConfig())
        .then((response: any) => {
          console.log(response);
          getContractTypeBasedSectionDetails({
            selectedRow: id,
          });
        })
        .catch((error) => {});
    } else {
      const postPayload = {
        ...payload,
        contract_type: id,
      };
      let url =
        process.env.REACT_APP_RIVERUS_CLM_API +
        "data-dictionary/section_order/";
      axios
        .post(url, postPayload, APIConfig())
        .then((response: any) => {
          console.log(response);
          getContractTypeBasedSectionDetails({
            selectedRow: id,
          });
        })
        .catch((error) => {});
    }
  };
  return {
    getContractTypeData,
    tableValue,
    selectedRow,
    setSelectedRow,
    isEditScreen,
    setIsEditScreen,
    isSectionDataView,
    setIsSectionDataView,
    SetSelectedContractType,
    sectionDetailsData,
    setSectionDetailsData,
    sectionDetailsParam,
    setSectionDetailsParam,
    selectedContractTypeId,
    setSelectedContractTypeId,
    isListView,
    setIsListView,
    getContractTypeBasedSectionDetails,
    loading,
    setloading,
    addField,
    selectedSection,
    setselectedSection,
    newSection,
    setNewSectionData,
    createSection,
    deleteSection,
    getDataType,
    setDataType,
    dataType,
    getDefaultValue,
    setDefaultResponse,
    defaultResponse,
    setAddField,
    postFieldName,
    deleteFieldName,
    updateFieldName,
    setIsSame,
    isSame,
    getSectionDictionary,
    setSectionDictionary,
    sectionDictionary,
    getFieldName,
    viewAllFielUpdate,
    setTableValues,
    viewAllSectionUpdate,
    reOrderSection,
    isSectionSort,
    totalCount,
    settotalCount,
    pageWiseData,
    getCustomContractTypeData,
    getDefaultContractTypeData,
    defaultContractType,
    customContractType,
    totalDefaultCount,
    totalCustomCount,
    defaultPageWiseData,
    customPageWiseData,
    setDefaultContractType,
    setCustomContractType,
  };
};
