import React from "react";
import axios, { AxiosRequestConfig } from "axios";
import { SITE_API_BY_REALM_NAME } from "../../../Configuration/global";
import {
  ContractDetails,
  InsightsInterface,
  QuickIntel,
} from "../../State/documentState";
import HeimdallUtil from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import AxiosGateWay from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import KeyPoints from "./keyPoints";
import { getBreadcrumbText } from "../Utils/docUtils";
import { getMimeType } from "../../../Utils/UploadCheckUtil.ts/uploadCheckUtil";
import { DarkTooltip } from "../documentInsights";
import { getKeyCloakRealmFromLS } from "../../../Authentication/Actions/authentication";
import { isNullOrUndefined } from "is-what";
import { ClauseErrorList } from "../../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/State/clauseErrorManagementState";
import FileSaver from "file-saver";
import DownloadInsights from "../InsightComponents/saveInsights";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
interface Props {
  fileId: any;
  linkedPage: string;
  setTaskPage: (hideTaskPage: boolean) => void;
  setCurrentTask: (name: string, value: string, contractName: string) => void;
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  fileID: string;
  getClauseType: () => void;
  getDocumentTree: (fileID: string) => void;
  clauseModeStatus: boolean;
  saveClauseModeStatus: (clauseModeStatus: boolean) => void;
  getFolderHeading: () => void;
  setRequestID: (requestID: number) => void;
  setTaskOrigin: (origin: string) => void;
  windowMenu: {
    topMenu: boolean;
    selectedTopMenu: string;
    rightMenu: boolean;
    selectedRightMenu: string;
  };
  handleWindowMenu: (
    menuType: string,
    menuTypeValue: boolean,
    selectedMenu: string
  ) => void;
  insightsData: InsightsInterface[];
  clauseErrorData: ClauseErrorList[];
  contractData: ContractDetails;
  showClauseModal: () => void;
  getSectionDictionary: () => void;
  getProjectData: () => void;
}

interface State {
  quickIntel: QuickIntel;
  hideTaskPage: boolean;
  insight_download: boolean;
}

export default class InfoBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      quickIntel: {
        fileName: "",
        metaData: [],
      },
      hideTaskPage: true,
      insight_download: false,
    };
  }

  componentDidMount() {
    let contract_id = atob(this.props.fileId);

    /*let clause_error_url = SITEAPI + "kdp-clause-error/" + contract_id + "/";
    let clause_error_response = [];
    AxiosGateWay.get(clause_error_url)
      .then((result) => {
        if (result.data.length != 0) {
          metaData.fileName = result.data.file_name;
        }
        clause_error_response = result.data.queryResult as ClauseErrorList[];
        if (clause_error_response.length != 0) {
          let link = document.getElementById("clauseErrorId");
          !isNullOrUndefined(link) && link.click();
        }
      })
      .catch((error) => {});*/

    var url = SITEAPI + "" + contract_id + "/";

    let metaData = {
      fileName: "",
      metaData: [],
    };
    // AxiosGateWay.get(SITEAPI + "document/meta/" + this.props.fileId)
    // console.log("url", url);
    AxiosGateWay.get(url)
      .then((result) => {
        if (result.data.length != 0) {
          metaData.fileName = result.data.file_name;
        }

        let parsed = metaData as QuickIntel; //result.data.queryResult as QuickIntel;

        this.setState({ quickIntel: parsed });
      })
      .catch((error) => {});
  }
  downloadFile() {
    let { quickIntel } = this.state;
    /*let config: AxiosRequestConfig = {
      responseType: "blob",
      headers: {
        Authorization: HeimdallUtil.getTokenFromStorage().accessToken,
      },
    };
    var element = document.createElement("a");
    axios
      .get(SITEAPI + "download/" + this.props.fileId, config)
      .then((result) => {
        var windowUrl = window.URL;
        if (typeof windowUrl.createObjectURL === "function") {
          var blob = new Blob([result.data], {
            type: "application/pdf",
          });
          var url = windowUrl.createObjectURL(blob);
          element.setAttribute("href", url);
          let mimeType = getMimeType(quickIntel.fileName);
          let downloadFormat = "";
          downloadFormat = quickIntel.fileName.replace(mimeType, ".pdf");
          element.setAttribute("download", downloadFormat);
          element.dispatchEvent(
            new MouseEvent(`click`, {
              bubbles: true,
              cancelable: true,
              view: window,
            })
          );
          windowUrl.revokeObjectURL(url);
        }
      })
      .catch((error) => {});
      */
    let contract_id = atob(this.props.fileId);
    AxiosGateWay.get(SITEAPI + "download/" + contract_id + "/")
      .then((result) => {
        if (result && result.data) {
          let blob = fetch(result.data.location).then((r) => {
            r.blob();
            FileSaver.saveAs(result.data.location, result.data.file_name);
          });
        }
      })
      .catch((error) => {});
  }

  onClickTasks = (fileName: string) => {
    let {
      setTaskPage,
      setCurrentTask,
      getAllTasksData,
      fileID,
      getClauseType,
      setRequestID,
      setTaskOrigin,
    } = this.props;
    let { hideTaskPage } = this.state;
    setCurrentTask("", "", fileName);
    setTaskPage(hideTaskPage);
    getAllTasksData(fileID, "0", "", "", false, "");
    getClauseType();
    setRequestID(0);
    setTaskOrigin("infobar");
  };

  render() {
    let { quickIntel, hideTaskPage, insight_download } = this.state;

    let { linkedPage, setTaskPage, fileID, clauseModeStatus } = this.props;
    const { windowMenu, handleWindowMenu } = this.props;

    return (
      <>
        <div style={{ backgroundColor: "#f8f9fa" }}>
          <div className="row">
            <div
              className="col-md-12 mt-4 "
              style={{
                backgroundColor: "unset",
                border: "unset",
                left: "-6vh",
              }}
            >
              <div className="row offset-md-1">
                <div
                  className="col-md-12 ml-4"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "4vh",
                  }}
                >
                  <span
                    className="cursor-pointer"
                    onClick={
                      () => 
                      // window.history.go(-1)
                      // ()=>linkedPage.history.go(-1)
                      (window.location.href = "/" + linkedPage)
                      
                    }
                    
                  >
                    <img
                      className="cursor-pointer"
                      src="/static_images/back-arrow.svg"
                      alt="back-arrow"
                    />
                    &nbsp;
                    {getBreadcrumbText(linkedPage)}&nbsp;{">"}
                    &nbsp;
                  </span>
                  <span> {this.props.contractData.file_name}</span>
                  &nbsp;&nbsp;&nbsp;
                  {/* <span className="cursor-pointer tooltip download-style" onClick={() => this.downloadFile()}><img src="/static_images/download-icn.svg" alt="download" style={{ paddingBottom: '30%' }} />
                                    <span className="tooltiptext">Download as PDF</span>
                                </span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 " style={{ left: "-11vh" }}>
              <div className="row offset-md-1">
                <div className="col-md-12 " style={{ left: "6.5vh" }}>
                  <div
                    className="row px-1 mx-4 "
                    style={{
                      backgroundColor: "hsla(0,0%,100%,.8)",
                      borderRadius: "20px",
                      left: "0",
                    }}
                  >
                    <div
                      className="col-md-10"
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <DarkTooltip title={"Download"} placement="bottom">
                        <span
                          className="cursor-pointer tooltip download-style"
                          onClick={() => this.downloadFile()}
                        >
                          <img
                            src="/static_images/file-download.svg"
                            alt="download"
                          />
                        </span>
                      </DarkTooltip>
                      <span
                        className="cursor-pointer tooltip download-style"
                        style={{ marginLeft: "2rem", display: "none" }}
                        data-toggle="modal"
                        data-target="#clauseErrorModal"
                        id="clauseErrorId"
                      ></span>

                      <DarkTooltip
                        title={"Add to clause library"}
                        placement="bottom"
                      >
                        <span
                          className="cursor-pointer tooltip download-style"
                          style={{ marginLeft: "2rem" }}
                          onClick={() => 
                          {
                            // this.props.showClauseModal();
                            this.switchClauseMode();
                          }
                          }
                        >
                          {clauseModeStatus ? (
                            <img
                              src="/static_images/add-to-clause-library-icn.svg"
                              alt="add-to-clause"
                            />
                          ) : (
                            <img
                              src="/static_images/add-to-clause-library-icn.svg"
                              alt="add-to-clause"
                            />
                          )}
                        </span>
                      </DarkTooltip>
                      <DarkTooltip title={"Tasks"} placement="bottom">
                        <span
                          className="cursor-pointer tooltip download-style"
                          style={{ marginLeft: "2rem" }}
                          onClick={() =>
                            /*console.log(
                                      "On click Clause ",
                                      quickIntel.fileName,
                                      "+++",
                                      hideTaskPage
                                    )*/
                            this.setState(
                              {
                                hideTaskPage: !hideTaskPage,
                              },
                              () => this.onClickTasks(quickIntel.fileName)
                            )
                          }
                        >
                          <img
                            src="/static_images/task-icn-2.svg"
                            alt="tasks"
                          />
                        </span>
                      </DarkTooltip>
                    </div>
                    <div
                      className="col-md-2"
                      style={{
                        textAlign: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          insight_download: true,
                        });
                      }}
                    >
                      {/* <DownloadInsights
                      insightsData={this.props.insightsData}
                      quickIntel={quickIntel}
                    /> */}
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-2" style={{ textAlign: 'right' }}>
                                <img src="/static_images/quick-intel.svg" alt="quick-intelligence" />
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  switchClauseMode() {
    let { clauseModeStatus } = this.props;
    this.props.saveClauseModeStatus(!clauseModeStatus);
    this.props.getFolderHeading();
    this.props.getSectionDictionary();
    this.props.getProjectData();
  }
}