import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { getContractTypes } from "../../ContractTypes/DA";
import { ContractTypeRes } from "../../ContractTypes/State/ContractTypeState";
import {
  initialTemplateData,
  TemplateData,
} from "../../Templates/State/templateState";
import { getDateStringToDate } from "../../Utils/DataModifierUtil/dataModUtil";
import {
  deleteProjects,
  editContractMetaData,
  getcontracts,
  getexternalgroups,
  getProjects,
  getTemplates,
  getUsers,
  postProjects,
  sendemailtoapproval,
  uploadContractMetaData,
} from "../DA";
import {
  ContractData,
  ContractRes,
  CounterParty,
  defaultErrorValidation,
  defaultUser,
  draftStatus,
  ErrorValidation,
  initialContractData,
  Signatory,
  User,
} from "../State/DraftState";
import UploadFileDialog from "./UploadFileDialog";
import useMultipleUsers from "./useMultipleUsers";
import { BsFillCheckCircleFill, BsBoxArrowUpRight } from "react-icons/bs";
import { IconContext } from "react-icons";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import ForApproval from "./ForApproval";
import { getBlobDocument } from "../../Templates/Component/DocGenerate";
import { Blank_Document_link } from "../../Constants/const";
import { min } from "moment";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { data } from "jquery";
import PdfViewer from "./PdfViewerDialog";
import WarningPopup from "./WarningPopup";

interface Props {
  fromTemplate: boolean;
  onClose: (fieldInputs: any) => void;
  open: boolean;
  routeParams: any;
  isEdit: boolean;
  getDrafts: () => void;
  myDraft: any;
  contractData: ContractRes | null;
  uploadFile: (post: any, fileType: any, callback: Function) => void;
  setTemplate: any;
  createLink: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CreateDraftDialog = (props: Props) => {
  const [templateData, setTemplateData] =
    useState<TemplateData>(initialTemplateData);
  const [templates, setTemplates] = useState<TemplateData[]>([]);
  const [templatesAll, setTemplatesAll] = useState<TemplateData[]>([]);
  const [open, setOpen] = useState(false);
  const [selectContractFrom, setSelectContractFrom] = useState("template");
  const [contractData, setContractData] =
    useState<ContractData>(initialContractData);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [existingContractData, setExistContractData] = useState("");
  const [deadlineError, setdeadlineError] = useState(false);
  const [approvers, setApprovers] = useState<string[]>([]);
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [notifiers, setnotifiers] = useState<string[]>([]);
  const [signatories, setsignatories] = useState<string[]>([]);
  const [partyemail, setpartyemail] = useState<any>("");
  const [user, setUser] = useState<any>();
  const [alldrafts, setalldrafts] = useState<any[]>(props.myDraft);
  const [reqresponse, setreqresponse] = useState<any[]>([]);
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [disable, setdisable] = useState<any>(false);
  const [openpdfviewer, setopenpdfviewer] = useState(false);
  const [draftLink, setDraftLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [draftDetails, setdraftDetails] = useState<any>();
  const [disableInput, setDisableInput] = useState(false);
  const [showWarningPopup, setWarningPopup] = useState<any>({
    isShow: false,
    tagValue: "",
    type: "",
  });

  // Need to change the functionlaity
  useEffect(() => {
    const user_data: string | null = localStorage.getItem("user_profile");
    if (user_data) {
      setUser(JSON.parse(user_data));
    }
  }, []);

  const {
    approverList,
    collaboratorList,
    currentUser,
    signatoriesList,
    groupList,
  } = useMultipleUsers();
  const [contractTypeList, setcontractTypeList] = useState<ContractTypeRes[]>(
    []
  );
  const [errorValidation, seterrorValidation] = useState<ErrorValidation>(
    defaultErrorValidation
  );
  const [projectList, setProjectList] = useState<any[]>([]);
  const [toggleAdd, settoggleAdd] = useState(false);
  const [postProjectName, setPostProjectName] = useState("");
  const [openUploadNew, setopenUploadNew] = useState(false);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [groups, setgroups] = useState<string[]>([]);
  const [approverMap, setapproverMap] = useState<any[]>([]);
  const [approverSequence, setapproverSequence] = useState("");
  const [signatoriesMap, setsignatoriesMap] = useState<any[]>([]);
  const [externalData, setExternal] = useState<any[]>([]);
  const [externalDataall, setExternalall] = useState<any[]>([]);
  const [openapproval, setopenapproval] = useState(false);
  const [executedcontractdata, setexecutedcontractdata] = useState<any[]>([]);
  const [executedcontractdataAll, setexecutedcontractdataAll] = useState<any[]>(
    []
  );
  const [filterexternalgroups, setfilterexternalgroups] = useState<any[]>([]);
  const [contractfileexten, setcontractfileexten] = useState<any>("");
  const [earlierdraftlink, setearlierdraftlink] = useState<any>("");
  const [fileData, setFileData] = useState("");
  const [requisitionId, setRequisitionId] = useState("");
  const [version, setversion] = useState("");

  const handleSelectContractFrom = (event: any) => {
    setSelectContractFrom(event.target.value);
    if (event.target.value == "template") {
      _getTemplates();
    }
    setContractData({ ...contractData, createFrom: event.target.value });
  };
  useEffect(() => {
    handleExternalSelect();
    handleexecutedcontract();
  }, []);
  useEffect(() => {
    if (groupList && groupList?.length) {
      let filterexternals = groupList?.filter((item: any) => {
        return item?.name.toLowerCase().includes("external");
      });
      let splitdatahiphen = filterexternals?.map((item: any) => {
        return item?.name?.split("-")[1];
      });
      let splitdataunderscore = filterexternals?.map((item: any) => {
        return item?.name?.split("_")[1];
      });
      const spreadboth = [...splitdatahiphen, ...splitdataunderscore]?.filter(
        (item: any) => item !== undefined
      );

      if (filterexternals && filterexternals?.length) {
        setfilterexternalgroups(spreadboth);
      }
    }
  }, [groupList]);
  useEffect(() => {
    getContractType();
    if (props.fromTemplate && props.routeParams) {
      const { template, updatedFields } = props.routeParams;
      setSelectContractFrom("template");
      setTemplateData(template);
      setTemplates([template]);
      setTemplatesAll([template]);
      setDynamicFields(updatedFields);
      setContractData({
        ...contractData,
        contractType: template.templateType,
        createFrom: "template",
      });
    } else {
      _getTemplates();
    }
    getProjectData();
    return () => {
      setSelectContractFrom("exec");
      setTemplateData(initialTemplateData);
      setTemplates([]);
      setTemplatesAll([]);
    };
  }, [props.fromTemplate]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.isEdit && props.contractData) {
      setContractData({
        ...contractData,
        ...props.contractData,
        deadline: getDateStringToDate(props.contractData.deadline),
        notes: props.contractData.notes || "",
      });
      setTemplateData({ ...templateData, name: props.contractData.source });
      setSelectContractFrom(props.contractData.createFrom);
      setDisableInput(
        props.isEdit &&
          !(
            props.contractData.status == draftStatus.INTERNAL_DRAFT_CREATED ||
            props.contractData.status ==
              draftStatus.REQUISITION_APRROVAL_PENDING
          )
      );
      if (props.contractData.approvalTypes.length) {
        let approvalObj = props.contractData.approvalTypes.find(
          (obj) => obj.approval_type_name == "Contract Requisition Approvers"
        );
        if (
          approvalObj &&
          approvalObj.approvers &&
          approvalObj.approvers.length
        ) {
          let usersMap = approvalObj.approvers.map((userInfo: any) => {
            return userInfo.first_name;
          });
          setApprovers([...usersMap]);
        }
        if (approvalObj) {
          setapproverSequence(approvalObj.approval_sequence);
        }
      }
      if (props.contractData.collaborators.length > 0) {
        let usersMap = props.contractData.collaborators.map((userData) => {
          return userData.first_name;
        });
        setCollaborators([...usersMap]);
      }
      if (props.contractData.signatories.length > 0) {
        let usersMap = props.contractData.signatories
          .filter((data) => data)
          .map((userData) => {
            return userData.first_name;
          });
        setsignatories([...usersMap]);
      }
    } else {
      let counterParty = contractData.counterParties[0];
      setContractData({ ...contractData, counterParties: [counterParty] });
    }
  }, [props.isEdit]);

  useEffect(() => {
    let usersMap = approvers.map((name) => {
      let user = approverList.find((value) => value.first_name == name);
      return user;
    });
    if (usersMap) {
      let modifiedUsers = usersMap.map((user) => {
        return {
          ...user,
          assigned_date: new Date().toLocaleDateString(),
          approved_date: null,
          approval_status: "Requisition Approval Pending",
        };
      });
      let approverMapList = [...approverMap];
      let approverObj = approverMapList.find(
        (value) => value.approval_type_name == "Contract Requisition Approvers"
      );
      if (approverObj) {
        approverObj.approvers = [...modifiedUsers];
        setapproverMap(approverMapList);
      } else {
        let obj = {
          approvers: [...modifiedUsers],
          approval_type_name: "Contract Requisition Approvers",
        };
        approverMapList[0] = { ...obj };
        setapproverMap([...approverMapList]);
      }
    }
  }, [approvers, approverList]);

  useEffect(() => {
    let usersMap = signatories.map((name) => {
      let user = signatoriesList.find((value) => value.first_name == name);
      return user;
    });
    if (usersMap) {
      let modifiedUsers = usersMap
        .map((user) => {
          return {
            ...user,
            assigned_date: new Date().toLocaleDateString(),
            signed_date: null,
            status: "Requisition Approval Pending",
          };
        })
        .filter((data) => data);
      setsignatoriesMap([...modifiedUsers]);
    }
  }, [signatories]);

  useEffect(() => {
    setapproverSequence("All");
    let approverMapList = [...approverMap];
    let obj = {
      approvers: [],
      approval_type_name: "Contract Requisition Approvers",
      approval_sequence: "All",
    };
    approverMapList[0] = { ...obj };
    setapproverMap(approverMapList);
  }, []);

  const handleExternalSelect = async () => {
    try {
      let getexternalgroua: any = await getexternalgroups();

      if (getexternalgroua && getexternalgroua.length) {
        setExternal(Array.from(new Set(getexternalgroua)));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleexecutedcontract = async () => {
    try {
      const getcontractdata = await getcontracts();
      if (getcontractdata?.results && getcontractdata?.results?.length) {
        setexecutedcontractdata(getcontractdata?.results);
        setexecutedcontractdataAll(getcontractdata?.results);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const _getTemplates = async () => {
    try {
      setLoading(true);
      let response = await getTemplates();
      if (response && response.results.length > 0) {
        let templates = response.results.map((res: any) => {
          let template: TemplateData = {
            name: res.file_name,
            createdOn: new Date(res.created_on).toDateString(),
            owner: res.created_by,
            lastUsed: "",
            templateLink: res.file_path,
            templateType: res.template_type || "",
            fileId: res.id,
            filePath: res.file_path,
            contract_type: res.contract_type,
          };
          return template;
        });
        setTemplates(templates);
        setTemplatesAll(templates);
      } else if (response && response.results.length == 0) {
        setTemplates([]);
        setTemplatesAll([]);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getProjectData = async () => {
    try {
      let response = await getProjects();
      if (response && response.results.length > 0) {
        setProjectList(response.results);
      } else {
        setProjectList([]);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });
  const getContractType = async () => {
    try {
      const response = await getContractTypes();
      if (response && response.results.length > 0) {
        const sorted = response.results.sort((a: any, b: any) => {
          if (a.displayName > b.displayName) {
            return 1;
          } else {
            return -1;
          }
        });
        setcontractTypeList(sorted);
      } else if (response && response.results.length == 0) {
        setcontractTypeList([]);
      }
    } catch (error) {
      console.error("getContractType", error);
    }
  };
  const handleCloseviewer = () => {
    setopenpdfviewer(false);
    setOpen(false);
    props.onClose(null);
  };

  const handleOrgName = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].orgName = event.target.value;
    setContractData({ ...contractData, counterParties: data });
    const filtercontactname = externalData?.filter((item: any) => {
      let groupPersent = item?.groups?.filter((val: any) => {
        if (val.includes(event.target.value)) {
          return val;
        }
      });
      if (groupPersent.length) {
        return item;
      }
    });
    setExternalall(filtercontactname);
  };
  const handlemanualorgname = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].orgName = event.target.value;
    setContractData({ ...contractData, counterParties: data });
    // const filtercontactname = externalData?.filter((item: any) => {
    //   let groupPersent = item?.groups?.filter((val: any) => {
    //     if (val.includes(event.target.value)) {
    //       return val;
    //     }
    //   });
    //   if (groupPersent.length) {
    //     return item;
    //   }
    // });
    // setExternalall(filtercontactname);
  };
  const handlemanualContactNameWithEmail = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].orgContactName = event.target.value;
    setContractData({ ...contractData, counterParties: data });
  };
  const handlemanualEmail = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].email = event.target.value;
    setContractData({ ...contractData, counterParties: data });
  };

  const handleContactName = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].orgContactName = event.target.value;
    setContractData({ ...contractData, counterParties: data });
  };
  const handleEmail = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].email = event.target.value;
    setContractData({ ...contractData, counterParties: data });
  };
  const handleExecutedContractData = (event: any, index: number) => {
    const filter = executedcontractdataAll?.filter(
      (item: any) => item.id == event.target.value
    );
    const splitted = filter?.length ? filter[0]?.file_name.split(".") : "";
    const fileextension = filter?.length ? splitted[splitted.length - 1] : "";

    setcontractfileexten(fileextension);
    if (filter?.length && filter[0].contract_type.length) {
      setContractData({
        ...contractData,
        contractType: filter[0]?.contract_type[0].id,
        source: event.target.value,
      });
    } else {
      setContractData({ ...contractData, source: event.target.value });
    }
    // setselectedcontract(event.target.value)
  };
  const common = async (data: any) => {
    let draftpayload: any = {
      createFrom: data.createFrom,
      contractName: data.contractName,
      link: data.link ? data.link : null,
      contractType: data.contractType?.id,
      groups: data.groups,
      projectNames: data.projectNames,
      counterParties: data.counterParties,
      collaborators: data?.collaborators?.map((i: any) => i.id),
      approvalTypes: data.approvalTypes,
      signatories: data.signatories,
      version: 1,
      owner: [data.owner.id],
      status:
        data.createFrom == "counter_party"
          ? draftStatus.COUNTER_PARTY_DRAFT_CREATED
          : data.createFrom === "template"
          ? draftStatus.DRAFT_CREATION_PENDING
          : draftStatus.INTERNAL_DRAFT_CREATED,
      deadline: data.deadline,
      earlier_draft_link: data.source,
      requisition_approver_link: data.requisition_approver_link,
      source: data.source,
      requisition_id: data.id,
      counter_party_link:
        "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/2a1ad994-d45a-452d-a287-3e4ad45da51b.docx",
      template_link: data.template_link,
      support_document_link:
        "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/2a1ad994-d45a-452d-a287-3e4ad45da51b.docx",
      executed_contract_link: `${data.source}.${contractfileexten}`,
      logs: {
        action: `Draft Created`,
        action_on: [user.id],
      },
    };
    if (data.createFrom == "earlier_draft") {
      [
        "template_link",
        "counter_party_link",
        "support_document_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (data.createFrom == "template") {
      [
        "earlier_draft_link",
        "counter_party_link",
        "support_document_link",
        "executed_contract_link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (data.createFrom == "counter_party") {
      [
        "earlier_draft_link",
        "template_link",
        "support_document_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (data.createFrom == "support_document") {
      [
        "earlier_draft_link",
        "template_link",
        "counter_party_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else {
      [
        "earlier_draft_link",
        "template_link",
        "counter_party_link",
        "support_document_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    }

    axios
      .post(
        process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/`,
        draftpayload,
        APIConfig()
      )
      .then((res: any) => {
        setDraftLink(res.data.link);
        setFileName(res.data.contractName);
        setdraftDetails(res.data);
        if (res.data.createFrom === "template") {
          props.setTemplate(res.data);
        } else {
          setopenpdfviewer(true);
        }
        props.getDrafts();
      })
      .catch((err: any) => console.log(err));
  };
  const uploadBlankDoc = (data: any) => {
    if (data) {
      setLoading(true);
      let keyValuePair = {
        nameofthedraft: data?.contractName,
        contracttypefield: data?.contractType?.name,
        createcontractfromfield: "createFrom",
        selecttemplatefield: data.createFrom,
        projects: data.projectNames,
        counterParty: data?.counterParties.map((value: any) => {
          return { name: value.orgContactName, email: value.email };
        }),
        groups: data.groups,
        collaborators:
          data.collaborators && data.collaborators.length
            ? data.collaborators.map(
                (value: any) => value.first_name + " " + value.last_name
              )
            : [],
        deadlinefield: data.deadline,
        hasAll: data.approvalTypes.length
          ? data.approvalTypes[0].approval_sequence == "All"
          : false,

        hasAny: data.approvalTypes.length
          ? data.approvalTypes[0].approval_sequence == "Any"
          : false,

        hasSequence: data.approvalTypes.length
          ? data.approvalTypes[0].approval_sequence == "In Sequence"
          : false,
        signatories: data.signatories.map(
          (value: any) => value.first_name + " " + value.last_name
        ),
        approvers: data.approvalTypes.length
          ? data.approvalTypes[0].approvers.map(
              (value: any) => value.first_name + " " + value.last_name
            )
          : [],
        notesfield: data.notes,
      };
      getBlobDocument(
        keyValuePair,
        Blank_Document_link.TEMPLATE_BLANK_DOC,
        (response: any) => {
          const file = response;
          file["name"] = data.contractName + ".docx";
          let reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
              setLoading(true);
              props.uploadFile([file], true, (response: any, error: any) => {
                if (response && data.approvalTypes[0]?.approvers.length) {
                  setFileData(response.access_url);
                  // setOpen(false);
                  // props.onClose({response:response,contract:props.contractData,action:'cancel'});
                  setLoading(false);
                  setopenapproval(true);
                } else if (data.createFrom !== "template") {
                  common(data);
                } else {
                  props.createLink(data, common);
                }
                if (error) {
                  console.error(error);
                  setLoading(false);
                }
              });
            };
          }
        }
      );
    } else {
      handleClose();
    }
  };

  const sendEmail = async (id: any, response: any, newData: any) => {
    id.forEach((element: any) => {
      const item = newData.filter((val: any) => val.id == element);
      let payload = {
        user_ids: [element],
        body_html: `<h2>Hello ${
          item.length && item[0].first_name
        },<br/></h2><p>I am creating a new contract on Riverus Contracts Platform and requesting you to approve ${
          response.data.contractName
        }.
    </p><p>You may check the details of the draft by <a href="${
      window.location.origin
    }/draftingreview/${
          response.data.id
        }" target="_blank">clicking here </a></span>and take further action.</p>
    <p>The deadline for the draft is ${
      response.data.deadline
    }.</p><p> Thank you in advance for your timely action.</p>
    <h4>Regards,</h4><h4>${response.data?.owner.first_name}</h4>`,
        body_text: `${user.first_name} raised requisition approval for ${response.data.contractName}. Kindly review and approve the same.`,
        subject: `Requisition approval raised for ${response.data.contractName}`,
      };
      try {
        sendemailtoapproval(payload);
      } catch (err) {
        console.log(err, "err");
      }
    });
  };
  const handleRequisition = () => {
    setSelectedUsers(contractData);
    if (!checkValidation()) {
      return;
    } else {
      const earlier = {
        createFrom: contractData.createFrom,
        contractName: contractData.contractName,
        projectNames: contractData.projectNames,
        contractType: contractData.contractType,
        counterParties: contractData.counterParties,
        groups: contractData.groups,
        approvalTypes: contractData.approvalTypes,
        signatories: contractData.signatories,
        owner: [user.id],
        collaborators: contractData.collaborators,
        status:
          contractData?.approvalTypes &&
          contractData?.approvalTypes[0]?.approvers.length
            ? draftStatus.REQUISITION_APRROVAL_PENDING
            : draftStatus.REQUISITION_APPROVED,
        deadline: contractData.deadline,
        earlier_draft_link: earlierdraftlink,
        source: earlierdraftlink,
        notes: contractData.notes,
        draft_created:
          contractData.approvalTypes &&
          contractData?.approvalTypes[0]?.approvers.length
            ? false
            : true,
        pre_signatories: [],
      };
      const execont = {
        createFrom: contractData.createFrom,
        contractName: contractData.contractName,
        projectNames: contractData.projectNames,
        contractType: contractData.contractType,
        counterParties: contractData.counterParties,
        groups: contractData.groups,
        approvalTypes: contractData.approvalTypes,
        signatories: contractData.signatories,
        owner: [user.id],
        collaborators: contractData.collaborators,
        status:
          contractData?.approvalTypes &&
          contractData?.approvalTypes[0]?.approvers.length
            ? draftStatus.REQUISITION_APRROVAL_PENDING
            : draftStatus.REQUISITION_APPROVED,
        deadline: contractData.deadline,
        executed_contract_link: `${contractData?.source}.${contractfileexten}`,
        source: contractData.source,
        notes: contractData.notes,
        draft_created:
          contractData.approvalTypes &&
          contractData?.approvalTypes[0]?.approvers.length
            ? false
            : true,
        pre_signatories: [],
      };
      let newData: any = [...contractData.approvalTypes[0]?.approvers];
      const filter = newData?.findIndex(
        (item: any, index: any) => item?.id === user.id
      );

      if (contractData?.source !== "") {
        setdisable(true);
        setLoading(true);
        AxiosGateWay.post(
          process.env.REACT_APP_RIVERUS_CLM_API + `stylus/requisition_form/`,
          contractData?.createFrom === "executed_contract" ? execont : earlier
        )
          .then((response) => {
            setRequisitionId(response.data.id);
            uploadBlankDoc(response.data);
            setreqresponse(response.data);
            setversion(response.data.version);
            setLoading(false);
            props.getDrafts();
            if (
              response.data?.approvalTypes.length &&
              response.data?.approvalTypes[0]
            ) {
              const data = response.data?.approvalTypes[0];
              if (data.approval_sequence == "In Sequence") {
                const id = [newData[0].id];
                sendEmail(id, response, newData);
              } else {
                const id = newData?.map((item: any) => item.id);
                sendEmail(id, response, newData);
              }
            }
          })
          .catch((error) => {
            if (error?.response) {
              setopenSnackbar({
                open: true,
                msg: error.response?.data?.contractName[0],
              });
              setLoading(true);
            }
          });
      } else {
        setopenSnackbar({
          open: true,
          msg: "Please Select another contract type",
        });
      }
    }
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });
    setdisable(false);
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );
  const addCounterParty = () => {
    let newField: CounterParty = {
      orgContactName: "",
      orgName: "",
      email: "",
      isExternalSignatory: false,
      disable: false,
    };
    // let orgName = localStorage.getItem("kcRealm");
    // if (orgName) {
    //   newField.orgName = orgName;
    // }
    let counterParty = [...contractData.counterParties, newField];
    setContractData({ ...contractData, counterParties: counterParty });
  };
  const addmanuallydata = (index: any) => {
    let counterParty = [...contractData.counterParties];
    counterParty[index].disable = true;
    counterParty[index].email = "";
    counterParty[index].orgContactName = "";
    counterParty[index].orgName = "";
    setContractData({ ...contractData, counterParties: counterParty });
  };

  const handleIsExternal = (event: any, index: number) => {
    let data = [...contractData.counterParties];
    data[index].isExternalSignatory = !data[index].isExternalSignatory;
    setContractData({ ...contractData, counterParties: data });
  };

  const deletemanuallydata = (index: any) => {
    let data = [...contractData.counterParties];
    data[index].disable = false;
    data[index].email = "";
    data[index].orgContactName = "";
    data[index].orgName = "";
    setContractData({ ...contractData, counterParties: data });
  };
  const deleteCounterParty = (index: number) => {
    let data = [...contractData.counterParties];
    data.splice(index, 1);
    setContractData({ ...contractData, counterParties: data });
  };

  const handleContractName = (event: any) => {
    setContractData({ ...contractData, contractName: event.target.value });
  };

  const handleContractType = (event: any) => {
    const filter = props.myDraft?.filter(
      (item: any) => item?.contractType?.id === event.target.value
    );
    const contractfilter: any = executedcontractdataAll?.filter((val: any) => {
      const contractType: any = val?.contract_type?.map((data: any) => data.id);
      if (contractType.includes(event.target.value)) {
        return val;
      }
    });
    const templatefilter: any = templatesAll?.filter((val: any) => {
      const templateType: any = val?.contract_type?.id;
      if (templateType === event.target.value) {
        return val;
      }
    });
    setTemplates(templatefilter);
    setexecutedcontractdata(contractfilter);
    // setexecutedcontractdata(contractfilter);
    setalldrafts(filter);
    setContractData({ ...contractData, contractType: event.target.value });
  };

  const handleDeadline = (event: any) => {
    setdeadlineError(false);
    setContractData({ ...contractData, deadline: event.target.value });
  };

  const handleApproverSequence = (event: any) => {
    setapproverSequence(event.target.value);
    let approverMapList = [...approverMap];
    let approverObj = approverMapList.find(
      (value) => value.approval_type_name == "Contract Requisition Approvers"
    );
    if (approverObj) {
      approverObj["approval_sequence"] = event.target.value;
      setapproverMap(approverMapList);
    } else {
      let obj = {
        approvers: [],
        approval_type_name: "Contract Requisition Approvers",
        approval_sequence: event.target.value,
      };
      approverMapList[0] = { ...obj };
      setapproverMap(approverMapList);
    }
  };

  const handleTemplate = (event: any) => {
    let templateName = event.target.value;
    let template = templates.find((value) => value.name == templateName);
    if (template != undefined) {
      setTemplateData(template);
      // if (template.templateType != undefined) {
      //   setContractData({
      //     ...contractData,
      //     contractType: template.contract_type,
      //   });
      // }
    }
  };

  const handleExistContractData = (event: any) => {
    const filter = alldrafts?.filter(
      (item: any) => item.contractName == event.target.value
    );
    setearlierdraftlink(filter[0]?.link);
    filter?.length &&
      setContractData({
        ...contractData,
        contractType: filter[0]?.contractType?.id,
      });
    setExistContractData(event.target.value);
  };
  const handleContactNameWithEmail = (event: any, index: any) => {
    let data = [...contractData.counterParties];
    data[index].orgContactName = event.target.value;
    const email = externalData?.filter(
      (val: any) => val.first_name == event.target.value
    );
    if (email && email.length) {
      data[index].email = email[0]?.email;
      setContractData({ ...contractData, counterParties: data });
    }
  };

  const editOrCreateDraft = () => {
    setSelectedUsers(contractData);
    if (!checkValidation()) {
      return;
    }
    if (props.isEdit) {
      uploadContractData(contractData);
    } else {
      contractData.status = draftStatus.REQUISITION_APRROVAL_PENDING;
      // if (contractData.approvers.length > 0) {
      //     contractData.status = 'Approval Pending'
      // }
      if (selectContractFrom == "template") {
        contractData.source = "T-" + templateData.name;
      } else if (selectContractFrom == "upload") {
        contractData.source = "N-" + templateData.name;
      } else if (selectContractFrom == "exec") {
        contractData.source = "C-" + templateData.name;
      } else if (selectContractFrom == "earlier_draft") {
        contractData.source = "D-" + templateData.name;
      } else if (selectContractFrom == "blank") {
        contractData.source = "B-" + templateData.name;
      } else {
        contractData.source = templateData.name || "";
      }
      const templatePayload = {
        createFrom: selectContractFrom,
        contractName: contractData.contractName,
        projectNames: contractData.projectNames,
        contractType: contractData.contractType,
        counterParties: contractData.counterParties,
        groups: contractData.groups,
        approvalTypes: contractData.approvalTypes,
        signatories: contractData.signatories,
        owner: [user.id],
        collaborators: contractData.collaborators,
        status: contractData.status,
        deadline: contractData.deadline,
        template_link: templateData.filePath,
        source: "",
        notes: contractData.notes,
        draft_created:
          contractData.approvalTypes &&
          contractData?.approvalTypes[0]?.approvers.length
            ? false
            : true,
        pre_signatories: [],
      };
      let newData: any = [...contractData.approvalTypes[0]?.approvers];
      const filter = newData?.findIndex(
        (item: any, index: any) => item?.id === user.id
      );
      if (templateData.filePath) {
        AxiosGateWay.post(
          process.env.REACT_APP_RIVERUS_CLM_API + `stylus/requisition_form/`,
          templatePayload
        )
          .then((response) => {
            setRequisitionId(response.data.id);
            uploadBlankDoc(response.data);
            setreqresponse(response.data);
            setversion(response.data.version);

            if (
              response.data?.approvalTypes.length &&
              response.data?.approvalTypes[0]
            ) {
              const data = response.data?.approvalTypes[0];
              if (data.approval_sequence == "In Sequence") {
                const id = [newData[0].id];
                sendEmail(id, response, newData);
              } else {
                const id = newData?.map((item: any) => item.id);
                sendEmail(id, response, newData);
              }
            }
            // setopenapproval(true);
            // setOpen(false);
            // setOpen(false);
            // props.onClose({
            //   contractData: contractData,
            //   templateData: templateData,
            //   dynamicFields: dynamicFields,
            // });
          })
          .catch((error) => {
            if (error?.response) {
              setopenSnackbar({
                open: true,
                msg: error.response?.data?.contractName[0],
              });
            }
          });
      } else {
        setopenSnackbar({
          open: true,
          msg: "Please Select another contract type",
        });
      }
    }
  };

  const checkValidation = () => {
    let isValid = true;
    let errorObj = { ...errorValidation };
    if (contractData.contractName == "") {
      const {
        draftName: { errorMsg },
      } = errorValidation;
      errorObj = {
        ...errorObj,
        draftName: { showError: true, errorMsg: errorMsg },
      };
      isValid = false;
    }
    // if (contractData.projectNames.length == 0) {
    //     const { projectName: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, projectName: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    if (contractData.contractType == "") {
      const {
        contractType: { errorMsg },
      } = errorValidation;
      errorObj = {
        ...errorObj,
        contractType: { showError: true, errorMsg: errorMsg },
      };
      isValid = false;
    }
    // if (contractData.deadline == '') {
    //     const { deadline: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, deadline: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    // if (contractData.counterParties.length < 2) {
    //     const { counterParty: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, counterParty: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    // if (contractData.collaborators.length == 0) {
    //     const { collaborators: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, collaborators: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    if (
      selectContractFrom == "counter_party" ||
      selectContractFrom == "support_document"
    ) {
      if (approvers.length == 0) {
        const {
          approvers: { errorMsg },
        } = errorValidation;
        errorObj = {
          ...errorObj,
          approvers: { showError: true, errorMsg: errorMsg },
        };
        isValid = false;
      }
    }

    if (selectContractFrom == "earlier_draft") {
      if (alldrafts.length == 0) {
        const {
          earlierdraft: { errorMsg },
        } = errorValidation;
        errorObj = {
          ...errorObj,
          earlierdraft: { showError: true, errorMsg: errorMsg },
        };
        isValid = false;
      }
    }

    // if (contractData.notifiers.length == 0) {
    //     const { notifiers: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, notifiers: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    // if (contractData.signatories.length == 0) {
    //     const { signatories: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, signatories: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    // if (selectContractFrom == 'template' && templateData.name == '') {
    //     const { templateData: { errorMsg } } = errorValidation;
    //     errorObj = { ...errorObj, templateData: { showError: true, errorMsg: errorMsg } };
    //     isValid = false;
    // }
    if (approvers.length && approverSequence == "") {
      const {
        approverSequence: { errorMsg },
      } = errorValidation;
      errorObj = {
        ...errorObj,
        approverSequence: { showError: true, errorMsg: errorMsg },
      };
      isValid = false;
    }
    seterrorValidation({ ...errorValidation, ...errorObj });
    return isValid;
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose(null);
  };

  const uploadContractData = async (contractData: any) => {
    try {
      setLoading(true);
      if (props.contractData) {
        let contract: any = { ...contractData };
        delete contract["owner"];
        contract.signatories = contractData.signatories.filter(
          (data: any) => data
        );
        const response = await editContractMetaData(
          props.contractData.id,
          contract
        );
        if (response) {
          setOpen(false);
          setTimeout(() => {
            props.getDrafts();
          }, 500);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApprover = (event: any) => {
    const {
      target: { value },
    } = event;
    setApprovers(typeof value === "string" ? value.split(",") : value);
  };

  const handleCollaborator = (event: any) => {
    const {
      target: { value },
    } = event;
    setCollaborators(typeof value === "string" ? value.split(",") : value);
  };

  // const handleNotifiers = (event: any) => {
  //     const {
  //         target: { value },
  //     } = event;
  //     setnotifiers(typeof value === 'string' ? value.split(',') : value);
  // }

  const handleSignatories = (event: any) => {
    const {
      target: { value },
    } = event;
    setsignatories(typeof value === "string" ? value.split(",") : value);
  };
  const handleProjectNames = (event: any) => {
    const {
      target: { value },
    } = event;
    setContractData({
      ...contractData,
      projectNames: typeof value === "string" ? value.split(",") : value,
    });
  };

  const addProjectName = async () => {
    if (postProjectName !== "") {
      setLoading(true);
      const response = await postProjects({ name: postProjectName });
      if (response) {
        setProjectList([...projectList, response]);
        setPostProjectName("");
        settoggleAdd(!toggleAdd);
        setContractData({
          ...contractData,
          projectNames:
            typeof postProjectName === "string"
              ? postProjectName.split(",")
              : postProjectName,
        });
      }
      setLoading(false);
    }
  };

  const setSelectedUsers = (contract: ContractData) => {
    if (approverMap.length > 0) {
      contract.approvalTypes = approverMap;
    }
    if (collaborators.length > 0) {
      let usersMap = collaborators.map((name) => {
        let user = collaboratorList.find((value) => value.first_name == name);
        if (user != undefined) return user.id;
        return "";
      });
      contract.collaborators = usersMap;
    }
    if (signatoriesMap.length > 0) {
      contract.signatories = [...signatoriesMap];
    }
  };

  const openApprovalsTab = () => {
    window.open("/approvals", "_blank");
  };

  const createDraftWithoutDoc = async () => {
    try {
      setSelectedUsers(contractData);
      if (!checkValidation()) {
        return;
      }
      setLoading(true);
      const response = await uploadContractMetaData(contractData);
      if (response) {
        setOpen(false);
        setTimeout(() => {
          props.getDrafts();
        }, 500);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const uploadAndCreateDraft = async (preSignedUrl: any) => {
    try {
      setSelectedUsers(contractData);
      if (!checkValidation()) {
        return;
      }
      setLoading(true);
      if (preSignedUrl && preSignedUrl.file_path) {
        contractData.link = preSignedUrl.file_path;
        contractData.file_name = preSignedUrl.file_name;
        contractData.filehash = preSignedUrl.file_hash;
        if (selectContractFrom == "counter_party") {
          contractData.status = "Counter Party Draft created";
        }
        if (selectContractFrom == "support_document") {
          contractData.status = "Internal Draft Created";
        }
        const response = await uploadContractMetaData(contractData);
        if (response) {
          setOpen(false);
          setTimeout(() => {
            props.getDrafts();
          }, 500);
        }
      }
    } catch (error) {}
  };

  const openUploadDialog = () => {
    setopenUploadNew(true);
  };

  const handleCloseEditDialog = () => {
    setopenUploadNew(false);
  };

  const handleExpandCounterParty =
    (panel: number) => (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getExpandIcon = () => (
    <img src="static_images/expand-icn.svg" alt="expand_icn" />
  );

  const handleGroups = (event: any) => {
    const {
      target: { value },
    } = event;
    setgroups(typeof value === "string" ? value.split(",") : value);
    setContractData({
      ...contractData,
      groups: value?.map((i: any) => "/" + i),
    });
  };

  useEffect(() => {
    if (groupList.length) {
      setContractData({ ...contractData, groups: ["/org", "/file-manager"] });
    }
  }, [groupList]);
  const enteronlyString = (event: any) => {
    const keyCode = event.which || event.keyCode;
    const keyValue = String.fromCharCode(keyCode);
    const isVaild = new RegExp("[a-zA-Z0-9_  -]").test(keyValue);
    if (!isVaild) {
      event.preventDefault();
      return;
    }
  };
  const handleClosebox = () => {
    handleClose();
    setopenapproval(false);
    props.getDrafts();
  };

  const updateDraft = () => {
    setSelectedUsers(contractData);
    if (!checkValidation()) {
      return;
    }
    uploadContractData(contractData);
  };

  const checkIfUserApproved = (userId: string) => {
    let isDisabled = false;
    const { logs }: any = contractData;
    let sequence = contractData.approvalTypes.length
      ? contractData.approvalTypes[0].approval_sequence
      : null;
    if (props.isEdit) {
      let userApproved = null;
      if (
        logs &&
        logs.action &&
        logs.action.includes("approved") &&
        logs.action_on.length
      ) {
        userApproved = [...logs.action_on];
      }
      if (
        userApproved &&
        userApproved.length &&
        sequence &&
        sequence.toLowerCase() == "any"
      ) {
        return true;
      }
      if (userApproved && userApproved?.includes(userId)) {
        isDisabled = true;
      }
    }
    return isDisabled;
  };

  const deleteProject = async (id: any) => {
    setLoading(true);
    const data: any = await deleteProjects(id);
    if (data) {
      await getProjectData();
      setContractData({
        ...contractData,
        projectNames: [],
      });
      setLoading(false);
    }
  };

  useEffect(() => {}, [showWarningPopup]);
  return (
    <Dialog
      scroll={"paper"}
      maxWidth="md"
      className="draft-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="riverus-dialog-header">
        <div className="row">
          <div className="col-md-11">
            <p
              style={{ textAlign: "center" }}
              className="riverus-dialog-header__title m-0"
            >
              {props.isEdit ? "Edit" : "New"} Contract Draft/ Review Requisition
            </p>
          </div>
          <div
            onClick={handleClose}
            className="col-md-1"
            style={{ textAlign: "end" }}
          >
            <img
              src="/static_images/close-analysismodal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading && <CircularProgress className="loader" />}
        {showWarningPopup.isShow && (
          <WarningPopup
            openWarningPopup={showWarningPopup.isShow}
            message={
              showWarningPopup.type === "delete"
                ? "Are you sure you want to delete the Project?"
                : "Are you sure you want to Add the Project?"
            }
            onClose={() => {
              setWarningPopup({
                isShow: false,
                tagValue: "",
                type: "",
              });
            }}
            onClick={() => {
              if (showWarningPopup.type === "delete") {
                deleteProject(showWarningPopup.tagValue);
              } else {
                addProjectName();
              }
            }}
          />
        )}
        <div className="row mb-3 justify-content-between">
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Name the draft
            </DialogContentText>
            <TextField
              className="riverus-text-box"
              size="small"
              error={errorValidation.draftName.showError}
              helperText={
                errorValidation.draftName.showError &&
                errorValidation.draftName.errorMsg
              }
              value={contractData.contractName}
              onChange={handleContractName}
              fullWidth={true}
              id="outlined-basic"
              variant="outlined"
              onKeyPress={enteronlyString}
            />
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Contract Type
            </DialogContentText>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
              disabled={props.isEdit}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth={true}
                onChange={handleContractType}
                value={contractData.contractType}
                error={errorValidation.contractType.showError}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {contractTypeList?.length ? (
                  contractTypeList?.map((type, index) => (
                    <MenuItem
                      key={index}
                      style={{ whiteSpace: "initial" }}
                      value={type.id}
                    >
                      {type.displayName}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem style={{ whiteSpace: "initial" }} disabled>
                    No Data Found
                  </MenuItem>
                )}
              </Select>
              {errorValidation.contractType.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.contractType.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Create Contract from
            </DialogContentText>
            <FormControl
              size="small"
              variant="outlined"
              className="riverus-text-box"
              fullWidth={true}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectContractFrom}
                onChange={handleSelectContractFrom}
                fullWidth={true}
                disabled={props.fromTemplate || props.isEdit}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  style={{ whiteSpace: "initial" }}
                  value={"earlier_draft"}
                >
                  Earlier Draft
                </MenuItem>
                <MenuItem style={{ whiteSpace: "initial" }} value={"template"}>
                  Template
                </MenuItem>
                <MenuItem
                  style={{ whiteSpace: "initial" }}
                  value={"support_document"}
                >
                  Requisition Draft
                </MenuItem>
                <MenuItem
                  style={{ whiteSpace: "initial" }}
                  value={"counter_party"}
                >
                  Counter Party Draft
                </MenuItem>
                <MenuItem
                  style={{ whiteSpace: "initial" }}
                  value={"executed_contract"}
                >
                  Executed Contract
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            {selectContractFrom == "executed_contract" && (
              <div>
                <DialogContentText className="riverus-field-title">
                  Select Executed Contract
                </DialogContentText>
                <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                  disabled={contractData.contractType ? false : true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth={true}
                    // value={contractData?.source}
                    onChange={(event: any, index: any) => {
                      handleExecutedContractData(event, index);
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 240,
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {executedcontractdata?.length ? (
                      executedcontractdata?.map((name: any, index: any) => (
                        <MenuItem
                          style={{ whiteSpace: "initial" }}
                          value={name?.id}
                          key={index}
                        >
                          {" "}
                          {name?.file_name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No Contract Found</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            )}
            {selectContractFrom == "support_document" && (
              <div>
                <DialogContentText className="riverus-field-title">
                  Upload Draft
                </DialogContentText>
                <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                >
                  <TextField
                    disabled
                    placeholder="Upload Draft Later"
                    className="riverus-text-box"
                    size="small"
                    fullWidth={true}
                    id="outlined-basic"
                    variant="outlined"
                  />
                </FormControl>
              </div>
            )}
            {selectContractFrom == "counter_party" && (
              <div>
                <DialogContentText className="riverus-field-title">
                  Upload Draft
                </DialogContentText>
                {/* <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                > */}
                {/* <Select
                  disabled
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth={true}
                    value={contractData?.source}
                    onChange={(event: any, index: any) => {
                      handleExecutedContractData(event, index);
                    }}
                  >
                   <MenuItem value={""}>draft 1</MenuItem>
                  </Select> */}
                <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                >
                  <TextField
                    disabled
                    placeholder="Upload Draft Later"
                    className="riverus-text-box"
                    size="small"
                    fullWidth={true}
                    id="outlined-basic"
                    variant="outlined"
                  />
                </FormControl>
                {/* </FormControl> */}
              </div>
            )}
            {selectContractFrom == "earlier_draft" && (
              <div>
                <DialogContentText className="riverus-field-title">
                  Select Draft
                </DialogContentText>
                <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth={true}
                    error={errorValidation.earlierdraft.showError}
                    value={existingContractData}
                    onChange={handleExistContractData}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                          width: 240,
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    disabled={contractData.contractType ? false : true}
                  >
                    {alldrafts.length ? (
                      alldrafts?.map((item: any, index: number) => {
                        return (
                          <MenuItem
                            key={index}
                            style={{ whiteSpace: "initial" }}
                            value={item?.contractName}
                          >
                            {item?.contractName}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem disabled>No Draft Found</MenuItem>
                    )}
                  </Select>
                  {errorValidation.earlierdraft.showError && (
                    <FormHelperText>
                      <span style={{ color: "red" }}>
                        {errorValidation.earlierdraft.errorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
            {selectContractFrom == "template" && (
              <div>
                <DialogContentText className="riverus-field-title">
                  Select Template
                </DialogContentText>
                <FormControl
                  variant="outlined"
                  className="riverus-text-box"
                  size="small"
                  fullWidth={true}
                >
                  {templates.length > 0 || props.isEdit ? (
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth={true}
                      value={templateData?.name}
                      disabled={props.fromTemplate || props.isEdit}
                      onChange={handleTemplate}
                      error={errorValidation.templateData.showError}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 240,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {!props.isEdit ? (
                        templates.map((template, index) => (
                          <MenuItem
                            style={{ whiteSpace: "initial" }}
                            key={index}
                            value={template.name}
                          >
                            {template.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem
                          style={{ whiteSpace: "initial" }}
                          value={templateData?.name}
                        >
                          {templateData?.name}
                        </MenuItem>
                      )}
                      ;
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth={true}
                      disabled={true}
                      value={"Template 1"}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                            width: 240,
                          },
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem
                        style={{ whiteSpace: "initial" }}
                        value={"Template 1"}
                      >
                        No Templates
                      </MenuItem>
                    </Select>
                  )}
                  {errorValidation.templateData.showError && (
                    <FormHelperText>
                      <span style={{ color: "red" }}>
                        {errorValidation.templateData.errorMsg}
                      </span>
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <div
              className="df justify-content-between"
              style={{ width: "243px" }}
            >
              <DialogContentText className="pl-0 riverus-field-title col-md-6">
                Project
              </DialogContentText>
              <Button
                onClick={() => settoggleAdd(!toggleAdd)}
                color="secondary"
              >
                + Add New
              </Button>
            </div>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
            >
              <InputLabel>Select or add new</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={contractData.projectNames}
                onChange={handleProjectNames}
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={errorValidation.projectName.showError}
                label="Select or add new"
              >
                {projectList.map((value, index) => (
                  <MenuItem key={index} value={value.name}>
                    <ListItemText
                      style={{ whiteSpace: "initial" }}
                      primary={value.name}
                    />
                    <ListItemIcon className="justify-content-end">
                      <AiOutlineDelete
                        color="red"
                        onClick={async () => {
                          setLoading(true);
                          const data = await deleteProjects(value.id);
                          if (data) {
                            await getProjectData();
                            setContractData({
                              ...contractData,
                              projectNames: [],
                            });
                            setLoading(false);
                          }
                          setWarningPopup({
                            isShow: true,
                            tagValue: value.id,
                            type: "delete",
                          });
                        }}
                      />
                    </ListItemIcon>
                  </MenuItem>
                ))}
              </Select>
              {errorValidation.projectName.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.projectName.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
            {toggleAdd && (
              <div className="row mt-2 align-items-center">
                <div className="col-md-10">
                  <TextField
                    size="small"
                    value={postProjectName}
                    onChange={(event: any) =>
                      setPostProjectName(event.target.value)
                    }
                    fullWidth={true}
                    id="outlined-basic"
                    style={{ width: "204px" }}
                    variant="outlined"
                  />
                </div>
                <div className="col-md-1 p-0">
                  <IconContext.Provider
                    value={{ color: "#88305F", size: "20" }}
                  >
                    <BsFillCheckCircleFill
                      onClick={() => {
                        setWarningPopup({
                          isShow: true,
                          tagValue: "",
                          type: "add",
                        });
                      }}
                    />
                  </IconContext.Provider>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Groups
            </DialogContentText>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                fullWidth={true}
                onChange={handleGroups}
                value={groups}
                multiple
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {groupList && groupList?.length ? (
                  groupList?.map((value, index) => {
                    const valuedata = value.name.split("/");
                    const splitted = valuedata.splice(1).join("/");
                    return (
                      <MenuItem
                        key={index}
                        style={{ whiteSpace: "initial" }}
                        value={splitted}
                      >
                        <Checkbox checked={groups.indexOf(splitted) > -1} />
                        <ListItemText
                          style={{ whiteSpace: "initial" }}
                          primary={splitted}
                        />
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem style={{ whiteSpace: "initial" }} disabled>
                    No Groups
                  </MenuItem>
                )}
              </Select>
              <FormHelperText></FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div
              className="df justify-content-between"
              style={{ width: "400px" }}
            >
              <DialogContentText className="riverus-field-title">
                Counter Parties
              </DialogContentText>
              <div>
                <Button
                  style={{ paddingTop: "0" }}
                  onClick={addCounterParty}
                  color="secondary"
                  disabled={disableInput}
                >
                  + Add More Counter Parties
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-md-12">
            {contractData?.counterParties?.map((party, index) => (
              // !party.manually ? (
              <Accordion
                className={`mb-2`}
                key={index}
                expanded={expanded === index}
                onChange={handleExpandCounterParty(index)}
              >
                <AccordionSummary
                  expandIcon={getExpandIcon()}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography>Counter Party {index + 1}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row" key={index}>
                    <div className="col-md-4">
                      {/* <TextField
                        disabled
                        className="riverus-text-box mb-2"
                        size="small"
                        onChange={(event) => handleOrgName(event, index)}
                        value={party.orgName}
                        fullWidth={true}
                        id="outlined-basic"
                        label="Organisation name"
                        variant="outlined"
                      /> */}
                      <FormControl
                        variant="outlined"
                        className="riverus-text-box"
                        size="small"
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Organisation name
                        </InputLabel>
                        <Select
                          label="Organisation name"
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          fullWidth={true}
                          value={party.disable ? "" : party.orgName}
                          onChange={(event) => handleOrgName(event, index)}
                          disabled={party.disable || disableInput}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 240,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {filterexternalgroups?.map(
                            (item: any, index: number) => {
                              return (
                                <MenuItem
                                  style={{ whiteSpace: "initial" }}
                                  value={item}
                                  key={index}
                                >
                                  {item}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      {/* {selectContractFrom === "earlier_draft" ? (
                        <> */}
                      <FormControl
                        variant="outlined"
                        className="riverus-text-box"
                        size="small"
                        fullWidth={true}
                      >
                        <InputLabel id="demo-simple-select-label">
                          Contact name
                        </InputLabel>
                        <Select
                          label="Contact name"
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          fullWidth={true}
                          value={party.disable ? "" : party.orgContactName}
                          onChange={(event) =>
                            handleContactNameWithEmail(event, index)
                          }
                          disabled={party.disable || disableInput}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 240,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {externalDataall?.map((item: any, index: number) => {
                            return (
                              <MenuItem
                                style={{ whiteSpace: "initial" }}
                                value={item?.first_name}
                                key={index}
                              >
                                {item?.first_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-4">
                      <TextField
                        className="riverus-text-box mb-2"
                        size="small"
                        onChange={(event) => handleEmail(event, index)}
                        value={party.disable ? "" : party.email}
                        fullWidth={true}
                        disabled={party.disable || disableInput}
                        id="outlined-basic"
                        label="E-mail"
                        variant="outlined"
                      />
                    </div>
                    {party.disable ? (
                      <>
                        <div className="col-md-4">
                          <TextField
                            className="riverus-text-box mb-2"
                            size="small"
                            onChange={(event) => handleOrgName(event, index)}
                            value={party.orgName}
                            fullWidth={true}
                            id="outlined-basic"
                            label="Organisation Name"
                            variant="outlined"
                            disabled={disableInput}
                          />
                        </div>
                        <div className="col-md-4">
                          {/* {selectContractFrom === "earlier_draft" ? (
                      <> */}
                          <TextField
                            className="riverus-text-box mb-2"
                            size="small"
                            onChange={(event) =>
                              handlemanualContactNameWithEmail(event, index)
                            }
                            value={party.orgContactName}
                            fullWidth={true}
                            id="outlined-basic"
                            label="Contact Name"
                            variant="outlined"
                            disabled={disableInput}
                          />
                        </div>
                        <div className="col-md-4">
                          <TextField
                            className="riverus-text-box mb-2"
                            size="small"
                            onChange={(event) =>
                              handlemanualEmail(event, index)
                            }
                            value={party.email}
                            fullWidth={true}
                            id="outlined-basic"
                            label="E-mail"
                            variant="outlined"
                            disabled={disableInput}
                          />
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* {party.manually?.map((item))} */}

                    {contractData.counterParties.length < 2 && (
                      <>
                        <div className="col-md-4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={party.isExternalSignatory}
                                className="primary-checkbox"
                                onChange={(event) =>
                                  handleIsExternal(event, index)
                                }
                                disabled={disableInput}
                              />
                            }
                            label="Will act as a signatory"
                          />
                        </div>
                      </>
                    )}

                    {contractData.counterParties.length > 1 && (
                      <div
                        className="col-md-5 row"
                        style={{ flexWrap: "nowrap" }}
                      >
                        <div className="col-md-1">
                          <img
                            style={{ marginTop: "9px" }}
                            onClick={() => deleteCounterParty(index)}
                            className="delete-icon"
                            src="/static_images/checklist-delete-icn.svg"
                            alt="close_icon"
                          />
                        </div>
                        <div className="col-md-12">
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={party.isExternalSignatory}
                                className="primary-checkbox"
                                onChange={(event) =>
                                  handleIsExternal(event, index)
                                }
                                disabled={disableInput}
                              />
                            }
                            label="Will act as a signatory"
                          />
                        </div>
                      </div>
                    )}
                    {!party.disable ? (
                      <div className="col-md-4 mt-2">
                        <Button
                          style={{ paddingTop: "0" }}
                          onClick={() => addmanuallydata(index)}
                          color="secondary"
                          disabled={disableInput}
                        >
                          + Add Manually
                        </Button>
                      </div>
                    ) : (
                      <div className="col-md-4 mt-2">
                        <Button
                          style={{ paddingTop: "0" }}
                          onClick={() => deletemanuallydata(index)}
                          color="secondary"
                        >
                          - Delete Manual data
                        </Button>
                      </div>
                    )}
                    {/* {contractData.counterParties.length - 1 == index && ( */}

                    {/* )} */}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
            {errorValidation.counterParty.showError && (
              <p style={{ fontSize: "12px", color: "red" }}>
                {errorValidation.counterParty.errorMsg}
              </p>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Collaborators
            </DialogContentText>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
              disabled={props.isEdit}
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={collaborators}
                onChange={handleCollaborator}
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={errorValidation.collaborators.showError}
              >
                {collaboratorList
                  .map((user) => user.first_name)
                  .map((name, index) => (
                    <MenuItem key={index} value={name}>
                      <Checkbox checked={collaborators.indexOf(name) > -1} />
                      <ListItemText
                        style={{ whiteSpace: "initial" }}
                        primary={name}
                      />
                    </MenuItem>
                  ))}
              </Select>
              {errorValidation.collaborators.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.collaborators.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Signatories
            </DialogContentText>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
              disabled={props.isEdit}
            >
              <Select
                labelId="demo-multiple-checkbox-error-label"
                id="demo-multiple-checkbox-error"
                multiple
                value={signatories}
                onChange={handleSignatories}
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={errorValidation.signatories.showError}
              >
                {signatoriesList
                  .map((user) => user.first_name)
                  .map((name, index) => (
                    <MenuItem key={index} value={name}>
                      <Checkbox checked={signatories.indexOf(name) > -1} />
                      <ListItemText
                        style={{ whiteSpace: "initial" }}
                        primary={name}
                      />
                    </MenuItem>
                  ))}
              </Select>
              {errorValidation.signatories.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.signatories.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Deadline
            </DialogContentText>
            <TextField
              className="riverus-text-box"
              size="small"
              error={errorValidation.deadline.showError}
              helperText={
                errorValidation.deadline.showError &&
                errorValidation.deadline.errorMsg
              }
              value={contractData.deadline}
              onChange={handleDeadline}
              inputProps={{
                // only needs the first 16 characters in the date string
                min: new Date().toISOString()?.split("T")[0]?.slice(0, 16),
              }}
              type={"date"}
              fullWidth={true}
              id="outlined-basic"
              variant="outlined"
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <div className="row m-0" style={{ width: "238px" }}>
              <div className="col-md-10 p-0">
                <DialogContentText className="riverus-field-title">
                  Requisition Approvers
                </DialogContentText>
              </div>
              <div className="col-md-2">
                <Tooltip title={"Click Here to Refer Approvals"} arrow>
                  <img
                    className="cp"
                    style={{ width: "20px" }}
                    onClick={openApprovalsTab}
                    src="/static_images/navigate-icn.svg"
                    alt="close_icon"
                  />
                </Tooltip>
              </div>
            </div>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={approvers}
                onChange={handleApprover}
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                error={errorValidation.approvers.showError}
                // disabled={disableInput}
              >
                {approverList.map((user, index) => (
                  <MenuItem
                    key={index}
                    value={user.first_name}
                    disabled={checkIfUserApproved(user.id)}
                  >
                    <Checkbox
                      checked={approvers.indexOf(user.first_name) > -1}
                    />
                    <ListItemText
                      style={{ whiteSpace: "initial" }}
                      primary={user.first_name}
                    />
                  </MenuItem>
                ))}
              </Select>
              {errorValidation.approvers.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.approvers.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-md-4">
            <DialogContentText className="riverus-field-title">
              Approvers Sequence
            </DialogContentText>
            <FormControl
              variant="outlined"
              className="riverus-text-box"
              size="small"
              fullWidth={true}
            >
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                value={approverSequence}
                onChange={handleApproverSequence}
                error={errorValidation.approverSequence.showError}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 240,
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={props.isEdit}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Any">Any</MenuItem>
                <MenuItem value="In Sequence">In Sequence</MenuItem>
              </Select>
              {errorValidation.approverSequence.showError && (
                <FormHelperText>
                  <span style={{ color: "red" }}>
                    {errorValidation.approverSequence.errorMsg}
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <DialogContentText className="riverus-field-title">
              Notes
            </DialogContentText>
            <TextField
              fullWidth
              variant="outlined"
              id="outlined-multiline-flexible"
              multiline
              maxRows={3}
              value={contractData.notes}
              onChange={({ target: { value } }) => {
                if (value.length <= 512)
                  setContractData({ ...contractData, notes: value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment disablePointerEvents={false} position="end">
                    {contractData.notes.length}/512
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className="cancel-btn" onClick={handleClose} color="primary">
          Cancel
        </Button>
        {selectContractFrom == "template" && !props.isEdit && (
          <button
            onClick={editOrCreateDraft}
            type="button"
            className="btn btn-warning create-btn save-btn"
          >
            Create Requisition
          </button>
        )}
        {selectContractFrom == "support_document" && !props.isEdit && (
          <button
            onClick={() => handleRequisition()}
            type="button"
            disabled={disable}
            className="btn btn-warning create-btn save-btn"
          >
            Create Requisition
          </button>
        )}
        {selectContractFrom == "counter_party" && !props.isEdit && (
          <button
            onClick={() => handleRequisition()}
            type="button"
            disabled={disable}
            className="btn btn-warning create-btn save-btn"
          >
            {/* Upload Counterparty Draft */}
            Create Requisition
          </button>
        )}
        {selectContractFrom == "executed_contract" && !props.isEdit && (
          <button
            type="button"
            disabled={disable}
            className="btn btn-warning create-btn save-btn"
            onClick={() => handleRequisition()}
          >
            Create Requisition{" "}
          </button>
        )}
        {selectContractFrom == "earlier_draft" && !props.isEdit && (
          <button
            onClick={() => {
              handleRequisition();
              //   setopenapproval(true)
              //   handleClose()
            }}
            type="button"
            disabled={disable}
            className="btn btn-warning create-btn save-btn"
          >
            Create Requisition
          </button>
        )}
        {props.isEdit && (
          <button
            onClick={() => {
              updateDraft();
            }}
            type="button"
            disabled={disable}
            className="btn btn-warning create-btn save-btn"
          >
            Update
          </button>
        )}
      </DialogActions>
      {/* {openUploadNew && (
        <UploadFileDialog
          title="Upload Draft"
          fileType="Contract_Draft"
          uploadedFileResponse={uploadAndCreateDraft}
          open={openUploadNew}
          onClose={handleCloseEditDialog}
          contractType={[]}
        />
      )} */}
      {openapproval && (
        <ForApproval
          //   title="Upload Draft"
          //   action="Contract_Draft"
          //   uploadedFileResponse={uploadAndCreateDraft}
          draftcomponent=""
          version={version}
          requisitionId={requisitionId}
          close={() =>
            props.onClose({
              contractData: contractData,
              templateData: templateData,
              dynamicFields: dynamicFields,
            })
          }
          show={true}
          setTemplate={props.setTemplate}
          openmain={handleClosebox}
          reqres={reqresponse}
          open={openapproval}
          setopenapproval={setopenapproval}
          createLink={
            selectContractFrom === "template" ? props.createLink : undefined
          }
          getDrafts={props.getDrafts}
        />
      )}
      {openpdfviewer && (
        <PdfViewer
          open={openpdfviewer}
          onClose={handleCloseviewer}
          fileName={fileName}
          draftDetails={draftDetails}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar.open}
        onClose={closeSnackbar}
        message={openSnackbar.msg}
        key="bottomCenter"
        action={action}
      />
    </Dialog>
  );
};

export default CreateDraftDialog;
