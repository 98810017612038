import React from "react";
import { Card, Badge, Table, Row, Col } from "react-bootstrap";
const OtherObligationsRender = (props: any) => {
  const { insightParas } = props;
  const {
    scrollToDatapoint,
    UpDownButtonsRender,
    scrollToHighlightedDatapoint,
    getCustomChildLabelName,
    getHighlightedTextFromDP,
    getHighlightedPara,
    getSmallSentence,
  } = props;
  const { label, children, dataPoints } = props.insight;
  let uniqueDataPoints: any = [];
  let uniqueDataPointsObj: any = {};
  for (let i = 0; i < dataPoints.length; i++) {
    if (
      uniqueDataPointsObj[dataPoints[i].paraId] ||
      insightParas.includes(dataPoints[i].paraId)
    ) {
    } else {
      uniqueDataPointsObj[dataPoints[i].paraId] = true;
      uniqueDataPoints.push(dataPoints[i]);
    }
  }
  let idx = 1;
  const totalInstances = uniqueDataPoints.length;
  return (
    <Card>
      <Card.Header
        className={
          "small-card-header light-pink-background card-toc-row toc-row-default " +
          (totalInstances === 0 ? "zero-instance-header" : "")
        }
        style={{ marginLeft: 0 }}
      >
        <Row style={{ paddingRight: 7 }}>
          <Col className="capitalize" style={{ fontSize: 15 }} md={8}>
            {label}&nbsp;
            {totalInstances > 0 && (
              <Badge className="riverus-yellow-btn ">{totalInstances}</Badge>
            )}
          </Col>
          {totalInstances ? (
            totalInstances > 1 ? (
              <Col style={{ textAlign: "right", paddingLeft: 0 }}>
                <UpDownButtonsRender dataPoints={uniqueDataPoints} />
              </Col>
            ) : (
              <Col style={{ textAlign: "right" }}>
                <span
                  className="riverus-yellow-btn"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    scrollToDatapoint(uniqueDataPoints[0]);
                  }}
                >
                  View
                </span>
              </Col>
            )
          ) : (
            <Col style={{ textAlign: "right" }}>
              <Badge className="riverus-yellow-btn">{totalInstances}</Badge>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Table striped hover responsive size="sm">
              <tbody>
                {/* {label === 'Other Obligations' ? (
                                    <> */}
                {uniqueDataPoints.map((instance: any, i: number) => {
                  if (!insightParas.includes(instance.paraId)) {
                    return (
                      <tr key={i}>
                        <td>{idx++}</td>
                        <td>
                          {getSmallSentence(getHighlightedPara(instance))}
                        </td>
                        <td>
                          <span
                            className="riverus-yellow-btn"
                            style={{ fontSize: "13px" }}
                            onClick={() => {
                              scrollToDatapoint(instance);
                            }}
                          >
                            View
                          </span>
                        </td>
                      </tr>
                    );
                  } else return <React.Fragment key={i}></React.Fragment>;
                })}
                {/* </>
                                ) : (
                                    <>
                                        {dataPoints.map((instance: any, i: number) => {
                                            
                                            return (
                                                <tr key={i}>
                                                    <td>{idx++}</td>
                                                    <td>{getSmallSentence(getHighlightedPara(instance))}</td>
                                                    <td>
                                                        <span
                                                            className="riverus-yellow-btn"
                                                            style={{ fontSize: '13px' }}
                                                            onClick={() => {
                                                                scrollToDatapoint(instance);
                                                            }}
                                                        >
                                                            View
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                )} */}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default OtherObligationsRender;
