import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
const APIPaths = {
  getClauseTypes:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/clause-type/",
  getCurrency:process.env.REACT_APP_RIVERUS_CLM_API + "admin/currency/",
  getGroups: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/group/",
  getNature: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/nature/",
  getOthers: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/others/",
  getParties:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/parties/",
  getFilterContracts:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/",
  getCount: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/statistics/",
  getPayee: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/payees/",
  getPayers: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/payers/",
  getExtentOfCosts:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/extent_of_costs/",
  getTriggeringEvents:
    process.env.REACT_APP_RIVERUS_CLM_API +
    "contracts/filter/triggering_events/",
  getConfidentialityNature:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/natures/",
  getTerminationEvent:
    process.env.REACT_APP_RIVERUS_CLM_API +
    "contracts/filter/termination_events/",
  getForceMejureEvents:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/fm_events/",
  getJurisdictions:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/jurisdictions/",
  getDisputeResolutionVenue:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/venues/",
  getDisputeResolutionActOrState:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/act_or_statute/",
  getDisputeResolutionPanel:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/panels/",
  getTerritory:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/territory/",
  getAuthority:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/authority/",
  getSavedFilters:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/savefilter/",
  getEventsOfDefault:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/event/?type=eventsofdefault",
  getLockedInperiod: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/locked_in_period/",
  getLimitedLiability: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/limited_liability_amount/",
  getNonCompeteDuration: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/non_compete_duration/",
  getNonSolicitationDuration: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/non_solicitation_duration/",
  getIndemnityCap: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/indemnity_cap/",
  getCexcept: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/cexcept/",
  getConfidentialityDuration: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/filter/confidentiality_duration/",
};
const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});
export class FilterAPI {
  constructor() { }

  static getClauseTypes() {
    return axios
      .get(APIPaths.getClauseTypes, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getClauseTypes error", error);
      });
  }

  static getCurrency() {
    return axios
      .get(APIPaths.getCurrency, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getCurrency error", error);
      });
  }

  static getGroups() {
    return axios
      .post(APIPaths.getGroups, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getGroups error", error);
      });
  }

  static getNature() {
    return axios
      .post(APIPaths.getNature, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getNature error", error);
      });
  }

  static getOthers() {
    return axios
      .post(APIPaths.getOthers, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getOthers error", error);
      });
  }

  static getParties() {
    return axios
      .post(APIPaths.getParties, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getParties error", error);
      });
  }

  static getContracts(filters: any) {
    return axios
      .post(APIPaths.getFilterContracts, filters, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getContracts error", error);
      });
  }

  static getTotalContractsCount() {
    return axios
      .get(APIPaths.getCount, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getTotalContractsCount error", error);
      });
  }

  static getPayee() {
    return axios
      .post(APIPaths.getPayee, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getPayee error", error);
      });
  }

  static getPayers() {
    return axios
      .post(APIPaths.getPayers, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getPayers error", error);
      });
  }

  static getExtentOfCosts() {
    return axios
      .post(APIPaths.getExtentOfCosts, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getExtentOfCosts error", error);
      });
  }

  static getTriggeringEvents() {
    return axios
      .post(APIPaths.getTriggeringEvents, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getTriggeringEvents error", error);
      });
  }

  static getConfidentialityNature() {
    return axios
      .post(APIPaths.getConfidentialityNature, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getConfidentialityNature error", error);
      });
  }

  static getTerminationEvent() {
    return axios
      .post(APIPaths.getTerminationEvent, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getTerminationEvent error", error);
      });
  }

  static getForceMejureEvents() {
    return axios
      .post(APIPaths.getForceMejureEvents, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getForceMejureEvents error", error);
      });
  }

  static getJurisdictions() {
    return axios
      .post(APIPaths.getJurisdictions, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getJurisdictions error", error);
      });
  }

  static getDisputeResolutionVenue() {
    return axios
      .post(APIPaths.getDisputeResolutionVenue, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getDisputeResolutionVenue error", error);
      });
  }

  static getDisputeResolutionActOrState() {
    return axios
      .post(APIPaths.getDisputeResolutionActOrState, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getDisputeResolutionActOrState error", error);
      });
  }

  static getDisputeResolutionPanel() {
    return axios
      .post(APIPaths.getDisputeResolutionPanel, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getDisputeResolutionPanel error", error);
      });
  }

  static getTerritory() {
    return axios
      .post(APIPaths.getTerritory, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getTerritory error", error);
      });
  }

  static getAuthority() {
    return axios
      .post(APIPaths.getAuthority, {}, APIConfig())
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Get getAuthority error", error);
      });
  }

  static getSavedFilters() {
    return axios
      .get(APIPaths.getSavedFilters, APIConfig())
      .then((response) => {
        return response.data.results;
      })
      .catch((error) => {
        console.error("Get getSavedFilters error", error);
      });
  }

  static getEventsofdefault() {
    return axios.get(APIPaths.getEventsOfDefault, APIConfig()).then((response) => {
      return response.data.results;
    }).catch((error) => {
      console.error("Get getEventsofdefault error", error)
    })
  }

  static getLockInPeriod() {
    return axios.post(APIPaths.getLockedInperiod, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getEventsofdefault error", error)
    })
  }

  static getLimitedLiability() {
    return axios.post(APIPaths.getLimitedLiability, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getLimitedLiability error", error)
    })
  }

  static getNonCompeteDuration() {
    return axios.post(APIPaths.getNonCompeteDuration, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getNonCompeteDuration error", error)
    })
  }

  static getNonSolicitationDuration() {
    return axios.post(APIPaths.getNonSolicitationDuration, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getNonSolicitationDuration error", error)
    })
  }

  static getIndemnityCap() {
    return axios.post(APIPaths.getIndemnityCap, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getIndemnityCap error", error)
    })
  }

  static getCexcept(){
    return axios.post(APIPaths.getCexcept, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getCexcept error", error)
    })
  }

  static getConfidentialityDuration() {
    return axios.post(APIPaths.getConfidentialityDuration, {}, APIConfig()).then((response) => {
      return response.data;
    }).catch((error) => {
      console.error("Get getConfidentialityDuration error", error)
    })
  }
}

export const postsaveFilter = (savefilterPostReq: any) => {
  return axios
    .post(APIPaths.getSavedFilters, savefilterPostReq, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("savefilterPostReq error", error);
    });
};

export const patchsaveFilter = (savefilterPostReq: any, id: string) => {
  let url = APIPaths.getSavedFilters + "" + id + "/";
  return axios
    .patch(url, savefilterPostReq, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("savefilterPostReq error", error);
    });
};