import React, { useState, useEffect } from "react";
import { QuickIntel, InsightsInterface } from "../../State/documentState";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Table,
  Button,
} from "react-bootstrap";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import BasicInformationRender from "./basic-info-render";
import KDPRender from "./kdp-render";
import OtherObligationsRender from "./other-obligations-render";
import GeneralDefinitionsRender from "./general-definitions-render";
import DownloadInsights from "../InsightComponents/saveInsights";
import AxiosGateWay from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import { SITE_API_BY_REALM_NAME } from "../../../Configuration/global";
import { getKeyCloakRealmFromLS } from "../../../Authentication/Actions/authentication";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
// import Assessment from '../../../Assessments/Container/assessmentCon'
interface Props {
  insightsData: InsightsInterface[];
  fileId: string;
  sentenceLoader: boolean;
}
type GroupedInsight = {
  name: string;
  insights: any[];
};

const ViewInsights = (props: Props) => {
  const { insightsData } = props;
  const [quickIntel, setQuickIntel] = useState<any>({});
  const fetchQuickIntel = async () => {
    let contract_id = atob(props.fileId);
    let url = SITEAPI + "" + contract_id + "/";
    let metaData = {
      fileName: "",
      metaData: [],
    };
    // AxiosGateWay.get(SITEAPI + "document/meta/" + props.fileId)
   /* AxiosGateWay.get(url)
      .then((result) => {
        if (result.data.length != 0) {
          metaData.fileName = result.data.file_name;
        }
        let parsed = metaData as QuickIntel;
        setQuickIntel(parsed);
      })
      .catch((error) => {});*/
  };
  const [insightParas, setInsightParas] = useState<number[]>([]);
  useEffect(() => {
    fetchQuickIntel();
    let insightParas_: number[] = [];
    {
      insightsData &&
        insightsData.length > 0 &&
        insightsData.map((insight, i) => {
          if (
            insight.label !== "Other Obligations" &&
            insight.label !== "General Definitions"
          ) {
            if (insight.dataPoints && insight.dataPoints.length > 0) {
              insightParas_.push(...insight.dataPoints.map((dp) => dp.paraId));
            }
          }
        });
    }

    setInsightParas(insightParas_);
  }, [insightsData]);
  return (
    <Container className="view-insights-body">
      <Row>
        <Col>
          {/* <span className="riverus-yellow-btn" style={{ fontSize: '13px', paddingBottom: 3 }}>
                        Download Insights
                    </span> */}
          {/* <DownloadInsights
            insightsData={insightsData}
            quickIntel={quickIntel}
          /> */}
          <br />
          {insightsData &&
            insightsData.length > 0 &&
            insightsData.map((insight, i) => {
              if (insight.label === "Basic Information") {
                return <BasicInformationRender key={i} insight={insight} />;
              } else if (insight.label === "Other Obligations") {
                return (
                  <OtherObligationsRender
                    key={i}
                    insight={insight}
                    insightParas={insightParas}
                    scrollToDatapoint={scrollToDatapoint}
                    UpDownButtonsRender={UpDownButtonsRender}
                    scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
                    getCustomChildLabelName={getCustomChildLabelName}
                    getHighlightedTextFromDP={getHighlightedTextFromDP}
                    getHighlightedPara={getHighlightedPara}
                    getSmallSentence={getSmallSentence}
                  />
                );
              } else if (insight.label === "General Definitions") {
                return (
                  <GeneralDefinitionsRender
                    key={i}
                    insight={insight}
                    scrollToDatapoint={scrollToDatapoint}
                    UpDownButtonsRender={UpDownButtonsRender}
                    scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
                    getCustomChildLabelName={getCustomChildLabelName}
                    getHighlightedTextFromDP={getHighlightedTextFromDP}
                    getHighlightedPara={getHighlightedPara}
                    getSmallSentence={getSmallSentence}
                  />
                );
              } else {
                return (
                  <KDPRender
                    key={i}
                    insight={insight}
                    scrollToDatapoint={scrollToDatapoint}
                    UpDownButtonsRender={UpDownButtonsRender}
                    scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
                    getCustomChildLabelName={getCustomChildLabelName}
                    getHighlightedTextFromDP={getHighlightedTextFromDP}
                    getHighlightedPara={getHighlightedPara}
                    getSmallSentence={getSmallSentence}
                  />
                );
              }
            })}
        </Col>
      </Row>
    </Container>
  );
};
export default ViewInsights;

const scrollToDatapoint = (dataPoint: any) => {
  let dataPointElementId = `p${dataPoint.paraId}`;
  if (dataPoint.rowId !== -1) dataPointElementId += `;r${dataPoint.rowId}`;
  if (dataPoint.columnId !== -1)
    dataPointElementId += `;c${dataPoint.columnId}`;
  let paraHeader = document.getElementById(dataPointElementId);
  if (paraHeader !== null && paraHeader !== undefined) {
    paraHeader.scrollIntoView({ block: "center" });
    document.documentElement.style.scrollBehavior = "smooth";
    paraHeader.style.backgroundColor = "gray";
    paraHeader.style.animation = "";
    paraHeader.style.animation = "background-fade 20s forwards";
    setTimeout(() => {
      if (paraHeader) {
        paraHeader.style.animation = "";
        paraHeader.style.backgroundColor = "white";
      }
    }, 16000);
  }
};
const scrollToHighlightedDatapoint = (dataPoint: any) => {
  const { startWordId, endWordId, paraId } = dataPoint;
  for (let i = startWordId; i <= endWordId; i++) {
    let dataPointElementId = `p${paraId};w${i}`;

    let paraHeader = document.getElementById(dataPointElementId);
    if (paraHeader !== null && paraHeader !== undefined) {
      paraHeader.scrollIntoView({ block: "center" });
      document.documentElement.style.scrollBehavior = "smooth";
      paraHeader.style.backgroundColor = "gray";
      paraHeader.style.animation = "";
      paraHeader.style.animation = "background-fade-lighter 20s forwards";
      setTimeout(() => {
        if (paraHeader) {
          paraHeader.style.animation = "";
          paraHeader.style.backgroundColor = "white";
        }
      }, 16000);
    }
  }
};

const getCustomChildLabelName = (childName: string) => {
  switch (childName) {
    case "Indemnity/Reimbursements/Costs Extent of Cost":
      return "Extent of Cost";
    case "Indemnity/Reimbursements/Costs Triggering Event":
      return "Triggering Event";
    case "Indemnity/Reimbursements/Costs Payer":
      return "Payer";
    case "Indemnity/Reimbursements/Costs Payee":
      return "Payee";
    case "Confidentiality Nature":
      return "Responsible Party";
    case "Subletting/Assignment Notice Info":
      return "Notice Info";
    case "Exception to Confidentiality":
      return "C Except";
    case "Subletting/Assignment Consent Info":
      return "Consent Info";
    case "eventsOfDefault":
      return "Event";
    case "Indemnity/Reimbursements/Costs Amount":
      return "Amount";
    case "Start Dates":
      return "Start Date";
    case "End Dates":
      return "End Date";
    case "Effective Dates":
      return "Effective Dates";
    default:
      return childName;
  }
};

const getHighlightedTextFromDP = (dataPoint: any) => {
  const { paraId, startWordId, endWordId } = dataPoint;
  let sentence = "";
  for (let w = startWordId; w <= endWordId; w++) {
    let wordId = `p${paraId};w${w}`;
    let word = document?.getElementById(wordId.toString())?.innerHTML;
    sentence += word;
    sentence += " ";
  }
  return sentence;
};

const getHighlightedPara = (dataPoint: any) => {
  const { paraId, rowId, columnId } = dataPoint;
  if (rowId !== -1 && columnId !== -1) {
    const cellId = `p${paraId};r${rowId};c${columnId}`;
    let sentence: any = "";
    if (
      document.getElementById(cellId.toString()) &&
      document.getElementById(cellId.toString())?.textContent &&
      document.getElementById(cellId.toString())?.textContent !== ""
    ) {
      sentence = document.getElementById(cellId.toString())?.textContent;
    }
    return sentence ? sentence : "";
  } else {
    let sentence = "";
    for (let w = 0; ; w++) {
      let wordId = `p${paraId};w${w}`;
      let word = document?.getElementById(wordId.toString())?.textContent;
      if (word && word.length > 0) {
        sentence += word;
        sentence += " ";
      } else {
        break;
      }
    }

    return sentence;
  }
};

const getSmallSentence = (longSentence: string) => {
  if (longSentence && longSentence.length > 80) {
    return longSentence.substr(0, 80) + "...";
  } else return longSentence;
};

const UpDownButtonsRender = (props: any) => {
  const [position, setPosition] = useState(0);
  const { dataPoints } = props;
  const length = dataPoints.length;
  const handleChangePosition = (type: string) => {
    if (type === "up") {
      if (position > 0) {
        scrollToDatapoint(dataPoints[position - 1]);
        scrollToHighlightedDatapoint(dataPoints[position - 1]);
        setPosition(position - 1);
      } else {
        scrollToDatapoint(dataPoints[0]);
        scrollToHighlightedDatapoint(dataPoints[0]);
      }
    } else if (type === "down") {
      if (position < length - 1) {
        scrollToDatapoint(dataPoints[position + 1]);
        scrollToHighlightedDatapoint(dataPoints[position + 1]);
        setPosition(position + 1);
      } else {
        scrollToDatapoint(dataPoints[length - 1]);
        scrollToHighlightedDatapoint(dataPoints[length - 1]);
      }
    }
  };
  return (
    <>
      <span
        className="riverus-yellow-btn"
        style={{ fontSize: "13px" }}
        onClick={() => {
          handleChangePosition("up");
        }}
      >
        <AiOutlineArrowUp size={16} />
      </span>
      &nbsp;{position + 1}/{length}&nbsp;
      <span
        className="riverus-yellow-btn"
        style={{ fontSize: "13px" }}
        onClick={() => {
          handleChangePosition("down");
        }}
      >
        <AiOutlineArrowDown size={16} />
      </span>
    </>
  );
};
