import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { deleteContractType } from "../DA";

interface Props {
  id: string;
  name: string;
  onClose: () => void;
  open: boolean;
  getContractType: () => void;
}
const DeleteContractTypeDialog = (props: Props) => {
  const [open, setopen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleClose = () => {
    setopen(false);
    props.onClose();
  };
  useEffect(() => {
    setopen(props.open);
  }, [props.open]);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await deleteContractType(props.id);
      handleClose();
      setTimeout(() => {
        props.getContractType();
      }, 500);
    } catch (error) {
      console.error("delete contract type error", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="modal-header-2 ">
        <div className="d-flex justify-content-between align-items-center w-100 px-3">
          <p
            style={{
              fontSize: "16px",
              margin: "0px",
            }}
          >
            Delete Contract Type
          </p>
          <div onClick={handleClose}>
            <img
              src="/static_images/white-close-modal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </div>
      <DialogContent dividers>
        {isLoading && <CircularProgress className="loader" />}
        <div className="row">
          <div className="col-md-12">
            <p>
              Are you sure you want to delete <b>{props.name}</b> contract type
              ?
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
        >
          Cancel
        </Button>
        <Button className="create-btn" onClick={handleSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteContractTypeDialog;
