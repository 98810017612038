import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  CircularProgress,
  FormControl,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { convertToObject } from "typescript";
import { useRef } from "react";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import { combineReducers } from "redux";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import WarningPopup from "./WarningPopup";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "420px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  openModal: string;
  handleCloseaddtagBtn: any;
  titleTag: any;
  addTagContractType: any;
  documentLibraryAddTagGetProjecetApi: any;
  projectData: any;
  projectDatastateUpdate: any;
  checkDocument: any;
  reloadDocumentLibray: () => void;
  groupData: any;
  documentLibraryAddTagGetGroupApi: any;
  selectedContractTypes: any;
  tagContractType: any;
  setTagContractType: (item: any) => void;
  projectList: any;
  setprojectList: (item: any) => void;
  handleProjectType: (item: any) => void;
  groupList: any;
  handleGroupType: (item: any) => void;
  handleGroup: (e: any) => void;
  handleProjectsSet: (e: any) => void;
  resetGroups: () => void;
}
interface State {
  position: any;
  value: any;
  tagContractType: any;
  a: boolean;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AddTag: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState("HirarchyValueOne");

  const [parentDropdown, setParentdropdown] = React.useState();

  const [tagContractType, setTagContractType] = React.useState<any>([]);
  const [projectList, setprojectList] = React.useState<any>([]);

  const [openForm, setOpenForm] = React.useState(false);
  const [Formname, setFormName] = React.useState("");
  const [FormComment, setFormComment] = React.useState("");
  const [Formdisplayname, setFormdisplayname] = React.useState("");
  const [a, seta] = React.useState(false);
  const [loader, setloader] = useState(false);
  const [groupList, setGroupList] = React.useState<any>([]);
  const [showError, setshowError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [projectNamesList, setprojectNamesList] = useState<any>({
    projectNames: [],
    project: "",
  });

  const [showWarningPopup, setWarningPopup] = useState<any>({
    isShow: false,
    tagValue: "",
    type: "",
    items: {},
  });

  useEffect(() => {
    setprojectNamesList({
      projectNames: [],
      project: "",
    });
  }, []);

  const formRef: any = React.useRef();
  console.log("selectedContractTypes11", props.selectedContractTypes);

  React.useEffect(() => {
    console.log("usefeftct", props.selectedContractTypes);
    setTagContractType(props.selectedContractTypes);
  }, [props.titleTag]);

  React.useEffect(() => {
    console.log("tagContractType", tagContractType);
  }, [tagContractType]);

  useEffect(() => {
    setTagContractType(props.selectedContractTypes);
    setprojectList([]);
    setTagContractType([]);
  }, [props.projectData]);

  useEffect(() => {
    setFormName("");
  }, []);

  const handleTags = (e: any) => {
    const data = props.addTagContractType?.filter(
      (val: any) => val.id == e.target.value
    );
    props.setTagContractType(data);
    setTagContractType(data);
    // let contractType = e.target.value;
    // setTagContractType([contractType]);
  };

  const handleProjects = (e: any) => {
    /*const {
      target: { value },
    } = e;*/
    let value = e;
    let project_id = value.id;

    props.setprojectList(project_id);
    const data = props.projectData?.filter((val: any) => val.id == project_id);
    if (data.length >= 0) {
      let index = projectList.findIndex((obj: any) => obj.id === data[0].id);
      if (index == -1) {
        setprojectList([...projectList, data[0]]);
      }
    }

    setprojectNamesList({
      ...projectNamesList,
      projectNames: [value["name"]],
      project: value.name,
    });
    // let contractType = e.target.value;
    // setTagContractType([contractType]);
  };

  const handleGroup = (e: any) => {
    props.handleGroup(e);
    const data = props.groupData?.filter(
      (val: any) => val.id == e.target.value
    );
    if (data.length >= 0) {
      let index = groupList.findIndex((obj: any) => obj.id === data[0].id);
      if (index == -1) {
        setGroupList([...groupList, data[0]]);
      }
    }
    // let contractType = e.target.value;
    // setTagContractType([contractType]);
  };

  const handleSubmit = (val: any) => {
    val.preventDefault();

    setloader(true);

    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `umbrella/projects/`,
      [
        {
          name: Formname,
          displayName: Formname, //Formdisplayname,
          comments: FormComment,
        },
      ]
    )
      .then((response) => {
        openAddProjectForm();
        props.documentLibraryAddTagGetProjecetApi();
        if (response.status == 201) {
          props.handleProjectsSet(response.data[0]);
        }
        props.documentLibraryAddTagGetGroupApi();
        setloader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowError(true);
        setloader(false);
      });
    setFormName("");
  };

  const openAddProjectForm = () => {
    setOpenForm(!openForm);
  };
  const handleProjectType = (val: any) => {
    props.handleProjectType(val);
    /* const arr=[...props.projectData]
    let arrFilter=arr?.filter((data:any)=>data.id!==val.id)
    props.projectDatastateUpdate(arrFilter)*/
    const arr = projectList;
    let arrFilter = arr?.filter((data: any) => data.id !== val.id);
    setprojectList(arrFilter);
  };
  const handleTagSubmit = () => {
    setloader(true);
    const contractID = props.checkDocument?.map((val: any) => val.id);
    // const projectId=props.projectData?.map((val:any)=>val.id)
    const projectId = props.projectList?.map((val: any) => val.id);
    const contractType = props.tagContractType?.map((val: any) => val.id);
    const groupId = props.groupList?.map((val: any) => val.id);
    AxiosGateWay.patch(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/tag/`,

      {
        contract_id: contractID,
        projects: projectId,
        contract_type: contractType,
        groups: groupId,
      }
    )
      .then((response) => {
        setprojectNamesList({ project: "", projectNames: [] });
        setloader(false);
        props.handleCloseaddtagBtn();

        props.reloadDocumentLibray();
      })
      .catch((error) => {
        setprojectNamesList({ project: "", projectNames: [] });
        setloader(false);
        console.log(error);
      });
  };
  const handleGroupType = (val: any) => {
    props.handleGroupType(val);
    /* const arr=[...props.projectData]
    let arrFilter=arr?.filter((data:any)=>data.id!==val.id)
    props.projectDatastateUpdate(arrFilter)*/
    const arr = groupList;
    let arrFilter = arr?.filter((data: any) => data.id !== val.id);
    setGroupList(arrFilter);
  };
  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const deleteProjects = async (id: any) => {
    axios
      .delete(
        `${process.env.REACT_APP_RIVERUS_CLM_API}umbrella/projects/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
          },
        }
      )
      .then((response) => {
        props.documentLibraryAddTagGetProjecetApi();
        setloader(false);
        handleProjectType(showWarningPopup.item);
      })
      .catch((error) => {
        return false;
        setloader(false);
      });
  };

  return (
    <>
      <Modal
        open={props.openModal === "modalAddTag"}
        className="deletemodal"
        onClose={() => props.handleCloseaddtagBtn()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={hierarchyStyle}
          className="position-relative"
          style={{ maxHeight: "unset" }}
        >
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              {props.titleTag}
              <span
                className="mr-0"
                onClick={() => {
                  setprojectNamesList({ project: "", projectNames: [] });
                  props.handleCloseaddtagBtn();
                }}
              >
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          </Typography>
          <Typography style={{ margin: "20px" }}>
            {loader && <CircularProgress className="loader" />}

            <div className="row">
              <div className="col-md-12">
                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span className="addTag-title ">Contract Type</span>
                    <span
                      className="addtag-reset"
                      onClick={() => {
                        setTagContractType([]);
                        props.setTagContractType([]);
                      }}
                    >
                      Reset
                    </span>
                  </div>
                  <div className="mt-1">
                    <FormControl
                      variant="outlined"
                      className="riverus-text-box"
                      size="small"
                      fullWidth={true}
                    >
                      <Select
                        // className="addtag-selectBox"
                        value={
                          // tagContractType?.length ? tagContractType[0]?.id : ""
                          props.tagContractType?.length
                            ? props.tagContractType[0]?.id
                            : ""
                        }
                        onChange={handleTags}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        fullWidth={true}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 240,
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {/* <option
                        selected
                        className="addtag-selectBox-title"
                        value={""}
                        disabled
                      >
                        Select Dropdown{" "}
                      </option> */}
                        {props.addTagContractType.map((item: any) => {
                          return (
                            <MenuItem
                              key={`contracttags-${item.id}`}
                              style={{ whiteSpace: "initial" }}
                              value={item.id}
                            >
                              {item.name}
                            </MenuItem>
                          );
                          // <option value={item.id} key={`contracttags-${item.id}`}>{item.name}</option>;
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <div style={{ marginTop: "6px" }}>
                      {props.tagContractType?.map((val: any) => {
                        return (
                          <>
                            <span className="contract-type">
                              {val.name}
                              <img
                                src={"/static_images/mdi_close.svg"}
                                alt=""
                                style={{
                                  height: "24px",
                                  width: "24px",
                                  color: "#EE5800", //"#88305F",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setTagContractType([]);
                                  props.setTagContractType([]);
                                }}
                              />
                            </span>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12" style={{ marginTop: "20px" }}>
                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span className="addTag-title ">Project</span>
                    <span
                      className="addtag-reset"
                      onClick={() => openAddProjectForm()}
                    >
                      +Add NEW
                    </span>
                  </div>
                  <div className="mt-1">
                    {/* {JSON.stringify(props.projectData)} */}
                    <FormControl
                      variant="outlined"
                      className="riverus-text-box"
                      size="small"
                      fullWidth={true}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        // value={projectNamesList.projectNames}
                        value={projectNamesList.project}
                        // onChange={handleProjects}
                        // renderValue={}
                        renderValue={(selected: any) =>
                          projectNamesList.project
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 240,
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        placeholder={"Select Projects"}
                      >
                        {props.projectData.map((item: any, index: number) => {
                          return (
                            <MenuItem key={index} value={item}>
                              <ListItemText
                                style={{ whiteSpace: "initial" }}
                                primary={item.displayName}
                                onClick={() => {
                                  handleProjects(item);
                                }}
                              />
                              <ListItemIcon className="justify-content-end">
                                <AiOutlineDelete
                                  color="red"
                                  onClick={async () => {
                                    setWarningPopup({
                                      isShow: true,
                                      tagValue: item.id,
                                      type: "delete",
                                      item: item,
                                    });
                                  }}
                                />
                              </ListItemIcon>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <select
                        className="addtag-selectBox"
                        onChange={handleProjects}
                        style={{ display: "none" }}
                      >
                        <option
                          selected
                          className="addtag-selectBox-title"
                          disabled
                        >
                          Multiselect Dropdown{" "}
                        </option>
                        {props.projectData.map((item: any) => {
                          return (
                            <option value={item.id}>{item.displayName}</option>
                          );
                        })}
                      </select>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginLeft: "16px",
                  }}
                  className="d-flex align-items-center"
                >
                  {/* {props.projectData?.map((val: any) => { */}

                  {props.projectList.map((val: any) => {
                    return (
                      <>
                        <div
                          className="project-type"
                          style={{
                            backgroundColor: "#EEEDFF",
                            color: "#392494",
                            fontWeight: "bold",
                          }}
                        >
                          {val.displayName}
                          <img
                            src={"/static_images/mdi_close.svg"}
                            alt=""
                            style={{
                              height: "16px",
                              width: "16px",
                              color: "#392494", //"#88305F",
                              cursor: "pointer",
                              marginLeft: "7px",
                              fontWeight: "bold",
                            }}
                            onClick={() => handleProjectType(val)}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {openForm ? (
              <form>
                <div
                  className="row"
                  style={{ gap: "15px", marginLeft: "16px", marginTop: "10px" }}
                >
                  <input
                    type="text"
                    name="name"
                    onChange={(val) => {
                      setFormName(val.target.value);
                      setshowError(false);
                    }}
                    value={Formname}
                    placeholder="Name"
                    className="col-3 col-md-3"
                    style={{ border: "1px solid #dddddd" }}
                  />

                  <input
                    type="text"
                    name="displayName"
                    onChange={(val) => setFormdisplayname(val.target.value)}
                    value={Formdisplayname}
                    className="col-3 col-md-3"
                    placeholder="DisplayName"
                    style={{ border: "1px solid #dddddd", display: "none" }}
                  />
                  <input
                    type="text"
                    name="comments"
                    onChange={(val) => setFormComment(val.target.value)}
                    value={FormComment}
                    className="col-3 col-md-3"
                    placeholder="Comments"
                    style={{ border: "1px solid #dddddd", display: "none" }}
                  />
                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className=" form-submit-btn"
                    style={{ height: "25px" }}
                  >
                    <img
                      src={"/static_images/tick-filter-icn.svg"}
                      alt=""
                      style={{
                        height: "8px",
                        // width: "16px",
                        color: "#88305F",
                        cursor: "pointer",
                      }}
                    />
                  </button>
                </div>
                {showError ? (
                  <p
                    style={{
                      marginLeft: "16px",
                      marginTop: "5px",
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    Project name already exists
                  </p>
                ) : null}
              </form>
            ) : (
              ""
            )}
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span className="addTag-title ">Group</span>
                    <span
                      className="addtag-reset"
                      onClick={() => {
                        props.resetGroups();
                      }}
                    >
                      Reset
                    </span>
                  </div>
                  <div className="mt-1">
                    <FormControl
                      variant="outlined"
                      className="riverus-text-box"
                      size="small"
                      fullWidth={true}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-groups-checkbox"
                        // value={projectNamesList.projectNames}
                        onChange={handleGroup}
                        // renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 240,
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        placeholder={"Select Groups"}
                      >
                        {props.groupData.map((item: any) => {
                          if (
                            item.name !== "/org" &&
                            item.name !== "/file-manager"
                          ) {
                            return (
                              <MenuItem
                                key={`groups-${item.id}`}
                                style={{ whiteSpace: "initial" }}
                                value={item.id}
                              >
                                {item.name.split("/").pop()}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                    <select
                      className="addtag-selectBox"
                      onChange={handleGroup}
                      style={{ display: "none" }}
                    >
                      <option
                        selected
                        className="addtag-selectBox-title"
                        disabled
                      >
                        Multiselect Dropdown{" "}
                      </option>
                      {props.groupData.map((item: any) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "15px",
                flexWrap: "wrap",
                gap: "10px",
                marginLeft: "16px",
              }}
              className="d-flex align-items-center"
            >
              {/* {props.projectData?.map((val: any) => { */}

              {props.groupList?.map((val: any) => {
                if (val.name !== "/org" && val.name !== "/file-manager") {
                  return (
                    <>
                      <div
                        className="project-type"
                        style={{ backgroundColor: "#abe7bf" }}
                      >
                        {val.name.split("/").pop()}
                        <img
                          src={"/static_images/mdi_close.svg"}
                          alt=""
                          style={{
                            height: "16px",
                            width: "16px",
                            color: "#88305F",
                            cursor: "pointer",
                            marginLeft: "7px",
                          }}
                          onClick={() => handleGroupType(val)}
                        />
                      </div>
                    </>
                  );
                }
              })}
            </div>
          </Typography>
          <div
            className=" d-flex justify-content-end cursor-pointer  "
            style={{ paddingTop: "110px" }}
          >
            <img
              src={"/static_images/Main-Button.svg"}
              onClick={() => handleTagSubmit()}
              alt=""
            />
          </div>
        </Box>
      </Modal>

      {showWarningPopup.isShow && (
        <WarningPopup
          openWarningPopup={showWarningPopup.isShow}
          message={
            showWarningPopup.type === "delete"
              ? "Are you sure you want to delete the Project?"
              : "Are you sure you want to Add the Project?"
          }
          onClose={() => {
            setWarningPopup({
              isShow: false,
              tagValue: "",
              type: "",
              item: {},
            });
          }}
          onClick={() => {
            if (showWarningPopup.type === "delete") {
              setloader(true);
              deleteProjects(showWarningPopup.tagValue);
            }
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTag);
