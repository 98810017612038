import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import axios from "axios";
const APIPaths = {
  getProjects: process.env.REACT_APP_RIVERUS_CLM_API + "stylus/project/",
  getContractType:
    process.env.REACT_APP_RIVERUS_CLM_API + "admin/contracttype/",
  getUserUploads: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/",
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    // Origin: process.env.REACT_APP_RIVERUS_CLM_APP_HOST,
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET, POST",
    // "Content-Type": "application/json",x
  },
});

export const getContractTypes = () => {
  return axios
    .get(APIPaths.getContractType, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("getContractTypes error", error);
    });
};

export const getProjects = () => {
  return axios
    .get(APIPaths.getProjects, APIConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error));
};

export const getUserUploads = (contractPageCount: number) => {
  return axios
    .get(APIPaths.getUserUploads+"?page="+contractPageCount, APIConfig())
    .then((response) => response.data)
    .catch((error) => console.error(error));
};
