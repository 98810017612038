import {
  CircularProgress,
  Divider,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FilterData } from "../../Draft/State/DraftState";
import { getContractTypes, getProjects, getUserUploads } from "./DA";
import "./filterModal.scss";
interface Props {
  open: boolean;
  anchorEl?: any | null;
  handleClose: () => void;
  applyFilter: (
    searchKey: string[],
    filterKey: string,
    updateSectionClauseSuccess?: any,
    isSort?: any
  ) => void;
  filterKeyVals: FilterData;
  filterKey: string;
  list: any[];
  data?: any | null;
  updateSectionClauseSuccess?: any | null;
  isSort?: any | null;
  type?: string | null;
  selectedItem?: string[];
  showSearchBar?: boolean;
}

const FilterInputModal = (props: Props) => {
  const [list, setlist] = useState<string[]>([]);
  const [filteredList, setfilteredList] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setloading] = useState(false);
  const [selectedList, setselectedList] = useState<string[]>([]);
  const [searchData, setsearchData] = useState("");
  // useEffect(() => {
  //     if (props.filterKey == 'contractType' && list.length == 0) {
  //         fetchContractTypes();
  //     }
  //     if (props.filterKey == 'projectNames' && list.length == 0) {
  //         fetchProjects();
  //     }
  // }, [props.filterKey]);

  useEffect(() => {
    if (props.selectedItem) {
      setselectedList([...props.selectedItem]);
    }
  }, [props.selectedItem]);

  useEffect(() => {
    if (props.list.length > 0) {
      setlist(props.list);
    }
  }, [props.list]);

  useEffect(() => {
    applySearch();
  }, [search, list]);

  useEffect(() => {
    if (props.open) {
      applySearch();
    }
  }, [props.open]);

  const fetchContractTypes = async () => {
    try {
      setloading(true);
      const response = await getContractTypes();
      if (response && response.results.length > 0) {
        setlist(response.results.map((result: any) => result.name));
      } else if (response && response.results.length == 0) {
        setlist([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  function removeDuplicates(arr: any) {
    return arr.filter((item: any, index: any) => arr.indexOf(item) === index);
  }

  const fetchUploaded = async () => {
    try {
      setloading(true);
      const response = await getUserUploads(1);
      if (response && response.results.length > 0) {
        let arr = [];
        arr = response.results.map((result: any) => result.created_by);
        let newarr = removeDuplicates(arr);
        setlist(newarr);
      } else if (response && response.results.length == 0) {
        setlist([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      setloading(true);
      const response = await getProjects();
      if (response && response.results.length > 0) {
        setlist(response.results.map((result: any) => result.name));
      } else if (response && response.results.length == 0) {
        setlist([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };
  const fetchStatuses = () => {
    try {
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const applySearch = () => {
    if (search == "") {
      setfilteredList([...list]);
    } else {
      let filteredList = list.filter((data: any) => {
        if (data.displayName) {
          return data.displayName.toLowerCase().includes(search.toLowerCase());
        } else {
          return data.toLowerCase().includes(search.toLowerCase());
        }
      });
      setfilteredList([...filteredList]);
    }
  };

  const selectItem = (item: string) => {
    let selected = [...selectedList];
    let index = selected.indexOf(item.toLowerCase());
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(item.toLowerCase());
    }
    setselectedList([...selected]);
    setsearchData(selected.join());
    // apply filter while selecting checkbox also
    props.applyFilter(
      selected.map((item) => item.toLowerCase()),
      props.filterKey
    );
  };

  const applyFilter = () => {
    if (props.type === "tags") {
      props.applyFilter(
        selectedList.map((item) => item.toLowerCase()),
        props.data,
        props.updateSectionClauseSuccess,
        props.isSort
      );
    } else {
      props.applyFilter(
        selectedList.map((item) => item.toLowerCase()),
        props.filterKey
      );
    }
  };

  const showSearchBar = () => {
    let searchBar = (
      <FormControl className="mb-2" fullWidth size="small" variant="outlined">
        <OutlinedInput
          id="outlined-adornment-password"
          value={search}
          placeholder={searchData}
          onChange={(e) => setSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              {loading ? (
                <CircularProgress style={{ width: "25px", height: "25px" }} />
              ) : (
                <AiOutlineSearch
                  className="cp"
                  style={{ color: "grey" }}
                  size={25}
                  onClick={applyFilter}
                />
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    );
    if (props.showSearchBar == undefined) {
      return searchBar;
    } else {
      if (props.showSearchBar) {
        return searchBar;
      } else return "";
    }
  };

  return (
    <Popover
      elevation={0}
      id={props.open ? new Date().toISOString() : undefined}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="filter">
        {showSearchBar()}
        <div
          className="filter__list-box"
          style={{ maxHeight: "250px", overflow: "scroll" }}
        >
          {filteredList.length > 0 &&
            filteredList.map((listItem, index) => {
              return (
                <div key={index}>
                  <div className={`row m-0 p-0 mb-1`}>
                    <div
                      className="col-md-10 pl-0"
                      style={{ maxWidth: "83%", flex: "0 0 83%" }}
                    >
                      {listItem.displayName ? listItem.displayName : listItem}
                    </div>
                    <div
                      className="col-md-2"
                      style={{
                        paddingRight: "0",
                        flex: "0 0 16%",
                        textAlign: "right",
                      }}
                    >
                      {selectedList.indexOf(
                        listItem.displayName
                          ? listItem.displayName.toLowerCase()
                          : listItem.toLowerCase()
                      ) == -1 && (
                        <img
                          onClick={() => {
                            if (listItem.displayName) {
                              selectItem(listItem.displayName);
                            } else {
                              selectItem(listItem);
                            }
                          }}
                          style={{ width: "16px" }}
                          src="/static_images/checkbox_neutral.svg"
                        />
                      )}
                      {selectedList.indexOf(
                        listItem.displayName
                          ? listItem.displayName.toLowerCase()
                          : listItem.toLowerCase()
                      ) > -1 && (
                        <img
                          onClick={() => {
                            if (listItem.displayName) {
                              selectItem(listItem.displayName);
                            } else {
                              selectItem(listItem);
                            }
                          }}
                          style={{
                            width: "20px",
                            position: "relative",
                            left: "1px",
                          }}
                          src="/static_images/checked_icon.svg"
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Popover>
  );
};

export default FilterInputModal;
