export interface FieldName {
  field_name: string;
  display_name: string;
  data_type: any[];
  field_validation: string;
  set_recommended_value: RecommendedValue;
  section_dictionary: any[];
  description: string;
  required: boolean;
  ui_component: string;
}

export interface RecommendedValue {
  default_field_value: string | null;
  default_field_unit: string | null;
}

export const initialFieldName: FieldName = {
  field_name: "",
  ui_component: "",
  display_name: "Field Name",
  data_type: [],
  field_validation: "",
  set_recommended_value: {
    default_field_value: "",
    default_field_unit: "",
  },
  description: "",
  required: false,
  section_dictionary: [],
};
