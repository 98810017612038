import React, { useEffect } from "react";
import { History } from "history";
import { getLocalStorage } from "../Authentication/Actions/authentication";
interface Props {
  history: History;
}

const Admin = (props: Props) => {
  useEffect(() => {
    if (getLocalStorage("user_profile").roles.includes("admin")) {
      props.history.push("/admin/dataDictionaryAdmin");
    } else {
      props.history.push("/documentlibrary");
    }
  }, []);

  return <div></div>;
};

export default Admin;
