import {
  ClauseLibraryActions,
  CREATECLAUSE,
  CREATEFOLDERHEADING,
  CREATEFOLDERSUBHEADING,
  CREATESECTIONCLAUSE,
  CREATESECTIONCLAUSE_FAILURE,
  CREATESECTIONCLAUSE_SUCCESS,
  DELETECLAUSE,
  DELETEFOLDER,
  GETCLAUSELIBRARYDATA,
  GETCLAUSELIBRARYDATA_SUCCESS,
  GETCLAUSESDATA,
  GETCLAUSESDATA_SUCCESS,
  GETFOLDERHEADING_SUCCESS,
  GETFOLDERSUBHEADING,
  GETFOLDERSUBHEADING_SUCCESS,
  GETSECTIONCLAUSE,
  GETSECTIONCLAUSE_SUCCESS,
  GETSECTIONDICTIONARY_SUCCESS,
  SAVECOLLAPSEDFOLERIDS,
  SAVEFOLDERSUBHEADING,
  SAVESELECTEDCLAUSEDATA,
  UPDATESECTIONCLAUSE,
  UPDATESECTIONCLAUSE_SUCCESS,
  GETSECTIONCLAUSE_FAILURE,
} from "../Actions/def";
import ClauseLibraryState, {
  defaultClauseLibraryState,
} from "../State/clauseLibraryState";

export default function clauseLibraryReducer(
  state: ClauseLibraryState = defaultClauseLibraryState(),
  action: ClauseLibraryActions
): ClauseLibraryState {
  switch (action.type) {
    case UPDATESECTIONCLAUSE: {
      return {
        ...state,
        updateSectionClauseList: [],
      };
    }
    case UPDATESECTIONCLAUSE_SUCCESS: {
      return {
        ...state,
        updateSectionClauseList: action.payload.updateSectionClauseList,
      };
    }
    case GETSECTIONCLAUSE: {
      return {
        ...state,
        sectionClauseList: [],
        clauseLibraryLoader: true,
      };
    }
    case GETSECTIONCLAUSE_SUCCESS: {
      return {
        ...state,
        sectionClauseList: action.payload.sectionClauseList,
        clauseLibraryLoader: false,
        clause_Library_Loader: false,
        totalCount:action.payload.totalCount
      };
    }

    case GETSECTIONDICTIONARY_SUCCESS: {
      return {
        ...state,
        sectionDictionaryList: action.payload.sectionDictionaryList,
      };
    }
    case GETFOLDERHEADING_SUCCESS: {
      return { ...state, folderHeadingList: action.payload.folderHeadingList };
    }
    case GETFOLDERSUBHEADING: {
      return { ...state, folderSubHeadingList: [] };
    }
    case GETFOLDERSUBHEADING_SUCCESS: {
      return {
        ...state,
        folderSubHeadingList: action.payload.folderSubHeadingList,
      };
    }
    case SAVECOLLAPSEDFOLERIDS: {
      return {
        ...state,
        collapsedFolderIds: action.payload.collapsedFolderIds,
      };
    }
    case SAVESELECTEDCLAUSEDATA: {
      return {
        ...state,
        selectedClauseData: action.payload.selectedClauseData,
      };
    }
    case SAVEFOLDERSUBHEADING: {
      return {
        ...state,
        folderSubHeadingList: action.payload.folderSubHeadingList,
      };
    }
    // Create Section Clause
    case CREATESECTIONCLAUSE: {
      return { ...state, clauseLibraryLoader: true };
    }
    case CREATECLAUSE: {
      return { ...state, clauseLibraryLoader: true };
    }
    case CREATEFOLDERHEADING: {
      return { ...state, clauseLibraryLoader: true };
    }
    case CREATEFOLDERSUBHEADING: {
      return { ...state, clauseLibraryLoader: true };
    }
    case DELETECLAUSE: {
      return { ...state, clauseLibraryLoader: true };
    }
    case DELETEFOLDER: {
      return { ...state, clauseLibraryLoader: true };
    }
    case GETCLAUSELIBRARYDATA: {
      return { ...state, clauseLibraryLoader: true, clauseLibraryData: [] };
    }
    case GETCLAUSELIBRARYDATA_SUCCESS: {
      return {
        ...state,
        clauseLibraryData: action.payload.clauseLibraryData,
        clauseLibraryLoader: false,
      };
    }
    case GETCLAUSESDATA: {
      return { ...state, clauseIds: action.payload.clauseIds };
    }
    case GETCLAUSESDATA_SUCCESS: {
      return {
        ...state,
        clausesData: action.payload.clausesData,
        clauseLibraryLoader: false,
      };
    }
    case CREATESECTIONCLAUSE_SUCCESS: {
      return {
        ...state,
        clause_Library_Loader: true,
      }
    }
    case CREATESECTIONCLAUSE_FAILURE: {
      return {
        ...state,
        clause_Library_Loader: false
      }
    }
    case GETSECTIONCLAUSE_FAILURE: {
      return {
        ...state,
        clause_Library_Loader: false
      }
    }
    default:
      return state;
  }
}
