import React, { useEffect, useRef, useState } from "react";
import SideNavbar from "../../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import { History } from "history";
import "../../Design/template.scss";
import ListView from "../../Component/ListView";
import {
  Button,
  CircularProgress,
  Snackbar,
  AppBar,
  Tabs,
  Tab,
  Box,
} from "@material-ui/core";
import { Tag, TemplateData } from "../../State/templateState";
import { File } from "../../Constants/types";
import { getDateString } from "../../../Utils/DataModifierUtil/dataModUtil";
import { ContractTypeRes } from "../../../ContractTypes/State/ContractTypeState";
import { getContractTypes } from "../../../ContractTypes/DA";
import { IconContext } from "react-icons";
import {
  AiOutlineDownload,
  AiOutlineReload,
  AiOutlineUpload,
} from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import DeleteTemplate from "../../Component/DeleteTemplate";
import UploadFileDialog from "../../../Draft/Component/UploadFileDialog";
import saveAs from "file-saver";
import AddTagDialog from "../../Component/AddTagDialog";
import { getFieldName, getTagFromTagLibrary } from "../../DA";
interface Props {
  history: History;
  pageWatcher: (page: string) => void;
  upload: (post: any, callback: Function) => void;
  files: File[];
  response: any;
  getTemplates: (page: number) => Promise<any>;
}

const TemplateAdmin = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState("");
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const [templateType, setTemplateType] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [templates, setTempates] = useState<TemplateData[]>([]);
  const [multipleTypes, setMultipleTypes] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [contractTypeList, setcontractTypeList] = useState<ContractTypeRes[]>(
    []
  );
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [value, setValue] = useState(0);
  const [selectedTemplate, setselectedTemplate] = useState<string[]>([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deletingTemplateData, setDeletingData] = useState<TemplateData[]>([]);
  const [supportDocTemplate, setsupportDocTemplate] = useState<TemplateData[]>(
    []
  );
  const [allTemplates, setallTemplates] = useState<TemplateData[]>([]);
  const [myTemplateCount, setmyTemplateCount] = useState(0);
  const [openAddTagDialog, setopenAddTagDialog] = useState(false);
  const [tagList, settagList] = useState<Tag[]>([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [fieldNameList, setFieldNameList] = useState<any>([]);
  const [totalCount, settotalCount] = useState(0);

  const fieldData = async () => {
    const data: any = await getFieldName();
    if (data && data.length) {
      setFieldNameList(data);
    }
  };
  useEffect(() => {
    fieldData();
  }, []);

  useEffect(() => {
  }, [fieldNameList]);

  useEffect(() => {
    props.pageWatcher("templatesAdmin");
    getTemplates(pageNumber);
    getContractType();
    getTags();
  }, []);

  useEffect(() => {
    if (selectTemplate !== "upload") {
      setShowTemplateForm(false);
    }
  }, [selectTemplate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getContractType = async () => {
    try {
      const response = await getContractTypes();
      if (response && response.results.length > 0) {
        setcontractTypeList(response.results);
      } else if (response && response.results.length == 0) {
        setcontractTypeList([]);
      }
    } catch (error) {
      console.error("getContractType", error);
    }
  };

  const getTemplates = async (page: number) => {
    try {
      setLoading(true);
      let response = await props.getTemplates(page);
      if (response && response.results.length > 0) {
        let templates: any[] = response.results.map((res: any) => {
          let template: any = {
            name: res.file_name,
            createdOn: getDateString(new Date(res.created_on)),
            owner: res.created_by,
            lastUsed: getDateString(new Date(res.last_used)),
            templateLink: res.file_path,
            templateType: res.template_type || "",
            fileId: res.id,
            filePath: res.file_path,
            id: res?.id,
            template_id: res.template_id,
            fileType: res.file_type,
            tags: res.tags,
            creation_type: res.creation_type,
          };
          return template;
        });
        settotalCount(response.count);
        setallTemplates(templates.reverse());
        let contractTemplates = templates.filter(
          (template) => template.fileType.toLowerCase() == "template"
        );
        setTempates(contractTemplates.reverse());
        let supportDocTemplates = templates.filter(
          (template) =>
            template.fileType.toLowerCase() == "support_doc_template"
        );
        setsupportDocTemplate(supportDocTemplates.reverse());
        let user_id = localStorage.getItem("user_id");
        let myTemplateCount = templates.reduce((acc, curr) => {
          const { owner } = curr;
          if (owner && user_id && owner.id == user_id) {
            return (acc = acc + 1);
          }
          return acc;
        }, 0);
        setmyTemplateCount(myTemplateCount);
      } else if (response && response.results.length == 0) {
        setallTemplates([]);
        setTempates([]);
        setsupportDocTemplate([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getTags = async () => {
    try {
      const response = await getTagFromTagLibrary();
      if (response && response.results) {
        settagList(response.results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setUploadedFiles([]);
    setSelectTemplate("");
    setOpen(false);
    setMultipleTypes([]);
    setTemplateType("");
    setLoading(false);
  };

  const generateTopHeader = () => {
    return (
      <>
        <div className="row justify-content-between">
          <div className="col">
            <p className="stylus_section_header">Templates</p>
          </div>
          <div className="col text-align-end mr-2">
            <button
              onClick={handleClickOpen}
              type="button"
              className="btn btn-warning create-btn"
            >
              <span
                style={{ position: "relative", top: "3px", marginRight: "5px" }}
              >
                <IconContext.Provider
                  value={{ color: "#626262", className: "reload-icon" }}
                >
                  <AiOutlineUpload />
                </IconContext.Provider>
              </span>
              Upload a Template
            </button>
          </div>
        </div>
        <div className="mb-3">
          <div className="row m-0">
            <div className="col-md-6">{displayCount({})}</div>
          </div>
        </div>
      </>
    );
  };

  const displayCount = (counts: any) => {
    return (
      <div className="draft-row mb-2">
        <div className="draft-count-box">
          <p className="draft-count mb-1">{allTemplates.length}</p>
          <p className="m-0">TOTAL TEMPLATES</p>
        </div>
        <span style={{ width: "20px" }}></span>
        <div className="draft-count-box">
          <p className="draft-count mb-1">0</p>
          <p className="m-0 uppercase">SYSTEM TEMPLATES</p>
        </div>
        <span style={{ width: "20px" }}></span>
        <div className="draft-count-box">
          <p className="draft-count mb-1">{myTemplateCount}</p>
          <p className="m-0 uppercase">MY TEMPLATES</p>
        </div>
      </div>
    );
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleSelectTemplate = (id: string) => {
    let selected = [...selectedTemplate];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedTemplate([...selected]);
  };

  const deleteTemplate = () => {
    let deleteTemplates = selectedTemplate.reduce<TemplateData[]>(
      (acc, curr) => {
        let templatesArr = allTemplates.find((value: any) => value?.id == curr);
        if (templatesArr) {
          acc = [...acc, templatesArr];
        }
        return acc;
      },
      []
    );
    setDeletingData(deleteTemplates);
    setOpenDelete(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDelete(false);
  };

  const getDelayedTemplates = () => {
    setTimeout(() => {
      getTemplates(pageNumber);
    }, 500);
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const download = () => {
    let templates = selectedTemplate.reduce<TemplateData[]>((acc, curr) => {
      let templatesArr = allTemplates.find((value: any) => value.id == curr);
      if (templatesArr) {
        acc = [...acc, templatesArr];
      }
      return acc;
    }, []);
    templates.forEach((template) => {
      if (template.filePath) {
        let name = template.filePath.split("/").pop();
        saveAs(template.filePath, name);
      }
    });
  };

  const closeTagDialog = (template: any) => {
    if (template) {
      setTimeout(() => {
        getTemplates(pageNumber);
      }, 300);
      setopenSnackbar({ open: true, msg: "Tag Added Successfully" });
      setselectedTemplate([]);
    }
    setopenAddTagDialog(false);
  };

  const openTagDialog = () => {
    let deleteTemplates = selectedTemplate.reduce<TemplateData[]>(
      (acc, curr) => {
        let templatesArr = allTemplates.find((value: any) => value?.id == curr);
        if (templatesArr) {
          acc = [...acc, templatesArr];
        }
        return acc;
      },
      []
    );
    setDeletingData(deleteTemplates);
    setopenAddTagDialog(true);
  };

  const handleSelectAllTemplate = (isContract: boolean) => {
    if (selectedTemplate.length > 0) {
      setselectedTemplate([]);
      return;
    }
    let selected: string[] = [];
    if (isContract) {
      selected = templates.map((temp) => temp.id || "");
    } else {
      selected = supportDocTemplate.map((temp) => temp.id || "");
    }
    setselectedTemplate([...selected]);
  };

  const updatePageNumber = (number: number) => {
    getTemplates(number);
    setpageNumber(number);
  };
  return (
    <div className="row">
      <div className="col-md-1" style={{ zIndex: 2, flex: "0 0 7.333333%" }}>
        <SideNavbar history={props.history} />
      </div>
      <div className="col-md-11 mt-6">
        {generateTopHeader()}
        {isLoading && <CircularProgress className="loader" />}
        <AppBar position="static" className="mb-2">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            className="draft-tabs"
          >
            <Tab disableRipple label="Contract Templates" {...a11yProps(0)} />
            <Tab
              disableRipple
              label="Support Document Templates"
              {...a11yProps(1)}
            />
          </Tabs>
        </AppBar>
        <div className="row justify-content-between mb-2">
          <div className="col-md-8">
            <div className="row mt-1 mb-1" style={{ marginLeft: "0" }}>
              <IconContext.Provider
                value={{ color: "#88305F", className: "reload-icon" }}
              >
                <AiOutlineReload
                  className="ml-2"
                  onClick={() => getTemplates(pageNumber)}
                />
              </IconContext.Provider>
            </div>
          </div>
          <div className="col-md-4">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {selectedTemplate.length == 1 && (
                <img
                  style={{ width: "21px", margin: "0 7px" }}
                  src="/static_images/section-filter.svg"
                  alt="refresh"
                  onClick={openTagDialog}
                />
              )}
              {selectedTemplate.length > 0 && (
                <IconContext.Provider
                  value={{ color: "#88305F", className: "edit-icon" }}
                >
                  <AiOutlineDownload onClick={download} />
                </IconContext.Provider>
              )}
              {selectedTemplate.length > 0 && (
                <IconContext.Provider
                  value={{ color: "#FF685F", className: "svg-delete-icon" }}
                >
                  <BsTrash onClick={() => deleteTemplate()} />
                </IconContext.Provider>
              )}
            </div>
          </div>
        </div>
        <TabPanel value={value} index={0}>
          <ListView
            pageNumber={pageNumber}
            updatePageNumber={updatePageNumber}
            selectAllTemplate={handleSelectAllTemplate}
            isSupportTemplate={false}
            selectMultipleTemplates={handleSelectTemplate}
            selectedTemplate={selectedTemplate}
            history={props.history}
            templateData={templates}
            fieldNameList={fieldNameList}
            getTemplates={() => getTemplates(pageNumber)}
            totalCount={totalCount}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ListView
            pageNumber={pageNumber}
            updatePageNumber={updatePageNumber}
            selectAllTemplate={handleSelectAllTemplate}
            isSupportTemplate={true}
            selectMultipleTemplates={handleSelectTemplate}
            selectedTemplate={selectedTemplate}
            history={props.history}
            templateData={supportDocTemplate}
            getTemplates={() => getTemplates(pageNumber)}
            fieldNameList={fieldNameList}
            totalCount={totalCount}
          />
        </TabPanel>
      </div>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar.open}
        onClose={closeSnackbar}
        message={openSnackbar.msg}
        key="bottomCenter"
        action={action}
      />
      <DeleteTemplate
        selectedTemplates={deletingTemplateData}
        getTemplates={() => getTemplates(pageNumber)}
        open={openDelete}
        handleClose={handleDeleteDialogClose}
      />
      <UploadFileDialog
        title="Upload Template"
        fileType="template"
        uploadedFileResponse={getDelayedTemplates}
        open={open}
        onClose={handleClose}
        contractType={contractTypeList}
        contractData={[]}
        show={false}
        isInternal={false}
        handleisinternal={() => {}}
        setisinternal={() => {}}
      />
      {openAddTagDialog && (
        <AddTagDialog
          getTags={getTags}
          open={openAddTagDialog}
          handleClose={closeTagDialog}
          template={deletingTemplateData[0]}
          tags={tagList}
        />
      )}
    </div>
  );
};

export default TemplateAdmin;
