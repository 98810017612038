import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Typography,
} from "@material-ui/core";
import "../../Design/style.css";
import { getFormValue } from "../../Component/fixture";
import { useStateManage } from "../../Component/useStateManage";
import AxiosGateWay from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import SideNavbar from "../../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import SectionViewAll from "../../Component/ViewAllSection/SectionViewAll";
import { ContractTypeDetails } from "../../Component/ContractTypeDetails/ContractTypeDetails";
import { DefaultFieldDataScreen } from "../../Component/DetailScreen/FieldData";
import ClauselibraryDrawer from "../../../UniversalComponents/ClauseLibraryDrawer/Component/ClauseLibraryDrawer";
import DeleteSection from "../../Component/DeleteSection";
import AddSection from "../../Component/AddSection";
import { DefaultListScreen } from "../../Component/ListScreen/ListScreen";
import { History } from "history";
import AddContractDialog from "../../../ContractTypes/Component/AddContractDialog";
import { getContractTypes } from "../../../ContractTypes/DA";

interface Props {
  history: History;
  renderHeader: () => "";
  pageWatcher: (page: string) => void;
}

type FilterData = {
  [name: string]: string[];
};

const DataDictionaryAdmin = (props: Props) => {
  const { history } = props;
  const {
    TableHeader,
    ScreenTitle,
    TableValues,
    SectionDetails,
    contractCardContent,
    sectionCardContent,
    fieldCardContent,
  } = getFormValue(props);
  const {
    getContractTypeData,
    tableValue,
    isEditScreen,
    selectedRow,
    setIsEditScreen,
    isSectionDataView,
    setIsSectionDataView,
    sectionDetailsData,
    setSectionDetailsData,
    selectedContractTypeId,
    setSelectedContractTypeId,
    getContractTypeBasedSectionDetails,
    isListView,
    setIsListView,
    loading,
    setloading,
    selectedSection,
    addField,
    setAddField,
    setselectedSection,
    newSection,
    setNewSectionData,
    createSection,
    deleteSection,
    getDataType,
    setDataType,
    dataType,
    getDefaultValue,
    setDefaultResponse,
    defaultResponse,
    postFieldName,
    deleteFieldName,
    updateFieldName,
    setIsSame,
    getSectionDictionary,
    setSectionDictionary,
    sectionDictionary,
    isSame,
    getFieldName,
    viewAllFielUpdate,
    setTableValues,
    viewAllSectionUpdate,
    reOrderSection,
    isSectionSort,
    ...restStateProps
  } = useStateManage();
  const formProps = {
    TableHeader,
    SectionDetails,
    ScreenTitle,
    contractCardContent,
    sectionCardContent,
    fieldCardContent,
    TableValues,
    tableValue,
    isEditScreen,
    setIsEditScreen,
    sectionDetailsData,
    selectedContractTypeId,
    setSelectedContractTypeId,
    setSectionDetailsData,
    getContractTypeBasedSectionDetails,
    selectedRow,
    isListView,
    setIsListView,
    loading,
    setloading,
    ...restStateProps,
  };

  const [isDelete, setDelete] = useState(false);
  const [modifiedDate, setModifiedDate] = useState("");
  const [isSectionView, setIsSectionView] = useState(false);
  const [isFieldView, setIsFieldView] = useState(false);
  const [openClauseDrawer, setopenClauseDrawer] = useState(false);
  const [addSection, setAddSection] = useState<any>(false);
  const [openContractDialog, setContractDialog] = useState<any>(false);
  const [editSection, setSectionData] = useState<any>(null);
  const [filterKeyVals, setFilterKeyVals] = useState<FilterData>({});
  const [userList, setuserList] = useState<any[]>([]);
  const [filteredList, setfilteredList] = useState<any[]>([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [tabName, setTabName] = useState("default");
  useEffect(() => {}, [tabName]);

  useEffect(() => {
    props?.pageWatcher("dataDictionaryAdmin");
    getContractTypeData(1);
  }, []);

  useEffect(() => {
    if (isEditScreen == false) {
      history.push("/admin/dataDictionaryAdmin");
    } else if (isEditScreen == true) {
      history.push(`/admin/dataDictionaryAdmin/${selectedRow}`);
    }
  }, []);

  useEffect(() => {
    if (isListView == "EditView") {
      getContractTypeBasedSectionDetails({
        selectedRow: selectedContractTypeId,
      });
    }
  }, [isListView]);

  const onClickBackButton = (props: any) => {
    const { history } = props;
    history.replace(`/admin/dataDictionaryAdmin`);
    setIsEditScreen(false);
    setIsListView("ListView");
    getContractTypeData(1);
    setTabName("default");
  };

  useEffect(() => {
    setFilter();
  }, [filterKeyVals]);

  useEffect(() => {
    const newSections = {
      ...newSection,
      contract_type: [selectedContractTypeId],
    };
    if (selectedContractTypeId) {
      createSection(newSections);
    }
  }, [newSection]);

  useEffect(() => {
    setfilteredList([...tableValue]);
  }, [tableValue]);

  const getUserList = (value: any) => {
    if (value.length) {
      let users = Array.from(
        new Set(
          value.map((data: any) => {
            if (data.last_edited_by) {
              return (
                data?.last_edited_by?.first_name +
                " " +
                data?.last_edited_by?.last_name
              );
            } else
              return (
                data?.created_by?.first_name + " " + data?.created_by?.last_name
              );
          })
        )
      );
      setuserList([...users]);
    }
  };
  const deleteSections = () => {
    deleteSection(
      selectedSection.section_id,
      selectedContractTypeId,
      setDelete
    );
  };

  useEffect(() => {
    if (!addSection) {
      setSectionData(null);
    }
  }, [addSection]);

  useEffect(() => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/last_modified";
    AxiosGateWay.get(URL)
      .then((response) => {
        setModifiedDate(response.data.result);
      })
      .catch((error) => console.log(error, "ERRROR"));
  }, []);

  const sorting = (data: any, type: any, isName: boolean) => {
    if (isName) {
      if (type === "ASC") {
        data.sort((a: any, b: any) => (a.displayName > b.displayName ? 1 : -1));
      } else {
        data.sort((a: any, b: any) => (a.displayName > b.displayName ? -1 : 1));
      }
    } else {
      if (type === "ASC") {
        data.sort((a: any, b: any) =>
          a.last_edited_on > b.last_edited_on ? 1 : -1
        );
      } else {
        data.sort((a: any, b: any) =>
          a.last_edited_on > b.last_edited_on ? -1 : 1
        );
      }
    }
    setfilteredList([...data]);
  };

  const applyFilter = (searchKey: string[], filterKey: string) => {
    setFilterKeyVals((prev) => ({
      ...prev,
      [filterKey]: [...searchKey],
    }));
  };

  const setFilter = () => {
    if (filterKeyVals["users"] && filterKeyVals["users"].length) {
      let filteredList = [...tableValue];
      Object.keys(filterKeyVals).forEach((data) => {
        const firstName = filterKeyVals[data].map((filter) =>
          filter.split(" ")[0].toLowerCase()
        );
        const LastName = filterKeyVals[data].map((filter) =>
          filter.split(" ")[0].toLowerCase()
        );
        filteredList = filteredList.filter((dd) => {
          if (dd.last_edited_by) {
            return (
              firstName.includes(dd.last_edited_by.first_name.toLowerCase()) &&
              LastName.includes(dd.last_edited_by.first_name.toLowerCase())
            );
          } else
            return (
              firstName.includes(dd.created_by.first_name.toLowerCase()) &&
              LastName.includes(dd.created_by.first_name.toLowerCase())
            );
        });
      });
      setfilteredList([...filteredList]);
    } else {
      setfilteredList([...tableValue]);
    }
  };

  const updatePageNumber = (page: number) => {
    getContractTypeData(page);
    setpageNumber(page);
  };
  const isAdmin = true;

  const concatClauseID = (element: any) => {
    element &&
      element.length &&
      element.forEach((item: any) => {
        const dd =
          addField.data?.set_recommended_value?.default_field_value?.split(" ");
        if (dd) {
          if (dd[dd?.length - 1] !== `[${item?.clause_no}]`) {
            setAddField({
              ...addField,
              data: {
                ...addField.data,
                set_recommended_value: {
                  ...addField.data?.set_recommended_value,
                  default_field_value:
                    (addField.data?.set_recommended_value?.default_field_value
                      ? addField.data?.set_recommended_value
                          ?.default_field_value
                      : "") + `, [${item?.clause_no}]`,
                },
              },
            });
          }
        } else {
          setAddField({
            ...addField,
            data: {
              ...addField.data,
              set_recommended_value: {
                ...addField.data?.set_recommended_value,
                default_field_value:
                  (addField.data?.set_recommended_value?.default_field_value
                    ? addField.data?.set_recommended_value?.default_field_value
                    : "") + ` [${item?.clause_no}]`,
              },
            },
          });
        }
      });
  };

  return (
    <div className="row m-0">
      <div
        className="col-md-1 p-0"
        style={{ zIndex: 2, flex: "0 0 6.333333%" }}
      >
        <SideNavbar history={props?.history} />
      </div>
      {isSectionView ? (
        <SectionViewAll
          loading={loading}
          updateState={setIsSectionView}
          getData={getSectionDictionary}
          type="section"
          data={sectionDictionary}
          apiCall={() => {}}
          dataType={[]}
          viewAllFielUpdate={() => {}}
        />
      ) : isFieldView ? (
        <SectionViewAll
          loading={loading}
          updateState={setIsFieldView}
          type="field"
          getData={getFieldName}
          data={sectionDictionary}
          apiCall={getDataType}
          dataType={dataType}
          viewAllFielUpdate={viewAllFielUpdate}
        />
      ) : (
        <>
          {isEditScreen && isListView == "EditView" ? (
            <div className="col-md-11 mt-6">
              {loading && <CircularProgress className="loader" />}
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-1">
                      <img
                        src="/static_images/back-arrow.svg"
                        onClick={() => onClickBackButton(props)}
                        alt="close_icon"
                        style={{ width: 15 }}
                      />
                    </div>
                    <div className="col-md-11">
                      <span style={{ fontSize: 20 }}>
                        <>{selectedRow}</>{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cls_detail_screen mt-3 row">
                <div className="cls_data_dictionary_nav_bar col-md-3">
                  <ContractTypeDetails
                    setselectedSection={setselectedSection}
                    addField={addField}
                    SectionDetails={SectionDetails}
                    loading={loading}
                    sectionDetailsData={sectionDetailsData}
                    setSectionDetailsData={setSectionDetailsData}
                    setNewSectionData={setNewSectionData}
                    setAddField={setAddField}
                    dataType={dataType}
                    selectedSection={selectedSection}
                    setAddSection={setAddSection}
                    contract_typeId={selectedContractTypeId}
                    reOrderSection={reOrderSection}
                    isSectionSort={isSectionSort}
                    tabName={tabName}
                    isAdmin={isAdmin}
                  />
                </div>
                <div className="cls_section_details col-md-9">
                  <DefaultFieldDataScreen
                    selectedSection={selectedSection}
                    addField={addField}
                    deleteSection={deleteSection}
                    contract_typeId={selectedContractTypeId}
                    getDataType={getDataType}
                    dataType={dataType}
                    getDefaultValue={getDefaultValue}
                    setDefaultResponse={setDefaultResponse}
                    defaultResponse={defaultResponse}
                    sectionDetailsData={sectionDetailsData}
                    setAddField={setAddField}
                    postFieldName={postFieldName}
                    deleteFieldName={deleteFieldName}
                    updateFieldName={updateFieldName}
                    isDelete={isDelete}
                    setDelete={setDelete}
                    setClauseDrawer={setopenClauseDrawer}
                    setSectionData={setSectionData}
                    setAddSection={setAddSection}
                    tabName={tabName}
                    isAdmin={isAdmin}
                  />

                  <ClauselibraryDrawer
                    open={openClauseDrawer}
                    onClose={() => setopenClauseDrawer(false)}
                    postClauseData={(clause) => {
                      concatClauseID(clause);
                    }}
                  />
                  <DeleteSection
                    deleteSection={deleteSections}
                    isDelete={isDelete}
                    setDelete={setDelete}
                  />
                  <AddSection
                    setNewSectionData={setNewSectionData}
                    setAddSection={setAddSection}
                    addSection={addSection}
                    edit={editSection ? true : false}
                    data={editSection}
                    viewAllSectionUpdate={viewAllSectionUpdate}
                    setSectionData={setSectionData}
                  />
                  <Snackbar
                    open={isSame}
                    message="Field Name is Already there! Please try with some other name."
                    onClick={() => {
                      setIsSame(false);
                    }}
                    action={
                      <React.Fragment>
                        <Button
                          style={{
                            color: "#88305F",
                            border: "1px solid #88305F",
                          }}
                          size="small"
                          onClick={() => {}}
                        >
                          Ok
                        </Button>
                      </React.Fragment>
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-11 mt-6">
              {loading ? (
                <div
                  style={{
                    height: "570px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress className="loader" />
                </div>
              ) : (
                DefaultListScreen({
                  ...formProps,
                  ...props,
                  modifiedDate,
                  setIsSectionView,
                  setIsFieldView,
                  sorting,
                  filteredList,
                  setfilteredList,
                  isAdmin,
                  setContractDialog,
                  getContractTypeData,
                  applyFilter,
                  userList,
                  filterKeyVals,
                  updatePageNumber,
                  pageNumber,
                  setTabName,
                  tabName,
                  getUserList,
                })
              )}
              {openContractDialog && (
                <AddContractDialog
                  getContractType={() => getContractTypeData(1)}
                  isEdit={false}
                  isClone={false}
                  contractType={null}
                  open={openContractDialog}
                  onClose={() => setContractDialog(false)}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DataDictionaryAdmin;
