import { useEffect, useState } from "react";
import { conditionMap, DataDictionary, DATA_DICTIONARY, Field_INFO } from "../../Constants/DataDictionary";
import { FieldValue } from "../State/ApprovalState";
function useConditionBuilder(selectedSection: string, selectedMergeTag1: string): FieldValue {
    const [sectionList, setSectionList] = useState<string[]>([]);
    const [mergeTagList, setMergeTagList] = useState<Field_INFO[]>([]);
    const [conditionList, setConditionList] = useState<string[]>([]);
    const [defaultValueList, setDefaultValueList] = useState<string[]>([]);
    const [selectedDataType, setselectedDataType] = useState('');


    useEffect(() => {
        extractSections();
    }, []);

    useEffect(() => {
        extractMergeTags(selectedSection);
    }, [selectedSection]);

    useEffect(() => {
        getConditions(selectedMergeTag1);
        setMergeTagDataType(selectedMergeTag1);
    }, [selectedMergeTag1, mergeTagList]);

    const extractSections = async () => {
        try {
            const sections: string[] = DataDictionary.reduce((acc: string[], curr: DATA_DICTIONARY) => {
                acc.push(curr.sectionName);
                return acc;
            }, []);
            setSectionList([...sections]);
        } catch (error) {
            console.error(error);
            setSectionList([]);
        }
    };
    const extractMergeTags = async (sectionName: string) => {
        try {
            const mergeTags = DataDictionary.reduce((acc: Field_INFO[], currVal: DATA_DICTIONARY) => {
                let tags: Field_INFO[] = currVal.sectionName == sectionName ? currVal.tags : [];
                acc = [...acc, ...tags]
                return acc;
            }, []);
            setMergeTagList([...mergeTags]);
        } catch (error) {
            console.error(error);
            setMergeTagList([]);
        }
    }

    const getConditions = async (mergeTag1: string) => {
        try {
            let MergeTag = mergeTagList.find((value) => value.mergeTag == mergeTag1);
            if (MergeTag) {
                let dataType = MergeTag.dataType;
                let condition = conditionMap.find((value) => value.mergeTagDatatype == dataType);
                if (condition) {
                    setConditionList([...condition.operators]);
                }
            }
        } catch (error) {
            console.error(error);
            setConditionList([]);
        }
    }

    const setMergeTagDataType = (mergeTagName: string) => {
        let mergeTag = mergeTagList.find((mergeTag) => mergeTag.mergeTag == mergeTagName);
        if (mergeTag) {
            setselectedDataType(mergeTag.dataType);
            if (mergeTag.defaultValue && mergeTag.defaultValue.length > 0) {
                setDefaultValueList(mergeTag.defaultValue);
            }
        }
    }

    return { sectionList, mergeTagList, conditionList, defaultValueList, selectedDataType };
};

export default useConditionBuilder;