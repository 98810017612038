import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Checkbox, CircularProgress, createTheme, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, ThemeProvider, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { getContractTypes } from '../../ContractTypes/DA';
import { ContractTypeRes } from '../../ContractTypes/State/ContractTypeState';
import Avatarfordraft from '../../Draft/Component/AvatarForDraft';
import { getUsers } from '../../Draft/DA';
import { User } from '../../Draft/State/DraftState';
import ClauselibraryDrawer from '../../UniversalComponents/ClauseLibraryDrawer/Component/ClauseLibraryDrawer';
import HeimdallUtil from '../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil';
import { editApprovals, postApprovals } from '../DA';
import { Approval, approvalTypeList, CreateApprovalPostReq, FieldValue } from '../State/ApprovalState';
import useConditionBuilder from './UseConditionBuilder';

interface Props {
    open: boolean;
    getApprovals: () => void;
    onClose: () => void;
    currentUser: any;
    count: number;
    edit: boolean;
    approvalData: Approval | null;
    getColor:(type:string,role:any,isBackGroundColor:boolean) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const enabeledApprovalTypes= ['Contract Condition Approval', 'Contract Requisition', 'Draft pre-signature Approval'];

const CreateApprovalDialog = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [selectedSection, setselectedSection] = useState('');
    const [selectedMergeTag1, setSelectedMergeTag1] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<any>('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [duration, setduration] = useState<string>('');
    const [contractTypeList, setcontractTypeList] = useState<ContractTypeRes[]>([]);
    const [contractTypes, setcontractTypes] = useState<string[]>([]);
    const [approversList, setapproversList] = useState<User[]>([]);
    const [approvers, setapprovers] = useState<string[]>([]);
    const [loading, setisLoading] = useState(false);
    const [approvalName, setapprovalName] = useState<string>('');
    const [approvalType, setapprovalType] = useState('');
    const {
        sectionList,
        mergeTagList,
        conditionList,
        defaultValueList,
        selectedDataType }: FieldValue = useConditionBuilder(selectedSection, selectedMergeTag1);
    const [isCaseSensitive,setIsCaseSensitive] = useState<boolean>(false);
    const [isIncludeAll,setisIncludeAll] = useState<boolean>(false);
    const [include, setinclude] = useState<string>('');
    const [openClauseDrawer, setopenClauseDrawer] = useState(false);
    useEffect(() => {
        getContractType();
        getApprovers();
    }, []);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])

    useEffect(() => {
        if (props.edit) {
            if (props.approvalData != null) {
                setselectedSection(props.approvalData.section_tag);
                setSelectedMergeTag1(props.approvalData.field_name);
                setSelectedCondition(props.approvalData.conditions);
                setSelectedValue(props.approvalData.value);
                setduration(props.approvalData.optional_data);
                setapprovalName(props.approvalData.approval_name);
                setapprovalType(props.approvalData.approval_type);
                setIsCaseSensitive(props.approvalData.is_case_sensitive);
                setisIncludeAll(props.approvalData.include_all);
                setinclude(props.approvalData.scope || "");
            }
        }
    }, [props.edit,props.approvalData]);

    useEffect(() => {
      if(props.edit && props.approvalData != null && (approvers.length == 0 || contractTypes.length == 0) && approversList.length > 0 && contractTypeList.length > 0){
        if (props.approvalData.approvers.length > 0) {
            let usersMap = props.approvalData.approvers.map((userData) => {
                let user = approversList.find((value) => value.id == userData.id);
                if (user != undefined) return user.first_name;
                return '';
            });
            setapprovers([...usersMap]);
        }
        if (props.approvalData.applicable_for.length > 0) {
            let contractMap = props.approvalData.applicable_for.map((contractData) => {
                let contract = contractTypeList.find((value) => value.name == contractData.name);
                if (contract != undefined) return contract.name;
                return '';
            });
            setcontractTypes([...contractMap]);
        }
      }
    }, [approvers,contractTypes,contractTypeList, approversList,props.approvalData,props.edit])
    


    const getContractType = async () => {
        try {
            const response = await getContractTypes();
            if (response && response.results.length > 0) {
                setcontractTypeList(response.results);
            } else if (response && response.results.length == 0) {
                setcontractTypeList([]);
            }
        } catch (error) {
            console.error('getContractType', error);
        }   
    };

    const getApprovers = async () => {
        try {
            const approvers = await getUsers('Approvers').then((response) => response.results);
            setapproversList(approvers);
        } catch (error) {
            console.error('getApprovers', error);
        }
    };



    const selectMergeTag1 = (event: any) => {
        setSelectedMergeTag1(event.target.value);
    }

    const handleValue = (event: any) => {
        setSelectedValue(event.target.value);
    }

    const selectCondition = (event: any) => {
        setSelectedCondition(event.target.value);
    }

    const handleDuration = (event: any) => {
        setduration(event.target.value);
    }


    const handleApprovalName = (event: any) => {
        setapprovalName(event.target.value);
    }

    const handleClose = () => {
        setOpen(false);
        props.onClose();
    };

    const handleSubmit = async () => {
        try {
            setisLoading(true);
            const postReq: CreateApprovalPostReq = {
                section_tag: !(include.toLowerCase() == 'all sections') ? selectedSection : '',
                field_name: !(include.toLowerCase() == 'all sections') ? selectedMergeTag1 : '',
                conditions: selectedCondition,
                applicable_for: getApplicableForByIds(contractTypes),
                approvers: getApproversByIds(approvers),
                optional_data: duration,
                value: selectedValue,
                approval_name: approvalName,
                approval_type: approvalType,
                approval_number: (props.edit && props.approvalData != null) ? props.approvalData.approval_number : props.count + 1,
                include_all:isIncludeAll,
                is_case_sensitive:isCaseSensitive,
                scope:include
            }
            if (props.edit && props.approvalData != null) {
                const response = await editApprovals(postReq, props.approvalData.id);
                if (response) {
                    handleClose();
                    setTimeout(() => {
                        props.getApprovals();
                    }, 500);
                }
            } else {
                const response = await postApprovals(postReq);
                if (response) {
                    handleClose();
                    setTimeout(() => {
                        props.getApprovals();
                    }, 500);
                }
            }
        } catch (error) {
            console.error('handleSubmit error', error);
        } finally {
            setisLoading(false);
        }

    }

    const getApplicableForByIds = (data: string[]) => {
        let result:any[] = [];
        if (data.length > 0) {
            result = data.map((name) => {
                const obj = contractTypeList.find((value) => value.name == name);
                if (obj) {
                    return obj.id
                } else return '';
            });
        }
        return result.filter((data) => data);
    }

    const getApproversByIds = (data: string[]) => {
        if (data.length > 0) {
            return data.map((name) => {
                const obj = approversList.find((value) => value.first_name == name);
                if (obj) {
                    return obj.id
                } else return '';
            });
        } else return [];
    }

    const selectSection = (event: any) => {
        setselectedSection(event.target.value);
    }

    const handleContractTypes = (event: any) => {
        const {
            target: { value },
        } = event;
        setcontractTypes(typeof value === 'string' ? value.split(',') : value);
    }

    const handleApprovers = (event: any) => {
        const {
            target: { value },
        } = event;
        setapprovers(typeof value === 'string' ? value.split(',') : value);
    }

    const getApprovalCount = (count: number): string => {
        if (count + 1 < 10) {
            return `#00${count}`;
        }
        if (count + 1 < 100) {
            return `#0${count}`;
        }
        return `#${count}`;
    }

    const getExpandIcon = () => (
        <img src="static_images/expand-icn.svg" alt="expand_icn" />
      );
    
    const theme = createTheme({
    palette: {
        primary: {
        main: '#88305f',
        dark: '#88305f',
        },
    },
    });
    const addClauseToValue = (clause:any[]) => {
        if(clause && clause.length){
            let string = clause.flatMap((data) => data.clause_text).filter((v) => v).join();
            setSelectedValue(string);
        }else setSelectedValue("");
    }

    const handleScope = (event:any) => {
        if(event.target.value.toLowerCase() == 'section wise'){
            setselectedSection('');
            setSelectedMergeTag1('');
            setSelectedCondition('');
        }
        setinclude(event.target.value)
    }
    return (
        <Dialog className='approval-dialog' maxWidth={'md'} fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            {loading && <CircularProgress className="loader" />}
            <DialogTitle id="form-dialog-title" className="riverus-dialog-header">
                <div className="row justify-content-between">
                    <div className="col-md-6">
                        {props.edit ? 'Editing Approval' : 'Creating Approval'}
                    </div>
                    <div onClick={handleClose} className="col-md-auto">
                    <img src="/static_images/close-analysismodal-icn.svg" alt="close_icon" />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers className='p-0'>
                <div className="row" style={{ margin: '5px 10px' }}>
                    <p className='m-0'>Approval {getApprovalCount((props.edit && props.approvalData != null) ? props.approvalData.approval_number : props.count + 1)}</p>
                </div>
                <p className='m-0 approval-subtitle'>Build a condition for approval here</p>
                <div className='mt-3' style={{ padding: '0 15px' }}>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <DialogContentText className="riverus-field-title">Approval Name</DialogContentText>
                            <TextField
                                fullWidth={true} size='small' id="outlined-basic" variant="outlined" value={approvalName} onChange={handleApprovalName} />
                        </div>
                        <div className="col-md-4">
                            <DialogContentText className="riverus-field-title">Approval Type</DialogContentText>
                            <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    fullWidth={true}
                                    onChange={(event: any) => setapprovalType(event.target.value)}
                                    value={approvalType}
                                >
                                    {approvalTypeList.map((value, index) => (
                                        <MenuItem disabled={!enabeledApprovalTypes.includes(value)} key={index} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4 align-self-end">
                            <DialogContentText className="riverus-field-title">Scope</DialogContentText>
                            <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    fullWidth={true}
                                    onChange={handleScope}
                                    value={include}
                                >
                                    <MenuItem value={'All Sections'}>All Sections</MenuItem>
                                    <MenuItem value={'Section Wise'}>Section Wise</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <Accordion className='mb-2'>
                        <AccordionSummary
                        expandIcon={getExpandIcon()}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                            <Typography>Select Conditions</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'block'} } >
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <DialogContentText className="riverus-field-title">Section Name</DialogContentText>
                                    <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            fullWidth={true}
                                            onChange={selectSection}
                                            value={selectedSection}
                                            disabled={include.toLowerCase() == 'all sections'}
                                        >
                                            {sectionList.map((value, index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))}
                                        </Select>
                                        {include.toLowerCase() == 'all sections' && <FormHelperText>Not Applicable When All Sections Selected</FormHelperText>}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <DialogContentText className="riverus-field-title">Field Name</DialogContentText>
                                    <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            fullWidth={true}
                                            value={selectedMergeTag1}
                                            onChange={selectMergeTag1}
                                            disabled={include.toLowerCase() == 'all sections'}
                                        >
                                            {mergeTagList.map((value, index) => (
                                                <MenuItem key={index} value={value.mergeTag}>{value.mergeTag}</MenuItem>
                                            ))}
                                        </Select>
                                        {include.toLowerCase() == 'all sections' && <FormHelperText>Not Applicable When All Sections Selected</FormHelperText>}
                                    </FormControl>
                                </div>
                                <div className="col-md-4">
                                    <DialogContentText className="riverus-field-title">Condition</DialogContentText>
                                    <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            fullWidth={true}
                                            value={selectedCondition}
                                            onChange={selectCondition}
                                        >
                                            {!(include.toLowerCase() == 'all sections') && conditionList.map((value, index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))}
                                            {include.toLowerCase() == 'all sections' && ['is present','is absent','contains','does not contain','is','is not'].map((value,index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="row">
                                {['text', '', 'select-default'].includes(selectedDataType) && <div className="col-md-4">
                                    <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                    <TextField
                                        fullWidth={true}
                                        disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                        size='small'
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={selectedValue}
                                        onChange={handleValue}
                                        helperText={'For multiple text,use comma'}  
                                        />
                                </div>}
                                {selectedDataType == 'duration' &&
                                        <div className="col-md-4">
                                            <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                            <TextField
                                                type='number'
                                                fullWidth={true}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                size='small'
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={selectedValue}
                                                onChange={handleValue} />
                                        </div>
                                }
                                {selectedDataType == 'duration' && <div className="col-md-4">
                                        <DialogContentText className="riverus-field-title">Duration</DialogContentText>
                                        <FormControl fullWidth={true} size='small' variant="outlined">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={duration}
                                                onChange={handleDuration}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                            >
                                                <MenuItem value={'Month(s)'}>Month(s)</MenuItem>
                                                <MenuItem value={'Year(s)'}>Year(s)</MenuItem>
                                                <MenuItem value={'Days'}>Day(s)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>}
                                {selectedDataType == 'numeric' &&
                                        <div className="col-md-4">
                                            <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                            <TextField
                                                type='number'
                                                fullWidth={true}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                size='small'
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={selectedValue}
                                                onChange={handleValue} />
                                        </div>
                                }
                                {selectedDataType == 'text-box' &&
                                        <div className="col-md-4">
                                            <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                            <TextField
                                                fullWidth={true}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                size='small'
                                                id="outlined-multiline-flexible"
                                                variant="outlined"
                                                multiline
                                                maxRows={10}
                                                value={selectedValue}
                                                onChange={handleValue}
                                            />
                                        </div>
                                }

                                {selectedDataType == 'currency' &&
                                        <div className="col-md-4">
                                            <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                            <FormControl size='small' fullWidth variant="outlined">
                                                <OutlinedInput
                                                    type='number'
                                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                    id="outlined-adornment-amount"
                                                    value={selectedValue}
                                                    onChange={handleValue}
                                                    startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                                />
                                            </FormControl>
                                        </div>
                                }

                                {selectedDataType == 'date' &&
                                        <div className="col-md-4">
                                            <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                            <TextField
                                                type='date'
                                                fullWidth={true}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                size='small'
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={selectedValue}
                                                onChange={handleValue} />
                                        </div>
                                }

                                {selectedDataType == 'percentage' &&
                                    <div className="col-md-4">
                                        <DialogContentText className="riverus-field-title">Value</DialogContentText>
                                        <FormControl size='small' fullWidth variant="outlined">
                                            <OutlinedInput
                                                type='number'
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                                id="outlined-adornment-amount"
                                                value={selectedValue}
                                                onChange={handleValue}
                                                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                }

                                {selectedDataType == 'select-default' && 
                                    <div className="col-md-4">
                                        <DialogContentText className="riverus-field-title">Select</DialogContentText>
                                        <FormControl fullWidth={true} size='small' variant="outlined">
                                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={duration}
                                                onChange={handleDuration}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent')}
                                            >
                                                {defaultValueList.map((val: string, index) => (
                                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>}
                                {['text', ''].includes(selectedDataType) &&
                                    <div className="col-md-4 align-self-center">
                                        <ThemeProvider theme={theme}>
                                            <Button onClick={() => setopenClauseDrawer(true)} variant="outlined" color='primary' className='fw' style={{ marginTop: '10px' }}>Use Existing Clause</Button>
                                        </ThemeProvider>
                                    </div>
                                }
                                {['text', ''].includes(selectedDataType) &&
                                    <div className='col-md-4 df align-items-center'>
                                        <div>
                                            {['contains', 'does not contain', 'is', 'is not'].includes(selectedCondition) &&
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox checked={isIncludeAll} onChange={(e) => setisIncludeAll(e.target.checked)} />} label="Include All" />
                                                </FormGroup>}
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={isCaseSensitive} onChange={(e) => setIsCaseSensitive(e.target.checked)} />} label="Case Sensitive" />
                                            </FormGroup>
                                        </div>
                                    </div>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                
                <div className="container m-0 mb-3">
                    <div className="row">
                        <div className="col-md-4">
                            <DialogContentText className="riverus-field-title">Applicable For</DialogContentText>
                            <FormControl size='small' variant='outlined' className="formControl" fullWidth={true}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={contractTypes}
                                    onChange={handleContractTypes}
                                    renderValue={(selected: any) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {contractTypeList.map((contract) => contract.name).map((name, index) => (
                                        <MenuItem key={index} value={name} disabled={contractTypes.indexOf('All Contract Type') > -1}>
                                            <Checkbox checked={contractTypes.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={'All Contract Type'}>
                                            <Checkbox checked={contractTypes.indexOf('All Contract Type') > -1} />
                                            <ListItemText primary={'All Contract Type'} />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-4">
                            <DialogContentText className="riverus-field-title">Add Approvers</DialogContentText>
                            <FormControl size='small' variant='outlined' className="formControl" fullWidth={true}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={approvers}
                                    onChange={handleApprovers}
                                    renderValue={(selected: any) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {approversList.map((user: any) => user.first_name).map((name, index) => (
                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={approvers.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="container m-0 mb-4">
                    <div className="row">
                        
                    </div>
                </div>
                {openClauseDrawer && <ClauselibraryDrawer
                    open={openClauseDrawer}
                    onClose={() => setopenClauseDrawer(false)}
                    postClauseData={(clause) => {addClauseToValue(clause)}}
                  />}
            </DialogContent>
            <div className='row m-1 justify-content-between'>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="col-md-7">
                                    Created by:
                                </div>
                                <div className="col-md-5">
                                    <Avatarfordraft users={[props.currentUser]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-4">
                                    Created on:
                                </div>
                                <div className="col-md-8">
                                    {new Date().toLocaleDateString()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="row">
                        <div className="col-md-6">
                            <Button className="cancel-btn" onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </div>
                        <div className="col-md-6">
                            <button onClick={handleSubmit} type="button" className="btn btn-warning create-btn save-btn">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default CreateApprovalDialog