import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Popover,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import * as React from "react";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";

type Anchor = "right";

interface Props {
  open: any;
  onClose:() => void;
  postClauseData:(clause:any) => void;
}

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

export default function ClauselibraryDrawer(props: Props) {
  const [sortType, setSortType] = React.useState<any>("ASC");
  const [isClauseNo, setClauseNo] = React.useState<any>(false);
  const [isPreview, setPreview] = React.useState<any>(false);
  const [searchPreview, setSearchPreview] = React.useState<any>("");
  const [searchClause, setSearchClause] = React.useState<any>("");
  const [mainData, setMainData] = React.useState<any>(null);
  const [checkedData, setCheckedData] = React.useState<any[]>([]);
  const [showSnack, setShowSnack] = React.useState<any>(false);
  const [open, setopen] = React.useState(false);
  const preview: any = React.useRef();
  const clauseNo: any = React.useRef();
  const [loading, setloading] = React.useState(false);
  const [clauseLibraryData, setClauseLibraryData] = React.useState<any[]>([]);

  React.useEffect(() => {
    setMainData(clauseLibraryData);
    getClauseLibraryData()
    return () => {
      setClauseLibraryData([])
    }
  }, []);

  React.useEffect(() => {
    setopen(props.open);
  }, [props.open])
  

  React.useEffect(() => {
    if(clauseLibraryData.length){
      setMainData(clauseLibraryData);
    }
  }, [clauseLibraryData])
  
  
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      handleCloseDrawer();
    };

    const handleCloseDrawer = () => {
      setopen(false);
      props.onClose();
    };

  const sorting = (data: any, type: any) => {
    let a: any = [];
    data.forEach((item: any) => {
      a = [...a, Object.entries(item)];
    });
    if (type === "ASC") {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? 1 : -1));
    } else {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? -1 : 1));
    }
    const output = a.reduce((accumulator: any, data: any) => {
      return [
        ...accumulator,
        { [data[0][0]]: data[0][1], modified_on: data[1][1] },
      ];
    }, []);

    setClauseLibraryData(output);
  };

  const getClauseLibraryData = async () => {
    try {
      setloading(true);
      const URL =
        process.env.REACT_APP_RIVERUS_CLM_API + "stylus/custom-clauselibrary/";
      const response = await axios.get(URL, APIConfig())
      if(response && response.data){
        setClauseLibraryData(response?.data?.results);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const onSearch = (searchString: any, type: any, clauseLibraryData: any) => {
    let output: any = [];
    let filterData: any = [];
    if (type === "preview") {
      setSearchPreview(searchString);
      filterData = clauseLibraryData.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return (
            element.description.toLowerCase() === searchString.toLowerCase()
          );
        });
      });
    } else if (type === "clauseNo") {
      setSearchClause(searchString);
      filterData = clauseLibraryData?.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.clause_no.toLowerCase() === searchString.toLowerCase();
        });
      });
    }

    filterData.forEach((item: any) => {
      if (item.length) {
        output = [...output, ...item];
      }
    });
    const newOutput = output.map((item: any) => {
      const customObject: any = {
        [`${item.section_name.section_name}`]: [item],
        modified_on: item.created_on,
      };
      return customObject;
    });

    if (searchString.length > 0) {
      setClauseLibraryData(newOutput);
    } else {
      setClauseLibraryData(mainData);
    }
  };

  const selectClause = (element:any) => {
    let selected = [...checkedData];
    let index = selected.findIndex((data) => data.id == element.id);
    if(index > -1){
      selected.splice(index,1);
    }else selected.push(element);
    setCheckedData([...selected]);
    props.postClauseData(selected);
  }
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 650 }}
      role="presentation"
      //   onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="mt-0">
        <div className="d-flex align-items-center field-header-2">
          <div className="col-md-1 d-flex">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft onClick={toggleDrawer(anchor, false)} />
            </IconContext.Provider>
          </div>
          <div className="col-md-11">
            <span className="fw">Clause Library</span>
          </div>
        </div>
        <div className="mt-5">
          <div className="row m-0">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-10">
                  <label className={"cursor-pointer"}>Title</label>
                  <img
                    onClick={() => {
                      sorting(clauseLibraryData, sortType);
                      if (sortType === "ASC") {
                        setSortType("DES");
                      } else {
                        setSortType("ASC");
                      }
                    }}
                    className="ml-2"
                    src="/static_images/section-title.svg"
                    alt="section-title"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4" ref={clauseNo}>
              Clause No.
              <img
                onClick={() => {
                  setClauseNo(true);
                }}
                className="ml-2"
                src="/static_images/section-search.svg"
                alt="section-created"
              />
              {isClauseNo ? (
                <PopOver
                  fieldRef={clauseNo}
                  closePop={() => setClauseNo(false)}
                  onSearch={onSearch}
                  value={searchClause}
                  type={"clauseNo"}
                  data={clauseLibraryData}
                />
              ) : null}
            </div>
            <div className="col-md-4" ref={preview}>
              Preview
              <img
                onClick={() => {
                  setPreview(true);
                }}
                className="ml-2"
                src="/static_images/section-search.svg"
                alt="section-created"
              />
              {isPreview ? (
                <PopOver
                  fieldRef={preview}
                  closePop={() => setPreview(false)}
                  onSearch={onSearch}
                  value={searchPreview}
                  type={"preview"}
                  data={clauseLibraryData}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="mt-3">
          {clauseLibraryData.map((item: any) => {
            return (
              <Accordion className="m-0">
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="section-accordian"
                >
                  <div
                    className="col-md-8"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    <div className="row w-100" style={{ flexWrap: "nowrap" }}>
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          display: "flex",
                          width: "50%",
                        }}
                      >
                        <img
                          className="clause-folder-icn"
                          src="/static_images/section-folder.svg"
                          alt="clause-folder"
                        />
                        <label
                          className={"cursor-pointer m-0"}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          {Object.keys(item)[0]}
                        </label>
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                {item[`${Object.keys(item)[0]}`].map(
                  (element: any, index: any) => {
                    return (
                      <AccordionDetails
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        <div
                          className="col-md-4"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingLeft: "0px",
                          }}
                        >
                          <input
                            type="checkbox"
                            id=""
                            name=""
                            style={{
                              accentColor: "#88305F",
                            }}
                            checked={checkedData.map((data) => data.id).includes(element.id)}
                            onClick={() => {
                              selectClause(element);
                              // const dd =
                              //   props?.fieldData?.data?.set_recommended_value?.default_field_value?.split(
                              //     " "
                              //   );
                              // if (dd) {
                              //   if (
                              //     dd[dd?.length - 1] !==
                              //     `[${element?.clause_no}]`
                              //   ) {
                              //     props?.setAddField({
                              //       ...props?.fieldData,
                              //       data: {
                              //         ...props?.fieldData?.data,
                              //         set_recommended_value: {
                              //           ...props?.fieldData?.data
                              //             ?.set_recommended_value,
                              //           default_field_value:
                              //             (props?.fieldData?.data
                              //               ?.set_recommended_value
                              //               ?.default_field_value
                              //               ? props?.fieldData?.data
                              //                   ?.set_recommended_value
                              //                   ?.default_field_value
                              //               : "") + `, [${element?.clause_no}]`,
                              //         },
                              //       },
                              //     });
                              //     setShowSnack(true);
                              //   } else {
                              //     setCheckedData([element.id]);
                              //   }
                              // } else {
                              //   props?.setAddField({
                              //     ...props?.fieldData,
                              //     data: {
                              //       ...props?.fieldData?.data,
                              //       set_recommended_value: {
                              //         ...props?.fieldData?.data
                              //           ?.set_recommended_value,
                              //         default_field_value:
                              //           (props?.fieldData?.data
                              //             ?.set_recommended_value
                              //             ?.default_field_value
                              //             ? props?.fieldData?.data
                              //                 ?.set_recommended_value
                              //                 ?.default_field_value
                              //             : "") + ` [${element?.clause_no}]`,
                              //       },
                              //     },
                              //   });
                              // }
                            }
                          }
                          />
                          <img
                            className="clause-folder-icn ml-3"
                            src={`${
                              element?.clause_type === "recommended"
                                ? "/static_images/sub-section-success.svg"
                                : "/static_images/avoidable-icon.svg"
                            }`}
                            alt="clause-folder"
                          />
                          <Tooltip
                            color="black"
                            title={element?.clause_name}
                            arrow
                          >
                            <label
                              style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                width: "100%",
                              }}
                              className="m-0 cursor-pointer"
                              onClick={() => {}}
                            >
                              {element?.clause_name}
                            </label>
                          </Tooltip>
                        </div>
                        <p className="col-md-4 m-0 section-label cursor-pointer">
                          {element?.clause_no}
                        </p>
                        <p className="col-md-4 m-0">
                          <div className="m-0 d-flex align-items-center">
                            <p className="section-label m-0">
                              {element?.description}
                            </p>
                            <Tooltip
                              color="black"
                              title={element?.clause_text}
                              arrow
                            >
                              <img
                                style={{ height: "16px", width: "16px" }}
                                src="/static_images/section-tooltip.svg"
                                alt="clause-folder"
                                className="ml-1"
                              />
                            </Tooltip>
                          </div>
                        </p>
                      </AccordionDetails>
                    );
                  }
                )}
              </Accordion>
            );
          })}
        </div>
      </div>
      <Snackbar
        open={showSnack}
        message="Clause Number Added Successfully"
        onClick={() => {
          setShowSnack(false);
        }}
        action={
          <React.Fragment>
            <Button
              style={{ color: "#88305F", border: "1px solid #88305F" }}
              size="small"
              onClick={() => {}}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      />
    </Box>
  );
  let anchor: Anchor = "right";

  return (
    <div className="position-relative">
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={open}
          onClose={toggleDrawer(anchor, false)}
        >
          {loading && <CircularProgress className="loader" />}
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
export const PopOver = (props: any) => {
  return (
    <Popover
      elevation={0}
      anchorEl={props.fieldRef?.current}
      open={true}
      onClose={() => props.closePop()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="filter">
        <FormControl className="mb-2" fullWidth size="small" variant="outlined">
          <OutlinedInput
            className="outline-inpuuu"
            placeholder={`Search ${props.type}`}
            id="outlined-adornment-password"
            value={props.value}
            onChange={(e) => {
              props.onSearch(e.target.value, props.type, props.data);
            }}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
          />
        </FormControl>
      </div>
    </Popover>
  );
};
