import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

interface Props {
  filterName: string;
  onChange: (change: any) => void;
  reset: () => void;
  filterType: string;
  dateChange: any;
}
const DateRangeFilter = (props: Props) => {
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");

  const changeMinDate = (event: any) => {
    setminDate(event.target.value);
    props.onChange({
      min: event.target.value,
      max: maxDate,
    });
  };

  const changeMaxDate = (event: any) => {
    setmaxDate(event.target.value);
    props.onChange({
      min: minDate,
      max: event.target.value,
    });
  };

  const onReset = () => {
    setminDate("");
    setmaxDate("");
    props.reset();
  };
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-7 f-14 filter-container-filter-header">
          {props.filterName}
        </div>
        <div className="col filter-reset text-align-right cp" onClick={onReset}>
          Reset
        </div>
      </div>
      <div className="row no-gutters mb-3">
        <div className="col-md-6">
          <TextField
            size="small"
            value={props.dateChange.min}
            onChange={changeMinDate}
            type={"date"}
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            style={{ width: "96%" }}
            key={props.filterType}
          />
        </div>
        <div className="col-md-6">
          <TextField
            size="small"
            value={props.dateChange.max}
            onChange={changeMaxDate}
            type={"date"}
            fullWidth={true}
            id="outlined-basic"
            variant="outlined"
            style={{ width: "96%" }}
            key={props.filterType}
          />
        </div>
      </div>
    </>
  );
};

export default DateRangeFilter;
