import React from "react";
import { History } from "history";
import UserInfo from "./userInfo";
import Notification from "../../Notification/Container/notificationCon";
import { AUTHURL } from "../../../Configuration/global";
import {
  ADMIN,
  STYLLUS,
  UMBRELLA,
} from "../../HeimdallChild/Actions/definitions";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";

interface Props {
  pageType: string;
  history: History;
  visibility: boolean;
  isLoggedIn: boolean;
  match: any;
  sessionExpired: boolean;
  refreshToken: string;
  userName: string;
  userEmail: string;
  logout: () => void;
  toggleSearchBarVisibility: (visibility: boolean) => void;
  gotoUmbrella: () => void;
  gotoStyllus: () => void;
  platform: string;
  gotoAdmin: () => void;
}

export default class Menu extends React.Component<Props> {
  componentDidUpdate() {

    if (
      window.location.pathname === "/templates" ||
      window.location.pathname === "/draftingreview" ||
      window.location.pathname === "/approvals" ||
      window.location.pathname === "/datadictionary" ||
      window.location.pathname === "/dataDictionary" ||
      window.location.pathname === "/clauselibrary"
    ) {
      this.props.gotoStyllus();
    }
    if (
      window.location.pathname === "/documentlibrary" ||
      window.location.pathname === "/uploads" ||
      window.location.pathname === "/tasks" ||
      window.location.pathname === "/filter"
    ) {
      this.props.gotoUmbrella();
    }
    if (
      window.location.pathname === "/admin/dataDictionaryAdmin" ||
      window.location.pathname === "/admin/clauseLibraryAdmin" ||
      window.location.pathname === "/admin/templatesAdmin"
    ) {
      this.props.gotoAdmin();
    }
  }
  render() {
    let {
      logout,
      userName,
      userEmail,
      toggleSearchBarVisibility,
      visibility,
      pageType,
      platform,
      gotoStyllus,
      gotoUmbrella,
      gotoAdmin,
      isLoggedIn,
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-12 web-menu-container">
          <div className="row">
            <div className="col-md-5">
              <span className="web-riverus-image">
                {/* <a
									href={AUTHURL.replace("/auth", "")}
									target="_blank"
									rel="noopener noreferrer"
								> */}
                <a href="/">
                  <img
                    alt="icon"
                    className="mt-2"
                    src="/static_images/web-menubar-logo.svg"
                  />
                </a>
              </span>
              {/* <span>
                <a href="/">
                  {platform == UMBRELLA && (
                    <img
                      alt="icon"
                      className="mt-2 ml-2"
                      src="/static_images/web-menubar-contracts-icon.svg"
                    />
                  )}
                  {platform == STYLLUS && (
                    <img
                      alt="icon"
                      className="mt-2 ml-2"
                      src="/static_images/styllus_menu_bar_icn.svg"
                    />
                  )}
                  {platform == ADMIN && (
                    <img
                      alt="icon"
                      className="mt-2 ml-2"
                      src="/static_images/admin_menu_bar_icn.svg"
                    />
                  )}
                </a>
              </span> */}
              <span
                className="df justify-content-between ml-2"
                style={{
                  display: "inline-block",
                  position: "relative",
                  top: "5px",
                  fontWeight: 600,
                }}
              >
                <span
                  className={`cp c-primary mb-2 menu-item ${
                    isLoggedIn && platform == STYLLUS ? "selected-menu" : ""
                  }`}
                  onClick={() => {
                    gotoStyllus();
                    // this.props.history.push("/templates");
                    this.props.history.push("/draftingreview");
                  }}
                >
                  Stylus
                </span>
                <span className="c-primary fw ml-1 mr-1">|</span>
                <span
                  className={`cp c-primary mb-2 menu-item ${
                    isLoggedIn && platform == UMBRELLA ? "selected-menu" : ""
                  }`}
                  onClick={() => {
                    if (
                      localStorage?.getItem("user_profile") &&
                      localStorage.getItem("user_profile") !== "undefined"
                    ) {
                      gotoUmbrella();
                      window.location.href = "/documentlibrary";
                    }
                  }}
                >
                  Umbrella
                </span>
                {getLocalStorage("user_profile")?.roles?.includes("admin") ? (
                  <>
                    <span className="c-primary fw ml-1 mr-1">|</span>
                    <span
                      className={`cp c-primary mb-2 menu-item ${
                        isLoggedIn && platform == ADMIN ? "selected-menu" : ""
                      }`}
                      onClick={() => {
                        if (
                          localStorage?.getItem("user_profile") &&
                          localStorage.getItem("user_profile") !== "undefined"
                        ) {
                          gotoAdmin();
                          window.location.href = "/admin/dataDictionaryAdmin";
                        }
                      }}
                    >
                      Admin
                    </span>
                  </>
                ) : null}
              </span>
            </div>
            <div className="col-md-7 pr-0 generic-web-menu">
              <div
                className="generic-web-menu-item"
                onClick={() => toggleSearchBarVisibility(!visibility)}
                style={{
                  borderBottom:
                    pageType === "search" ? "4px solid #996C84" : "none",
                }}
              >
                <img
                  src={
                    pageType === "search"
                      ? "/static_images/search-inline-icn.svg"
                      : "/static_images/web-menubar-search-icon.svg"
                  }
                  alt="search-icon"
                  style={{
                    filter:
                      "invert(42%) sepia(93%) saturate(1522%) hue-rotate(267deg) brightness(49%) contrast(119%)",
                  }}
                />
              </div>
              <Notification userEmail={userEmail} />
              <UserInfo logout={logout} userName={userName} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
