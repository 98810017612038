import React, { useRef, useState } from "react";
import FilterInputModal from "../../UniversalComponents/Filter/filterInputModal";
import { FilterData } from "../State/DraftState";

interface Props {
  applyFilter: (a: string[], b: string) => void;
  filterKeyVals: FilterData;
  applySorting: (key: string, valueType: string) => void;
  contractTypes: any[];
  projectList: any[];
  selectedDraft: string[];
  selectAllDraft: () => void;
  totalCount: number;
  statusList: any[];
}

const ListViewHeader = (props: Props) => {
  const contractTypeEl = useRef(null);
  const projectsEl = useRef(null);
  const [filterDetails, setFilterDetails] = useState({});
  const [filterControl, setfilterControl] = useState<any>({});
  const { applyFilter, filterKeyVals } = props;
  const [sortType, setSortType] = useState("asc");
  const [numSortType, setNumSortType] = useState("asc");
  const statusEl = useRef(null);

  const handleClick = (event: any, placeHolder: string, filterKey: string) => {
    setFilterDetails({
      placeHolder,
      filterKey,
    });
    setfilterControl({ ...filterControl, [filterKey]: true });
  };

  const handleClose = (key: string) => {
    setfilterControl({ ...filterControl, [key]: false });
  };

  return (
    <>
      <div className="col-md-12 p-0">
        <div className="row draft-list-view-header m-0 p-0">
          <div className="col-md-1" style={{ maxWidth: "2%" }}>
            {(props.selectedDraft.length == 0 ||
              props.selectedDraft.length != props.totalCount) && (
              <input
                onChange={(e) => props.selectAllDraft()}
                style={{ width: "15px", height: "15px" }}
                type="checkbox"
                name="check"
                id="check"
              />
            )}
            {props.selectedDraft.length > 0 &&
              props.selectedDraft.length == props.totalCount && (
                <img
                  onClick={() => props.selectAllDraft()}
                  className="checked-icon"
                  src="/static_images/checked_icon.svg"
                  alt="checked"
                ></img>
              )}
          </div>
          <div className="col-md-1">Doc ID</div>
          <div
            className="col-md-1"
            style={{ paddingLeft: "4%", maxWidth: "12%", flex: "0 0 12%" }}
          >
            Title
            <span>
              <img
                onClick={() => {
                  if (sortType === "asc") {
                    setSortType("desc");
                  } else {
                    setSortType("asc");
                  }
                  props.applySorting("contractName", "string");
                }}
                className="cursor-pointer"
                src={`${
                  sortType === "asc"
                    ? "/static_images/text_filter_inactive.svg"
                    : "/static_images/reverse-alpha.svg"
                }`}
              />
            </span>
          </div>
          <div className="col-md-1">Support Docs</div>
          <div className="col-md-1" style={{ maxWidth: "12%" }}>
            Version #
            <span>
              <img
                onClick={() => {
                  if (numSortType === "asc") {
                    setNumSortType("desc");
                  } else {
                    setNumSortType("asc");
                  }
                  props.applySorting("version", "number");
                }}
                src={`${
                  numSortType === "asc"
                    ? "/static_images/number_filter_inactive.svg"
                    : "/static_images/reverse-num.svg"
                }`}
                className="cursor-pointer"
              />
            </span>
          </div>
          <div className="col-md-2 df" ref={contractTypeEl}>
            Contract Type
            <span>
              <img
                src="/static_images/filter-icn.svg"
                className="cursor-pointer"
                onClick={(e) => handleClick(e, "Contract Type", "contractType")}
              />
            </span>
            {filterControl["contractType"] && (
              <FilterInputModal
                filterKey="contractType"
                filterKeyVals={filterKeyVals}
                open={filterControl["contractType"]}
                anchorEl={contractTypeEl?.current}
                handleClose={() => handleClose("contractType")}
                applyFilter={applyFilter}
                list={props.contractTypes}
                selectedItem={filterKeyVals["contractType"]}
              />
            )}
          </div>
          <div className="col-md-2" ref={projectsEl}>
            Project/Group
            <span>
              <img
                className="cursor-pointer"
                src="/static_images/filter-icn.svg"
                onClick={(e) => handleClick(e, "Project", "projectNames")}
              />
            </span>
            {filterControl["projectNames"] && (
              <FilterInputModal
                filterKey="projectNames"
                filterKeyVals={filterKeyVals}
                open={filterControl["projectNames"]}
                anchorEl={projectsEl?.current}
                handleClose={() => handleClose("projectNames")}
                applyFilter={applyFilter}
                list={props.projectList}
                selectedItem={filterKeyVals["projectNames"]}
              />
            )}
          </div>
          <div className="col-md-2" ref={statusEl}>
            Status
            <span>
              <img
                src="/static_images/filter-icn.svg"
                onClick={(e) => handleClick(e, "Status", "status")}
              />
            </span>
            {filterControl["status"] && (
              <FilterInputModal
                filterKey="status"
                filterKeyVals={filterKeyVals}
                open={filterControl["status"]}
                anchorEl={statusEl?.current}
                handleClose={() => handleClose("status")}
                applyFilter={applyFilter}
                list={props.statusList}
                selectedItem={filterKeyVals["status"]}
              />
            )}
          </div>
          <div className="col-md-1 d-flex">Assigned to</div>
        </div>
      </div>
    </>
  );
};

export default ListViewHeader;
