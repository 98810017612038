import { CheckList } from "../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";

export default interface TaskManagementPageState {
  allTasksData: AllTasksData[];
  loader: boolean;
  lastUpdate: string;
  progressNameList: ProgressNameList[];
  isProgressUpdated: number;
  taskCount: TaskCount;
  lastUpdatedOnLoader: boolean;
  taskCountLoader: boolean;
  updatedProgressQueue: ProgressUpdated[];
}

export interface ProgressNameList {
  id: string;
  name: string;
}

export interface ProgressUpdated {
  requestID: number;
  progressID: string;
  id: string;
}

export interface TaskCount {
  allTaskCount: number;
  myTaskCount: number;
}

export interface AllTasksData {
  requestID: number;
  taskTitle: string;
  description: string;
  clauseName: string;
  clauseAlias: string;
  biType: string;
  biColumnName: string;
  progressID: string;
  progressName: string;
  stateName: string;

  contractID: number;
  linkedText: string;
  contractName: string;
  associateGroup: AssociateGroup[];
  reminder: Reminder;
  checkList: CheckList[];

  //___
  id: string;
  contract: string;
  contract_file_name: string;
  contract_clause_type: string;
  contract_business_intelligence_type: string;
  link_to_contract_text: string;
  title: string;
  assignees: AssigneesList[];
  due_date: string;
  progress: string;
  status: string;
  reminder_number: number;
  reminder_period: string;
  reminder_before_or_after: string;
  reminder_repeats: string;
  reminder_until: string;
  notes: string;
  checklist: CheckList[];
}

export interface AssigneesList {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  groups: any[];
  email: string;
}

export interface AssociateGroup {
  profileID: string;
  name: string;
  hexCode: string;
  aliasName: string;
  email: string;
  hexID: number;
}

export interface Reminder {
  reminderStart: number;
  reminderStartType: string;
  reminderType: string;
  reminderUntil: string;
  frequencyType: string;
}

export function defaultTaskManagementPageState(): TaskManagementPageState {
  return {
    allTasksData: [],
    loader: false,
    lastUpdate: "",
    progressNameList: [],
    isProgressUpdated: -2,
    taskCount: {
      allTaskCount: 0,
      myTaskCount: 0,
    },
    lastUpdatedOnLoader: false,
    taskCountLoader: false,
    updatedProgressQueue: [],
  };
}
