import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Paper,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { isNullOrUndefined } from "is-what";
import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";

interface Props {
  handleClose: any;
  setcopiedText:() => void;
  copiedText: string;
  selectedClause: string;
  recommendedValue: string;
  pdftronPara: string;
  nodeId: string;
  draft_id: any;
  paraId: any;
  replaceWithCL: () => void;
  replaceWithClauseText: (clause:any) => void;
}
const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

const DeviationCLComponent: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = React.useState<any>([]);
  const [clauseData, setClauseData] = React.useState([]);
  const [clauseCount, setclauseCount] = React.useState(0);
  const [loader, setloader] = React.useState(true);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  React.useEffect(() => {
    getClauseLibraryData();
  }, []);

  const getClauseLibraryData = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + "stylus/custom-clauselibrary/";
    
    return axios
      .get(URL, APIConfig())
      .then((response) => {
        
        setOpenSnackBar(true);
        setClauseData(response.data.results);
        setloader(false);

        response.data.results?.map((val: any) => {
          let section_name = Object.keys(val)[0];
          if(section_name == props.selectedClause)
          {
            setclauseCount(val[Object.keys(val)[0]].length);
            if(val[Object.keys(val)[0]].length>0){
              const arr=[...open]
              arr.push(val[Object.keys(val)[0]][0]["id"])
              setOpen(arr);
              console.log("clause id", val[Object.keys(val)[0]][0]["id"]);
            }
          }
        })
       
      })
      .catch((error) => {setloader(false);});
  };
  const hendleOpen=(item:any)=>{
    const arr=[...open]
    arr.push(item)
    setOpen(arr)
  }
  const handleCloseTable=(item:any)=>{
    const index=open.filter((val:any)=>val!==item)
    setOpen(index)

  }

  const closeSnackbar = () => {
    setOpenSnackBar(false);
}

  const replaceClauseText = (clauses: any) => {
    let clause_para = clauses.clause_text;
    let post_params = {
      draft_id: props.draft_id,
      para_id: props.paraId,
      pdftron_para: props.pdftronPara,
      clause_para: clauses.clause_text,
      node_id: props.nodeId
    }
    console.log("post_params", post_params)
   const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/deviation/`;
    let response = axios.post(URL, post_params, APIConfig());
    
    response.then((result) => {
      if (result.status == 200) {
        props.replaceWithCL();
        props.handleClose();
        // getClauseLibraryData();
        // setOpen([]);
        // setClauseData([]);
        // setloader(true);
      }
    });
    response.catch((error) => {
      console.log(error);
    });
  }
  return (
    <>
    <Box sx={{ width: "100%" }}>
      <div className=" d-flex flex-column draft-tab" style={{ gap: "16px" }}>
        <Box className="pr-3">
          <div
            className="d-flex ml-2 align-items-center"
            style={{
              gap: "20px",
              marginTop: "10px",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            <span>
              <img
                src="/static_images/back_Arrow2.svg"
                className="pl-1 w-10"
                style={{ cursor: "pointer" }}
                onClick={() => props.handleClose()}
              />
            </span>
            <span>Select Clause to Replace with</span>
          </div>
        </Box>
        <Card>
          <CardContent>
            <Typography className="d-flex flex-column" style={{ gap: "16px" }}>
              {props.recommendedValue==""?null:<>
              <div className="custom-box-new">
                <div className=" row col-12 p-0">
                  <div className="col-6 font-weight-bold">
                    <img
                      src="/static_images/stariconblack.svg"
                      className="pl-1 w-10 mr-1"
                      style={{ cursor: "pointer" }}
                    />
                    Riverus Suggestion
                  </div>
                </div>
                <div
                  className="text-container mr-1"
                  style={{ backgroundColor: "#F1F7FF" }}
                >
                  <p className="p-2">
                    {props.recommendedValue}
                    {/* In case of renewal of a high value contract and you have to
                    make payment, set the auto renewal clause to Opt In Mode.
                    Look at CL-02 for guidance. */}
                  </p>
                </div>
              </div>
              </>}
              {clauseData.length>0?<div className=" row col-12 p-0">
                <div className="col-10">
                  <span>Showing {clauseCount} clauses relevant to</span>
                  <span style={{ color: "#996C84" }}>
                    &lt;{props.selectedClause}&gt;
                  </span>
                </div>
              </div>:null}
              
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox disabled />}
                    label="Show Avoidable Clauses"
                    style={{ color: "#4C4C4C", fontSize: "14px" }}
                  />
                </FormGroup>
              </div>
              <div
                className="container-table"
                style={{ fontSize: "14px", fontWeight: 400 }}
              >
                <div
                  className="row upload-header-container mt-3 pl-0 ml-1 mr-1 align-items-center"
                  style={{
                    borderBottom: "1px solid grey",
                    minHeight: "46px",
                    color: "#1D1D1D",
                  }}
                >
                  <div className="col-md-4">
                    Folder Name
                    <img
                      src="/static_images/section-search.svg"
                      className="pl-1 w-10 mr-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-4">
                    Clause No.
                    <img
                      src="/static_images/section-search.svg"
                      className="pl-1 w-10 mr-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="col-md-4">
                    Preview
                    <img
                      src="/static_images/section-search.svg"
                      className="pl-1 w-10 mr-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                {loader?<CircularProgress className="loader" />:null}
                <Scrollable maxHeight={window.screen.height} padding={true}>
                {clauseData?.map((val: any) => {
                  return (
                    <div className="mr-1 ml-1">
                      <Accordion>
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            minHeight: "46px",
                            borderBottom: "1px solid grey",
                            color: "#6D6D6D",
                          }}
                        >
                          <Typography>
                            <img
                              src="/static_images/section-folder.svg"
                              className="pl-1 w-10 mr-1"
                              style={{ cursor: "pointer" }}
                            />
                            {Object.keys(val)[0]}
                          </Typography>
                        </AccordionSummary>
                        
                        {val[Object.keys(val)[0]]?.map((item: any) => {
                              return (
                        <AccordionDetails
                          className="w-100 p-0 m-0 justify-content-center align-items-center"
                          style={
                            open.includes(item.id)
                              ? {
                                  minHeight: "46px",
                                  borderBottom: "1px solid grey",
                                  backgroundColor: "#F0E1E9",
                                }
                              : {
                                  minHeight: "46px",
                                  borderBottom: "1px solid grey",
                                }
                          }
                        >
                          <Typography
                            className="w-100 p-0 m-0"
                            style={{
                              borderLeft: "none",
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#6D6D6D",
                            }}
                          >
                            {/* {val[Object.keys(val)[0]]?.map((item: any) => {
                              return ( */}
                                <>
                                  {open.includes(item.id) ? (
                                    <div
                                      className="d-flex flex-column"
                                      style={{ gap: "16px" }}
                                    >
                                      <div
                                        className="row col-12 ml-1 mr-1 align-items-center"
                                        style={{ minHeight: "42px" }}
                                        onClick={() => {
                                          handleCloseTable(item.id);
                                          props.setcopiedText();
                                        }}
                                      >
                                        <div
                                          className="col-md-4"
                                          style={{ color: "#88305F" }}
                                        >
                                          <img
                                            src="/static_images/sub-section-success.svg"
                                            className="pl-1 w-10 mr-1"
                                            style={{ cursor: "pointer" }}
                                          />
                                          <img
                                            src="/static_images/settingicon.svg"
                                            className="pl-1 w-10 mr-1"
                                            style={{ cursor: "pointer" }}
                                          />
                                          {item.clause_name}
                                        </div>
                                        <div className="col-md-4">
                                          {item.clause_no}
                                        </div>
                                      </div>
                                      <div
                                        className="ml-5 mr-5"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {item.clause_text}
                                      </div>
                                      <div className="row mb-1 d-flex justify-content-end">
                                        <div className="mr-5">
                                          <button
                                            style={{
                                              cursor: "pointer",
                                              fontWeight: 500,
                                              color: "#626262",
                                            }}
                                            disabled={(props.pdftronPara)==""?true:false}
                                            className="addtag-addHierarchy-addbtn"
                                            onClick={()=>{
                                              // replaceClauseText(item);
                                              props.replaceWithClauseText(item)
                                            }}
                                          >
                                            <img
                                              src="/static_images/replacebtnicon.svg"
                                              className="pl-1 w-10 mr-1"
                                              style={{ cursor: "pointer" }}
                                            />
                                            <span className="addtag-addHierarchy-addbtn-title">
                                              {(props.copiedText)==""?'Replace': 'Replace'}
                                              {/* Replace */}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row col-12 ml-1 mr-1">
                                      <div
                                        className="col-md-4"
                                        style={{ color: "#88305F" }}
                                      >
                                        <img
                                          src="/static_images/sub-section-success.svg"
                                          className="pl-1 w-10 mr-1"
                                          style={{ cursor: "pointer" }}
                                        />
                                        <img
                                          src="/static_images/settingicon.svg"
                                          className="pl-1 w-10 mr-1"
                                          style={{ cursor: "pointer" }}
                                        />
                                        {item.clause_name}
                                      </div>
                                      <div className="col-md-4">
                                        {item.clause_no}
                                      </div>
                                      <div className="col-md-4">
                                        {item.clause_text}
                                        <img
                                          src="/static_images/section-tooltip.svg"
                                          className="pl-1 w-10 ml-1"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => hendleOpen(item?.id)}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                          </Typography>
                        </AccordionDetails>
                              );
                    })}
                      </Accordion>
                    </div>
                  );
                })}
                </Scrollable>
              </div>
                
            </Typography>
          </CardContent>
        </Card>
      </div>
    </Box>
    {/* <Snackbar
                open={openSnackBar}
                autoHideDuration={300}
                message="Clause Text Updated Successfully"
                onClose={closeSnackbar}
            /> */}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviationCLComponent);
