import React, { Component } from "react";
import BarLoader from "../../../UniversalComponents/Loader/barLoader";
import TaskManagementTablemodal from "../../../UniversalComponents/Modals/TaskManagementTableModal/taskManagementTableModal";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import {
  AllTasksData,
  AssigneesList,
  AssociateGroup,
  ProgressNameList,
  ProgressUpdated,
} from "../../State/taskManagementPageState";
import ListViewHeader from "./listViewHeader";
import ListViewTable from "./listViewTable";
import {History} from 'history';

interface Props {
  allTasksData: AllTasksData[];
  history:History;
  setSortAndOrder: (sort: string, order: string) => void;
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  progressNameList: ProgressNameList[];
  updateProgress: (
    requestID: number,
    progressID: string,
    id: string,
    progressName: string
  ) => void;
  loader: boolean;
  isProgressUpdated: number;
  updatedProgressQueue: ProgressUpdated[];
  resetUpdatedProgressQueue: (progressUpdated: ProgressUpdated[]) => void;
}

interface State {
  title: string;
  numberOfMembers: number;
  // associateGroup: AssociateGroup[];
  assignees: AssigneesList[];
}

export default class Listview extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      title: "",
      numberOfMembers: 0,
      // associateGroup: [],
      assignees: [],
    };
  }

  componentDidMount() { }

  render() {
    let {
      setSortAndOrder,
      getAllTasksData,
      progressNameList,
      updateProgress,
      loader,
      isProgressUpdated,
      updatedProgressQueue,
      resetUpdatedProgressQueue,
      history
    } = this.props;
    // let { title, numberOfMembers, associateGroup } = this.state;
    let { title, numberOfMembers, assignees } = this.state;
    return (
      <>
        <div className="col-md-12 p-0" style={{}}>
          <div
            className="row"
            style={{
              borderBottom: "1px solid #E1E1E1",
              marginLeft: "0rem",
              marginRight: "0rem",
              backgroundColor: "white",
            }}
          >
            <ListViewHeader
              setSortAndOrder={setSortAndOrder}
              getAllTasksData={getAllTasksData}
            />
          </div>
          <Scrollable maxHeight={420}>
            <div className="row mr-2" style={{ marginBottom: "200px" }}>
              {loader === true ? (
                <BarLoader />
              ) : (
                <ListViewTable
                  history={history}
                  allTasksData={this.props.allTasksData}
                  setModal={(
                    title: string,
                    numberOfMembers: number,
                    assignees: AssigneesList[]
                  ) =>
                    this.setState({
                      title: title,
                      numberOfMembers: numberOfMembers,
                      assignees: assignees,
                    })
                  }
                  progressNameList={progressNameList}
                  updateProgress={updateProgress}
                  isProgressUpdated={isProgressUpdated}
                  updatedProgressQueue={updatedProgressQueue}
                  resetUpdatedProgressQueue={resetUpdatedProgressQueue}
                />
              )}
            </div>
          </Scrollable>
          <TaskManagementTablemodal
            title={title}
            numberOfMembers={numberOfMembers}
            assignees={assignees}
          />
          <button
            style={{ display: "none" }}
            type="button"
            data-toggle="modal"
            data-target="#taskManagementTableModal"
            id="taskManagementTableButton"
          ></button>
        </div>
      </>
    );
  }
}
