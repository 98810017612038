import { CircularProgress, Drawer, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { AiOutlineReload } from "react-icons/ai";
import { BsArrowLeft, BsDownload, BsTrash } from "react-icons/bs";
import { MdSelectAll } from "react-icons/md";
import { getSupportDocs, uploadsupportdocnew } from "../DA";
import { Sort } from "../State/DraftState";
import DeleteDraftDialog from "./DeleteDraftDialog";
import UploadFileDialog from "./UploadFileDialog";
import Uploadsupporttypeselect from "./Uploadsupporttypeselect";
interface Props {
  open: boolean;
  onClose: () => void;
  reqid: string;
  allsupportdoc: any;
  // setsupportmodel:any;
}
const SupportDocDialog = (props: Props) => {

  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedDoc, setselectedDoc] = useState<any[]>([]);
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  const [supportDocs, setsupportDocs] = useState<any[]>([]);
  const [openmodel, setopenmodel] = useState(false);
  const [selectedfiles, setselectedfiles] = useState<Array<string>>([]);
  const [childrenmodal, setchildrenmodal] = useState(false);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  const[SelectAll,setselectall]=useState([])
  const [selectedDocData, setselectedDocData] = useState<any[]>([]);

  const handleClose = () => {
    setopen(false);
    props.onClose();
  };
  useEffect(() => {
    if (props.reqid) {
      getSupportDoc();
    }
    setopen(props.open);
  }, [props.reqid, props.open]);

  useEffect(() => {
    setSorting(supportDocs);
  }, [sortData]);

  const setSorting = (contracts: any[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...contracts];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "number") {
          if (sortData.sortType == "asc") {
            return a[sortData.sortKey] - b[sortData.sortKey];
          }
          if (sortData.sortType == "desc") {
            return b[sortData.sortKey] - a[sortData.sortKey];
          }
        }
        if (sortData.valueType == "string" && sortData.sortType == "asc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return +1;
          } else return -1;
        }
        if (sortData.valueType == "string" && sortData.sortType == "desc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return -1;
          } else return +1;
        }
        if (sortData.valueType == "date") {
          if (sortData.sortType == "asc") {
            return (
              new Date(a[sortData.sortKey]).getTime() -
              new Date(b[sortData.sortKey]).getTime()
            );
          }
          if (sortData.sortType == "desc") {
            return (
              new Date(b[sortData.sortKey]).getTime() -
              new Date(a[sortData.sortKey]).getTime()
            );
          }
        }
        return sorted;
      });
      setsupportDocs([...filteredList]);
    }
  };

  const getSupportDoc = async () => {
    try {
      setloading(true);
      const response = await getSupportDocs(props.reqid);
      if (response && response.results) {
        setsupportDocs(response.results);
        setselectedDoc([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const openSupportDocDialog = () => {
    setopenmodel(true);
  };

  const handleOpenDeleteDialog = () => {
    setopenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setopenDeleteDialog(false);
    // setselectedDrafts([]);
  };
  const handleCloseSupportDialog = () => {
    setopenmodel(false);
    setchildrenmodal(true);
  };

  const download = () => {};

  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
  };

  const selectMultipleDrafts = (id: string) => {
    let selected = [...selectedDoc];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
      setselectall([])
    } else {
      selected.push(id);
    }
    const seletedData=supportDocs.filter((val:any)=>selected.includes(val.id))
    setselectedDocData(seletedData)
    setselectedDoc([...selected]);
  };
//   const handlemultipleselect = (key:string) => {
    
//     if(key==="check"){
//     let selected = [...selectedDoc];
//     const ids = supportDocs?.map((i: any) => i.id);
//     selected = ids;
//     setselectedDoc([...selected]);
//     }else{
//         setselectedDoc([])
//     }
//   };

  const uploadSupportDocument = async (preSignedUrl: any) => {
    let a: any = [];
    try {
      setloading(true);
      let payload = {
        file_id: preSignedUrl?.id,
        file_name: preSignedUrl?.file_name,
        upload_type: "support_doc",
        document_type: null,
        link:preSignedUrl?.file_path,
        ref_req: [props.reqid],
      };
      const response = await uploadsupportdocnew(payload);
      if (response) {
        console.log(response, "responsedataaaaa");
        a = [...a, response];
        setselectedfiles(a);
        // setTimeout(() => {
        //   getVersions();
        // }, 500);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };
  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <div className="container support-doc">
        {loading && <CircularProgress className="loader" />}
        <div className="row align-items-center">
          <div className="col-md-4 df align-items-center">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft onClick={handleClose} />
            </IconContext.Provider>
            <p className="m-0 ml-3 fw" style={{ fontSize: "16px" }}>
              Support Docs
            </p>
          </div>
          <div className="col-md-8 text-align-end">
            <button
              type="button"
              onClick={openSupportDocDialog}
              className="btn btn-warning create-btn"
            >
              Upload New
            </button>
          </div>
        </div>
        <div
          className="row justify-content-between mt-3"
          style={{ height: "33px" }}
        >
          <div className="col-md-8">
            <div
              className="row mt-1 mb-1 df align-items-center"
              style={{ marginLeft: "0" }}
            >
              <IconContext.Provider
                value={{ color: "#88305F", className: "reload-icon" }}
              >
                <AiOutlineReload onClick={()=>getSupportDoc()} className="ml-2" />
              </IconContext.Provider>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {selectedDoc.length == 2 && (
                <img
                  className="cp"
                  src="/static_images/compare-icon.svg"
                  alt="compare"
                />
              )}
              {selectedDoc.length == 1 && (
                <IconContext.Provider
                  value={{
                    color: "#88305F",
                    className: "svg-delete-icon cp",
                    style: { strokeWidth: "0.5" },
                  }}
                >
                  <BsDownload onClick={download} />
                </IconContext.Provider>
              )}
              {selectedDoc.length === 1 && (
                <IconContext.Provider
                  value={{ color: "#FF685F", className: "svg-delete-icon cp" }}
                >
                  <BsTrash onClick={handleOpenDeleteDialog} />
                </IconContext.Provider>
              )}
            </div>
          </div>
        </div>
        <div className="row list-view-header support-doc__table-header">
          <div
            className="col-md-1 df align-items-center"
            style={{ maxWidth: "6%" }}
          >
            {/* {selectedDoc.length == 0 && ( */}
              <input
                // onChange={()=>handlemultipleselect("check")}
                style={{ width: "15px", height: "15px" }}
                type="checkbox"
                name="check"
                id="check"
              />
            {/* )} */}
            {/* { (selectedDoc.length >0 )&& (
              <img
                // onClick={()=>handlemultipleselect("uncheck")}
                className="checked-icon"
                src="/static_images/checked_icon.svg"
                alt="checked"
              ></img>
            )} */}
          </div>
          <div className="col-md-4">
            Name
            <span>
              <img
                onClick={() => applySort("file_name", "string")}
                src="static_images/number_filter_inactive.svg"
              />
            </span>
          </div>
          <div className="col-md-4">
            Type
            <span className="ml-2">
              <img
                onClick={() => applySort("document_type", "string")}
                src="static_images/calender_filter_inactive.svg"
              />
            </span>
          </div>
          <div className="col-md-3">
            Uploaded On{" "}
            <span>
              <img
                onClick={() => applySort("created_on", "date")}
                src="static_images/calender_filter_inactive.svg"
              />
            </span>
          </div>
        </div>
        {supportDocs.length > 0 &&
          supportDocs.map((doc: any, index) => (
            <div
              key={index}
              className={`row version-history__table-list ${
                selectedDoc.indexOf(doc.id) > -1 ? "selected-draft" : ""
              }`}
              style={{ backgroundColor: index % 2 != 0 ? "white" : "#FAFAFA" }}
            >
              <div
                className="col-md-1 df align-items-center"
                style={{ maxWidth: "6%", padding: "0 10px" }}
              >
                {selectedDoc.indexOf(doc.id) == -1 && (
                  <input
                    style={{ width: "15px", height: "15px" }}
                    onClick={() => selectMultipleDrafts(doc.id)}
                    type="checkbox"
                    name="check"
                    id="check"
                  />
                )}
                {selectedDoc.indexOf(doc.id) > -1 && (
                  <img
                    className="checked-icon"
                    style={{ top: "0" }}
                    onClick={() => selectMultipleDrafts(doc.id)}
                    src="/static_images/checked_icon.svg"
                    alt="checked"
                  ></img>
                )}
              </div>
              <div
                className="col-md-4 c-grey"
                style={{ paddingLeft: "4%", maxWidth: "31%" }}
              >
                <Tooltip title={doc.file_name} arrow>
                  <div className="text-overflow ">{doc.file_name}</div>
                </Tooltip>
              </div>
              <div className="col-md-4 c-grey">{doc.document_type?doc.document_type:null}</div>
              <div className="col-md-3">
                {new Date(doc.created_on).toLocaleDateString()}
              </div>
            </div>
          ))}
      </div>
      {openmodel && (
        <UploadFileDialog
          title="Upload Support Doc"
          fileType="support_doc"
          uploadedFileResponse={uploadSupportDocument}
          open={openmodel}
          onClose={handleCloseSupportDialog}
          contractData={[]}
          show={false}
          contractType={[]}
          isInternal={false}
          handleisinternal={() => {}}
          setisinternal={() => {}}
        />
      )}
      <Uploadsupporttypeselect
        supporttitle={"Upload Supporting Documents"}
        selectedfile={selectedfiles}
        openchildren={childrenmodal}
        handleclose={() => {
          setchildrenmodal(false);
          props.onClose();
          // props.documentLibraryApi({archive: false})
        }}
      />
      {openDeleteDialog && (
        <DeleteDraftDialog
          open={openDeleteDialog}
          getDrafts={getSupportDoc}
          onClose={handleCloseDeleteDialog}
          selectedDraft={selectedDocData}
          for={"supportdialogue"}
        />
      )}
    </Drawer>
  );
};

export default SupportDocDialog;
