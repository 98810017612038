import AxiosGateWay from "../../../HeimdallChild/HeimdallUtil/axiosUtils";

export default class ClausErrorManagementAPI {
  public static getClauseErrorData(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        return err.response;
      });
  }
}
