export interface TemplateData {
  name?: string;
  templateType?: string;
  owner?: any;
  createdOn?: string;
  lastUsed?: string;
  templateLink?: string;
  fileId?: string;
  filePath?: string;
  id?: string;
  template_id?: string;
  fileType?: string;
  tags?: any[];
  creation_type?: string;
  contract_type?:any[]|null;
}

export interface TemplateState {
  templates: TemplateData[];
  isLoading: boolean;
  error: string;
}

export const initialTemplateData: TemplateData = {
  name: "",
  templateType: "",
  owner: [""],
  createdOn: "",
  lastUsed: "",
  templateLink: "",
  fileId: "",
  filePath: "",
  creation_type: "",
  contract_type:null
};

export interface Sort {
  sortKey: string;
  sortType: string;
  valueType: string;
}

export type FilterData = {
  [name: string]: string[];
};

export interface Tag {
  id: string;
  tag_name: string;
}
