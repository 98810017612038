import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  Box,
  Paper,
  TableBody,
  Tabs,
  Tab,
} from "@material-ui/core";
import "./styles.css";
import { History } from "history";
import DataDictionaryHeader from "./DataDictionaryHeader";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
import HeimdallUtil from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import AddContractDialog from "../../../ContractTypes/Component/AddContractDialog";
import DeleteContractTypeDialog from "../../../ContractTypes/Component/DeleteContractTypeDialog";
import { FilterData } from "../DataDictionary";
import PageNavigation from "../../../UniversalComponents/PageNavigation/Component/PageNavigation";
import { isRiverusAdmin } from "../../DDUtils";
import { useStateManage } from "../useStateManage";
import Avatarfordraft from "../../../Draft/Component/AvatarForDraft";

interface Props {
  history: History;
  TableHeader: any;
  tableValue: any;
  setSelectedRow: any;
  selectedRow: any;
  isEditScreen: boolean;
  setIsEditScreen: any;
  setSectionDetailsData: any;
  SectionDetails: any;
  selectedContractTypeId: string;
  setSelectedContractTypeId: any;
  isListView: string;
  getContractTypeBasedSectionDetails: any;
  setIsListView: any;
  loading: boolean;
  setloading: any;
  sorting: any;
  filteredList: any;
  setfilteredList: any;
  getContractTypeData?: any | null;
  isAdmin: boolean;
  applyFilter: (a: string[], b: string) => void;
  filterKeyVals: FilterData;
  userList: string[];
  setTabName: any;
  tabName: any;
  getUserList: any;
}

export const EnhancedDataDictionaryTable = (props: Props) => {
  const {
    getCustomContractTypeData,
    getDefaultContractTypeData,
    customContractType,
    defaultContractType,
    totalDefaultCount,
    totalCustomCount,
    defaultPageWiseData,
    customPageWiseData,
    setDefaultContractType,
    setCustomContractType,
  } = useStateManage();

  const [tabValue, setTabValue] = useState(0);
  const [defaultData, setDefaultData] = useState<any>([]);
  const [customData, setCustomData] = useState<any>([]);
  const [defaultPageNumber, setDefaultPageNumber] = useState(1);
  const [customPageNumber, setCustomPageNumber] = useState(1);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setDefaultData(defaultContractType);
    } else {
      setCustomData(customContractType);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getDefaultContractTypeData(defaultPageNumber);
    getCustomContractTypeData(customPageNumber);
  };

  useEffect(() => {
    setDefaultData(defaultContractType);
    setCustomData(customContractType);
    if (tabValue === 0) {
      props.getUserList(defaultContractType);
    } else {
      props.getUserList(customContractType);
    }
  }, [defaultContractType, customContractType]);

  const updatePageNumber = (page: number) => {
    if (tabValue === 0) {
      if (defaultPageWiseData[page]) {
        setDefaultContractType(defaultPageWiseData[page]);
      } else {
        getDefaultContractTypeData(page);
      }
      setDefaultPageNumber(page);
    } else {
      if (customPageWiseData[page]) {
        setCustomContractType(customPageWiseData[page]);
      } else {
        getCustomContractTypeData(page);
      }
      setCustomPageNumber(page);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{
          background: "white",
          boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          borderRadius: "4px",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab
            label="Default"
            {...a11yProps(0)}
            onClick={() => {
              getDefaultContractTypeData(defaultPageNumber);
              props.setTabName("default");
              props.getUserList(defaultData);
            }}
          />
          {isRiverusAdmin() ? null : (
            <Tab
              label="Custom"
              {...a11yProps(1)}
              onClick={() => {
                getCustomContractTypeData(customPageNumber);
                props.setTabName("custom");
                props.getUserList(customData);
              }}
            />
          )}
        </Tabs>
      </Box>
      <Paper style={{ width: "100%", marginBottom: 2 }}>
        <TableContainer>
          <Table style={{ minWidth: 750 }}>
            <DataDictionaryHeader
              data={tabValue === 0 ? defaultData : customData}
              sorting={props.sorting}
              setTableData={tabValue === 0 ? setDefaultData : setCustomData}
              isAdmin={props.isAdmin}
              applyFilter={props.applyFilter}
              filterKeyVals={props.filterKeyVals}
              userList={props.userList}
              filteredList={props.filteredList}
              getData={getData}
            />
            {tabValue === 0
              ? EnhancedDataDictionaryTableBody(
                  props,
                  props.filteredList.length > 0
                    ? props.filteredList
                    : defaultData,
                  updatePageNumber,
                  defaultPageNumber,
                  totalDefaultCount
                )
              : EnhancedDataDictionaryTableBody(
                  props,
                  props.filteredList.length > 0
                    ? props.filteredList
                    : customData,
                  updatePageNumber,
                  customPageNumber,
                  totalCustomCount
                )}
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export const EnhancedDataDictionaryTableBody = (
  props: Props,
  data: any,
  updatePageNumber: any,
  pageNumber: number,
  totalCount: number
) => {
  const {
    setSelectedRow,
    filteredList: modifiedTableData,
    SectionDetails,
    setSelectedContractTypeId,
    setIsEditScreen,
    history,
  } = props;
  const onContractTypeClick = (
    displayName: any,
    row: any,
    rowId: any,
    SectionDetails: any,
    props: any
  ) => {
    const { setIsListView } = props;
    setSelectedRow(row);
    setSelectedContractTypeId(rowId);
    setIsEditScreen(true);
    setIsListView("EditView");
  };

  const [isClone, setClone] = useState(false);

  const [isEdit, setEdit] = useState(false);
  const [contractData, setContractData] = useState<any>(null);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);

  const editContractType = (contractData: any) => {
    setContractData({ ...contractData });
    setEdit(true);
  };
  const handleOpenDeleteDialog = (contractData: any) => {
    setContractData({ ...contractData });
    setopenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setopenDeleteDialog(false);
  };

  useEffect(() => {}, [isClone]);

  return (
    <>
      <TableBody style={{ width: "100%", height: "20px" }}>
        {data?.map((row: any, index: any) => {
          return (
            <>
              <div className="row m-0 p-2" key={index}>
                <div className="col-md-2">
                  <div
                    onClick={() =>
                      onContractTypeClick(
                        row?.displayName,
                        row?.name,
                        row?.id,
                        SectionDetails,
                        props
                      )
                    }
                    className="position-relative cursor-pointer"
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 700,
                      color: "#88305F",
                      fontSize: "14px",
                      lineHeight: "22px",
                    }}
                  >
                    {row?.name}
                  </div>
                </div>
                <div className="col-md-2 position-relative">
                  {row?.displayName}
                </div>
                <div className={`col-md-2 position-relative pl-5`}>
                  {row?.last_edited_by ? (
                    <Avatarfordraft users={[row?.last_edited_by]} />
                  ) : row?.created_by ? (
                    <Avatarfordraft users={[row?.created_by]} />
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  className={`${
                    props.isAdmin ? "col-md-2" : "col-md-3"
                  } position-relative`}
                >
                  {row?.last_edited_on ? getDate(row?.last_edited_on) : "-"}
                </div>
                <div
                  className={`${
                    props.isAdmin ? "col-md-2" : "col-md-3"
                  } position-relative c-primary pl-0`}
                  style={{ fontWeight: 600 }}
                >
                  {`${row?.used_in_templates} Templates / ${row?.used_in_drafts} Drafts`}
                </div>
                {props.isAdmin && (
                  <>
                    {props.tabName === "default" ? (
                      isRiverusAdmin() ? (
                        <div className="col-md-2">
                          <div className="row text-purple">
                            <div
                              onClick={() => editContractType(row)}
                              className="col-md-3"
                            >
                              <img src="/static_images/edit_icn.svg"></img>
                            </div>
                            <div
                              onClick={() => handleOpenDeleteDialog(row)}
                              className="col-md-9"
                            >
                              <img
                                height={19}
                                src="/static_images/delete-icon.svg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-2">
                          <div className="row text-purple">
                            <div
                              onClick={() => {
                                setContractData({
                                  ...row,
                                  displayName: "",
                                  name: `Cloned-${row.name}`,
                                });
                                setClone(true);
                              }}
                              className="col-md-9"
                            >
                              <img
                                height={19}
                                className="w-50 cursor-pointer"
                                src="/static_images/clause-copy-icn.svg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <>
                        <div className="col-md-2">
                          <div className="d-flex text-purple">
                            <div onClick={() => editContractType(row)}>
                              <img
                                src="/static_images/edit_icn.svg"
                                className="mr-2 ml-2 cursor-pointer"
                              ></img>
                            </div>
                            <div
                              onClick={() => handleOpenDeleteDialog(row)}
                              className="ml-2 cursor-pointer"
                            >
                              <img
                                height={19}
                                src="/static_images/delete-icon.svg"
                              ></img>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          );
        })}
      </TableBody>

      <AddContractDialog
        getContractType={() => props.getContractTypeData(pageNumber)}
        isEdit={true}
        contractType={contractData}
        open={isEdit || isClone}
        isClone={isClone}
        onClose={() => {
          if (isEdit) {
            setEdit(false);
          } else {
            setClone(false);
          }
          setContractData(null);
        }}
      />
      <DeleteContractTypeDialog
        id={contractData?.id}
        onClose={handleDeleteDialogClose}
        name={contractData?.name}
        getContractType={() => props.getContractTypeData(pageNumber)}
        open={openDeleteDialog}
      />
      <PageNavigation
        pageSize={10}
        pageNumber={pageNumber}
        updatePageNumber={updatePageNumber}
        totalCount={totalCount}
      />
    </>
  );
};
