import React, { Component } from "react";
import { History } from "history";
import { capitalizeFirstLetter } from "../../../Utils/DataModifierUtil/dataModUtil";
import {
  ADMIN,
  STYLLUS,
  UMBRELLA,
} from "../../HeimdallChild/Actions/definitions";
import { Tooltip } from "@material-ui/core";

interface Props {
  pageType: string;
  history: History;
  platform: string;
  gotoStyllus: () => void;
  gotoAdmin: () => void;
}

interface IconState {
  icon: string;
  name: string;
  text: string;
}

interface State {
  iconsList: IconState[];
  selectedTab: string;
  styllusIconsList: IconState[];
  adminIconsList: any;
}

export class SideNavbar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      iconsList: [
        /*{
                    icon: '/static_images/dashboard-icn.svg',
                    name: 'dashboard',
                    text: 'Monitor your contracts here'
                },*/

        {
          icon: "/static_images/document-library-icn.svg",
          name: "documentlibrary",
          text: "Access Document Library here",
        },
        {
          icon: "/static_images/uploads-icn.svg",
          name: "uploads",
          text: "Access your processed files here",
        },
        {
          icon: "/static_images/tasks-icn.svg",
          name: "tasks",
          text: "Manage your tasks here",
        },

        /* {
          icon: "/static_images/clause-library-icn.svg",
          name: "clauselibrary",
          text: "Access Clause Library here",
        },*/
        // {
        //   icon: "/static_images/addfiles-icn.svg",
        //   name: "addfiles",
        //   text: "Upload new files here",
        // },
        // {
        //   icon: "/static_images/uploads-icn.svg",
        //   name: "uploads",
        //   text: "Access your processed files here",
        // },
        /*  {
                     icon: '/static_images/analysis-icn.svg',
                     name: 'analysis',
                     text: 'Get macro insights using filters here'
                 }, */
        // {
        //   icon: "/static_images/analysis-icn.svg",
        //   name: "analysis",
        //   text: "Get macro insights using filters here",
        // },
        /*,
                {
                    icon: '/static_images/reports-icn.svg',
                    name: 'reports',
                    text: 'View your reports here'
                }*/
        {
          icon: "/static_images/filter-icn.svg",
          name: "filter",
          text: "Filter",
        },
      ],
      selectedTab: props.pageType,
      styllusIconsList: [
        {
          icon: "/static_images/draft-icn.svg",
          name: "draftingreview",
          text: "Manage your drafts here",
        },
        {
          icon: "/static_images/clause-library-new.svg",
          name: "clauselibrary",
          text: "Access Clause Library here",
        },
        {
          icon: "/static_images/template_icn.svg",
          name: "templates",
          text: "Manage your template here",
        },
        {
          icon: "/static_images/dictionary-icn.svg",
          name: "datadictionary",
          text: "Manage your data dictionary here",
        },
        {
          icon: "/static_images/approval-icn.svg",
          name: "approvals",
          text: "Manage your approvals here",
        },
        // {
        //   icon: "/static_images/signature-icn.svg",
        //   name: "signatures",
        //   text: "Manage your signatures here",
        // },
      ],
      adminIconsList: [
        {
          icon: "/static_images/dictionary-icn.svg",
          name: "dataDictionaryAdmin",
          text: "Manage your data dictionary here",
          displayName: "datadictionary",
        },
        {
          icon: "/static_images/clause-library-new.svg",
          name: "clauseLibraryAdmin",
          text: "Manage your clause library here",
          displayName: "clauselibrary",
        },
        {
          icon: "/static_images/template_icn.svg",
          name: "templatesAdmin",
          text: "Manage your template here",
          displayName: "templates",
        },
      ],
    };
  }

  componentDidMount() {
    if (
      window.location.href.includes("templates") ||
      window.location.href.includes("draftingreview") ||
      window.location.href.includes("approvals") ||
      window.location.href.includes("datadictionary") ||
      window.location.href.includes("dataDictionary") ||
      window.location.href.includes("clauselibrary")
    ) {
      this.props.gotoStyllus();
    }
    if (window.location.href.includes("admin")) {
      this.props.gotoAdmin();
    }
  }

  setClassName(iconName: string) {
    let className = "";
    if (iconName == "clauselibrary") {
      className = "sidenav-title p-3 ht-79";
    } else if (iconName == "datadictionary") {
      className = "sidenav-title p-2 pb-5 ht-79 w-200";
    }
    // else if (iconName == "signatures") {
    //   className = "sidenav-title pt-3 p-3 pb-5 ht-79 w-200";
    // }
    else if (iconName == "approvals") {
      className = "sidenav-title p-3 pt-3 ht-79";
    }
    // else if (iconName == "contractTypes") {
    //   className = "sidenav-title p-3 ht-auto";
    // }
    else if (iconName == "dataDictionaryAdmin") {
      className = "sidenav-title p-3 ht-auto";
    } else if (iconName == "clauseLibraryAdmin") {
      className = "sidenav-title p-3 ht-auto";
    } else if (iconName == "templatesAdmin") {
      className = "sidenav-title p-3 ht-auto";
    } else className = "sidenav-title p-3";

    if (iconName == this.props.pageType) {
      className = className.replace("sidenav-title", "sidenav-title-active");
    }
    return className;
  }
  render() {
    let { iconsList, styllusIconsList, adminIconsList } = this.state;
    let { pageType, platform } = this.props;

    switch (pageType) {
      case "document": {
        return (
          // <div className="row" >
          <div
            className="pl-0 sidenav-layout"
            style={{ width: "3.5%", zIndex: 2 }}
          >
            <div>
              <div className="mt-4 sidenav-theme">
                {iconsList.map((icon, i) => (
                  <div key={i}>
                    <div className="tooltip sidenav-title-layout cursor-pointer">
                      <span
                        className="tooltiptext"
                        style={{ width: "max-content" }}
                      >
                        {icon.text}
                      </span>
                      <div
                        id="collapsed-menubar"
                        className={
                          icon.name === pageType
                            ? "sidenav-title-active p-3"
                            : "sidenav-title p-3"
                        }
                        onClick={() => this.selectTab(icon.name)}
                      >
                        <img
                          src={
                            icon.name === pageType
                              ? icon.icon.replace(".svg", "-active.svg")
                              : icon.icon
                          }
                          alt="navbar-img"
                        />
                        <br />
                      </div>
                      {/* {i === 3 && <hr style={{ border: '0.5px solid #D3D3D3' }}></hr>} */}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          // </div>
        );
      }
      default: {
        return (
          <div className="row">
            <div className="col-md-1 pl-0 sidenav-layout">
              <div className="row">
                <div className="col-md-12 mt-4 sidenav-theme">
                  {platform == UMBRELLA &&
                    iconsList.map((icon, i) => (
                      <div className="row" key={i}>
                        <div className="col-md-12 tooltip sidenav-title-layout cursor-pointer">
                          <div
                            className={
                              (pageType === "documentlibrary" ||
                                pageType === "clauselibrary") &&
                              icon.name === pageType
                                ? "sidenav-title-active p-1"
                                : icon.name === pageType
                                ? "sidenav-title-active p-3"
                                : icon.name === "documentlibrary" ||
                                  icon.name === "clauselibrary"
                                ? "sidenav-title p-1"
                                : "sidenav-title p-3"
                            }
                            onClick={() => {
                              console.log(this.props);

                              this.selectTab(icon.name);
                            }}
                          >
                            <span>
                              <img
                                src={
                                  icon.name === pageType
                                    ? icon.icon.replace(".svg", "-active.svg")
                                    : icon.icon
                                }
                                alt="navbar-img"
                              />
                            </span>
                            <br />
                            <span
                              className={
                                icon.name === pageType
                                  ? "sidenav-text-active"
                                  : "sidenav-text"
                              }
                            >
                              <Tooltip title={icon.text} arrow>
                                <p>{capitalizeFirstLetter(icon.name)}</p>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {platform == STYLLUS &&
                    styllusIconsList.map((icon, i) => (
                      <div className="row" key={i}>
                        <div className="col-md-12 tooltip sidenav-title-layout cursor-pointer">
                          <div
                            className={this.setClassName(icon.name)}
                            onClick={() =>
                              (icon.name == "templates" ||
                                icon.name == "draftingreview" ||
                                icon.name == "approvals" ||
                                icon.name == "clauselibrary" ||
                                icon.name == "datadictionary") &&
                              this.selectTab(icon.name)
                            }
                          >
                            <span>
                              <img
                                src={
                                  icon.name === pageType
                                    ? icon.icon.replace(".svg", "-active.svg")
                                    : icon.icon
                                }
                                alt="navbar-img"
                              />
                            </span>
                            <br />
                            <span
                              className={
                                icon.name === pageType
                                  ? "sidenav-text-active"
                                  : "sidenav-text"
                              }
                            >
                              <Tooltip title={icon.text} arrow>
                                <p>{capitalizeFirstLetter(icon.name)}</p>
                              </Tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  {platform == ADMIN &&
                    adminIconsList.map((icon: any, i: any) => {
                      return (
                        <div className="row" key={i}>
                          <div className="col-md-12 tooltip sidenav-title-layout cursor-pointer">
                            <div
                              className={this.setClassName(icon.name)}
                              onClick={() =>
                                (icon.name == "dataDictionaryAdmin" ||
                                  icon.name == "clauseLibraryAdmin" ||
                                  icon.name == "templatesAdmin") &&
                                this.selectTab(icon.name)
                              }
                            >
                              <span>
                                <img
                                  src={
                                    icon.name === pageType
                                      ? icon.icon.replace(".svg", "-active.svg")
                                      : icon.icon
                                  }
                                  alt="navbar-img"
                                />
                              </span>
                              <br />
                              <span
                                className={
                                  icon.name === pageType
                                    ? "sidenav-text-active"
                                    : "sidenav-text"
                                }
                              >
                                <Tooltip title={icon.text} arrow>
                                  <p>
                                    {capitalizeFirstLetter(icon.displayName)}
                                  </p>
                                </Tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
  selectTab = (name: string) => {
    this.setState({ selectedTab: name });
    // name === 'addfiles' ? window.location.href = "/addfiles" : this.props.history.push('/' + name.toLowerCase())
    name === "dataDictionaryAdmin" ||
    name === "clauseLibraryAdmin" ||
    name === "templatesAdmin"
      ? (window.location.href = "/admin/" + name)
      : this.props.history.push("/" + name.toLowerCase().split(" ").join(""));
  };
}

//menu Item
// iconsList: [{
//     icon: '/static_images/dashboard-icn.svg',
//     name: 'dashboard'
// },{
//     icon: '/static_images/analysis-icn.svg',
//     name: 'analysis'
// }, {
//     icon: '/static_images/project-icn.svg',
//     name: 'project'
// }, {
//     icon: '/static_images/addfiles-icn.svg',
//     name: 'addfiles'
// }, {
//     icon: '/static_images/uploads-icn.svg',
//     name: 'uploads'
// },{
//     icon: '/static_images/settings-icn.svg',
//     name: 'settings'
// }, {
//     icon: '/static_images/subscriptions-icn.svg',
//     name: 'subscriptions'
// }],
