import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Box, IconButton, Snackbar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

interface Props {
  openModal: string;
  favourite: any;
  handleClosefavouriteBtn: any;
  action: any;
  undoFavourites:()=>void;
}

const FavModal: React.FC<Props> = (props: Props) => {
  const [msg, setMsg] = useState("");

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        style={{ color: "#88305F" }}
        onClick={props.undoFavourites}
      >
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.handleClosefavouriteBtn}
      >
        <img
          src={"/static_images/back_ICON.svg"}
          alt=""
          style={{
            width: "24px",
            height: "24px",
            marginLeft: "10px ",
          }}
          onClick={props.undoFavourites}
          
        />
        
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    let message = `${props.favourite} items added in Favourites`;
    setMsg(message);
  }, [props.favourite]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={props.openModal === "modalFav"}
        autoHideDuration={600000000}
        onClose={props.handleClosefavouriteBtn}
        message={msg}
        action={action}
        className="update-snackbar"
      />
      {/* <div>
       
        <Snackbar
          open={props.openModal === "modalFav"}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={props.handleClosefavouriteBtn}
          // message={props.favourite.length}
          message={msg}
          action={action}
          key={"top" + "center"}
      
        />
      </div> */}
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FavModal);
