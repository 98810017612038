import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { deleteApproval } from '../DA';

interface Props {
    open: boolean;
    getApprovals: () => void;
    onClose: () => void;
    id: string;
    count: number;
    approvalName: string;
}
const DeleteApprovalDialog = (props: Props) => {

    const [loading, setloading] = useState(false);
    const [open, setopen] = useState(false);

    useEffect(() => {
        setopen(props.open);
    }, [props.open]);

    const handleSubmit = async () => {
        try {
            setloading(true);
            const newResponse: any = await deleteApproval(props.id);
            if (newResponse.status == 204) {
                setloading(false);
                handleClose();
                setTimeout(() => {
                    props.getApprovals();
                }, 500);
            }
        } catch (error) {
            setloading(false);
            console.error('handleSubmit error ', error);
        } finally {
            setloading(false);
        }
    }

    const handleClose = () => {
        setopen(false);
        props.onClose();
    }

    const getApprovalCount = (count: number): string => {
        if (count + 1 < 10) {
            return `#00${count + 1}`;
        }
        if (count + 1 < 100) {
            return `#0${count + 1}`;
        }
        return `#${count + 1}`;
    }

    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle className="riverus-dialog-header">
                <div className="row">
                    <div className="col-md-11">Delete Draft</div>
                    <div onClick={handleClose} className="col-md-1 close-icn"><img src="/static_images/close-analysismodal-icn.svg" alt="close_icon" /></div>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {loading && <CircularProgress className="loader" />}
                <div className="row">
                    <div className="col-md-12">
                        <p>Are you sure you want to delete <b>Approval {getApprovalCount(props.count)} {props.approvalName}</b> ?</p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>Cancel</Button>
                <Button className='create-btn' onClick={handleSubmit}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteApprovalDialog