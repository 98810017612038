import React, { Component } from "react";
import { isNullOrUndefined } from "is-what";
import { DarkTooltip } from "../../../DocumentView/Component/documentInsights";
import OtherTags from "../../../DocumentView/Component/EditFeature/Component/BasicInformation/Tags/otherTags";
import {
  nature_tag_color,
  tag_nature_tooltip,
  tag_type_tooltip,
  type_tag_color,
} from "../../../DocumentView/Component/Utils/docUtils";
import { TagData } from "../../../DocumentView/State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import {
  BasicdocumentLibraryFileInfo,
  BasicFileInfo,
} from "../../State/documentLibraryState";
import {
  documentgetFileIdsFromFiles,
  getFileIdsFromFiles,
} from "../Utils/libraryUtils";

import { SITE_API_BY_REALM_NAME } from "../../../Configuration/global";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../../Authentication/Actions/authentication";
import axios from "axios";
import Select, { ActionMeta, OnChangeValue, StylesConfig } from "react-select";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

const userGroupStyles: StylesConfig<groupOption, true> = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

interface groupOption {
  readonly value: string;
  readonly label: string;
  readonly isFixed?: boolean;
}

interface Props {
  listTagNature: () => void;
  listTagType: () => void;
  tagNature: TagData[];
  tagType: TagData[];
  otherTags: TagData[];
  listOtherTags: () => void;
  createOtherTags: (name: string) => void;
  storeOtherTags: (storedOtherTags: any) => void;
  tags: any[];
  newTagData: TagData;
  storedOtherTags: any;
  savedMultipleSelectedFiles: BasicFileInfo[];
  selectedFile: BasicFileInfo | null;
  saveSelectedFile: (selectedFile: BasicFileInfo | null) => void;
  selectedLibraryAction: string;
  isBulkAction: boolean;
  saveSelectedLibraryAction: (
    selectedLibraryAction: string,
    isBulkAction: boolean
  ) => void;
  editTags: (
    editFileIds: string[],
    dataType: string,
    tagEditData: any,
    isBulkAction: boolean
  ) => void;
  saveFileTagData: (savedFileTagData: any[]) => void;
  initialFileIds: string[];
  getDocumentHierarchy: (
    sort: string,
    order: string,
    fileIds: string[]
  ) => void;
  otherTagsLoader: boolean;
  documentselectedFile: BasicdocumentLibraryFileInfo | null;
  documentsaveSelectedFile: (
    documentselectedFile: BasicdocumentLibraryFileInfo | null
  ) => void;
  documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  readonly allUserGroups: readonly groupOption[];
  readonly selectedUserGroups: readonly groupOption[];
  readonly originalselectedUserGroups: readonly groupOption[];
  onUserGroupChange: (
    value: OnChangeValue<groupOption, true>,
    actionMeta: ActionMeta<groupOption>
  ) => void;
}

interface State {
  natureTagId: string;
  typeTagId: string;
  othersTagId: string[];
  nature: string;
  nature_id: string;
  type: string;
  otherTagsId: string[];
  expandNature: boolean;
  expandType: boolean;
}

export default class LibraryAddTag extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      natureTagId: "-1",
      typeTagId: "-1",
      othersTagId: [],
      nature: "",
      type: "",
      otherTagsId: [],
      expandNature: false,
      expandType: false,
      nature_id: "-1",
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    let { tags } = nextProps;

    if (this.props.tags !== nextProps.tags && nextProps.tags !== undefined) {
      if (nextProps.tags.length > 0) {
        let savedNature = tags.filter((el) => {
          return el.dataPoints.categoryId === 1;
        })[0];
        !isNullOrUndefined(savedNature) &&
          this.setState({
            nature: savedNature.name,
            nature_id: savedNature.id,
          });
        let savedType = tags.filter((el) => {
          return el.dataPoints.categoryId === 2;
        })[0];
        !isNullOrUndefined(savedType) &&
          this.setState({ type: savedType.name });
        this.setState({
          natureTagId: this.setInitialTagId("1", tags),
          typeTagId: this.setInitialTagId("2", tags),
          othersTagId: this.setInitialOtherTagId(tags),
        });
      } else {
        this.setState({
          natureTagId: "-1",
          typeTagId: "-1",
          othersTagId: [],
          nature: "",
          type: "",
          otherTagsId: [],
          expandNature: false,
          expandType: false,
        });
      }
    }
  }

  setInitialTagId(categoryId: string, tags: any[]) {
    let returnTagId: string = "-1";
    tags.forEach((el) => {
      if (el.dataPoints.categoryId === categoryId) {
        returnTagId = el.dataPoints.tagId;
      }
    });
    return returnTagId;
  }

  setInitialOtherTagId(tags: any[]) {
    let returnTagId: string[] = [];
    tags.forEach((el) => {
      if (el.dataPoints.categoryId === 3) {
        returnTagId.push(el.dataPoints.tagId);
      }
    });
    return returnTagId;
  }

  render() {
    let {
      storedOtherTags,
      storeOtherTags,
      newTagData,
      tagNature,
      tagType,
      listOtherTags,
      otherTags,
      createOtherTags,
      tags,
      isBulkAction,
      selectedLibraryAction,
      otherTagsLoader,
      documentselectedFile,
      selectedFile,
      documentsavedMultipleSelectedFiles,
      allUserGroups,
      selectedUserGroups,
    } = this.props;

    let { nature, type, expandNature, expandType } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 my-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-4">
                    <div className="row">
                      <div className="col-md-12 tag-type-hoverable align-left">
                        <DarkTooltip
                          title={tag_nature_tooltip}
                          placement="right-end"
                        >
                          <span>Nature</span>
                        </DarkTooltip>
                      </div>

                      <div className="col-md-11 mb-1">
                        {nature.length > 0 ? (
                          <div className="row">
                            <div className="col-md-12 align-left">
                              <span className="tag-input-disabled">
                                <input
                                  type="text"
                                  readOnly
                                  className="tag-input"
                                  placeholder="Remove existing tag to add another"
                                  style={{
                                    width: "93%",
                                    border: "none",
                                    outline: "none",
                                    background: "#E2E2E2",
                                  }}
                                />
                                <img
                                  src="/static_images/tag-dropdown-inactive.svg"
                                  alt="dropdown"
                                />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-md-12 align-left">
                                <span
                                  className="tag-input"
                                  style={{
                                    background:
                                      isBulkAction === true
                                        ? "#E9E9E9"
                                        : "white",
                                    border: "1px solid #DDDDDD",
                                  }}
                                  onClick={() =>
                                    isBulkAction === false &&
                                    this.setState({
                                      expandNature: !this.state.expandNature,
                                    })
                                  }
                                >
                                  <input
                                    type="text"
                                    readOnly
                                    className="tag-input"
                                    placeholder="Select one tag"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      background:
                                        isBulkAction === true
                                          ? "#E9E9E9"
                                          : "white",
                                    }}
                                  />
                                  <img
                                    src="/static_images/new-tag-dropdown-active.svg"
                                    alt="dropdown"
                                    style={{
                                      transform: expandNature
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            {expandNature && (
                              <div className="row ">
                                <div
                                  className="col-md-12 tag-autocomplete-container align-left"
                                  style={{ margin: 0 }}
                                >
                                  <Scrollable maxHeight={100}>
                                    {tagNature.map((tag, i) => (
                                      <div
                                        className="tag-input-suggestion cursor-pointer align-left"
                                        style={{ fontSize: "14px" }}
                                        key={i}
                                        onClick={() =>
                                          this.setTags(
                                            tag.name,
                                            "Nature",
                                            tag.name
                                          )
                                        }
                                      >
                                        {tag.name}
                                      </div>
                                    ))}
                                  </Scrollable>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {nature.length > 0 && isBulkAction === false && (
                      <div className="row">
                        <div className="col-md-12 tag-label align-left">
                          <span
                            style={{
                              background: nature_tag_color,
                              marginLeft: "0rem",
                              padding: "1% 1%",
                            }}
                          >
                            {nature}&nbsp;&nbsp;
                            <img
                              src="/static_images/new-remove-tag-label.svg"
                              alt="remove"
                              className="cursor-pointer"
                              onClick={() => this.setTags("", "Nature", nature)}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mb-4">
                    <div className="row">
                      <div className="col-md-12 tag-type-hoverable align-left">
                        <DarkTooltip
                          title={tag_type_tooltip}
                          placement="right-end"
                        >
                          <span>Type</span>
                        </DarkTooltip>
                      </div>
                      <div className="col-md-11 mb-1">
                        {type.length > 0 ? (
                          <div className="row">
                            <div className="col-md-12">
                              <span className="tag-input-disabled">
                                <input
                                  type="text"
                                  readOnly
                                  className="tag-input"
                                  placeholder="Remove existing tag to add another"
                                  style={{
                                    width: "93%",
                                    border: "none",
                                    outline: "none",
                                    background: "#E2E2E2",
                                  }}
                                />
                                <img
                                  src="/static_images/tag-dropdown-inactive.svg"
                                  alt="dropdown"
                                />
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <span
                                  className="tag-input"
                                  style={{
                                    background:
                                      isBulkAction === true
                                        ? "#E9E9E9"
                                        : "white",
                                    border: "1px solid #DDDDDD",
                                  }}
                                  onClick={() =>
                                    isBulkAction === false &&
                                    this.setState({
                                      expandType: !this.state.expandType,
                                    })
                                  }
                                >
                                  <input
                                    type="text"
                                    readOnly
                                    className="tag-input"
                                    placeholder="Select one tag"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      background:
                                        isBulkAction === true
                                          ? "#E9E9E9"
                                          : "white",
                                    }}
                                  />
                                  <img
                                    src="/static_images/new-tag-dropdown-active.svg"
                                    alt="dropdown"
                                    style={{
                                      transform: expandType
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            {expandType && (
                              <div className="row">
                                <div
                                  className="col-md-12 tag-autocomplete-container"
                                  style={{ margin: 0 }}
                                >
                                  <Scrollable maxHeight={100}>
                                    {tagType.map((tag, i) => (
                                      <div
                                        className="tag-input-suggestion cursor-pointer align-left"
                                        style={{ fontSize: "14px" }}
                                        key={i}
                                        onClick={() =>
                                          this.setTags(
                                            tag.name,
                                            "Type",
                                            tag.name
                                          )
                                        }
                                      >
                                        {tag.name}
                                      </div>
                                    ))}
                                  </Scrollable>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {type.length > 0 && isBulkAction === false && (
                      <div className="row">
                        <div className="col-md-12 tag-label align-left">
                          <span
                            style={{
                              background: type_tag_color,
                              marginLeft: "0rem",
                              padding: "1% 1%",
                            }}
                          >
                            {type}&nbsp;&nbsp;
                            <img
                              src="/static_images/new-remove-tag-label.svg"
                              alt="remove"
                              className="cursor-pointer"
                              onClick={() => this.setTags("", "Type", type)}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="mb-2 align-left">
                    <OtherTags
                      storedOtherTags={storedOtherTags}
                      storeOtherTags={storeOtherTags}
                      newTagData={newTagData}
                      setTags={(e) => this.setTags(e, "Others", "")}
                      tags={tags}
                      otherTags={otherTags}
                      createOtherTags={createOtherTags}
                      listOtherTags={listOtherTags}
                      selectedLibraryAction={selectedLibraryAction}
                      isBulkAction={isBulkAction}
                      otherTagsLoader={otherTagsLoader}
                      documentselectedFile={documentselectedFile}
                      
                    />
                  </div> */}
                </div>
              </div>

              <Select
                value={selectedUserGroups}
                isMulti
                styles={userGroupStyles}
                isClearable={selectedUserGroups.some((v) => !v.isFixed)}
                name="Groups"
                className="basic-multi-select"
                classNamePrefix="Select Groups"
                onChange={this.props.onUserGroupChange}
                options={this.props.allUserGroups}
              />
            </div>
          </div>
          {this.saveOrCancel()}
        </div>
      </div>
    );
  }

  saveOrCancel = () => {
    let {
      documentselectedFile,
      selectedUserGroups,
      originalselectedUserGroups,
    } = this.props;

    return (
      <div className="row">
        <div className="col-md-3" />
        <div className="col-md-7">
          <span
            className="upload-yellow-btn py-1 mb-4"
            id="save-btn"
            data-dismiss="modal"
            onClick={() => this.onSave()}
          >
            Save
          </span>
        </div>
      </div>
    );
  };
  setTags(e: any, category: string, selectedTag: any) {
    let { tagNature, tagType } = this.props;
    if (category === "Nature") {
      this.setState({ nature: e, nature_id: e.id });
      let index = tagNature.findIndex((el: TagData) => {
        return el.name === e;
      });
      if (index > -1) {
        this.setState({ natureTagId: tagNature[index].id });
      } else {
        this.setState({ natureTagId: "-1" });
      }
    } else if (category === "Type") {
      this.setState({ type: e });
      let index = tagType.findIndex((el: TagData) => {
        return el.name === e;
      });
      if (index > -1) {
        this.setState({ typeTagId: tagType[index].id });
      } else {
        this.setState({ typeTagId: "-1" });
      }
    } else {
      let othersArray = this.state.otherTagsId;
      othersArray.push(e);
      this.setState({ otherTagsId: othersArray });
      this.setState({ othersTagId: e });
    }
  }

  onSave() {
    let { natureTagId, typeTagId, othersTagId, nature, type } = this.state;
    let {
      isBulkAction,
      selectedFile,
      savedMultipleSelectedFiles,
      initialFileIds,
      documentselectedFile,
      tagNature,
      tagType,
      selectedUserGroups,
    } = this.props;

    let editedFileIds: string[] = [];
    if (isBulkAction === true) {
      if (documentselectedFile != null) {
        editedFileIds = [documentselectedFile.fileId]; //getFileIdsFromFiles(savedMultipleSelectedFiles);
        this.props.editTags(
          editedFileIds,
          "Tags:Others",
          othersTagId,
          isBulkAction
        );
      }
    } else {
      if (selectedFile !== null) {
        if (documentselectedFile != null) {
          editedFileIds = [documentselectedFile.fileId];
          let nature_tag_id = natureTagId;
          let type_id = typeTagId;

          if (!isNullOrUndefined(nature)) {
            let index = tagNature.findIndex((el: TagData) => {
              return el.name === nature;
            });
            if (index > -1) {
              nature_tag_id = tagNature[index].id;
            }
          }
          if (!isNullOrUndefined(type)) {
            let index = tagType.findIndex((el: TagData) => {
              return el.name === type;
            });
            if (index > -1) {
              type_id = tagType[index].id;
            }
          }

          this.props.editTags(
            editedFileIds,
            "Tags:Nature",
            nature_tag_id,
            isBulkAction
          );
          this.props.editTags(
            editedFileIds,
            "Tags:Type",
            type_id,
            isBulkAction
          );
          this.props.editTags(
            editedFileIds,
            "Tags:Others",
            othersTagId,
            isBulkAction
          );

          var groups = [];
          for (var i = 0; i < selectedUserGroups.length; i++) {
            groups.push(selectedUserGroups[i].value);
          }
          var bodyData = {
            groups: groups,
          };

          let url = SITEAPI + "" + documentselectedFile?.fileId + "/";
          var tokenLatest =
            "Bearer " + getLocalStorage("accessToken")?.toString();
          var response = axios.patch(url, bodyData, {
            headers: { Authorization: `${tokenLatest}` },
          });

          response.then((result) => {
            if (result.status == 200) {
              console.log(result);
            }
          });
          response.catch((error) => {
            console.log(error);
          });
        }
      }
    }
    this.props.saveSelectedFile(null);
    this.props.saveSelectedLibraryAction("", false);
    this.setState({
      nature: "",
      type: "",
      otherTagsId: [],
      expandNature: false,
      expandType: false,
      natureTagId: "-1",
      typeTagId: "-1",
      othersTagId: [],
      nature_id: "-1",
    });
    this.props.saveFileTagData([]);
    window.location.reload();
  }
}
