import { Field_INFO } from "../../Constants/DataDictionary";
import { User } from "../../Draft/State/DraftState";

export interface FieldValue {
    sectionList: string[];
    mergeTagList: Field_INFO[];
    conditionList: string[];
    defaultValueList: string[];
    selectedDataType: string;
}

export interface CreateApprovalPostReq {
    section_tag: string;
    field_name: string;
    conditions: string;
    value: string;
    approvers: string[];
    applicable_for: string[];
    optional_data?: string;
    approval_type: string;
    approval_name: string;
    approval_number: number;
    include_all:boolean;
    is_case_sensitive:boolean;
    scope:string;
}

export interface Approval {
    section_tag: string;
    field_name: string;
    conditions: string;
    value: string;
    approvers: User[];
    applicable_for: any[];
    optional_data: string;
    id: string;
    created_by: User | any;
    created_on: string;
    approval_type: string;
    approval_name: string;
    approval_number: number;
    is_case_sensitive:boolean;
    include_all:boolean;
    scope:string;
}

export const approvalTypeList: string[] = [
    'Contract Requisition',
    'Contract Condition Approval',
    // 'Draft pre-signature Approval',
    // 'Standing Approval',
    // 'Adhoc Approval'
];

export interface Filter {
    approval_type: string;
    applicable_for: string[];
}