import React, { useEffect, useState } from "react";
import {
  getLocalStorage,
  getKeyCloakRealmFromLS,
} from "../Authentication/Actions/authentication";
import { NotificationManager } from "react-notifications";
import UnAuthorized from "./unauthorized";
import LoadingAnim from "../Assessments/Components/loadingAnim";
import axios from "axios";
import { SITE_API_BY_REALM_NAME } from "../Configuration/global";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
const axiosConfig = async () => {
  const accessToken = await getLocalStorage("accessToken");
  return { headers: { Authorization: `Bearer ${accessToken}` } };
};
const UserAuthChecker = (ComposedClass, allowedRoles) => {
  const AuthenticationCheck = (props) => {
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState("");
    const [allow, setAllow] = useState(false);
    const fetchProfile = async () => {
      const profile = await getLocalStorage("user_profile");
      const accessToken = await getLocalStorage("accessToken");

      let role = "";
      if (profile && profile.username) {
        setAllow(true);
        setLoading(false);
        /*if (profile.roles && profile.roles.length > 0) {
          if (profile.roles.includes("internal-users")) {
            setRole("internal");
            role = "internal";
          } else if (profile.roles.includes("external-users")) {
            setRole("external");
            role = "external";
          }
        }
        if (allowedRoles.includes(role)) setAllow(true);
        setLoading(false);*/
      } else {
        axios
          .get(
            process.env.REACT_APP_RIVERUS_CLM_API + "users/profile/",

            await axiosConfig()
          )
          .then((result) => {
            const response = result.data;

            if (response.id) {
              localStorage.setItem(
                "user_profile",
                JSON.stringify(response.data)
              );

              const profile = response;

              localStorage.setItem("user_id", profile.id);
              localStorage.setItem(
                "user_name",
                profile.first_name + " " + profile.last_name
              );
              setAllow(true);
              setLoading(false);
              /*if (profile && profile.name) {
                if (profile.roles && profile.roles.length > 0) {
                  if (profile.roles.includes("internal-users")) {
                    setRole("internal");
                    role = "internal";
                  } else if (profile.roles.includes("external-users")) {
                    setRole("external");
                    role = "external";
                  }
                }
                if (allowedRoles.includes(role)) setAllow(true);
                setLoading(false);
              }*/
            } else {
            }
          })
          .catch((error) => {});
      }
    };
    useEffect(() => {
      fetchProfile();
    }, []);

    if (loading) {
      return <LoadingAnim />;
    } else if (!allow) return <UnAuthorized />;
    else
      return (
        <>
          <ComposedClass {...props} userRole={role} />
        </>
      );
  };

  return AuthenticationCheck;
};
export default UserAuthChecker;
