import React, { Component, Fragment } from "react";
import { FilterData } from "../../Draft/State/DraftState";
import FilterInputModal from "../../UniversalComponents/Filter/filterInputModal";

interface Props {
  applySorting: (key: string, valueType: string) => void;
  isSupportTemplate: boolean;
  contractTypes: any[];
  filterKeyVals: FilterData;
  applyFilter: (a: string[], b: string) => void;
  selectMultipleTemplates: (id: string) => void;
  tags: any[];
  selectAllTemplate: (isContract: boolean) => void;
  selectedTemplate: any[];
  totalCount: number;
  userList: string[];
}

interface State {
  contractRef: any;
  filterControl: any;
  checked: boolean;
  tags: any;
  uploadedByRef: any;
}

export default class ListViewHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contractRef: null,
      filterControl: {},
      checked: false,
      tags: null,
      uploadedByRef: null,
    };
  }

  handleClick(event: any, placeHolder: string, filterKey: string) {
    this.setState({
      filterControl: { ...this.state.filterControl, [filterKey]: true },
    });
  }

  handleClose(key: string) {
    this.setState({
      filterControl: { ...this.state.filterControl, [key]: false },
    });
  }

  componentDidMount(): void {
    let contractType = document.getElementById("contractRef");
    let tags = document.getElementById("tags");
    let uploadedBy = document.getElementById("uploadedBy");
    if (contractType) {
      this.setState({ contractRef: contractType });
    }
    if (tags) {
      this.setState({ tags: tags });
    }
    if (uploadedBy) {
      this.setState({ uploadedByRef: uploadedBy });
    }
  }

  render() {
    const { applyFilter, filterKeyVals } = this.props;
    return (
      <>
        <div className="col-md-12 p-0">
          <div className="row list-view-header m-0 p-0">
            <div className="col-md-1" style={{ maxWidth: "2%" }}>
              {(this.props.selectedTemplate.length == 0 ||
                this.props.selectedTemplate.length !==
                  this.props.totalCount) && (
                <input
                  onChange={(e) =>
                    this.props.selectAllTemplate(!this.props.isSupportTemplate)
                  }
                  style={{ width: "15px", height: "15px" }}
                  type="checkbox"
                  name="check"
                  id="check"
                />
              )}
              {this.props.selectedTemplate.length > 0 &&
                this.props.selectedTemplate.length == this.props.totalCount && (
                  <img
                    onClick={() =>
                      this.props.selectAllTemplate(
                        !this.props.isSupportTemplate
                      )
                    }
                    className="checked-icon"
                    src="/static_images/checked_icon.svg"
                    alt="checked"
                  ></img>
                )}
            </div>
            <div className="col-md-1">ID</div>
            <div
              className="col-md-2"
              style={{ flex: "0 0 22%", maxWidth: "25%" }}
            >
              Name{" "}
              <span>
                <img
                  src="/static_images/text_filter_inactive.svg"
                  onClick={() => this.props.applySorting("name", "string")}
                />
              </span>
            </div>
            <div
              className="col-md-2"
              id="contractRef"
              style={{ flex: "0 0 15%" }}
            >
              {this.props.isSupportTemplate ? "Document Type" : "Contract Type"}{" "}
              <span>
                <img
                  src="/static_images/filter-icn.svg"
                  onClick={(e) =>
                    this.handleClick(e, "Contract Type", "templateType")
                  }
                />
              </span>
              {this.state.filterControl["templateType"] &&
                this.state.contractRef && (
                  <FilterInputModal
                    filterKey="templateType"
                    filterKeyVals={filterKeyVals}
                    open={this.state.filterControl["templateType"]}
                    anchorEl={this.state.contractRef}
                    handleClose={() => this.handleClose("templateType")}
                    applyFilter={applyFilter}
                    list={this.props.contractTypes}
                    selectedItem={filterKeyVals["templateType"]}
                  />
                )}
            </div>
            <div
              className="col-md-1"
              id="tags"
              style={{ flex: "0 0 9.3%", maxWidth: "9.3%", paddingLeft: "0" }}
            >
              Tags
              <span>
                <img
                  src="/static_images/filter-icn.svg"
                  onClick={(e) => this.handleClick(e, "Tags", "tags")}
                />
              </span>
              {this.state.filterControl["tags"] && this.state.tags && (
                <FilterInputModal
                  filterKey="tags"
                  filterKeyVals={filterKeyVals}
                  open={this.state.filterControl["tags"]}
                  anchorEl={this.state.tags}
                  handleClose={() => this.handleClose("tags")}
                  applyFilter={applyFilter}
                  list={this.props.tags}
                  selectedItem={filterKeyVals["tags"]}
                />
              )}
            </div>
            <div
              className="col-md-1"
              id="uploadedBy"
              style={{ flex: "0 0 11.3%", maxWidth: "11.3%" }}
            >
              Uploaded By
              <span>
                <img
                  src="static_images/filter-icn.svg"
                  onClick={(e) => this.handleClick(e, "Upload", "uploadedBy")}
                />
              </span>
              {this.state.filterControl["uploadedBy"] &&
                this.state.uploadedByRef && (
                  <FilterInputModal
                    filterKey="uploadedBy"
                    filterKeyVals={filterKeyVals}
                    open={this.state.filterControl["uploadedBy"]}
                    anchorEl={this.state.uploadedByRef}
                    handleClose={() => this.handleClose("uploadedBy")}
                    applyFilter={applyFilter}
                    list={this.props.userList}
                    selectedItem={filterKeyVals["uploadedBy"]}
                  />
                )}
            </div>
            <div className="col-md-2" style={{ paddingLeft: "4px" }}>
              Uploaded Date{" "}
              <span>
                <img
                  onClick={() => this.props.applySorting("createdOn", "date")}
                  src="/static_images/calender_filter_inactive.svg"
                />
              </span>
            </div>
            <div className="col-md-2" style={{ flex: "0 0 14%" }}>
              No of Contracts{" "}
              <span>
                <img
                  // onClick={() => this.props.applySorting("version", "number")}
                  src="/static_images/number_filter_inactive.svg"
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
