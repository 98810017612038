import React, { useEffect, useState } from "react";
import Scrollable from "../../Scrollable/scrollable";

export default function SelectSection(props: any) {
  const [sectionName, setSection] = useState<any>();
  useEffect(() => {
    props.getSectionDictionary();
  }, []);
  return (
    <div className="row section-modal">
      <div className="select-section-modal">
        <div className="modal-header">
          <p className="m-0">Section Section</p>
          <img
            onClick={props.onClose}
            className="cursor-pointer"
            src="/static_images/white-close-modal-icn.svg"
            alt="clause-folder"
          />
        </div>
        <div style={{ paddingLeft: "15px" }}>
          <p
            className="m-0 pl-1 pt-3"
            style={{ fontSize: "14px", color: "#818181" }}
          >
            Select Section to move the clause
          </p>
          <div className="d-flex justify-content-center align-items-center flex-column mt-4 cursor-pointer">
            <div className="col-md-8">
              <label>Select Section</label>
              <select
                onChange={(e) => {
                  setSection(e.target.value);
                }}
                style={{
                  width: "100%",
                  height: "45%",
                  background: "transparent",
                  border: "1px solid #DDDDDD",
                }}
                placeholder="Select Dropdown"
              >
                <option selected hidden>
                  Select Section
                </option>
                {props.sectionDictionaryData &&
                  props.sectionDictionaryData?.length &&
                  props.sectionDictionaryData.map((item: any) => {
                    return (
                      <option value={item.id}>{item?.section_name}</option>
                    );
                  })}
              </select>
            </div>
            <div className="mb-2 mt-4">
              <div
                onClick={() => {
                  props.bulkUpdateClause({
                    section_name: sectionName,
                    clause_id: props.selectedClause,
                  });
                }}
                className="section-add-clause py-1"
                style={{ fontSize: "13px", padding: "10px 25px" }}
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
