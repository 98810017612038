import * as React from "react";
import { isNullOrUndefined } from "is-what";
import { Box, Modal, Typography } from "@material-ui/core";
import { isRiverusAdmin } from "../DDUtils";

interface Props {
  setNewSectionData: any;
  addSection: any;
  setAddSection: any;
  edit: boolean;
  data?: any | null;
  viewAllSectionUpdate?: any | null;
  setSectionData: any;
}
interface State {
  sectionName: string;
  sectionDescription: string;
}

export default class AddSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sectionName: "",
      sectionDescription: "",
    };
  }
  componentDidMount() {
    document.addEventListener("mouseup", this.handleOutsideClick, false);
  }

  componentDidUpdate(previousProps: any, previousState: any): void {
    if (previousProps.data !== this.props.data) {
      this.setState({
        sectionName: this.props.data ? this.props?.data?.section_name : "",
        sectionDescription: this.props.data
          ? this.props?.data?.section_description
          : "",
      });
    }
  }
  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("deleteModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("delete-btn-outside-click")?.click();
        window.location.reload();
      }
    }
  };

  style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 15,
  };

  camelize(str: any) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  render() {
    console.log(this.props.edit, "dasdsa");

    return (
      <Modal
        open={this.props.addSection}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={this.style}>
          <div className="modal-header">
            <p className="m-0">Add Section</p>
            <img
              className="cursor-pointer"
              src="/static_images/white-close-modal-icn.svg"
              alt="clause-folder"
              onClick={() => {
                this.props.setAddSection(false);
                this.props.setSectionData(null);
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <div>
              <input
                type="text"
                className="cls_section_name mt-4"
                placeholder={"Enter the Section Name"}
                value={this.state.sectionName}
                onChange={(event: any) => {
                  this.setState({ sectionName: event.target.value });
                }}
              />
              <input
                className="cls_section_description mt-3"
                type="text"
                placeholder={"Enter the Section Description"}
                value={this.state.sectionDescription}
                onChange={(event: any) => {
                  this.setState({ sectionDescription: event.target.value });
                }}
              />
              <div className="d-flex justify-content-end mt-3">
                <div
                  className="row justify-content-end"
                  style={{ marginRight: "20px" }}
                >
                  <button
                    onClick={() => {
                      this.props.setAddSection(false);
                      this.props.setSectionData(null);
                    }}
                    type="button"
                    className="btn btn-warning create-btn"
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="row justify-content-end"
                  style={{ marginRight: "20px" }}
                >
                  <button
                    onClick={() => {
                      const sectionTag: string = `::${this.camelize(
                        this.state.sectionName
                      )}::`;
                      const payload = {
                        section_name: this.state.sectionName,
                        section_tag: sectionTag,
                        section_description: this.state.sectionDescription,
                        creation_type: isRiverusAdmin() ? "system" : "custom",
                        comments: "string",
                      };
                      if (this.props.edit) {
                        this.props.viewAllSectionUpdate(
                          { ...payload, edit_for_all: false },
                          this.props.data.section_id
                        );
                        this.props.setAddSection(false);
                        this.setState({
                          sectionDescription: "",
                          sectionName: "",
                        });
                      } else {
                        this.props.setNewSectionData(payload);
                        this.props.setAddSection(false);
                        this.setState({
                          sectionDescription: "",
                          sectionName: "",
                        });
                      }
                      this.props.setSectionData(null);
                    }}
                    type="button"
                    className="btn btn-warning create-btn"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}
