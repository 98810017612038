import React, { Component } from "react";
import {
  AllTasksData,
  AssigneesList,
  AssociateGroup,
  ProgressNameList,
  ProgressUpdated,
} from "../../State/taskManagementPageState";
import Tooltip from "@material-ui/core/Tooltip";
import {
  getDueDateFormat,
  truncateFileName,
  truncateString,
} from "../../../Utils/DataModifierUtil/dataModUtil";
import { DarkTooltip } from "../../../DocumentView/Component/documentInsights";
import { getUserNameInitials } from "../Utils/taskManagementUtils";
import { isNullOrUndefined } from "is-what";
import { History } from "history";

export interface UpdateProgress {
  requestID: number;
  updatedTo: string;
}

interface Props {
  allTasksData: AllTasksData[];
  history: History;
  setModal: (
    title: string,
    numberOfMembers: number,
    // associateGroup: AssociateGroup[]
    assignees: AssigneesList[]
  ) => void;
  progressNameList: ProgressNameList[];
  updateProgress: (
    requestID: number,
    progressID: string,
    id: string,
    progressName: string
  ) => void;
  isProgressUpdated: number;
  updatedProgressQueue: ProgressUpdated[];
  resetUpdatedProgressQueue: (progressUpdated: ProgressUpdated[]) => void;
}

interface State {
  hideProgressOptions: boolean;
  requestID: number;
  updatedProgress: UpdateProgress[];
  updateProgressTo: string;
  executionQueue: ProgressUpdated[];
  id: string;
  statusdata: any;
  progressData: any;
}

export default class ListViewTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hideProgressOptions: true,
      requestID: 0,
      updatedProgress: [],
      updateProgressTo: "",
      executionQueue: [],
      id: "0",
      statusdata: {
        DONE: "Done",
        OVERDUE: "Overdue",
        DUE_TODAY: "Due Today",
        DUE_IN_A_WEEK: "Due in a Week",
        DUE_IN_A_MONTH: "Due in a Month",
        NOT_DUE_SOON: "Not Due Soon",
      },
      progressData: {
        NOT_STARTED: "Not Started",
        IN_PROGRESS: "In Progress",
        COMPLETED: "Completed",
      },
    };
  }
  getExtraCount = (numberOfAssociates: number) => {
    let extraCount = numberOfAssociates - 3;
    return "+" + extraCount;
  };

  getProgressData = (progress: any) => {
    return this.state.progressData[progress];
  };

  getStatusData = (status: any) => {
    return this.state.statusdata[status];
  };

  setModal(
    check: boolean,
    title: string,
    numberOfMembers: number,
    // associateGroup: AssociateGroup[]
    assignees: AssigneesList[]
  ) {
    if (check) {
      // this.props.setModal(title, numberOfMembers, associateGroup);
      this.props.setModal(title, numberOfMembers, assignees);
      let link = document.getElementById("taskManagementTableButton");
      !isNullOrUndefined(link) && link.click();
    }
  }

  updateProgress(
    requestID: number,
    progressID: string,
    updateProgressTo: string,
    id: string
  ) {
    let { updateProgress } = this.props;
    updateProgress(requestID, progressID, id, updateProgressTo);
    let temp: ProgressUpdated = {
      requestID: requestID,
      progressID: progressID,
      id: id,
    };

    this.state.executionQueue.push(temp);
    this.setState({
      hideProgressOptions: !this.state.hideProgressOptions,
      updateProgressTo: updateProgressTo,
      executionQueue: this.state.executionQueue,
    });
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.updatedProgressQueue !== nextProps.updatedProgressQueue) {
      if (this.state.executionQueue.length > 0) {
        let temp = [...this.state.executionQueue];

        let filteredData: ProgressUpdated[] = [];

        for (let i = 0; i < temp.length; i++) {
          let isPush: boolean = false;
          for (let j = 0; j < nextProps.updatedProgressQueue.length; j++) {
            if (
              // nextProps.updatedProgressQueue[j].requestID !==
              // this.state.executionQueue[i].requestID
              nextProps.updatedProgressQueue[j].id ==
              this.state.executionQueue[i].id
            ) {
              isPush = true;
              // break;
            } else {
              isPush = false;
            }
          }
          isPush === true && filteredData.push(temp[i]);
        }
        if (filteredData.length === 0) {
          nextProps.resetUpdatedProgressQueue(filteredData);
        }
        this.setState({
          updatedProgress: this.state.updatedProgress,
          executionQueue: filteredData,
        });
      }
    }
  }

  render() {
    let {
      allTasksData,
      progressNameList,
      isProgressUpdated,
      updatedProgressQueue,
      history,
    } = this.props;
    let { hideProgressOptions, requestID, executionQueue, id } = this.state;

    return (
      <>
        {allTasksData.map((task: AllTasksData, key: number) => (
          <div
            className="col-md-12"
            style={{
              borderBottom: "1px solid #E1E1E1",
              marginLeft: "0.8rem",
              height: "45px",
              backgroundColor: "white",
            }}
          >
            <div className="row list-view-table text-center">
              <div id="contract-name" className="col-md-2">
                <span
                  style={{ display: "initial", cursor: "pointer" }}
                  onClick={() => {
                    /*// window.open(
                    //   "/document/tasks/" +
                    //   btoa(task.contract.toString()) +
                    //   "/" +
                    //   btoa(task.id.toString()),
                    //   "_blank"
                    // )
                    history.push( "/document/tasks/" +
                          btoa(task.contract.toString()) +
                          "/" +
                          btoa(task.id.toString())
                          )*/
                  }}
                >
                  {(task.title === null || task.title === "") && (
                    <img src="/static_images/empty-dash.svg"></img>
                  )}
                  {task.title !== null && task.title.length > 25 ? (
                    <DarkTooltip title={task.title} placement="right-end">
                      <span
                        style={{ display: "initial", cursor: "pointer" }}
                        onClick={() =>
                          // window.open(
                          //   "/document/tasks/" +
                          //   btoa(task.contract.toString()) +
                          //   "/" +
                          //   btoa(task.id.toString()),
                          //   "_blank"
                          // )
                          history.push(
                            "/document/tasks/" +
                              btoa(task.contract.toString()) +
                              "/" +
                              btoa(task.id.toString())
                          )
                        }
                      >
                        {truncateString(task.title, 25)}
                      </span>
                    </DarkTooltip>
                  ) : (
                    <span
                      style={{ display: "initial", cursor: "pointer" }}
                      onClick={() =>
                        // window.open(
                        //   "/document/tasks/" +
                        //   btoa(task.contract.toString()) +
                        //   "/" +
                        //   btoa(task.id.toString()),
                        //   "_blank"
                        // )
                        history.push(
                          "/document/tasks/" +
                            btoa(task.contract.toString()) +
                            "/" +
                            btoa(task.id.toString())
                        )
                      }
                    >
                      {task.title}
                    </span>
                  )}
                </span>
              </div>
              <div id="contract-name" className="col-md-2">
                {(task.contract_file_name === null ||
                  task.contract_file_name === "") && (
                  <img src="/static_images/empty-dash.svg"></img>
                )}

                {task.contract_file_name !== null &&
                task.contract_file_name.length > 15 ? (
                  <DarkTooltip
                    title={task.contract_file_name}
                    placement="right-end"
                  >
                    <span
                      style={{ display: "initial", cursor: "pointer" }}
                      onClick={() =>
                        // window.open(
                        //   "/document/tasks/" + btoa(task.contract.toString()),
                        //   "_blank"
                        // )
                        history.push(
                          "/document/tasks/" + btoa(task.contract.toString())
                        )
                      }
                    >
                      {truncateFileName(task.contract_file_name)}
                    </span>
                  </DarkTooltip>
                ) : (
                  <span
                    style={{ display: "initial", cursor: "pointer" }}
                    onClick={() =>
                      // window.open(
                      //   "/document/tasks/" + btoa(task.contract.toString()),
                      //   "_blank"
                      // )
                      history.push(
                        "/document/tasks/" + btoa(task.contract.toString())
                      )
                    }
                  >
                    {task.contract_file_name}
                  </span>
                )}
              </div>
              <div id="clause-name" className="col-md-2">
                {task.contract_clause_type === null ||
                task.contract_clause_type === "" ? (
                  <img src="/static_images/empty-dash.svg"></img>
                ) : (
                  task.contract_clause_type
                )}
              </div>
              <div
                id="assigned-to"
                className="col-md-2"
                style={
                  task.assignees.length === 0
                    ? { display: "block", left: "1.1vw" }
                    : {}
                }
              >
                {task.assignees.length === 0 && (
                  <img src="/static_images/empty-dash.svg"></img>
                )}

                {task.assignees.length < 3 &&
                  task.assignees.map((user: AssigneesList, key: number) => (
                    <>
                      <DarkTooltip
                        title={`${user.first_name} ${user.last_name}`}
                        placement="bottom"
                      >
                        <div
                          className="avatar-circle-sm"
                          style={{
                            marginLeft: key > 0 ? "0.5rem" : "",
                            backgroundColor: "#e0e0e0",
                          }}
                        >
                          <span className="initials">
                            {user.first_name != ""
                              ? user.first_name[0].toUpperCase()
                              : ""}
                            {user.last_name != ""
                              ? user.last_name[0].toUpperCase()
                              : ""}
                          </span>
                        </div>
                      </DarkTooltip>
                    </>
                  ))}
                {task.assignees.length >= 3 &&
                  task.assignees.map((user: AssigneesList, key: number) =>
                    key < 3 ? (
                      <DarkTooltip
                        title={`${user.first_name} ${user.last_name}`}
                        placement="bottom"
                      >
                        <div
                          className="avatar-circle-sm"
                          style={{
                            marginLeft: key > 0 ? "0.5rem" : "",
                            backgroundColor: "#e0e0e0",
                          }}
                        >
                          <span className="initials">
                            {user.first_name != ""
                              ? user.first_name[0].toUpperCase()
                              : ""}
                            {user.last_name != ""
                              ? user.last_name[0].toUpperCase()
                              : ""}
                          </span>
                        </div>
                      </DarkTooltip>
                    ) : key === 3 ? (
                      <span
                        id="extra-count"
                        onClick={() =>
                          this.setModal(
                            true,
                            "Assigned to",
                            task.assignees.length,
                            task.assignees
                          )
                        }
                      >
                        {this.getExtraCount(task.assignees.length)}
                      </span>
                    ) : (
                      <> </>
                    )
                  )}
              </div>
              <div id="date" className="col-md-1">
                {task.due_date === null || task.due_date === "" ? (
                  <img src="/static_images/empty-dash.svg"></img>
                ) : (
                  getDueDateFormat(task.due_date)
                )}
              </div>
              <div id="status" className="col-md-1 p-0">
                {task.status === null || task.status === "" ? (
                  <img src="/static_images/empty-dash.svg"></img>
                ) : (
                  // task.status
                  this.getStatusData(task.status)
                )}
              </div>

              <div id="progress" className="col-md-2">
                <span
                  onClick={() =>
                    this.setState({
                      hideProgressOptions: !hideProgressOptions,
                      requestID: task.requestID,
                      id: task.id,
                    })
                  }
                >
                  {/* {task.progress} */}
                  {this.getProgressData(task.progress)}

                  {/* <img src="/static_images/expand-icn.svg" alt="img" /> */}
                  {executionQueue.findIndex((i) => i.id === task.id) > -1 ? (
                    <img src="/static_images/small-loader.svg" />
                  ) : (
                    <img src="/static_images/expand-icn.svg" alt="img" />
                  )}
                </span>

                {task.id === id && (
                  <div
                    id="progress-options-container"
                    hidden={hideProgressOptions}
                  >
                    {/* {JSON.stringify(progressNameList, null, 2)} */}
                    {progressNameList.map((progress, key) => (
                      <p
                        onClick={() => {
                          this.updateProgress(
                            task.requestID,
                            progress.id,
                            progress.name,
                            task.id
                          );
                          window.location.reload();
                        }}
                      >
                        {progress.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}
