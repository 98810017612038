import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AxiosGateWay from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { FieldName, initialFieldName } from "../../DataDictionaryState";
import { isRiverusAdmin } from "../../DDUtils";
import "../../Design/style.css";
interface Props {
  selectedSection: any | null;
  addField: any | null;
  deleteSection: any;
  contract_typeId: any;
  getDataType: any;
  dataType: any;
  getDefaultValue: any;
  setDefaultResponse: any;
  defaultResponse: any;
  sectionDetailsData: any;
  setAddField: any;
  postFieldName: any;
  deleteFieldName: any;
  updateFieldName: any;
  isDelete: any;
  setDelete: any;
  setClauseDrawer: any;
  setSectionData?: any | null;
  setAddSection: any;
  tabName: string;
  isAdmin: boolean;
}

export const DefaultFieldDataScreen = (props: Props) => {
  const [clauseNumber, setClauseNumber] = useState<any>([]);
  const [clauseData, setClauseData] = useState<any>([]);
  const [dynamicFields, setDynamicFields] = useState<any>([]);

  const [editMode, setEditMode] = useState<any>(false);

  const handleChange = (event: any) => {
    setClauseNumber(event.target.value);
  };

  const getClause = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/custom-clauselibrary/`;
    AxiosGateWay.get(URL)
      .then((response) => {
        setClauseData(response.data.results);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getClause();
  }, []);

  useEffect(() => {}, [editMode, clauseNumber, props.addField]);

  useEffect(() => {
    if (props?.addField?.isEdit) {
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  }, [props.selectedSection]);

  useEffect(() => {
    if (props?.addField?.data?.data_type?.custom_data_type === "Date") {
      const dateValue = props?.defaultResponse?.default_field_value
        ?.split("-")
        ?.reverse();
      props.setAddField({
        data: {
          ...props?.addField?.data,
          set_recommended_value: {
            default_field_unit: props?.defaultResponse?.default_field_unit,
            default_field_value: `${dateValue[0]}-${dateValue[2]}-${dateValue[1]}`,
          },
        },
        isEdit: props?.addField?.isEdit,
      });
    } else {
      props.setAddField({
        data: {
          ...props?.addField?.data,
          set_recommended_value: {
            default_field_unit: props?.defaultResponse?.default_field_unit,
            default_field_value: props?.defaultResponse?.default_field_value,
          },
        },
        isEdit: props?.addField?.isEdit,
      });
    }
  }, [props.defaultResponse]);
  useEffect(() => {}, [props.dataType]);

  function convert_number(number: any) {
    if (number < 0 || number > 999999999) {
      return "NUMBER OUT OF RANGE!";
    }
    var Gn = Math.floor(number / 10000000); /* Crore */
    number -= Gn * 10000000;
    var kn = Math.floor(number / 100000); /* lakhs */
    number -= kn * 100000;
    var Hn = Math.floor(number / 1000); /* thousand */
    number -= Hn * 1000;
    var Dn = Math.floor(number / 100); /* Tens (deca) */
    number = number % 100; /* Ones */
    var tn = Math.floor(number / 10);
    var one = Math.floor(number % 10);
    var res = "";

    if (Gn > 0) {
      res += convert_number(Gn) + " CRORE";
    }
    if (kn > 0) {
      res += (res == "" ? "" : " ") + convert_number(kn) + " LAKH";
    }
    if (Hn > 0) {
      res += (res == "" ? "" : " ") + convert_number(Hn) + " THOUSAND";
    }

    if (Dn) {
      res += (res == "" ? "" : " ") + convert_number(Dn) + " HUNDRED";
    }

    var ones = Array(
      "",
      "ONE",
      "TWO",
      "THREE",
      "FOUR",
      "FIVE",
      "SIX",
      "SEVEN",
      "EIGHT",
      "NINE",
      "TEN",
      "ELEVEN",
      "TWELVE",
      "THIRTEEN",
      "FOURTEEN",
      "FIFTEEN",
      "SIXTEEN",
      "SEVENTEEN",
      "EIGHTEEN",
      "NINETEEN"
    );
    var tens = Array(
      "",
      "",
      "TWENTY",
      "THIRTY",
      "FOURTY",
      "FIFTY",
      "SIXTY",
      "SEVENTY",
      "EIGHTY",
      "NINETY"
    );

    if (tn > 0 || one > 0) {
      if (!(res == "")) {
        res += " AND ";
      }
      if (tn < 2) {
        res += ones[tn * 10 + one];
      } else {
        res += tens[tn];
        if (one > 0) {
          res += "-" + ones[one];
        }
      }
    }

    if (res == "") {
      res = "zero";
    }
    props.addField.data.verbal_value = res;
    return res;
  }

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function convertDate(date_str: any) {
    if (date_str) {
      const temp_date = date_str?.split("-");
      props.addField.data.verbal_value =
        temp_date[2] +
        " " +
        months[Number(temp_date[1]) - 1] +
        " " +
        temp_date[0];

      return (
        temp_date[2] +
        " " +
        months[Number(temp_date[1]) - 1] +
        " " +
        temp_date[0]
      );
    }
    return "";
  }

  const recommendedField = (choice: any) => {
    switch (choice?.ui_component) {
      case "Text Box":
        if (
          choice?.data_type === "Numeric" ||
          choice?.data_type === "Currency Verbal"
        ) {
          return (
            <div className="tooltip-custom">
              <input
                disabled={editMode ? false : true}
                className={`${
                  editMode ? "form-control" : "border-0 bg-transparent"
                }`}
                value={
                  props.addField?.data?.set_recommended_value
                    ?.default_field_value
                    ? props.addField?.data?.set_recommended_value
                        ?.default_field_value
                    : ""
                }
                onChange={(e) => {
                  if (
                    choice?.data_type === "Numeric" ||
                    choice?.data_type === "Currency Verbal"
                  ) {
                    const pattern = /^[0-9]*$/;
                    if (pattern.test(e.target.value)) {
                      props.setAddField({
                        data: {
                          ...props.addField?.data,
                          set_recommended_value: {
                            ...props.addField?.data?.set_recommended_value,
                            default_field_value: e.target.value,
                          },
                        },
                        isEdit: props.addField.isEdit,
                      });
                    }
                  }
                }}
              />
              {choice?.data_type === "Currency Verbal" ? (
                <span style={{ fontSize: "12px" }}>
                  {convert_number(
                    props?.addField?.data?.set_recommended_value
                      ?.default_field_value
                  ) !== "zero"
                    ? convert_number(
                        props?.addField?.data?.set_recommended_value
                          ?.default_field_value
                      )
                    : ""}
                </span>
              ) : null}
            </div>
          );
        } else {
          return (
            <div className="tooltip-custom w-100">
              {editMode ? (
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  multiline
                  minRows={5}
                  variant="outlined"
                  className={`${
                    editMode ? "form-control" : "border-0 bg-transparent"
                  }`}
                  value={
                    props.addField?.data?.set_recommended_value
                      ?.default_field_value
                      ? props.addField?.data?.set_recommended_value
                          ?.default_field_value
                      : ""
                  }
                  onChange={(e) => {
                    if (choice?.data_type?.custom_data_type === "Numeric") {
                      const pattern = /^[0-9]*$/;
                      if (pattern.test(e.target.value)) {
                        props.setAddField({
                          data: {
                            ...props.addField?.data,
                            set_recommended_value: {
                              ...props.addField?.data?.set_recommended_value,
                              default_field_value: e.target.value,
                            },
                          },
                          isEdit: props.addField.isEdit,
                        });
                      }
                    } else {
                      props.setAddField({
                        data: {
                          ...props.addField?.data,
                          set_recommended_value: {
                            ...props.addField?.data?.set_recommended_value,
                            default_field_value: e.target.value,
                          },
                        },
                        isEdit: props.addField.isEdit,
                      });
                    }
                  }}
                />
              ) : (
                <Scrollable maxHeight={100}>
                  {" "}
                  <p>{`${
                    props.addField?.data?.set_recommended_value
                      ?.default_field_value
                      ? props.addField?.data?.set_recommended_value
                          ?.default_field_value
                      : ""
                  }`}</p>
                </Scrollable>
              )}
            </div>
          );
        }
      case "Check Box":
        return (
          <div>
            <div>
              <input
                disabled={editMode ? false : true}
                type="radio"
                name="radio"
                value={
                  props?.addField?.data?.set_recommended_value
                    ?.default_field_value
                }
                onChange={(e) => {
                  props.setAddField({
                    data: {
                      ...props.addField?.data,
                      set_recommended_value: {
                        ...props.addField?.data?.set_recommended_value,
                        default_field_value: true,
                      },
                    },
                    isEdit: props.addField.isEdit,
                  });
                }}
              />
              <label className="ml-1">True</label>
            </div>
            <div>
              <input
                disabled={editMode ? false : true}
                type="radio"
                name="radio"
                value={
                  props?.addField?.data?.set_recommended_value
                    ?.default_field_value
                }
                onChange={(e) => {
                  props.setAddField({
                    data: {
                      ...props.addField?.data,
                      set_recommended_value: {
                        ...props.addField?.data?.set_recommended_value,
                        default_field_value: false,
                      },
                    },
                    isEdit: props.addField.isEdit,
                  });
                }}
              />
              <label className="ml-1">False</label>
            </div>
          </div>
        );
      case "Date Component":
        return (
          <div className="tooltip-custom">
            <input
              disabled={editMode ? false : true}
              type="date"
              className={`${
                editMode ? "form-control" : "border-0 bg-transparent"
              }`}
              value={
                props.addField?.data?.set_recommended_value?.default_field_value
              }
              onChange={(e) => {
                props.setAddField({
                  data: {
                    ...props.addField?.data,
                    set_recommended_value: {
                      ...props.addField?.data?.set_recommended_value,
                      default_field_value: e.target.value,
                    },
                  },
                  isEdit: props.addField.isEdit,
                });
              }}
            />
            {choice?.data_type === "Date Verbal" ? (
              <span style={{ fontSize: "12px" }}>
                {convertDate(
                  props?.addField?.data?.set_recommended_value
                    ?.default_field_value
                )}
              </span>
            ) : null}
          </div>
        );
      case "Drop Down":
      case "Text Box & Drop Down":
      case "Table":
        return (
          <div className="mt-2">
            <div className="row m-0 d-flex align-items-center justify-content-between">
              <input
                disabled={editMode ? false : true}
                type="text"
                className={`${
                  editMode ? "form-control col-md-9" : "border-0 bg-transparent"
                }`}
                value={dynamicFields}
                onChange={(e) => {
                  setDynamicFields(e.target.value);
                }}
              />
              {editMode ? (
                <button
                  disabled={editMode ? false : true}
                  className="col-md-2 mb-0 cursor-pointer btn btn-warning create-btn"
                  onClick={() => {
                    if (
                      props.addField?.data?.set_recommended_value
                        ?.default_field_value?.length
                    ) {
                      props.setAddField({
                        data: {
                          ...props.addField?.data,
                          set_recommended_value: {
                            ...props.addField?.data?.set_recommended_value,
                            default_field_value: [
                              ...props.addField?.data?.set_recommended_value
                                ?.default_field_value,
                              dynamicFields,
                            ],
                          },
                        },
                        isEdit: props.addField.isEdit,
                      });
                    } else {
                      props.setAddField({
                        data: {
                          ...props.addField?.data,
                          set_recommended_value: {
                            ...props.addField?.data?.set_recommended_value,
                            default_field_value: [dynamicFields],
                          },
                        },
                        isEdit: props.addField.isEdit,
                      });
                    }
                    setDynamicFields("");
                  }}
                >
                  Add
                </button>
              ) : null}
            </div>
            <div className="mt-2">
              {props?.addField?.data?.set_recommended_value?.default_field_value
                ? props?.addField?.data?.set_recommended_value
                    ?.default_field_value.length
                  ? props?.addField?.data?.set_recommended_value?.default_field_value?.map(
                      (item: any, index: any) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p className="mb-0">
                              {props?.addField?.data?.data_type ===
                              "Numbered List" ? (
                                <span>{index + 1}.&nbsp;</span>
                              ) : (
                                <span>&#8226;&nbsp;</span>
                              )}
                              {item}
                            </p>
                            <img
                              src="/static_images/Delete_upload.svg"
                              className="cursor-pointer"
                              onClick={() => {
                                if (editMode) {
                                  const updatedArray =
                                    props?.addField?.data?.set_recommended_value?.default_field_value?.filter(
                                      (item: any, i: any) => index !== i
                                    );
                                  props.setAddField({
                                    data: {
                                      ...props.addField?.data,
                                      set_recommended_value: {
                                        ...props.addField?.data
                                          ?.set_recommended_value,
                                        default_field_value: updatedArray,
                                      },
                                    },
                                    isEdit: props.addField.isEdit,
                                  });
                                }
                              }}
                            />
                          </div>
                        );
                      }
                    )
                  : null
                : null}
            </div>
          </div>
        );

      default:
        return (
          <>
            {editMode ? (
              <TextField
                fullWidth
                id="outlined-multiline-static"
                multiline
                minRows={5}
                variant="outlined"
                className={`${
                  editMode ? "form-control" : "border-0 bg-transparent"
                }`}
                value={
                  props?.addField?.data?.set_recommended_value
                    ?.default_field_value
                    ? props?.addField?.data?.set_recommended_value
                        ?.default_field_value
                    : ""
                }
              />
            ) : (
              <Scrollable maxHeight={100}>
                {" "}
                <p>{`${
                  props.addField?.data?.set_recommended_value
                    ?.default_field_value
                    ? props.addField?.data?.set_recommended_value
                        ?.default_field_value
                    : ""
                }`}</p>
              </Scrollable>
            )}
          </>
        );
    }
  };

  const camelize = (str: any) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  return (
    <>
      <div className="section_Field_Data">
        {props.selectedSection ? (
          <div className="row">
            <div className="col-md-5">
              <h5>Section Description</h5>
              <p className="c-grey f-14">
                {props.selectedSection?.section_description}
              </p>
            </div>
            <div className="col-md-4">
              <h5>Section Tag</h5>
              <p className="c-grey f-14">
                {props.selectedSection?.section_tag}
              </p>
            </div>
            <div className="col-md-3">
              <button
                disabled={
                  props.tabName === "default"
                    ? isRiverusAdmin()
                      ? false
                      : true
                    : props.isAdmin
                    ? false
                    : true
                }
                className={`delete-field-icon cursor-pointer  ${
                  props.tabName === "default"
                    ? isRiverusAdmin()
                      ? ""
                      : "disabled-buttons"
                    : props.isAdmin
                    ? ""
                    : "disabled-buttons"
                }`}
                data-toggle="modal"
                data-target="#notAvailable"
                style={{
                  boxShadow: "none",
                  border: "0px",
                }}
                onClick={() => props.setDelete(true)}
              >
                <img
                  height={19}
                  src="/static_images/delete-field-icon.svg"
                ></img>
              </button>
              <button
                style={{
                  boxShadow: "none",
                  border: "0px",
                }}
                disabled={
                  props.tabName === "default"
                    ? isRiverusAdmin()
                      ? false
                      : true
                    : props.isAdmin
                    ? false
                    : true
                }
                className={`delete-field-icon cursor-pointer mr-3  ${
                  props.tabName === "default"
                    ? isRiverusAdmin()
                      ? ""
                      : "disabled-buttons"
                    : props.isAdmin
                    ? ""
                    : "disabled-buttons"
                }`}
                data-toggle="modal"
                data-target="#notAvailable"
                onClick={() => {
                  props.setAddSection(true);
                  props.setSectionData(props.selectedSection);
                }}
              >
                <img
                  height={19}
                  src="/static_images/edit-filter-icon.svg"
                ></img>
              </button>
            </div>
          </div>
        ) : null}
        {props.selectedSection ? (
          props.addField?.data ? (
            <div className="section_field_container">
              <div className="field-container">
                <div className="row align-items-center field-header m-0">
                  <span className="col-md-4 fw">
                    {props.addField?.data?.display_name}
                  </span>
                  <div className="col-md-8 row align-items-center">
                    <span className="col fw" style={{ textAlign: "end" }}>
                      Section
                    </span>
                    <select
                      disabled={editMode ? false : true}
                      name="section"
                      id="field-header-input"
                      onChange={(e: any) => {
                        props.setAddField({
                          data: {
                            ...props.addField.data,
                            section_id: e.target.value,
                          },
                          isEdit: props.addField.isEdit,
                        });
                      }}
                    >
                      <option value={props.selectedSection.section_id} selected>
                        {props.selectedSection.section_name}
                      </option>
                      {props.sectionDetailsData.map((item: any) => (
                        <option value={item.section_id}>
                          {item.section_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row justify-content-between m-0 mt-3">
                  <div className="col-md-4">
                    <p className="m-0 c-primary fw mb-1">Display Name</p>
                    <input
                      style={{
                        borderColor: "#88305F",
                        borderWidth: "1px",
                      }}
                      disabled={editMode ? false : true}
                      type="text"
                      className={`${
                        editMode ? "form-control" : "border-0 bg-transparent"
                      }`}
                      value={props.addField?.data?.display_name}
                      onChange={(e) => {
                        props.setAddField({
                          data: {
                            ...props.addField?.data,
                            display_name: e.target.value,
                            field_name: `{${camelize(e.target.value)}}`,
                          },
                          isEdit: props.addField.isEdit,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-5">
                    <p className="m-0 c-primary fw mb-1">Field Name</p>
                    <input
                      disabled={true}
                      type="text"
                      className={`${
                        editMode ? "form-control" : "border-0 bg-transparent"
                      }`}
                      style={{
                        borderColor: "#88305F",
                        borderWidth: "1px",
                      }}
                      value={props.addField?.data?.field_name}
                    />
                  </div>
                  <div className="col-md-3">
                    <p className="m-0 c-primary fw mb-1">Required ?</p>
                    <Switch
                      disabled={editMode ? false : true}
                      value={props.addField?.data?.required}
                      checked={props.addField?.data?.required}
                      onChange={(e) => {
                        props.setAddField({
                          data: {
                            ...props.addField?.data,
                            required: e.target.checked,
                          },
                          isEdit: props.addField.isEdit,
                        });
                      }}
                    />
                  </div>
                </div>
                {editMode ? (
                  <div className="row m-0 mt-3">
                    <div className="col-md-4">
                      <p className="m-0 c-primary fw mb-1">Data Type</p>

                      <FormControl
                        className="col"
                        size="small"
                        variant="outlined"
                      >
                        <Select
                          disabled={editMode ? false : true}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={props?.addField?.data?.data_type}
                          onChange={(e) => {
                            const value: any = e.target.value;
                            const dataTypeValue = props.dataType.filter(
                              (item: any) => item.custom_data_type === value
                            );
                            props.getDefaultValue(
                              dataTypeValue[0].custom_data_type
                            );
                            props.setAddField({
                              data: {
                                ...props.addField.data,
                                data_type: dataTypeValue[0].custom_data_type,
                                ui_component: dataTypeValue[0].ui_component,
                                data_type_id: dataTypeValue[0].id,
                              },
                              isEdit: props.addField.isEdit,
                            });
                          }}
                        >
                          {props?.dataType?.map((item: any) => {
                            return (
                              <MenuItem value={item?.custom_data_type}>
                                {item.custom_data_type}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-md-5">
                      <p className="m-0 c-primary fw mb-1">Field Validation</p>
                      <input
                        disabled={editMode ? false : true}
                        type="text"
                        className="form-control"
                        style={{
                          borderColor: "#88305F",
                          borderWidth: "1px",
                        }}
                        value={props.addField?.data?.field_validation}
                        onChange={(e) => {
                          props.setAddField({
                            data: {
                              ...props.addField.data,
                              field_validation: e.target.value,
                            },
                            isEdit: props.addField.isEdit,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                <div className="row m-0 justify-content-between mt-3 align-items-start mb-3">
                  <div className="col-md-4">
                    <p className="m-0 c-primary fw mb-1">Description</p>
                    {!editMode ? (
                      <Scrollable maxHeight={100}>
                        <p style={{ color: "#4C4C4C" }}>
                          {props.addField?.data?.description}
                        </p>
                      </Scrollable>
                    ) : (
                      <TextField
                        fullWidth
                        id="outlined-multiline-static"
                        multiline
                        minRows={3}
                        variant="outlined"
                        value={props.addField?.data?.description}
                        onChange={(e) => {
                          props.setAddField({
                            data: {
                              ...props.addField.data,
                              description: e.target.value,
                            },
                            isEdit: props.addField.isEdit,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className={`${editMode ? "col-md-5" : "col-md-4"}`}>
                    <p className="m-0 c-primary fw mb-1">
                      Set Recommended Value
                    </p>

                    {recommendedField(props.addField.data)}
                  </div>
                  {editMode ? (
                    props?.addField?.data?.data_type === "Text" ||
                    props?.addField?.data?.data_type === "Text Box" ? (
                      <div className="col-md-3" style={{ marginTop: "30px" }}>
                        <button
                          type="button"
                          className="btn btn-warning create-btn"
                          onClick={() => {
                            props.setClauseDrawer(true);
                          }}
                        >
                          Use Existing Clause
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-3"></div>
                    )
                  ) : (
                    <div className="col-md-4"></div>
                  )}
                </div>
                <div className="row m-0 mb-3">
                  {!editMode ? <div className="col-md-6"></div> : null}
                  <div className="col-md-6">
                    <div className="w-100 d-flex">
                      {editMode ? (
                        <button
                          disabled={
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? false
                                : true
                              : props.isAdmin
                              ? false
                              : true
                          }
                          onClick={() => {
                            if (props.addField.isEdit) {
                              props.deleteFieldName(
                                props.addField.data.field_id,
                                props.contract_typeId
                              );
                            } else {
                              props.setAddField({
                                data: null,
                                isEdit: props.addField.isEdit,
                              });
                            }
                          }}
                          style={{ color: "#FF685F" }}
                          type="button"
                          className="btn btn-warning field-btn"
                        >
                          Delete Field
                        </button>
                      ) : (
                        <div className="w-100"></div>
                      )}
                      {!editMode ? (
                        <button
                          disabled={
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? false
                                : true
                              : props.isAdmin
                              ? false
                              : true
                          }
                          onClick={() => {
                            if (editMode) {
                              setEditMode(false);
                            } else {
                              setEditMode(true);
                            }
                          }}
                          style={{ color: "#FF685F" }}
                          type="button"
                          className={`${
                            editMode
                              ? "btn btn-warning create-btn ml-3"
                              : "btn btn-warning create-btn mr-0"
                          }  ${
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? ""
                                : "disabled-buttons"
                              : props.isAdmin
                              ? ""
                              : "disabled-buttons"
                          }`}
                        >
                          Edit Field
                        </button>
                      ) : (
                        <div className="col-md-4"></div>
                      )}
                    </div>
                  </div>

                  {editMode ? (
                    <div className="col-md-4 row justify-content-between">
                      <div className="w-100 d-flex">
                        <button
                          disabled={
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? false
                                : true
                              : props.isAdmin
                              ? false
                              : true
                          }
                          type="button"
                          className="btn btn-warning field-btn "
                          onClick={() =>
                            props.setAddField({
                              data: initialFieldName,
                              isEdit: props.addField.isEdit,
                            })
                          }
                        >
                          Reset Changes
                        </button>
                        <button
                          disabled={
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? false
                                : true
                              : props.isAdmin
                              ? false
                              : true
                          }
                          onClick={() => {
                            let payload: any = {
                              creation_type: isRiverusAdmin()
                                ? "system"
                                : "custom",
                              edit_for_all: false,
                              field_name: props.addField.data.field_name,
                              display_name: props.addField.data.display_name,
                              data_type: [props.addField.data.data_type_id],
                              field_validation:
                                props.addField.data.field_validation,
                              description: props.addField.data.description,
                              required: props.addField.data.required,
                              section_dictionary: [
                                props.addField.data.section_id,
                              ],
                              default_field_value: props.addField.data
                                .verbal_value
                                ? props.addField.data.verbal_value
                                : props.addField.data.set_recommended_value
                                    .default_field_value,
                              default_field_unit:
                                props.addField.data.set_recommended_value
                                  .default_field_unit,
                              set_recommended_value: {
                                default_field_unit:
                                  props.addField.data.set_recommended_value
                                    .default_field_unit,
                                default_field_value: props.addField.data
                                  .verbal_value
                                  ? props.addField.data.verbal_value
                                  : props.addField.data.set_recommended_value
                                      .default_field_value,
                              },
                            };

                            if (props.addField.isEdit) {
                              if (payload.data_type[0]) {
                                props.updateFieldName(
                                  payload,
                                  props.addField.data.field_id,
                                  props.contract_typeId
                                );
                              } else {
                                const dd = props.dataType.filter(
                                  (item: any) =>
                                    item.custom_data_type ===
                                    props.addField.data.data_type
                                );
                                payload.data_type = [dd[0].id];

                                props.updateFieldName(
                                  payload,
                                  props.addField.data.field_id,
                                  props.contract_typeId
                                );
                              }
                            } else {
                              props.postFieldName(
                                payload,
                                props.contract_typeId
                              );
                            }

                            setEditMode(false);
                          }}
                          style={{ padding: "0 30px" }}
                          type="button"
                          className={`btn btn-warning create-btn  ${
                            props.tabName === "default"
                              ? isRiverusAdmin()
                                ? ""
                                : "disabled-buttons"
                              : props.isAdmin
                              ? ""
                              : "disabled-buttons"
                          }`}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row"></div>
              </div>
            </div>
          ) : null
        ) : (
          <div className="d-flex justify-content-center flex-column align-items-center mt-5">
            <img
              height={300}
              src="/static_images/no_section_background.png"
            ></img>
            <span
              style={{ color: "#818181", fontSize: "16px", fontWeight: 700 }}
            >
              Click on Section/ Field names to view details
            </span>
            <p
              style={{ fontSize: "30px" }}
            >{`It contains ${props.sectionDetailsData.length} sections`}</p>
          </div>
        )}
      </div>
    </>
  );
};
