import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import {
  deleteCompareVersion,
  deleteDraft,
  deleteS3Draft,
  deletesupportdocsingle,
} from "../DA";
import { ContractRes } from "../State/DraftState";

interface Props {
  onClose: () => void;
  open: boolean;
  getDrafts: () => void;
  selectedDraft: any;
  for: any;
}

const DeleteDraftDialog = (props: Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [req, setreq] = useState<any>([]);
  const [draft, setdraft] = useState<any>([]);

  useEffect(() => {}, [req, draft]);
  const APIConfig = () => {
    return {
      headers: {
        Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
      },
    };
  };
  useEffect(() => {
    const filter = props.selectedDraft?.filter((i: any) => {
      return i.docID?.includes("REQ");
    });

    setreq(filter);
    const filter2 = props.selectedDraft?.filter((i: any) => {
      return i.docID?.includes("STC");
    });
    setdraft(filter2);
  }, [props.selectedDraft]);

  useEffect(() => {
    setopen(props.open);
    setloading(false);
  }, [props.open]);
  const handleClose = () => {
    setopen(false);
    setreq([]);
    setdraft([]);
    props.onClose();
  };
  const handleSubmit = async () => {
    setloading(true);
    if (req.length) {
      const req_data = req?.map((i: any) => i?.id);
      const data: any = {
        delete_list: req_data,
      };
      await axios
        .delete(
          process.env.REACT_APP_RIVERUS_CLM_API + `stylus/requisition_form/`,
          {
            headers: {
              Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
            },
            data,
          }
        )
        .then((response: any) => {
          setreq([]);
          props.onClose();
          setTimeout(() => {
            props.getDrafts();
          }, 500);
        })
        .catch((err) => {
          props.onClose();
          setTimeout(() => {
            props.getDrafts();
          }, 500);
        });
    }
    if (draft.length) {
      const draft_data = draft?.map((i: any) => i?.id);
      const data: any = {
        delete_list: draft_data,
      };
      await axios
        .delete(process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/`, {
          headers: {
            Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
          },
          data,
        })
        .then((response) => {
          setreq([]);
          setdraft([]);
          props.onClose();

          setTimeout(() => {
            props.getDrafts();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
          props.onClose();
          setTimeout(() => {
            props.getDrafts();
          }, 500);
        });
    }
    if (props.for === "versionhistory") {
      const deleteID = props.selectedDraft?.map((item: any) => item?.id);
      const data: any = {
        delete_list: deleteID,
      };
      await deleteCompareVersion(data);
      setreq([]);
      setdraft([]);
      props.onClose();
      setTimeout(() => {
        props.getDrafts();
      }, 500);
    }
    if (props.for === "supportdialogue") {
      const deleteID = props.selectedDraft?.map((item: any) => item.id);
      const data: any = {
        delete_list: deleteID,
      };
      await deletesupportdocsingle(deleteID);
      props.onClose();
      setloading(false);
      setTimeout(() => {
        props.getDrafts();
      }, 500);
    }
  };
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle className="riverus-dialog-header">
        <div className="row">
          <div className="col-md-11">Delete Draft</div>
          <div onClick={handleClose} className="col-md-1 close-icn">
            <img
              src="/static_images/close-analysismodal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {loading && <CircularProgress className="loader" />}
        <div className="row">
          <div className="col-md-12">
            <p>Are you sure you want to delete these drafts ?</p>
            <ol>
              {props.selectedDraft.length > 0 &&
                props.selectedDraft.map((draft: any, index: any) => {
                  console.log(draft, "fknfnfkfnjfn jf");
                  return (
                    <li style={{ listStyle: "auto" }} key={index}>
                      {draft?.compare_versions
                        ? draft?.compare_versions?.visible_version
                        : draft.contractName
                        ? draft.contractName
                        : draft?.file_name}
                    </li>
                  );
                })}
            </ol>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
        >
          Cancel
        </Button>
        <Button className="create-btn" onClick={handleSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDraftDialog;
