import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import WebViewer from "@pdftron/webviewer";
import axios from "axios";
import FileSaver from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import LinesLoader from "../../UniversalComponents/Loader/linesLoader";
import { getsupportdocbyid, getSupportDocs } from "../DA";
interface Props {
  supportdoc: any;
}
interface Pdfprop {
  link: any;
}

export default function SupportDocTab(props: Props) {
  const [showpdfview, setshowpdfview] = useState(false);
  const [supportdocdata, setsupportdocdata] = useState<any>();
  const [loader, setLoader] = useState(true);
  const [second, setsecond] = useState<any>();
  const [secondcore, setsecondcore] = useState<any>();
  const [supportdoc, setsupportdoc] = useState<any>([]);
  const documentContainer = useRef<any>(null);

  const handleshowpdfviewer = async (data: any) => {
    const response = await getsupportdocbyid(data.id);
    setsupportdocdata(response);
    setshowpdfview(true);
  };
  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  useEffect(() => {
    // setFileLink(props.link)
    // if (documentContainer.current != null) {
    if (showpdfview) {
      WebViewer(
        {
          path: "/webviewer",
          disabledElements: ["header", "toolsHeader"],
        },
        documentContainer.current
      ).then((instance: any) => {
        const { UI, Core } = instance;
        setsecond(instance);
        const { documentViewer, Annotations, annotationManager, Tools } = Core;
        setsecondcore(Core);
        setLoader(false);
        documentViewer.addEventListener("documentLoaded", () => {
          // call methods relating to the loaded document
        });
        instance.UI.loadDocument(supportdocdata.access_url);
      });
    }
    // }
  }, [showpdfview]);
  useEffect(() => {
    const getsupportdocument = async () => {
      const response = await getSupportDocs(props.supportdoc);
      setsupportdoc(response?.results);
    };
    getsupportdocument();
  }, []);

  useEffect(() => {
    if (!loader) {
      secondcore.documentViewer.addEventListener("documentLoaded", () => {
        // call methods relating to the loaded document
      });
      second.UI.loadDocument(supportdocdata.access_url);
    }
  }, [supportdocdata]);
  const handleChangeselect = async (event: any) => {
    const filter = supportdoc?.filter(
      (item: any) => item.id === event.target.value
    )[0];
    const response = await getsupportdocbyid(filter.id);
    setsupportdocdata(response);
  };
  function downloadFile(link: string, filename: any) {
    FileSaver.saveAs(link, filename);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  return (
    <>
      {!showpdfview ? (
        <div className="support-doc-2 mt-4">
          {false && <CircularProgress className="loader" />}
          <div className="row align-items-center">
            <div className="col-md-4 df align-items-center">
              <p className="m-0 ml-3 fw" style={{ fontSize: "16px" }}>
                Support Docs
              </p>
            </div>
          </div>
          <div
            className="row justify-content-between mt-3"
            style={{ height: "33px" }}
          >
            <div className="col-md-4 mb-2">
              <div className="df mt-1" style={{ justifyContent: "end" }}></div>
            </div>
          </div>
          <div className="row list-view-header support-doc__table-header">
            <div className="col-md-3">
              Name
              <span>
                <img
                  //   onClick={() => applySort("name", "string")}
                  src="static_images/number_filter_inactive.svg"
                />
              </span>
            </div>
            <div className="col-md-3">
              Type
              <span className="ml-2">
                <img
                  //   onClick={() => applySort("type", "string")}
                  src="static_images/calender_filter_inactive.svg"
                />
              </span>
            </div>
            <div className="col-md-3">
              Uploaded On{" "}
              <span>
                <img
                  //   onClick={() => applySort("created_on", "date")}
                  src="static_images/calender_filter_inactive.svg"
                />
              </span>
            </div>
          </div>
          {supportdoc?.length > 0 &&
            supportdoc?.map((doc: any, index: number) => (
              <div key={index}>
                <div className="row list-view-header support-doc__table-header">
                  <div className="col-md-3 c-grey d-flex justify-content-start align-items-center">
                    <Tooltip title={doc.file_name} arrow>
                      <div className="text-overflow ">{doc.file_name}</div>
                    </Tooltip>
                  </div>
                  <div className="col-md-3 c-grey">{doc.document_type}</div>
                  <div
                    className="col-md-3 d-flex align-items-center"
                    style={{ gap: "50px" }}
                  >
                    {new Date(doc.created_on).toLocaleDateString()}
                  </div>
                  <div className="col-md-3 d-flex align-items-center">
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ gap: "20px" }}
                    >
                      <span>
                        <img
                          src="static_images/document-view-icn.svg"
                          onClick={() => handleshowpdfviewer(doc)}
                        />
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          downloadFile(doc.link, doc.file_name);
                        }}
                      >
                        <img
                          src="/static_images/file-download.svg"
                          alt="download"
                          style={{
                            // paddingTop: "10px",
                            // marginRight: "7px",
                            width: "20px",
                            height: "15px",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : null}

      {showpdfview ? (
        <>
          <div
            className="d-flex flex-row-reverse mt-5"
            style={{ maxHeight: "0.01px", cursor: "pointer" }}
          >
            <div
              className="col-md-1"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setshowpdfview(false);
              }}
            >
              <img
                src="/static_images/remove-document-view.svg"
                onClick={() => {
                  setshowpdfview(false);
                }}
                style={{ cursor: "pointer", width: "16px" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="group-3831">
              <span className="text ml-3">Select Another Support Document</span>
            </div>
          </div>
          <FormControl
            size="small"
            variant="outlined"
            className="riverus-text-box"
            fullWidth={true}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={supportdocdata?.id}
              onChange={handleChangeselect}
              fullWidth={true}
              style={{ marginTop: "10px" }}
              // defaultValue={supportdocdata?.id}
              // disabled={props.fromTemplate || props.isEdit}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 240,
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {supportdoc?.map((i: any, index: number) => {
                return (
                  <MenuItem
                    style={{ whiteSpace: "initial" }}
                    value={i?.id}
                    key={index}
                  >
                    {i?.file_name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <select
                defaultValue={supportdocdata?.id}
                onChange={handleChangeselect}
                className="ml-3"
              >
                {props.supportdoc?.map((i: any) => {
                  return (
                    <option value={i.id} selected>
                      {i.file_name}
                    </option>
                  );
                })}
              </select> */}
          <div className="mt-20">
            {/* {this.state.selectedMenu.pdfview ? ( */}
            {/* <DocumentPdftronView link={supportdocdata} /> */}
            {/* // ) : null} */}
            {/* {loader ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <LinesLoader
                  animatedLines={[
                    {
                      width: 100,
                      height: 42,
                      marginTop: "5px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      ) : null} */}

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="row">
                  <div
                    ref={documentContainer}
                    className="col-md-12  "
                    id="viewer"
                    style={{ height: "1000px" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
