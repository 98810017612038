import React from "react";
import { Card, Badge, Table, Row, Col } from "react-bootstrap";
const GeneralDefinitionsRender = (props: any) => {
  const { insightParas } = props;
  const {
    scrollToDatapoint,
    UpDownButtonsRender,
    scrollToHighlightedDatapoint,
    getCustomChildLabelName,
    getHighlightedTextFromDP,
    getHighlightedPara,
    getSmallSentence,
  } = props;
  const { children, dataPoints } = props.insight;

  const label = "List Of Defined Terms";
  let idx = 1;
  const totalInstances = children[0].childValue.length;
  const definitions: any = {};
  for (let i = 0; i < totalInstances; i++) {
    const definedWord = children[0].childValue[i].dataPoints.definedWord;
    if (Array.isArray(definitions[definedWord])) {
      definitions[definedWord].push(children[0].childValue[i].dataPoints);
    } else {
      definitions[definedWord] = [];

      definitions[definedWord].push(children[0].childValue[i].dataPoints);
    }
  }

  return (
    <Card>
      <Card.Header
        className={
          "small-card-header light-pink-background card-toc-row toc-row-default " +
          (totalInstances === 0 ? "zero-instance-header" : "")
        }
        style={{ marginLeft: 0 }}
      >
        <Row style={{ paddingRight: 7 }}>
          <Col className="capitalize" style={{ fontSize: 15 }} md={8}>
            {label}&nbsp;
            {totalInstances > 0 && (
              <Badge className="riverus-yellow-btn ">{totalInstances}</Badge>
            )}
          </Col>
          {totalInstances ? (
            totalInstances > 1 ? (
              <Col style={{ textAlign: "right", paddingLeft: 0 }}>
                <UpDownButtonsRender dataPoints={dataPoints} />
              </Col>
            ) : (
              <Col style={{ textAlign: "right" }}>
                <span
                  className="riverus-yellow-btn"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    scrollToDatapoint(dataPoints[0]);
                  }}
                >
                  View
                </span>
              </Col>
            )
          ) : (
            <Col style={{ textAlign: "right" }}>
              <Badge className="riverus-yellow-btn">{totalInstances}</Badge>
            </Col>
          )}
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Table striped hover responsive size="sm">
              <tbody>
                {Object.keys(definitions).map((key, i) => {
                  return (
                    <tr key={i}>
                      <td>{key}</td>
                      <td className="right-align">
                        {definitions[key].length > 1 ? (
                          <UpDownButtonsRender dataPoints={definitions[key]} />
                        ) : (
                          <span
                            className="riverus-yellow-btn"
                            style={{ fontSize: "13px" }}
                            onClick={() => {
                              scrollToDatapoint(definitions[key][0]);
                            }}
                          >
                            View
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default GeneralDefinitionsRender;
