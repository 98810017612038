import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomChip from "../../../UniversalComponents/CustomChip/Component/CustomChip";
import HeimdallUtil from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
export default function SubSectionListView(props: any) {
  const [clauseSource, setClauseSource] = useState("");

  return (
    <>
      <div className="col-md-5 sub-section-list-view">
        <div className="d-flex">
          <div>
            <img
              className="clause-folder-icn"
              onClick={() => {
                props.updateCheckedClause(props.sectionClauseData?.id);
              }}
              src={`${
                props.checkedClause.filter(
                  (item: any) => item === props.sectionClauseData.id
                ).length
                  ? "/static_images/checkbox-section.svg"
                  : "/static_images/uncheck-section.svg"
              }`}
              alt="clause-folder"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className="clause-folder-icn"
              src={`${
                props.sectionClauseData.clause_type === "recommended"
                  ? "/static_images/sub-section-success.svg"
                  : "/static_images/avoidable-icon.svg"
              }`}
              alt="clause-folder"
            />
            <Tooltip
              color="black"
              title={props.sectionClauseData.clause_name}
              arrow
            >
              <label
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
                className="m-0 cursor-pointer"
                onClick={() => {
                  props.selectedClause(props.sectionClauseData);
                }}
              >
                {props.sectionClauseData.clause_name}
              </label>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className="m-0 d-flex align-items-center justify-content-between">
          <p className="section-label m-0">
            {props.sectionClauseData.description}
          </p>
          <Tooltip
            color="black"
            title={props.sectionClauseData.clause_text}
            arrow
          >
            <img
              style={{ height: "16px", width: "16px", marginLeft: "20px" }}
              src="/static_images/section-tooltip.svg"
              alt="clause-folder"
            />
          </Tooltip>
        </div>
      </div>
      <div className="col-md-2 w-100 d-flex justify-content-start">
        <div className=" section-created-by">
          <p className="m-0">
            {HeimdallUtil.getNameForAvatar(props.sectionClauseData.created_by)}
          </p>
        </div>
      </div>
      <p
        className="col-md-1 m-0"
        style={{
          color: "#6D6D6D",
          fontSize: "14px",
          textAlign: "center",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        <Tooltip
          color="black"
          title={`${getDate(props.sectionClauseData.modified_on)}`}
          className="cursor-pointer"
          arrow
        >
          <p
            className="mb-0"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {getDate(props.sectionClauseData.modified_on)}
          </p>
        </Tooltip>
      </p>
      <div className="col-md-2">
        <div className="row">
          <Tooltip
            className="cursor-pointer"
            title={
              props?.sectionClauseData?.tags
                .map((tag: any) => tag.name)
                .join(", ") || ""
            }
            arrow
          >
            <div className="col-md-9">
              <CustomChip
                backgroundColor="#D8F7E6"
                textColor="#00692A"
                data={props?.sectionClauseData?.tags.map(
                  (tag: any) => tag.name
                )}
                showItems={1}
                addFilter={(tag) =>
                  props.applyFilter([tag.toLowerCase()], "tags")
                }
                isCancel={
                  props.filterKeyVals["tags"]
                    ? props.filterKeyVals["tags"].length
                      ? true
                      : false
                    : false
                }
                cancelAction={() => props.applyFilter([], "tags")}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
}
