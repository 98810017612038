import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Snackbar,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import React, { useEffect, useRef, useState } from "react";
import FileSaver, { saveAs } from "file-saver";
import DeviationButtonmodal from "./DeviationButtonmodal";
import DeviationRequestBtnmodal from "./DeviationRequestBtnmodal";
import DeviationCLComponent from "./DeviationCLComponent";
import ApprovalTab from "./ApprovalTab";
import RuleAndDeviationTab from "./RuleAndDeviationTab";
import LogTab from "./LogTab";
import { AnnotationAndCommentTab } from "./AnnotationAndCommentTab";
import { ContractRes, Approvers, draftStatus } from "../State/DraftState";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { isNullOrUndefined } from "is-what";
import SupportDocTab from "./SupportDocTab";
import RemoveDeviation from "./RemoveDeviation";
import ManualDeviation from "./ManualDeviationPopup";
import Deviationmodal from "./Deviationmodal";
import ValidationPopup from "./ValidationPopup";
import { useStateManage } from "../../DataDictionary/Component/useStateManage";
import NotifyCommit from "./CommitNotify";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { PopOver } from "../../UniversalComponents/ClauseLibraryDrawer/Component/ClauseLibraryDrawer";

import {
  addAnnotationValue,
  getAnnotation,
  getComment,
  getdraftbyid,
  getreqbyidbyid,
  updateAnnotation,
  updateContractMetaData,
  getIPAddress,
} from "../DA";
import SignaturesTab from "./SignaturesTab";
import ConfirmationPopup from "./ConfirmationPopup";

interface Props {
  open: boolean;
  onClose: (res: any) => void;
  fileName: string;
  draftDetails: any | undefined;
  openAssignedTo?: any | undefined;
  setSpecificDraft?: any | undefined;
  setdraftDetails?: any | undefined;
  uploadFile?: (post: any, fileType: any, callback: Function) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PdfViewer = (props: Props) => {
  const [open, setOpen] = useState(false);
  const documentContainer = useRef<HTMLDivElement>(null);
  const [value, setValue] = React.useState(0);
  const [modalVisible, setModalVisible] = useState("");
  const [inta, seInsta] = useState<any>("");
  const [selectedText, setselectedText] = useState("");
  const [copiedText, setcopiedText] = useState("");
  const [selectedClause, setselectedClause] = useState("");
  const [deviationPolicyData, setdeviationPolicyData] = React.useState([]);
  const [deviationStatistics, setdeviationStatistics] = React.useState({
    deviation_count: 0,
    total_approvals: 0,
    file_name: "",
    red_deviation: 0,
    amber_deviation: 0,
  });

  const [deviationDataPoint, setdeviationDataPoint] = React.useState("");
  const [annotationDataPoint, setannotationDataPoint] = React.useState("");
  const [recommendedValue, setrecommendedValue] = useState("");
  const [nodeId, setnodeId] = useState("");
  const [paraId, setparaId] = useState("");
  const [results, setresults] = useState<any>([]);
  const [kdpClause_DataPoint, setkdpClause_DataPoint] = React.useState<any>([]);
  const [enableKDP, setenableKDP] = React.useState(false);
  const [annotationIds, setannotationIds] = React.useState<any>([]);
  const [showTemplateField, setshowTemplateField] = useState(false);
  const [templateKeyValue, settemplateKeyValue] = useState<any>(null);
  const [clauseLibraryData, setClauseLibraryData] = React.useState<any[]>([]);
  const [draftValue, setDraftValue] = useState<string>("");
  const [fileLink, setFileLink] = useState<string>("");
  const [showAnnotationCommentCard, setShowAnnotationCommentCard] =
    useState<boolean>(false);
  const [showAnnotationAnnotationCard, setShowAnnotationAnnotationCard] =
    useState<boolean>(false);

  const [draftComments, setDraftComment] = React.useState<string[]>([]);
  const [draftAnnotations, setDraftAnnotation] = React.useState<string[]>([]);
  const [collaborationType, setCollaborationType] = useState<boolean>(false);
  const [showAnnotationCard, setAnnotationCard] = React.useState<any>({
    isShow: false,
    type: "",
  });

  useEffect(() => {
    if (props.draftDetails && props.draftDetails.optional_field) {
      let flatArr = props.draftDetails.optional_field?.flatMap(
        (section: any) => section.field_data
      );
      let keyValuePair: any = flatArr?.reduce((acc: any, curr: any) => {
        if (curr.data_type.toLowerCase() == "duration") {
          acc = {
            ...acc,
            [curr.display_name]: curr.fieldValue + " " + curr.fieldLength,
          };
        } else acc = { ...acc, [curr.display_name]: curr.fieldValue };
        return acc;
      }, {});
      settemplateKeyValue(keyValuePair);
    }
  }, [props.draftDetails]);
  useEffect(() => {
    showTemplateFieldValues();
  }, [showTemplateField]);

  const [hoverTextSource, sethoverTextSource] = React.useState<any>([]);
  const [kdpannotationIds, setkdpannotationIds] = React.useState<any>([]);
  const [approvers, setapprovers] = React.useState<Approvers[]>([]);
  const [deviationapprovalId, setdeviationapprovalId] = React.useState("");
  const [approvals, setapprovals] = React.useState<any>({});
  const [approvalsLoader, setapprovalsLoader] = React.useState<boolean>(false);
  const [deletedeviationPopup, setdeletedeviationPopup] =
    useState<boolean>(false);
  const [removedeviationIds, setremovedeviationIds] = useState<any>([]);
  const [approvalType, setapprovalType] = useState<any>("");
  const [rulesdeviationLoader, setrulesdeviationLoader] =
    React.useState<boolean>(false);
  const [manualdeviationPopup, setmanualdeviationPopup] =
    useState<boolean>(false);
  const [manualdeviationDataPoint, setmanualdeviationDataPoint] =
    React.useState<any>({});

  const [scrollIndex, setscrollIndex] = React.useState<number>(1);
  const [removedeviationtype, setremovedeviationtype] = useState<string>("");
  const [removedeviationclauseText, setremovedeviationclauseText] =
    useState<any>([]);
  const [compliantdeviationPolicyData, setcompliantdeviationPolicyData] =
    React.useState([]);
  const [compliantdeviationDatapoint, setcompliantdeviationDatapoint] =
    React.useState<any>({});
  const [kdpclauseType, setkdpclauseType] = React.useState<any>({});
  const [kdpsubhighlighted_text, setkdpsubhighlighted_text] =
    React.useState<any>({});
  const [selectedSection, setselectedSection] = React.useState<any>("");
  const [validationPopup, setvalidationPopup] = useState<any>({
    modal: false,
    text: "",
  });
  const [draftselectedSection, setdraftselectedSection] =
    React.useState<any>("");
  const [openSnackbar, setopenSnackbar] = React.useState({
    open: false,
    msg: "",
    isPresign: false,
  });
  const [dropPoint, setDropPoint] = useState<any>(null);
  const [addedSignatureFields, setaddedSignatureFields] =
    useState<boolean>(false);
  const [openConfirmationDialog, setopenConfirmationDialog] = useState(false);
  const [hideSignatureButton, sethideSignatureButton] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const signatories = useRef<any[]>([]);
  const XFDF = useRef<any>(null);

  const draftAccessURL = async () => {
    const response = await getdraftbyid(props.draftDetails?.id);
    if (response) {
      return response.access_url;
    }
    return "";
  };

  const requisitionAccessURL = async () => {
    const response = await getreqbyidbyid(props.draftDetails?.id);
    if (response) {
      return response.access_url;
    }
    return "";
  };

  useEffect(() => {
    if (props.draftDetails?.version === 0) {
      requisitionAccessURL().then((value) => {
        if (value) {
          setFileLink(value);
        }
      });
    } else {
      draftAccessURL().then((value) => {
        if (value) {
          setFileLink(value);
        }
      });
    }
  }, []);

  const checkPreSignatories = () => {
    if (
      props.draftDetails.pre_signatories &&
      props.draftDetails.pre_signatories.length > 0
    ) {
      const user_details = getLocalStorage("user_profile");
      const filterData = props.draftDetails.pre_signatories.filter(
        (item: any) => item.id === user_details.id
      );
      if (
        filterData.length > 0 &&
        filterData[0]?.approval_status ===
          draftStatus.PRESIGNATORIES_APRROVAL_PENDING
      ) {
        setopenSnackbar({
          open: true,
          msg: "Pre Signatory Approval Pending",
          isPresign: true,
        });
      }
    }
  };
  useEffect(() => {
    checkPreSignatories();
  }, []);

  useEffect(() => {
    if (props.draftDetails && props.draftDetails.optional_field) {
      let flatArr = props.draftDetails.optional_field?.flatMap(
        (section: any) => section.field_data
      );
      let keyValuePair: any = flatArr?.reduce((acc: any, curr: any) => {
        if (curr.data_type.toLowerCase() == "duration") {
          acc = {
            ...acc,
            [curr.display_name]: curr.fieldValue + " " + curr.fieldLength,
          };
        } else acc = { ...acc, [curr.display_name]: curr.fieldValue };
        return acc;
      }, {});
      settemplateKeyValue(keyValuePair);
    }
  }, [props.draftDetails]);
  useEffect(() => {
    showTemplateFieldValues();
  }, [showTemplateField]);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const callAction = (passedValue: any, documentViewer: any) => {
    let selected_text: any = "";
    documentViewer.addEventListener(
      "textSelected",
      (quads: any, selectedText: any, pageNumber: any) => {
        if (selectedText.length > 0) {
          selected_text = selectedText;
          setselectedText(selectedText);
        }
      }
    );
    if (passedValue == "Copy") {
      setselectedText(selected_text);
    }
  };

  const addManually = async (documentViewer: any) => {
    const selectedText = documentViewer.getSelectedText();
    setdeviationDataPoint(selectedText);
    setannotationDataPoint(selectedText);
  };

  // Put this in webviewer useEffect
  useEffect(() => {}, [props.draftDetails]);

  const copyToClipboard = async (
    passedValue: any,
    documentViewer: any,
    Annotations: any,
    annotationManager: any
  ) => {
    const doc = documentViewer.getDocument();
    /* const searchTerm = 'HDBFS carries on Non-banking Business in India and has, among others, a Corporate Office at HDB Financial Services Limited, Ground Floor, Zenith House, Opp. Mahalaxmi Race Course, K.K. Marg, Mahalaxmi, Mumbai – 400034.';
    const pageNumber = 1; // page to parse
    let pageText:string = "";
    //await doc.loadPageText(pageNumber);
    let startIndex = 0;
    let endIndex = 0;
    let quad_data: any[]=[];
    let d =  inta.UI.getZoomLevel()
    while (startIndex > -1) {
      let outsideDiv1 = document.getElementById("document-viewer");
      if (!isNullOrUndefined(outsideDiv1)) {
        pageText =  outsideDiv1.innerHTML;
      }
      console.log(pageText);
      startIndex = pageText.indexOf(searchTerm);
      
      console.log("startIndex", startIndex, typeof(pageText))
      endIndex = startIndex + searchTerm.length;
      console.log("endIndex", endIndex, typeof(pageText))
      // Get text position for each letter in the 'searchTerm' found
      // 'quads' will contain an array for each character between the start and end indexes
      const quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);
      quad_data = quads;
      console.log("quad_data", quad_data);
      const highlight = new Annotations.TextHighlightAnnotation();
      highlight.PageNumber = 1;
      highlight.X = 405;
      highlight.Y = 165;
      highlight.Width = 275;
      highlight.Height = 25;
      highlight.StrokeColor = new Annotations.Color(255, 255, 0);
      highlight.Quads=quad_data as any[];
      annotationManager.addAnnotation(highlight);
      if(startIndex!=-1){
        break;
      }
      // break;
    }*/
    const selectedText = documentViewer.getSelectedText();
    setcopiedText(selectedText);
    navigator.clipboard.writeText(selectedText);
  };

  const getDeviationPolicyFromApprovals = () => {
    /*const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `contracts/deviation-policy/${props.draftDetails?.id}/`;
    */

    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/draft/deviation-policy/${props.draftDetails?.id}/`;

    return axios
      .get(URL, APIConfig())
      .then((response) => {
        if (response.status == 200) {
          let deviationData = response.data.queryResult.filter(
            (option: any) => option["deviation"] === true
          );
          let compliantData = response.data.queryResult.filter(
            (option: any) => option["compliant"] === true
          );
          setcompliantdeviationPolicyData(compliantData);
          setdeviationPolicyData(deviationData);
          setdeviationStatistics({
            deviation_count: response.data.deviation_count,
            total_approvals: response.data.approval_count,
            file_name: response.data.file_name,
            red_deviation: response.data.deviation_count,
            amber_deviation: response.data.amber_deviation_count,
          });
          setrulesdeviationLoader(false);
          // setloader(false);
        }
        // ClauseData(response.data.results);
      })
      .catch((error) => {
        setrulesdeviationLoader(false);
      });
  };

  const getKDPDataPoint = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/draft/insights/${props.draftDetails?.id}/`;
    return axios
      .get(URL, APIConfig())
      .then((response) => {
        if (response.status == 200) {
          let clauseName: any[] = [];
          let clausewiseDataPoint: any = {};
          let highlight_text: any = [];
          let highlightedData: any = [];
          if (response.data["queryResult"].length > 0) {
            let parseData = response.data["queryResult"];
            let kdp_insights: any[] = [];
            kdp_insights = response.data["queryResult"];
            setkdpClause_DataPoint(kdp_insights);
          }
        }
      })
      .catch((error) => {});
  };

  const replaceClauseText = async (clauses: any) => {
    const { Core } = inta;
    const { Search, documentViewer, Annotations, annotationManager } = Core;

    const searchText = deviationDataPoint;
    const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
    await replacePerformSearch(searchText);

    let result: any = results[0];
    if (result.resultCode === Search.ResultCode.FOUND) {
      const textQuad = result.quads[0].getPoints();

      let positionRect: any = {};
      positionRect.width = 75;
      positionRect.height = 25;
      positionRect.X = (textQuad.x2 + textQuad.x1) / 2;
      positionRect.Y = textQuad.y1;
      let pageNumber = result.pageNum;
      let replacementText: any = clauses["clause_text"];

      addStamp(
        process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
          "static_images/replace-stamp.png",
        {},
        positionRect,
        pageNumber,
        replacementText
      );

      let post_params = {
        draft_id: props.draftDetails?.id,
        para_id: paraId,
        pdftron_para: deviationDataPoint,
        replaced_text: clauses.clause_text,
        node_id: nodeId,
      };

      const URL =
        process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/deviation/`;
      let response = axios.post(URL, post_params, APIConfig());

      response.then((result) => {
        if (result.status == 200) {
          setopenSnackbar({
            open: true,
            msg: `Clause Text replaced sucessfully`,
            isPresign: false,
          });
          setModalVisible("");
          setrulesdeviationLoader(true);
          getDeviationPolicyFromApprovals();
        }
      });
      response.catch((error) => {
        console.log(error);
      });
    }
  };

  const getApproversList = () => {
    const URL = process.env.REACT_APP_RIVERUS_CLM_API + `users/?role=Approvers`;
    let response = axios.get(URL, APIConfig());
    response.then((result) => {
      if (result.status == 200) {
        let parseApprovals = result.data.results;
        setapprovers(parseApprovals);
      }
    });
    response.catch((error) => {
      console.log(error);
    });
  };

  const createrequestApproval = (
    payload: any,
    approvers: any,
    approvaltype: string
  ) => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/request_approval/`;
    let response = axios.post(URL, payload, APIConfig());
    response.then((result) => {
      if (result.status == 201) {
        setopenSnackbar({
          open: true,
          msg: `${approvaltype} Approval created sucessfully`,
          isPresign: false,
        });
        let created_by = "";
        if (result.data.length > 0) {
          created_by =
            result.data[0]["created_by"]["first_name"] +
            " " +
            result.data[0]["created_by"]["last_name"];
        }

        let mail_subject =
          created_by +
          " seeks Approval for " +
          props.draftDetails?.contractName;
        let link =
          process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
          `draftingreview/${props.draftDetails?.id}`;
        setModalVisible("");
        getApprovalList();
        let email_payload: any = {
          user_ids: approvers,
          body_html:
            "<h2>Approvals for Draft</h2><h4>" +
            mail_subject +
            "<br><a href='" +
            link +
            "'>click here to see the draft</a></h4>",
          body_text: mail_subject,
          subject:
            created_by +
            " seeks Approval for " +
            props.draftDetails?.contractName,
        };

        if (!isNullOrUndefined(created_by)) {
          send_email(email_payload);
        }
        if (approvalType == "amber-deviation") {
          setrulesdeviationLoader(true);
          getDeviationPolicyFromApprovals();
        }
        setapprovalType("");
      }
    });
    response.catch((error) => {
      console.log(error);
      setModalVisible("");
    });
  };

  const requestApproval = (approvers: any, comments: any) => {
    let node_id = "";
    let para_id = "";
    let approval_id = "";
    let success_flag = 0;
    if (approvalType == "amber-deviation") {
      const AMBER_URL =
        process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/amber-deviation/`;
      let amber_payload: any = {
        para: deviationDataPoint,
        draft_id: props.draftDetails?.id,
      };
      let amber_response = axios.post(AMBER_URL, amber_payload, APIConfig());
      amber_response.then((result) => {
        if (result.status == 200) {
          if (result.data["queryResult"].hasOwnProperty("id")) {
            node_id = result.data["queryResult"]["id"];
            success_flag = 1;
            let postData: any[] = [];
            for (let i = 0; i < approvers.length; i++) {
              let post_params = {
                draft: props.draftDetails?.id,
                para_id: para_id,
                clause_text: deviationDataPoint.replace(/\n/g, " "),
                kdp: node_id,
                approvers: approvers[i],
                approval: approval_id,
                comments: comments,
              };
              postData.push(post_params);
            }
            createrequestApproval(postData, approvers, "Adhoc");
          }
        }
      });
    } else if (approvalType == "red-deviation") {
      success_flag = 1;
      para_id = paraId;
      node_id = nodeId;
      approval_id = deviationapprovalId;

      let postData: any[] = [];
      for (let i = 0; i < approvers.length; i++) {
        let post_params = {
          draft: props.draftDetails?.id,
          para_id: para_id,
          clause_text: deviationDataPoint.replace(/\n/g, " "),
          kdp: node_id,
          approvers: approvers[i],
          approval: approval_id,
          comments: comments,
        };
        postData.push(post_params);
      }
      createrequestApproval(postData, approvers, "Request");
    }
  };

  const getApprovalList = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/request_approval/?draft_id=${props.draftDetails?.id}`;
    let response = axios.get(URL, APIConfig());
    response.then((result) => {
      if (result.status == 200) {
        setapprovals(result.data.results);
        setapprovalsLoader(false);
      }
    });
    response.catch((error) => {
      console.log(error);
      setapprovalsLoader(false);
    });
  };

  const updateRequestApproval = (data: any) => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/request_approval/${data["id"]}/`;
    let params: any = {
      status: data["status"],
    };
    if (data.hasOwnProperty("comment")) {
      if (
        data["comment"] == "" ||
        data["comment"] === null ||
        data["comment"] == " "
      ) {
        //console.log("Empty: {}", data['comment'])
      } else {
        params["approval_comments"] = data["comment"];
      }
    }

    let response = axios.patch(URL, params, APIConfig());
    response.then((result) => {
      if (result.status == 200) {
        let status: any = {
          Approved: "Approved",
          Rejected: "Rejected",
          Rework: "Reworked",
        };
        setopenSnackbar({
          open: true,
          msg: `${status[data["status"]]} sucessfully`,
          isPresign: false,
        });
        setapprovalsLoader(true);
        // console.log("update status", result);
        getApprovalList();
      }
    });
    response.catch((error) => {
      console.log(error);
    });
  };

  const send_email = (payload: any) => {
    const URL = process.env.REACT_APP_RIVERUS_CLM_API + `sending_email/`;
    let response = axios.post(URL, payload, APIConfig());
    response.then((result) => {
      if (result.status == 200) {
        // console.log("email sent", result);
      }
    });
    response.catch((error) => {
      console.log(error);
    });
  };

  const manualDeviation = (clause: any) => {
    const { Core } = inta;
    const { documentViewer } = Core;
    const selectedText = documentViewer.getSelectedText();
    if (
      isNullOrUndefined(selectedText) ||
      selectedText === null ||
      selectedText == ""
    ) {
      setvalidationPopup({
        modal: true,
        text: "Please first select relevant text from the agreement ",
      });
    } else {
      setdeviationDataPoint(selectedText);
      setmanualdeviationDataPoint(clause);
      setmanualdeviationPopup(true);
    }
  };

  const convert_pdf = async (Core: any, url: any) => {
    const buffer_data = await Core.office2PDFBuffer(url);
    const blob_data = new Blob([buffer_data], { type: "application/pdf" });
    return blob_data;
  };

  useEffect(() => {
    setTimeout(() => {
      if (documentContainer.current != null && fileLink != "") {
        WebViewer(
          {
            path: "/webviewer",
            disabledElements: [
              "header",
              "toolsHeader",
              "linkButton",
              "annotationCommentButton",
            ],
            fullAPI: true,
          },
          documentContainer.current
        ).then(async (instance) => {
          seInsta(instance);
          const { UI, Core } = instance;
          const {
            documentViewer,
            Annotations,
            annotationManager,
            Tools,
            PDFNet,
            Search,
          } = Core;
          const { iframeWindow } = UI;
          instance.UI.annotationPopup.update([{ type: "ac" }]);
          if (fileLink?.includes("pdf")) {
            instance.UI.loadDocument(fileLink);
          } else {
            const blob = await convert_pdf(Core, fileLink);
            instance.UI.loadDocument(blob);
          }
          const tool = documentViewer.getTool(Tools.ToolNames.TEXT_SELECT);
          documentViewer.setSearchHighlightColors({
            searchResult: new Annotations.Color(255, 238, 89, 1),
          });

          if (tabName === "Annotation") {
            UI.textPopup.update([
              {
                type: "actionButton",
                img: "/static_images/draft-delete.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                    setAnnotationCard({
                      isShow: true,
                      type: "remove",
                    });
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/re-order.svg",
                onClick: () => {
                  addManually(documentViewer);
                },
              },
              {
                type: "actionButton",
                img: "/static_images/draft-add-to-clause-library-icn.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    setShowAnnotationAnnotationCard(true);
                    addAnnotation(instance);
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/comment.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    setShowAnnotationCommentCard(true);
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/text.png",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                    setAnnotationCard({
                      isShow: true,
                      type: "add",
                    });
                  }
                },
              },
            ]);
          } else {
            UI.textPopup.update([
              {
                type: "actionButton",
                img: "/static_images/draft-view-click.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                    setAnnotationCard({
                      isShow: true,
                      type: "add",
                    });
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/draft-copy-icn.svg",
                onClick: () => {
                  copyToClipboard(
                    "Copy",
                    documentViewer,
                    Annotations,
                    annotationManager
                  );
                },
              },
              {
                type: "actionButton",
                img: "/static_images/draft-delete.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                    setAnnotationCard({
                      isShow: true,
                      type: "remove",
                    });
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/re-order.svg",
                onClick: () => {
                  addManually(documentViewer);
                },
              },
              {
                type: "actionButton",
                img: "/static_images/draft-clause-library.svg",
                onClick: () =>
                  callAction("Add Clause Library...", documentViewer),
              },
              {
                type: "actionButton",
                img: "/static_images/draft-add-to-clause-library-icn.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    setShowAnnotationAnnotationCard(true);
                    addAnnotation(instance);
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/comment.svg",
                onClick: () => {
                  if (props.draftDetails.open_collab) {
                    setShowAnnotationCommentCard(true);
                    const selectedText = documentViewer.getSelectedText();
                    setannotationDataPoint(selectedText);
                  }
                },
              },
              {
                type: "actionButton",
                img: "/static_images/clause_copy.svg",
                onClick: () => callAction("Copy text...", documentViewer),
              },
            ]);
          }

          // call methods from UI, Core, documentViewer and annotationManager as needed
          const highlightText11 = (searchText: any, pageNumber: any) => {
            const doc = documentViewer.getDocument();
          };
          documentViewer.addEventListener("documentLoaded", async () => {
            var FitMode = instance.UI.FitMode;
            instance.UI.setFitMode(FitMode.FitWidth);
          });

          documentViewer.addEventListener("mouseMove", async (e) => {
            const annot = annotationManager.getAnnotationByMouseEvent(e);
            if (annot) {
              let stampData: any = {};
              let isKeyPresentStampAnnontation = hoverTextSource.some(
                (el: any) => {
                  if (el.hasOwnProperty(annot.Id)) {
                    stampData = el;
                    return true;
                  }
                }
              );

              if (isKeyPresentStampAnnontation) {
                instance.UI.setAnnotationContentOverlayHandler((annotation) => {
                  const div = document.createElement("div");
                  div.appendChild(document.createTextNode(stampData[annot.Id]));
                  return div;
                });
              }
            }
          });
          const iframeDoc = iframeWindow.document.body;
          iframeDoc.addEventListener("dragover", dragOver);
          iframeDoc.addEventListener("drop", (e: any) => {
            drop(e, instance);
          });
          Annotations.setCustomCreateSignHereElementHandler(function (
            tool,
            { annotation, originalCreateSignHereElement }
          ) {
            const signHereElement = originalCreateSignHereElement(tool);
            signHereElement.innerHTML = "Click Here To Sign";
            return signHereElement;
          });
          annotationManager.on("annotationChanged", (annot, action, info) => {
            console.log(annot, "annotation changed");
            console.log(action, "action");
            console.log(info, "info");
            console.log(annot[0].getCustomData("user"), "userId");
            annot.forEach((annot: any) => {
              if (annot.Subject.toLowerCase() == "signature") {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                setaddedSignatureFields(true);
              }
              if (annot instanceof Annotations.SignatureWidgetAnnotation) {
                sethideSignatureButton(true);
              }
            });
          });
        });
      }
    }, 200);
  }, [fileLink, documentContainer.current, props.draftDetails]);

  const dragOver = (e: any) => {
    e.preventDefault();
    return false;
  };
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {}, [
    results,
    showAnnotationCommentCard,
    showAnnotationAnnotationCard,
    annotationDataPoint,
  ]);

  useEffect(() => {
    getDeviationPolicyFromApprovals();
    getKDPDataPoint();
    getApproversList();
    getApprovalList();
    getClauseLibraryData();
  }, []);

  const handleClose = (res: any) => {
    setOpen(false);
    props.onClose(res);
  };

  const getClauseLibraryData = async () => {
    try {
      const URL =
        process.env.REACT_APP_RIVERUS_CLM_API + "stylus/custom-clauselibrary/";
      const response = await axios.get(URL, APIConfig());
      if (response && response.data) {
        setClauseLibraryData(response?.data?.results);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };
  const downloadDoc = async () => {
    try {
      const response = await getdraftbyid(props.draftDetails?.id);
      if (response) {
        let blob = fetch(response.access_url).then((r) => {
          r.blob();
          FileSaver.saveAs(
            response.access_url,
            `${response.contractName}-(V${response.version}).docx`
          );
        });
      }
    } catch (error) {}
  };

  const Zoomout = () => {
    const zoom = inta.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      inta.UI.setZoomLevel(zoom);
    }
  };
  const ZoomIn = () => {
    const zoom = inta.UI.getZoomLevel() + 0.25;
    inta.UI.setZoomLevel(zoom);
  };

  const searchListener = (searchPattern: any, options: any, results: any) => {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    // add redaction annotation for each search result

    const annotations = annotationManager.getAnnotationsList();
    annotationManager.deleteAnnotations(annotations);
    const newAnnotations = results.map(
      (result: { pageNum: any; quads: any[] }) => {
        /*
      const annotation = new Annotations.RedactionAnnotation();
      annotation.PageNumber = result.pageNum;
      annotation.Quads = result.quads.map(quad => quad.getPoints());
      annotation.StrokeColor = new Annotations.Color(136, 39, 31);
      return annotation;
*/
        const annotation = new Annotations.TextHighlightAnnotation();
        annotation.Author = annotationManager.getCurrentUser();
        annotation.PageNumber = result.pageNum;
        annotation.Quads = result.quads.map((quad) => quad.getPoints());
        annotation.StrokeColor = new Annotations.Color(255, 255, 0);

        return annotation;
      }
    );

    /*annotationManager.addAnnotations(newAnnotations);
    annotationManager.drawAnnotationsFromList(newAnnotations);*/

    annotationManager.addAnnotations(newAnnotations);
    // annotationManager.selectAnnotations(newAnnotations);
  };

  const searchOptions = {
    caseSensitive: true, // match case
    wholeWord: true, // match whole words only
    wildcard: false, // allow using '*' as a wildcard value
    regex: false, // string is treated as a regular expression
    searchUp: false, // search from the end of the document upwards
    ambientString: true, // return ambient string as part of the result
  };

  const getKdpdata = () => {
    console.log("click herethe text");
    for (let i = 0; i < 10; i++) {
      console.log("click herethe text");
      let test = "bar";

      // getKdpdata_highlightText("This agreement shall be commencing from 20th June, 2022. This agreement shall continue for an initial term of 3 years from date of execution of this agreement", i+1);
      getKdpdata_highlightText(deviationDataPoint, i + 1);
    }
  };

  function drawAnnotation(phrase: any, type: any) {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    return new Promise<void>((resolve) => {
      // console.log("drawAnnotation(): " + phrase["clause"]);
      const searchMode =
        inta.Core.Search.Mode.HIGHLIGHT | inta.Core.Search.PAGE_STOP;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          if (result.resultCode === inta.Core.Search.ResultCode.FOUND) {
            /*console.log(
              "onResult: " + result,
              result.quads,
              "---pagenumberss-----",
              result.pageNum
            );*/
            let resData: any[] = kdpannotationIds;

            let kdp_clause_type: any = kdpclauseType;

            let highlight_hover_text = phrase["clause"];

            let sub_highlight_text: any = kdpsubhighlighted_text;

            let initial_quad: number = result.quads[0].getPoints();
            let end_quad: number =
              result.quads[result.quads.length - 1].getPoints();

            if (phrase.hasOwnProperty("para_id")) {
              if (kdpclauseType.hasOwnProperty(phrase["para_id"])) {
                let kdp_clause_arr = kdpclauseType[phrase["para_id"]];

                kdp_clause_arr.push(phrase["clause"]);
                kdpclauseType[phrase["para_id"]] = kdp_clause_arr;

                let unique = kdp_clause_arr.filter(
                  (item: any, i: any, ar: any) => ar.indexOf(item) === i
                );

                highlight_hover_text = unique.join(" | ");
              } else {
                sub_highlight_text[phrase["para_id"]] = {
                  x1: initial_quad,
                  y2: end_quad,
                  pageNumber: result.pageNum,
                };
                setkdpsubhighlighted_text(sub_highlight_text);

                kdp_clause_type[phrase["para_id"]] = [phrase["clause"]];
                setkdpclauseType(kdp_clause_type);
              }
            } else {
              kdp_clause_type[phrase["para_id"]] = [phrase["clause"]];
              setkdpclauseType(kdp_clause_type);

              sub_highlight_text[phrase["para_id"]] = {
                x1: initial_quad,
                y2: end_quad,
                pageNumber: result.pageNum,
              };
              setkdpsubhighlighted_text(sub_highlight_text);
            }
            let count = 0;
            //console.log("result: ", result);
            result.quads.map((quad: { getPoints: () => any }) => {
              resData.push(quad.getPoints());

              const highlight = new Annotations.TextHighlightAnnotation();
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              highlight.Quads = [quad.getPoints()] as any[];
              let annotationId: any = highlight.Id;
              let annotateId: any = {};
              annotateId[annotationId] = phrase["clause"];

              highlight.setContents(highlight_hover_text);
              // phrase["clause"]);

              let newannotate: any[] = annotationIds;

              newannotate.push(annotateId);
              setannotationIds(newannotate);

              if (type == "sub_highlighted_text") {
                highlight.StrokeColor = new Annotations.Color(252, 206, 211);
              }
              if (type == "sub_highlighted_text") {
                if (
                  quad.getPoints().x1 >=
                    sub_highlight_text[phrase["para_id"]]["x1"]["x1"] &&
                  quad.getPoints().y2 <=
                    sub_highlight_text[phrase["para_id"]]["y2"]["y2"] &&
                  sub_highlight_text[phrase["para_id"]]["pageNumber"] ==
                    result.pageNum
                ) {
                  annotationManager.addAnnotation(highlight);
                  annotationManager.redrawAnnotation(highlight);
                  annotationManager.drawAnnotations({
                    pageNumber: result.pageNum,
                  });
                }
              } else {
                annotationManager.addAnnotation(highlight);
                annotationManager.redrawAnnotation(highlight);
                annotationManager.drawAnnotations({
                  pageNumber: result.pageNum,
                });
              }

              count++;
            });

            setkdpannotationIds(resData);
            if (phrase["replaced_text"] != null) {
              const textQuad = result.quads[0].getPoints();

              console.log(result.quads.length, "Quad Length: ");

              let positionRect: any = {};
              positionRect.width = 75;
              positionRect.height = 25;
              positionRect.X = (textQuad.x2 + textQuad.x1) / 2;
              positionRect.Y = textQuad.y1;
              let pageNumber = result.pageNum;

              addStamp(
                process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
                  "static_images/replace-stamp.png",
                {},
                positionRect,
                pageNumber,
                phrase["replaced_text"]
              );
            }
          }
        },
        onDocumentEnd: (result: any) => {
          resolve();
          // setTimeout(() => {
          //   drawAnnotations();
          // }, 100);
        },
      };
      inta.UI.addSearchListener(searchListener);
      if (type == "highlighted_text") {
        documentViewer.textSearchInit(
          phrase["highlighted_text"],
          searchMode,
          searchOptions
        );
      }
      if (type == "sub_highlighted_text") {
        documentViewer.textSearchInit(
          phrase["sub_highlighted_text"],
          searchMode,
          searchOptions
        );
      }
      // inta.UI.searchTextFull(phrase, searchOptions);
      // inta.UI.closeElements(["searchPanel", "searchOverlay"]);
    });
  }
  const searchDraft = (searchText: any) => {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    return new Promise<void>((resolve) => {
      //console.log("drawAnnotation(): " + searchText);
      const searchMode =
        inta.Core.Search.Mode.HIGHLIGHT | inta.Core.Search.PAGE_STOP;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          if (result.resultCode === inta.Core.Search.ResultCode.FOUND) {
            result.quads.map((quad: { getPoints: () => any }) => {
              const highlight = new Annotations.TextHighlightAnnotation();
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              highlight.StrokeColor = new Annotations.Color(252, 206, 211); //new Annotations.Color(255, 229, 232);//new Annotations.Color(0, 255, 255);
              highlight.Quads = [quad.getPoints()] as any[];

              annotationManager.deselectAllAnnotations();
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
              documentViewer.setCurrentPage(result.pageNum);
            });
          }
        },
        onDocumentEnd: (result: any) => {
          resolve();
        },
      };
      inta.UI.addSearchListener(searchListener);
      documentViewer.textSearchInit(searchText, searchMode, searchOptions);
    });
  };

  const get_Kdpdata = (searchPattern: any) => {
    /*console.log("click herethe text");
    for (let i = 0; i < 10; i++) {
      console.log("click herethe text");
      let test = "bar";

      // getKdpdata_highlightText("This agreement shall be commencing from 20th June, 2022. This agreement shall continue for an initial term of 3 years from date of execution of this agreement", i+1);
      getKdpdata_highlightText(searchPattern, i + 1);
    }*/
    const { Core } = inta;
    const { annotationManager, documentViewer } = Core;
    const annotations = annotationManager.getAnnotationsList();

    let stampAnnotation: any[] = hoverTextSource;

    let stampannotations: any[] = annotations;
    for (let i = 0; i < stampAnnotation.length; i++) {
      for (let key in stampAnnotation[i]) {
        stampannotations = stampannotations.filter(
          (annotation: { Id: any }) => {
            return annotation.Id != key;
          }
        );
      }
    }

    annotationManager.deleteAnnotations(stampannotations);
    searchDraft(searchPattern);
  };

  const getKdpdata_highlightText = (searchText: any, pageNumber: any) => {
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;

    /*  const doc = documentViewer.getDocument();
              // let searchText = "HDBFS carries on Non-banking Business";
              // let pageNumber = 1;
              // highlightText11('HDBFS carries on Non-banking Business', i+1)
              // gets all text on the requested page
              // see https://pdftron.com/api/web/Core.Document.html#loadPageText__anchor
              doc.loadPageText(pageNumber).then((text: any)=> {
                let textStartIndex = 0;
                let textIndex;
                const annotationPromises = [];
                let search_text = 'This agreement dated 11th of June, 2022'//searchText.split(' ').slice(0,2).join(' ');
                const match = /This agreement shall be commencing from 20th June, 2022. This agreement shall continue for an initial term of 3 years from date of execution of this agreement/g.exec(text);
                console.log(match && match.index ,"////////////////////////////", text);
          
                // find the position of the searched text and add text highlight annotation at that location
                while ((textIndex = text.indexOf(search_text, textStartIndex)) > -1) {
                  textStartIndex = textIndex + searchText.length;
                  // gets quads for each of the characters from start to end index. Then,
                  // resolve the annotation and return.
                  // see https://pdftron.com/api/web/Core.Document.html#getTextPosition__anchor
                  const annotationPromise = doc.getTextPosition(pageNumber, textIndex, textIndex + searchText.length).then((quads: any[]) => {
                    const annotation = new Annotations.TextHighlightAnnotation();
                    annotation.Author = annotationManager.getCurrentUser();
                    annotation.PageNumber = pageNumber;
                    annotation.Quads = quads as any[];
                    annotation.StrokeColor = new Annotations.Color(0, 255, 255);
                    return annotation;
                  });
                  annotationPromises.push(annotationPromise);
                }
          
                // Wait for all annotations to be resolved.
                Promise.all(annotationPromises).then(annotations => {
                  annotationManager.addAnnotations(annotations);
                  annotationManager.selectAnnotations(annotations);
                });
              });
*/

    inta.UI.addSearchListener(searchListener);
    // start search after document loads
    inta.UI.searchTextFull(searchText, searchOptions);
  };

  const [isDark, setTheme] = useState(false);

  useEffect(() => {}, [isDark]);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };
  const search = () => {
    const { Core } = inta;
    inta.UI.searchText("", {
      caseSensitive: true,
      wholeWord: true,
    });
  };
  const Kdp_Parse = async () => {
    /* AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API +
        `stylus/draft/aws/clause-processed-webhook/`,
      { draft_id: props.draftDetails?.id }
    )
      .then((response) => {
        // console.log("response", response)
      })
      .catch((error) => {
        console.log(error);
      });
    getKdpdata();*/
    const { Core } = inta;
    const { annotationManager, Annotations, documentViewer } = Core;

    if (!enableKDP) {
      if (kdpClause_DataPoint && kdpClause_DataPoint.length) {
        let chain = Promise.resolve();
        let highlighttext = "";
        let count = 0;
        for (const nextAnnotation of kdpClause_DataPoint) {
          // await drawAnnotation(nextAnnotation);
          //console.log("nextAnnotation", nextAnnotation['clause'])
          /*if(count ==0){
            highlighttext = nextAnnotation['highlighted_text'];
          }else{
            highlighttext = highlighttext+"|"+nextAnnotation['highlighted_text'];
          }
          count++;*/
          if (nextAnnotation["highlighted_text"] != "") {
            chain = chain.then(() => {
              return drawAnnotation(nextAnnotation, "highlighted_text");
            });
          }
          // console.log("chain", chain)
        }

        //Sub Highlighted Text Start
        let sub_highlighted_array = kdpClause_DataPoint.filter(
          (option: any) => option.sub_highlighted_text !== ""
        );
        for (const nextAnnotation of sub_highlighted_array) {
          chain = chain.then(() => {
            return drawAnnotation(nextAnnotation, "sub_highlighted_text");
          });
        }
        // Sub Highlighted Text End
      }
    } else {
      setkdpclauseType({});
      const annotations = annotationManager.getAnnotationsList();

      let stampAnnotation: any[] = hoverTextSource;

      let stampannotations: any[] = annotations;
      for (let i = 0; i < stampAnnotation.length; i++) {
        for (let key in stampAnnotation[i]) {
          stampannotations = stampannotations.filter(
            (annotation: { Id: any }) => {
              return annotation.Id != key;
            }
          );
        }
      }

      annotationManager.deleteAnnotations(annotations);
    }
    /*if (!enableKDP) {
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
    } else {
      if (kdpClause_DataPoint && kdpClause_DataPoint.length) {
        let chain = Promise.resolve();
        for (const nextAnnotation of kdpClause_DataPoint) {
          chain = chain.then(() => {
            return drawAnnotation(nextAnnotation);
          });
          // console.log("chain", chain)
        }
      }
    }*/
  };

  const showTemplateFieldValues = () => {
    if (!inta) {
      return;
    }
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    if (!showTemplateField) {
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
    } else {
      if (templateKeyValue) {
        let map: any = {};
        for (const key in templateKeyValue) {
          let value = templateKeyValue[key];
          map = { ...map, [value]: key };
        }
        let searchData = Object.values(templateKeyValue)
          .filter((value: any) => value.trim())
          .reduce((acc, curr: any, index) => {
            if (index == 0) {
              return (acc = curr);
            } else return acc + "|" + curr;
          }, "");
        const searchMode =
          inta.Core.Search.Mode.REGEX |
          inta.Core.Search.Mode.HIGHLIGHT |
          inta.Core.Search.PAGE_STOP;
        const searchOptions = {
          fullSearch: true,
          onResult: (result: any) => {
            if (result.resultCode === inta.Core.Search.ResultCode.FOUND) {
              // documentViewer.displayAdditionalSearchResult(result);
              const highlight = new Annotations.TextHighlightAnnotation();
              //new Annotations.Color(0, 255, 255);
              highlight.Quads = result.quads.map((quad: any) =>
                quad.getPoints()
              );
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              highlight.setContents(
                map[result["resultStr"]] || map[result["result_str"]]
              );
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
            }
          },
          onDocumentEnd: (result: any) => {},
        };
        documentViewer.textSearchInit(searchData, searchMode, searchOptions);
      }
    }
  };

  const addStamp = async (
    imgData: any,
    point: any,
    rect: any,
    pageNumber: any,
    replacementText: any
  ) => {
    point = point || {};
    rect = rect || {};
    const { Core } = inta;
    const { documentViewer, Annotations, annotationManager } = Core;
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();

    const pageInfo = doc.getPageInfo(pageNumber);
    const pagePoint = displayMode.windowToPage(point, pageNumber);
    //const zoom = documentViewer.getZoomLevel();
    const zoom = 1;

    const stampAnnot = new Annotations.StampAnnotation();
    stampAnnot.PageNumber = pageNumber;
    const rotation = documentViewer.getCompleteRotation(pageNumber) * 90;
    stampAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      stampAnnot.Width = rect.height / zoom;
      stampAnnot.Height = rect.width / zoom;
    } else {
      stampAnnot.Width = rect.width / zoom;
      stampAnnot.Height = rect.height / zoom;
    }
    // stampAnnot.X = (pagePoint.x || pageInfo.width / 2) - stampAnnot.Width / 2;
    // stampAnnot.Y = (pagePoint.y || pageInfo.height / 2) - stampAnnot.Height / 2;
    stampAnnot.X = rect.X;
    stampAnnot.Y = rect.Y;

    //imgData = "./stamp.png";
    stampAnnot.setImageData(imgData);
    stampAnnot.Author = annotationManager.getCurrentUser();
    // annotationManager.setReadOnly(true)

    let IdToRetrieve = stampAnnot.Id;
    let hoverText: any = {
      IdToRetrieve: replacementText,
    };
    let annotateId: any = {};
    annotateId[IdToRetrieve] = replacementText;

    let newannotate: any[] = hoverTextSource;

    newannotate.push(annotateId);
    sethoverTextSource(newannotate);
    setresults([]);

    annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(stampAnnot);
    annotationManager.redrawAnnotation(stampAnnot);
    //annotationManager.selectAnnotation(stampAnnot);
    annotationManager.deselectAnnotation(stampAnnot);

    const annotations = annotationManager.getAnnotationsList();
    annotationManager.deselectAnnotations(annotations);
  };
  const replacePerformSearch = (searchTerm: any) => {
    const { Core } = inta;
    const { Search, documentViewer } = Core;
    return new Promise<void>((resolve) => {
      const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          let newannotate: any[] = results;

          newannotate.push(result);
          setresults([result]);
        },
        onDocumentEnd: () => {
          resolve();
        },
      };
      documentViewer.textSearchInit(searchTerm, mode, searchOptions);

      //documentViewer.displayAdditionalSearchResults(results);
    });
  };
  const replaceText = async (replacedList: any[]) => {
    const { Core } = inta;
    const { Search, documentViewer, Annotations, annotationManager } = Core;

    for (var counter = 0; counter < replacedList.length; counter++) {
      const searchText = replacedList[counter]["highlighted_text"];
      const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
      await replacePerformSearch(searchText);
    }
    for (var counter = 0; counter < replacedList.length; counter++) {
      let result: any = results[counter];
      if (result.resultCode === Search.ResultCode.FOUND) {
        const textQuad = result.quads[0].getPoints();

        let positionRect: any = {};
        positionRect.width = 75;
        positionRect.height = 25;
        positionRect.X = (textQuad.x2 + textQuad.x1) / 2;
        positionRect.Y = textQuad.y1;
        let pageNumber = result.pageNum;
        let replacedText = replacedList[counter]["replaced_text"];
        await addStamp(
          process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
            "static_images/replace-stamp.png",
          {},
          positionRect,
          pageNumber,
          replacedText
        );
      }
    }
  };

  const remove_deviation = () => {
    setdeletedeviationPopup(false);
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/deviation/`;
    let params: any = {
      node_id: removedeviationIds,
      draft_id: props.draftDetails?.id,
      type: removedeviationtype,
      clause_text: removedeviationclauseText,
    };
    //console.log("Remove Deviation Ids", params);
    axios
      .delete(URL, {
        headers: {
          Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
        },
        data: params,
      })
      .then(() => {
        setopenSnackbar({
          open: true,
          msg: `Deviation removed sucessfully`,
          isPresign: false,
        });
        setremovedeviationIds([]);
        setremovedeviationtype("");
        setrulesdeviationLoader(true);
        getDeviationPolicyFromApprovals();
      })
      .catch((error) => {
        console.log(error);
        setremovedeviationtype("");
        setremovedeviationIds([]);
      });
  };

  const addAnnotation = (instance: any) => {
    const { Core } = instance;
    const { documentViewer } = Core;
    const selectedText = documentViewer.getSelectedText();

    if (
      isNullOrUndefined(selectedText) ||
      selectedText === null ||
      selectedText == ""
    ) {
      setvalidationPopup({
        modal: true,
        text: "Please first select relevant text from the agreement",
      });
    } else {
      setannotationDataPoint(selectedText);
    }
  };

  const askforApproval = () => {
    const { Core } = inta;
    const { documentViewer } = Core;
    const selectedText = documentViewer.getSelectedText();
    if (
      isNullOrUndefined(selectedText) ||
      selectedText === null ||
      selectedText == ""
    ) {
      setvalidationPopup({
        modal: true,
        text: "Please first select relevant text from the agreement ",
      });
    } else {
      setparaId("");
      setdeviationapprovalId("");
      setnodeId("");
      setdeviationDataPoint(selectedText);
      setModalVisible("Request Approval");
    }
  };

  const addManualDeviation = () => {
    console.log("manual deviation", manualdeviationDataPoint);
    let payload: any = {
      field_name: manualdeviationDataPoint?.field_name,
      draft_id: props.draftDetails?.id,
      kdp_type: manualdeviationDataPoint?.kdp_type,
      para: deviationDataPoint.replace(/\n/g, " "),
      section_name: manualdeviationDataPoint?.section_name,
    };
    console.log("payload", JSON.stringify(payload));
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/manual-deviation/`;
    let response = axios.post(URL, payload, APIConfig());

    response.then((result) => {
      if (result.status == 200) {
        setopenSnackbar({
          open: true,
          msg: `Manual Deviation added successfully`,
          isPresign: false,
        });
        setrulesdeviationLoader(true);
        getDeviationPolicyFromApprovals();
        setdeviationDataPoint("");
      }
    });
    response.catch((error) => {
      console.log(error);
      setdeviationDataPoint("");
    });
  };

  const markasDeviation = () => {
    let payload: any = {
      nodeId: compliantdeviationDatapoint["nodeId"],
      field_name: compliantdeviationDatapoint["field_name"],
      draft_id: props.draftDetails?.id,
      highlightedText: compliantdeviationDatapoint["highlightedText"],
      type: "compliant",
    };
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/manual-deviation/`;
    let response = axios.post(URL, payload, APIConfig());

    response.then((result) => {
      if (result.status == 200) {
        setopenSnackbar({
          open: true,
          msg: `Manual Deviation added successfully`,
          isPresign: false,
        });
        setrulesdeviationLoader(true);
        getDeviationPolicyFromApprovals();
        setdeviationDataPoint("");
        setModalVisible("");
      }
    });
    response.catch((error) => {
      console.log(error);
      setdeviationDataPoint("");
      setModalVisible("");
    });
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg, isPresign: false });
    //props.close();
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );

  const [isClauseNo, setClauseNo] = React.useState<any>(false);
  const [isFolderName, setFolderName] = React.useState<any>(false);
  const [isPreview, setPreview] = React.useState<any>(false);
  const [searchPreview, setSearchPreview] = React.useState<any>("");
  const [searchClause, setSearchClause] = React.useState<any>("");
  const [searchFolderName, setSearchFolderName] = React.useState<any>("");

  const preview: any = React.useRef();
  const clauseNo: any = React.useRef();
  const folderName: any = React.useRef();

  const sorting = (data: any, type: any) => {
    let a: any = [];
    data.forEach((item: any) => {
      a = [...a, Object.entries(item)];
    });
    if (type === "ASC") {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? 1 : -1));
    } else {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? -1 : 1));
    }
    const output = a.reduce((accumulator: any, data: any) => {
      return [
        ...accumulator,
        { [data[0][0]]: data[0][1], modified_on: data[1][1] },
      ];
    }, []);

    setClauseLibraryData(output);
  };

  const onSearch = (searchString: any, type: any, clauseLibraryData: any) => {
    let output: any = [];
    let filterData: any = [];
    if (type === "preview") {
      setSearchPreview(searchString);
      filterData = clauseLibraryData.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.description
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "clauseNo") {
      setSearchClause(searchString);
      filterData = clauseLibraryData?.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.clause_no
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "folderName") {
      setSearchFolderName(searchString);
      filterData = clauseLibraryData?.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.section_name.section_name
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "clauseType") {
      setSearchFolderName(searchString);
      filterData = clauseLibraryData?.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.clause_type
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    }

    filterData.forEach((item: any) => {
      if (item.length) {
        output = [...output, ...item];
      }
    });
    const newOutput = output.map((item: any) => {
      const customObject: any = {
        [`${item.section_name.section_name}`]: [item],
        modified_on: item.created_on,
      };
      return customObject;
    });

    if (searchString.length > 0) {
      setClauseLibraryData(newOutput);
    } else {
      getClauseLibraryData();
    }
  };

  const isOwner = () => {
    const user_data = getLocalStorage("user_profile");
    if (props.draftDetails?.owner.id === user_data.id) {
      return true;
    }
    return false;
  };

  const updateStatus = async (type: string) => {
    try {
      if (
        props.draftDetails.pre_signatories &&
        props.draftDetails.pre_signatories.length > 0
      ) {
        const user_details = getLocalStorage("user_profile");
        const filterData = props.draftDetails.pre_signatories.filter(
          (item: any) => item.id === user_details.id
        );

        let payload = {};
        if (type === "approved") {
          filterData[0]["approval_status"] =
            draftStatus.PRESIGNATORIES_APRROVED;

          payload = {
            pre_signatories: [...filterData],
          };
        } else {
          filterData[0]["approval_status"] =
            draftStatus.PRESIGNATORIES_REJECTED;

          payload = {
            pre_signatories: [...filterData],
          };
        }
        const response = await updateContractMetaData(
          payload,
          props.draftDetails.id
        );
        if (response) {
          props.onClose(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDraftCommentCall = async () => {
    const response = await getComment(props.draftDetails.id);
    setDraftComment(response?.results);
  };

  const getDraftAnnotations = async () => {
    const response = await getAnnotation(props.draftDetails.id);

    setDraftAnnotation(response);
  };

  const updateSpecificAnnotation = async (id: any, status: string) => {
    const payload = {
      status: status,
      update_time: new Date(),
    };
    const response = await updateAnnotation(payload, id);

    if (response) {
      getDraftAnnotations();
    }
  };

  const removeAnnotation = (text: any, is_internal: boolean) => {
    const annotationPayload = {
      html_structure: `<s>${text}</s>`,
      ref_draft: props.draftDetails.id,
      proposed_to: "Remove",
      status:
        isOwnerID() === props.draftDetails.created_by.id
          ? "Approved"
          : "Pending",
      internal_only: is_internal,
      create_time: new Date(),
      update_time: new Date(),
      ref_line: "",
      proposed_line: "",
    };
    postAnnotation(annotationPayload);
  };

  const AddAnnotationInDoc = (text: any, is_internal: boolean) => {
   const lineText =  text.replace(/\n/g, "<br>");
   
    const annotationPayload = {
      html_structure: `<p style="color:#EE5800">${lineText}</p>`,
      ref_draft: props.draftDetails.id,
      proposed_to: "Add",
      status:
        isOwnerID() === props.draftDetails.created_by.id
          ? "Approved"
          : "Pending",
      internal_only: is_internal,
      create_time: new Date(),
      update_time: new Date(),
      ref_line: "",
      proposed_line: "",
    };
    postAnnotation(annotationPayload);
  };

  const postAnnotation = (payload: any) => {
    addAnnotationValue(payload).then((value: any) => {
      if (value) {
        getDraftAnnotations();
      }
    });
  };

  const isOwnerID = () => {
    const user_data = getLocalStorage("user_profile");
    return user_data.id;
  };

  const isCollaborator = () => {
    const collaborators = props.draftDetails.collaborators.filter(
      (item: any) => item.id === isOwnerID()
    );

    return collaborators.length;
  };
  const addField = async (type: any, point?: any, userId?: string) => {
    if (!inta) return;
    const { documentViewer, Annotations } = inta.Core;
    const annotationManager = documentViewer.getAnnotationManager();
    const fieldManager = annotationManager.getFieldManager();
    const doc = documentViewer.getDocument();
    const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
    const page = displayMode.getSelectedPages(point, point);
    if (!!point.x && page.first == null) {
      return; //don't add field to an invalid page location
    }
    const page_idx =
      page.first !== null ? page.first : documentViewer.getCurrentPage();
    const page_info = doc.getPageInfo(page_idx);
    const page_point = displayMode.windowToPage(point, page_idx);
    const zoom = documentViewer.getZoomLevel();
    let field = new Annotations.Forms.Field(Date.now() + "s", {
      type: "Sig",
    });
    let signAnnot = new Annotations.SignatureWidgetAnnotation(field, {
      appearance: "_DEFAULT",
      appearances: {
        _DEFAULT: {
          Normal: {
            data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC",
            offset: {
              x: 100,
              y: 100,
            },
          },
        },
      },
    });
    signAnnot.PageNumber = page_idx;
    const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
    signAnnot.Rotation = rotation;
    if (rotation === 270 || rotation === 90) {
      signAnnot.Width = 50.0 / zoom;
      signAnnot.Height = 250.0 / zoom;
    } else {
      signAnnot.Width = 150.0 / zoom;
      signAnnot.Height = 50.0 / zoom;
    }
    signAnnot.X = (page_point.x || page_info.width / 2) - signAnnot.Width / 2;
    signAnnot.Y = (page_point.y || page_info.height / 2) - signAnnot.Height / 2;
    // textAnnot.custom = {
    //   type,
    //   value,
    //   flag,
    //   name: `${assignee}_${type}_`,
    // };
    // set the type of annot
    // signAnnot.setContents('Sign Here');
    // customize styles of the form field
    fieldManager.addField(field);
    if (userId) signAnnot.setCustomData("user", userId);
    // annotationManager.deselectAllAnnotations();
    annotationManager.addAnnotation(signAnnot);
    await annotationManager.drawAnnotationsFromList([signAnnot]);
    // annotationManager.selectAnnotation(signAnnot);
  };

  const drop = (e: any, instance: any) => {
    const { docViewer } = instance;
    const scrollElement = docViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;
    setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
    e.preventDefault();
    return false;
  };

  const handleConfirmation = async (res: boolean) => {
    try {
      setLoading(true);
      if (res) {
        if (!inta) return;
        //fetch existing doc and merge annotations and upload to backend again
        let xfdfArr: any[] = [];
        const { Core } = inta;
        const { annotationManager, documentViewer, PDFNet } = Core;
        const currentXFDF = await annotationManager.exportAnnotations({
          widgets: false,
          fields: false,
        });
        XFDF.current = currentXFDF;
        xfdfArr = [...xfdfArr, currentXFDF];
        const response = await getdraftbyid(props.draftDetails.id);
        if (response) {
          let signatory = response.signatories;
          signatories.current = [...signatory];
          if (signatory.length > 0) {
            let XFDF = signatory
              .map((data: any) => data.xfdf)
              .filter((data: any) => data);
            xfdfArr = [...xfdfArr, ...XFDF];
          }
        }
        mergingAnnotations(xfdfArr);
      }
      setopenConfirmationDialog(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const mergingAnnotations = async (xfdfArr: any[]) => {
    try {
      setLoading(true);
      if (!inta) return;
      const { Core } = inta;
      const { documentViewer, PDFNet } = Core;
      await PDFNet.initialize();
      const doc = await documentViewer.getDocument().getPDFDoc();
      doc.initSecurityHandler();
      let i;
      for (i = 0; i < xfdfArr.length; i++) {
        let fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfArr[i]);
        await doc.fdfMerge(fdfDoc);
        await doc.flattenAnnotations();
      }
      const docbuf = await doc.saveMemoryBuffer(
        PDFNet.SDFDoc.SaveOptions.e_linearized
      );
      const blob = new Blob([docbuf], {
        type: "application/pdf",
      });
      updateSignatureAndUploadDoc(blob);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const isLoggedInUser = (id: string) => {
    const user_data = getLocalStorage("user_profile");
    if (id === user_data.id) {
      return true;
    }
    return false;
  };

  const updateSignatureAndUploadDoc = async (document: any) => {
    try {
      const payload: any = {};
      setLoading(true);
      let signatory: any = null;
      const { data } = await getIPAddress();
      let signatoryArr = [...signatories.current];
      if (signatoryArr.length > 0) {
        signatory = signatoryArr.find((data) => isLoggedInUser(data.id));
      }
      if (signatory) {
        signatory = {
          ...signatory,
          isSigned: true,
          signed_date: new Date().toISOString(),
          xfdf: XFDF.current,
          ip_address: data?.ip,
        };
        let index = signatoryArr.findIndex((data) => data.id == signatory.id);
        signatoryArr.splice(index, 1, signatory);
      }
      let totalCount = 0;
      let signedCount = 0;
      signatoryArr.forEach((data) => {
        totalCount += 1;
        if (data?.isSigned) {
          signedCount += 1;
        }
      });
      if (signedCount > 0 && totalCount > 0) {
        payload["status"] = `${signedCount}/${totalCount} Signature Pending`;
      }
      if (signedCount == totalCount && totalCount > 0) {
        payload["status"] = draftStatus.CONTRACT_EXECUTED_SUCCESSFULLY;
      }
      payload["signatories"] = signatoryArr;
      const file = document;
      const files_ = [];
      file["name"] = "signature_document" + ".pdf";
      let reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          files_.push({
            name: props.fileName,
            size: file.size,
            type: file.type,
            template_type: "",
            file_type: "Contract_Draft",
          });
        };
      }
      uploadSignatureDoc([file], payload);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const uploadSignatureDoc = (files: any, payload: any) => {
    try {
      setLoading(true);
      if (props.uploadFile) {
        props.uploadFile(files, false, (response: any, error: any) => {
          if (response) {
            updateSignatureMetaData(payload, response);
          }
          if (error) {
            console.error(error);
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updateSignatureMetaData = async (payload: any, presignedUrl: any) => {
    try {
      setLoading(true);
      if (presignedUrl && presignedUrl.file_path) {
        payload.link = presignedUrl.file_path;
      }
      const response = await updateContractMetaData(
        payload,
        props.draftDetails.id
      );
      if (response) {
        handleClose(response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const [tabName, setTabName] = useState<string>(
    props.draftDetails.open_for_collab && (isOwner() || isCollaborator() > 0)
      ? "Annotation"
      : "Rules"
  );

  useEffect(() => {
    if (inta) {
      const { UI, Core } = inta;
      const { documentViewer, Annotations, annotationManager } = Core;
      if (tabName === "Annotation") {
        UI.textPopup.update([
          {
            type: "actionButton",
            img: "/static_images/draft-delete.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
                setAnnotationCard({
                  isShow: true,
                  type: "remove",
                });
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/re-order.svg",
            onClick: () => {
              addManually(documentViewer);
            },
          },
          {
            type: "actionButton",
            img: "/static_images/draft-add-to-clause-library-icn.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                setShowAnnotationAnnotationCard(true);
                addAnnotation(inta);
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/comment.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                setShowAnnotationCommentCard(true);
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/text.png",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
                setAnnotationCard({
                  isShow: true,
                  type: "add",
                });
              }
            },
          },
        ]);
      } else {
        UI.textPopup.update([
          {
            type: "actionButton",
            img: "/static_images/draft-view-click.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
                setAnnotationCard({
                  isShow: true,
                  type: "add",
                });
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/draft-copy-icn.svg",
            onClick: () => {
              copyToClipboard(
                "Copy",
                documentViewer,
                Annotations,
                annotationManager
              );
            },
          },
          {
            type: "actionButton",
            img: "/static_images/draft-delete.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
                setAnnotationCard({
                  isShow: true,
                  type: "remove",
                });
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/re-order.svg",
            onClick: () => {
              addManually(documentViewer);
            },
          },
          {
            type: "actionButton",
            img: "/static_images/draft-clause-library.svg",
            onClick: () => callAction("Add Clause Library...", documentViewer),
          },
          {
            type: "actionButton",
            img: "/static_images/draft-add-to-clause-library-icn.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                setShowAnnotationAnnotationCard(true);
                addAnnotation(inta);
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/comment.svg",
            onClick: () => {
              if (props.draftDetails.open_collab) {
                setShowAnnotationCommentCard(true);
                const selectedText = documentViewer.getSelectedText();
                setannotationDataPoint(selectedText);
              }
            },
          },
          {
            type: "actionButton",
            img: "/static_images/clause_copy.svg",
            onClick: () => callAction("Copy text...", documentViewer),
          },
        ]);
      }
    }
  }, [tabName, props.draftDetails]);

  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      className="draft-main"
    >
      <DialogTitle>
        <div className="row m-0 p-0">
          <div className="col-md-3">
            <span style={{ fontSize: "16px" }}>
              <span style={{ marginRight: "10px", cursor: "pointer" }}>
                <img
                  style={{ width: "15px" }}
                  src="/static_images/back-arrow.svg"
                  alt="close_icon"
                  onClick={() => handleClose(null)}
                />
              </span>
              Contract Draft View {">"} {props.fileName}
            </span>
          </div>
          <div className="col-md-7">
            {openSnackbar.open ? (
              <div className="w-50 position-fixed d-flex justify-content-center">
                <div
                  className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                  style={{
                    marginLeft: "-25px",
                    padding: "10px",
                    width: "60%",
                    height: "42px",
                    borderRadius: "100px",
                  }}
                >
                  <div className="d-flex text-muted" style={{ gap: "10px" }}>
                    {openSnackbar.msg}
                  </div>

                  {openSnackbar.isPresign ? (
                    <>
                      <img
                        src="/static_images/crossicon.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                        onClick={() => {}}
                      />
                      <img
                        src="/static_images/selectrighticon.svg"
                        className="pl-1 w-10"
                        onClick={() => {
                          updateStatus("approved");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <div
                      className="d-flex cursor-pointer"
                      style={{ gap: "10px", color: "#88305F" }}
                      onClick={closeSnackbar}
                    >
                      OK
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-2">
            <div className="d-flex justify-content-end">
              {!props.draftDetails.status
                .toLowerCase()
                .includes("signature pending") && (
                <div className="close-icn" onClick={downloadDoc}>
                  {" "}
                  <img
                    style={{ width: "20px" }}
                    src="/static_images/file-download.svg"
                    alt="close_icon"
                  />
                </div>
              )}
              {value == 2 ? (
                <div>
                  <img
                    src="/static_images/skip-previous-outline .svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let endRulesDeviation =
                        document.getElementById("RulesDeviation");
                      if (
                        endRulesDeviation !== null &&
                        endRulesDeviation !== undefined
                      ) {
                        setscrollIndex(1);
                        endRulesDeviation.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }
                    }}
                  />
                  <img
                    src="/static_images/horizontal-scroll-arrow-left.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let scroll_index = scrollIndex - 1;
                      let prevdiv = document.getElementById(
                        `deviation-${scroll_index}`
                      );
                      if (prevdiv !== null && prevdiv !== undefined) {
                        setscrollIndex(scroll_index);
                        prevdiv.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }
                    }}
                  />
                  <img
                    src="/static_images/horizontal-scroll-arrow-right.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let nextdiv = document.getElementById(
                        `deviation-${scrollIndex}`
                      );
                      if (nextdiv !== null && nextdiv !== undefined) {
                        setscrollIndex(scrollIndex + 1);
                        nextdiv.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "nearest",
                        });
                      }
                    }}
                  />
                  <img
                    src="/static_images/skip-next-outline.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      let endRulesDeviation =
                        document.getElementById("RulesDeviation");
                      if (
                        endRulesDeviation !== null &&
                        endRulesDeviation !== undefined
                      ) {
                        let lastIndex = deviationPolicyData.length;
                        setscrollIndex(lastIndex);
                        endRulesDeviation.scrollIntoView({
                          behavior: "smooth",
                          block: "end",
                          inline: "nearest",
                        });
                      }
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </DialogTitle>

      <DialogContent dividers className="m-0 pt-16 pb-16 pl-0 pr-0">
        {isLoading && <CircularProgress className="loader" />}
        <div className="row col-12 m-0 p-0 h-100">
          <div className="col-md-6 m-0 p-0">
            <div className="d-flex flex-column h-100" style={{ gap: "20px" }}>
              <div
                className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                style={{
                  marginLeft: "17px",
                  padding: "10px",
                  width: "95.60%",
                  height: "42px",
                  borderRadius: "100px",
                }}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/settingiconnew.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTheme(!isDark);
                      if (isDark) {
                        lightTheme();
                      } else {
                        darkTheme();
                      }
                    }}
                  />
                  {/* <img
                    src="/static_images/bookixon.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src="/static_images/pageIcon.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                  /> */}
                  {templateKeyValue && (
                    <img
                      src="/static_images/data-dictionary-icn.svg"
                      className="pl-1 w-10"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setshowTemplateField(!showTemplateField);
                      }}
                    />
                  )}
                  {!templateKeyValue && (
                    <img
                      src="/static_images/template-disabled.svg"
                      className="pl-1 w-10"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                  <img
                    src="/static_images/kdp-icn.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setenableKDP(!enableKDP);
                      Kdp_Parse();
                    }}
                  />
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/serchiconplus.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => ZoomIn()}
                  />
                  <img
                    src="/static_images/serchiconminus.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => Zoomout()}
                  />
                  <img
                    src="/static_images/serchiconnew.svg"
                    className="pl-1 w-10"
                    onClick={() => {
                      search();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <ToggleButtonGroup
                    value={draftValue}
                    size="small"
                    exclusive
                    onChange={changeDefaultValue}
                    aria-label="text alignment"
                  >
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <ToggleButton value="collaboration">
                        <Tooltip title="Open For Collaboration" arrow>
                          <img
                            src="/static_images/CommitNotify.svg"
                            className="pl-1 w-10"
                            style={{ height: "20px" }}
                          />
                        </Tooltip>
                      </ToggleButton>
                    </button>
                    <button
                      style={{
                        border: "none",
                        background: "transparent",
                      }}
                      className={`${
                        isOwner() &&
                        props?.draftDetails?.approval_remaining_count === 0 &&
                        deviationStatistics.deviation_count === 0
                          ? ""
                          : "disabled-buttons"
                      }`}
                    >
                      <ToggleButton
                        value="commitnotify"
                        disabled={
                          isOwner() &&
                          props?.draftDetails?.approval_remaining_count === 0 &&
                          deviationStatistics.deviation_count === 0
                            ? false
                            : true
                        }
                      >
                        <Tooltip title="Commit and Notify" arrow>
                          <img
                            src="/static_images/CommitNotify.svg"
                            className="pl-1 w-10"
                            style={{ height: "20px" }}
                          />
                        </Tooltip>
                      </ToggleButton>
                    </button>
                  </ToggleButtonGroup> */}

                  <div className="d-flex">
                    {/* <button
                        style={{
                          border: "0px",
                          borderRadius: "5px",
                          padding: "5px",
                          borderColor: "#88305F",
                          background: "transparent",
                        }}
                        className="cursor-pointer"
                        onClick={() => setDraftValue("collaborator")}
                      >
                        <img
                          src="/static_images/collaboration.png"
                          className="pl-1 w-10"
                          style={{ height: "20px" }}
                        />
                      </button> */}
                    {isOwner() && (
                      <>
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Switch
                              style={{
                                color: "#88305F",
                              }}
                              checked={props.draftDetails.open_collab}
                              onChange={async (e: any) => {
                                if (!props.draftDetails.open_for_collab) {
                                  const payload = {
                                    open_for_collab: true,
                                    open_collab: true,
                                  };
                                  const response = await updateContractMetaData(
                                    payload,
                                    props.draftDetails.id
                                  );
                                  if (response) {
                                    props.setdraftDetails(response);
                                  }
                                } else {
                                  const payload = {
                                    open_collab: e.target.checked,
                                  };
                                  const response = await updateContractMetaData(
                                    payload,
                                    props.draftDetails.id
                                  );
                                  if (response) {
                                    props.setdraftDetails(response);
                                  }
                                }
                                setCollaborationType(e.target.checked);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label=""
                        ></FormControlLabel>
                        <FormHelperText className="d-flex align-items-center">{`${
                          props.draftDetails.open_for_collab
                            ? props.draftDetails.open_collab
                              ? "Opened For Collaboration(Click here to Close collab.)"
                              : "Closed For Collaboration(Click here to Open collab.)"
                            : "Click Here to Start Collaboration"
                        }`}</FormHelperText>
                      </>
                    )}
                    <Tooltip title="Commit and Notify" arrow>
                      <button
                        style={{
                          border: "0px",
                          borderRadius: "5px",
                          padding: "5px",
                          borderColor: "#88305F",
                          background: "transparent",
                          marginLeft: "10px",
                        }}
                        disabled={
                          isOwner() &&
                          props?.draftDetails?.approval_remaining_count === 0 &&
                          deviationStatistics.deviation_count === 0
                            ? false
                            : true
                        }
                        onClick={() => {
                          setDraftValue("commitnotify");
                        }}
                        className={`cursor-pointer ${
                          isOwner() &&
                          props?.draftDetails?.approval_remaining_count === 0 &&
                          deviationStatistics.deviation_count === 0 &&
                          !props.draftDetails.open_collab
                            ? ""
                            : "disabled-buttons"
                        }`}
                      >
                        <img
                          src="/static_images/CommitNotify.svg"
                          className="pl-1 w-10"
                          style={{ height: "20px" }}
                        />
                      </button>
                    </Tooltip>
                  </div>
                  <div className="mt-2"></div>
                </div>
              </div>
              <div
                ref={documentContainer}
                id="document-viewer"
                className="col-md-12 pr-4"
                style={{ height: "100%" }}
              >
                {/* {props.fileLink && (<DocumentViewer
                            viewerUrl={`https://docs.google.com/gview?url=${props.fileLink}&embedded=true`}
                            url={props.fileLink}
                            viewer="url"
                            style={{ width: '100%', height: '100%' }}
                        />)} */}
              </div>
            </div>
          </div>
          <div className="col-md-6 m-0 p-0 tab-container">
            {modalVisible == "Replace with CL" ? (
              <div className="mr-2">
                <DeviationCLComponent
                  handleClose={() => setModalVisible("")}
                  setcopiedText={() => {
                    setcopiedText("");
                  }}
                  copiedText={copiedText}
                  selectedClause={selectedClause}
                  recommendedValue={recommendedValue}
                  pdftronPara={deviationDataPoint}
                  nodeId={nodeId}
                  draft_id={props.draftDetails?.id}
                  paraId={paraId}
                  replaceWithCL={() => {
                    setrulesdeviationLoader(true);
                    getDeviationPolicyFromApprovals();
                  }}
                  replaceWithClauseText={(clause: any) => {
                    replaceClauseText(clause);
                  }}
                />
              </div>
            ) : (
              <Box sx={{ width: "100%", maxWidth: "100%" }}>
                <Box
                  className="pr-3"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="draft-tab"
                    style={{
                      borderRadius: "100px",
                      //position: "fixed"
                    }}
                    variant="scrollable"
                  >
                    {props.draftDetails.open_for_collab &&
                      (isOwner() || isCollaborator() > 0) && (
                        <Tab
                          disableRipple
                          label="Annotations and Comments"
                          onClick={() => setTabName("Annotation")}
                          {...a11yProps(0)}
                        />
                      )}
                    {/* <Tab
                      disableRipple
                      label="Log"
                      {...a11yProps(1)}
                      style={{ width: "20px" }}
                    /> */}
                    <Tab
                      disableRipple
                      label="Rules - Deviations"
                      onClick={() => setTabName("Rules")}
                      {...a11yProps(2)}
                    />
                    <Tab
                      disableRipple
                      label="Approvals"
                      {...a11yProps(3)}
                      onClick={() => setTabName("Approvals")}
                    />
                    <Tab
                      disableRipple
                      label="Support Document"
                      onClick={() => setTabName("Support")}
                      {...a11yProps(4)}
                    />
                    <Tab
                      disableRipple
                      label="Clause Library"
                      onClick={() => setTabName("Clause")}
                      {...a11yProps(5)}
                    />
                    {(props?.draftDetails?.status
                      ?.toLowerCase()
                      .includes("signature pending") ||
                      props?.draftDetails?.status
                        .toLowerCase()
                        .includes("contract executed successfully")) && (
                      <Tab disableRipple label="Signatures" {...a11yProps(6)} />
                    )}
                    {isOwner() &&
                    props?.draftDetails?.approval_remaining_count === 0 &&
                    deviationStatistics.deviation_count === 0 &&
                    draftValue === "commitnotify" &&
                    !props.draftDetails.open_collab ? (
                      props?.draftDetails?.status
                        .toLowerCase()
                        .includes("signature pending") ||
                      props?.draftDetails?.status
                        .toLowerCase()
                        .includes("contract executed successfully") ? (
                        <Tab
                          disableRipple
                          label="Commit and Notify"
                          {...a11yProps(7)}
                        />
                      ) : (
                        <Tab
                          disableRipple
                          label="Commit and Notify"
                          {...a11yProps(6)}
                        />
                      )
                    ) : null}
                  </Tabs>
                </Box>
                {props.draftDetails.open_for_collab &&
                  (isOwner() || isCollaborator() > 0) && (
                    <TabPanel value={value} index={0}>
                      <AnnotationAndCommentTab
                        showAnnotationCommentCard={showAnnotationCommentCard}
                        showAnnotationAnnotationCard={
                          showAnnotationAnnotationCard
                        }
                        setShowAnnotationAnnotationCard={
                          setShowAnnotationAnnotationCard
                        }
                        setShowAnnotationCommentCard={
                          setShowAnnotationCommentCard
                        }
                        draftDetails={props.draftDetails}
                        setAnnotationCommentDataPoint={(
                          kdpDataPoint: string
                        ) => {
                          get_Kdpdata(kdpDataPoint);
                        }}
                        annotationDataPoint={annotationDataPoint}
                        // addAnnotation={addAnnotation}
                        draftComments={draftComments}
                        setDraftComment={setDraftComment}
                        draftAnnotations={draftAnnotations}
                        setDraftAnnotation={setDraftAnnotation}
                        getDraftCommentCall={getDraftCommentCall}
                        getDraftAnnotations={getDraftAnnotations}
                        updateSpecificAnnotation={updateSpecificAnnotation}
                        isOwnerID={isOwnerID}
                        addAnnotationInDoc={AddAnnotationInDoc}
                        showAnnotationCard={showAnnotationCard}
                        removeAnnotation={removeAnnotation}
                        setAnnotationCard={setAnnotationCard}
                        setdraftDetails={props.setdraftDetails}
                      />
                    </TabPanel>
                  )}
                {/* <TabPanel
                  value={value}
                  index={
                    props.draftDetails.open_for_collab &&
                    (isOwner() || isCollaborator() > 0)
                      ? 1
                      : 0
                  }
                >
                  <LogTab
                    draft_id={props.draftDetails?.id}
                    get_Kdpdata={(clauseText: any) => {
                      get_Kdpdata(clauseText);
                    }}
                  />
                </TabPanel> */}
                <TabPanel
                  value={value}
                  index={
                    props.draftDetails.open_for_collab &&
                    (isOwner() || isCollaborator() > 0)
                      ? 1
                      : 0
                  }
                >
                  <RuleAndDeviationTab
                    setModalVisible={(data: any) => setModalVisible(data)}
                    draft_id={props.draftDetails?.id}
                    copiedText={copiedText}
                    setcopiedText={() => setcopiedText("")}
                    setselectedClause={(clause: string) => {
                      setselectedClause(clause);
                    }}
                    deviationPolicyData={deviationPolicyData}
                    deviationStatistics={deviationStatistics}
                    setdeviationDataPoint={(kdpDataPoint: string) => {
                      setdeviationDataPoint(kdpDataPoint);
                      //console.log("kdpDataPoint", kdpDataPoint);
                      get_Kdpdata(kdpDataPoint);
                    }}
                    setrecommendedValue={(recommendedValue: string) => {
                      setrecommendedValue(recommendedValue);
                    }}
                    setnodeId={(nodeId: string) => {
                      setnodeId(nodeId);
                    }}
                    setparaId={(paraId: string) => {
                      setparaId(paraId);
                    }}
                    setdeviationapprovalId={(deviationApprovalId: string) => {
                      setdeviationapprovalId(deviationApprovalId);
                    }}
                    deletedeviation={(clause: any) => {
                      console.log("clause", clause);
                      setremovedeviationIds(clause.nodeID);
                      setremovedeviationtype(clause.type);
                      setremovedeviationclauseText(clause.clause_text);

                      if (clause.type != "Not a Deviation") {
                        setModalVisible("Not a Deviation");
                      }
                    }}
                    askforApproval={() => {
                      console.log("Ask For Approval");
                      askforApproval();
                    }}
                    setapprovalType={(type: string) => {
                      setapprovalType(type);
                    }}
                    rulesdeviationLoader={rulesdeviationLoader}
                    refreshRulesAndRegulation={() => {
                      setrulesdeviationLoader(true);
                      getDeviationPolicyFromApprovals();
                    }}
                    addManually={(clause: any) => {
                      manualDeviation(clause);
                    }}
                    compliantdeviationPolicyData={compliantdeviationPolicyData}
                    setcompliantdeviationDatapoint={(clause: any) => {
                      setcompliantdeviationDatapoint(clause);
                      console.log("clause: ", clause, deviationDataPoint);
                    }}
                    selectedSection={selectedSection}
                    setselectedSection={(section: any) => {
                      setselectedSection(section);
                    }}
                    setdraftselectedSection={(section: any) => {
                      setdraftselectedSection(section);
                    }}
                    draftselectedSection={draftselectedSection}
                    setvalidationPopup={() => {
                      setvalidationPopup({
                        modal: true,
                        text: "Please click on the clause text requiring deviation approval",
                      });
                    }}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    props.draftDetails.open_for_collab &&
                    (isOwner() || isCollaborator() > 0)
                      ? 2
                      : 1
                  }
                >
                  <ApprovalTab
                    setapprovals={() => {
                      getApprovalList();
                    }}
                    approvals={approvals}
                    updateStatus={(data: any) => {
                      setapprovalsLoader(true);
                      updateRequestApproval(data);
                    }}
                    get_Kdpdata={(clauseText: any) => {
                      get_Kdpdata(clauseText);
                    }}
                    approvalsLoader={approvalsLoader}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    props.draftDetails.open_for_collab &&
                    (isOwner() || isCollaborator() > 0)
                      ? 3
                      : 2
                  }
                >
                  <SupportDocTab
                    supportdoc={props.draftDetails?.requisition_id}
                  />
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    props.draftDetails.open_for_collab &&
                    (isOwner() || isCollaborator() > 0)
                      ? 4
                      : 3
                  }
                >
                  {/* Add Clause Library View */}
                  <div>
                    <p className="mt-3">
                      Showing {clauseLibraryData.length} clauses relevant to{" "}
                      <span
                        style={{
                          color: "#996C84",
                        }}
                      >
                        {"<Contract Type>"}
                      </span>
                    </p>
                    <div className="d-flex">
                      <input
                        style={{
                          width: "15px",
                          accentColor: "#88305F",
                        }}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.currentTarget.checked) {
                            onSearch(
                              "avoidable",
                              "clauseType",
                              clauseLibraryData
                            );
                          } else {
                            onSearch("", "clauseType", clauseLibraryData);
                          }
                        }}
                      ></input>
                      <p className="mb-0 ml-2">Show Avoidable Clauses</p>
                    </div>
                    <div className="mt-5">
                      <div
                        className="row m-0 p-3"
                        style={{
                          backgroundColor: "#FAFAFA",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <div className="col-md-4">
                          <div className="row">
                            <div className="col-md-10 pl-0" ref={folderName}>
                              <label className={"cursor-pointer"}>
                                Folder Name
                              </label>
                              <img
                                onClick={() => {
                                  setFolderName(true);
                                }}
                                className="ml-2"
                                src="/static_images/section-search.svg"
                                alt="section-title"
                              />
                              {isFolderName ? (
                                <PopOver
                                  fieldRef={folderName}
                                  closePop={() => setFolderName(false)}
                                  onSearch={onSearch}
                                  value={searchFolderName}
                                  type={"folderName"}
                                  data={clauseLibraryData}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4" ref={clauseNo}>
                          Clause No.
                          <img
                            onClick={() => {
                              setClauseNo(true);
                            }}
                            className="ml-2"
                            src="/static_images/section-search.svg"
                            alt="section-created"
                          />
                          {isClauseNo ? (
                            <PopOver
                              fieldRef={clauseNo}
                              closePop={() => setClauseNo(false)}
                              onSearch={onSearch}
                              value={searchClause}
                              type={"clauseNo"}
                              data={clauseLibraryData}
                            />
                          ) : null}
                        </div>
                        <div className="col-md-4" ref={preview}>
                          Preview
                          <img
                            onClick={() => {
                              setPreview(true);
                            }}
                            className="ml-2"
                            src="/static_images/section-search.svg"
                            alt="section-created"
                          />
                          {isPreview ? (
                            <PopOver
                              fieldRef={preview}
                              closePop={() => setPreview(false)}
                              onSearch={onSearch}
                              value={searchPreview}
                              type={"preview"}
                              data={clauseLibraryData}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      {clauseLibraryData.map((item: any, index: number) => {
                        return (
                          <Accordion key={index} className="m-0 accordian-head">
                            <AccordionSummary
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              className="section-accordian"
                            >
                              <div
                                className="col-md-4"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "start",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="row w-100"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      width: "50%",
                                    }}
                                  >
                                    <img
                                      className="clause-folder-icn"
                                      src="/static_images/section-folder.svg"
                                      alt="clause-folder"
                                    />
                                    <label
                                      className={"cursor-pointer m-0"}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {Object.keys(item)[0]}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </AccordionSummary>
                            {item[`${Object.keys(item)[0]}`].map(
                              (element: any, index: any) => {
                                return (
                                  <AccordionDetails
                                    key={index}
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                  >
                                    <div
                                      className="col-md-4"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      <img
                                        className="clause-folder-icn ml-3"
                                        src={`${
                                          element?.clause_type === "recommended"
                                            ? "/static_images/sub-section-success.svg"
                                            : "/static_images/avoidable-icon.svg"
                                        }`}
                                        alt="clause-folder"
                                      />
                                      <Tooltip
                                        color="black"
                                        title={element?.clause_name}
                                        arrow
                                      >
                                        <label
                                          style={{
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            width: "100%",
                                          }}
                                          className="m-0 cursor-pointer"
                                          onClick={() => {}}
                                        >
                                          {element?.clause_name}
                                        </label>
                                      </Tooltip>
                                    </div>
                                    <p className="col-md-4 m-0 section-label cursor-pointer">
                                      {element?.clause_no}
                                    </p>
                                    <div className="col-md-4 m-0">
                                      <div className="m-0 d-flex align-items-center">
                                        <p className="section-label m-0">
                                          {element?.description}
                                        </p>
                                        <Tooltip
                                          color="black"
                                          title={element?.clause_text}
                                          arrow
                                        >
                                          <img
                                            style={{
                                              height: "16px",
                                              width: "16px",
                                            }}
                                            src="/static_images/section-tooltip.svg"
                                            alt="clause-folder"
                                            className="ml-1"
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </AccordionDetails>
                                );
                              }
                            )}
                          </Accordion>
                        );
                      })}
                    </div>
                  </div>
                </TabPanel>
                {(props?.draftDetails?.status
                  .toLowerCase()
                  .includes("signature pending") ||
                  props?.draftDetails?.status
                    .toLowerCase()
                    .includes("contract executed successfully")) && (
                  <TabPanel
                    value={value}
                    index={
                      props.draftDetails.open_for_collab &&
                      (isOwner() || isCollaborator() > 0)
                        ? 5
                        : 4
                    }
                  >
                    <div>
                      <SignaturesTab
                        selectedDraft={props.draftDetails}
                        dropPoint={dropPoint}
                        addField={addField}
                        addedSignatureField={addedSignatureFields}
                        openConfirmDialog={() =>
                          setopenConfirmationDialog(true)
                        }
                        hideSignatureButton={hideSignatureButton}
                      />
                    </div>
                  </TabPanel>
                )}

                {isOwner() &&
                props?.draftDetails?.approval_remaining_count === 0 &&
                deviationStatistics.deviation_count === 0 &&
                draftValue === "commitnotify" ? (
                  <TabPanel
                    value={value}
                    index={
                      props?.draftDetails?.status
                        .toLowerCase()
                        .includes("signature pending") ||
                      (props?.draftDetails?.status
                        .toLowerCase()
                        .includes("contract executed successfully") &&
                        (isOwner() || isCollaborator() > 0))
                        ? props.draftDetails.open_for_collab
                          ? 6
                          : 5
                        : props.draftDetails.open_for_collab
                        ? 5
                        : 4
                    }
                  >
                    <div>
                      <NotifyCommit
                        selectedDraft={props.draftDetails}
                        openAssignedTo={props.openAssignedTo}
                        setSpecificDraft={props.setdraftDetails}
                        onClose={() => props.onClose(null)}
                      />
                    </div>
                  </TabPanel>
                ) : null}
              </Box>
            )}
          </div>
        </div>
        {modalVisible == "Request Approval" && (
          <DeviationRequestBtnmodal
            modalVisible={modalVisible}
            handleClose={() => setModalVisible("")}
            approvers={approvers}
            requestApproval={(approvers: any[], comments: string) => {
              requestApproval(approvers, comments);
            }}
            approvalType={approvalType}
          />
        )}
        {modalVisible == "Not a Deviation" && (
          <DeviationButtonmodal
            modalVisible={modalVisible}
            handleClose={() => setModalVisible("")}
            removeDeviation={() => {
              setModalVisible("");
              remove_deviation();
            }}
          />
        )}
        {/* {openWarningPopup && (
          <WarningPopup
            openWarningPopup={openWarningPopup}
            onClose={() => {
              setWarningPopup(false);
            }}
            onClick={handleClose}
          />
        )} */}

        {deletedeviationPopup && (
          <RemoveDeviation
            deletedeviationPopup={deletedeviationPopup}
            onClose={() => {
              setdeletedeviationPopup(false);
            }}
            removeDeviation={() => {
              remove_deviation();
            }}
          />
        )}

        {manualdeviationPopup && (
          <ManualDeviation
            manualdeviationPopup={manualdeviationPopup}
            onClose={() => {
              setmanualdeviationPopup(false);
            }}
            addManaualDeviation={() => {
              addManualDeviation();
            }}
          />
        )}

        {modalVisible == "Deviation" && (
          <Deviationmodal
            modalVisible={modalVisible}
            handleClose={() => setModalVisible("")}
            markasDeviation={() => {
              markasDeviation();
            }}
          />
        )}

        {validationPopup && (
          <ValidationPopup
            validationPopup={validationPopup}
            onClose={() => {
              setvalidationPopup({ modal: false, text: "" });
            }}
          />
        )}

        {openConfirmationDialog && (
          <ConfirmationPopup
            open={openConfirmationDialog}
            onClose={handleConfirmation}
            msg={"Are you sure you want to sign this draft ?"}
            title={"Confirm Signature"}
          />
        )}

        {/* <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar.open}
          onClose={closeSnackbar}
          message={openSnackbar.msg}
          key="bottomCenter"
          action={action}
        /> */}
      </DialogContent>
    </Dialog>
  );
};

export default PdfViewer;
