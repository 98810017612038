import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import Modal from "@material-ui/core/Modal";
import { Box, Typography } from "@material-ui/core";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "145px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  modalVisible: any;
  handleClose: any;
  markasDeviation: () => void;
}

const Deviationmodal: React.FC<Props> = (props: Props) => {
  return (
    <>
      <Modal
        open={props.modalVisible == "Deviation"}
        className="deletemodal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={hierarchyStyle} className="position-relative">
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              Mark as ‘Deviation’
              <span className="mr-0">
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                  onClick={() => props.handleClose()}
                />
              </span>
            </div>
          </Typography>
          <Typography
            style={{ margin: "20px", gap: "10px" }}
            className="d-flex flex-column"
          >
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#626262",
              }}
            >
              Are you user you want to mark this deviation?
            </div>
            <div className=" row col-12">
              <div className="col-6">
                <button
                  style={{ cursor: "pointer" }}
                  className="btn-deviation"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6">
                <button
                  style={{ cursor: "pointer" }}
                  className="btn-deviation-cl"
                  onClick={() => props.markasDeviation()}
                >
                  Mark as ‘Deviation’
                </button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Deviationmodal);
