import * as React from "react";
import { isNullOrUndefined } from "is-what";
import { Box, Modal, Typography } from "@material-ui/core";

interface Props {
  manualdeviationPopup: boolean;
  onClose: any;
  addManaualDeviation: ()=>void;
}

export default class ManualDeviation extends React.Component<Props> {
  componentDidMount() {
    document.addEventListener("mouseup", this.handleOutsideClick, false);
  }

  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("deleteModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("delete-btn-outside-click")?.click();
        window.location.reload();
      }
    }
  };

  style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 15,
  };

  render() {
    return (
      <Modal
        open={this.props.manualdeviationPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={this.style}>
          <div className="modal-header">
            <p className="m-0">Mark as ‘Deviation’</p>
            <img
              className="cursor-pointer"
              src="/static_images/white-close-modal-icn.svg"
              alt="clause-folder"
              onClick={() => this.props.onClose()}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Typography>
            Are you sure you want to mark this deviation
            </Typography>
            <div
              className="modal-body modal-subtitle mt-4"
              style={{ margin: "0 25%", lineHeight: "28px" }}
              onClick={() => this.props.onClose()}
            >
              <span data-dismiss="modal" className="mailto-riverus">
                Cancel
              </span>
              <button
                className="upload-yellow-btn"
                style={{ float: "right", padding: "2% 10%" }}
                onClick={() => {
                  this.props.addManaualDeviation();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}