import React, { useEffect, useRef, useState } from "react";
import { FilterData, TemplateData } from "../State/templateState";
import "../Design/template.scss";
import DocViewerDialog from "./DocViewerDialog";
import { Tooltip } from "@material-ui/core";
import { History } from "history";
import CustomChip from "../../UniversalComponents/CustomChip/Component/CustomChip";
import Avatarfordraft from "../../Draft/Component/AvatarForDraft";

interface Props {
  templatesData: TemplateData[];
  getTemplates: () => void;
  history: History;
  selectedTemplate: string[];
  selectMultipleTemplates: (id: string) => void;
  applyFilter: (a: string[], b: string,c?:string) => void;
  filterKeyVals: FilterData;
  fieldNameList: any;
}
const ListViewTable = (props: Props) => {
  const [templates, setTempates] = useState<TemplateData[]>([]);
  const [openDoc, setOpenDoc] = useState(false);
  const [templateLink, setTemplateLink] = useState("");
  const [fileName, setFileName] = useState("");
  const [templateData, setTemplateData] = useState<TemplateData>();
  const [showFilter, setshowFilter] = useState(false);
  const chipEl = useRef(null);

  useEffect(() => {
    setTempates(props.templatesData);
  }, [props.templatesData]);

  const getExtraCount = (numberOfAssociates: number) => {
    let extraCount = numberOfAssociates - 3;
    return "+" + extraCount;
  };

  const openDocViewer = (template: TemplateData) => {
    setTemplateLink(template.templateLink || "");
    setFileName(template.name || "");
    setTemplateData(template);
    setOpenDoc(true);
  };

  const handleClose = () => {
    setOpenDoc(false);
  };

  const selectDraft = (event: any, id: string) => {
    event.stopPropagation();
    props.selectMultipleTemplates(id);
  };
  return (
    <>
      {templates.map((template: any, key: number) => {
        return (
          <div
            key={key}
            className={`col-md-12 template-list-view mb-2 ${
              template.id && props.selectedTemplate.indexOf(template.id) > -1
                ? "selected-draft"
                : ""
            }`}
            style={{
              height: "45px",
              backgroundColor: key % 2 != 0 ? "white" : "rgba(0,0,0,.04)",
            }}
          >
            <div className="row template-list-view-table">
              <div
                className="col-md-1"
                style={{ maxWidth: "2%", paddingLeft: "8px", marginTop: "7px" }}
              >
                {props.selectedTemplate.indexOf(template.id) == -1 && (
                  <input
                    onChange={(e) => selectDraft(e, template.id)}
                    style={{ width: "15px", height: "15px" }}
                    type="checkbox"
                    name="check"
                    id="check"
                  />
                )}
                {props.selectedTemplate.indexOf(template.id) > -1 && (
                  <img
                    onClick={(e) => selectDraft(e, template.id)}
                    className="checked-icon"
                    src="/static_images/checked_icon.svg"
                    alt="checked"
                  ></img>
                )}
              </div>
              <div className="col-md-1">
                <div className="text-overflow">{template.template_id}</div>
              </div>
              <div
                onClick={() => openDocViewer(template)}
                className="col-md-2"
                style={{ paddingLeft: "5px", flex: "0 0 22%", maxWidth: "22%" }}
              >
                <Tooltip title={template.name || ""} arrow>
                  <div className="text-overflow text-purple">
                    {(template.name === null || template.name === "") && (
                      <img src="/static_images/empty-dash.svg"></img>
                    )}
                    {template.name !== null && template.name}
                  </div>
                </Tooltip>
              </div>
              <div
                className="col-md-2"
                style={{
                  flex: "0 0 15%",
                  paddingLeft: "10px",
                  minWidth: "15%",
                  maxWidth: "15%",
                }}
              >
                <Tooltip title={template.templateType || ""} arrow>
                  <div
                    className="text-overflow m-0"
                    style={{ whiteSpace: "normal" }}
                  >
                    <CustomChip
                      data={[template.templateType]}
                      showItems={1}
                      addFilter={(type) =>
                        props.applyFilter([type.toLowerCase()], "templateType")
                      }
                      isCancel={
                        props.filterKeyVals["templateType"]
                          ? props.filterKeyVals["templateType"].length
                            ? true
                            : false
                          : false
                      }
                      cancelAction={(removeKey) => props.applyFilter([], "templateType",removeKey)}
                    />
                  </div>
                </Tooltip>
              </div>
              <div
                className="col-md-1"
                style={{ flex: "0 0 11.3%", maxWidth: "11.3%", padding: "0" }}
              >
                <Tooltip
                  title={
                    template.tags.map((tag: any) => tag.tag_name).join(", ") ||
                    ""
                  }
                  arrow
                >
                  <div className="text-overflow m-0" ref={chipEl}>
                    <CustomChip
                      data={template.tags.map((tag: any) => tag.tag_name)}
                      showItems={1}
                      addFilter={(tag) =>
                        props.applyFilter([tag.toLowerCase()], "tags")
                      }
                      isCancel={
                        props.filterKeyVals["tags"]
                          ? props.filterKeyVals["tags"].length
                            ? true
                            : false
                          : false
                      }
                      cancelAction={(removeKey) => props.applyFilter([], "tags",removeKey)}
                      displayAdditionalItem={() => {
                        setshowFilter(!showFilter);
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="col-md-1" style={{ flex: "0 0 9.3%", maxWidth: "9.3%", padding: "0" }}>
                <div>
                  {template.owner == null && (
                    <img src="/static_images/empty-dash.svg"></img>
                  )}
                  {template.owner != null && (
                    <Avatarfordraft users={[template.owner]} />
                  )}
                </div>
              </div>
              <div className="col-md-2">
                <div>
                  {template.createdOn === null || template.createdOn === "" ? (
                    <img src="/static_images/empty-dash.svg"></img>
                  ) : (
                    template.createdOn
                  )}
                </div>
              </div>
              <div className="col-md-2" style={{ flex: "0 0 12%" }}>
                <div>0</div>
              </div>
            </div>
          </div>
        );
      })}
      {openDoc && (
        <DocViewerDialog
          uploadContractData={() => undefined}
          uploadFile={() => {}}
          contractData={null}
          fromTemplate={true}
          dynamicFields={null}
          template={templateData}
          history={props.history}
          type="template"
          open={openDoc}
          onClose={handleClose}
          fileName={fileName}
          templateLink={templateLink}
          fieldNameList={props.fieldNameList}
        />
      )}
    </>
  );
};

export default ListViewTable;
