import { actionChannel } from "redux-saga/effects";

import {
  ClauseErrorManagementAction,
  //   GETCLAUSEERRORDATA_SUCCESS,
  GETCLAUSEERRORDATA,
  GETCLAUSEERRORDATA_SUCCESS,
} from "../Action/def";

import ClauseErrorManagementState, {
  defaultClauseErrorManagementState,
} from "../State/clauseErrorManagementState";
export default function clauserrorManagementReducer(
  state: ClauseErrorManagementState = defaultClauseErrorManagementState(),
  action: ClauseErrorManagementAction
): ClauseErrorManagementState {
  switch (action.type) {
    case GETCLAUSEERRORDATA: {
      return state;
    }
    case GETCLAUSEERRORDATA_SUCCESS: {
      return { ...state, clauseErrorData: action.payload.clauseErrorData };
    }

    default:
      return state;
  }
}
