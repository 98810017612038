import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "420px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  openModal: string;

  handleClosehierarchy: any;
  checkDocument: any;
  archiveData: any;
  reloadDocumentLibray: (data:any) => void;
}
interface State {
  position: any;
  value: any;
}

const AddHierarchyModal: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState("HirarchyValueOne");

  const [parentDropdown, setParentdropdown] = React.useState<any[]>([]);
  const [parentData, setPerentData] = React.useState("");
  const [childDropdown, setChilddropdown] = React.useState<any[]>([]);
  const [childData, setChildData] = React.useState<any>([]);
  const [loader, setloader] = React.useState<boolean>(false);
  const [openSnackbar, setopenSnackbar] = React.useState<any>({
    open: false,
    msg: ""
  });
  const handleChange = () => {
    if (props.checkDocument && props.checkDocument?.length > 1) {
      setValue("HirarchyValueOne");
    } else {
      if (value === "HirarchyValueOne") {
        setValue("HirarchyValueTwo");
      } else if (value === "HirarchyValueTwo") {
        setValue("HirarchyValueOne");
      }
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  useEffect(() => {
    const checkData = props.checkDocument?.map((val: any) => val.id);
    const Data = props.archiveData?.map((val: any) => val.id);
    ParentAPI(checkData, Data);
    ChildAPI(checkData, Data);
  }, []);
  const ParentAPI = (checkData: any, Data: any) => {
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/parent/`,

      {
        selectedFileId: "-1",
        fileIds: Data,
        bulkFileIds: checkData,
      }
    )
      .then((response) => {
        setParentdropdown(response?.data?.queryResult?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ChildAPI = (checkData: any, Data: any) => {
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/children/`,

      {
        selectedFileId: checkData[0],
        fileIds: Data,
      }
    )
      .then((response) => {
        setChilddropdown(response?.data?.queryResult?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleParentSubmit = () => {
    const checkData = props.checkDocument?.map((val: any) => val.id);
    AxiosGateWay.patch(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/${parentData}/`,

      {
        children: checkData,
      }
    )
      .then((response) => {
        // props.handleClosehierarchy();
        props.reloadDocumentLibray("Parent Document added successfully")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleExternal = (event: any) => {
    const {
      target: { value },
    } = event;
    setChildData(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChildSubmit = () => {
    const checkData = props.checkDocument?.map((val: any) => val.id);
    const checkOption = childDropdown?.filter((val: any) =>
      childData?.includes(val.fileName)
    );
    const checkID = checkOption?.map((val: any) => val?.fileId);

    AxiosGateWay.patch(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/${checkData[0]}/`,

      {
        children: checkID,
      }
    )
      .then((response) => {
        // props.handleClosehierarchy();
        props.reloadDocumentLibray("Child Document added successfully")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <Modal
          open={props.openModal === "modalHierarchy"}
          className="deletemodal"
          onClose={() => props.handleClosehierarchy()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={hierarchyStyle} className="position-relative">
            <Typography
              id="modal-modal-title"
              // variant="h6"
              // component="h2"
              className="myDocument-deleteFileTitle"
            >
              <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
                Add Hierarchy
                <span
                  className="mr-0"
                  onClick={() => props.handleClosehierarchy()}
                >
                  <img
                    src={"/static_images/Group 3610.svg"}
                    alt=""
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#818181",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </Typography>
            <Typography
              className="addtag-addHierarchy-D  mb-0"
              style={{ margin: "20px" }}
            >
              {loader && <CircularProgress className="loader" />}
              <div className="addtag-addHierarchy-D1">
                <span>
                  {" "}
                  A <button className="addtag-addHierarchy-btn">
                    Parent
                  </button>{" "}
                  signifies a 1 - to - many relationship
                </span>
                <span>
                  A <button className="addtag-addHierarchy-btn">Child</button>{" "}
                  signifies a many - to - 1 relationship
                </span>
              </div>
              <div className="">
                {value == "HirarchyValueOne" ? (
                  <img
                    className="addtag-addHierarchy-img"
                    src={"/static_images/HierarchyParent.svg"}
                    alt=""
                  />
                ) : (
                  <img
                    className="addtag-addHierarchy-img"
                    src={"/static_images/HierarchyChild.svg"}
                    alt=""
                  />
                )}
              </div>
              <div></div>
            </Typography>
            <Typography style={{ marginLeft: "20px", marginRight: "20px" }}>
              <div className="row documentmodal_parent mt-0">
                <div className="col-md-6">
                  <div>
                    <Box sx={{ width: "100%" }} className="">
                      <TabContext value={value}>
                        <Box className="addtag-addHierarchy MuiTab-root{">
                          <TabList
                            onChange={() => handleChange()}
                            aria-label="lab API tabs example"
                          >
                            <Tab
                              label="Add Parent"
                              value="HirarchyValueOne"
                            />
                            <Tab label="Add Child" value="HirarchyValueTwo" />
                          </TabList>
                        </Box>
                        <TabPanel value="HirarchyValueOne" className="mt-3">
                          <div className="d-flex justify-content-md-between mb-1">
                            <span className="addTag-title ">
                              {" "}
                              Parent Document
                            </span>
                            <span className="addtag-reset">Reset</span>
                          </div>

                          <div>
                            <FormControl style={{ width: "272px" }}>
                              <Select
                                style={{ border: "1px solid" }}
                                onChange={(e: any) =>
                                  setPerentData(e.target.value)
                                }
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                      width: 250,
                                    },
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                  },
                                  getContentAnchorEl: null
                                }}
                              >
                                {parentDropdown?.map((val: any) => {
                                  return (
                                    <MenuItem
                                      value={val?.fileId}
                                      style={{ whiteSpace: "initial" }}
                                    >
                                      {val?.fileName}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            {/* <select
                              className="addtag-selectBox"
                              onChange={(e: any) =>
                                setPerentData(e.target.value)
                              }
                            >
                              <option value={""}>Select Dropdown</option>
                              {parentDropdown?.map((val: any) => {
                                return (
                                  <option value={val?.fileId} style={{whiteSpace:"initial"}} >
                                    {val?.fileName}
                                  </option>
                                );
                              })}
                            </select> */}
                          </div>
                        </TabPanel>
                        {props.checkDocument &&
                        props.checkDocument?.length > 1 ? (
                          ""
                        ) : (
                          <TabPanel
                            value="HirarchyValueTwo"
                            className="mt-3"
                          >
                            <div className="d-flex justify-content-md-between mb-1">
                              <span className="addTag-title "> Children</span>
                              <span className="addtag-reset">Reset</span>
                            </div>

                            <div className="hello">
                              <FormControl style={{ width: "272px"}} className="m-1">
                                <Select
                                  multiple
                                  value={childData}
                                  onChange={(val) => handleExternal(val)}
                                  style={{ height: "26px" }}
                                  input={<OutlinedInput label="" />}
                                  renderValue={(selected: any) =>
                                    selected.join(", ")
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 250,
                                      },
                                    },
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                  }}
                                >
                                  {childDropdown?.map(
                                    (name: any, index: any) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={name?.fileName}
                                          disabled={ (name.parentExists === true &&
                                            name.isChild === false) || (name.parentExists === true &&
                                              name.isChild === true)
                                              ? true
                                              : false}
                                        >
                                          <Checkbox
                                            className="checkbox-custom"
                                            checked={
                                              childData.indexOf(
                                                name?.fileName
                                              ) > -1
                                            }
                                          />
                                          <ListItemText
                                            style={{ whiteSpace: "initial" }}
                                            primary={name?.fileName}
                                          />
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            </div>
                          </TabPanel>
                        )}
                      </TabContext>
                    </Box>
                  </div>
                </div>
              </div>
              {value == "HirarchyValueOne" ? (
                <>
                  <div className="row mt-6 d-flex justify-content-end">
                    <div className="mr-2">
                      {parentData !== "" ? (
                        <button
                          style={{ cursor: "pointer" }}
                          className="addtag-addHierarchy-addbtn"
                          onClick={() => {
                            setloader(true);
                            handleParentSubmit()
                          }}
                        >
                          <span className="addtag-addHierarchy-addbtn-title">
                            Add as Parent
                          </span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row mt-6 d-flex justify-content-end">
                    <div className="mr-2">
                      {childData?.length ? (
                        <button
                          style={{ cursor: "pointer" }}
                          className="addtag-addHierarchy-addbtn"
                          onClick={() => {
                            setloader(true);
                            handleChildSubmit()}}
                        >
                          <span className="addtag-addHierarchy-addbtn-title">
                            Add as Child
                          </span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddHierarchyModal);
