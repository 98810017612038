import { connect } from "react-redux";
import ClauseLibraryActionGenerator from "../../../../ClauseLibrary/Actions/gen";
import { StoreTree } from "../../../../Utils/MainReducer/mainReducer";
import CreateClauseModal from "./createClauseModal";
export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    folderHeadingList: appState.clauseLibrary.folderHeadingList,
    folderSubHeadingList: appState.clauseLibrary.folderSubHeadingList,
    sectionDictionaryData: appState.clauseLibrary.sectionDictionaryList,
  };
}
export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getFolderSubHeading: (parentId: string) =>
      dispatch(ClauseLibraryActionGenerator.getFolderSubHeading(parentId)),
    createClause: (
      clauseName: string,
      extractedText: string,
      userText: string,
      clauseType: string,
      sourceFileId: number,
      folderId: string,
      id: string
    ) =>
      dispatch(
        ClauseLibraryActionGenerator.createClause(
          clauseName,
          extractedText,
          userText,
          clauseType,
          sourceFileId,
          folderId,
          id
        )
      ),
    getSectionDictionary: () =>
      dispatch(ClauseLibraryActionGenerator.getSectionDictionary()),
    createSectionClause: (
      section_name: string,
      clause_name: string,
      clause_type: string,
      clause_source: string,
      clause_text: string,
      description: string,
      creation_type: string,
      tags: any
    ) =>
      dispatch(
        ClauseLibraryActionGenerator.createSectionClause(
          section_name,
          clause_name,
          clause_type,
          clause_source,
          clause_text,
          description,
          creation_type,
          tags
        )
      ),
    getSectionClauseData: (page: number) =>
      dispatch(ClauseLibraryActionGenerator.getSectionClause(page, "system")),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateClauseModal);
