import React, { Component } from "react";
import { getUserNameInitials } from "../../../TaskManagement/Component/Utils/taskManagementUtils";
import {
  AllTasksData,
  AssigneesList,
  AssociateGroup,
  ProgressNameList,
} from "../../../TaskManagement/State/taskManagementPageState";
import BarLoader from "../../../UniversalComponents/Loader/barLoader";
import CircleLoader from "../../../UniversalComponents/Loader/circleLoader";
import {
  CurrentTask,
  TaskState,
} from "../../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import {
  getDueDateFormat,
  truncateFileName,
  truncateString,
  truncateTaskName,
} from "../../../Utils/DataModifierUtil/dataModUtil";
import { DarkTooltip } from "../documentInsights";
import onClickOutside from "react-onclickoutside";

const defaultTask: AllTasksData = {
  requestID: 0,
  taskTitle: "",
  description: "",
  clauseName: "",
  clauseAlias: "",
  biType: "",
  biColumnName: "",
  progressID: "0",
  progressName: "",
  stateName: "",

  contractID: 0,
  linkedText: "",
  contractName: "",
  associateGroup: [],
  reminder: {
    reminderStart: 0,
    reminderStartType: "",
    reminderType: "",
    reminderUntil: "",
    frequencyType: "",
  },
  checkList: [],
  id: "",
  contract: "",
  contract_file_name: "",
  contract_clause_type: "",
  contract_business_intelligence_type: "",
  link_to_contract_text: "",
  title: "",
  assignees: [],
  due_date: "",
  progress: "",
  status: "",
  reminder_before_or_after: "",
  reminder_number: 0,
  reminder_period: "",
  reminder_repeats: "",
  reminder_until: "",
  notes: "",
  checklist: [],
};

interface Props {
  hidden: boolean;
  currentTask: CurrentTask;
  allTasksData: AllTasksData[];
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  fileId: string;
  // progressNameList: ProgressNameList[];
  taskProgress: TaskState[];
  updateProgress: (
    requestID: number,
    progressID: string,
    id: string,
    progressName: string
  ) => void;
  isProgressUpdated: number;
  taskLoader: boolean;
  setEditMode: (editMode: boolean, currentEditTaskData: AllTasksData) => void;
  setHideTaskPage: (hide: boolean) => void;
  progressNameList: ProgressNameList[];
}

interface State {
  hideProgressOptions: boolean;
  requestID: number;
  progressData: any;
  id: string;
  progressIDS: any;
}

class TaskPagecomponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hideProgressOptions: true,
      requestID: 0,
      id: "0",
      progressData: {
        NOT_STARTED: "Not Started",
        IN_PROGRESS: "In Progress",
        COMPLETED: "Completed",
      },
      progressIDS: {
        NOT_STARTED: "1",
        IN_PROGRESS: "2",
        COMPLETED: "3",
      },
    };
  }

  getProgressData = (progress: any) => {
    return this.state.progressData[progress];
  };

  componentDidMount() {
    // let { getAllTasksData, fileId, currentTask } = this.props;
    // getAllTasksData(fileId, '', '', false, currentTask.name);
  }

  componentWillReceiveProps(nextProps: Props) {
    let { fileId, currentTask, getAllTasksData } = this.props;

    if (
      nextProps.isProgressUpdated !== this.props.isProgressUpdated &&
      nextProps.isProgressUpdated !== -1
    ) {
      getAllTasksData(fileId, "0", "", "", false, currentTask.name);
      //this.setState({ hideProgressOptions: !this.state.hideProgressOptions });
    }
  }

  getExtraCount = (numberOfAssociates: number) => {
    let extraCount = numberOfAssociates - 3;
    return "+" + extraCount;
  };

  updateProgress(
    requestID: number,
    progressID: string,
    id: string,
    progressName: string
  ) {
    let { updateProgress } = this.props;
    updateProgress(requestID, progressID, id, progressName);
  }

  handleClickOutside = () => {
    //console.log('onClickOutside() method called')
    this.props.setHideTaskPage(true);
  };

  render() {
    let {
      hidden,
      currentTask,
      allTasksData,
      taskProgress,
      updateProgress,
      taskLoader,
      setEditMode,
      isProgressUpdated,
      progressNameList,
    } = this.props;
    let { hideProgressOptions, requestID, id } = this.state;

    return (
      <>
        <div className="row" id="task-page-container" hidden={hidden}>
          <div className="col-md-12">
            <div className="row offset-md-1">
              <div className="col-md-12 ml-3">
                <div
                  className="row px-1 mx-4"
                  id="individual-task-page-container"
                >
                  <div className="col-md-12 breadcrumb-container text-format">
                    {currentTask.name === "" ? (
                      <>Tasks or events &gt; {currentTask.contractName}</>
                    ) : (
                      <>
                        Tasks or events &gt; {currentTask.contractName} &gt;{" "}
                        {currentTask.name}
                      </>
                    )}
                  </div>

                  {taskLoader === true ? (
                    <div
                      className="col-md-12"
                      style={{
                        height: "25rem",
                        verticalAlign: "middle",
                        display: "flex",
                      }}
                    >
                      <CircleLoader />
                    </div>
                  ) : allTasksData.length === 0 ||
                    allTasksData === undefined ? (
                    <>
                      <div
                        className="col-md-12"
                        style={{
                          marginTop: "50px",
                          marginBottom: "30px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          src="/static_images/empty-task-page-img.svg"
                          alt="empty-tasks"
                        />
                      </div>
                      <div
                        className="col-md-12 text-format"
                        style={{ textAlign: "center" }}
                      >
                        No tasks or events added yet.
                      </div>
                      <div
                        className="col-md-12 create-task-link my-3"
                        style={{ textAlign: "center" }}
                        onClick={(e) => setEditMode(false, defaultTask)}
                      >
                        <span data-toggle="modal" data-target="#taskModal">
                          Add new task here
                        </span>
                      </div>
                      <div
                        className="col-md-12 link-info"
                        style={{
                          paddingLeft: "5rem",
                          background: "#F7F7F7",
                          marginBottom: "10rem",
                        }}
                      >
                        <ul>
                          <li className="my-3">
                            Create events related to clauses by clicking on the{" "}
                            <img
                              src="/static_images/create-task-icn.svg"
                              alt="empty-tasks"
                              style={{ marginBottom: "5px" }}
                            />{" "}
                            icon
                          </li>
                          <li className="my-3">
                            Assign members and set reminders and get notified
                            about your tasks.{" "}
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-12 breadcrumb-container table-header">
                        <div className="row">
                          <div className="col-md-3">Task name</div>
                          <div className="col-md-3">Assigned to</div>
                          <div className="col-md-3">Due date</div>
                          <div className="col-md-3">Progress</div>
                        </div>
                      </div>
                      <div
                        className="col-md-12 create-task-link my-3"
                        style={{ textAlign: "left", paddingLeft: "2rem" }}
                        onClick={(e) => setEditMode(false, defaultTask)}
                      >
                        <span data-toggle="modal" data-target="#taskModal">
                          <img
                            src="/static_images/add-new-task-icn.svg"
                            alt=""
                            style={{ verticalAlign: "baseline" }}
                          />{" "}
                          Add new task
                        </span>
                      </div>
                      <div
                        className="col-md-12"
                        style={{ padding: "0rem 0.7rem" }}
                      >
                        <Scrollable maxHeight={380}>
                          {allTasksData.length > 0 &&
                            allTasksData.map((task, key) => (
                              <div
                                key={key}
                                id={key + ""}
                                className="col-md-12 breadcrumb-container table-content"
                                style={{
                                  marginBottom:
                                    key === allTasksData.length - 1
                                      ? "10rem"
                                      : "",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3" id="task-name">
                                    {/* {task.taskTitle} */}
                                    {task.title !== null &&
                                    task.title.length > 10 ? (
                                      <>
                                        <DarkTooltip
                                          title={task.title}
                                          placement="right-end"
                                        >
                                          <span style={{ display: "initial" }}>
                                            {truncateString(task.title, 10)}
                                          </span>
                                        </DarkTooltip>
                                        <DarkTooltip
                                          title={"Edit Task"}
                                          placement="right-end"
                                        >
                                          <span
                                            data-toggle="modal"
                                            data-target="#taskModal"
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                              setEditMode(true, {
                                                requestID: 0,
                                                taskTitle: "",
                                                description: "",
                                                clauseName: "",
                                                clauseAlias: "",
                                                biType: "Clause",
                                                biColumnName:
                                                  task.contract_clause_type,
                                                progressID:
                                                  this.state.progressIDS[
                                                    task.progress
                                                  ],
                                                progressName:
                                                  this.state.progressData[
                                                    task.progress
                                                  ],
                                                stateName: "",

                                                contractID: 0,
                                                linkedText: "",
                                                contractName: "",
                                                associateGroup: [],
                                                reminder: {
                                                  reminderStart: 0,
                                                  reminderStartType: "",
                                                  reminderType: "",
                                                  reminderUntil: "",
                                                  frequencyType: "",
                                                },
                                                checkList: task.checklist,
                                                id: task.id,
                                                contract: task.contract,
                                                contract_file_name:
                                                  task.contract_file_name,
                                                contract_clause_type:
                                                  task.contract_clause_type,
                                                contract_business_intelligence_type:
                                                  task.contract_business_intelligence_type,
                                                link_to_contract_text:
                                                  task.link_to_contract_text,
                                                title: task.title,
                                                assignees: task.assignees,
                                                due_date: task.due_date,
                                                progress: task.progress,
                                                status: task.status,
                                                reminder_before_or_after:
                                                  task.reminder_before_or_after,
                                                reminder_number:
                                                  task.reminder_number,
                                                reminder_repeats:
                                                  task.reminder_repeats,
                                                reminder_until:
                                                  task.reminder_until,
                                                reminder_period:
                                                  task.reminder_period,
                                                notes: task.notes,
                                                checklist: task.checklist,
                                              });
                                            }}
                                          >
                                            <img src="/static_images/edit-task-icn.svg"></img>
                                          </span>
                                        </DarkTooltip>
                                      </>
                                    ) : (
                                      <>
                                        <span style={{ display: "initial" }}>
                                          {task.title}&nbsp;
                                        </span>
                                        <DarkTooltip
                                          title={"Edit Task"}
                                          placement="right-end"
                                        >
                                          <span
                                            data-toggle="modal"
                                            data-target="#taskModal"
                                            className="cursor-pointer"
                                            onClick={(e) =>
                                              setEditMode(true, {
                                                requestID: 0,
                                                taskTitle: "",
                                                description: "",
                                                clauseName: "",
                                                clauseAlias: "",
                                                biType: "Clause",
                                                biColumnName:
                                                  task.contract_clause_type,
                                                progressID:
                                                  this.state.progressIDS[
                                                    task.progress
                                                  ],
                                                progressName:
                                                  this.state.progressData[
                                                    task.progress
                                                  ],
                                                stateName: "",

                                                contractID: 0,
                                                linkedText: "",
                                                contractName: "",
                                                associateGroup: [],
                                                reminder: {
                                                  reminderStart: 0,
                                                  reminderStartType: "",
                                                  reminderType: "",
                                                  reminderUntil: "",
                                                  frequencyType: "",
                                                },
                                                checkList: task.checklist,
                                                id: task.id,
                                                contract: "",
                                                contract_file_name: "",
                                                contract_clause_type:
                                                  task.contract_clause_type,
                                                contract_business_intelligence_type:
                                                  "",
                                                link_to_contract_text:
                                                  task.link_to_contract_text,
                                                title: task.title,
                                                assignees: task.assignees,
                                                due_date: task.due_date,
                                                progress: task.progress,
                                                status: task.status,
                                                reminder_before_or_after:
                                                  task.reminder_before_or_after,
                                                reminder_number:
                                                  task.reminder_number,
                                                reminder_repeats:
                                                  task.reminder_repeats,
                                                reminder_until:
                                                  task.reminder_until,
                                                reminder_period:
                                                  task.reminder_period,
                                                notes: task.notes,
                                                checklist: task.checklist,
                                              })
                                            }
                                          >
                                            <img src="/static_images/edit-task-icn.svg"></img>
                                          </span>
                                        </DarkTooltip>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    className="col-md-3 p-0"
                                    id="assigned-to"
                                    style={
                                      task.assignees.length === 0
                                        ? { display: "block", left: "1.1vw" }
                                        : {}
                                    }
                                  >
                                    {task.assignees.length === 0 && (
                                      <img src="/static_images/empty-dash.svg"></img>
                                    )}
                                    {task.assignees.length < 3 &&
                                      task.assignees.map(
                                        (user: AssigneesList, key: number) => (
                                          <DarkTooltip
                                            key={key}
                                            title={`${user.first_name} ${user.last_name}`}
                                            placement="bottom"
                                          >
                                            <div
                                              className="avatar-circle-sm"
                                              style={{
                                                marginLeft:
                                                  key > 0 ? "0.5rem" : "",
                                                backgroundColor: "#e0e0e0",
                                              }}
                                            >
                                              <span className="initials">
                                                {user.first_name != ""
                                                  ? user.first_name[0].toUpperCase()
                                                  : ""}
                                                {user.last_name != ""
                                                  ? user.last_name[0].toUpperCase()
                                                  : ""}
                                              </span>
                                            </div>
                                          </DarkTooltip>
                                        )
                                      )}
                                    {task.assignees.length >= 3 &&
                                      task.assignees.map(
                                        (user: AssigneesList, key: number) =>
                                          key < 3 ? (
                                            <DarkTooltip
                                              key={key}
                                              title={`${user.first_name} ${user.last_name}`}
                                              placement="bottom"
                                            >
                                              <div
                                                className="avatar-circle-sm"
                                                style={{
                                                  marginLeft:
                                                    key > 0 ? "0.5rem" : "",
                                                  backgroundColor: "#e0e0e0",
                                                }}
                                              >
                                                <span className="initials">
                                                  {user.first_name != ""
                                                    ? user.first_name[0].toUpperCase()
                                                    : ""}
                                                  {user.last_name != ""
                                                    ? user.last_name[0].toUpperCase()
                                                    : ""}
                                                </span>
                                              </div>
                                            </DarkTooltip>
                                          ) : key === 3 ? (
                                            <span
                                              id="extra-count"
                                              // onClick={() => this.setModal(true, 'Assigned to', task.associateGroup.length, task.associateGroup)}
                                            >
                                              {this.getExtraCount(
                                                task.assignees.length
                                              )}
                                            </span>
                                          ) : (
                                            <> </>
                                          )
                                      )}
                                  </div>
                                  <div className="col-md-3" id="due-date">
                                    {task.due_date != null
                                      ? getDueDateFormat(task.due_date)
                                      : "-"}
                                  </div>
                                  <div className="col-md-3" id="progress">
                                    <span
                                      onClick={() => {
                                        this.setState({
                                          hideProgressOptions:
                                            !hideProgressOptions,
                                          requestID: task.requestID,
                                          id: task.id,
                                        });
                                      }}
                                    >
                                      {this.getProgressData(task.progress)}{" "}
                                      {isProgressUpdated === -1 &&
                                      task.id === id ? (
                                        <img src="/static_images/small-loader.svg" />
                                      ) : (
                                        <img
                                          src="/static_images/expand-icn.svg"
                                          alt="img"
                                        />
                                      )}
                                      {/* {JSON.stringify(this.state.progressData)} */}
                                    </span>
                                    {task.id === id && (
                                      <div
                                        id="progress-options-container"
                                        hidden={hideProgressOptions}
                                      >
                                        {progressNameList.map(
                                          (progress, key) => (
                                            <p
                                              key={key}
                                              onClick={() =>
                                                this.setState(
                                                  {
                                                    hideProgressOptions: true,
                                                  },
                                                  () => {
                                                    this.updateProgress(
                                                      task.requestID,
                                                      progress.id,
                                                      task.id,
                                                      progress.name
                                                    );
                                                    //window.location.reload();
                                                  }
                                                )
                                              }
                                            >
                                              {progress.name}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </Scrollable>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default onClickOutside(TaskPagecomponent);
