import React from "react";
import { Card, Badge, Table, Row, Col } from "react-bootstrap";

const BasicInformationRender = (props: any) => {
  const { label, children, dataPoints } = props.insight;
  const [title, tags, parties] = children;
  return (
    <Card>
      <Card.Header
        className="small-card-header light-pink-background toc-row toc-row-default"
        style={{ marginLeft: 0 }}
      >
        <Row>
          <Col style={{ fontSize: 16 }}>{label}</Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={2}>
            <b> {title.childAlias}</b>
          </Col>
          <Col style={{ textAlign: "right" }}>{title.childValue[0].name}</Col>
        </Row>
        <hr />

        <Row>
          <Col>
            <b>{parties.childAlias}</b>

            <Table size="sm" bordered responsive hover>
              <tbody>
                {parties.childValue.map((party: any, i: number) => {
                  return (
                    <tr key={i}>
                      <td> {party.partyName}</td>
                      <td> {party.partyType}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default BasicInformationRender;
