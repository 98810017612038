import React from 'react'
import { Approval } from '../State/ApprovalState'
import ApprovalsCard from './ApprovalsCard'

interface Props {
    approvalsList: Approval[];
    getApprovals: () => void;
    currentUser: string;
    removeApprover:(approverID:string,approval:any) => void;
    getColor:(type:string,role:any,isBackGroundColor:boolean) => void;
}
const ApprovalListView = (props: Props) => {
    return (
        <div>
            {props.approvalsList.map((approval, index) => (
                <ApprovalsCard removeApprover={props.removeApprover} getColor={props.getColor} currentUser={props.currentUser} getApprovals={props.getApprovals} approval={approval} index={index} key={index} />
            ))}
        </div>
    )
}

export default ApprovalListView