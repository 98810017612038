import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { appliedFilters, FilterPayload, SavedFilters } from "../State";
import { FilterSet, getClauseFilters } from "../Components/Utils/docUtils";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Row } from "react-bootstrap";
import { isNullOrUndefined } from "is-what";
import { patchsaveFilter, postsaveFilter } from "../DA";

interface Props {
  open: boolean;
  handleClose: () => void;
  selected_filter: appliedFilters[];
  search_text: string;
  filterPayload: FilterPayload;
  getSavedFilterList: () => void;
  savedType: string;
  savedFilterName: string;
  selectedSavedfilter: SavedFilters;
  label: string;
  updateMenuItem: (value: string) => void;
  selective_filter: any[];
  selected_checkboxItem:any;

  non_compete_duration_check:any;
  non_solicitation_duration_check:any
  confidentiality_duration_check:any
  cexcept_check:any;
  capped_check: any;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(3.5),
    margin: 0,
    marginBottom: "25px",
  },
}));

const SaveFilterDialog = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savefilterName, setsavefilterName] = useState("");
  const [errorLog, seterrorLog] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [message, setmessage] = useState("");
  const classes = useStyles();

  useEffect(() => {
    setOpen(props.open);
    setLoading(false);
    seterrorLog("");
    if (props.savedType == "edit") {
      setsavefilterName(props.savedFilterName);
    } else {
      setsavefilterName("");
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const handleSaveFilterName = (event: any) => {
    setsavefilterName(event.target.value);
  };

  const handleSubmit = async () => {
    seterrorLog("");
    if (savefilterName == null || savefilterName == "") {
      seterrorLog("Filter name is required.")
      setLoading(false);
      return true;
    }
    if (!isNullOrUndefined(savefilterName)) {
      setLoading(true);
      let content:any = {
        clauses: props.selected_filter,
        payment: { min: 0, max: 0, currency_type: "" },
        start_date: { max: "", min: "" },
        end_date: { max: "", min: "" },
      };
      if (props.filterPayload.hasOwnProperty("payment")) {
        content["payment"] = props.filterPayload.payment;
      }
      if (props.filterPayload.hasOwnProperty("start_date")) {
        content["start_date"] = props.filterPayload.start_date;
      }
      if (props.filterPayload.hasOwnProperty("end_date")) {
        content["end_date"] = props.filterPayload.end_date;
      }

      let non_solicitation_duration = props.selected_filter.filter(
        (option:any) => option.filterType == 'non_solicitation_duration'
      )
      if(non_solicitation_duration.length == 0){
        // if(props.selected_checkboxItem.hasOwnProperty("non_solicitation_duration")){
          if(props.non_solicitation_duration_check.includes('Yes')){
            content["non_solicitation_duration"] = {
              'yes': []
            }
          }
          if(props.non_solicitation_duration_check.includes('No')){
            content["non_solicitation_duration"] = {
              'no': []
            }
          }
        // }
      }

      let non_compete_duration = props.selected_filter.filter(
        (option:any) => option.filterType == 'non_compete_duration'
      )
      if(non_compete_duration.length == 0){
        // if(props.selected_checkboxItem.hasOwnProperty("non_compete_duration")){
          if(props.non_compete_duration_check.includes('Yes')){
            content["non_compete_duration"] = {
              'yes': []
            }
          }
          if(props.non_compete_duration_check.includes('No')){
            content["non_compete_duration"] = {
              'no': []
            }
          }
        // }
      }
      let confidentiality_duration = props.selected_filter.filter(
        (option:any) => option.filterType == 'confidentiality_duration'
      )

      if(confidentiality_duration.length == 0){
        // if(props.selected_checkboxItem.hasOwnProperty("confidentiality_duration")){
          if(props.confidentiality_duration_check.includes('Yes')){
            content["confidentiality_duration"] = {
              'yes': []
            }
          }
          if(props.confidentiality_duration_check.includes('No')){
            content["confidentiality_duration"] = {
              'no': []
            }
          }
        // }
      }

      let cexcept = props.selected_filter.filter(
        (option:any) => option.filterType == 'cexcept'
      );
      if(cexcept.length == 0){
        if(props.cexcept_check.includes('Yes')){
          content["cexcept"] = {
            'yes': []
          }
        }
        if(props.cexcept_check.includes('No')){
          content["cexcept"] = {
            'no': []
          }
        }
      }

      let limited_liability_amount = props.selected_filter.filter(
        (option:any) => option.filterType == 'limited_liability_amount'
      );
      if(limited_liability_amount.length == 0){
        if(props.capped_check.includes('Capped')){
          content["limited_liability_amount"] = {
            'Capped': []
          }
        }
        if(props.capped_check.includes('Uncapped')){
          content["limited_liability_amount"] = {
            'Uncapped': []
          }
        }
      }

      if (props.savedType == "edit") {
        let payload = {
          name: savefilterName,
          saved_data: content,
        };
        const response = await patchsaveFilter(
          payload,
          props.selectedSavedfilter.id
        );
        if (response) {
          
          props.updateMenuItem(response.name);
          setLoading(false);
          handleClose();
          
          setmessage("Filter updated successfully")
          setIsSave(true)
          props.getSavedFilterList();
        } else {
          seterrorLog("Filter name already exists.")
          setLoading(false);
        }
      }
      if (props.savedType == "save" || props.savedType == "saveas") {
        let payload = {
          name: savefilterName,
          saved_data: content,
          parent: "",
        };
        if (props.savedType == "saveas") {
          payload.parent = props.selectedSavedfilter.id;
        }

        const response = await postsaveFilter(payload);
        if (response) {
          setLoading(false);
          handleClose();
          setmessage("Filter saved successfully")
          setIsSave(true)
          props.getSavedFilterList();
        } else {
          seterrorLog("Filter name already exists.")
          setLoading(false);
        }
      }
    }
  };
  return (
    <>
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle className="background-primary">
        <div className="row">
          <div className="col-md-11 fw" style={{color:"white"}}>
            {props.label}
            {/* {props.savedType == "save" ? "Save Filter Set" : "Amend Filter Set"} */}
          </div>
          <div onClick={handleClose} className="col-md-1 close-icn">
            <img
              className="close-btn"
              src="/static_images/close-modal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {loading && <CircularProgress className="loader" />}
        <div className="row">
          <div className="col-md-8">
            <p>Name Filter Set</p>
            <p style={{ color: "red", fontWeight: "bold" }}>{errorLog}</p>
            <TextField
              fullWidth
              size="small"
              className="mb-3"
              id="outlined-basic"
              variant="outlined"
              onChange={handleSaveFilterName}
              value={savefilterName}
            />
          </div>
        </div>

        <h6>Filters Applied</h6>
        <div className="row mt-1" key="search-term">
          <span
            className="clauses-present-no"
            style={{
              color: "#949494",
              marginLeft: "15px",
              fontFamily: "Inter",
              lineHeight: "18px",
              fontSize: "12px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>Search Term</span> -{" "}
            {!isNullOrUndefined(props.search_text) && props.search_text !== ""
              ? props.search_text
              : null}
          </span>
        </div>

        {props.selective_filter.map((values, index) => {

          return (
            <div className="row mt-1" key={`selective-${values['filterType']}-${index}`}>
              <span
                className="clauses-present-no"
                style={{
                  color: "#949494",
                  marginLeft: "15px",
                  fontFamily: "Inter",
                  lineHeight: "18px",
                  fontSize: "12px",
                }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {values['filterType'] == 'payment' ? "Payment Obligation" : null}
                  {values['filterType'] == 'start_date' ? "Start Date" : null}
                  {values['filterType'] == 'end_date' ? "End Date" : null}</span>
                {" "}-{" "}
                {values["filterType"] == "payment" ? values['currency_type'] : null}
                {` ${values['min']} - ${values['max']}`}

              </span>
            </div>
          )
        })}

        {props.selected_filter.length > 0 ? (
          <>
            {Object.keys(FilterSet).map(function (key) {
              return (
                <>
                  {props.selected_filter.filter(
                    (option) => option.filterType == key
                  ).length > 0 ? (
                    <>
                      <div className="row mt-1" key={`clause_count${key}`}>
                        <span
                          className="clauses-present-no"
                          style={{
                            color: "#949494",
                            marginLeft: "15px",
                            fontFamily: "Inter",
                            lineHeight: "18px",
                            fontSize: "12px",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {FilterSet[key]}{" "}
                          </span>
                          -{" "}
                          {getClauseFilters(
                            props.selected_filter.filter(
                              (option) => option.filterType == key
                            )
                          )}
                        </span>
                      </div>
                    </>
                  ) : null}
                </>
              );
            })}
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className="c-primary fw">
          Cancel
        </Button>
        <Button className="create-btn fw" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>

      
    </Dialog>
    <Snackbar
                    open={isSave}
                    message={message}
                    onClick={() => {
                      setIsSave(false);
                    }}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    action={
                      <React.Fragment>
                        <Button
                          style={{
                            color: "#88305F",
                            border: "1px solid #88305F",
                          }}
                          size="small"
                          onClick={() => {}}
                        >
                          Ok
                        </Button>
                      </React.Fragment>
                    }
                  />
    </>
  );
};

export default SaveFilterDialog;
