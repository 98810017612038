import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { event } from "jquery";
import * as React from "react";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import {
  addComment,
  deleteComments,
  getAnnotation,
  getComment,
  getSupportDocs,
  addAnnotationValue,
  deleteAnnotations,
  updateComment,
  getContracts,
} from "../DA";
import { AddComment, AddReply } from "../State/DraftState";
import "../style.css";
import DiffMatchPatch, { diff_match_patch } from "diff-match-patch";
import moment from "moment";
interface Props {
  showAnnotationCommentCard: boolean;
  showAnnotationAnnotationCard: boolean;
  setShowAnnotationAnnotationCard: any;
  setShowAnnotationCommentCard: any;
  draftDetails: any;
  setAnnotationCommentDataPoint: any;
  annotationDataPoint: any;

  draftComments: any;
  setDraftComment: any;
  draftAnnotations: any;
  setDraftAnnotation: any;
  getDraftCommentCall: any;
  getDraftAnnotations: any;
  updateSpecificAnnotation: any;
  isOwnerID: any;
  addAnnotationInDoc: any;
  showAnnotationCard: any;
  removeAnnotation: any;
  setAnnotationCard: any;
  setdraftDetails: any;
}

export const AnnotationAndCommentTab = (props: Props) => {
  const [comment, setComment] = React.useState("");
  const [annotation, setannotation] = React.useState("");
  const [addAnnotation, setAddAnnotationValue] = React.useState("");
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [supportSuggestions, setSupportSuggestions] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState<string[]>([]);
  const [selectedSupportDoc, setSelectedSupportDoc] = React.useState<string[]>(
    []
  );
  const [supportDoc, setSupportDoc] = React.useState<string[]>([]);

  const [showComment, setShowComment] = React.useState<boolean>(false);
  const [showAnnotation, setShowAnnotation] = React.useState<boolean>(false);
  const [showBoth, setShowBoth] = React.useState<boolean>(false);

  const [showThread, setShowThread] = React.useState<any>({
    showThread: false,
    index: 0,
  });

  const [showReply, setShowReply] = React.useState<any>({
    isShow: false,
    index: 0,
  });
  const [isInternal, setIsInternal] = React.useState<boolean>(false);
  const [isEdit, setEdit] = React.useState<boolean>(false);
  const [editComment, setEditComment] = React.useState<any>();
  const [showDiff, setShowDiff] = React.useState({
    isShow: false,
    diff_string: "",
  });

  const [showAnnotDiff, setShowAnnotDiff] = React.useState({
    isShow: false,
    annot_diff_string: "",
    index: -1,
  });

  React.useEffect(() => {}, [props.showAnnotationCommentCard]);
  const commentChange = (event: any) => {
    const regexp = /@[a-zA-Z0-9]*$/;
    const supportReg = /#[a-zA-Z0-9]*$/;
    if (regexp.test(event.target.value)) {
      setShowSuggestions(true);
      var lastWord = event.target.value.split(" ");
      const withoutAnnotation = lastWord[lastWord.length - 1].split("@");
      const data: any = localStorage.getItem("userData");
      const userDatanew = JSON.parse(data);
      const systemUsers = userDatanew.users;
      if (withoutAnnotation[1]) {
        const filteredList = systemUsers.filter((item: any) =>
          item.first_name
            .toLowerCase()
            .includes(withoutAnnotation[1].toLowerCase())
        );
        if (filteredList.length === 0) {
          setUserList(systemUsers);
        } else {
          setUserList(filteredList);
        }
      } else {
        setUserList(systemUsers);
      }
    } else if (supportReg.test(event.target.value)) {
      setSupportSuggestions(true);
      var lastWord = event.target.value.split(" ");
      const withoutAnnotation = lastWord[lastWord.length - 1].split("#");

      if (withoutAnnotation[1]) {
        const filteredList = supportDoc.filter((item: any) =>
          item.file_name
            .toLowerCase()
            .includes(withoutAnnotation[1].toLowerCase())
        );
        if (filteredList.length === 0) {
          setSupportDoc(supportDoc);
        } else {
          setSupportDoc(filteredList);
        }
      } else {
        setSupportDoc(supportDoc);
      }
    } else {
      setSupportSuggestions(false);
    }

    setComment(event.target.value);
  };

  React.useEffect(() => {
    const getsupportdocument = async () => {
      const response = await getSupportDocs(props.draftDetails.requisition_id);
      setSupportDoc(response?.results);
    };
    getsupportdocument();
    props.getDraftCommentCall();
    props.getDraftAnnotations();
  }, []);

  const submitComment = async (
    value: any,
    isReply: boolean,
    parentID: string,
    isAnnotation: boolean,
    isEdit: boolean
  ) => {
    const splitedValue = value.split(" ");
    const userEntry = splitedValue.filter((item: any) => item.includes("@"));
    const userHtmlValue = userEntry.map(
      (item: any) => `<span class="orange">${item}</span>`
    );
    // replace the updated value at specific index
    const arr = splitedValue.map((item: any) => item.indexOf("@"));
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] === 0) indexes.push(i);
    indexes.forEach((item: any, index: number) => {
      splitedValue[item] = userHtmlValue[index];
    });

    const suppotDocEntry = splitedValue.filter((item: any) =>
      item.includes("#")
    );
    const supportHtmlValue = suppotDocEntry.map(
      (item: any) => `<span class="main_color">${item}</span>`
    );
    const newArr = splitedValue.map((item: any) => item.indexOf("#"));

    var indexes = [],
      i;
    for (i = 0; i < newArr.length; i++) if (newArr[i] === 0) indexes.push(i);

    indexes.forEach((item: any, index: number) => {
      splitedValue[item] = supportHtmlValue[index];
    });

    const finalSplitedValue = splitedValue.map((item: any) => {
      return item.replace(/\n/g, "<br>");
    });

    if (isReply) {
      let payload: any = {};
      if (isAnnotation) {
        payload = {
          ref_line: props.annotationDataPoint,
          html_structure: finalSplitedValue.join(" "),
          ref_draft: props.draftDetails.id,
          user_details: selectedUser,
          support_docs: selectedSupportDoc,
          annotation: parentID,
          for_annotation: true,
          create_time: new Date(),
          update_time: new Date(),
        };
      } else {
        payload = {
          ref_line: props.annotationDataPoint,
          html_structure: finalSplitedValue.join(" "),
          ref_draft: props.draftDetails.id,
          user_details: selectedUser,
          support_docs: selectedSupportDoc,
          parent: parentID,
          create_time: new Date(),
          update_time: new Date(),
        };
      }
      const response = await addComment(payload);
      if (response) {
        props.getDraftCommentCall();
        props.getDraftAnnotations();
        props.setShowAnnotationCommentCard(false);
        setShowReply({
          isReply: false,
          index: 0,
        });
      }
    } else if (isEdit) {
      setEdit(false);
      const payload = {
        ref_line: props.annotationDataPoint,
        html_structure: finalSplitedValue.join(" "),
        ref_draft: props.draftDetails.id,
        user_details: selectedUser,
        support_docs: selectedSupportDoc,
        internal_only: editComment.isInternal,
        text_comment: value,
        edit: [{ ...editComment }],
        create_time: new Date(),
        update_time: new Date(),
      };
      const response = await updateComment(payload, editComment.id);
      if (response) {
        props.getDraftCommentCall();
        props.setShowAnnotationCommentCard(false);
      }
    } else {
      const payload: any = {
        ref_line: props.annotationDataPoint,
        html_structure: finalSplitedValue.join(" "),
        ref_draft: props.draftDetails.id,
        user_details: selectedUser,
        support_docs: selectedSupportDoc,
        internal_only: isInternal,
        text_comment: value,
        create_time: new Date(),
        update_time: new Date(),
      };
      const response = await addComment(payload);
      if (response) {
        props.getDraftCommentCall();
        props.setShowAnnotationCommentCard(false);
      }
    }
    setComment("");
    setIsInternal(false);
  };

  React.useEffect(() => {
    const data: any = localStorage.getItem("userData");
    const userDatanew = JSON.parse(data);
    setUserList(userDatanew.users);
  }, []);

  React.useEffect(() => {}, [
    selectedUser,
    supportDoc,
    selectedSupportDoc,
    props.draftComments,
    props.annotationDataPoint,
    showBoth,
    annotation,
    editComment,
    props.showAnnotationCard,
    isInternal,
    showDiff,
  ]);

  React.useEffect(() => {
    if (showComment && showAnnotation) {
      setShowBoth(true);
    } else {
      setShowBoth(false);
    }
  }, [showComment, showAnnotation]);

  const getDate = (date: any) => {
    const uploadedDate = new Date(date);
    const time = uploadedDate.getHours() + ":" + uploadedDate.getMinutes();

    const diffDays = moment(date).fromNow();
    return (
      <>
        {diffDays} @ {time}
      </>
    );
  };

  const submitAnnotation = () => {
    const annotationText = annotation.replace(/\n/g, "<br>");
    const annotationPayload = {
      html_structure: `<span style="color:#818181">Replace</span> <span style="color:#000000">'${props.annotationDataPoint}'</span> <span style="color:#818181">with</span> <span style="color:#EE5800">${annotationText}<span>`,
      ref_draft: props.draftDetails.id,
      proposed_to: "Replace",
      status:
        props.isOwnerID() === props.draftDetails.created_by.id
          ? "Approved"
          : "Pending",
      internal_only: isInternal,
      create_time: new Date(),
      update_time: new Date(),
      ref_line: props.annotationDataPoint,
      proposed_line: annotation,
    };
    addAnnotationValue(annotationPayload).then((value) => {
      if (value) {
        props.getDraftAnnotations();
      }
    });
    setannotation("");
    setIsInternal(false);
  };

  const isOwner = () => {
    const user_data = getLocalStorage("user_profile");
    if (props.draftDetails?.owner.id === user_data.id) {
      return true;
    }
    return false;
  };

  const isUserExternal = () => {
    const user_data = getLocalStorage("user_profile");
    const externalUser = user_data.groups.filter((item: any) =>
      item.toLowerCase().includes("ext")
    );
    return externalUser.length;
  };

  function launch(text1: string, text2: string, index: number = -1) {
    var dmp = new diff_match_patch();
    var d = dmp.diff_main(text1, text2);
    dmp.diff_cleanupSemantic(d);
    var ds = dmp.diff_prettyHtml(d);

    if (showDiff.isShow) {
      setShowDiff({
        isShow: false,
        diff_string: "",
      });
      setShowAnnotDiff({
        isShow: false,
        annot_diff_string: "",
        index: -1,
      });
    } else {
      setShowDiff({
        isShow: true,
        diff_string: ds,
      });
      setShowAnnotDiff({
        isShow: true,
        annot_diff_string: ds,
        index: index,
      });
    }
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col-9 col-md-9 d-flex checkbox-labal">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={showAnnotation}
                  onChange={(e) => {
                    // handleCheckChange("annotation", e.target.checked);
                    setShowAnnotation(e.target.checked);
                  }}
                />
              }
              label={`Show Annotations (${props.draftAnnotations?.length})`}
              style={{ color: "#4C4C4C", fontSize: "14px" }}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={showComment}
                  onChange={(e) => {
                    setShowComment(e.target.checked);
                  }}
                />
              }
              label={`Show Comments (${props.draftComments.length})`}
              style={{ color: "#4C4C4C", fontSize: "14px" }}
              className="show-check"
            />
          </FormGroup>
        </div>
      </div>
      {props.showAnnotationCard.isShow && (
        <Card
          className="mt-2"
          style={{
            overflow: "visible",
          }}
        >
          <CardContent>
            <div
              style={{
                backgroundColor: "white",
              }}
              className="row m-0"
            >
              <div className="col-12">
                <div>
                  <div className="row m-0 justify-content-sm-between">
                    <div className="row m-0 align-items-center">
                      <div
                        className="uploadByDiv"
                        style={{
                          borderColor: "#EE5800",
                          backgroundColor: "#FFEFE4",
                        }}
                      >
                        <span
                          className="initials uploadByIcon "
                          style={{
                            color: "#EE5800",
                          }}
                        >
                          {HeimdallUtil.getNameForAvatar(
                            getLocalStorage("user_profile")
                          )}
                        </span>
                      </div>
                      <span
                        className="fw-600 ml-3"
                        style={{ color: "#818181" }}
                      >
                        <span style={{ color: "#88305f" }}>You</span> Said
                      </span>
                    </div>
                    <div>
                      <img
                        src="/static_images/section-clause-delete.svg"
                        className="pl-1 w-10"
                        onClick={() => props.setAnnotationCard(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label={`${
                        props.showAnnotationCard.type === "remove"
                          ? "Remove Proposes text"
                          : "Add Proposes text"
                      }`}
                      variant="outlined"
                      multiline
                      className="mt-4"
                      disabled={
                        props.showAnnotationCard.type === "remove"
                          ? true
                          : false
                      }
                      value={
                        props.showAnnotationCard.type === "remove"
                          ? props.annotationDataPoint
                          : addAnnotation
                      }
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setAddAnnotationValue(event.target.value);
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isInternal}
                        onChange={(e: any) => {
                          setIsInternal(e.target.checked);
                        }}
                        name="show"
                      />
                    }
                    label="Show to Org Employees only"
                  />
                </div>
              </div>
              <div className="col-12 mr-2">
                <div className="row m-0">
                  <div className="col-8"></div>
                  <div
                    onClick={() => {
                      if (props.showAnnotationCard.type === "add") {
                        props.addAnnotationInDoc(addAnnotation, isInternal);
                      } else {
                        props.removeAnnotation(
                          props.annotationDataPoint,
                          isInternal
                        );
                      }
                      props.setAnnotationCard({
                        isShow: false,
                        type: "",
                      });
                    }}
                    className="col-4"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#EE5800",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/static_images/replace-tag.svg"
                      height={20}
                      width={20}
                      className="mr-2"
                    ></img>
                    {props.showAnnotationCard.type === "add"
                      ? "Add Proposes Text"
                      : "Remove Proposes Text"}
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {isEdit && (
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <CardContent>
            <div
              style={{
                backgroundColor: "white",
              }}
              className="row m-0"
            >
              <div className="col-12">
                <div>
                  <div className="row m-0 justify-content-sm-between">
                    <div className="row m-0 align-items-center">
                      <div className="uploadByDiv">
                        <span className="initials uploadByIcon ">
                          {HeimdallUtil.getNameForAvatar(
                            getLocalStorage("user_profile")
                          )}
                        </span>
                      </div>
                      <span
                        className="fw-600 ml-3"
                        style={{ color: "#818181" }}
                      >
                        <span style={{ color: "#88305f" }}>You</span> Said
                      </span>
                    </div>

                    <div>
                      <img
                        src="/static_images/section-clause-delete.svg"
                        onClick={() => setEdit(false)}
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="w-100 position-relative">
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Your Comment"
                      variant="outlined"
                      multiline
                      className="mt-4"
                      value={comment}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        commentChange(event);
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                    {showSuggestions && (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "50%",
                          padding: "10px",
                          marginTop: "10px",
                          height: "200px",
                          overflow: "scroll",
                          position: "absolute",
                          top: "90px",
                          zIndex: 9,
                          boxShadow: "5px 5px 10px #393E46",
                        }}
                      >
                        {userList.map((item: any) => (
                          <div
                            className="d-flex mt-2"
                            onClick={() => {
                              setSelectedUser([...selectedUser, item.id]);
                              setShowSuggestions(false);
                              setSupportSuggestions(false);
                              const regexp = /[a-zA-Z0-9]*$/;
                              const newValue = comment.replace(
                                regexp,
                                item.first_name + " "
                              );
                              setComment(newValue);
                            }}
                          >
                            <img
                              src="/static_images/user.svg"
                              height={20}
                              width={20}
                            ></img>
                            <p
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                width: "50%",
                                marginLeft: 5,
                              }}
                            >
                              {item.first_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                    {supportSuggestions && (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "50%",
                          padding: "10px",
                          marginTop: "10px",
                          height: "200px",
                          overflow: "scroll",
                          position: "absolute",
                          top: "90px",
                          zIndex: 9,
                          boxShadow: "5px 5px 10px #393E46",
                        }}
                      >
                        {supportDoc.map((item: any) => (
                          <div
                            className="d-flex mt-2"
                            onClick={() => {
                              setSelectedSupportDoc([
                                ...selectedSupportDoc,
                                item.id,
                              ]);
                              setSupportSuggestions(false);
                              setShowSuggestions(false);
                              const regexp = /[a-zA-Z0-9]*$/;
                              const splitFileName =
                                item.file_name.split(".docx");
                              const newValue = comment.replace(
                                regexp,
                                splitFileName[0] + " "
                              );
                              setComment(newValue);
                            }}
                          >
                            <img
                              src="/static_images/user.svg"
                              height={20}
                              width={20}
                            ></img>
                            <p
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                width: "50%",
                                marginLeft: 5,
                              }}
                            >
                              {item.file_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 mr-2">
                <div className="row m-0">
                  <div className="col-9"></div>
                  <div
                    className="col-3"
                    onClick={() => {
                      // props.setShowAnnotationCommentCard(false);
                      submitComment(comment, false, "", false, true);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#00668F",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/static_images/comment-icon.svg"
                      height={20}
                      width={20}
                      className="mr-2"
                    ></img>
                    Edit Comment
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {props.showAnnotationCommentCard && (
        <Card
          style={{
            overflow: "visible",
          }}
        >
          <CardContent>
            <div
              style={{
                backgroundColor: "white",
              }}
              className="row m-0"
            >
              <div className="col-12">
                <div>
                  <div className="row m-0 justify-content-sm-between">
                    <div className="row m-0 align-items-center">
                      <div className="uploadByDiv">
                        <span className="initials uploadByIcon ">
                          {HeimdallUtil.getNameForAvatar(
                            getLocalStorage("user_profile")
                          )}
                        </span>
                      </div>
                      <span
                        className="fw-600 ml-3"
                        style={{ color: "#818181" }}
                      >
                        <span style={{ color: "#88305f" }}>You</span> Said
                      </span>
                    </div>

                    <div>
                      <img
                        src="/static_images/section-clause-delete.svg"
                        onClick={() =>
                          props.setShowAnnotationCommentCard(false)
                        }
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="w-100">
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Your Comment"
                      variant="outlined"
                      multiline
                      className="mt-4"
                      value={comment}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        commentChange(event);
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                    {showSuggestions && (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "50%",
                          padding: "10px",
                          marginTop: "10px",
                          height: "200px",
                          overflow: "scroll",
                          position: "absolute",
                          top: "90px",
                          zIndex: 9,
                          boxShadow: "5px 5px 10px #393E46",
                        }}
                      >
                        {userList.map((item: any) => (
                          <div
                            className="d-flex mt-2"
                            onClick={() => {
                              setSelectedUser([...selectedUser, item.id]);
                              setShowSuggestions(false);
                              setSupportSuggestions(false);
                              const regexp = /[a-zA-Z0-9]*$/;
                              const newValue = comment.replace(
                                regexp,
                                item.first_name + " "
                              );
                              setComment(newValue);
                            }}
                          >
                            <img
                              src="/static_images/user.svg"
                              height={20}
                              width={20}
                            ></img>
                            <p
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                width: "50%",
                                marginLeft: 5,
                              }}
                            >
                              {item.first_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                    {supportSuggestions && (
                      <div
                        style={{
                          backgroundColor: "white",
                          width: "50%",
                          padding: "10px",
                          marginTop: "10px",
                          height: "200px",
                          overflow: "scroll",
                          position: "absolute",
                          top: "90px",
                          zIndex: 9,
                          boxShadow: "5px 5px 10px #393E46",
                        }}
                      >
                        {supportDoc.map((item: any) => (
                          <div
                            className="d-flex mt-2"
                            onClick={() => {
                              setSelectedSupportDoc([
                                ...selectedSupportDoc,
                                item.id,
                              ]);
                              setSupportSuggestions(false);
                              setShowSuggestions(false);
                              const regexp = /[a-zA-Z0-9]*$/;
                              const splitFileName =
                                item.file_name.split(".docx");
                              const newValue = comment.replace(
                                regexp,
                                splitFileName[0] + " "
                              );
                              setComment(newValue);
                            }}
                          >
                            <img
                              src="/static_images/user.svg"
                              height={20}
                              width={20}
                            ></img>
                            <p
                              style={{
                                fontWeight: 600,
                                cursor: "pointer",
                                width: "50%",
                                marginLeft: 5,
                              }}
                            >
                              {item.file_name}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isInternal}
                        onChange={(e: any) => setIsInternal(e.target.checked)}
                        name="show"
                      />
                    }
                    label="Show to Org Employees only"
                  />
                </div>
              </div>
              <div className="col-12 mr-2">
                <div className="row m-0">
                  <div className="col-9"></div>
                  <div
                    className="col-3"
                    onClick={() => {
                      // props.setShowAnnotationCommentCard(false);
                      submitComment(comment, false, "", false, false);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#00668F",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/static_images/comment-icon.svg"
                      height={20}
                      width={20}
                      className="mr-2"
                    ></img>
                    Post Comment
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {props.showAnnotationAnnotationCard && (
        <Card className="mt-2">
          <CardContent>
            <div
              style={{
                backgroundColor: "white",
              }}
              className="row m-0"
            >
              <div className="col-12">
                <div>
                  <div className="row m-0 justify-content-sm-between">
                    <div className="row m-0 align-items-center">
                      <div
                        className="uploadByDiv"
                        style={{
                          borderColor: "#EE5800",
                          backgroundColor: "#FFEFE4",
                        }}
                      >
                        <span
                          className="initials uploadByIcon "
                          style={{
                            color: "#EE5800",
                          }}
                        >
                          {HeimdallUtil.getNameForAvatar(
                            getLocalStorage("user_profile")
                          )}
                        </span>
                      </div>
                      <span
                        className="fw-600 ml-3"
                        style={{ color: "#818181" }}
                      >
                        <span style={{ color: "#88305f" }}>You</span> Said
                      </span>
                    </div>
                    <div>
                      <img
                        src="/static_images/clause_copy.svg"
                        className="pl-1 w-10"
                        onClick={() => {
                          setannotation(props.annotationDataPoint);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="/static_images/compare-icon.svg"
                        className="pl-1 w-10"
                        onClick={() => {
                          launch(props.annotationDataPoint, annotation);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        src="/static_images/section-clause-delete.svg"
                        className="pl-1 w-10"
                        onClick={() =>
                          props.setShowAnnotationAnnotationCard(false)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  {showDiff.isShow ? (
                    <div
                      className="mt-3"
                      dangerouslySetInnerHTML={{
                        __html: showDiff.diff_string.replace(
                          /&para;<br>/g,
                          "&nbsp;"
                        ),
                      }}
                    ></div>
                  ) : (
                    <TextField
                      size="small"
                      id="outlined-basic"
                      label="Please add text"
                      variant="outlined"
                      multiline
                      className="mt-4"
                      value={annotation}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setannotation(event.target.value);
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isInternal}
                        onChange={(e: any) => setIsInternal(e.target.checked)}
                        name="show"
                      />
                    }
                    label="Show to Org Employees only"
                  />
                </div>
              </div>
              <div className="col-12 mr-2">
                <div className="row m-0">
                  <div className="col-8"></div>
                  <div
                    onClick={() => {
                      submitAnnotation();
                      props.setShowAnnotationAnnotationCard(false);
                    }}
                    className="col-4"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#EE5800",
                      color: "white",
                      padding: "10px",
                      borderRadius: "5px",
                      marginTop: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/static_images/replace-tag.svg"
                      height={20}
                      width={20}
                      className="mr-2"
                    ></img>
                    Proposes Replace Text
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {showComment
        ? props.draftComments && props.draftComments.length
          ? props.draftComments.map((item: any, index: number) => {
              return (
                <>
                  {isUserExternal() > 0 ? (
                    !item.internal_only ? (
                      <Card
                        className="my-3 position-relative"
                        style={{
                          overflow: "visible",
                        }}
                      >
                        <CardContent>
                          <div className="row justify-content-sm-between ml-1 mr-1">
                            <div className="row ml-2 align-items-center">
                              <div className="uploadByDiv">
                                <span className="initials uploadByIcon ">
                                  {HeimdallUtil.getNameForAvatar(
                                    item.created_by
                                  )}
                                </span>
                              </div>
                              <span
                                className="fw-600 ml-2"
                                style={{ color: "#818181" }}
                              >
                                <span style={{ color: "#88305f" }}>
                                  {item.created_by.first_name +
                                    " " +
                                    item.created_by.last_name}
                                </span>{" "}
                                Said
                              </span>
                            </div>
                            {props.isOwnerID() === item.created_by.id && (
                              <div>
                                <img
                                  src="/static_images/editicon.svg"
                                  className="pl-1 w-10"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (props.draftDetails.open_collab) {
                                      setEdit(true);
                                      setEditComment(item);
                                      setComment(item.text_comment);
                                    }
                                  }}
                                />{" "}
                                <img
                                  src="/static_images/section-clause-delete.svg"
                                  className="pl-1 w-10"
                                  onClick={async () => {
                                    if (props.draftDetails.open_collab) {
                                      deleteComments(item.id).then((value) => {
                                        props.getDraftCommentCall();
                                      });
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                          <div
                            className="mt-3 text-container ml-1 mr-1 p-2 cursor-pointer"
                            onClick={() => {
                              props.setAnnotationCommentDataPoint(
                                item.ref_line
                              );
                            }}
                            style={{ backgroundColor: "#E9FBFF" }}
                            dangerouslySetInnerHTML={{
                              __html: item.html_structure,
                            }}
                          ></div>
                          <div className="row justify-content-sm-between ml-1 mr-1">
                            <div className="row ml-2 align-items-center text-muted">
                              {getDate(item.update_time)}
                            </div>
                            {!showThread.showThread ? (
                              <div style={{ color: "#88305F" }}>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (
                                      showThread.showThread &&
                                      showThread.index === index
                                    ) {
                                      setShowThread({
                                        showThread: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: true,
                                        index: index,
                                      });
                                    }
                                  }}
                                >
                                  {item.children.length > 0
                                    ? showThread.showThread &&
                                      showThread.index === index
                                      ? "Hide Thread"
                                      : "Show Thread"
                                    : null}
                                </span>
                                <span
                                  className="cursor-pointer ml-3"
                                  onClick={() => {
                                    if (props.draftDetails.open_collab) {
                                      if (showReply.isShow) {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: true,
                                          index: index,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  Reply
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </CardContent>
                        {showThread.showThread
                          ? showThread.index === index && (
                              <div
                                style={{
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  paddingBottom: 20,
                                }}
                              >
                                {item.children.map((element: any) => {
                                  return (
                                    <CardContent>
                                      <div className="row justify-content-sm-between ml-1 mr-1">
                                        <div className="row ml-2 align-items-center">
                                          <div className="uploadByDiv">
                                            <span className="initials uploadByIcon ">
                                              {HeimdallUtil.getNameForAvatar(
                                                element.created_by
                                              )}
                                            </span>
                                          </div>
                                          <span
                                            className="fw-600 ml-2"
                                            style={{ color: "#818181" }}
                                          >
                                            <span style={{ color: "#88305f" }}>
                                              {element.created_by.first_name +
                                                " " +
                                                element.created_by.last_name}
                                            </span>{" "}
                                            Said
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="mt-3 text-container ml-1 mr-1 p-2"
                                        style={{ backgroundColor: "#E9FBFF" }}
                                        dangerouslySetInnerHTML={{
                                          __html: element.html_structure,
                                        }}
                                      ></div>
                                      <div className="row justify-content-sm-between ml-1 mr-1">
                                        <div className="row ml-2 align-items-center text-muted">
                                          {getDate(element.update_time)}
                                        </div>
                                      </div>
                                    </CardContent>
                                  );
                                })}
                                <div
                                  style={{ color: "#88305F" }}
                                  className="d-flex justify-content-end"
                                >
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (
                                        showThread.showThread &&
                                        showThread.index === index
                                      ) {
                                        setShowThread({
                                          showThread: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: true,
                                          index: index,
                                        });
                                      }
                                    }}
                                  >
                                    {item.children.length > 0
                                      ? showThread.showThread &&
                                        showThread.index === index
                                        ? "Hide Thread"
                                        : "Show Thread"
                                      : null}
                                  </span>
                                  <span
                                    className="cursor-pointer ml-3"
                                    onClick={() => {
                                      if (props.draftDetails.open_collab) {
                                        if (showReply.isShow) {
                                          setShowThread({
                                            showThread: false,
                                            index: 0,
                                          });
                                          setShowReply({
                                            isShow: false,
                                            index: index,
                                          });
                                        } else {
                                          setShowThread({
                                            showThread: false,
                                            index: 0,
                                          });
                                          setShowReply({
                                            isShow: true,
                                            index: index,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    Reply
                                  </span>
                                </div>
                              </div>
                            )
                          : showReply.isShow &&
                            showReply.index === index && (
                              <div className="row m-0 p-3">
                                <div className="w-100">
                                  <TextField
                                    size="small"
                                    id="outlined-basic"
                                    label="Your Comment"
                                    variant="outlined"
                                    multiline
                                    className="mt-4"
                                    value={comment}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      commentChange(event);
                                    }}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                  {showSuggestions && (
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "50%",
                                        padding: "10px",
                                        marginTop: "10px",
                                        height: "200px",
                                        overflow: "scroll",
                                        position: "absolute",
                                        top: "220px",
                                        zIndex: 9,
                                        boxShadow: "5px 5px 10px #393E46",
                                      }}
                                    >
                                      {userList.map((item: any) => (
                                        <div
                                          className="d-flex mt-2"
                                          onClick={() => {
                                            setSelectedUser([
                                              ...selectedUser,
                                              item.id,
                                            ]);
                                            setShowSuggestions(false);
                                            setSupportSuggestions(false);
                                            const regexp = /[a-zA-Z0-9]*$/;
                                            const newValue = comment.replace(
                                              regexp,
                                              item.first_name + " "
                                            );
                                            setComment(newValue);
                                          }}
                                        >
                                          <img
                                            src="/static_images/user.svg"
                                            height={20}
                                            width={20}
                                          ></img>
                                          <p
                                            style={{
                                              fontWeight: 600,
                                              cursor: "pointer",
                                              width: "50%",
                                              marginLeft: 5,
                                            }}
                                          >
                                            {item.first_name}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {supportSuggestions && (
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "50%",
                                        padding: "10px",
                                        marginTop: "10px",
                                        height: "200px",
                                        overflow: "scroll",
                                        position: "absolute",
                                        top: "220px",
                                        zIndex: 9,
                                        boxShadow: "5px 5px 10px #393E46",
                                      }}
                                    >
                                      {supportDoc.map((item: any) => (
                                        <div
                                          className="d-flex mt-2"
                                          onClick={() => {
                                            setSelectedSupportDoc([
                                              ...selectedSupportDoc,
                                              item.id,
                                            ]);
                                            setSupportSuggestions(false);
                                            setShowSuggestions(false);
                                            const regexp = /[a-zA-Z0-9]*$/;
                                            const splitFileName =
                                              item.file_name.split(".docx");
                                            const newValue = comment.replace(
                                              regexp,
                                              splitFileName[0] + " "
                                            );
                                            setComment(newValue);
                                          }}
                                        >
                                          <img
                                            src="/static_images/user.svg"
                                            height={20}
                                            width={20}
                                          ></img>
                                          <p
                                            style={{
                                              fontWeight: 600,
                                              cursor: "pointer",
                                              width: "50%",
                                              marginLeft: 5,
                                            }}
                                          >
                                            {item.file_name}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div className="col-9"></div>
                                <div
                                  className="col-3"
                                  onClick={() => {
                                    submitComment(
                                      comment,
                                      true,
                                      item.id,
                                      false,
                                      false
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#00668F",
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  Add Reply
                                </div>
                              </div>
                            )}
                      </Card>
                    ) : null
                  ) : (
                    <Card
                      className="my-3"
                      style={{
                        overflow: "visible",
                      }}
                    >
                      <CardContent>
                        <div className="row justify-content-sm-between ml-1 mr-1">
                          <div className="row ml-2 align-items-center">
                            <div className="uploadByDiv">
                              <span className="initials uploadByIcon ">
                                {HeimdallUtil.getNameForAvatar(item.created_by)}
                              </span>
                            </div>
                            <span
                              className="fw-600 ml-2"
                              style={{ color: "#818181" }}
                            >
                              <span style={{ color: "#88305f" }}>
                                {item.created_by.first_name +
                                  " " +
                                  item.created_by.last_name}
                              </span>{" "}
                              Said
                            </span>
                          </div>
                          {props.isOwnerID() === item.created_by.id && (
                            <div>
                              <img
                                src="/static_images/editicon.svg"
                                className="pl-1 w-10"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (props.draftDetails.open_collab) {
                                    setEdit(true);
                                    setEditComment(item);
                                    setComment(item.text_comment);
                                  }
                                }}
                              />{" "}
                              <img
                                src="/static_images/section-clause-delete.svg"
                                className="pl-1 w-10"
                                onClick={async () => {
                                  if (props.draftDetails.open_collab) {
                                    deleteComments(item.id).then((value) => {
                                      props.getDraftCommentCall();
                                    });
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className="mt-3 text-container ml-1 mr-1 p-2 cursor-pointer"
                          onClick={() => {
                            props.setAnnotationCommentDataPoint(item.ref_line);
                          }}
                          style={{ backgroundColor: "#E9FBFF" }}
                          dangerouslySetInnerHTML={{
                            __html: item.html_structure,
                          }}
                        ></div>
                        <div className="row justify-content-sm-between ml-1 mr-1">
                          <div className="row ml-2 align-items-center text-muted">
                            {getDate(item.update_time)}
                          </div>
                          {!showThread.showThread ? (
                            <div style={{ color: "#88305F" }}>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  if (
                                    showThread.showThread &&
                                    showThread.index === index
                                  ) {
                                    setShowThread({
                                      showThread: false,
                                      index: index,
                                    });
                                  } else {
                                    setShowThread({
                                      showThread: true,
                                      index: index,
                                    });
                                  }
                                }}
                              >
                                {item.children.length > 0
                                  ? showThread.showThread &&
                                    showThread.index === index
                                    ? "Hide Thread"
                                    : "Show Thread"
                                  : null}
                              </span>
                              <span
                                className="cursor-pointer ml-3"
                                onClick={() => {
                                  if (props.draftDetails.open_collab) {
                                    if (showReply.isShow) {
                                      setShowThread({
                                        showThread: false,
                                        index: 0,
                                      });
                                      setShowReply({
                                        isShow: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: false,
                                        index: 0,
                                      });
                                      setShowReply({
                                        isShow: true,
                                        index: index,
                                      });
                                    }
                                  }
                                }}
                              >
                                Reply
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </CardContent>
                      {showThread.showThread
                        ? showThread.index === index && (
                            <div
                              style={{
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingBottom: 20,
                              }}
                            >
                              {item.children.map((element: any) => {
                                return (
                                  <CardContent>
                                    <div className="row justify-content-sm-between ml-1 mr-1">
                                      <div className="row ml-2 align-items-center">
                                        <div className="uploadByDiv">
                                          <span className="initials uploadByIcon ">
                                            {HeimdallUtil.getNameForAvatar(
                                              element.created_by
                                            )}
                                          </span>
                                        </div>
                                        <span
                                          className="fw-600 ml-2"
                                          style={{ color: "#818181" }}
                                        >
                                          <span style={{ color: "#88305f" }}>
                                            {element.created_by.first_name +
                                              " " +
                                              element.created_by.last_name}
                                          </span>{" "}
                                          Said
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="mt-3 text-container ml-1 mr-1 p-2"
                                      style={{ backgroundColor: "#E9FBFF" }}
                                      dangerouslySetInnerHTML={{
                                        __html: element.html_structure,
                                      }}
                                    ></div>
                                    <div className="row justify-content-sm-between ml-1 mr-1">
                                      <div className="row ml-2 align-items-center text-muted">
                                        {getDate(element.update_time)}
                                      </div>
                                    </div>
                                  </CardContent>
                                );
                              })}
                              <div
                                style={{ color: "#88305F" }}
                                className="d-flex justify-content-end"
                              >
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (
                                      showThread.showThread &&
                                      showThread.index === index
                                    ) {
                                      setShowThread({
                                        showThread: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: true,
                                        index: index,
                                      });
                                    }
                                  }}
                                >
                                  {item.children.length > 0
                                    ? showThread.showThread &&
                                      showThread.index === index
                                      ? "Hide Thread"
                                      : "Show Thread"
                                    : null}
                                </span>
                                <span
                                  className="cursor-pointer ml-3"
                                  onClick={() => {
                                    if (props.draftDetails.open_collab) {
                                      if (showReply.isShow) {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: true,
                                          index: index,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  Reply
                                </span>
                              </div>
                            </div>
                          )
                        : showReply.isShow &&
                          showReply.index === index && (
                            <div className="row m-0 p-3">
                              <div className="w-100">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  label="Your Comment"
                                  variant="outlined"
                                  multiline
                                  className="mt-4"
                                  value={comment}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    commentChange(event);
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                />
                                {showSuggestions && (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      width: "50%",
                                      padding: "10px",
                                      marginTop: "10px",
                                      height: "200px",
                                      overflow: "scroll",
                                      position: "absolute",
                                      top: "220px",
                                      zIndex: 9,
                                      boxShadow: "5px 5px 10px #393E46",
                                    }}
                                  >
                                    {userList.map((item: any) => (
                                      <div
                                        className="d-flex mt-2"
                                        onClick={() => {
                                          setSelectedUser([
                                            ...selectedUser,
                                            item.id,
                                          ]);
                                          setShowSuggestions(false);
                                          setSupportSuggestions(false);
                                          const regexp = /[a-zA-Z0-9]*$/;
                                          const newValue = comment.replace(
                                            regexp,
                                            item.first_name + " "
                                          );
                                          setComment(newValue);
                                        }}
                                      >
                                        <img
                                          src="/static_images/user.svg"
                                          height={20}
                                          width={20}
                                        ></img>
                                        <p
                                          style={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            width: "50%",
                                            marginLeft: 5,
                                          }}
                                        >
                                          {item.first_name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {supportSuggestions && (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      width: "50%",
                                      padding: "10px",
                                      marginTop: "10px",
                                      height: "200px",
                                      overflow: "scroll",
                                      position: "absolute",
                                      top: "220px",
                                      zIndex: 9,
                                      boxShadow: "5px 5px 10px #393E46",
                                    }}
                                  >
                                    {supportDoc.map((item: any) => (
                                      <div
                                        className="d-flex mt-2"
                                        onClick={() => {
                                          setSelectedSupportDoc([
                                            ...selectedSupportDoc,
                                            item.id,
                                          ]);
                                          setSupportSuggestions(false);
                                          setShowSuggestions(false);
                                          const regexp = /[a-zA-Z0-9]*$/;
                                          const splitFileName =
                                            item.file_name.split(".docx");
                                          const newValue = comment.replace(
                                            regexp,
                                            splitFileName[0] + " "
                                          );
                                          setComment(newValue);
                                        }}
                                      >
                                        <img
                                          src="/static_images/user.svg"
                                          height={20}
                                          width={20}
                                        ></img>
                                        <p
                                          style={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            width: "50%",
                                            marginLeft: 5,
                                          }}
                                        >
                                          {item.file_name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div className="col-9"></div>
                              <div
                                className="col-3"
                                onClick={() => {
                                  submitComment(
                                    comment,
                                    true,
                                    item.id,
                                    false,
                                    false
                                  );
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#00668F",
                                  color: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Add Reply
                              </div>
                            </div>
                          )}
                    </Card>
                  )}
                </>
              );
            })
          : null
        : null}
      {showAnnotation
        ? props.draftAnnotations && props.draftAnnotations.length
          ? props.draftAnnotations.map((item: any, index: number) => {
              return (
                <>
                  {isUserExternal() > 0 ? (
                    !item.internal_only ? (
                      <Card className="my-3">
                        <CardContent>
                          <div className="d-flex justify-content-between ml-1 mr-1">
                            <div></div>
                            {props.isOwnerID() === item.created_by.id ? (
                              <div>
                                {item.proposed_to === "Replace" && (
                                  <img
                                    src="/static_images/compare-icon.svg"
                                    className="pl-1 w-10"
                                    onClick={async () => {
                                      launch(
                                        item.ref_line,
                                        item.proposed_line,
                                        index
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                                <img
                                  src="/static_images/section-clause-delete.svg"
                                  className="pl-1 w-10"
                                  onClick={async () => {
                                    if (props.draftDetails.open_collab) {
                                      deleteAnnotations(item.id).then(
                                        (value) => {
                                          props.getDraftAnnotations();
                                        }
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            ) : null}
                          </div>
                          {showAnnotDiff.index === index &&
                          showAnnotDiff.isShow ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: showAnnotDiff.annot_diff_string.replace(
                                  /&para;<br>/g,
                                  "&nbsp;"
                                ),
                              }}
                            ></div>
                          ) : (
                            <div
                              className="mt-3 text-container ml-1 mr-1 p-2"
                              onClick={() => {
                                props.setAnnotationCommentDataPoint(
                                  item.ref_line
                                );
                              }}
                              style={{ backgroundColor: "#FFEFE4" }}
                              dangerouslySetInnerHTML={{
                                __html: item.html_structure,
                              }}
                            ></div>
                          )}
                          <div className="row justify-content-sm-between ml-1 mr-1">
                            <div className="col-12">
                              <div className="row mt-2 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <div className="annotationDiv">
                                    <span
                                      className=""
                                      style={{
                                        color: "#EE5800",
                                        borderColor: "#EE5800",
                                      }}
                                    >
                                      {HeimdallUtil.getNameForAvatar(
                                        item.created_by
                                      )}
                                    </span>
                                  </div>
                                  <span
                                    className="fw-600 ml-2"
                                    style={{ color: "#818181" }}
                                  >
                                    <span style={{ color: "#EE5800" }}>
                                      {item.created_by.first_name +
                                        " " +
                                        item.created_by.last_name}
                                    </span>{" "}
                                    Proposes to{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                      }}
                                    >{`${item.proposed_to}`}</span>
                                  </span>
                                </div>
                                {isOwner() && item.status === "Pending" ? (
                                  <div>
                                    <img
                                      src="/static_images/selectIcon.svg"
                                      className="pl-1 w-10"
                                      onClick={() => {
                                        if (props.draftDetails.open_collab) {
                                          props.updateSpecificAnnotation(
                                            item.id,
                                            "Approved"
                                          );
                                        }
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src="/static_images/closeIconnew.svg"
                                      className="pl-1 w-10"
                                      onClick={() => {
                                        if (props.draftDetails.open_collab) {
                                          props.updateSpecificAnnotation(
                                            item.id,
                                            "Rejected"
                                          );
                                        }
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                ) : item.status === "Approved" ? (
                                  <div className="d-flex">
                                    <img
                                      src="/static_images/selectrighticon.svg"
                                      className="pl-1 w-10"
                                    />
                                    <p className="mb-0 ml-2 font-weight-bold">
                                      Approved
                                    </p>
                                  </div>
                                ) : item.status === "Rejected" ? (
                                  <div className="d-flex">
                                    <img
                                      src="/static_images/crossicon.svg"
                                      className="pl-1 w-10"
                                    />
                                    <p className="mb-0 ml-2 font-weight-bold">
                                      Rejected
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="row ml-2 mt-2 align-items-center text-muted">
                              {getDate(item.update_time)}
                            </div>
                            {!showThread.showThread ? (
                              <div style={{ color: "#88305F" }}>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (
                                      showThread.showThread &&
                                      showThread.index === index
                                    ) {
                                      setShowThread({
                                        showThread: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: true,
                                        index: index,
                                      });
                                    }
                                  }}
                                >
                                  {item.comments.length > 0
                                    ? showThread.showThread &&
                                      showThread.index === index
                                      ? "Hide Thread"
                                      : "Show Thread"
                                    : null}
                                </span>
                                <span
                                  className="cursor-pointer ml-3"
                                  onClick={() => {
                                    if (props.draftDetails.open_collab) {
                                      if (showReply.isShow) {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: true,
                                          index: index,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  Reply
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </CardContent>
                        {showThread.showThread
                          ? showThread.index === index && (
                              <>
                                {item.comments.map((element: any) => {
                                  return (
                                    <CardContent className="mx-3">
                                      <div className="row justify-content-sm-between ml-1 mr-1">
                                        <div className="row ml-2 align-items-center">
                                          <div className="uploadByDiv">
                                            <span className="initials uploadByIcon ">
                                              {HeimdallUtil.getNameForAvatar(
                                                element.created_by
                                              )}
                                            </span>
                                          </div>
                                          <span
                                            className="fw-600 ml-2"
                                            style={{ color: "#818181" }}
                                          >
                                            <span style={{ color: "#88305f" }}>
                                              {element.created_by.first_name +
                                                " " +
                                                element.created_by.last_name}
                                            </span>{" "}
                                            Said
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="mt-3 text-container ml-1 mr-1 p-2"
                                        style={{ backgroundColor: "#E9FBFF" }}
                                        dangerouslySetInnerHTML={{
                                          __html: element.html_structure,
                                        }}
                                      ></div>
                                      <div className="row justify-content-sm-between ml-1 mr-1">
                                        <div className="row ml-2 align-items-center text-muted">
                                          {getDate(element.update_time)}
                                        </div>
                                      </div>
                                    </CardContent>
                                  );
                                })}
                                <div style={{ color: "#88305F" }}>
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      if (
                                        showThread.showThread &&
                                        showThread.index === index
                                      ) {
                                        setShowThread({
                                          showThread: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: true,
                                          index: index,
                                        });
                                      }
                                    }}
                                  >
                                    {item.comments.length > 0
                                      ? showThread.showThread &&
                                        showThread.index === index
                                        ? "Hide Thread"
                                        : "Show Thread"
                                      : null}
                                  </span>
                                  <span
                                    className="cursor-pointer ml-3"
                                    onClick={() => {
                                      if (props.draftDetails.open_collab) {
                                        if (showReply.isShow) {
                                          setShowThread({
                                            showThread: false,
                                            index: 0,
                                          });
                                          setShowReply({
                                            isShow: false,
                                            index: index,
                                          });
                                        } else {
                                          setShowThread({
                                            showThread: false,
                                            index: 0,
                                          });
                                          setShowReply({
                                            isShow: true,
                                            index: index,
                                          });
                                        }
                                      }
                                    }}
                                  >
                                    Reply
                                  </span>
                                </div>
                              </>
                            )
                          : showReply.isShow &&
                            showReply.index === index && (
                              <div className="row m-0 p-3">
                                <div className="w-100">
                                  <TextField
                                    size="small"
                                    id="outlined-basic"
                                    label="Your Comment"
                                    variant="outlined"
                                    multiline
                                    className="mt-4"
                                    value={comment}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      commentChange(event);
                                    }}
                                    style={{
                                      width: "100%",
                                    }}
                                  />
                                  {showSuggestions && (
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "50%",
                                        padding: "10px",
                                        marginTop: "10px",
                                        height: "200px",
                                        overflow: "scroll",
                                        position: "absolute",
                                        top: "220px",
                                        zIndex: 9,
                                        boxShadow: "5px 5px 10px #393E46",
                                      }}
                                    >
                                      {userList.map((item: any) => (
                                        <div
                                          className="d-flex mt-2"
                                          onClick={() => {
                                            setSelectedUser([
                                              ...selectedUser,
                                              item.id,
                                            ]);
                                            setShowSuggestions(false);
                                            setSupportSuggestions(false);
                                            const regexp = /[a-zA-Z0-9]*$/;
                                            const newValue = comment.replace(
                                              regexp,
                                              item.first_name + " "
                                            );
                                            setComment(newValue);
                                          }}
                                        >
                                          <img
                                            src="/static_images/user.svg"
                                            height={20}
                                            width={20}
                                          ></img>
                                          <p
                                            style={{
                                              fontWeight: 600,
                                              cursor: "pointer",
                                              width: "50%",
                                              marginLeft: 5,
                                            }}
                                          >
                                            {item.first_name}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                  {supportSuggestions && (
                                    <div
                                      style={{
                                        backgroundColor: "white",
                                        width: "50%",
                                        padding: "10px",
                                        marginTop: "10px",
                                        height: "200px",
                                        overflow: "scroll",
                                        position: "absolute",
                                        top: "220px",
                                        zIndex: 9,
                                        boxShadow: "5px 5px 10px #393E46",
                                      }}
                                    >
                                      {supportDoc.map((item: any) => (
                                        <div
                                          className="d-flex mt-2"
                                          onClick={() => {
                                            setSelectedSupportDoc([
                                              ...selectedSupportDoc,
                                              item.id,
                                            ]);
                                            setSupportSuggestions(false);
                                            setShowSuggestions(false);
                                            const regexp = /[a-zA-Z0-9]*$/;
                                            const splitFileName =
                                              item.file_name.split(".docx");
                                            const newValue = comment.replace(
                                              regexp,
                                              splitFileName[0] + " "
                                            );
                                            setComment(newValue);
                                          }}
                                        >
                                          <img
                                            src="/static_images/user.svg"
                                            height={20}
                                            width={20}
                                          ></img>
                                          <p
                                            style={{
                                              fontWeight: 600,
                                              cursor: "pointer",
                                              width: "50%",
                                              marginLeft: 5,
                                            }}
                                          >
                                            {item.file_name}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                                <div className="col-9"></div>
                                <div
                                  className="col-3"
                                  onClick={() => {
                                    submitComment(
                                      comment,
                                      true,
                                      item.id,
                                      true,
                                      false
                                    );
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#00668F",
                                    color: "white",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                  Add Reply
                                </div>
                              </div>
                            )}
                      </Card>
                    ) : null
                  ) : (
                    <Card
                      className="my-3"
                      style={{
                        overflow: "visible",
                        paddingRight: 10,
                      }}
                    >
                      <CardContent>
                        <div className="d-flex justify-content-between ml-1 mr-1">
                          <div></div>
                          {props.isOwnerID() === item.created_by.id ? (
                            <div>
                              {item.proposed_to === "Replace" && (
                                <img
                                  src="/static_images/compare-icon.svg"
                                  className="pl-1 w-10"
                                  onClick={async () => {
                                    launch(
                                      item.ref_line,
                                      item.proposed_line,
                                      index
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                              <img
                                src="/static_images/section-clause-delete.svg"
                                className="pl-1 w-10"
                                onClick={async () => {
                                  if (props.draftDetails.open_collab) {
                                    deleteAnnotations(item.id).then((value) => {
                                      props.getDraftAnnotations();
                                    });
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          ) : null}
                        </div>
                        {showAnnotDiff.index === index &&
                        showAnnotDiff.isShow ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: showAnnotDiff.annot_diff_string.replace(
                                /&para;<br>/g,
                                "&nbsp;"
                              ),
                            }}
                          ></div>
                        ) : (
                          <div
                            className="mt-3 text-container ml-1 mr-1 p-2"
                            onClick={() => {
                              props.setAnnotationCommentDataPoint(
                                item.ref_line
                              );
                            }}
                            style={{ backgroundColor: "#FFEFE4" }}
                            dangerouslySetInnerHTML={{
                              __html: item.html_structure,
                            }}
                          ></div>
                        )}
                        <div className="row justify-content-sm-between ml-1 mr-1">
                          <div className="col-12">
                            <div className="row mt-2 d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="annotationDiv">
                                  <span
                                    className=""
                                    style={{
                                      color: "#EE5800",
                                      borderColor: "#EE5800",
                                    }}
                                  >
                                    {HeimdallUtil.getNameForAvatar(
                                      item.created_by
                                    )}
                                  </span>
                                </div>
                                <span
                                  className="fw-600 ml-2"
                                  style={{ color: "#818181" }}
                                >
                                  <span style={{ color: "#EE5800" }}>
                                    {item.created_by.first_name +
                                      " " +
                                      item.created_by.last_name}
                                  </span>{" "}
                                  Proposes to{" "}
                                  <span
                                    style={{
                                      fontWeight: 600,
                                    }}
                                  >{`${item.proposed_to}`}</span>
                                </span>
                              </div>
                              {isOwner() && item.status === "Pending" ? (
                                <div>
                                  <img
                                    src="/static_images/selectIcon.svg"
                                    className="pl-1 w-10"
                                    onClick={() => {
                                      if (props.draftDetails.open_collab) {
                                        props.updateSpecificAnnotation(
                                          item.id,
                                          "Approved"
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <img
                                    src="/static_images/closeIconnew.svg"
                                    className="pl-1 w-10"
                                    onClick={() => {
                                      if (props.draftDetails.open_collab) {
                                        props.updateSpecificAnnotation(
                                          item.id,
                                          "Rejected"
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              ) : item.status === "Approved" ? (
                                <div className="d-flex">
                                  <img
                                    src="/static_images/selectrighticon.svg"
                                    className="pl-1 w-10"
                                  />
                                  <p className="mb-0 ml-2 font-weight-bold">
                                    Approved
                                  </p>
                                </div>
                              ) : item.status === "Rejected" ? (
                                <div className="d-flex">
                                  <img
                                    src="/static_images/crossicon.svg"
                                    className="pl-1 w-10"
                                  />
                                  <p className="mb-0 ml-2 font-weight-bold">
                                    Rejected
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row ml-2 mt-2 align-items-center text-muted">
                            {getDate(item.update_time)}
                          </div>
                          {!showThread.showThread ? (
                            <div style={{ color: "#88305F" }}>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  if (
                                    showThread.showThread &&
                                    showThread.index === index
                                  ) {
                                    setShowThread({
                                      showThread: false,
                                      index: index,
                                    });
                                  } else {
                                    setShowThread({
                                      showThread: true,
                                      index: index,
                                    });
                                  }
                                }}
                              >
                                {item.comments.length > 0
                                  ? showThread.showThread &&
                                    showThread.index === index
                                    ? "Hide Thread"
                                    : "Show Thread"
                                  : null}
                              </span>
                              <span
                                className="cursor-pointer ml-3"
                                onClick={() => {
                                  if (props.draftDetails.open_collab) {
                                    if (showReply.isShow) {
                                      setShowThread({
                                        showThread: false,
                                        index: 0,
                                      });
                                      setShowReply({
                                        isShow: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: false,
                                        index: 0,
                                      });
                                      setShowReply({
                                        isShow: true,
                                        index: index,
                                      });
                                    }
                                  }
                                }}
                              >
                                Reply
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </CardContent>
                      {showThread.showThread
                        ? showThread.index === index && (
                            <>
                              {item.comments.map((element: any) => {
                                return (
                                  <CardContent className="mx-3">
                                    <div className="row justify-content-sm-between ml-1 mr-1">
                                      <div className="row ml-2 align-items-center">
                                        <div className="uploadByDiv">
                                          <span className="initials uploadByIcon ">
                                            {HeimdallUtil.getNameForAvatar(
                                              element.created_by
                                            )}
                                          </span>
                                        </div>
                                        <span
                                          className="fw-600 ml-2"
                                          style={{ color: "#818181" }}
                                        >
                                          <span style={{ color: "#88305f" }}>
                                            {element.created_by.first_name +
                                              " " +
                                              element.created_by.last_name}
                                          </span>{" "}
                                          Said
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className="mt-3 text-container ml-1 mr-1 p-2"
                                      style={{ backgroundColor: "#E9FBFF" }}
                                      dangerouslySetInnerHTML={{
                                        __html: element.html_structure,
                                      }}
                                    ></div>
                                    <div className="row justify-content-sm-between ml-1 mr-1">
                                      <div className="row ml-2 align-items-center text-muted">
                                        {getDate(element.update_time)}
                                      </div>
                                    </div>
                                  </CardContent>
                                );
                              })}
                              <div
                                style={{ color: "#88305F" }}
                                className="d-flex justify-content-end"
                              >
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (
                                      showThread.showThread &&
                                      showThread.index === index
                                    ) {
                                      setShowThread({
                                        showThread: false,
                                        index: index,
                                      });
                                    } else {
                                      setShowThread({
                                        showThread: true,
                                        index: index,
                                      });
                                    }
                                  }}
                                >
                                  {item.comments.length > 0
                                    ? showThread.showThread &&
                                      showThread.index === index
                                      ? "Hide Thread"
                                      : "Show Thread"
                                    : null}
                                </span>
                                <span
                                  className="cursor-pointer ml-3"
                                  onClick={() => {
                                    if (props.draftDetails.open_collab) {
                                      if (showReply.isShow) {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: false,
                                          index: index,
                                        });
                                      } else {
                                        setShowThread({
                                          showThread: false,
                                          index: 0,
                                        });
                                        setShowReply({
                                          isShow: true,
                                          index: index,
                                        });
                                      }
                                    }
                                  }}
                                >
                                  Reply
                                </span>
                              </div>
                            </>
                          )
                        : showReply.isShow &&
                          showReply.index === index && (
                            <div className="row m-0 p-3">
                              <div className="w-100">
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  label="Your Comment"
                                  variant="outlined"
                                  multiline
                                  className="mt-4"
                                  value={comment}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    commentChange(event);
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                />
                                {showSuggestions && (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      width: "50%",
                                      padding: "10px",
                                      marginTop: "10px",
                                      height: "200px",
                                      overflow: "scroll",
                                      position: "absolute",
                                      top: "220px",
                                      zIndex: 9,
                                      boxShadow: "5px 5px 10px #393E46",
                                    }}
                                  >
                                    {userList.map((item: any) => (
                                      <div
                                        className="d-flex mt-2"
                                        onClick={() => {
                                          setSelectedUser([
                                            ...selectedUser,
                                            item.id,
                                          ]);
                                          setShowSuggestions(false);
                                          setSupportSuggestions(false);
                                          const regexp = /[a-zA-Z0-9]*$/;
                                          const newValue = comment.replace(
                                            regexp,
                                            item.first_name + " "
                                          );
                                          setComment(newValue);
                                        }}
                                      >
                                        <img
                                          src="/static_images/user.svg"
                                          height={20}
                                          width={20}
                                        ></img>
                                        <p
                                          style={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            width: "50%",
                                            marginLeft: 5,
                                          }}
                                        >
                                          {item.first_name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                                {supportSuggestions && (
                                  <div
                                    style={{
                                      backgroundColor: "white",
                                      width: "50%",
                                      padding: "10px",
                                      marginTop: "10px",
                                      height: "200px",
                                      overflow: "scroll",
                                      position: "absolute",
                                      top: "220px",
                                      zIndex: 9,
                                      boxShadow: "5px 5px 10px #393E46",
                                    }}
                                  >
                                    {supportDoc.map((item: any) => (
                                      <div
                                        className="d-flex mt-2"
                                        onClick={() => {
                                          setSelectedSupportDoc([
                                            ...selectedSupportDoc,
                                            item.id,
                                          ]);
                                          setSupportSuggestions(false);
                                          setShowSuggestions(false);
                                          const regexp = /[a-zA-Z0-9]*$/;
                                          const splitFileName =
                                            item.file_name.split(".docx");
                                          const newValue = comment.replace(
                                            regexp,
                                            splitFileName[0] + " "
                                          );
                                          setComment(newValue);
                                        }}
                                      >
                                        <img
                                          src="/static_images/user.svg"
                                          height={20}
                                          width={20}
                                        ></img>
                                        <p
                                          style={{
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            width: "50%",
                                            marginLeft: 5,
                                          }}
                                        >
                                          {item.file_name}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                              <div className="col-9"></div>
                              <div
                                className="col-3"
                                onClick={() => {
                                  submitComment(
                                    comment,
                                    true,
                                    item.id,
                                    true,
                                    false
                                  );
                                }}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#00668F",
                                  color: "white",
                                  padding: "10px",
                                  borderRadius: "5px",
                                  marginTop: "20px",
                                  textAlign: "center",
                                }}
                              >
                                Add Reply
                              </div>
                            </div>
                          )}
                    </Card>
                  )}
                </>
              );
            })
          : null
        : null}
    </>
  );
};
