import React, { Component } from "react";
import {
  getCommentTimeFormat,
  getDateFormat,
  getUploadedTime,
} from "../../../../Utils/DataModifierUtil/dataModUtil";
import { Comments } from "../State/taskManagementState";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface Props {
  comments: Comments[];
}
interface State {}
class PostedComments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    let { comments } = this.props;
    return (
      <>
        {comments.map((comment: Comments, index: number) => (
          <>
            <div className="row mt-2">
              <div className="col-md-1">
                <div
                  className="avatar-circle-sm"
                  style={{
                    backgroundColor: "1",
                    //    comment.hexID
                  }}
                >
                  <span className="initials">
                    {/* {comment.aliasName} */}
                    {comment.created_by.split(" ") ? (
                      <>
                        {comment.created_by.split(" ")[0]
                          ? comment.created_by.split(" ")[0][0].toUpperCase()
                          : ""}
                        {comment.created_by.split(" ")[1]
                          ? comment.created_by.split(" ")[1][0].toUpperCase()
                          : ""}
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 pr-0 mt-1 user-name">
                <span>{comment.created_by}</span>
              </div>
              <div className="col-md-5 mt-1 timestamp">
                <span>{getCommentTimeFormat(comment.created_at)}</span>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-1">{/* do nothing */}</div>
              <div className="col-md-6 comment">
                {/* <span>{comment.message}</span> */}
                <ReactQuill
                  value={comment.message}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
              <div className="col-md-5">{/* do nothing */}</div>
            </div>
          </>
        ))}
      </>
    );
  }
}

export default PostedComments;
