import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { Approvers } from "../State/DraftState";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "242px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Props {
  modalVisible: any;
  handleClose: any;
  approvers: Approvers[];
  requestApproval: (approver: any[], comments: string) => void;
  approvalType: string;
}
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const DeviationRequestBtnmodal: React.FC<Props> = (props: Props) => {
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [comments, setcomments] = React.useState<string>("");
  const [loader, setloader] = React.useState<boolean>(false);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const approvalRequest = () => {
    console.log("personName", personName);
    let selectedApprovers: any[] = personName;
    let selectedApproverValues: any[] = [];

    if (selectedApprovers.length > 0) {
      for (let i = 0; i < selectedApprovers.length; i++) {
        let filterApprovers = props.approvers.filter(function (
          approver: Approvers
        ) {
          return approver.username == selectedApprovers[i];
        });
        if (filterApprovers.length > 0) {
          selectedApproverValues.push(filterApprovers[0].id);
        }
      }
    }
    if (selectedApproverValues.length > 0) {
      setloader(true);
      props.requestApproval(selectedApproverValues, comments);
    }
    console.log("selectedApproverValues", selectedApproverValues);
  };
  return (
    <>
      <Modal
        open={props.modalVisible == "Request Approval"}
        className="deletemodal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={hierarchyStyle} className="position-relative">
        {loader?<CircularProgress className="loader" />:null}
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              {props.approvalType == "amber-deviation"? 'Adhoc Approval':'Request Approval'}
              
              <span className="mr-0">
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                  onClick={() => props.handleClose()}
                />
              </span>
            </div>
          </Typography>
          <Typography
            style={{ margin: "20px", gap: "10px" }}
            className="d-flex flex-column"
          >
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#626262",
                width: "64%",
              }}
            >
              Please select the users from which approval needs to be received.
            </div>

            <div className="row">
              <div className="col-md-12 p-0">
                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span
                      className="addTag-title "
                      style={{ color: "#88305F" }}
                    >
                      Select Approvers
                    </span>
                  </div>
                  <div className="mt-1">
                    <FormControl style={{ width: "272px" }} className="m-1">
                      <Select
                        multiple
                        value={personName}
                        onChange={handleChange}
                        //   value={childData}
                        //   onChange={(val) => handleExternal(val)}
                        style={{ height: "26px" }}
                        input={<OutlinedInput label="" />}
                        renderValue={(selected: any) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {/* {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={personName.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))} */}

                        {props.approvers.map((approver: Approvers) => (
                          <MenuItem key={approver.id} value={approver.username}>
                            <Checkbox
                              checked={
                                personName.indexOf(approver.username) > -1
                              }
                            />
                            <ListItemText primary={approver.username} />
                          </MenuItem>
                        ))}

                        {/* {childDropdown?.map(
                                    (name: any, index: any) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={name?.fileName}
                                        >
                                          <Checkbox
                                            className="checkbox-custom"
                                            checked={
                                              childData.indexOf(
                                                name?.fileName
                                              ) > -1
                                            }
                                          />
                                          <ListItemText
                                            style={{ whiteSpace: "initial" }}
                                            primary={name?.fileName}
                                          />
                                        </MenuItem>
                                      );
                                    }
                                  )} */}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span
                      className="addTag-title "
                      style={{ color: "#88305F" }}
                    >
                      Add Comment
                    </span>
                  </div>
                  <div className="mt-1">
                    <FormControl style={{ width: "550px" }} className="m-1">
                      <textarea
                        rows={4}
                        name="text"
                        placeholder="Type here"
                        style={{
                          borderColor: "#88305f",
                          outlineColor: "#88305f",
                        }}
                        onChange={(e) => {
                          setcomments(e.target.value);
                        }}
                      ></textarea>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className=" row col-12">
              <div className="col-5">
                <button
                  style={{ cursor: "pointer" }}
                  className="btn-deviation"
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </button>
              </div>
              <div className="col-7">
                <button
                  style={{ cursor: "pointer" }}
                  className="btn-deviation-cl"
                  onClick={() => {
                    approvalRequest();
                  }}
                >
                  Create   {props.approvalType == "amber-deviation"? 'Adhoc Approval':'Approval Request'}
                </button>
              </div>
            </div>
            <br />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviationRequestBtnmodal);
