import * as React from "react";
import { isNullOrUndefined } from "is-what";

interface Props {
  // deleteInsight: (confirmed: boolean) => void;
}

interface State {}

export default class DeviationPolicyModal extends React.Component<
  Props,
  State
> {
  componentDidMount() {
    document.addEventListener("mouseup", this.handleOutsideClick, false);
  }

  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("deviationPolicyModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("delete-insight-btn")?.click();
      }
    }
  };

  render() {
    return (
      <div className="col-md-12 col-12">
        <div
          className="row modal"
          id="deviationPolicyModal"
          aria-labelledby="deviationPolicyModal"
        >
          <div
            className="col-md-12 modal-dialog"
            style={{
              height: "100vh",
              marginTop: "0",
              marginBottom: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="row">
              <div className="col-md-12 modal-content">
                <div className="row">
                  <div className="col-md-12 modal-body">
                    <span
                      data-dismiss="modal"
                      style={{ float: "right", cursor: "pointer" }}
                      id="file-hierarchy-modal-close-btn"
                      // onClick={() => this.dismissModal()}
                    >
                      <img
                        src="/static_images/close-modal-icn.svg"
                        alt="close"
                      />
                    </span>
                  </div>

                  <div
                    className="col-md-12 modal-title"
                    style={{ color: "#88305f" }}
                  >
                    <>Relevant Approver(s) has been notified</>
                  </div>
                </div>

                <div className="modal-footer float-right">
                  <button
                    type="button"
                    className="upload-yellow-btn ml-4"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  confirmInsightDelete() {
    // this.props.deleteInsight(true);
  }

  cancelInsightDelete() {
    // this.props.deleteInsight(false);
  }
}
