import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Table,
  Button,
} from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { DarkTooltip } from "../documentInsights";
import { isNullOrUndefined } from "is-what";
const lg = "#D8E4BC";
const p16 = "16px";
// const userName =  getLocalStorage("userFullName");


const ViewInsights = (props) => {
  const { insightsData, quickIntel } = props;
  const [insights, setInsights] = useState([]);
  const [tableTopInfo, setTableTopInfo] = useState([]);
  const [title, settitle] = useState("insights");
  const [username, setusername] = useState(null);
  useEffect(() => {
    let insights = [];

    for (let i = 0; i < insightsData.length; i++) {
      if (insightsData[i].label === "Basic Information") {
      } else {
        const newInsights = getInsightPoints(insightsData[i]);
        insights.push(...newInsights);
      }
    }
    setusername(localStorage.getItem("user_name"))
    setInsights([...insights]);
  }, [props.insightsData]);

  useEffect(() => {
    
    let basicInfo = {};
    for (let i = 0; i < insightsData.length; i++) {
      if (insightsData[i].label === "Basic Information") {
        basicInfo = getBasicInfo(insightsData[i]);
      }
    }
    settitle(basicInfo["title"]);
    setTableTopInfo([
      { key: "Downloaded On", value: moment().format("LLLL") },
      { key: "Downloaded By:", value: username ? username : "" },
      {
        key: "Reviewed By:",
        value: "[Name of the Lawyer reviewing the summary]",
        color: "#FF0000",
      },
      { key: "File Name", value: props.quickIntel.fileName },
      { key: "Contract Title", value: basicInfo.title },
      {
        key: "Parties ",
        value: basicInfo.partyName,
      },
      { key: "Party Types", value: basicInfo.partyType },
    ]);
  }, [props.quickIntel, localStorage.getItem("user_profile")]);
  return (
    <>
      <span
        className="riverus-yellow-btn"
        style={{ fontSize: "13px", paddingBottom: 3, float: "right" }}
        onClick={() => {
          let link = document.getElementById("test-table-xls-button");
          !isNullOrUndefined(link) && link.click();
        }}
      >
        <img
          src="/static_images/download-insights-icn.svg"
          alt="download-insights"
          className="p-1"
        />
        Download Insights
      </span>
      {/* <DarkTooltip title={"Download Insights"} placement="bottom">
        <span
          className="cursor-pointer tooltip download-style"
          style={{ marginLeft: "2rem" }}
          onClick={() => {
            let link = document.getElementById("test-table-xls-button");
            !isNullOrUndefined(link) && link.click();
          }}
        >
          <img
            src="/static_images/new-download-icn.svg"
            alt="Download Insights"
          />
        </span>
      </DarkTooltip> */}
      <div style={{ display: "None" }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-warning btn-sm"
          table="table-to-xls"
          filename="insights"
          sheet="insights"
          // buttonText={<FaFileDownload size={16} />}
          buttonText="Download Insights"
        />
      </div>
      <table
        id="table-to-xls"
        style={{ display: "none", fontFamily: "Cambria Math" }}
      >
        <tbody>
          <RenderTableTopInfo tableTopInfo={tableTopInfo} />

          <tr>
            <td style={{ width: "469px" }}></td>
            <td style={{ width: "224px" }}></td>
            <td style={{ width: "434px" }}></td>
            <td style={{ width: "700px" }}></td>
            <td style={{ width: "308px" }}></td>
          </tr>
          <RenderHeader />
          {insights.map((ins, i) => {
            return (
              <tr key={i}>
                <td>{ins["Clause Type"]}</td>
                <td>{ins["BI Type"]}</td>
                <td>{ins["BI Value"]}</td>
                <td>{ins["Clause Text"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default ViewInsights;

const getInsightPoints = (insight) => {
  const res = [];
  const { label, children, dataPoints } = insight;
  children.map((child) => {
    /*if (
      child.childLabel !== label &&
      child.childLabel !== "Confidentiality" &&
      child.childLabel !== "Subletting/Assignment"
    ) {*/
      
      if (child.childValue && child.childValue.length > 0) {
        child.childValue.map((clause) => {
          if(clause.name != -1){
          const clauseName =
            clause.name && clause.name.toLowerCase() === "yes"
              ? clause.dataPoints.highlightedText
                ? clause.dataPoints.highlightedText
                : getHighlightedPara(clause.name, clause.dataPoints)
              : clause.name;
          if (clauseName.toLowerCase() === "no") {
          } else {
            const highlightedText = getHighlightedPara(
              clauseName,
              clause.dataPoints
            );
            res.push({
              "Clause Type": label,
              "BI Type": child.childLabel,
              "BI Value": clauseName,
              "Clause Text": highlightedText,
              "Associated Heading": "",
              Comment: "",
            });
          }
        }
        });
      }
    // }
  });
  return res;
};
const getBasicInfo = (info) => {
  const { label, children, dataPoints } = info;
  const [title, tags, parties] = children;
  let partyName = "";
  let partyType = "";
  {
    parties.childValue.map((party, i) => {
      if (i) {
        partyName += ", ";
        partyType += ", ";
      }
      partyName += party.partyName;
      partyType += party.partyType;
    });
  }
  const res = {
    title: title.childValue[0].name,
    partyName,
    partyType,
  };

  return res;
};
const getHighlightedPara = (clauseName, dataPoint) => {
  const { paraId } = dataPoint;
  let sentence = "";
  for (let w = 0; ; w++) {
    let wordId = `p${paraId};w${w}`;
    let word = document?.getElementById(wordId.toString())?.textContent;
    if (word && word.length > 0) {
      sentence += word;
      sentence += " ";
    } else {
      break;
    }
  }
  return sentence;
};

const RenderTableTopInfo = ({ tableTopInfo }) => {
  return (
    <>
      {tableTopInfo.map((info, i) => {
        return (
          <tr key={i}>
            <td colSpan={2}></td>
            <td
              style={{
                backgroundColor: lg,
                fontSize: p16,
                fontWeight: "bold",
                textAlign: "center",
                color: info.color ? info.color : "black",
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >
              {info.key}
            </td>
            <td
              style={{
                backgroundColor: lg,
                fontSize: p16,
                fontWeight: "bold",
                textAlign: "center",
                color: info.color ? info.color : "black",
                borderWidth: 1,
                borderColor: "black",
                borderStyle: "solid",
              }}
            >
              {info.value}
            </td>
          </tr>
        );
      })}
    </>
  );
};
const header = [
  "Type of Clause",
  "Type of Business Intelligence found in this Clause ",
  "Extracted Business Intelligence from this Clause ",
  "Corresponding Clause Text from Contract",
  "Assessment & Recommendation ",
  "How to Read this Clause & the Assessment",
];
const RenderHeader = () => {
  const lb = "#8DB4E2";
  const p14 = "14px";
  return (
    <tr>
      {header.map((head, i) => {
        return (
          <td
            style={{
              backgroundColor: lb,
              fontSize: p14,
              fontWeight: "bold",
              textAlign: "center",
            }}
            key={i}
          >
            {head}
          </td>
        );
      })}
    </tr>
  );
};
