import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import { DocumentViewer } from "react-documents";
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import DeviationButtonmodal from "./DeviationButtonmodal";
import DeviationRequestBtnmodal from "./DeviationRequestBtnmodal";
import DeviationCLComponent from "./DeviationCLComponent";
import ApprovalTab from "./ApprovalTab";
import RuleAndDeviationTab from "./RuleAndDeviationTab";
import LogTab from "./LogTab";
import { AnnotationAndCommentTab } from "./AnnotationAndCommentTab";
import ReworkPopup from "./ReworkPopup";
import RejectionPopup from "./RejectionPopup";
import { idText } from "typescript";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import moment from "moment";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { draftStatus, initialContractData } from "../State/DraftState";
import {
  getcontracts,
  getdraftbyid,
  getreqbyidbyid,
  sendemailtoapproval,
} from "../DA";
import { truncateFileName } from "../../Utils/DataModifierUtil/dataModUtil";
import SupportDocTab from "./SupportDocTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Props {
  open: any;
  setopenapproval: any;
  close: any;
  requisitionId: any;
  version: any;
  draftcomponent: any;
  reqres: any;
  openmain: any;
  show: any;
  createLink?: any | undefined;
  getDrafts: any;
  setTemplate?: any | undefined;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ForApproval = (props: Props) => {
  const [value, setValue] = React.useState(1);
  const [reworkopen, setReworkOpen] = React.useState(false);
  const [rejectionOpen, setRejectionOpen] = React.useState(false);
  const [inta, seInsta] = useState<any>("");
  const [user, setUser] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [selectedDoc, setselectedDoc] = useState<any[]>([]);
  const [supportDocs, setsupportDocs] = useState<any[]>([]);
  const [requistionlog, setrequisitionlog] = useState<any>([]);
  const [loading, setloading] = useState<any>(false);
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [reqFormData, setReqForm] = useState<any>({});
  const [logkeys, setlogkey] = useState<any>([]);
  const [loader, setloader] = useState(true);
  const [logloader, setlogloader] = useState(true);

  const [draftsData, setDraftsData] = useState<any>();
  const [isDark, setTheme] = useState(false);

  const [contractdata, setcontractdata] = useState<any>([]);
  const [fileLink, setFileLink] = useState<string>("");

  const requisitionAccessURL = async () => {
    const response = await getreqbyidbyid(props.reqres?.id);
    if (response) {
      return response.access_url;
    }
    return "";
  };

  useEffect(() => {
    const user_data: string | null = localStorage.getItem("user_profile");
    if (user_data) {
      setUser(JSON.parse(user_data));
    }
    requisitionAccessURL().then((value) => {
      if (value) {
        setFileLink(value);
      }
    });
  }, []);

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  useEffect(() => {
    const handleexecutedcontract = async () => {
      try {
        const getdata = await getcontracts();
        if (getdata?.results && getdata?.results.length) {
          setcontractdata(getdata.results);
        }
      } catch (error) {}
    };
    handleexecutedcontract();
  }, []);

  const getRequisition = () => {
    try {
      setloader(true);
      if (props.version === 0) {
        axios
          .get(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${props.requisitionId}/`,
            APIConfig()
          )
          .then((response: any) => {
            setReqForm(response.data);
            setloader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        axios
          .get(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/draft/${props.requisitionId}/`,
            APIConfig()
          )
          .then((response: any) => {
            setReqForm(response.data);
            setloader(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    getRequisition();
  }, []);
  useEffect(() => {
    if (props.version === 0) {
      setlogloader(true);
      axios
        .get(
          process.env.REACT_APP_RIVERUS_CLM_API +
            `stylus/requisition/${props.requisitionId}/logs/`,
          APIConfig()
        )
        .then((response) => {
          setrequisitionlog(response.data);
          setlogkey(Object.keys(response.data));
          setlogloader(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setlogloader(true);
      axios
        .get(
          process.env.REACT_APP_RIVERUS_CLM_API +
            `stylus/draft/${props.requisitionId}/logs/`,
          APIConfig()
        )
        .then((response) => {
          setrequisitionlog(response.data);
          setlogkey(Object.keys(response.data));
          setlogloader(false);
        })
        .catch((error) => {
          console.log(error);
          setlogloader(false);
        });
    }
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  const documentContainer = useRef<HTMLDivElement>(null);
  const callAction = (passedValue: any, documentViewer: any) => {};
  useEffect(() => {
    setTimeout(() => {
      if (documentContainer.current != null && fileLink != "") {
        WebViewer(
          {
            path: "/webviewer",
            disabledElements: ["header", "toolsHeader"],
          },
          documentContainer.current
        ).then((instance: any) => {
          seInsta(instance);
          const { UI, Core } = instance;
          const { documentViewer, Annotations, annotationManager, Tools } =
            Core;

          UI.textPopup.update([]);

          // UI.textPopup.update([
          //   {
          //     type: "actionButton",
          //     img: "/static_images/add-clause.svg",
          //     onClick: () => callAction("Add Bookmark...", documentViewer),
          //   },
          //   {
          //     type: "actionButton",
          //     img: "/static_images/clause_copy.svg",
          //     onClick: () => callAction("Copy text...", documentViewer),
          //   },
          //   {
          //     type: "actionButton",
          //     img: "/static_images/browse-btn-icon.svg",
          //     onClick: () => callAction("replace from CL", documentViewer),
          //   },
          //   {
          //     type: "actionButton",
          //     img: "/static_images/add-tag-icn.svg",
          //     onClick: () => callAction("labelling", documentViewer),
          //   },
          //   {
          //     type: "actionButton",
          //     img: "/static_images/section-clause-delete.svg",
          //     onClick: () => callAction("Delete", documentViewer),
          //   },
          // ]);
          // call methods from UI, Core, documentViewer and annotationManager as needed
          documentViewer.addEventListener("documentLoaded", () => {
            // call methods relating to the loaded document
          });
          instance.UI.loadDocument(fileLink);
        });
      }
    }, 200);
  }, [documentContainer.current, fileLink]);
  //     useEffect(() => {
  //       setOpen(props.open);
  //     }, [props.open]);

  //     const handleClose = () => {
  //       setOpen(false);
  //       props.onClose();
  //     };

  //     const downloadDoc = () => {
  //       saveAs(props.fileLink, `${props.fileName}.docx`);
  //     };

  const downloadDoc = async () => {
    try {
      const response = await getreqbyidbyid(props.reqres?.id);
      if (response) {
        saveAs(response.access_url, `${response.access_url}.docx`);
      }
    } catch (error) {}
  };

  const Zoomout = () => {
    const zoom = inta.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      inta.UI.setZoomLevel(zoom);
    }
  };
  const ZoomIn = () => {
    const zoom = inta.UI.getZoomLevel() + 0.25;
    inta.UI.setZoomLevel(zoom);
  };
  const selectMultipleDrafts = (id: string) => {
    let selected = [...selectedDoc];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedDoc([...selected]);
  };

  const common = async (response: any) => {
    const contractfilter = contractdata?.length
      ? contractdata?.filter((item: any) => item?.id == response.source)
      : [];
    const splitted = contractfilter?.length
      ? contractfilter[0]?.file_name.split(".")
      : "";
    const fileextension = contractfilter?.length
      ? splitted[splitted.length - 1]
      : "";
    let draftpayload: any = {
      createFrom: response.createFrom,
      contractName: response.contractName,
      link: response.link ? response.link : null,
      contractType: response?.contractType?.id,
      groups: response.groups,
      projectNames: response.projectNames,
      counterParties: response.counterParties,
      collaborators: response?.collaborators?.map((i: any) => i.id),
      approvalTypes: response.approvalTypes,
      signatories: response.signatories,
      version: 1,
      owner: [response.owner.id],
      status:
        response.createFrom == "counter_party"
          ? "Counter Party Draft Created"
          : response.createFrom === "template"
          ? draftStatus.DRAFT_CREATION_PENDING
          : "Internal Draft Created",
      deadline: response.deadline,
      earlier_draft_link: response.source,
      requisition_approver_link: response.requisition_approver_link,
      source: response.source,
      requisition_id: response.id,
      counter_party_link:
        "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/2a1ad994-d45a-452d-a287-3e4ad45da51b.docx",
      template_link: response.template_link,
      support_document_link:
        "https://riverus-clm-testing-stylus1.s3.us-west-2.amazonaws.com/2a1ad994-d45a-452d-a287-3e4ad45da51b.docx",
      executed_contract_link: `${response.source}.${fileextension}`,
      pre_signatories: [],
    };
    if (response.createFrom == "earlier_draft") {
      [
        "template_link",
        "counter_party_link",
        "support_document_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (response.createFrom == "template") {
      [
        "earlier_draft_link",
        "counter_party_link",
        "support_document_link",
        "executed_contract_link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (response.createFrom == "counter_party") {
      [
        "earlier_draft_link",
        "template_link",
        "support_document_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else if (response.createFrom == "support_document") {
      [
        "earlier_draft_link",
        "template_link",
        "counter_party_link",
        "executed_contract_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    } else {
      [
        "earlier_draft_link",
        "template_link",
        "counter_party_link",
        "support_document_link",
        "link",
      ].forEach((e: any) => delete draftpayload[e]);
    }
    axios
      .post(
        process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/`,
        draftpayload,
        APIConfig()
      )
      .then((res: any) => {
        props.getDrafts();
        setDraftsData(res.data);
        setloading(false);
        setopenSnackbar({ open: true, msg: "Approved successfully" });
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {}, [draftsData]);

  const handlesetStatus = (val: any) => {
    const data: any = val?.approvalTypes[0];
    if (data.approval_sequence.toLowerCase() == "all") {
      const checkStatus = data?.approvers?.every(
        (item: any) =>
          item.approval_status.toLowerCase() == "approved" ||
          item.approval_status == "Requisition Approved"
      );
      if (checkStatus) {
        axios
          .patch(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${props?.reqres?.id}/`,
            {
              status: "Requisition Approved",
              draft_created:
                val.createFrom === "support_document" ||
                val.createFrom === "counter_party"
                  ? false
                  : true,
            },
            APIConfig()
          )
          .then((response: any) => {
            if (
              response.data.createFrom.toLowerCase() === "counter_party" ||
              response.data.createFrom.toLowerCase() === "support_document"
            ) {
              props.getDrafts();
              setloading(false);
              setopenSnackbar({ open: true, msg: "Approved successfully" });
            } else {
              if (
                props.createLink &&
                val.createFrom.toLowerCase() == "template"
              ) {
                props.createLink(response.data, common);
              } else {
                common(response.data);
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        return;
      }
    } else if (data.approval_sequence.toLowerCase() == "any") {
      const checkStatus = data?.approvers?.some(
        (item: any) =>
          item.approval_status.toLowerCase() == "approved" ||
          item.approval_status == "Requisition Approved"
      );
      if (checkStatus) {
        axios
          .patch(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${props?.reqres?.id}/`,
            {
              status: "Requisition Approved",
              draft_created:
                val.createFrom === "support_document" ||
                val.createFrom === "counter_party"
                  ? false
                  : true,
            },
            APIConfig()
          )
          .then((response: any) => {
            if (
              response.data.createFrom.toLowerCase() === "counter_party" ||
              response.data.createFrom.toLowerCase() === "support_document"
            ) {
              props.getDrafts();
              setloading(false);
              setopenSnackbar({ open: true, msg: "Approved successfully" });
            } else {
              if (
                props.createLink &&
                val.createFrom.toLowerCase() == "template"
              ) {
                props.createLink(response.data, common);
              } else {
                common(response.data);
              }
            }
          })
          .catch((err) => console.log(err));
      }
    } else if (data.approval_sequence.toLowerCase() == "in sequence") {
      if (data?.approvers?.length == 1) {
        const checkStatus = data?.approvers?.every(
          (item: any) =>
            item.approval_status.toLowerCase() == "approved" ||
            item.approval_status == "Requisition Approved"
        );
        if (checkStatus) {
          axios
            .patch(
              process.env.REACT_APP_RIVERUS_CLM_API +
                `stylus/requisition_form/${props?.reqres?.id}/`,
              {
                status: "Requisition Approved",
                draft_created:
                  val.createFrom === "support_document" ||
                  val.createFrom === "counter_party"
                    ? false
                    : true,
              },
              APIConfig()
            )
            .then((response: any) => {
              if (
                response.data.createFrom.toLowerCase() === "counter_party" ||
                response.data.createFrom.toLowerCase() === "support_document"
              ) {
                props.getDrafts();
                setloading(false);
                setopenSnackbar({ open: true, msg: "Approved successfully" });
              } else {
                if (
                  props.createLink &&
                  val.createFrom.toLowerCase() == "template"
                ) {
                  props.createLink(response.data, common);
                } else {
                  common(response.data);
                }
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        const findindex = data.approvers?.findIndex(
          (item: any) => item.id === user.id
        );
        if (data.approvers.length - 1 > findindex) {
          const approvaldata = data.approvers[findindex + 1];
          let payload = {
            user_ids: [approvaldata.id],
            body_html: `<h2>Hello ${approvaldata?.first_name},<br/></h2><p>I am creating a new contract on Riverus Contracts Platform and requesting you to approve ${val?.contractName}.
      </p><p>Alternatively, you may check the details of the draft by <a href="${window.location.origin}/draftingreview/${val?.id}" target="_blank">clicking here</a></span>and take further action.</p>
      <p>The deadline for the draft is ${val?.deadline}.</p><p> Thank you in advance for your timely action.</p>
      <h4>Regards,</h4><h4>${val?.owner.first_name}</h4>`,
            body_text: `${user.first_name} raised requisition approval for ${val?.contractName}. Kindly review and approve the same.`,
            subject: `Requisition approval raised for ${val?.contractName}`,
          };

          sendemailtoapproval(payload);
          // axios
          //   .post(
          //     process.env.REACT_APP_RIVERUS_CLM_API + `sending_email/`,
          //     {
          //       user_ids: [approvaldata.id],
          //       body_html: `<h2>Hello ${approvaldata?.first_name},<br/></h2><p>I am creating a new contract on Riverus Contracts Platform and requesting you to approve ${val?.contractName}.
          // </p><p>Alternatively, you may check the details of the draft by <a href="${window.location.origin}/draftingreview/${val?.id}" target="_blank">clicking here</a></span>and take further action.</p>
          // <p>The deadline for the draft is ${val?.deadline}.</p><p> Thank you in advance for your timely action.</p>
          // <h4>Regards,</h4><h4>${val?.owner.first_name}</h4>`,
          //       body_text: `${user.first_name} raised requisition approval for ${val?.contractName}. Kindly review and approve the same.`,
          //       subject: `Requisition approval raised for ${val?.contractName}`,
          //     },
          //     APIConfig()
          //   )
          //   .then((response: any) => {
          //     console.log(response, "slnfnf");
          //   })
          //   .catch((err) => {
          //     console.log(err, "errrrrrrr");
          //   });
        } else {
          const checkStatus = data?.approvers?.some(
            (item: any) =>
              item.approval_status.toLowerCase() == "approved" ||
              item.approval_status == "Requisition Approved"
          );
          if (checkStatus) {
            axios
              .patch(
                process.env.REACT_APP_RIVERUS_CLM_API +
                  `stylus/requisition_form/${props?.reqres?.id}/`,
                {
                  status: "Requisition Approved",
                },
                APIConfig()
              )
              .then((response: any) => {
                if (
                  response.data.createFrom.toLowerCase() === "counter_party" ||
                  response.data.createFrom.toLowerCase() === "support_document"
                ) {
                  props.getDrafts();
                } else {
                  if (
                    props.createLink &&
                    val.createFrom.toLowerCase() == "template"
                  ) {
                    props.createLink(response.data, common);
                  } else {
                    common(response.data);
                  }
                }
              })
              .catch((err) => console.log(err));
          }
        }
      }
    } else {
    }
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });
    props.setopenapproval(false);
    props.close();
    props.openmain();
  };

  const handleconfirmreq = () => {
    setloading(true);
    let newData: any = [...props?.reqres?.approvalTypes[0]?.approvers];
    const filter = newData?.findIndex(
      (item: any, index: any) => item?.id === user.id
    );
    newData[filter].approval_status = "Requisition Approved";
    newData[filter].approved_date = new Date().toLocaleDateString();
    axios
      .patch(
        process.env.REACT_APP_RIVERUS_CLM_API +
          `stylus/requisition_form/${props?.reqres?.id}/`,
        {
          approvalTypes: [
            {
              approval_sequence:
                props?.reqres?.approvalTypes[0]?.approval_sequence,
              approval_type_name:
                props?.reqres?.approvalTypes[0]?.approval_type_name,
              approvers: newData,
            },
          ],
          logs: {
            action: `approved the requisition raised by ${props.reqres.owner.first_name} ${props.reqres.owner.last_name}`,
            action_on: [user.id],
          },
        },
        APIConfig()
      )
      .then(async (res: any) => {
        let payload = {
          user_ids: [res.data.owner.id],
          body_html: `<h2>Hello ${res.data.owner.first_name},</h2><p>The new contract requisition form -<a href="${window.location.origin}/draftingreview/${res.data.id}" target="_blank">${window.location.origin}/draftingreview/?${res.data.id}</a> has been approved.
          </p><h4>Regards,</h4><h4>${user.first_name}</h4>`,
          body_text: `${res.data.owner.first_name} raised requisition approval for ${props?.reqres?.contractName}. Kindly review and approve the same.`,
          subject: `Requisition approval raised for ${props?.reqres?.contractName}`,
        };

        await sendemailtoapproval(payload);
        handlesetStatus(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkApprovalStatus = () => {
    if (user?.roles?.includes("Approvers")) {
      const approvalval_type = reqFormData?.approvalTypes[0];
      const data = approvalval_type?.approvers?.filter(
        (val: any) => val.id == user.id
      );
      if (data.length) {
        if (
          reqFormData.status.toLowerCase() == "approved" ||
          reqFormData.status == "Requisition Approved" ||
          reqFormData.status.toLowerCase() == "rework" ||
          reqFormData.status.toLowerCase() == "rejected"
        ) {
          return false;
        } else {
          if (
            approvalval_type?.approval_sequence?.toLowerCase() == "in sequence"
          ) {
            const approval_filter = approvalval_type.approvers?.findIndex(
              (val: any) => val.id == user.id
            );
            if (approval_filter > 0) {
              let newArr = approvalval_type.approvers.slice(0, approval_filter);
              const checkStatus = newArr?.every(
                (item: any) =>
                  item.approval_status?.toLowerCase() == "approved" ||
                  item.approval_status == "Requisition Approved"
              );
              if (checkStatus) {
                return true;
              } else {
                return false;
              }
            } else {
              if (
                approvalval_type.approvers[
                  approval_filter
                ]?.approval_status?.toLowerCase() == "approved" ||
                approvalval_type.approvers[approval_filter]?.approval_status ==
                  "Requisition Approved"
              ) {
                return false;
              } else {
                return true;
              }
            }
          } else if (
            approvalval_type.approval_sequence?.toLowerCase() == "all" ||
            approvalval_type.approval_sequence?.toLowerCase() == "any"
          ) {
            const approval_filter = approvalval_type.approvers?.filter(
              (val: any) => val.id == user.id
            );
            if (
              approval_filter.length &&
              (approval_filter[0].approval_status?.toLowerCase() ==
                "approved" ||
                approval_filter[0].approval_status == "Requisition Approved")
            ) {
              return false;
            } else {
              return true;
            }
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {}, [loading]);

  const darkTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.DARK);
  };
  const lightTheme = () => {
    const theme = inta.UI.Theme;
    inta.UI.setTheme(theme.LIGHT);
  };
  const search = () => {
    const { Core } = inta;
    inta.UI.searchText("", {
      caseSensitive: true,
      wholeWord: true,
    });
  };
  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={props.open}
      onClose={() => {
        props.openmain();
        props.close();
        props.setopenapproval(false);
      }}
      className="draft-main"
    >
      <DialogTitle>
        <div className="row m-0 p-0">
          <div className="col-md-3">
            <span style={{ fontSize: "16px" }}>
              <span
                onClick={() => {
                  if (props.show) {
                    props.openmain();
                    props.setopenapproval(false);
                    props.close();
                  } else {
                    props.setopenapproval(false);
                    props.openmain();
                    props.close();
                  }
                }}
                style={{ marginRight: "10px", cursor: "pointer" }}
              >
                <img
                  style={{ width: "15px" }}
                  src="/static_images/back-arrow.svg"
                  alt="close_icon"
                />
              </span>
              {/* Contract Draft View {">"} {props.fileName} */}
            </span>
          </div>
          <div className="col-md-7">
            {openSnackbar.open ? (
              <div className="w-50 position-fixed d-flex justify-content-center">
                <div
                  className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                  style={{
                    marginLeft: "-25px",
                    padding: "10px",
                    width: "60%",
                    height: "42px",
                    borderRadius: "100px",
                  }}
                >
                  <div className="d-flex text-muted" style={{ gap: "10px" }}>
                    {openSnackbar.msg}
                  </div>
                  <div
                    className="d-flex cursor-pointer"
                    style={{ gap: "10px", color: "#88305F" }}
                    onClick={closeSnackbar}
                  >
                    OK
                  </div>
                </div>
              </div>
            ) : Object.keys(reqFormData)?.length && checkApprovalStatus() ? (
              <div className="w-50 position-fixed d-flex justify-content-center">
                <div
                  className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                  style={{
                    marginLeft: "-25px",
                    padding: "10px",
                    width: "60%",
                    height: "42px",
                    borderRadius: "100px",
                  }}
                >
                  <div className="d-flex text-muted" style={{ gap: "10px" }}>
                    Approve Contract Requisition?
                  </div>
                  <div className="d-flex" style={{ gap: "10px" }}>
                    {/* {
                      <img
                        src="/static_images/iconsettingfile.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setReworkOpen(true);
                        }}
                      />
                    } */}
                    <img
                      src="/static_images/crossicon.svg"
                      className="pl-1 w-10"
                      style={{ cursor: "pointer" }}
                      onClick={() => setRejectionOpen(true)}
                    />
                    <img
                      src="/static_images/selectrighticon.svg"
                      className="pl-1 w-10"
                      onClick={handleconfirmreq}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            onClick={downloadDoc}
            className="col-md-2 close-icn d-flex justify-content-end"
          >
            <img
              style={{ width: "20px" }}
              src="/static_images/file-download.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers className="m-0 pt-16 pb-16 pl-0 pr-0">
        {loading && <CircularProgress className="loader" />}
        <div className="row col-12 m-0 p-0 h-100">
          <div className="col-md-6 m-0 p-0">
            <div className="d-flex flex-column h-100" style={{ gap: "20px" }}>
              <div
                className="bg-light d-flex  justify-content-sm-between align-items-center pl-3 pr-3"
                style={{
                  marginLeft: "17px",
                  padding: "10px",
                  width: "95.60%",
                  height: "42px",
                  borderRadius: "100px",
                }}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/settingiconnew.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTheme(!isDark);
                      if (isDark) {
                        lightTheme();
                      } else {
                        darkTheme();
                      }
                    }}
                  />
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <img
                    src="/static_images/serchiconplus.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => ZoomIn()}
                  />
                  <img
                    src="/static_images/serchiconminus.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => Zoomout()}
                  />
                  <img
                    src="/static_images/serchiconnew.svg"
                    className="pl-1 w-10"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      search();
                    }}
                  />
                </div>
              </div>
              <div
                ref={documentContainer}
                id="document-viewer"
                className="col-md-12 pr-4"
                style={{ height: "100%" }}
              >
                {/* {props.fileLink && (<DocumentViewer
                              viewerUrl={`https://docs.google.com/gview?url=${props.fileLink}&embedded=true`}
                              url={props.fileLink}
                              viewer="url"
                              style={{ width: '100%', height: '100%' }}
                          />)} */}
              </div>
            </div>
          </div>
          <div className="col-md-6 m-0 p-0 tab-container">
            <Box sx={{ width: "100%" }}>
              <Box
                className="pr-3"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="draft-tab"
                  style={{ borderRadius: "100px" }}
                >
                  <Tab
                    label="Log"
                    {...a11yProps(0)}
                    style={{ width: "20px" }}
                  />
                  <Tab label="Approvals" {...a11yProps(1)} />
                  <Tab label="Support Document" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                {logloader ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "50%" }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="mt-5 custom-accordion">
                    {Object?.keys(requistionlog)?.map(
                      (i: any, index: number) => {
                        return (
                          <div key={index}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreOutlined />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography>{i}</Typography>
                              </AccordionSummary>
                              {requistionlog[i]?.map(
                                (item: any, index: number) => {
                                  return (
                                    <AccordionDetails
                                      style={{
                                        height: "110px",
                                      }}
                                      key={index}
                                    >
                                      <div>
                                        <div className="row col-12 m-0 p-0 bg-white position-absolute custom-div">
                                          <div className="col-1 p-0 m-0 accordion-icon-change  d-flex justify-content-center align-items-center">
                                            <img
                                              src="/static_images/logTabicon.svg"
                                              className="pl-1 w-10"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </div>
                                          <div className="col-11 p-0 m-0">
                                            <div
                                              className=" d-flex flex-column p-3"
                                              style={{ gap: "10px" }}
                                            >
                                              <div className="d-flex align-items-center">
                                                <div
                                                  className="d-flex flex-column justify-content-center align-items-center mr-2"
                                                  style={{
                                                    width: "28px",
                                                    height: "30px",
                                                    background: "#FFEFE4",
                                                    borderRadius: "100px",
                                                    border: "1px solid #88305F",
                                                  }}
                                                >
                                                  <span
                                                    className="fw-600"
                                                    style={{
                                                      fontSize: "12px",
                                                      color: "#88305F",
                                                    }}
                                                  >
                                                    {item.action_by_details?.first_name.slice(
                                                      0,
                                                      1
                                                    ) +
                                                      item.action_by_details?.last_name.slice(
                                                        0,
                                                        1
                                                      )}
                                                  </span>
                                                </div>
                                                <span>
                                                  <span
                                                    style={{ color: "#88305F" }}
                                                    className="mr-1"
                                                  >
                                                    {user.id !==
                                                    item?.action_by_details?.id
                                                      ? `${item?.action_by_details?.first_name} ${item?.action_by_details?.last_name}`
                                                      : "you"}
                                                  </span>
                                                  {item.action}
                                                </span>
                                              </div>
                                              {open && (
                                                <div
                                                  className="mt-3 text-container ml-1 mr-1"
                                                  style={{
                                                    backgroundColor: "#FFEFE4",
                                                  }}
                                                >
                                                  <p
                                                    className="p-2"
                                                    style={{
                                                      fontSize: "14px",
                                                      color: "#EE5800",
                                                    }}
                                                  >
                                                    ‘This is an Annotation text
                                                    that appears in line with
                                                    the document. The User has
                                                    the ability to accept or
                                                    reject or modify this
                                                    annotation via the dialogue
                                                    box on the side.’
                                                  </p>
                                                </div>
                                              )}
                                              <div
                                                className="d-flex justify-content-between"
                                                style={{ fontSize: "14px" }}
                                              >
                                                <span>
                                                  {moment(item?.time).format(
                                                    "h:mm a"
                                                  )}
                                                </span>
                                                {/* <span
                                              style={{
                                                color: "#88305F",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                // setOpen(!open)
                                              }}
                                            >
                                              View
                                            </span> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  );
                                }
                              )}
                            </Accordion>
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div
                  className="d-flex flex-column mt-5"
                  style={{ gap: "16px" }}
                >
                  <Card>
                    <CardContent style={{ borderRadius: "8px" }}>
                      <div>
                        {loader ? (
                          <div className="d-flex justify-content-center">
                            <CircularProgress />
                          </div>
                        ) : (
                          <div
                            className="d-flex flex-column"
                            style={{ gap: "16px" }}
                          >
                            <div className=" row col-12 p-0 m-0">
                              <div className="col-1 p-0 m-0">
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  style={{
                                    width: "28px",
                                    height: "30px",
                                    background: "#FFEFE4",
                                    borderRadius: "100px",
                                    border: "solid",
                                    borderColor: "#EE5800",
                                    borderWidth: "1px",
                                  }}
                                >
                                  <span
                                    className="fw-600"
                                    style={{
                                      fontFamily: "Inter",
                                      fontStyle: "normal",
                                      fontSize: "12px",
                                      lineHeight: "16px",
                                      color: "#EE5800",
                                    }}
                                  >
                                    {HeimdallUtil.getNameForAvatar(
                                      reqFormData?.owner
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-8 p-0 m-0">
                                <span
                                  className="fw-600"
                                  style={{ color: "#818181" }}
                                >
                                  <span style={{ color: "#88305f" }}>
                                    {reqFormData?.owner?.first_name} seeks
                                  </span>{" "}
                                  Approval
                                </span>
                              </div>
                              <div
                                className="col-3 d-flex justify-content-end p-0 m-0"
                                style={{ color: "#818181" }}
                              >
                                {new Date().toLocaleDateString()}
                              </div>
                            </div>
                            {/* next line */}
                            {reqFormData?.approvalTypes &&
                              !reqFormData?.approvalTypes[0]?.approvers
                                ?.length && (
                                <div
                                  className="ml-5"
                                  style={{
                                    color: "#818181",
                                    fontWeight: "700",
                                  }}
                                >
                                  none
                                </div>
                              )}
                            {reqFormData?.approvalTypes[0]?.approvers?.map(
                              (i: any, index: number) => {
                                return (
                                  <div key={index}>
                                    <div className=" row col-12 p-0 m-0 d-flex align-items-center">
                                      <div className="col-1 p-0 m-0">
                                        {/* {i.approval_status.toLowerCase() == "rejected" && (
                                          <img
                                            src="/static_images/crossicon.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        )} */}
                                        {/* {i.approval_status.toLowerCase() == "rework" && (
                                          <img
                                            src="/static_images/timeclockicon.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        )} */}
                                        {i.approval_status.toLowerCase() ==
                                          "approved" ||
                                        i.approval_status.toLowerCase() ==
                                          "requisition approved" ? (
                                          <img
                                            src="/static_images/selectrighticon.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : i.approval_status.toLowerCase() ===
                                            "pending" ||
                                          i.approval_status.toLowerCase() ===
                                            "requisition approval pending" ? (
                                          <img
                                            src="/static_images/waiting.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : i.approval_status.toLowerCase() ===
                                          "rejected" ? (
                                          <img
                                            src="/static_images/crossicon.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : i.approval_status.toLowerCase() ===
                                          "rework" ? (
                                          <img
                                            src="/static_images/waiting.svg"
                                            className="pl-1 w-10"
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : null}
                                      </div>
                                      <div className="col-8 p-0 m-0">
                                        <span
                                          className="fw-600 d-flex align-items-center"
                                          style={{
                                            color: "#818181",
                                            gap: "5px",
                                          }}
                                        >
                                          <div
                                            className="d-flex flex-column justify-content-center align-items-center"
                                            style={
                                              i.approval_status.toLowerCase() ==
                                                "approved" ||
                                              i.approval_status.toLowerCase() ==
                                                "requisition approved"
                                                ? {
                                                    width: "28px",
                                                    height: "30px",
                                                    background: "#D8F7E6",
                                                    borderRadius: "100px",
                                                    border: "solid",
                                                    borderColor: "#00692A",
                                                    borderWidth: "1px",
                                                  }
                                                : i.approval_status.toLowerCase() ==
                                                  "rejected"
                                                ? {
                                                    width: "28px",
                                                    height: "30px",
                                                    background: "#EEEDFF",
                                                    borderRadius: "100px",
                                                    border: "solid",
                                                    borderColor: "#392494",
                                                    borderWidth: "1px",
                                                  }
                                                : {
                                                    width: "28px",
                                                    height: "30px",
                                                    background: "#FFEFE4",
                                                    borderRadius: "100px",
                                                    border: "solid",
                                                    borderColor: "#EE5800",
                                                    borderWidth: "1px",
                                                  }
                                            }
                                          >
                                            <span
                                              className="fw-600"
                                              style={
                                                i.approval_status.toLowerCase() ==
                                                  "approved" ||
                                                i.approval_status.toLowerCase() ==
                                                  "requisition approved"
                                                  ? {
                                                      fontFamily: "Inter",
                                                      fontStyle: "normal",
                                                      fontSize: "12px",
                                                      lineHeight: "16px",
                                                      color: "#00692A",
                                                    }
                                                  : i.approval_status.toLowerCase() ==
                                                    "rejected"
                                                  ? {
                                                      fontFamily: "Inter",
                                                      fontStyle: "normal",
                                                      fontSize: "12px",
                                                      lineHeight: "16px",
                                                      color: "#392494",
                                                    }
                                                  : {
                                                      fontFamily: "Inter",
                                                      fontStyle: "normal",
                                                      fontSize: "12px",
                                                      lineHeight: "16px",
                                                      color: "#EE5800",
                                                    }
                                              }
                                            >
                                              {HeimdallUtil?.getNameForAvatar(
                                                i
                                              )}
                                            </span>
                                          </div>
                                          <span
                                            style={
                                              i.approval_status.toLowerCase() ==
                                                "approved" ||
                                              i.approval_status.toLowerCase() ==
                                                "requisition approved"
                                                ? { color: "#00692A" }
                                                : i.approval_status ==
                                                  "rejected"
                                                ? { color: "#392494" }
                                                : { color: "#EE5800" }
                                            }
                                          >
                                            {i.first_name}
                                          </span>
                                          {i.comment != "" &&
                                            i.comment &&
                                            "With Comment"}
                                        </span>
                                      </div>
                                      <div
                                        className="col-3 d-flex justify-content-end p-0 m-0"
                                        style={{ color: "#818181" }}
                                      >
                                        on&nbsp;{i.approved_date}
                                      </div>
                                    </div>
                                    {i.comment != "" && i.comment && (
                                      <div className=" row col-12 p-0 m-0">
                                        <div className="col-1 p-0 m-0"></div>
                                        <div className="col-11 p-0 m-0">
                                          <div
                                            className="mt-3 text-container ml-1 mr-1"
                                            style={{
                                              backgroundColor: "#E9FBFF",
                                            }}
                                          >
                                            <p
                                              className="p-2"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {i.comment}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <SupportDocTab supportdoc={props.reqres?.id} />
              </TabPanel>
            </Box>
          </div>
        </div>
        {reworkopen && (
          <ReworkPopup
            reworkopen={reworkopen}
            user={user}
            reqres={props.reqres}
            setopenSnackbar={() => {
              setopenSnackbar({ open: true, msg: "rework required" });
            }}
            close={() => setReworkOpen(false)}
          />
        )}
        {rejectionOpen && (
          <RejectionPopup
            reqres={props.reqres}
            user={user}
            rejectionOpen={rejectionOpen}
            setopenSnackbar={() => {
              setopenSnackbar({
                open: true,
                msg: "Requisition Reject sucessfully",
              });
            }}
            close={() => setRejectionOpen(false)}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ForApproval;
