import React, { useContext, useEffect, useState } from "react";
import {
  ContractTypeRes,
  defaultContractTypeRes,
} from "../State/ContractTypeState";
import AddContractDialog from "./AddContractDialog";
import DeleteContractTypeDialog from "./DeleteContractTypeDialog";

interface Props {
  contractTypes: ContractTypeRes[];
  getContractType: () => void;
}
const ListViewTable = (props: Props) => {
  const [contractTypesArr, setcontractTypesArr] = useState<ContractTypeRes[]>(
    []
  );
  const [contractData, setContractData] = useState<ContractTypeRes>(
    defaultContractTypeRes
  );
  const [openEditDialog, setopenEditDialog] = useState(false);
  const [openDeleteDialog, setopenDeleteDialog] = useState(false);
  useEffect(() => {
    if (props.contractTypes.length > 0) {
      setcontractTypesArr([...props.contractTypes]);
    } else {
      setcontractTypesArr([]);
    }
  }, [props.contractTypes]);

  const editContractType = (contractData: ContractTypeRes) => {
    setContractData({ ...contractData });
    setopenEditDialog(true);
  };
  const handleEditDialogClose = () => {
    setopenEditDialog(false);
  };

  const handleOpenDeleteDialog = (contractData: ContractTypeRes) => {
    setContractData({ ...contractData });
    setopenDeleteDialog(true);
  };
  const handleDeleteDialogClose = () => {
    setopenDeleteDialog(false);
  };
  return (
    <>
      {contractTypesArr.map((contractType, index) => (
        <div
          key={index}
          className="template-list-view col-md-12 mb-2"
          style={{
            height: "45px",
            backgroundColor: index % 2 != 0 ? "white" : "rgba(0,0,0,.04)",
          }}
        >
          <div className="row template-list-view-table">
            <div className="col-md-3">
              <div className="text-purple">{contractType.name}</div>
            </div>
            <div className="col-md-3">
              <div className="text-overflow">{contractType.displayName}</div>
            </div>
            <div className="col-md-3">
              <div>{contractType.comments}</div>
            </div>
            <div className="col-md-3">
              <div className="row text-purple">
                <div
                  onClick={() => editContractType(contractType)}
                  className="col-md-3"
                >
                  <img src="/static_images/edit_icn.svg"></img>
                </div>
                <div
                  onClick={() => handleOpenDeleteDialog(contractType)}
                  className="col-md-9"
                >
                  <img height={19} src="/static_images/delete-icon.svg"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <AddContractDialog
        isClone={false}
        getContractType={props.getContractType}
        isEdit={true}
        contractType={contractData}
        open={openEditDialog}
        onClose={handleEditDialogClose}
      />
      <DeleteContractTypeDialog
        id={contractData.id}
        onClose={handleDeleteDialogClose}
        name={contractData.name}
        getContractType={props.getContractType}
        open={openDeleteDialog}
      />
    </>
  );
};

export default ListViewTable;
