import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import HeimdallUtil from "../../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import AssignedToDialog from "../AssignedToDialog";
import axios from "axios";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import { sendemailtoapproval, updateContractMetaData } from "../../DA";
import AddNewPersonDialog from "../AddNewPersonDialog";
import { draftStatus } from "../../State/DraftState";

interface Props {
  selectedDraft: any;
  openAssignedTo: any;
  setSpecificDraft: any;
  onClose: () => void;
}

export default function NotifyCommit(props: Props) {
  const [selectedValue, setSelectedValue] = useState<any>("pre_signatories");
  const [isEdit, setEdit] = useState<boolean>(false);
  const [onreload, setonreload] = useState<any>(false);
  const [allspreadedassignee, setallspreadedassignee] = useState<any>([]);
  const [openAddAssigneeDialog, setopenAddAssigneeDialog] = useState(false);

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const updateRequisition = (id: any, payload: any, type: any) => {
    if (type === "STC") {
      const URL = process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/${id}/`;
      axios.patch(URL, payload, APIConfig()).then((value: any) => {
        props.setSpecificDraft(value.data);
        setonreload(true);
        setEdit(false);
      });
    } else {
      const URL =
        process.env.REACT_APP_RIVERUS_CLM_API +
        `stylus/requisition_form/${id}/`;
      axios.patch(URL, payload, APIConfig()).then((value: any) => {
        props.setSpecificDraft(value.data);
        setonreload(true);
        setEdit(false);
      });
    }
  };

  const onClose = () => {
    setEdit(false);
  };

  useEffect(() => {}, [selectedValue]);

  const handleChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const sendEmail = () => {
    const user_data = getLocalStorage("user_profile");
    if (selectedValue === "pre_signatories") {
      const preSignatories_list =
        props?.selectedDraft?.pre_signatories &&
        props?.selectedDraft?.pre_signatories?.length &&
        props?.selectedDraft?.pre_signatories;
      preSignatories_list.map((item: any) => {
        let payload = {
          user_ids: [item.id],
          body_html: `<h2>Hello ${item.first_name},<br/></h2><p>You have been invited to review this draft <Provide draft link> and approve for further processing for Signature.Kindly review and approve the same.</p><br/><h2> Best Regards,${user_data.first_name}</h2>`,
          body_text: `${user_data.first_name} raised requisition approval for ${props.selectedDraft.contractName}. Kindly review and approve the same.`,
          subject: `Pre-Signatories approval raised for ${props.selectedDraft.contractName}`,
        };
        try {
          sendemailtoapproval(payload);
          updateStatus();
        } catch (err) {
          console.log(err, "err");
        }
      });
    } else {
      const signatories_list =
        props?.selectedDraft?.signatories &&
        props?.selectedDraft?.signatories?.length &&
        props?.selectedDraft?.signatories;
      let created_by = props?.selectedDraft.created_by.first_name + " " + props?.selectedDraft.created_by.last_name;
      let orgName = localStorage.getItem('kcRealm') ? localStorage.getItem('kcRealm') : '';
      let groups = props?.selectedDraft.groups ? props?.selectedDraft.groups : [];
      let projects = props?.selectedDraft.projectNames ? props?.selectedDraft.projectNames : [];
      let allGroups = groups.concat(projects).join(',');
      let link =
      process.env.REACT_APP_RIVERUS_CLM_APP_HOST +
      `draftingreview/${props?.selectedDraft?.id}`;
      let parties = props?.selectedDraft.signatories.map((user:any) => user.first_name+" "+user.last_name).join(', ');
      signatories_list.map((item: any) => {
        let payload = {
          user_ids: [item.id],
          body_html: `Hello ${item.first_name} ${item.last_name}<br/><p>You have been requested by ${created_by} from ${orgName} to sign the document:</p><br/><table style='border-collapse: collapse;width:100%'><thead><tr><th style='border:1px solid black;color:#4472C4;text-align:left;'>Name of the document</th><th style='border:1px solid black;color:#4472C4;text-align:left;'>Project name/Group name</th><th style='border:1px solid black;color:#4472C4;text-align:left;'>Name of the parties</th></tr></thead><tr><td style='border:1px solid black;padding:5px'>${props?.selectedDraft?.contractName}</td><td style='border:1px solid black;padding:5px'>${allGroups}</td><td style='border:1px solid black;padding:5px'>${parties}</td></tr></table><br/><h4><a href='${link}'>Click here to be redirected to signature page</a></h4><p>Thank You</p>`,
          body_text: ``,
          subject: `Signature Request: ${props?.selectedDraft?.contractName}`,
        };
        try {
          sendemailtoapproval(payload);
          updateStatus();
        } catch (err) {
          console.log(err, "err");
        }
      });
    }
  };

  const updateStatus = async () => {
    try {
      let payload = {};
      if (selectedValue === "pre_signatories") {
        payload = {
          status: "Pre Signature Approval Pending",
        };
      } else {
        payload = {
          status: "Signature Pending",
        };
      }
      const response = await updateContractMetaData(
        payload,
        props.selectedDraft.id
      );
      if (response) {
        props.onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="notify-card mt-4">
      <div className="d-flex align-items-center">
        <p className="mb-0">
          You want to change the status of this document from{" "}
          <span className="from-status">{props.selectedDraft.status}</span>
          &nbsp;&nbsp;to&nbsp;&nbsp;
        </p>
        <FormControl
          size="small"
          variant="outlined"
          className="riverus-text-box"
          fullWidth={true}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
            fullWidth={true}
          >
            <MenuItem
              style={{ whiteSpace: "initial" }}
              value={"pre_signatories"}
            >
              Pre Signatories
            </MenuItem>
            <MenuItem style={{ whiteSpace: "initial" }} value={"signatories"}>
              Signatories
            </MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="d-flex justify-content-between mt-3">
        <p>This will notify</p>
        <p
          className="cursor-pointer"
          style={{
            color: "#88305F",
          }}
          onClick={() => {
            if (props.selectedDraft?.approvalTypes?.length) {
              setEdit(true);
            }
          }}
        >
          Edit
        </p>
      </div>
      {selectedValue === "pre_signatories" ? (
        props.selectedDraft?.pre_signatories &&
        props.selectedDraft?.pre_signatories?.length ? (
          props.selectedDraft?.pre_signatories?.map(
            (item: any, index: number) => {
              return (
                <div
                  className="d-flex align-items-center users-view mt-2"
                  key={index}
                >
                  {item.approval_status ===
                  draftStatus.PRESIGNATORIES_APRROVED ? (
                    <img
                      src="/static_images/selectrighticon.svg"
                      className="pl-1 w-10"
                    />
                  ) : item.approval_status ===
                    draftStatus.PRESIGNATORIES_REJECTED ? (
                    <img
                      src="/static_images/crossicon.svg"
                      className="pl-1 w-10"
                    />
                  ) : (
                    <img
                      src="/static_images/pending.svg"
                      className="pl-1 w-10"
                    />
                  )}
                  <Avatar
                    key={1}
                    style={{
                      border: `1px solid #00692A`,
                      fontSize: "14px",
                      padding: "5px",
                      marginLeft: "10px",
                      color: `#00692A`,
                      backgroundColor: `#D8F7E6`,
                    }}
                  >
                    <span>{HeimdallUtil?.getNameForAvatar(item)}</span>
                  </Avatar>
                  <p className="mb-0 ml-2 cursor-pointer">
                    {item.first_name + " " + item.last_name}
                  </p>
                </div>
              );
            }
          )
        ) : (
          <p
            style={{
              color: "#88305F",
            }}
          >
            None of Pre-signatories available
          </p>
        )
      ) : (
        props.selectedDraft?.signatories &&
        props.selectedDraft?.signatories?.length &&
        props.selectedDraft?.signatories?.map((item: any, index: number) => {
          return (
            <div
              className="d-flex align-items-center users-view mt-2"
              key={index}
            >
              {item.approval_status === draftStatus.PRESIGNATORIES_APRROVED ? (
                <img
                  src="/static_images/selectrighticon.svg"
                  className="pl-1 w-10"
                />
              ) : item.approval_status ===
                draftStatus.PRESIGNATORIES_REJECTED ? (
                <img src="/static_images/crossicon.svg" className="pl-1 w-10" />
              ) : (
                <img src="/static_images/pending.svg" className="pl-1 w-10" />
              )}
              <Avatar
                key={1}
                style={{
                  border: `1px solid #00692A`,
                  fontSize: "14px",
                  marginLeft: "10px",
                  padding: "5px",
                  color: `#00692A`,
                  backgroundColor: `#D8F7E6`,
                }}
              >
                <span>{HeimdallUtil?.getNameForAvatar(item)}</span>
              </Avatar>
              <p className="mb-0 ml-2 cursor-pointer">
                {item.first_name + " " + item.last_name}
              </p>
            </div>
          );
        })
      )}
      <div className="mt-3 ">
        <button
          type="button"
          className="btn btn-warning create-btn"
          onClick={sendEmail}
        >
          {`Move Document to ${
            selectedValue === "pre_signatories"
              ? "Pre Signatories"
              : "Signatories"
          }`}
        </button>
      </div>

      {isEdit && (
        <AssignedToDialog
          open={isEdit}
          onClose={onClose}
          onPatch={updateRequisition}
          setopenAddAssigneeDialog={setopenAddAssigneeDialog}
          specificDraft={props.selectedDraft}
          setallspreaded={setallspreadedassignee}
          allspreaded={allspreadedassignee}
          onreload={onreload}
          setonreload={setonreload}
          showEdit={false}
        />
      )}
      {openAddAssigneeDialog && (
        <AddNewPersonDialog
          open={openAddAssigneeDialog}
          onClose={() => {
            setopenAddAssigneeDialog(false);
            setonreload(true);
          }}
          specificDraft={props.selectedDraft}
          onPatch={updateRequisition}
        />
      )}
    </div>
  );
}
