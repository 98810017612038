import { connect } from "react-redux";
import DocumentLibraryGenerator from "../../../DocumentLibrary/Actions/gen";
import { StoreTree } from "../../../Utils/MainReducer/mainReducer";
import AddHierarchyModal from "./addHierarchyModal";
export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    initialFileIds: appState.library.initialFileIds,
    savedMultipleSelectedFiles: appState.library.savedMultipleSelectedFiles,
    savedParentFileList: appState.library.savedParentFileList,
    savedChildrenFileList: appState.library.savedChildrenFileList,
  };
}
export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getParentFileList: (
      selectedFileId: string,
      fileIds: string[],
      bulkFileIds: string[]
    ) =>
      dispatch(
        DocumentLibraryGenerator.getParentFileList(
          selectedFileId,
          fileIds,
          bulkFileIds
        )
      ),
    addParent: (childFileIds: string[], editedParentFileId: string) =>
      dispatch(
        DocumentLibraryGenerator.addParent(childFileIds, editedParentFileId)
      ),
    getChildrenFileList: (selectedFileId: string, fileIds: string[]) =>
      dispatch(
        DocumentLibraryGenerator.getChildrenFileList(selectedFileId, fileIds)
      ),
    removeParent: (childFileIds: string[], editedParentFileId: string) =>
      dispatch(
        DocumentLibraryGenerator.removeParent(childFileIds, editedParentFileId)
      ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddHierarchyModal);
