import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index';
import { saveAs } from 'file-saver';

const InspectModule = require("docxtemplater/js/inspect-module");
function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}
export const expressionParser = require("docxtemplater/expressions.js");
export const generateDocument = (json, filePath, fileName) => {

    loadFile(filePath, function (
        error,
        content
    ) {
        if (error) {
            throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            parser:expressionParser
        });
        doc.setData(json);
        try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
        } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
                if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                        error,
                        key
                    ) {
                        error[key] = value[key];
                        return error;
                    },
                        {});
                }
                return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors
                    .map(function (error) {
                        return error.properties.explanation;
                    })
                    .join('\n');
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }
        var out = doc.getZip().generate({
            type: 'blob',
            mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }); //Output the document using Data-URI
        console.log(out);
        saveAs(out, fileName + '.docx');
    });
};

export const getBlobDocument = (json, filePath, callback) => {
    loadFile(filePath, function (
        error,
        content
    ) {
        if (error) {
            throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            parser:expressionParser
        });
        doc.setData(json);
        try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
        } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
                if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                        error,
                        key
                    ) {
                        error[key] = value[key];
                        return error;
                    },
                        {});
                }
                return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors
                    .map(function (error) {
                        return error.properties.explanation;
                    })
                    .join('\n');
                console.error('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }
        var out = doc.getZip().generate({
            type: 'blob',
            mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        }); //Output the document using Data-URI
        callback(out);
    });
}

export const generateTags = (filePath, callback) => {
    const iModule = InspectModule();
    loadFile(filePath, (error, content) => {
        if (error) {
            throw error;
        }
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
            modules: [iModule],
            parser:expressionParser
        });
        const tags = iModule.getAllTags();
        callback(tags);
    });
}

export const generateTagsFromUpload = (file, callback) => new Promise((resolve, reject) => {
    try {
        const iModule = InspectModule();
        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            var zip = new PizZip(reader.result);
            var doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
                modules: [iModule]
            });
            const tags = iModule.getAllTags();
            return resolve(tags);
        }
    } catch (error) {
        reject(error);
    }
});