import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "../Design/template.scss";
import { generateDocument, generateTags, getBlobDocument } from "./DocGenerate";
import {
  DataDictionary,
  DATA_DICTIONARY,
  Field_INFO,
} from "../../Constants/DataDictionary";
import { History } from "history";
import { TemplateData } from "../State/templateState";
import {
  ContractData,
  File,
  initialContractData,
} from "../../Draft/State/DraftState";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import { Blank_Document_link } from "../../Constants/const";
import axios from "axios";
import {
  fetchDataDictionary,
  getContractType,
  getSpecificTemplate,
} from "../DA";

import { getdraftbyid } from "../../Draft/DA/index";
interface Props {
  open: boolean;
  onClose: (docViewerData: any) => void;
  templateLink: any;
  fileName: string;
  type: string;
  history: History;
  template?: TemplateData | any;
  dynamicFields: any[] | null;
  fromTemplate: boolean;
  contractData: any | null;
  uploadFile: (post: any, fileType: any, callback: Function) => void;
  uploadContractData: (post: any) => Promise<any> | undefined;
  collaborators?: any[] | undefined;
  updateContractData?: any | null;
  fieldNameList: any;
}
const DocViewerDialog = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState({});
  const [dynamicFields, setDynamicFields] = useState<any[]>([]);
  const [error, setError] = useState<any>(null);
  const [fileLink, setFileLink] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const documentContainer = useRef<HTMLDivElement>(null);
  const [dataDictionary, setdataDictionary] = useState<any[]>([]);
  const [webviewerInstance, setwebviewerInstance] =
    useState<WebViewerInstance | null>(null);
  const [templateKeyValue, settemplateKeyValue] = useState<any>(null);
  const [showTemplateField, setshowTemplateField] = useState(false);
  const [isWarning, setWarning] = useState<any>([]);
  const [warningField, setWarningField] = useState<any>([]);
  // const [contractFieldList, setContractFieldList] = useState<any>([]);

  useEffect(() => {
    fetchDD();
    if (props.fromTemplate) {
      getTemplates(props.template.id);
    } else {
      getDraft(props.contractData.id);
    }
  }, []);

  useEffect(() => {
    showTemplateFieldValues();
  }, [showTemplateField]);

  useEffect(() => {
    setWebViewer();
    return () => {
      setwebviewerInstance(null);
    };
  }, [fileLink, documentContainer.current]);

  // useEffect(() => {}, [contractFieldList, contractFieldList.length]);
  useEffect(() => {
    try {
      // getTagsFromDoc();
      // setFileLink(props.templateLink);
    } catch (error) {
      console.log(error);
      setError(error);
    }
    return () => {
      setFileLink("");
      setDynamicFields([]);
    };
  }, [props.templateLink, props.fileName]);

  useEffect(() => {
    if (props.dynamicFields !== null && props.dynamicFields.length > 0) {
      setDynamicFields(props.dynamicFields);
    }
  }, [props.dynamicFields]);

  useEffect(() => {
    if (dynamicFields && dynamicFields.length) {
      let flatArr = dynamicFields?.flatMap(
        (section: any) => section.field_data
      );
      let keyValuePair: any = flatArr?.reduce((acc: any, curr: any) => {
        let tag = "{" + curr.field_name + "}";
        return (acc = { ...acc, [curr.display_name]: tag });
      }, {});
      settemplateKeyValue(keyValuePair);
    }
  }, [dynamicFields]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (
      (props.dynamicFields == null || props.dynamicFields.length == 0) &&
      dataDictionary.length > 0
    ) {
      constructDocFields();
    }
  }, [tags, dataDictionary]);

  const getTemplates = async (id: string) => {
    try {
      setLoading(true);
      const response = await getSpecificTemplate(id);
      if (response) {
        setFileLink(response.access_url);
        getTagsFromDoc(response.access_url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDraft = async (id: string) => {
    try {
      setLoading(true);
      const response = await getdraftbyid(id);

      if (response) {
        setFileLink(response.access_url);
        getTagsFromDoc(response.access_url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const setWebViewer = async () => {
    if (
      documentContainer.current != null &&
      fileLink != "" &&
      !webviewerInstance
    ) {
      const instance = await WebViewer(
        {
          path: "/webviewer",
          disabledElements: ["header", "toolsHeader"],
        },
        documentContainer.current
      );
      setwebviewerInstance(instance);
      const { UI, Core } = instance;
      const { documentViewer, annotationManager, Tools, Annotations } = Core;
      // call methods from UI, Core, documentViewer and annotationManager as needed
      documentViewer.addEventListener("documentLoaded", () => {
        // call methods relating to the loaded document
      });
      instance.UI.loadDocument(fileLink);
    }
  };

  const fetchDD = async () => {
    try {
      setLoading(true);

      if (props.fromTemplate) {
        const response = await getContractType(
          props.template?.templateType || props.contractData?.contractType || ""
        );
        if (response && response.results.length > 0) {
          const response_2 = await fetchDataDictionary(
            response.results[0]["id"]
          );
          if (response_2 && response_2.data) {
            setdataDictionary([...response_2.data]);
          }
        }
      } else {
        const response = await getContractType(
          props.template?.templateType.name ||
            props.contractData?.contractType ||
            ""
        );
        if (response && response.results.length > 0) {
          const response_2 = await fetchDataDictionary(
            response.results[0]["id"]
          );
          if (response_2 && response_2.data) {
            setdataDictionary([...response_2.data]);
          }
        }
      }
    } catch (error) {
      console.error("docviewerdialog_fetchDD", error);
    } finally {
      setLoading(false);
    }
  };

  const constructDocFields = () => {
    try {
      // setLoading(true);
      if (Object.keys(tags).length > 0) {
        let tagList = Object.keys(tags);
        let fields: any[] = [];
        tagList.forEach((tag) => {
          let sectionObj: any = {};
          for (let index = 0; index < dataDictionary.length; index++) {
            const section = JSON.parse(JSON.stringify(dataDictionary[index]));
            let filteredTags: Field_INFO[] = [];
            filteredTags = section.field_data.filter((field: any) =>
              tag.includes(field.field_name.replaceAll(/{|}/g, ""))
            );
            if (filteredTags.length > 1) {
              filteredTags = filteredTags.filter((field: any) =>
                field.field_name.replaceAll(/{|}/g, "").includes(tag)
              );
            }
            if (filteredTags.length > 0) {
              sectionObj = JSON.parse(JSON.stringify(section));
              if (fields.length > 0) {
                let fieldIndex = fields.findIndex(
                  (value) => value.section_name == sectionObj.section_name
                );
                if (fieldIndex > -1) {
                  const field = JSON.parse(JSON.stringify(filteredTags[0]));
                  field.display_name = getFieldName(tag, field.display_name);
                  field.fieldValue = field.data_type.toLowerCase() == 'boolean' ? false : "";
                  field.fieldLength = "";
                  field.field_name = tag;
                  field.validation = {};
                  fields[fieldIndex].field_data = [
                    ...fields[fieldIndex].field_data,
                    field,
                  ];
                  return;
                } else {
                  sectionObj.field_data = [];
                  const field = JSON.parse(JSON.stringify(filteredTags[0]));
                  field.display_name = getFieldName(tag, field.display_name);
                  field.fieldValue = field.data_type.toLowerCase() == 'boolean' ? false : "";
                  field.fieldLength = "";
                  field.validation = {};
                  field.field_name = tag;
                  sectionObj.field_data = [field];
                  fields.push(sectionObj);
                  return;
                }
              } else {
                sectionObj.field_data = [];
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.fieldValue = field.data_type.toLowerCase() == 'boolean' ? false : "";;
                field.fieldLength = "";
                field.validation = {};
                field.field_name = tag;
                sectionObj.field_data = [field];
                fields.push(sectionObj);
                return;
              }
            }
          }
        });

        setDynamicFields(fields);
        // setLoading(false);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
    }
  };

  const showTemplateFieldValues = () => {
    if (!webviewerInstance) {
      return;
    }
    const { Core } = webviewerInstance;
    const { documentViewer, Annotations, annotationManager, Tools } = Core;
    if (!showTemplateField) {
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
    } else {
      if (templateKeyValue) {
        let map: any = {};
        for (const key in templateKeyValue) {
          let value = templateKeyValue[key];
          map = { ...map, [value]: key };
        }
        let searchData: string = Object.values<string>(templateKeyValue)
          .filter((value) => value)
          .reduce((acc, curr: any, index) => {
            if (index == 0) {
              return (acc = curr);
            } else return acc + "|" + curr;
          }, "");
        const searchMode =
          webviewerInstance.Core.Search.Mode.REGEX |
          webviewerInstance.Core.Search.Mode.HIGHLIGHT |
          webviewerInstance.Core.Search.Mode.PAGE_STOP;
        const searchOptions = {
          fullSearch: true,
          onResult: (result: any) => {
            if (
              result.resultCode ===
              webviewerInstance.Core.Search.ResultCode.FOUND
            ) {
              const highlight = new Annotations.TextHighlightAnnotation();
              result.quads.map((quad: { getPoints: () => any }) => {
                highlight.Quads = [quad.getPoints()] as any[];
              });
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              highlight.setContents(
                map[result["resultStr"]] || map[result["result_str"]]
              );
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
            }
          },
          onDocumentEnd: (result: any) => {},
        };
        documentViewer.textSearchInit(searchData, searchMode, searchOptions);
      }
    }
  };

  const getFieldName = (mergeTag: string, fieldName: string): string => {
    let name = fieldName;
    try {
      if (
        mergeTag.charCodeAt(mergeTag.length - 1) >= 49 &&
        mergeTag.charCodeAt(mergeTag.length - 1) <= 57
      ) {
        name = name + " " + mergeTag[mergeTag.length - 1];
      }
      return name;
    } catch (error) {
    } finally {
      return name;
    }
  };

  // useEffect(() => {
  //   if (props?.fieldNameList && props?.fieldNameList?.length) {
  //     const dataLength = props.fieldNameList.filter((item: any) => {
  //       return (
  //         item?.section_dictionary?.contract_type?.name ===
  //         props?.template.templateType.name
  //       );
  //     });
  //     const fieldName = dataLength.map((item: any) => item?.field_name);

  //     setContractFieldList(fieldName);
  //   }
  // }, []);
  useEffect(() => {}, [warningField]);
  
  const parseTagList = (tags:any) => {
    let parsedTags = Object.keys(tags).reduce((acc,curr) => {
      let innerObject = {};
      if(Object.keys(tags[curr]).length > 0){
        innerObject = {...innerObject,...tags[curr]}
      }
      return {...acc,[curr.split(" ")[0]]:{},...innerObject};
    },{})
    return parsedTags;
  }

  const getTagsFromDoc = (link: any) => {
    let contractFieldList: any = [];
    if (props?.fieldNameList && props?.fieldNameList?.length) {
      const dataLength = props.fieldNameList.filter((item: any) => {
        let templateType = props.fromTemplate ? props?.template.templateType.toLowerCase() : props?.template.templateType.name.toLowerCase();
        return (
          item?.section_dictionary?.contract_type?.name.toLowerCase() ===
          templateType
        );
      });
      const fieldName = dataLength.map((item: any) => item?.field_name);
      contractFieldList = [...fieldName];
    }
    generateTags(link, (tags: any) => {
      let parseTags:any = parseTagList(tags);
      if (contractFieldList.length > 0) {
        if (Object.keys(parseTags).length !== contractFieldList.length) {
          setWarning(true);
          const remainingDD = Object.keys(parseTags).filter(
            (item: any, index: number) => {
              if (!contractFieldList.includes(`{${item}}`)) {
                return item;
              }
            }
          );

          if (remainingDD.length > 0) {
            setWarningField(remainingDD);
          } else {
            setWarningField([]);
          }
        }
      } else {
        setWarningField(Object.keys(parseTags));
      }
      setTags(parseTags);
    });
  };
  const handleClose = () => {
    setOpen(false);
    props.onClose(null);
  };

  const uploadBlankDoc = () => {
    if (!props.fromTemplate) {
      setLoading(true);
      let keyValuePair = {
        nameofthedraft: props.contractData!.contractName,
        contracttypefield: props.contractData!.contractType,
        createcontractfromfield: props.contractData!.createFrom,
        projects: props.contractData!.projectNames,
        notesfield: props.contractData!.notes,
        counterParty: props.contractData!.counterParties.map((value: any) => {
          return { name: value.orgContactName, email: value.email };
        }),
        deadlinefield: props.contractData!.deadline,
        selecttemplatefield: props.fileName,
        signatories: props.contractData!.signatories.map(
          (value: any) => value.first_name + " " + value.last_name
        ),
        collaborators:
          props.collaborators && props.collaborators.length
            ? props.collaborators.map(
                (value) => value.first_name + " " + value.last_name
              )
            : [],
        hasAll: props.contractData!.approvalTypes.length
          ? props.contractData!.approvalTypes[0].approval_sequence == "All"
          : false,
        hasAny: props.contractData!.approvalTypes.length
          ? props.contractData!.approvalTypes[0].approval_sequence == "Any"
          : false,
        hasSequence: props.contractData!.approvalTypes.length
          ? props.contractData!.approvalTypes[0].approval_sequence ==
            "In Sequence"
          : false,
        approvers: props.contractData!.approvalTypes.length
          ? props.contractData!.approvalTypes[0].approvers.map(
              (value: any) => value.first_name + " " + value.last_name
            )
          : [],
        groups: props.contractData?.groups?.length
          ? props.contractData?.groups?.map((value: any) => value?.name)
          : [],
      };
      getBlobDocument(
        keyValuePair,
        Blank_Document_link.TEMPLATE_BLANK_DOC,
        (response: any) => {
          const file = response;
          file["name"] = props.contractData!.contractName + ".docx";
          let reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
              setLoading(true);
              props.uploadFile([file], false, (response: any, error: any) => {
                if (response) {
                  setOpen(false);
                  props.onClose({
                    response: response,
                    contract: props.contractData,
                    action: "cancel",
                  });
                  setLoading(false);
                }
                if (error) {
                  console.error(error);
                  setLoading(false);
                }
              });
            };
          }
        }
      );
    } else handleClose();
  };

  const handleSubmit = () => {
    if (checkValidation()) {
      return;
    }

    if (props.fromTemplate) {
      props.history.push("/draftingreview", {
        updatedFields: dynamicFields,
        fromTemplate: true,
        template: props.template,
      });
    } else {
      // upload contract meta data to database
      // upload generated document into s3 using presigned url

      let flatArr = dynamicFields.flatMap((section) => section.field_data);
      let keyValuePair: any = flatArr.reduce((acc, curr) => {
        let key = curr.field_name.replaceAll(/{|}/g, "");
        if (curr.data_type.toLowerCase() == "duration") {
          acc = { ...acc, [key]: curr.fieldValue + " " + curr.fieldLength };
        } else acc = { ...acc, [key]: curr.fieldValue };
        return acc;
      }, {});

      for (let key in keyValuePair) {
        if (keyValuePair[key] == "") {
          keyValuePair[key] = "{" + key + "}";
        }
      }
      let files_: File[] = [];
      if (props.fromTemplate) {
        // getTemplates(props.template.id);
        getSpecificTemplate(props.template.id).then((data) => {
          if (data) {
            getBlobDocument(keyValuePair, data.access_url, (response: any) => {
              const file = response;
              file["name"] = props.contractData!.contractName + ".docx";
              let reader = new FileReader();
              if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                  files_.push({
                    name: props.fileName,
                    size: file.size,
                    type: file.type,
                    template_type: "",
                    file_type: "Contract_Draft",
                  });
                  uploadDoc([file]);
                };
              }
            });
          }
        });
      } else {
        // getDraft(props.contractData.id);
        getdraftbyid(props.contractData.id).then((data) => {
          if (data) {
            getBlobDocument(keyValuePair, data.access_url, (response: any) => {
              const file = response;
              file["name"] = props.contractData!.contractName + ".docx";
              let reader = new FileReader();
              if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                  files_.push({
                    name: props.fileName,
                    size: file.size,
                    type: file.type,
                    template_type: "",
                    file_type: "Contract_Draft",
                  });
                  uploadDoc([file]);
                };
              }
            });
          }
        });
      }
    }

    // DOWNLOAD PDF

    // let flatArr = dynamicFields.flatMap(section => section.field_data);
    // let keyValuePair: any = flatArr.reduce((acc, curr) => {
    //     let key = curr.field_name.replaceAll(/{|}/g,'');
    //     if (curr.data_type.toLowerCase() == 'duration') {
    //         acc = { ...acc, [key]: curr.fieldValue + ' ' + curr.fieldLength }
    //     } else acc = { ...acc, [key]: curr.fieldValue }
    //     return acc;
    // }, {});
    // console.log(keyValuePair);
    // for (let key in keyValuePair) {
    //     if (keyValuePair[key] == '') {
    //         keyValuePair[key] = '{' + key + '}';
    //     }
    // }
    // console.log(keyValuePair);
    // generateDocument(keyValuePair, props.templateLink, props.fileName.split(".")[0]);
  };

  const handleSaveDraft = async () => {
    let contract: any = { ...props.contractData };
    contract.optional_field = dynamicFields;
    if (props.fromTemplate) {
      getSpecificTemplate(props.template.id).then(async (data) => {
        if (data) {
          let blob = await axios
            .get(data.access_url, { responseType: "blob" })
            .then((res) => res.data);
          blob["name"] = props.contractData!.contractName + ".docx";
          console.log(blob);
          setLoading(true);
          props.uploadFile([blob], false, (response: any, error: any) => {
            if (response) {
              if (response && response.file_path) {
                contract.link = response.file_path;
              }
              setOpen(false);
              props.onClose({ contract: contract, action: "save" });
              setLoading(false);
            }
            if (error) {
              console.error(error);
              setLoading(false);
            }
          });
        }
      });
    } else {
      getdraftbyid(props.contractData.id).then(async (data) => {
        if (data) {
          let blob = await axios
            .get(data.access_url, { responseType: "blob" })
            .then((res) => res.data);
          blob["name"] = props.contractData!.contractName + ".docx";
          console.log(blob);
          setLoading(true);
          props.uploadFile([blob], false, (response: any, error: any) => {
            if (response) {
              if (response && response.file_path) {
                contract.link = response.file_path;
              }
              setOpen(false);
              props.onClose({ contract: contract, action: "save" });
              setLoading(false);
            }
            if (error) {
              console.error(error);
              setLoading(false);
            }
          });
        }
      });
    }
  };

  const uploadDoc = (files: any) => {
    setLoading(true);
    props.uploadFile(files, false, (response: any, error: any) => {
      if (response) {
        uploadContractData(response);
      }
      if (error) {
        console.error(error);
        setLoading(false);
      }
    });
  };

  const uploadContractData = async (preSignedUrl: any) => {
    try {
      let contract: any = initialContractData;
      if (props.contractData) {
        contract = { ...contract, ...props.contractData };
      }
      if (preSignedUrl && preSignedUrl.file_path) {
        contract.link = preSignedUrl.file_path;
      }
      contract.optional_field = dynamicFields;
      const contractPayload = {
        ...contract,
        owner: [contract?.owner?.id],
        status: "Internal Draft Created",
        contractType: [contract?.contractType?.id],
        collaborators: contract?.collaborators?.map((item: any) => item?.id),
      };
      delete contractPayload["created_by"];

      if (contractPayload?.updateVersionData) {
        const response = await props.updateContractData(
          contractPayload,
          contractPayload.id
        );
        if (response) {
          webviewerInstance?.UI.loadDocument(response.access_url);
        }

        setLoading(false);
        // handleClose();
      } else {
        const response = await props.uploadContractData(contractPayload);
        if (response) {
          console.log(response);
          handleClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDynamicFieldValue = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    type: string
  ) => {
    const fields = [...dynamicFields];
    // setNewTextAnnotation(fields[sectionIndex].field_data[fieldIndex].field_name,event.target.value);
    if (type == "text" || type == "date" || type == "select") {
      fields[sectionIndex].field_data[fieldIndex].fieldValue =
        event.target.value;
      const { required, fieldValue, field_validation, validation } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation) {
        if (required && fieldValue.length > 0) {
          validation["errorMsg"] = "";
        }
        if (field_validation && event.target.value.length > field_validation) {
          validation[
            "errorMsg"
          ] = `Max character limit allowed is ${validation.maxCharLength} chars`;
        } else validation["errorMsg"] = "";
      }
    }
    if (type == "duration") {
      fields[sectionIndex].field_data[fieldIndex].fieldLength =
        event.target.value;
    }
    if (type == "percentage") {
      fields[sectionIndex].field_data[fieldIndex].fieldValue =
        event.target.value + " %";
    }
    if(type == 'boolean') {
      fields[sectionIndex].field_data[fieldIndex].fieldValue =
        event.target.checked;
    }
    setDynamicFields(fields);
  };

  const checkRequiredValidation = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    type: string
  ) => {
    removeAnnotations();
    const fields = [...dynamicFields];
    if (type == "text") {
      const { validation, fieldValue, required } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation !== null) {
        if (required && fieldValue == "") {
          validation["errorMsg"] = "This field is required.";
        } else validation["errorMsg"] = "";
      }
    }

    setDynamicFields(fields);
  };

  const checkValidation = (): boolean => {
    let isError = false;
    const fields = [...dynamicFields];
    fields.forEach((section) => {
      const { field_data } = section;
      field_data.forEach((tag: any) => {
        const { required, fieldValue, validation } = tag;
        if (required && fieldValue.length == 0) {
          validation["errorMsg"] = "This field is required.";
        }
        if (validation["errorMsg"] && validation["errorMsg"].length > 0) {
          isError = true;
        }
      });
    });

    setDynamicFields(fields);
    return isError;
  };

  const handleExpansion =
    (panel: number) => (event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const getExpandIcon = () => (
    <img src="/static_images/expand-icn.svg" alt="expand_icn" />
  );
  const ZoomIn = () => {
    if (webviewerInstance) {
      const zoom = webviewerInstance.UI.getZoomLevel() + 0.25;
      webviewerInstance.UI.setZoomLevel(zoom);
    }
  };

  const Zoomout = () => {
    if (webviewerInstance) {
      const zoom = webviewerInstance.UI.getZoomLevel() - 0.25;
      if (zoom > 0.25) {
        webviewerInstance.UI.setZoomLevel(zoom);
      }
    }
  };
  const highlightTag = (tag: string) => {
    if (webviewerInstance) {
      const { Core } = webviewerInstance;
      const { documentViewer, Annotations, annotationManager, Tools } = Core;
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
      let searchData = "{" + tag + "}";
      const searchMode =
        webviewerInstance.Core.Search.Mode.REGEX |
        webviewerInstance.Core.Search.Mode.HIGHLIGHT |
        webviewerInstance.Core.Search.Mode.PAGE_STOP;
      let isMoveToPage = true;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          if (
            result.resultCode === webviewerInstance.Core.Search.ResultCode.FOUND
          ) {
            const highlight = new Annotations.TextHighlightAnnotation();
            result.quads.map((quad: { getPoints: () => any }) => {
              highlight.Quads = [quad.getPoints()] as any[];
            });
            highlight.PageNumber = result.pageNum;
            highlight.X = 405;
            highlight.Y = 165;
            highlight.Width = 275;
            highlight.Height = 25;
            annotationManager.addAnnotation(highlight);
            annotationManager.redrawAnnotation(highlight);
            annotationManager.drawAnnotations({ pageNumber: result.pageNum });
            if (documentViewer.getCurrentPage() == result.pageNum) {
              isMoveToPage = false;
            }
            if (isMoveToPage) {
              documentViewer.setCurrentPage(result.pageNum, true);
            }
          }
        },
        onDocumentEnd: (result: any) => {},
      };
      documentViewer.textSearchInit(searchData, searchMode, searchOptions);
    }
  };

  const removeAnnotations = () => {
    if (webviewerInstance) {
      const { Core } = webviewerInstance;
      const { annotationManager } = Core;
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
    }
  };

  const setNewTextAnnotation = (tag: string, value: any) => {
    if (webviewerInstance) {
      const { Core } = webviewerInstance;
      const { annotationManager, documentViewer, Annotations } = Core;
      const annotations = annotationManager.getAnnotationsList();
      annotationManager.deleteAnnotations(annotations);
      let searchData = tag;
      const searchMode =
        webviewerInstance.Core.Search.Mode.HIGHLIGHT |
        webviewerInstance.Core.Search.Mode.PAGE_STOP;
      const searchOptions = {
        fullSearch: true,
        onResult: (result: any) => {
          if (
            result.resultCode === webviewerInstance.Core.Search.ResultCode.FOUND
          ) {
            console.log(result);
            const highlight = new Annotations.FreeTextAnnotation();
            highlight.PageNumber = result.pageNum;
            highlight.X = 405;
            highlight.Y = 165;
            highlight.Width = 275;
            highlight.Height = 25;
            highlight.setContents(value);
            highlight.StrokeColor = new Annotations.Color(0, 255, 0, 1);
            highlight.TextColor = new Annotations.Color(255, 0, 0, 1);
            highlight.TextAlign = "center";
            highlight.TextVerticalAlign = "center";
            highlight.FillColor = new Annotations.Color(255, 255, 255, 255);
            annotationManager.addAnnotation(highlight);
            annotationManager.redrawAnnotation(highlight);
            annotationManager.drawAnnotations({ pageNumber: result.pageNum });
          }
        },
      };
      documentViewer.textSearchInit(searchData, searchMode, searchOptions);
    }
  };
  return (
    <Dialog
      fullScreen={true}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        <div className="row">
          <div className="col-md-9">
            <span style={{ fontSize: "16px" }}>
              <span
                onClick={handleClose}
                style={{ marginRight: "10px", cursor: "pointer" }}
              >
                <img
                  style={{ width: "15px" }}
                  src="/static_images/back-arrow.svg"
                  alt="close_icon"
                />
              </span>
              {props.type == "template"
                ? "Template View"
                : "Contract Draft View"}{" "}
              {">"} {props.fileName}
            </span>
          </div>
          {warningField.length > 0 && (
            <Tooltip
              color="black"
              title={
                <>
                  {warningField?.map((item: any) => {
                    return (
                      <p
                        style={{
                          width: "auto",
                          marginBottom: "5px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        {item}
                      </p>
                    );
                  })}
                </>
              }
              arrow
            >
              <div className="col-md-3">
                <div className="row">
                  <img src="/static_images/dd-view.svg" className="h-13" />
                  <p
                    className="mb-0 ml-3 cursor-pointer"
                    style={{ fontSize: "14px", color: "#88305F" }}
                  >
                    {`Warning:${warningField.length} Fields Missing`}
                  </p>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading && <CircularProgress className="loader" />}
        <div className="row justify-content-between">
          <div className="col-md-5 form-table">
            {props.type == "template" && (
              <>
                {dynamicFields.map(
                  (section, sectionIndex) => {
                    return (
                      <Accordion
                        key={sectionIndex}
                        className={`card shadow mb-3`}
                        expanded={expanded === sectionIndex}
                        onChange={handleExpansion(sectionIndex)}
                      >
                        <AccordionSummary
                          expandIcon={getExpandIcon()}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                          className={`${
                            expanded == sectionIndex
                              ? expanded == 0
                                ? "first-header"
                                : "second-header"
                              : "remove-header"
                          }`}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {section.section_name}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <form
                            style={{ width: "100%" }}
                            className="needs-validation"
                          >
                            {section.field_data.map(
                              (field: any, fieldIndex: number) => (
                                <div key={fieldIndex}>
                                  {field.data_type.toLowerCase() == "text" && (
                                    <div className="form-group">
                                      <label className="field-label">
                                        {field.display_name}
                                        {field.validation !== null &&
                                        field.required
                                          ? " *"
                                          : ""}
                                      </label>
                                      <input
                                        onFocus={() => {
                                          highlightTag(field.field_name);
                                        }}
                                        onChange={(event) =>
                                          updateDynamicFieldValue(
                                            event,
                                            sectionIndex,
                                            fieldIndex,
                                            "text"
                                          )
                                        }
                                        onBlur={(event) =>
                                          checkRequiredValidation(
                                            event,
                                            sectionIndex,
                                            fieldIndex,
                                            "text"
                                          )
                                        }
                                        value={field.fieldValue}
                                        type="string"
                                        className={`form-control ${
                                          field.validation?.errorMsg
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        required
                                      />
                                      {
                                        <div
                                          id="validationServer03Feedback"
                                          className="invalid-feedback"
                                        >
                                          {field.validation?.errorMsg}
                                        </div>
                                      }
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "numeric" && (
                                    <div className="form-group">
                                      <label className="field-label">
                                        {field.display_name}
                                        {field.validation !== null &&
                                        field.required
                                          ? " *"
                                          : ""}
                                      </label>
                                      <input
                                        onFocus={() => {
                                          highlightTag(field.field_name);
                                        }}
                                        onChange={(event) =>
                                          updateDynamicFieldValue(
                                            event,
                                            sectionIndex,
                                            fieldIndex,
                                            "text"
                                          )
                                        }
                                        onBlur={(event) =>
                                          checkRequiredValidation(
                                            event,
                                            sectionIndex,
                                            fieldIndex,
                                            "text"
                                          )
                                        }
                                        value={field.fieldValue}
                                        type="number"
                                        className={`form-control ${
                                          field.validation?.errorMsg
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        required
                                      />
                                      {
                                        <div
                                          id="validationServer03Feedback"
                                          className="invalid-feedback"
                                        >
                                          {field.validation?.errorMsg}
                                        </div>
                                      }
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() == "date" && (
                                    <div className="form-group">
                                      <label className="field-label">
                                        {field.display_name}
                                      </label>
                                      <input
                                        onFocus={() => {
                                          highlightTag(field.field_name);
                                        }}
                                        onChange={(event) =>
                                          updateDynamicFieldValue(
                                            event,
                                            sectionIndex,
                                            fieldIndex,
                                            "date"
                                          )
                                        }
                                        value={field.fieldValue}
                                        type="date"
                                        className="form-control"
                                        name="date"
                                        id="date"
                                      />
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "duration" && (
                                    <div className="mb-3">
                                      <label htmlFor="" className="field-label">
                                        {field.display_name}
                                      </label>
                                      <div className="input-group">
                                        <input
                                          onFocus={() => {
                                            highlightTag(field.field_name);
                                          }}
                                          onChange={(event) =>
                                            updateDynamicFieldValue(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              "text"
                                            )
                                          }
                                          value={field.fieldValue}
                                          type="number"
                                          className="form-control"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                        />
                                        <div className="input-group-append">
                                          <FormControl
                                            size="small"
                                            style={{ minWidth: "104px" }}
                                            variant="outlined"
                                          >
                                            <InputLabel id="demo-simple-select-label">
                                              Select
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={field.fieldLength}
                                              label="Select"
                                              onChange={(event) =>
                                                updateDynamicFieldValue(
                                                  event,
                                                  sectionIndex,
                                                  fieldIndex,
                                                  "duration"
                                                )
                                              }
                                            >
                                              {field.set_recommended_value
                                                .default_field_value &&
                                                field.set_recommended_value
                                                  .default_field_value.length >
                                                  0 &&
                                                field.set_recommended_value.default_field_value.map(
                                                  (
                                                    value: any,
                                                    index: number
                                                  ) => (
                                                    <MenuItem
                                                      key={index}
                                                      value={value}
                                                    >
                                                      {value}
                                                    </MenuItem>
                                                  )
                                                )}
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                     {field.description}
                                                                 </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "select" && (
                                    <div className="mb-3">
                                      <label htmlFor="" className="field-label">
                                        {field.display_name}
                                      </label>
                                      <div className="input-group">
                                        <FormControl
                                          size="small"
                                          style={{
                                            minWidth: "104px",
                                            width: "100%  ",
                                          }}
                                          variant="outlined"
                                        >
                                          <Select
                                            onFocus={() => {
                                              highlightTag(field.field_name);
                                            }}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={field.fieldValue}
                                            onChange={(event) =>
                                              updateDynamicFieldValue(
                                                event,
                                                sectionIndex,
                                                fieldIndex,
                                                "select"
                                              )
                                            }
                                          >
                                            {field.set_recommended_value
                                              .default_field_value &&
                                              field.set_recommended_value
                                                .default_field_value.length >
                                                0 &&
                                              field.set_recommended_value.default_field_value.map(
                                                (value: any, index: number) => (
                                                  <MenuItem
                                                    key={index}
                                                    value={value}
                                                  >
                                                    {value}
                                                  </MenuItem>
                                                )
                                              )}
                                          </Select>
                                        </FormControl>
                                      </div>
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "percentage" && (
                                    <div className="mb-3">
                                      <label htmlFor="" className="field-label">
                                        {field.display_name}
                                      </label>
                                      <div className="input-group">
                                        <FormControl
                                          size="small"
                                          fullWidth
                                          variant="outlined"
                                        >
                                          <OutlinedInput
                                            onFocus={() => {
                                              highlightTag(field.field_name);
                                            }}
                                            type="number"
                                            id="outlined-adornment-amount"
                                            value={field.fieldValue}
                                            onChange={(event) =>
                                              updateDynamicFieldValue(
                                                event,
                                                sectionIndex,
                                                fieldIndex,
                                                "percentage"
                                              )
                                            }
                                            startAdornment={
                                              <InputAdornment position="start">
                                                %
                                              </InputAdornment>
                                            }
                                          />
                                        </FormControl>
                                      </div>
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "text box" && (
                                    <div className="mb-3">
                                      <label htmlFor="" className="field-label">
                                        {field.display_name}
                                      </label>
                                      <div className="input-group">
                                        <TextField
                                          onFocus={() => {
                                            highlightTag(field.field_name);
                                          }}
                                          fullWidth={true}
                                          size="small"
                                          id="outlined-multiline-flexible"
                                          variant="outlined"
                                          multiline
                                          minRows={3}
                                          maxRows={5}
                                          value={field.fieldValue}
                                          onChange={(event) =>
                                            updateDynamicFieldValue(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              "text"
                                            )
                                          }
                                        />
                                      </div>
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                  {field.data_type.toLowerCase() ==
                                    "boolean" && (
                                    <div className="mb-3">
                                      <div className="input-group">
                                        <FormGroup>
                                          <FormControlLabel control={<Checkbox checked={field.fieldValue} onChange={(event) =>
                                            updateDynamicFieldValue(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              "boolean"
                                            )} />} label={field.display_name} />
                                        </FormGroup>
                                      </div>
                                      {/* <small id="passwordHelpBlock" className="form-text text-muted">
                                                                    {field.description}
                                                                </small> */}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </form>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }

                  // <div key={sectionIndex} className={`card shadow ${sectionIndex == dynamicFields.length - 1 ? '' : 'mb-3'}`}>
                  //     <div className="card-header section-name">
                  //         {section.sectionName}
                  //     </div>
                  //     <div className="card-body">

                  //     </div>
                  // </div>
                )}
              </>
            )}
          </div>
          <div
            className="col-md-7"
            style={{ background: "rgba(233, 233, 233, 0.2)" }}
          >
            <div className="doc-viewer-header df mb-2">
              <div className="df" style={{ gap: "10px" }}>
                <img
                  src="/static_images/data-dictionary-icn.svg"
                  className="pl-1 w-10"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setshowTemplateField(!showTemplateField);
                  }}
                />
              </div>
              <div className="df" style={{ gap: "10px" }}>
                <img
                  src="/static_images/serchiconplus.svg"
                  className="pl-1 w-10"
                  style={{ cursor: "pointer" }}
                  onClick={() => ZoomIn()}
                />
                <img
                  src="/static_images/serchiconminus.svg"
                  className="pl-1 w-10"
                  style={{ cursor: "pointer" }}
                  onClick={() => Zoomout()}
                />
                {/* <img
                                    src="/static_images/serchiconnew.svg"
                                    className="pl-1 w-10"
                                    onClick={() => {
                                    }}
                                    style={{ cursor: "pointer" }}
                                /> */}
              </div>
            </div>
            <div
              ref={documentContainer}
              className="document-viewer"
              style={{ minHeight: "calc(100vh - 210px)" }}
            ></div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="flex-center">
        <Button
          // onClick={uploadBlankDoc}
          onClick={() => handleClose()}
          style={{
            textTransform: "capitalize",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          }}
        >
          Cancel
        </Button>
        <Button className="create-btn" onClick={handleSaveDraft}>
          Save Draft
        </Button>
        <Button className="create-btn" onClick={handleSubmit}>
          Generate Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocViewerDialog;