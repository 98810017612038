import React from "react";
import { humanFileSize } from "../../Utils/DataModifierUtil/dataModUtil";
import { FileList } from "../State/uploadState";
import UploadCard from "./uploadCards";
import { isNullOrUndefined } from "is-what";

interface Props {
  fileInfo: FileList[];
  selectedFilter: string;
  selectFilter: (selectedFilter: string) => void;
  contract_statistics: any;
}

export default function QuickLook(props: Props) {
  let { fileInfo, selectFilter, selectedFilter } = props;
  

  let a = fileInfo.filter((i) => {
    if (i.uploaded_type === "Contract") {
      return i
    }
  });
  let b = fileInfo.filter((i) => {
    if (i.uploaded_type === "Amendment") {
      return i;
    }
  });

  let c = fileInfo.filter((i) => {
    if (i.uploaded_type === "Support Document") {
      return i;
    }
  });
  

  return (
    <>
    <div className="row" style={{display:"none"}}>
      {/* <div className="col-md-3">
        <QuickLookCard
          title={fileInfo.length.toString()}
          subTitle={"totalUploads"}
          selectedFilter={selectedFilter}
          selectFilter={selectFilter}
          isClickable={fileInfo.length > 0 ? true : false}
        />
      </div> */}

      {/* <div className="col-md-3">
        <QuickLookCard title={getErrorCount(fileInfo).toString()} subTitle={'Errors'} />
    </div> */}
      {/* <div className="col-md-3">
        <QuickLookCard title={getProcessedCount(fileInfo).toString()} subTitle={'processed'} selectedFilter={selectedFilter}  selectFilter={selectFilter} isClickable={getProcessedCount(fileInfo) > 0 ? true : false} />
    </div> */}
      <div className="col-md-4">
        <UploadCard
          // title={a.length.toString()}
          title={props.contract_statistics?.contracts_count}
          subTitle={"totalUploads"}
          selectedFilter={selectedFilter}
          selectFilter={selectFilter}
          isClickable={false}
        />
      </div>
      <div className="col-md-4">
        <UploadCard
          // title={c.length.toString()}
          title={props.contract_statistics?.support_document_count}
          subTitle={"supportDocuments"}
          selectedFilter={selectedFilter}
          selectFilter={selectFilter}
          isClickable={false}
        />
      </div>
      <div className="col-md-4">
        <UploadCard
          // title={getUploadSize(fileInfo).toString()}
          title={humanFileSize((props.contract_statistics?.total_contracts_size), true)}
          subTitle={"uploadSize"}
          selectedFilter={selectedFilter}
          selectFilter={selectFilter}
          isClickable={false}
        />
      </div>
    </div>
    {!isNullOrUndefined(props.contract_statistics) && (
        <div className="d-flex mt-3 pl-0" >
          <div className="mr-4"
          >
            <p className="myDocument-uploadNo">
              {props.contract_statistics?.contracts_count - ((props.contract_statistics?.amendment_count) + (props.contract_statistics?.support_document_count))}

            </p>
            <p className="myDocument-uploadtype">
              No. of Contracts
            </p>
          </div>
          <div className="mr-4"
          >
            <p className="myDocument-uploadNo">
              {props.contract_statistics?.amendment_count}
            </p>
            <p className="myDocument-uploadtype">
              No of Amendment Documents
            </p>
          </div>
          <div className="mr-4"
          >
            <p className="myDocument-uploadNo">
              {props.contract_statistics?.support_document_count}
            </p>
            <p className="myDocument-uploadtype">
              No of Support Documents
            </p>
          </div>
          <div className="mr-4"
          >
            <p className="myDocument-uploadNo" style={{ width: "unset" }}>

              {humanFileSize((props.contract_statistics?.total_contracts_size), true)}
            </p>
            <p className="myDocument-uploadtype">
              Used Space
            </p>
          </div>
        </div>
      )}
    </>
  );
}

function getErrorCount(fileInfo: FileList[]) {
  let count = 0;
  for (let i = 0; i < fileInfo.length; i++) {
    // if (fileInfo[i].fileState.progressState.process === FAILED) {
    count++;
    // }
  }
  return count;
}

function getUploadSize(fileInfo: FileList[]) {
  let size = 0;
  for (let i = 0; i < fileInfo.length; i++) {
    size = size + fileInfo[i].file_size;
    // size = size + fileInfo[i].fileState.size;
  }
  return humanFileSize(size, true);
}
