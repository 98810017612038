export default interface ClauseLibraryState {
  sectionDictionaryList: SectionDictionaryInfo[];
  folderHeadingList: FolderHeadingInfo[];
  folderSubHeadingList: FolderHeadingInfo[];
  clauseLibraryData: ClauseFolderState[];
  collapsedFolderIds: string[];
  selectedClauseData: ClauseInfo;
  clauseLibraryLoader: boolean;
  clauseIds: string[];
  clausesData: ClauseStructure[];
  sectionClauseList: SectionClauseInfo[];
  updateSectionClauseList: SectionClauseInfo[];
  clause_Library_Loader: boolean;
  totalCount:number;
}

// export interface SectionClauseInfo {
//   id: string;
//   section_name: SectionClauseName;
//   tags: [];
//   created_by: string;
//   clause_name: string;
//   clause_no: string;
//   clause_type: string;
//   clause_source: string;
//   clause_text: string;
//   description: string;
//   created_on: string;
//   creation_type: string;
// }

export interface SectionClauseInfo {
  data: any;
}

export interface SectionClauseName {
  id: string;
  contract_type: string;
  created_by: string;
  section_name: string;
  section_tag: string;
  section_description: string;
  comments: string;
  created_on: string;
}

export interface SectionDictionaryInfo {
  comments: string;
  contract_type: SectionContractType;
  created_by: any;
  created_on: string;
  id: string;
  section_description: string;
  section_name: string;
  section_tag: string;
}

export interface SectionContractType {
  comments: string;
  created_by: any;
  created_on: string;
  displayName: string;
  id: string;
  name: string;
}

export interface FolderHeadingInfo {
  folderId: string;
  folderName: string;
  parentId: string;
  id: string;
  name: string;
}

export interface ClauseFolderState {
  folderID: string;
  folderName: string;
  clauses: ClauseState[];
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  children: ClauseFolderState[];
  id: string;
  name: string;
  created_by: string;
  created_on: string;
  modified_on: string;
  subfolders: ClauseFolderState[];
}

export interface ClauseState {
  id: string;
  name: string;
  clauseType: string;
  createdBy: string;
  createdOn: string;
  modifiedOn: string;
  type: string;
  //
  created_on: string;
  modified_on: string;
  created_by: string;
  folder: string;
  nature: string;
  text: string;
  original_text: string;
  contract: string;
}

export interface ClauseStructure {
  clauseID: string;
  sourceFileID: number;
  sourceFileName: string;
  folderID: string;
  folderName: string;
  subfolderID: string;
  subfolderName: string;
  extractedClause: string;
  userEditedClause: string;
}

export interface ClauseInfo {
  clauseId: string;
  clauseName: string;
  clauseFolderId: string;
  clauseFolderName: string;
  clauseSubFolderId: string;
  clauseSubFolderName: string;
  clauseType: string;
  extractedClause: string;
  userEditedClause: string;
  sourceFileId: number;
  sourceFileName: string;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
}

export function defaultClauseLibraryState(): ClauseLibraryState {
  return {
    updateSectionClauseList: [],
    sectionClauseList: [],
    sectionDictionaryList: [],
    folderHeadingList: [],
    folderSubHeadingList: [],
    clauseLibraryData: [],
    collapsedFolderIds: [],
    selectedClauseData: {
      clauseId: "-1",
      clauseName: "",
      clauseFolderId: "-1",
      clauseFolderName: "",
      clauseSubFolderId: "-1",
      clauseSubFolderName: "",
      clauseType: "",
      extractedClause: "",
      userEditedClause: "",
      sourceFileId: -1,
      sourceFileName: "",
      createdOn: "",
      createdBy: "",
      modifiedOn: "",
    },
    clauseLibraryLoader: false,
    clausesData: [],
    clauseIds: [],
    clause_Library_Loader: false,
    totalCount:0
  };
}
