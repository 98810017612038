import React, { createContext, useEffect, useState } from "react";
import { History } from "history";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import "../Design/contractType.scss";
import { CircularProgress } from "@material-ui/core";
import AddContractDialog from "./AddContractDialog";
import ListView from "./ListView";
import { ContractTypeRes } from "../State/ContractTypeState";
import { getContractTypes } from "../DA";
interface Props {
  pageWatcher: (page: string) => void;
  history: History;
}
const ContractType = (props: Props) => {
  useEffect(() => {
    props.pageWatcher("contractTypes");
    getContractType();
  }, []);
  const [contractTypes, setcontractTypes] = useState<ContractTypeRes[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const getContractType = async () => {
    try {
      setisLoading(true);
      const response = await getContractTypes();
      if (response && response.results.length > 0) {
        setcontractTypes(response.results);
      } else if (response && response.results.length == 0) {
        setcontractTypes([]);
      }
    } catch (error) {
      console.error("getContractType", error);
    } finally {
      setisLoading(false);
    }
  };
  const generateTopHeader = () => {
    return (
      <>
        <p className="stylus_section_header">Contract Types</p>
        <div className="row mb-5">
          <div className="col-md-6">
            <div className="template-row">
              <div className="template-count-box">
                <p className="template-count">{contractTypes.length}</p>
                <p className="margin-0">TOTAL CONTRACT TYPES</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="row justify-content-end"
              style={{ marginRight: "20px" }}
            >
              <button
                onClick={handleClickOpen}
                type="button"
                className="btn btn-warning create-btn"
              >
                + Contract Types
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="row">
      <div className="col-md-1" style={{ zIndex: 2 }}>
        <SideNavbar history={props.history} />
      </div>
      <div className="col-md-11 mt-6">
        {generateTopHeader()}
        {isLoading && <CircularProgress className="loader" />}
        {openDialog && (
          <AddContractDialog
            isClone={false}
            getContractType={getContractType}
            isEdit={false}
            contractType={null}
            open={openDialog}
            onClose={handleClose}
          />
        )}
        <ListView
          getContractType={getContractType}
          contractTypes={contractTypes}
        />
      </div>
    </div>
  );
};

export default ContractType;
