import { AppBar, Box, CircularProgress, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import DocViewerDialog from "../../Templates/Component/DocViewerDialog";
import { initialTemplateData } from "../../Templates/State/templateState";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import { getDateString } from "../../Utils/DataModifierUtil/dataModUtil";
import {
  getContracts,
  getContractsall,
  getTemplates,
  getUsers,
  updateContractMetaData,
} from "../DA";
import "../Design/draft.scss";
import { ContractData, ContractRes, draftStatus } from "../State/DraftState";
import CreateDraftDialog from "./CreateDraftDialog";
import DeleteDraftDialog from "./DeleteDraftDialog";
import DraftListView from "./DraftListView";
import { AiOutlineReload, AiOutlineEdit } from "react-icons/ai";
import { IconContext } from "react-icons";
import { BsTrash } from "react-icons/bs";
import SupportDocDialog from "./SupportDocDialog";
import AssignedToDialog from "./AssignedToDialog";
import AddNewPersonDialog from "./AddNewPersonDialog";
import VersionHistory from "./VersionHistory";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import ComparisionDialog from "./ComparisionDialog";
import ForApproval from "./ForApproval";
import ApprovalStatusDialog from "./ApprovalStatusDialog";
import PdfViewer from "./PdfViewerDialog";
import { getFieldName, getSpecificTemplate } from "../../Templates/DA";
import SignatureConfigDialog from "./SignatureConfigDialog";

interface Props {
  history: any;
  pageWatcher: (page: string) => void;
  uploadFile: (post: any, fileType: any, callback: Function) => void;
  uploadContractData: (post: any) => Promise<any> | undefined;
  getDrafts: () => void;
}

const DraftComponent = (props: Props) => {
  const [fromTemplate, setFromTemplate] = useState(false);
  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [openDocViewer, setopenDocViewer] = useState(false);
  const [templateData, settemplateData] = useState(initialTemplateData);
  const [dynamicFields, setdynamicFields] = useState([]);
  const [contractData, setcontractData] = useState(null);
  const [loading, setloading] = useState(false);
  const [value, setValue] = useState(0);
  const [templateRouteParam, setTemplateRouteParam] = useState({});
  const [myDraft, setMyDraft] = useState<ContractRes[]>([]);
  const [forApproval, setforApproval] = useState<ContractRes[]>([]);
  const [forSignature, setForSignature] = useState<ContractRes[]>([]);
  const [forAll, setForAll] = useState<ContractRes[]>([]);
  const [selectedDraft, setselectedDraft] = useState<string[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDrafts, setdeleteDrafts] = useState<ContractRes[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [draftData, setdraftData] = useState<ContractRes | null>(null);
  const [showVersionHistory, setshowVersionHistory] = useState(false);
  const [selectedDraftObj, setselectedDraftObj] = useState<ContractRes | null>(
    null
  );
  const [contractTypeList, setcontractTypeList] = useState<any[]>([]);
  const [projectList, setprojectList] = useState<any[]>([]);
  const [openSupportDocDialog, setopenSupportDocDialog] = useState(false);
  const [openAssignedToDialog, setopenAssignedToDialog] = useState(false);
  const [openAddAssigneeDialog, setopenAddAssigneeDialog] = useState(false);
  const [collaborators, setcollaborators] = useState<any[]>([]);
  const [approverType, setApproverType] = useState<any>([]);
  const [usersData, setUsersData] = useState<any>(null);
  const [specificDraft, setSpecificDraft] = useState<any>(null);
  const [openComparisionDrawer, setComparisionDrawer] = useState<any>(false);
  const [selectedDraftsLink, setselectedDraftsLink] = useState<string[]>([]);
  const [open, setOpen] = useState<any>(false);
  const [reqresponse, setreqresponse] = useState<any>([]);
  const [myDraftCount, setmyDraftCount] = useState(0);
  const [draftGeneratedData, setdraftGeneratedData] = useState<ContractRes[]>(
    []
  );
  const [newreq, setnewreq] = useState<any>(0);
  const [mydraftnewreq, setmydraftnewreq] = useState<any>(0);
  const [earlierdraft, setearlierdraft] = useState<any>([]);
  const [pageWiseData, setpageWiseData] = useState<any>({});
  const [compareFileName, setCompareFileName] = useState<any[]>([]);
  const [openApprovalStatusDialog, setopenApprovalStatusDialog] =
    useState(false);
  const [draftdetails, setdraftdetails] = useState<ContractRes>();
  const [draftlink, setdraftlink] = useState("");
  const [draftfileName, setdraftfileName] = useState("");
  const [opendraftpdftron, setopendraftpdftron] = useState<any>(false);
  const [allcomingdata, setallcomingdata] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [allsupportdoc, setallsupportdoc] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [fieldNameList, setFieldNameList] = useState<any>([]);
  const [alldraft, setalldraft] = useState<any>([]);
  const [myalldraft, setmyalldraft] = useState<any>([]);
  const [allspreadedassignee, setallspreadedassignee] = useState<any>([]);
  const [onreload, setonreload] = useState<any>(false);
  const [statusList, setstatusList] = useState<any>([]);
  const [openSignatureConfig, setopenSignatureConfig] =
    useState<boolean>(false);
  const fieldData = async () => {
    const data: any = await getFieldName();
    if (data && data.length) {
      setFieldNameList(data);
    }
  };

  useEffect(() => {
    if (forAll.length > 0) {
      let contractTypeList = forAll
        .map((con) => con.contractType)
        .filter((data) => {
          if (data) return data;
        });
      let projectNames = forAll
        .map((con) => con.projectNames)
        .flat()
        .filter((data) => {
          if (data) return data;
        });
      let statusList = forAll
        .map((con) => con.status)
        .flat()
        .filter((data) => {
          if (data) return data;
        });
      setcontractTypeList(Array.from(new Set(contractTypeList)));
      setprojectList(Array.from(new Set(projectNames)));
      setstatusList(Array.from(new Set(statusList)));
    }
  }, [forAll.length]);

  useEffect(() => {
    props.pageWatcher("draftingreview");
    if (props.history.location.state) {
      setFromTemplate(true);
      setTemplateRouteParam(props.history.location.state);
      openCreateDraftDialog();
    }
    getContractDrafts(pageNumber);
  }, []);
  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const getdraftDetails = (id: string) => {
    axios
      .get(
        process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/${id}/`,
        APIConfig()
      )
      .then((response) => {
        let parsed = response.data as ContractRes;
        setdraftdetails(response.data);
        setdraftlink(parsed.access_url);
        setdraftfileName(parsed.contractName);
        setopendraftpdftron(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (window.location.pathname !== "") {
      const a = window.location.pathname.split("/")[2];
      if (a) {
        axios
          .get(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${a}/`,
            APIConfig()
          )
          .then((response) => {
            setreqresponse(response.data);
            setOpen(true);
          })
          .catch((error) => {
            getdraftDetails(a);
            //console.log(error);
          });
      }
    }
  }, []);

  const handleSelectDraft = (id: string) => {
    let selected = [...selectedDraft];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedDraft([...selected]);
  };

  const handleOpenDeleteDialog = () => {
    let deleteDrafts = selectedDraft.reduce<ContractRes[]>((acc, curr) => {
      let drafts = forAll.find((value) => value.id == curr);
      if (drafts) {
        acc = [...acc, drafts];
      }
      return acc;
    }, []);
    setdeleteDrafts(deleteDrafts);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setselectedDraft([]);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const openCreateDraftDialog = () => {
    setOpenDraftDialog(true);
  };
  const handleClose = (draftDialogData: any) => {
    if (draftDialogData != null) {
      const { contractData, dynamicFields, collaborators } = draftDialogData;
      if (dynamicFields.length > 0) {
        setdynamicFields(dynamicFields);
      }
      if (contractData) {
        setcontractData(contractData);
      }
      //if (templateData["templateLink"]) {
      //settemplateData(templateData);
      //setopenDocViewer(true);
      //}
      if (collaborators?.length) {
        setcollaborators(collaborators);
      }
    }
    setOpenDraftDialog(false);
  };

  const setTemplate = (contractData: any) => {
    const template = { ...templateData };
    if (contractData.status === draftStatus.DRAFT_CREATION_PENDING) {
      template.templateLink = contractData["template_link"];
      template.templateType = contractData["contractType"];
    } else {
      template.templateLink = contractData["link"];
    }
    if (contractData.optional_field) {
      setdynamicFields(contractData.optional_field);
    }
    template.name = contractData["contractName"];
    settemplateData(template);
    setcontractData({ ...contractData, updateVersionData: true });
    setopenDocViewer(true);
  };
  const handleDocViewerClose = async (draftData: any) => {
    try {
      if (draftData) {
        const { response, contract } = draftData;
        if (draftData.action && draftData.action == "cancel") {
          if (response && response.file_path) {
            contract.link = response.file_path;
          }
          contract.status = "Initial Version";
        }
        if (draftData.action && draftData.action == "save") {
          contract.status = draftStatus.DRAFT_CREATION_PENDING;
          setloading(true);
          const contractPayload = {
            ...contract,
            collaborators: contract?.collaborators?.map((i: any) => i.id),
            owner: [contract?.owner?.id],
          };
          const contract_response = await updateContractMetaData(
            contractPayload,
            contractPayload.id
          );
          if (contract_response) {
            setloading(false);
            setopenDocViewer(false);
            setOpenDraftDialog(false);
            setTimeout(() => {
              getContractDrafts(pageNumber);
            }, 500);
          }
        } else {
          setloading(true);
          const contractPayload = {
            ...contract,
            collaborators: contract?.collaborators?.map((i: any) => i.id),
            owner: [contract?.owner?.id],
          };
          const contract_response = await props.uploadContractData(
            contractPayload
          );
          if (contract_response) {
            setloading(false);
            setopenDocViewer(false);
            setOpenDraftDialog(false);
            setTimeout(() => {
              getContractDrafts(pageNumber);
            }, 500);
          }
        }
      } else {
        setOpen(false);
        setopenDocViewer(false);
        setOpenDraftDialog(false);
        setshowVersionHistory(false);

        setTimeout(() => {
          getContractDrafts(pageNumber);
        }, 500);
      }
      setdynamicFields([]);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = () => {
    const URL = process.env.REACT_APP_RIVERUS_CLM_API + "users/org/all/";
    axios
      .get(URL, APIConfig())
      .then((value: any) => {
        localStorage.setItem("userData", JSON.stringify(value.data));
        setUsersData(value.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUsers();
    fieldData();
  }, []);

  const updateRequisition = (id: any, payload: any, type: any) => {
    if (type === "STC") {
      const URL = process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/${id}/`;
      axios.patch(URL, payload, APIConfig()).then((value: any) => {
        setSpecificDraft(value.data);
        getContractDrafts(pageNumber);
        setonreload(true);
      });
    } else {
      const URL =
        process.env.REACT_APP_RIVERUS_CLM_API +
        `stylus/requisition_form/${id}/`;
      axios.patch(URL, payload, APIConfig()).then((value: any) => {
        setSpecificDraft(value.data);
        getContractDrafts(pageNumber);
        setonreload(true);
      });
    }
  };

  const segregateDraftTypes = (
    contracts: ContractRes[],
    totalDrafts: any[]
  ) => {
    try {
      let draft = contracts?.filter((a: any) => a?.version != 0);
      let alldrafts = totalDrafts?.filter((a: any) => a?.version != 0);
      const perticularmyalldraft = alldrafts?.filter(
        (val: any) => val.id == localStorage.getItem("user_id")
      );
      const myalldraft = alldrafts?.filter(
        (val: any) => val.id !== localStorage.getItem("user_id")
      );
      const persentUserINAnotherDraftall = myalldraft.filter((val: any) => {
        const collo =
          val?.collaborators !== null
            ? val?.collaborators?.filter(
                (data: any) => data?.id == localStorage.getItem("user_id")
              )
            : [];
        const sign =
          val?.signatories != null
            ? val?.signatories?.filter((data: any) => {
                if (data?.id == localStorage.getItem("user_id")) {
                  return data;
                }
              })
            : [];
        if (collo.length || sign.length) {
          return val;
        }
      });
      const myallDraftData = [
        ...perticularmyalldraft,
        ...persentUserINAnotherDraftall,
      ];
      setmyalldraft(myallDraftData);
      setearlierdraft(draft);
      const perticularmydraft = draft?.filter(
        (val: any) => val.id == localStorage.getItem("user_id")
      );
      const mydraft = draft?.filter(
        (val: any) => val.id !== localStorage.getItem("user_id")
      );
      const persentUserINAnotherDraft = mydraft.filter((val: any) => {
        const collo =
          val?.collaborators !== null
            ? val?.collaborators?.filter(
                (data: any) => data?.id == localStorage.getItem("user_id")
              )
            : [];
        const sign =
          val?.signatories != null
            ? val?.signatories?.filter((data: any) => {
                if (data?.id == localStorage.getItem("user_id")) {
                  return data;
                }
              })
            : [];
        if (collo.length || sign.length) {
          return val;
        }
      });
      let newrequisition = totalDrafts?.filter((a: any) => a?.version === 0);
      setnewreq(newrequisition?.length);
      let mynewreq = totalDrafts?.filter(
        (i: any) =>
          i?.created_by.id === localStorage.getItem("user_id") &&
          i.version === 0
      );
      setmydraftnewreq(mynewreq.length);
      const myDraftData = [...perticularmydraft, ...persentUserINAnotherDraft];
      let approvalPending = contracts?.filter((contract) => {
        let status = contract?.status?.toLowerCase();
        if (
          status == "approval pending" ||
          status == "counter party approval Pending" ||
          status == "approval denied" ||
          status == "requisition approval pending" ||
          status == "requisition approved" ||
          status == "rejected" ||
          status == "rework"
        ) {
          return contract;
        }
      });
      let forSignature = contracts?.filter((contract) => {
        let status = contract?.status;
        if (
          status === "Signature Pending" ||
          status === "counter party signature Pending" ||
          status === "contract executed successfully"
        ) {
          return contract;
        }
      });
      let draftGenerated = contracts?.filter((a: any) => a.version !== 0);
      setForAll(contracts);
      setMyDraft(myDraftData);
      setdraftGeneratedData(draftGenerated);
      setforApproval(approvalPending);
      setForSignature(forSignature);
      setmyDraftCount(myDraftData.length);
    } catch (error) {
      console.error(error);
    }
  };

  const getContractDrafts = async (pageNumber: number) => {
    try {
      setloading(true);
      const response = await getContracts(pageNumber);
      const responseall = await getContractsall();
      setalldraft(responseall.results);
      setallcomingdata(response.results);
      if (response && response.results && response.results.length > 0) {
        setPageCount(response.count);
        let user_id: any = localStorage.getItem("user_id");
        const current_user: any = localStorage.getItem("user_profile");
        const user_profile: any = JSON.parse(current_user);
        let contracts: ContractRes[] = response.results.map((contract: any) => {
          let contractRes: ContractRes = {
            ...contract,
            created_on:
              contract["created_on"] &&
              getDateString(new Date(contract["created_on"])),
            deadline:
              contract["deadline"] &&
              getDateString(new Date(contract["deadline"])),
          };
          return contractRes;
        });
        contracts.sort((a: any, b: any) =>
          a.lastUpdated > b.lastUpdated ? -1 : 1
        );
        segregateDraftTypes(contracts, responseall.results);
      } else {
        setForAll([]);
        setMyDraft([]);
        setforApproval([]);
        setdraftGeneratedData([]);
        setForSignature([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const filternewreq = (key: any) => {
    if (key === "total") {
      const newreq = allcomingdata?.filter((i: any) => i.version === 0);
      setForAll(newreq);
    } else {
      let draft = allcomingdata?.filter((a: any) => a?.version != 0);
      const perticularmydraft = draft?.filter(
        (val: any) => val.id == localStorage.getItem("user_id")
      );
      setForAll(perticularmydraft);
    }
  };
  const displayCount = (total: any, newReq: number) => {
    return (
      <div className="draft-row mb-2">
        <div className="draft-count-box">
          <p
            className="draft-count mb-1"
            style={{ cursor: "pointer" }}
            onClick={() => setForAll(allcomingdata)}
          >
            {total}
          </p>
          <p className="m-0" style={{ cursor: "pointer" }}>
            TOTAL
          </p>
        </div>
        <div className="count-divider"></div>
        <div className="draft-count-box">
          <p
            className="draft-count mb-1"
            onClick={() => filternewreq("total")}
            style={{ cursor: "pointer" }}
          >
            {newReq}
          </p>
          <p className="m-0 uppercase" style={{ cursor: "pointer" }}>
            New Requisitions
          </p>
        </div>
        <div className="count-divider"></div>
        <div className="draft-count-box">
          <p className="draft-count mb-1">0</p>
          <p className="m-0 uppercase">under collaboration</p>
        </div>
        <div className="count-divider"></div>
        <div className="draft-count-box">
          <p className="draft-count mb-1">0</p>
          <p className="m-0 uppercase">approval pending</p>
        </div>
        <div className="count-divider"></div>
        <div className="draft-count-box">
          <p className="draft-count mb-1">0</p>
          <p className="m-0 uppercase">signatures pending</p>
        </div>
      </div>
    );
  };

  const generateTopHeader = () => {
    return (
      <>
        <div className="row justify-content-between">
          <div className="col">
            <p className="stylus_section_header">Drafts</p>
          </div>
          <div className="col text-align-end mr-2">
            <button
              onClick={openCreateDraftDialog}
              type="button"
              className="btn btn-warning create-btn"
            >
              + Start a Draft
            </button>
          </div>
        </div>
        <div className="mb-2">
          <div className="row m-0">
            <div className="col-md-6">
              <p className="f-14 mb-1">TOTAL DRAFTS</p>
              {displayCount(alldraft.length, newreq)}
            </div>
            <div className="col-md-6">
              <p className="f-14 mb-1 ">MY DRAFTS</p>
              {displayCount(myalldraft.length, mydraftnewreq)}
            </div>
          </div>
        </div>
      </>
    );
  };

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }

  const handleOpenEditDialog = (id: string) => {
    let data = forAll.find((contract) => contract.id == id);
    if (data) {
      setdraftData(data);
      setOpenEditDialog(true);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleCloseVersionHistory = () => {
    setshowVersionHistory(false);
    getContractDrafts(pageNumber);
  };

  const openVersionHistory = (draftId: string) => {
    let data = forAll.find((contract) => contract.id == draftId);
    if (data) {
      setselectedDraftObj({ ...data });
      setshowVersionHistory(true);
    }
  };

  const openSupportDoc = (id: string, alldata: any) => {
    let data = forAll.find((contract) => contract.id == id);
    if (data) {
      setselectedDraftObj({ ...data });
      setopenSupportDocDialog(true);
      setallsupportdoc(alldata);
    }
  };

  const closeSupportDoc = () => {
    setopenSupportDocDialog(false);
    getContractDrafts(pageNumber);
  };

  const openAssignedTo = (id: string) => {
    let data = forAll.find((contract) => contract.id == id);
    if (data) {
      console.log("openAssignedTo", id);
      setselectedDraftObj({ ...data });
      setopenAssignedToDialog(true);
    }
  };

  const closeAssignedTo = () => {
    setopenAssignedToDialog(false);
  };

  const openApprovalStatus = (id: string) => {
    let data = forAll.find((contract) => contract.id == id);
    if (data) {
      setselectedDraftObj({ ...data });
      if (
        data.status &&
        data.status.toLowerCase().includes("signature pending")
      ) {
        setopenSignatureConfig(true);
      } else setopenApprovalStatusDialog(true);
    }
  };

  const closeopenApprovalStatus = () => {
    setopenApprovalStatusDialog(false);
  };

  const openDocViewerDialog = (contract: ContractRes) => {
    let contractData: any = { ...contract };
    if (contract.optional_field) {
      setdynamicFields(contract.optional_field);
    }
    if (contract) {
      setcontractData({ ...contractData });
    }
    settemplateData({
      name: contract.contractName,
      templateLink: contract.link,
      templateType: contract.contractType,
    });
    setopenDocViewer(true);
  };

  useEffect(() => {}, [
    specificDraft,
    selectedDraftsLink,
    templateData,
    dynamicFields,
  ]);

  const handleCloseForApproval = () => {
    setOpen(false);
  };

  // Generating link for template scenario
  const createLink = async (payload: any, common: any) => {
    // const access_template_link = payload.template_link
    getTemplates().then(async (data: any) => {
      if (data.results.length > 0) {
        const template_id = data.results?.filter(
          (item: any) => item.file_path === payload.template_link
        );
        const specificTemplate = await getSpecificTemplate(template_id[0].id);
        if (specificTemplate) {
          let contract: any = { ...payload };
          let blob = await axios
            // .get(contract.template_link, { responseType: "blob" })
            .get(specificTemplate.access_url, { responseType: "blob" })
            .then((res) => res.data)
            .catch((error) => console.log(error));
          blob["name"] = payload!.contractName + ".docx";
          props.uploadFile([blob], false, (response: any, error: any) => {
            if (response) {
              if (response && response.file_path) {
                contract.link = response.file_path;
                common(contract);
              }
            }
            if (error) {
              console.error(error);
            }
          });
        }
      }
    });
  };

  const handleSelectAllDraft = () => {
    let selected: string[] = [];
    if (selectedDraft.length > 0) {
      setselectedDraft([]);
      return;
    }
    switch (value) {
      case 0:
        selected = forAll.map((draft) => draft.id);
        break;
      case 1:
        selected = myDraft.map((draft) => draft.id);
        break;
      case 2:
        selected = draftGeneratedData.map((draft) => draft.id);
        break;
      case 3:
        selected = forApproval.map((draft) => draft.id);
        break;
      case 4:
        selected = forSignature.map((draft) => draft.id);
        break;
      default:
        break;
    }
    setselectedDraft([...selected]);
  };

  const updatePageNumber = (number: number) => {
    getContractDrafts(number);
    setpageNumber(number);
  };

  return (
    <div className="row">
      <div className="col-md-1" style={{ zIndex: 2, flex: "0 0 7.333333%" }}>
        <SideNavbar history={props.history} />
      </div>
      <div className="col-md-11 mt-6">
        {generateTopHeader()}
        <div className="ROOT">
          <AppBar position="static" className="mb-2">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className="draft-tabs"
            >
              <Tab disableRipple label="All" {...a11yProps(0)} />
              <Tab disableRipple label="My Drafts" {...a11yProps(1)} />
              <Tab disableRipple label="Draft Generated" {...a11yProps(2)} />
              <Tab disableRipple label="For Approval" {...a11yProps(3)} />
              <Tab disableRipple label="For Signature" {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <div className="row justify-content-between mb-2">
            <div className="col-md-8">
              <div className="row mt-1 mb-1" style={{ marginLeft: "0" }}>
                <IconContext.Provider
                  value={{ color: "#88305F", className: "reload-icon" }}
                >
                  <AiOutlineReload
                    className="ml-2 cursor-pointer"
                    onClick={() => getContractDrafts(pageNumber)}
                  />
                </IconContext.Provider>
              </div>
            </div>
            <div className="col-md-4">
              <div className="df mt-1" style={{ justifyContent: "end" }}>
                {selectedDraft.length == 1 && (
                  <IconContext.Provider
                    value={{ color: "#88305F", className: "edit-icon" }}
                  >
                    <AiOutlineEdit
                      onClick={() => handleOpenEditDialog(selectedDraft[0])}
                    />
                  </IconContext.Provider>
                )}
                {selectedDraft.length > 0 && (
                  <IconContext.Provider
                    value={{ color: "#FF685F", className: "svg-delete-icon" }}
                  >
                    <BsTrash onClick={handleOpenDeleteDialog} />
                  </IconContext.Provider>
                )}
              </div>
            </div>
          </div>
          <TabPanel value={value} index={0} style={{ overflowX: "auto" }}>
            <DraftListView
              contractTypeList={contractTypeList}
              projectList={projectList}
              openVersionHistory={openVersionHistory}
              selectedDraft={selectedDraft}
              selectMultipleDrafts={handleSelectDraft}
              draftContractData={forAll}
              openDocViewerDialog={openDocViewerDialog}
              openSupportDoc={openSupportDoc}
              openAssignedTo={openAssignedTo}
              setSpecificDraft={setSpecificDraft}
              onPatch={updateRequisition}
              setTemplate={setTemplate}
              getDrafts={() => getContractDrafts(pageNumber)}
              createLink={createLink}
              selectAllDraft={handleSelectAllDraft}
              updatePageNumber={updatePageNumber}
              openApprovalStatus={openApprovalStatus}
              pageNumber={pageNumber}
              pageCount={pageCount}
              allspreaded={allspreadedassignee}
              statusList={statusList}
              uploadFile={props.uploadFile}
            />
          </TabPanel>
          <TabPanel value={value} index={1} style={{ overflowX: "auto" }}>
            <DraftListView
              contractTypeList={contractTypeList}
              projectList={projectList}
              openVersionHistory={openVersionHistory}
              selectedDraft={selectedDraft}
              selectMultipleDrafts={handleSelectDraft}
              draftContractData={myDraft}
              openDocViewerDialog={openDocViewerDialog}
              openSupportDoc={openSupportDoc}
              openAssignedTo={openAssignedTo}
              setSpecificDraft={setSpecificDraft}
              onPatch={updateRequisition}
              setTemplate={setTemplate}
              getDrafts={() => getContractDrafts(pageNumber)}
              createLink={createLink}
              selectAllDraft={handleSelectAllDraft}
              updatePageNumber={updatePageNumber}
              openApprovalStatus={openApprovalStatus}
              pageCount={pageCount}
              pageNumber={pageNumber}
              allspreaded={allspreadedassignee}
              statusList={statusList}
              uploadFile={props.uploadFile}
            />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ overflowX: "auto" }}>
            <DraftListView
              contractTypeList={contractTypeList}
              projectList={projectList}
              openVersionHistory={openVersionHistory}
              selectedDraft={selectedDraft}
              selectMultipleDrafts={handleSelectDraft}
              draftContractData={draftGeneratedData}
              openSupportDoc={openSupportDoc}
              openAssignedTo={openAssignedTo}
              openDocViewerDialog={openDocViewerDialog}
              setSpecificDraft={setSpecificDraft}
              onPatch={updateRequisition}
              setTemplate={setTemplate}
              getDrafts={() => getContractDrafts(pageNumber)}
              createLink={createLink}
              selectAllDraft={handleSelectAllDraft}
              updatePageNumber={updatePageNumber}
              openApprovalStatus={openApprovalStatus}
              pageCount={pageCount}
              pageNumber={pageNumber}
              allspreaded={allspreadedassignee}
              statusList={statusList}
              uploadFile={props.uploadFile}
            />
          </TabPanel>
          <TabPanel value={value} index={3} style={{ overflowX: "auto" }}>
            <DraftListView
              contractTypeList={contractTypeList}
              projectList={projectList}
              openVersionHistory={openVersionHistory}
              selectedDraft={selectedDraft}
              selectMultipleDrafts={handleSelectDraft}
              draftContractData={forApproval}
              openSupportDoc={openSupportDoc}
              openAssignedTo={openAssignedTo}
              openDocViewerDialog={openDocViewerDialog}
              setSpecificDraft={setSpecificDraft}
              onPatch={updateRequisition}
              setTemplate={setTemplate}
              getDrafts={() => getContractDrafts(pageNumber)}
              createLink={createLink}
              selectAllDraft={handleSelectAllDraft}
              updatePageNumber={updatePageNumber}
              openApprovalStatus={openApprovalStatus}
              pageCount={pageCount}
              pageNumber={pageNumber}
              allspreaded={allspreadedassignee}
              statusList={statusList}
              uploadFile={props.uploadFile}
            />
          </TabPanel>
          <TabPanel value={value} index={4} style={{ overflowX: "auto" }}>
            <DraftListView
              contractTypeList={contractTypeList}
              projectList={projectList}
              openVersionHistory={openVersionHistory}
              selectedDraft={selectedDraft}
              selectMultipleDrafts={handleSelectDraft}
              draftContractData={forSignature}
              openSupportDoc={openSupportDoc}
              openAssignedTo={openAssignedTo}
              openDocViewerDialog={openDocViewerDialog}
              setSpecificDraft={setSpecificDraft}
              onPatch={updateRequisition}
              setTemplate={setTemplate}
              getDrafts={() => getContractDrafts(pageNumber)}
              createLink={createLink}
              selectAllDraft={handleSelectAllDraft}
              updatePageNumber={updatePageNumber}
              openApprovalStatus={openApprovalStatus}
              pageCount={pageCount}
              pageNumber={pageNumber}
              allspreaded={allspreadedassignee}
              statusList={statusList}
              uploadFile={props.uploadFile}
            />
          </TabPanel>
        </div>
      </div>
      {openDeleteDialog && (
        <DeleteDraftDialog
          open={openDeleteDialog}
          getDrafts={() => getContractDrafts(pageNumber)}
          onClose={handleCloseDeleteDialog}
          selectedDraft={deleteDrafts}
          for={"drafts"}
        />
      )}
      {loading && <CircularProgress className="loader" />}
      {openDraftDialog && (
        <CreateDraftDialog
          uploadFile={props.uploadFile}
          contractData={null}
          getDrafts={() => getContractDrafts(pageNumber)}
          isEdit={false}
          open={openDraftDialog}
          onClose={handleClose}
          myDraft={earlierdraft}
          fromTemplate={fromTemplate}
          routeParams={templateRouteParam}
          setTemplate={setTemplate}
          createLink={createLink}
        />
      )}
      {openDocViewer && (
        <DocViewerDialog
          uploadContractData={props.uploadContractData}
          uploadFile={props.uploadFile}
          contractData={contractData}
          fromTemplate={false}
          dynamicFields={dynamicFields}
          template={templateData}
          history={props.history}
          type="template"
          open={openDocViewer}
          onClose={handleDocViewerClose}
          fileName={templateData.name || ""}
          templateLink={templateData.templateLink}
          collaborators={collaborators}
          updateContractData={updateContractMetaData}
          fieldNameList={fieldNameList}
        />
      )}
      {openEditDialog && (
        <CreateDraftDialog
          uploadFile={props.uploadFile}
          contractData={draftData}
          getDrafts={() => getContractDrafts(pageNumber)}
          isEdit={true}
          myDraft={earlierdraft}
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          fromTemplate={false}
          routeParams={null}
          setTemplate={setTemplate}
          createLink={createLink}
        />
      )}
      {showVersionHistory && (
        <VersionHistory
          uploadContract={props.uploadContractData}
          getContractDraft={() => getContractDrafts(pageNumber)}
          selectedDraft={selectedDraftObj}
          open={showVersionHistory}
          onClose={handleCloseVersionHistory}
          setComparisionDrawer={setComparisionDrawer}
          selectedDraftsLink={selectedDraftsLink}
          setselectedDraftsLink={setselectedDraftsLink}
          setTemplate={setTemplate}
          getDrafts={() => getContractDrafts(pageNumber)}
          setCompareFileName={setCompareFileName}
          setselectedDraft={setselectedDraftObj}
          setshowVersionHistory={setshowVersionHistory}
        />
      )}
      {openSupportDocDialog && (
        <SupportDocDialog
          open={openSupportDocDialog}
          onClose={closeSupportDoc}
          // setsupportmodel={setsupportmodel}
          reqid={
            selectedDraftObj
              ? selectedDraftObj.requisition_id
                ? selectedDraftObj.requisition_id
                : selectedDraftObj.id
              : ""
          }
          allsupportdoc={allsupportdoc}
        />
      )}
      {openAssignedToDialog && (
        <AssignedToDialog
          open={openAssignedToDialog}
          onClose={closeAssignedTo}
          onPatch={updateRequisition}
          setopenAddAssigneeDialog={setopenAddAssigneeDialog}
          specificDraft={specificDraft}
          setallspreaded={setallspreadedassignee}
          allspreaded={allspreadedassignee}
          onreload={onreload}
          setonreload={setonreload}
          showEdit={true}
        />
      )}
      {openAddAssigneeDialog && (
        <AddNewPersonDialog
          open={openAddAssigneeDialog}
          onClose={() => {
            setopenAddAssigneeDialog(false);
            setonreload(true);
          }}
          specificDraft={specificDraft}
          onPatch={updateRequisition}
        />
      )}
      {openComparisionDrawer && (
        <ComparisionDialog
          openComparisionDrawer={openComparisionDrawer}
          setComparisionDrawer={setComparisionDrawer}
          draftDetails={specificDraft}
          selectedDraftsLink={selectedDraftsLink}
          onClose={() => {
            getContractDrafts(pageNumber);
            setshowVersionHistory(false);
            setselectedDraftsLink([]);
          }}
          compareFileName={compareFileName}
        />
      )}
      {open && (
        <ForApproval
          draftcomponent=""
          openmain={handleCloseForApproval}
          version={reqresponse.version}
          requisitionId={reqresponse.id}
          close={() => {}}
          createLink={undefined}
          show={false}
          reqres={reqresponse}
          open={open}
          setopenapproval={setOpen}
          getDrafts={() => getContractDrafts(pageNumber)}
        />
      )}

      {openApprovalStatusDialog && (
        <ApprovalStatusDialog
          uploadContract={props.uploadContractData}
          getContractDraft={() => getContractDrafts(pageNumber)}
          selectedDraft={selectedDraftObj}
          open={openApprovalStatusDialog}
          onClose={closeopenApprovalStatus}
          selectedDraftsLink={selectedDraftsLink}
          setselectedDraftsLink={setselectedDraftsLink}
          getDrafts={() => getContractDrafts(pageNumber)}
        />
      )}

      {opendraftpdftron && (
        <PdfViewer
          open={opendraftpdftron}
          onClose={() => {
            getContractDrafts(pageNumber);
            setopendraftpdftron(false);
          }}
          fileName={draftfileName}
          draftDetails={draftdetails}
        />
      )}
      {openSignatureConfig && (
        <SignatureConfigDialog
          open={openSignatureConfig}
          onClose={() => setopenSignatureConfig(false)}
          selectedDraft={selectedDraftObj}
        />
      )}
    </div>
  );
};

export default DraftComponent;
