import React from "react";
import SentenceRenderer from "../../UniversalComponents/SentenceRenderer/Container/sentenceCon";
import {
  SentencesData,
  InsightsInterface,
  BiPointDataMode,
  DurationType,
  CurrencyType,
  DeviationPolicyInterface,
  Approval,
  ContractDetails,
} from "../State/documentState";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import InfoBar from "./InfoBar/infoBar";
import DocumentInsights from "./documentInsights";
import { History } from "history";
import RequestTagModal from "../../UniversalComponents/Modals/RequestTagsModal/requestTagsCon";
import ScrollNavButton from "./scrollNavButton";
import TaskModal from "../../UniversalComponents/Modals/TaskManagementModal/Container/taskModalCon";

import {
  CurrentTask,
  TaskData,
  TaskState,
} from "../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";
import TaskPagecomponent from "./TaskPage/taskPageComponent";
import {
  AllTasksData,
  ProgressNameList,
} from "../../TaskManagement/State/taskManagementPageState";
import TaskStatusModal from "../../UniversalComponents/Modals/TaskStatusModal/TaskStatusModalCon";
import ViewFileHierarchyModal from "../../UniversalComponents/Modals/ViewFileHierarchyModal/viewFileHierarchyModal";
import { FileHierarchy } from "../../DocumentLibrary/State/documentLibraryState";
import SentenceFrameRenderer from "../../UniversalComponents/SentenceRenderer/Component/sentenceFrameRenderer";
import CreateClauseModal from "../../UniversalComponents/Modals/ClauseLibraryModals/CreateClauseModal/createClauseModalCon";
import { isNullOrUndefined } from "is-what";
import { getParentHighlights } from "./Utils/docUtils";
import DeviationPolicy from "./deviationPolicy";

import ClauseErrorModal from "../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/Container/clauseerrorModalCon";
import {
  ClauseErrorData,
  ClauseErrorList,
} from "../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/State/clauseErrorManagementState";
import DeviationPolicyModal from "../../UniversalComponents/Modals/DeviationPolicyModal/deviationpolicymodal";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import DocumentPdftronView from "./documentPdftronView";
import RightMenu from "./rightMenu";
import ViewInsights from "./ViewInsights/view-insights";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import DocumentsView from "./documents";
import { getContractsTags} from "../../Draft/DA";
import toast, { Toaster } from "react-hot-toast";
// import CustomizedSnackbar from "../../UniversalComponents/Snackbars/Component/customizedSnackbar";

interface Props {
  match: any;
  location: any;
  history: History;
  sentenceData: SentencesData;
  getSentences: (fileId: string) => void;
  pageWatcher: (page: string) => void;
  getInsights: (fileId: string) => void;
  getApproval: () => void;
  insightsData: InsightsInterface[];
  approvalData: Approval[];
  sentenceLoader: boolean;
  editLoader: boolean;
  editSuccessLoader: boolean;
  editFailureLoader: boolean;
  storedBiPointDataModes: BiPointDataMode[];
  saveBiPointDataMode: (storedBiPointDataModes: BiPointDataMode[]) => void;
  currentTask: CurrentTask;
  setCurrentTask: (name: string, value: string, contractName: string) => void;
  allTasksData: AllTasksData[];
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  progressNameList: ProgressNameList[];
  updateProgress: (
    requestID: number,
    progressID: string,
    id: string,
    progressName: string
  ) => void;
  getProgressNameList: () => void;
  isProgressUpdated: number;
  taskLoader: boolean;
  createTaskSuccess: number;
  getClauseType: () => void;
  getDocumentTree: (fileID: string) => void;
  documentTree: FileHierarchy;
  saveDocumentTree: (documentTree: FileHierarchy) => void;
  clauseModeStatus: boolean;
  saveClauseModeStatus: (clauseModeStatus: boolean) => void;
  getFolderHeading: () => void;
  taskProgress: TaskState[];
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  saveInsightToDelete: (
    insightToDelete: any,
    childLabelToDelete: string
  ) => void;
  durationList: DurationType[];
  getDurationTypes: () => void;
  currencyList: CurrencyType[];
  getCurrencyTypes: () => void;
  deviationpolicyData: DeviationPolicyInterface[];
  getClauseErrorData: (fileID: string) => void;
  clauseErrorData: ClauseErrorList[];
  getContractData: (fileId: string) => void;
  contractData: ContractDetails;
  getSectionDictionary: () => void;
  clauseLibraryLoader: boolean;
  clause_Library_Loader: boolean;
}

interface State {
  dataPoints: string[];
  clickedState: boolean;
  tempParaIndex: number;
  scrolledChildIndex: number;
  superImposedChildIndex: number;
  type: string;
  scrollTop: boolean;
  fileId: string;
  editOptionSelected: boolean;
  selectedInsightPoint: string;
  currentScrollIndex: number;
  tabs: string[];
  activeTabName: string;
  hideTaskPage: boolean;
  showTaskStatusModal: boolean;
  editMode: boolean;
  currentEditTaskData: AllTasksData;
  selectedClauseText: string;
  requestID: number;
  taskOrigin: string; // to identify if task page is opened through insights or tasks-icon
  // showRightObjects: boolean;
  // rightMenuType: string;
  windowMenu: {
    topMenu: boolean;
    selectedTopMenu: string;
    rightMenu: boolean;
    selectedRightMenu: string;
  };
  assessmentWords: any[];
  assessmentSelectionMode: boolean;
  hideclauseErrorPage: boolean;
  tabValue: number;
  showClauseModal: boolean;
  projectData: [];
  clauseLibraryLoader: boolean;
  clause_Library_Loader: boolean;
  create_clause: number;
}

export default class DocumentView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dataPoints: [],
      clickedState: false,
      tempParaIndex: 0,
      scrolledChildIndex: 0,
      type: "",
      superImposedChildIndex: 0,
      scrollTop: false,
      fileId: "0",
      editOptionSelected: false,
      selectedInsightPoint: "",
      currentScrollIndex: 0,
      tabs: [], //["Insights", "Deviation from Policy"],
      activeTabName: "Insights",
      hideTaskPage: true,
      showTaskStatusModal: false,
      editMode: false,
      currentEditTaskData: {
        requestID: 0,
        taskTitle: "",
        description: "",
        clauseName: "",
        clauseAlias: "",
        biType: "",
        biColumnName: "",
        progressID: "0",
        progressName: "",
        stateName: "",

        contractID: 0,
        linkedText: "",
        contractName: "",
        associateGroup: [],
        reminder: {
          reminderStart: 0,
          reminderStartType: "",
          reminderType: "",
          reminderUntil: "",
          frequencyType: "",
        },
        checkList: [],
        id: "",
        contract: "",
        contract_file_name: "",
        contract_clause_type: "",
        contract_business_intelligence_type: "",
        link_to_contract_text: "",
        title: "",
        assignees: [],
        due_date: "",
        progress: "",
        status: "",
        reminder_number: 0,
        reminder_period: "",
        reminder_before_or_after: "",
        reminder_repeats: "",
        reminder_until: "",
        notes: "",
        checklist: [],
      },
      selectedClauseText: "",
      requestID: 0,
      taskOrigin: "",
      windowMenu: {
        topMenu: true,
        selectedTopMenu: "processed",
        rightMenu: true,
        // selectedRightMenu: "assessments",
        selectedRightMenu: "editInsights",
      },
      assessmentWords: [],
      assessmentSelectionMode: false,
      hideclauseErrorPage: true,
      tabValue: 0,
      showClauseModal: false,
      projectData: [],
      clauseLibraryLoader: false,
      clause_Library_Loader: false,
      create_clause: -1,
    };
  }

  handleWindowMenu = (
    menuType: string,
    menuTypeValue: boolean,
    selectedMenu: string
  ) => {
    let windowMenu_ = this.state.windowMenu;
    if (menuType === "top") {
      windowMenu_.topMenu = menuTypeValue;
      windowMenu_.selectedTopMenu = selectedMenu;
    } else if (menuType === "right") {
      windowMenu_.rightMenu = menuTypeValue;
      windowMenu_.selectedRightMenu = selectedMenu;
    }
    if (
      menuType === "right" &&
      selectedMenu === "editInsights" &&
      menuTypeValue
    ) {
      this.setState({
        activeTabName: "Insights",
      });
    } else {
      this.setState({
        activeTabName: "Insights",
      });
      getParentHighlights([]);
    }
    this.setState({ windowMenu: windowMenu_ });
  };
  // handleRightMenuOpen = (menuType: string) => {
  //     this.setState({ showRightObjects: true, rightMenuType: menuType });
  // };
  // handleRightMenuClose = () => {
  //     this.setState({ showRightObjects: false, rightMenuType: '' });
  // };

  getProjectData = async () => {
    try {
      let response = await getContractsTags();
      if (response && response.results.length > 0) {
        this.setState({ projectData: response.results });
      }
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    let {
      match,
      getSentences,
      pageWatcher,
      getInsights,
      getProgressNameList,
      getAllTasksData,
      allTasksData,
      progressNameList,
      getClauseErrorData,
      getApproval,
      getContractData,
    } = this.props;
    let fileId = match.params.id;

    this.setState({ fileId: atob(fileId) });
    getSentences(fileId);
    getClauseErrorData(fileId);

    getInsights(fileId);
    getContractData(fileId);
    getApproval();
    pageWatcher("document");
    document.addEventListener("scroll", (e) => this.toggleGoToTop());
    this.props.getDurationTypes();
    this.props.getCurrencyTypes();
    getProgressNameList();
    if (!isNullOrUndefined(match.params.requestid)) {
      let requestID = match.params.requestid;

      this.setState({ requestID: parseInt(atob(requestID)) });
      getAllTasksData(atob(fileId), atob(requestID), "", "", false, "");
      this.setState({ hideTaskPage: false });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.editLoader !== nextProps.editLoader &&
      !nextProps.editLoader
    ) {
      this.props.getInsights(this.props.match.params.id);
    }

    if (
      !isNullOrUndefined(this.props.match.params.requestid) &&
      this.props.allTasksData !== nextProps.allTasksData &&
      this.state.requestID !== 0
    ) {
      if (nextProps.allTasksData.length === 1) {
        this.setState(
          {
            currentEditTaskData: nextProps.allTasksData[0],
            hideTaskPage: false,
            editMode: true,
          },
          () => {
            let link = document.getElementById("taskModalButton");
            !isNullOrUndefined(link) && link.click();
          }
        );
      }
    }
    if (nextProps.clause_Library_Loader != this.props.clause_Library_Loader) {
      if (nextProps.clause_Library_Loader == true) {
        notifyClauseSuccess();
        this.setState({ clauseLibraryLoader: false, create_clause: -1 });
      }
    }
    if (nextProps.clause_Library_Loader == this.props.clause_Library_Loader) {
      if (
        nextProps.clause_Library_Loader == false &&
        this.state.create_clause == 1
      ) {
        notifyClauseError();
        this.setState({ clauseLibraryLoader: false, create_clause: -1 });
      }
    }
  }

  toggleGoToTop() {
    if (window.pageYOffset > 600) {
      this.setState({ scrollTop: true });
    }
    if (window.pageYOffset < 600) {
      this.setState({ scrollTop: false });
    }
  }

  getTabStyle = (label: string, isActive: boolean) => {
    if (isActive) {
      switch (label) {
        case "Insights": {
          return "col-md-6 label active-tab1 tab-border";
        }
        // case 'Obligations': {
        //     return 'col-md-4 label active-tab2 tab-border';
        // }
        case "Deviation from Policy": {
          return "col-md-6 label active-tab3";
        }
        default: {
          return "col-md-6 label active-tab";
        }
      }
    } else {
      switch (label) {
        case "Insights": {
          return "col-md-6 label inactive-tab tab-border";
        }
        // case 'Obligations': {
        //     return 'col-md-4 label inactive-tab tab-border';
        // }
        case "Deviation from Policy": {
          return "col-md-6 label inactive-tab";
        }
        default: {
          return "col-md-6 label inactive-tab";
        }
      }
    }
  };
  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    if (newValue == 3) {
      this.setState({
        windowMenu: {
          topMenu: true,
          selectedTopMenu: "processed",
          rightMenu: true,
          selectedRightMenu: "deviationPolicy",
        },
      });
    } else if (newValue == 0) {
      this.setState({
        windowMenu: {
          topMenu: true,
          selectedTopMenu: "processed",
          rightMenu: true,
          selectedRightMenu: "editInsights",
        },
      });
    } else if (newValue == 1) {
      this.setState({
        windowMenu: {
          topMenu: true,
          selectedTopMenu: "processed",
          rightMenu: true,
          selectedRightMenu: "document",
        },
      });
    }
    else if (newValue == 2) {
      this.setState({
        windowMenu: {
          topMenu: true,
          selectedTopMenu: "processed",
          rightMenu: true,
          selectedRightMenu: "task",
        },
      });
    }
    this.setState({ tabValue: newValue });
  };

  showClauseModal = () => {
    this.setState({
      showClauseModal: true,
      clauseLibraryLoader: false,
      create_clause: -1,
    });
  };

  render() {
    let {
      currentTask,
      sentenceData,
      insightsData,
      match,
      history,
      location,
      sentenceLoader,
      editLoader,
      editSuccessLoader,
      editFailureLoader,
      storedBiPointDataModes,
      saveBiPointDataMode,
      setCurrentTask,
      allTasksData,
      getAllTasksData,
      progressNameList,
      updateProgress,
      isProgressUpdated,
      taskLoader,
      getClauseType,
      documentTree,
      saveDocumentTree,
      getDocumentTree,
      clauseModeStatus,
      saveClauseModeStatus,
      getFolderHeading,
      taskProgress,
      editDataPoint,
      saveInsightToDelete,
      durationList,
      currencyList,
      deviationpolicyData,
      getClauseErrorData,
      clauseErrorData,
      approvalData,
      contractData,
      getSectionDictionary,
    } = this.props;
    let {
      dataPoints,
      clickedState,
      tempParaIndex,
      scrolledChildIndex,
      type,
      superImposedChildIndex,
      scrollTop,
      fileId,
      editOptionSelected,
      selectedInsightPoint,
      currentScrollIndex,
      hideTaskPage,
      editMode,
      currentEditTaskData,
      selectedClauseText,
      taskOrigin,
      tabValue,
    } = this.state;

    const { windowMenu } = this.state;
    // const col1 = this.state.showRightObjects ? '5' : '10';
    let col1 = 5;
    let col2 = 5;
    const { topMenu, selectedTopMenu, rightMenu, selectedRightMenu } =
      windowMenu;
    if (topMenu && rightMenu) {
      col1 = 5;
      col2 = 5;
    } else if (topMenu && !rightMenu) {
      col1 = 10;
      col2 = 0;
    } else if (!topMenu && rightMenu) {
      col1 = 0;
      col2 = 10;
    } else {
      col1 = 0;
      col2 = 0;
    }

    return (
      <>
      <div>
           <Toaster
            position="bottom-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{
              top: 50,
            }}
            toastOptions={{
              className: "",
              duration: 5000,
              success: {
                duration: 3000,
                theme: {
                  primary: "yellow",
                },
              },
            }}
          /> 
        </div>
        <div className="row">
          <div
            className="col-md-6 mt-4 mb-2 col-12"
            style={{ position: "fixed", zIndex: 2, maxWidth: "50.2%" }}
          >
            <InfoBar
              fileId={match.params.id}
              linkedPage={match.params.page}
              setTaskPage={(hideTaskPage: boolean) =>
                this.setState({ hideTaskPage: hideTaskPage })
              }
              setCurrentTask={setCurrentTask}
              getAllTasksData={getAllTasksData}
              fileID={fileId}
              getClauseType={getClauseType}
              getDocumentTree={getDocumentTree}
              clauseModeStatus={clauseModeStatus}
              saveClauseModeStatus={saveClauseModeStatus}
              getFolderHeading={getFolderHeading}
              setRequestID={(requestID: number) =>
                this.setState({ requestID: requestID })
              }
              setTaskOrigin={(origin: string) =>
                this.setState({ taskOrigin: origin })
              }
              windowMenu={this.state.windowMenu}
              handleWindowMenu={this.handleWindowMenu}
              insightsData={insightsData}
              clauseErrorData={clauseErrorData}
              contractData={contractData}
              showClauseModal={() => {
                this.showClauseModal();
              }}
              getSectionDictionary={getSectionDictionary}
              getProjectData={() => {
                this.getProjectData();
              }}
            />

            <TaskPagecomponent
              hidden={hideTaskPage}
              currentTask={currentTask}
              allTasksData={allTasksData}
              getAllTasksData={getAllTasksData}
              fileId={fileId}
              updateProgress={updateProgress}
              isProgressUpdated={isProgressUpdated}
              taskLoader={taskLoader}
              setEditMode={(
                editMode: boolean,
                currentEditTaskData: AllTasksData
              ) =>
                this.setState({
                  editMode: editMode,
                  currentEditTaskData: currentEditTaskData,
                })
              }
              setHideTaskPage={(hide: boolean) =>
                this.setState({ hideTaskPage: hide })
              }
              taskProgress={taskProgress}
              progressNameList={progressNameList}
            />
            {/* <button style={{ display: 'none' }} type="button" id="taskPageButton"></button> */}
          </div>
        </div>
        {/* ___ */}
        <div className="row">
          <div className="col-md-1 px-0">
            <SideNavbar history={history} />
          </div>
          <div style={{ height: 20 }} />
        </div>
        {/* ____ */}
        <div className="row">
          <div className="col-md-1 px-0">
            <SideNavbar history={history} />
          </div>

          <div
            className={`col-md-${col1}`}
            // className="col-md-6"
            style={{ top: "18vh", right: "6vh" }}
          >
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === "editInsights" && (
                <div
                  className={`col-md-6`}
                  style={{
                    position: "fixed",
                    left: "48vw",
                    top: "12vh",
                  }}
                  // style={{ marginTop: '10vh', position: 'fixed' }}
                >
                  <div className="row">
                    <div style={{ height: 40 }} />

                    <DocumentInsights
                      editLoader={editLoader}
                      editSuccessLoader={editSuccessLoader}
                      editFailureLoader={editFailureLoader}
                      sentenceLoader={sentenceLoader}
                      fileId={fileId}
                      insightsData={insightsData}
                      saveDataPoints={(
                        dataPoints: string[],
                        state: boolean,
                        tempParaIndex: number,
                        type: string
                      ) =>
                        this.setState({
                          dataPoints: dataPoints,
                          clickedState: state,
                          tempParaIndex: tempParaIndex,
                          type: type,
                        })
                      }
                      scrolledChildIndex={scrolledChildIndex}
                      superImposeChildIndex={(scrolledChildIndex: number) =>
                        this.setState({
                          superImposedChildIndex: scrolledChildIndex,
                        })
                      }
                      location={location}
                      history={history}
                      editOptionSelected={(editOptionSelected: boolean) =>
                        this.setState({
                          editOptionSelected: editOptionSelected,
                        })
                      }
                      storedBiPointDataModes={storedBiPointDataModes}
                      saveBiPointDataMode={(
                        storedBiPointDataModes: BiPointDataMode[]
                      ) => saveBiPointDataMode(storedBiPointDataModes)}
                      saveSelectedInsightPoint={(
                        selectedInsightPoint: string
                      ) =>
                        this.setState({
                          selectedInsightPoint: selectedInsightPoint,
                        })
                      }
                      setCurrentScrollIndex={(currentScrollIndex: number) =>
                        this.setState({
                          currentScrollIndex: currentScrollIndex,
                        })
                      }
                      currentScrollIndex={currentScrollIndex}
                      currentTask={currentTask}
                      setCurrentTask={setCurrentTask}
                      fileEncoded={match.params.id}
                      setTaskPage={(hideTaskPage: boolean) =>
                        this.setState({
                          hideTaskPage: hideTaskPage,
                        })
                      }
                      getAllTasksData={getAllTasksData}
                      clauseModeStatus={clauseModeStatus}
                      setTaskOrigin={(origin: string) =>
                        this.setState({ taskOrigin: origin })
                      }
                      editDataPoint={editDataPoint}
                      saveInsightToDelete={saveInsightToDelete}
                      durationList={durationList}
                      currencyList={currencyList}
                      contractData={contractData}
                      getcontractData={()=>this.props.getContractData(fileId)}
                      clauseErrorData={clauseErrorData}
                    />
                  </div>
                </div>
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === "viewInsights" && (
                <div
                  // className={`col-md-${col2}`}
                  // style={{
                  //   position: "fixed",
                  //   left: "48vw",
                  //   top: "14vh",
                  //   paddingBottom: 20,
                  // }}
                  className={`col-md-6`}
                  style={{
                    position: "fixed",
                    left: "48vw",
                    top: "13vh",
                    paddingBottom: 20,
                  }}
                >
                  <div className="row">
                    <div style={{ height: 40 }} />
                    <ViewInsights
                      sentenceLoader={sentenceLoader}
                      fileId={match.params.id}
                      insightsData={insightsData}
                    />
                  </div>
                </div>
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === "deviationPolicy" && (
                <div
                  className={`col-md-6`}
                  style={{
                    position: "fixed",
                    left: "48vw",
                    top: "14vh",
                    paddingBottom: 20,
                  }}
                >
                  <div className="row">
                    <div style={{ height: 40 }} />
                    <DeviationPolicy
                      editLoader={editLoader}
                      sentenceLoader={sentenceLoader}
                      fileId={fileId}
                      insightsData={insightsData}
                      saveDataPoints={(
                        dataPoints: string[],
                        state: boolean,
                        tempParaIndex: number,
                        type: string
                      ) =>
                        this.setState({
                          dataPoints: dataPoints,
                          clickedState: state,
                          tempParaIndex: tempParaIndex,
                          type: type,
                        })
                      }
                      scrolledChildIndex={scrolledChildIndex}
                      superImposeChildIndex={(scrolledChildIndex: number) =>
                        this.setState({
                          superImposedChildIndex: scrolledChildIndex,
                        })
                      }
                      location={location}
                      history={history}
                      editOptionSelected={(editOptionSelected: boolean) =>
                        this.setState({
                          editOptionSelected: editOptionSelected,
                        })
                      }
                      storedBiPointDataModes={storedBiPointDataModes}
                      saveBiPointDataMode={(
                        storedBiPointDataModes: BiPointDataMode[]
                      ) => saveBiPointDataMode(storedBiPointDataModes)}
                      saveSelectedInsightPoint={(
                        selectedInsightPoint: string
                      ) =>
                        this.setState({
                          selectedInsightPoint: selectedInsightPoint,
                        })
                      }
                      setCurrentScrollIndex={(currentScrollIndex: number) =>
                        this.setState({
                          currentScrollIndex: currentScrollIndex,
                        })
                      }
                      currentScrollIndex={currentScrollIndex}
                      currentTask={currentTask}
                      setCurrentTask={setCurrentTask}
                      fileEncoded={match.params.id}
                      setTaskPage={(hideTaskPage: boolean) =>
                        this.setState({
                          hideTaskPage: hideTaskPage,
                        })
                      }
                      getAllTasksData={getAllTasksData}
                      clauseModeStatus={clauseModeStatus}
                      setTaskOrigin={(origin: string) =>
                        this.setState({ taskOrigin: origin })
                      }
                      editDataPoint={editDataPoint}
                      saveInsightToDelete={saveInsightToDelete}
                      durationList={durationList}
                      currencyList={currencyList}
                      deviationpolicyData={deviationpolicyData}
                      approvalData={approvalData}
                    ></DeviationPolicy>
                  </div>
                </div>
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === "editInsights11" &&
              this.state.hideTaskPage &&
              dataPoints.length > 1 &&
              clickedState && (
                <ScrollNavButton
                  dataPoints={dataPoints}
                  currentScrollIndex={currentScrollIndex}
                  type={type}
                  setCurrentScrollIndex={(currentScrollIndex: number) =>
                    this.setState({
                      currentScrollIndex: currentScrollIndex,
                    })
                  }
                  selectedInsightPoint={selectedInsightPoint}
                />
              )}
            {windowMenu.rightMenu &&
              windowMenu.selectedRightMenu === "document" && (
                <div
                  className={`col-md-6`}
                  style={{
                    position: "fixed",
                    left: "48vw",
                    top: "12vh",
                  }}
                  // style={{ marginTop: '10vh', position: 'fixed' }}
                >
                  <DocumentsView
                    fileId={match.params.id}
                    contractData={this.props.contractData}
                  />
                  {/* <div className="row">

                  <DocumentPdftronView fileId={match.params.id} />
                  </div> */}
                </div>
              )}
            <div
              className="row"
              style={{ marginRight: "0px", display: "none" }}
            >
              {/* <AppBar position="static"> */}
              {/* <Tabs
                value={tabValue}
                onChange={this.handleChangeTab}
                aria-label="Document Library"
                style={{ backgroundColor: "white", width: "100%" }}
              >
                <Tab disableRipple label="Processed File" />
                <Tab disableRipple label="Original File" />
              </Tabs> */}
              {/* </AppBar> */}
            </div>
            <br />
            {tabValue != null ? (
              <>
                <Scrollable
                  maxHeight={
                    window.screen.width < 1300
                      ? 650
                      : window.screen.width > 1300 && window.screen.width < 1500
                      ? 750
                      : 650
                  }
                >
                  <div style={{}}>
                    {clauseModeStatus ? (
                      <SentenceFrameRenderer
                        sentenceData={sentenceData}
                        sentenceLoader={sentenceLoader}
                        setClauseText={(selectedClauseText: string) =>
                          this.setState({
                            selectedClauseText: selectedClauseText,
                          })
                        }
                        showClauseModal={() => {
                          this.showClauseModal();
                        }}
                      />
                    ) : (
                      <>
                        <SentenceRenderer
                          sentenceData={sentenceData}
                          sentenceLoader={sentenceLoader}
                          editOptionSelected={editOptionSelected}
                          selectedInsightPoint={selectedInsightPoint}
                          fileId={fileId}
                          editDataPoint={editDataPoint}
                          insightsData={insightsData}
                        />
                        {/* {dataPoints.length > 1 && clickedState && (
                      <ScrollNavButton
                        dataPoints={dataPoints}
                        currentScrollIndex={currentScrollIndex}
                        type={type}
                        setCurrentScrollIndex={(currentScrollIndex: number) =>
                          this.setState({
                            currentScrollIndex: currentScrollIndex,
                          })
                        }
                        selectedInsightPoint={selectedInsightPoint}
                      />
                    )} */}
                      </>
                    )}
                  </div>
                </Scrollable>
              </>
            ) : (
              <>{/* <DocumentPdftronView fileId={match.params.id} /> */}</>
            )}

            {/* dataPoints.length > 1 && clickedState && <StickyNavButtons dataPoints={dataPoints} tempParaIndex={tempParaIndex}
                            getScrolledIndex={(scrolledChildIndex: number) => this.setState({ scrolledChildIndex: scrolledChildIndex })}
                            type={type} superImposedChildIndex={superImposedChildIndex} />} */}
          </div>
          {tabValue == 0 || tabValue == 1 || tabValue == 2 || tabValue == 3 ? (
            <>
              {scrollTop && (
                <div
                  className="go-to-top"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  <img src="/static_images/go-to-top.svg" alt="go-to-top" />
                  Top
                </div>
              )}
              <div
                className="col-md-6"
                // style={{ position: "fixed", left: "55vw", top: "10vh" }}
                style={{ position: "fixed", left: "48vw", top: "10vh" }}
              >
                <div className="row" style={{ height: 40 }}>
                  <div
                    id="tabs-container"
                    style={{
                      width: "-webkit-fill-available",
                      border: "unset",
                      boxSizing: "unset",
                      boxShadow: "unset",
                      background: "unset",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                      className="mt-2"
                    >
                      {/* <AppBar position="static"> */}
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChangeTab}
                        aria-label="Document Library"
                        style={{ backgroundColor: "white" }}
                        variant="fullWidth"
                      >
                        <Tab label="Insights" style={{ 
                          // maxWidth: "22%" 
                          }} />
                        <Tab label="Documents" style={{ 
                          // maxWidth: "22%"
                           }} />
                        <Tab label="Tasks" style={{ 
                          // maxWidth: "22%"
                           }} />
                        {/* <Tab label="Deviation from Policy" /> */}
                      </Tabs>
                      {/* </AppBar> */}
                      {/* <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChangeTab}
                        aria-label="Document Library"
                        style={{ backgroundColor: "white", }}
                        variant="fullWidth"
                      >
                        <Tab label="Insights" />
                        <Tab label="Documents" />
                        <Tab label="Tasks" />
                        <Tab label="Deviation from Policy" />
                      </Tabs> */}
                      {this.state.tabs.map((label, i) => (
                        <React.Fragment key={i}>
                          <div
                            className={
                              this.state.activeTabName === label
                                ? this.getTabStyle(label, true)
                                : this.getTabStyle(label, false)
                            }
                            id={"l" + i}
                            onClick={() => {
                              if (label === "Deviation from Policy") {
                                this.setState({
                                  windowMenu: {
                                    topMenu: true,
                                    selectedTopMenu: "processed",
                                    rightMenu: true,
                                    selectedRightMenu: "deviationPolicy",
                                  },
                                });
                              } else {
                                this.setState({
                                  windowMenu: {
                                    topMenu: true,
                                    selectedTopMenu: "processed",
                                    rightMenu: true,
                                    selectedRightMenu: "editInsights",
                                  },
                                });
                              }
                              this.setState({
                                activeTabName: label,
                              });
                            }}
                            style={{
                              borderBottomRightRadius:
                                label === "Deviation from Policy" ? "20px" : "",
                              borderTopRightRadius:
                                label === "Deviation from Policy" ? "20px" : "",
                              borderBottomLeftRadius:
                                label === "Insights" ? "20px" : "",
                              borderTopLeftRadius:
                                label === "Insights" ? "20px" : "",
                              border: "1px solid #e1e1e1",
                              boxSizing: "border-box",
                              boxShadow: "1px 2px 8px rgb(0 0 0 / 10%)",
                            }}
                          >
                            {label}
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                  {this.state.activeTabName == "Insights" ? (
                    <>
                      {/* <DocumentInsights
                        editLoader={editLoader}
                        editSuccessLoader={editSuccessLoader}
                        editFailureLoader={editFailureLoader}
                        sentenceLoader={sentenceLoader}
                        fileId={fileId}
                        insightsData={insightsData}
                        saveDataPoints={(
                          dataPoints: string[],
                          state: boolean,
                          tempParaIndex: number,
                          type: string
                        ) =>
                          this.setState({
                            dataPoints: dataPoints,
                            clickedState: state,
                            tempParaIndex: tempParaIndex,
                            type: type,
                          })
                        }
                        scrolledChildIndex={scrolledChildIndex}
                        superImposeChildIndex={(scrolledChildIndex: number) =>
                          this.setState({
                            superImposedChildIndex: scrolledChildIndex,
                          })
                        }
                        location={location}
                        history={history}
                        editOptionSelected={(editOptionSelected: boolean) =>
                          this.setState({
                            editOptionSelected: editOptionSelected,
                          })
                        }
                        storedBiPointDataModes={storedBiPointDataModes}
                        saveBiPointDataMode={(
                          storedBiPointDataModes: BiPointDataMode[]
                        ) => saveBiPointDataMode(storedBiPointDataModes)}
                        saveSelectedInsightPoint={(
                          selectedInsightPoint: string
                        ) =>
                          this.setState({
                            selectedInsightPoint: selectedInsightPoint,
                          })
                        }
                        setCurrentScrollIndex={(currentScrollIndex: number) =>
                          this.setState({
                            currentScrollIndex: currentScrollIndex,
                          })
                        }
                        currentScrollIndex={currentScrollIndex}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        fileEncoded={match.params.id}
                        setTaskPage={(hideTaskPage: boolean) =>
                          this.setState({
                            hideTaskPage: hideTaskPage,
                          })
                        }
                        getAllTasksData={getAllTasksData}
                        clauseModeStatus={clauseModeStatus}
                        setTaskOrigin={(origin: string) =>
                          this.setState({ taskOrigin: origin })
                        }
                        editDataPoint={editDataPoint}
                        saveInsightToDelete={saveInsightToDelete}
                        durationList={durationList}
                        currencyList={currencyList}
                      /> */}
                    </>
                  ) : (
                    <>
                      {/* <DeviationPolicy
                        editLoader={editLoader}
                        sentenceLoader={sentenceLoader}
                        fileId={fileId}
                        insightsData={insightsData}
                        saveDataPoints={(
                          dataPoints: string[],
                          state: boolean,
                          tempParaIndex: number,
                          type: string
                        ) =>
                          this.setState({
                            dataPoints: dataPoints,
                            clickedState: state,
                            tempParaIndex: tempParaIndex,
                            type: type,
                          })
                        }
                        scrolledChildIndex={scrolledChildIndex}
                        superImposeChildIndex={(scrolledChildIndex: number) =>
                          this.setState({
                            superImposedChildIndex: scrolledChildIndex,
                          })
                        }
                        location={location}
                        history={history}
                        editOptionSelected={(editOptionSelected: boolean) =>
                          this.setState({
                            editOptionSelected: editOptionSelected,
                          })
                        }
                        storedBiPointDataModes={storedBiPointDataModes}
                        saveBiPointDataMode={(
                          storedBiPointDataModes: BiPointDataMode[]
                        ) => saveBiPointDataMode(storedBiPointDataModes)}
                        saveSelectedInsightPoint={(
                          selectedInsightPoint: string
                        ) =>
                          this.setState({
                            selectedInsightPoint: selectedInsightPoint,
                          })
                        }
                        setCurrentScrollIndex={(currentScrollIndex: number) =>
                          this.setState({
                            currentScrollIndex: currentScrollIndex,
                          })
                        }
                        currentScrollIndex={currentScrollIndex}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        fileEncoded={match.params.id}
                        setTaskPage={(hideTaskPage: boolean) =>
                          this.setState({
                            hideTaskPage: hideTaskPage,
                          })
                        }
                        getAllTasksData={getAllTasksData}
                        clauseModeStatus={clauseModeStatus}
                        setTaskOrigin={(origin: string) =>
                          this.setState({ taskOrigin: origin })
                        }
                        editDataPoint={editDataPoint}
                        saveInsightToDelete={saveInsightToDelete}
                        durationList={durationList}
                        currencyList={currencyList}
                        deviationpolicyData={deviationpolicyData}
                        approvalData={approvalData}
                      ></DeviationPolicy> */}
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          <div style={{ display: "none" }}>
            <RightMenu
              windowMenu={this.state.windowMenu}
              handleWindowMenu={this.handleWindowMenu}
            />
          </div>
          {/* ___end___ */}
        </div>

        {this.state.hideclauseErrorPage ? <RequestTagModal /> : ""}

        <TaskModal
          fileID={fileId}
          editMode={editMode}
          currentEditTaskData={currentEditTaskData}
          origin={taskOrigin}
        />
        <button
          style={{ display: "none" }}
          type="button"
          data-toggle="modal"
          data-target="#taskModal"
          id="taskModalButton"
        ></button>
        <TaskStatusModal editMode={editMode} />
        

        <button
          style={{ display: "none" }}
          type="button"
          data-toggle="modal"
          data-target="#taskStatusModal"
          id="taskStatusModalButton"
        ></button>
        <ViewFileHierarchyModal
          currentFileId={fileId}
          documentTree={documentTree}
          saveDocumentTree={saveDocumentTree}
        />
        {this.state.showClauseModal ? (
          <CreateClauseModal
            selectedClauseText={selectedClauseText}
            currentFileId={fileId}
            closeModal={(type: string) => {
              this.setState({ showClauseModal: false });
              let { clauseModeStatus } = this.props;
              this.props.saveClauseModeStatus(!clauseModeStatus);
              if (type == "create-clause") {
                this.setState({ clauseLibraryLoader: true, create_clause: 1 });
              } else {
                this.setState({ clauseLibraryLoader: false, create_clause: 0 });
              }
            }}
            projectData={this.state.projectData}
          />
        ) : null}

        {match.params.page == "tasks" ? null : (
          <ClauseErrorModal editMode={editMode} fileID={fileId} />
        )}
        <DeviationPolicyModal />
        {/* <CustomizedSnackbar isSuccess={true} isOpen={true} /> */}
      </>
    );
  }
}

const notifyClauseSuccess = () => toast.success("Clause Library added Successfully", {position: "bottom-center",
iconTheme: {
  primary: '#ffef5a',
  secondary: '#FFFAEE',
},
style: {
  zIndex:"0",
}
});

const notifyClauseError = () => toast.error("Oops! Something went wrong...", {position: "bottom-center", iconTheme: {
  primary: '#ffef5a',
  secondary: '#FFFAEE',
},
style: {
  zIndex:"0",
}});
