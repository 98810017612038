import { Snackbar, Tooltip } from '@material-ui/core';
import React, { useState } from 'react'
import HeimdallUtil from '../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil';
import "../../Draft/Design/draft.scss";
import { ContractRes } from '../../Draft/State/DraftState';
import PdfViewer from '../../Draft/Component/PdfViewerDialog';

interface Props {
    draftContractData: ContractRes[];
    getDrafts: () => void;
}
const ListViewTable = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [draftLink, setDraftLink] = useState("");
    const [fileName, setFileName] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const { draftContractData } = props;    
    const [draftDetails, setdraftDetails] = useState<ContractRes>();

    const openDocViewer = (draft: ContractRes) => {
        // if (draft.link && draft.link !== '') {
        //     setDraftLink(draft.link);
        //     setFileName(draft.contractName);
        //     setOpen(true);
        // } else { setOpenSnackBar(true) }
    };

    const handleClose = () => {
        // setOpen(false)
    }

    const handleSnackBarClose = () => {
        // setOpenSnackBar(false);
    }

    return (
        <>
            {draftContractData.map((draftContract: ContractRes, key: number) =>
                <div onClick={() => openDocViewer(draftContract)} key={key} className="template-list-view col-md-12 hover mb-2" style={{ backgroundColor: key % 2 != 0 ? 'white' : 'rgba(0,0,0,.04)' }}>
                    <div className="row template-list-view-table">
                        <div className="col-md-2">
                            <Tooltip title={draftContract.projectNames} arrow>
                                <div className='text-overflow text-purple'>
                                    {
                                        (draftContract.projectNames.length == 0) && <img src="/static_images/empty-dash.svg"></img>
                                    }
                                    {draftContract.projectNames.length > 0 && draftContract.projectNames[0]}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-md-2">
                            <Tooltip title={draftContract.contractType} arrow>
                                <div className='text-overflow'>
                                    {(draftContract.contractType === null || draftContract.contractType === '') ? <img src="/static_images/empty-dash.svg"></img> : draftContract.contractType}
                                </div>
                            </Tooltip>
                        </div>
                       
                        <div className="col-md-1">
                            <div className='text-overflow'>
                                {draftContract.created_by != null &&
                                    <div key={key} className="avatar-circle-sm" style={{ marginLeft: '8px' }} >
                                        <span className="initials">{HeimdallUtil.getNameForAvatar(draftContract.created_by)}</span>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-md-1" style={{
                            flex: '0 0 9%',
                            maxWidth: '9%',
                            padding: '0'
                        }}>
                            <div>
                                {(draftContract.created_on === null || draftContract.created_on === '') ? <img src="/static_images/empty-dash.svg"></img> : draftContract.created_on}
                            </div>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: '10px' }}>
                            <Tooltip title={draftContract.source} arrow>
                                <div className='text-overflow'>
                                    {draftContract.source}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-md-2" style={{ paddingLeft: "10px", 'flex': '0 0 16%' }}>
                            <div>
                                {(draftContract.status === null || draftContract.status === '') ? <img src="/static_images/empty-dash.svg"></img> : draftContract.status}
                            </div>
                        </div>
                        <div className="col-md-1" style={{ padding: '0' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingRight: '25px' }}>
                                {draftContract.collaborators.map((user, index) => (
                                    <div key={index} className="avatar-circle-sm" style={{ marginLeft: '8px' }} >
                                        <span className="initials">{HeimdallUtil.getNameForAvatar(user)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>            
                    </div>
                </div>
            )}
            {open && <PdfViewer open={open} onClose={handleClose}  fileName={fileName} draftDetails={draftDetails}  />}
            <Snackbar
                open={openSnackBar}
                autoHideDuration={300}
                message="Draft link not present"
                onClose={handleSnackBarClose}
            />
        </>
    )
}

export default ListViewTable

