import React, { Fragment, useEffect, useState } from "react";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import { FilterData, Sort, TemplateData } from "../State/templateState";
import ListViewHeader from "./listViewHeader";
import ListViewTable from "./ListViewTable";
import { History } from "history";
import PageNavigation from "../../UniversalComponents/PageNavigation/Component/PageNavigation";
interface Props {
  templateData: TemplateData[];
  getTemplates: () => void;
  history: History;
  selectMultipleTemplates: (id: string) => void;
  selectedTemplate: any[];
  isSupportTemplate: boolean;
  selectAllTemplate: (isContract: boolean) => void;
  updatePageNumber: (page: number) => void;
  pageNumber: number;
  fieldNameList: any;
  totalCount:number;
}
const ListView = (props: Props) => {
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState<TemplateData[]>([]);
  const [filterKeyVals, setFilterKeyVals] = useState<FilterData>({});
  const [contractTypeList, setcontractTypeList] = useState<any[]>([]);
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  const [tags, settags] = useState<any[]>([]);
  const [userList, setuserList] = useState<string[]>([]);

  useEffect(() => {
    if (props.templateData.length > 0) {
      let contractTypeList = props.templateData
        .map((con) => con.templateType)
        .filter((data) => {
          if (data) return data;
        });
      let tags = props.templateData
        .flatMap((con) => con.tags)
        .map((tag) => tag.tag_name)
        .filter((data) => {
          if (data) return data;
        });
      setcontractTypeList(Array.from(new Set(contractTypeList)));
      settags(Array.from(new Set(tags)));
      getUserList();
    }
  }, [props.templateData.length]);

  useEffect(() => {
    if (Object.values(filterKeyVals).length > 0) {
      setFiter(props.templateData);
    } else if (sortData.sortKey) {
      setSorting(props.templateData);
    } else setFilterData(props.templateData);
  }, [props.templateData]);

  useEffect(() => {
    setSorting(props.templateData);
  }, [sortData]);

  useEffect(() => {
    setFiter(props.templateData);
  }, [filterKeyVals]);

  const getUserList = () => {
    if (props.templateData.length) {
      let users = Array.from(
        new Set(
          props.templateData.map((data: any) => {
            return data?.owner?.first_name + " " + data?.owner?.last_name;
          })
        )
      );
      setuserList([...users]);
    }
  };

  const setSorting = (templates: TemplateData[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...templates];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "number") {
          if (sortData.sortType == "asc") {
            return a[sortData.sortKey] - b[sortData.sortKey];
          }
          if (sortData.sortType == "desc") {
            return b[sortData.sortKey] - a[sortData.sortKey];
          }
        }
        if (sortData.valueType == "string" && sortData.sortType == "asc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return +1;
          } else return -1;
        }
        if (sortData.valueType == "string" && sortData.sortType == "desc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return -1;
          } else return +1;
        }
        if (sortData.valueType == "date") {
          if (sortData.sortType == "asc") {
            return (
              new Date(a[sortData.sortKey]).getTime() -
              new Date(b[sortData.sortKey]).getTime()
            );
          }
          if (sortData.sortType == "desc") {
            return (
              new Date(b[sortData.sortKey]).getTime() -
              new Date(a[sortData.sortKey]).getTime()
            );
          }
        }
        return sorted;
      });
      setFilterData([...filteredList]);
    }
  };

  const setFiter = (templates: any[]) => {
    if (Object.values(filterKeyVals).length > 0) {
      let filteredList = [...templates];
      Object.keys(filterKeyVals).forEach((data) => {
        const detail: any = filteredList.find((a: any) => a[data]);
        const searchKey = filterKeyVals[data];
        if (searchKey.length) {
          if (typeof detail?.[data] === "string") {
            filteredList = filteredList.filter((a: any) =>
              searchKey.includes(a[data].toLowerCase())
            );
          } else if (data == "uploadedBy") {
            const firstName = filterKeyVals[data].map((filter) =>
              filter.split(" ")[0].toLowerCase()
            );
            const LastName = filterKeyVals[data].map((filter) =>
              filter.split(" ")[1].toLowerCase()
            );
            filteredList = filteredList.filter((dd) => {
              return (
                firstName.includes(dd.owner.first_name.toLowerCase()) &&
                LastName.includes(dd.owner.last_name.toLowerCase())
              );
            });
          } else {
            filteredList = filteredList.filter((a: any) => {
              let isAvailable = a[data].filter((projectName: any) => {
                return searchKey.includes(projectName.tag_name.toLowerCase());
              });
              if (isAvailable.length > 0) {
                return true;
              } else return false;
            });
          }
        }
      });
      setFilterData(filteredList);
    }
  };

  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
  };

  const applyFilter = (searchKey: string[], filterKey: string,removeKey?:string) => {
    let searchKeys = [...searchKey]
    if(removeKey){
      let removeIndex = filterKeyVals[filterKey].findIndex((data) => data === removeKey);
      if(removeIndex || removeIndex>-1){
        let filterKeys = [...filterKeyVals[filterKey]];
        filterKeys.splice(removeIndex,1);
        searchKeys = [...filterKeys];
      }
    }
    setFilterKeyVals((prev) => ({
      ...prev,
      [filterKey]: searchKeys,
    }));
  };

  // return (
  //   <div className="col-md-12 p-0" style={{}}>
  //     <div
  //       className="row"
  //       style={{
  //         borderBottom: "1px solid #E1E1E1",
  //         marginLeft: "0rem",
  //         marginRight: "0rem",
  //         backgroundColor: "white",
  //       }}
  //     >
  //       <ListViewHeader
  //         totalCount={props.templateData.length}
  //         selectedTemplate={props.selectedTemplate}
  //         tags={tags}
  //         selectAllTemplate={props.selectAllTemplate}
  //         selectMultipleTemplates={props.selectMultipleTemplates}
  //         filterKeyVals={filterKeyVals}
  //         applyFilter={applyFilter}
  //         contractTypes={contractTypeList}
  //         isSupportTemplate={props.isSupportTemplate}
  //         applySorting={applySort}
  //       />
  //       <ListViewHeader
  //         userList={userList}
  //         totalCount={props.templateData.length}
  //         selectedTemplate={props.selectedTemplate}
  //         tags={tags}
  //         selectAllTemplate={props.selectAllTemplate}
  //         selectMultipleTemplates={props.selectMultipleTemplates}
  //         filterKeyVals={filterKeyVals}
  //         applyFilter={applyFilter}
  //         contractTypes={contractTypeList}
  //         isSupportTemplate={props.isSupportTemplate}
  //         applySorting={applySort}
  //       />
  //     </div>
  //   </div>
  // );
  return (
    <>
      <div className="col-md-12 p-0" style={{}}>
        <div
          className="row"
          style={{
            borderBottom: "1px solid #E1E1E1",
            marginLeft: "0rem",
            marginRight: "0rem",
            backgroundColor: "white",
          }}
        >
          <ListViewHeader
            userList={userList}
            totalCount={props.templateData.length}
            selectedTemplate={props.selectedTemplate}
            tags={tags}
            selectAllTemplate={props.selectAllTemplate}
            selectMultipleTemplates={props.selectMultipleTemplates}
            filterKeyVals={filterKeyVals}
            applyFilter={applyFilter}
            contractTypes={contractTypeList}
            isSupportTemplate={props.isSupportTemplate}
            applySorting={applySort}
          />
        </div>
        <Scrollable maxHeight={420}>
          <div className="row m-0">
            {loader === true ? (
              <BarLoader />
            ) : (
              <>
                <ListViewTable
                  fieldNameList={props.fieldNameList}
                  filterKeyVals={filterKeyVals}
                  applyFilter={applyFilter}
                  selectMultipleTemplates={props.selectMultipleTemplates}
                  selectedTemplate={props.selectedTemplate}
                  history={props.history}
                  templatesData={filterData}
                  getTemplates={props.getTemplates}
                />
                <div className="col-md-12">
                  <PageNavigation
                    pageSize={10}
                    updatePageNumber={props.updatePageNumber}
                    totalCount={props.totalCount}
                    pageNumber={props.pageNumber}
                  />
                </div>
              </>
            )}
          </div>
        </Scrollable>
      </div>
    </>
  );
};

export default ListView;
