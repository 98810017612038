import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Drawer, Snackbar } from "@material-ui/core";
import { BsArrowLeft } from "react-icons/bs";
import { IconContext } from "react-icons";
import { AiOutlineReload, AiOutlineEdit } from "react-icons/ai";
import { BsTrash, BsDownload } from "react-icons/bs";
import {
  getCompareVersion,
  getdraftbyid,
  getreqbyidbyid,
  getRequisitionVersionHistory,
  getVersionHistory,
  uploadCompareVersion,
  uploadSupportDoc,
} from "../DA";
import { ContractRes, draftStatus, Sort } from "../State/DraftState";
import UploadFileDialog from "./UploadFileDialog";
import Avatar from "../../UniversalComponents/Avatar/Component/Avatar";
import DeleteDraftDialog from "./DeleteDraftDialog";
import saveAs from "file-saver";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import ForApproval from "./ForApproval";
import { constants } from "buffer";
import PdfViewer from "./PdfViewerDialog";
import Shareversionmodal from "./ShareVersionModal";
import ShareSuccessModal from "./ShareSuccessModal";
interface Props {
  open: boolean;
  onClose: () => void;
  selectedDraft: any | null;
  getContractDraft: () => void;
  uploadContract: (contract: any) => Promise<any> | undefined;
  setComparisionDrawer: any;
  selectedDraftsLink: any;
  setselectedDraftsLink: any;
  setTemplate: any;
  getDrafts: any;
  setCompareFileName: any;
  setselectedDraft: any;
  setshowVersionHistory: any;
}
const VersionHistory = (props: Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [openUploadNew, setopenUploadNew] = useState(false);
  const [contractVersions, setcontractVersions] = useState<ContractRes[]>([]);
  const [selectedDrafts, setselectedDrafts] = useState<string[]>([]);
  const [selectedComparedDrafts, setselectedComparedDrafts] = useState<any>([]);
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDrafts, setdeleteDrafts] = useState<ContractRes[]>([]);
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [draftId, setDraftID] = useState("");
  const [latestVersion, setlatestVersion] = useState(0);
  const [openSupportDoc, setopenSupportDoc] = useState(false);
  const [openapproval, setopenapproval] = useState(false);
  const [fileData, setFileData] = useState("");
  const [requisitionId, setRequisitionId] = useState<any>("");
  const [version, setversion] = useState<any>("");
  const [reqresponse, setreqresponse] = useState<any[]>([]);
  const [contractData, setcontractData] = useState<any>({});
  const [openpdfviewr, setOpenpdfviewr] = useState(false);
  const [draftLink, setDraftLink] = useState("");
  const [draftContracts, setDraftContracts] = useState<ContractRes[]>([]);
  const [fileName, setFileName] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [draftDetails, setdraftDetails] = useState<ContractRes>();
  const [selectedDraft, setSelectedDraft] = useState<any>([]);
  const [totalVersion, setTotalVersion] = useState<any>(0);

  const [latestDraftDetails, setlatestDraftDetails] = useState<ContractRes>();
  const [isInternal, setisinternal] = useState<any>(true);
  const [openModal, setopenModal] = useState<any>("");
  const [openSuccessShare, setOpenSuccessShare] = useState<boolean>(false);
  const [versiondata, setversiondata] = useState<any>([]);
  const handleClose = () => {
    setopen(false);
    props.onClose();
  };
  const [show, setShow] = useState(true);
  useEffect(() => {}, [selectedDraft, totalVersion]);

  const handleisinternal = (value: any) => {
    setisinternal(!isInternal);
  };
  const setSorting = (contracts: any[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...contracts];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "number") {
          if (sortData.sortType == "asc") {
            return a[sortData.sortKey] - b[sortData.sortKey];
          }
          if (sortData.sortType == "desc") {
            return b[sortData.sortKey] - a[sortData.sortKey];
          }
        }
        if (sortData.valueType == "string" && sortData.sortType == "asc") {
          if (
            a[sortData.sortKey].toLowerCase() ===
            b[sortData.sortKey].toLowerCase()
          ) {
            return 0;
          }
          if (
            a[sortData.sortKey].toLowerCase() >
            b[sortData.sortKey].toLowerCase()
          ) {
            return +1;
          } else return -1;
        }
        if (sortData.valueType == "string" && sortData.sortType == "desc") {
          if (
            a[sortData.sortKey].toLowerCase() ===
            b[sortData.sortKey].toLowerCase()
          ) {
            return 0;
          }
          if (
            a[sortData.sortKey].toLowerCase() >
            b[sortData.sortKey].toLowerCase()
          ) {
            return -1;
          } else return +1;
        }
        if (sortData.valueType == "date") {
          if (sortData.sortType == "asc") {
            return (
              new Date(a[sortData.sortKey]).getTime() -
              new Date(b[sortData.sortKey]).getTime()
            );
          }
          if (sortData.sortType == "desc") {
            return (
              new Date(b[sortData.sortKey]).getTime() -
              new Date(a[sortData.sortKey]).getTime()
            );
          }
        }
        return sorted;
      });
      setcontractVersions([...filteredList]);
    }
  };

  const handleCloseviewer = () => {
    props.getContractDraft();
    setOpenpdfviewr(false);
  };
  const openDocViewer = (draft: ContractRes) => {
    // if(draft.status == 'Draft Saved'){
    //   props.openDocViewerDialog(draft);
    // } else {
    if (draft.link && draft.link != "") {
      setdraftDetails(draft);
      setDraftLink(draft.access_url);
      setFileName(draft.contractName);
      setOpenpdfviewr(true);
    } else {
      setOpenSnackBar(true);
    }
    // }
  };

  useEffect(() => {
    if (props.selectedDraft && props.selectedDraft.id) {
      setDraftID(props.selectedDraft.id);
      getVersions();
    }
    setopen(props.open);
    return () => {
      setcontractVersions([]);
    };
  }, [props.selectedDraft, props.open]);

  useEffect(() => {
    setSorting(contractVersions);
  }, [sortData]);

  useEffect(() => {
    if (contractVersions && contractVersions.length) {
      let latestVersion = -1;
      let latestDraft = undefined;
      contractVersions.forEach((value) => {
        if (latestVersion < value.version) {
          latestVersion = value.version;
          latestDraft = { ...value };
        }
      });
      setlatestDraftDetails(latestDraft);
    }
    return () => {
      setlatestDraftDetails(undefined);
    };
  }, [contractVersions]);

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const getVersions = async () => {
    try {
      let version = 0;
      setloading(true);
      if (props.selectedDraft) {
        const response = await getVersionHistory(
          props.selectedDraft.draftID || props.selectedDraft.id
        );
        if (response && response.results && response.results.length > 0) {
          if (response.results[response.results.length - 1]?.requisition_id) {
            const requisionResponse = await getRequisitionVersionHistory(
              response.results[response.results.length - 1]?.requisition_id
            );
            const data = await getCompareVersion(
              props.selectedDraft.draftID || props.selectedDraft.id
            );
            if (data && data.results) {
              setcontractVersions([
                ...requisionResponse.results,
                ...response.results,
                ...data.results,
              ]);

              setTotalVersion(
                requisionResponse.results.length + response.results.length
              );
              setSortData({
                sortKey: "version",
                sortType: "asc",
                valueType: "number",
              });
              setRequisitionId(requisionResponse?.results[0]?.id);
            }
          } else {
            setcontractVersions([...response.results]);
            setSortData({
              sortKey: "version",
              sortType: "asc",
              valueType: "number",
            });
          }

          response.results.forEach((element: any) => {
            if (element.version > version) {
              version = element.version;
            }
          });
          setlatestVersion(version);
        } else {
          const requisionResponse = await getRequisitionVersionHistory(
            props.selectedDraft.id
          );
          if (requisionResponse && requisionResponse.results) {
            setcontractVersions([...requisionResponse.results]);

            setTotalVersion(
              requisionResponse.results.length + response.results.length
            );
            // applySort("version", "number");
            setSortData({
              sortKey: "version",
              sortType: "asc",
              valueType: "number",
            });
            setRequisitionId(requisionResponse?.results[0]?.id);
          }
        }
      } else {
        const requisionResponse = await getRequisitionVersionHistory(
          props.selectedDraft.id
        );
        setcontractVersions([...requisionResponse.results]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const handleCloseEditDialog = () => {
    setopenUploadNew(false);
  };

  const handleCloseSupportDialog = () => {
    setopenSupportDoc(false);
  };

  const openCreateDraftDialog = () => {
    if (
      latestDraftDetails?.createFrom == "template" &&
      (latestDraftDetails?.status.toLowerCase() == "draft creation pending" ||
        latestDraftDetails?.version == 0)
    ) {
      setopenSnackbar({
        open: true,
        msg: "Version should not be uploaded until draft generation from template is approved",
      });
      return;
    } else if (
      (latestDraftDetails?.createFrom === "counter_party" ||
        latestDraftDetails?.createFrom === "support_document") &&
      latestDraftDetails.status === "Requisition Approval Pending"
    ) {
      setopenSnackbar({
        open: true,
        msg: "Version should not be uploaded until draft generation from counter party or requisitiondraft is approved",
      });
      return;
    }
    setopenUploadNew(true);
  };

  const selectMultipleDrafts = (id: string) => {
    let selected = [...selectedDrafts];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedDrafts([...selected]);
  };

  const selectComparedDrafts = (id: string) => {
    let selected = [...selectedComparedDrafts];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedComparedDrafts([...selected]);
  };

  const selectDraft = (data: any) => {
    let selected = [...selectedDraft];
    let index = selected.findIndex((x) => x.id === data.id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(data);
    }
    setSelectedDraft([...selected]);
  };

  const selectMultipleDraftsLink = (link: string) => {
    let selected = [...props.selectedDraftsLink];
    let index = selected.indexOf(link);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(link);
    }
    props.setselectedDraftsLink([...selected]);
  };

  useEffect(() => {}, [
    props.selectedDraftsLink,
    selectedComparedDrafts,
    contractVersions,
  ]);
  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
  };

  const handleOpenDeleteDialog = () => {
    if (selectedComparedDrafts.length > 0) {
      let deleteDrafts = selectedComparedDrafts.reduce(
        (acc: any, curr: any) => {
          let drafts = contractVersions.find((value) => value.id == curr);
          if (drafts) {
            acc = [...acc, drafts];
          }
          return acc;
        },
        []
      );
      let versions = deleteDrafts.map((draft: any) => draft.version);
      if (versions.length && versions.includes(1)) {
        setopenSnackbar({ open: true, msg: "V1 draft cannot be deleted." });
        return;
      }
      setdeleteDrafts(deleteDrafts);
    } else {
      let deleteDrafts = selectedDrafts.reduce<ContractRes[]>((acc, curr) => {
        let drafts = contractVersions.find((value) => value.id == curr);
        if (drafts) {
          acc = [...acc, drafts];
        }
        return acc;
      }, []);
      let versions = deleteDrafts.map((draft) => draft.version);
      if (versions.length && versions.includes(1)) {
        setopenSnackbar({ open: true, msg: "V1 draft cannot be deleted." });
        return;
      }
      setdeleteDrafts(deleteDrafts);
    }
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setselectedDrafts([]);
  };

  const download = async () => {
    let drafts = selectedDrafts.reduce<ContractRes[]>((acc, curr) => {
      let drafts = contractVersions.find((value) => value.id == curr);
      if (drafts) {
        acc = [...acc, drafts];
      }
      return acc;
    }, []);
    if (drafts[0].version === 0) {
      const response = await getreqbyidbyid(drafts[0]?.id);
      if (response) {
        let name = response.access_url.split("/").pop();
        saveAs(response.access_url, name);
      }
    } else {
      const response = await getdraftbyid(drafts[0]?.id);
      // if (response) {
      //   let name = response.access_url.split("/").pop();
      //   saveAs(response.access_url, name);
      // }
      if (response) {
        let blob = fetch(response.access_url).then((r) => {
          r.blob();
          saveAs(
            response.access_url,
            `${response.contractName}-(V${response.version}).docx`
          );
        });
      }
    }

    // if (drafts.length == 1 && drafts[0].link) {
    //   let name = drafts[0].link.split("/").pop();
    //   saveAs(drafts[0].link, name);
    // } else {
    //   let name = drafts[0].requisition_approver_link.split("/").pop();
    //   saveAs(drafts[0].requisition_approver_link, name);
    // }
  };
  const shareversion = () => {
    let drafts = selectedDrafts.reduce<ContractRes[]>((acc, curr) => {
      let drafts = contractVersions.find((value) => value.id == curr);
      if (drafts) {
        acc = [...acc, drafts];
      }
      return acc;
    }, []);
    setversiondata(drafts);
    if (drafts.length == 1 && drafts[0].link) {
      setopenModal("modalShare");
    } else {
      setopenModal("modalShare");
    }
  };
  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });
  };

  const uploadNewVersionDraft = async (preSignedUrl: any) => {
    try {
      if (props.selectedDraft) {
        setloading(true);
        let contractData = { ...props.selectedDraft };
        if (preSignedUrl && preSignedUrl.file_path) {
          contractData.link = preSignedUrl.file_path;
        }
        contractData.version = latestVersion + 1;
        if (contractData.draftID == null) {
          contractData.draftID = draftId;
        }
        if (
          props.selectedDraft &&
          props.selectedDraft.approvalTypes.length > 0
        ) {
          contractData.approvalTypes = props.selectedDraft.approvalTypes;
        }
        if (
          props.selectedDraft &&
          props.selectedDraft.collaborators.length > 0
        ) {
          contractData.collaborators = props.selectedDraft.collaborators.map(
            (user: any) => user?.id
          );
        }
        if (props.selectedDraft && props.selectedDraft.signatories.length > 0) {
          contractData.signatories = props.selectedDraft?.signatories?.map(
            (user: any) => user
          );
        }
        const user = getLocalStorage("user_profile");
        let contract: any = {
          ...contractData,
          owner: props?.selectedDraft?.owner?.id
            ? [props?.selectedDraft?.owner?.id]
            : [user?.id],
          requisition_id:
            props.selectedDraft.version === 0
              ? props.selectedDraft.id
              : props.selectedDraft.requisition_id,
          draftID:
            props.selectedDraft.version === 0
              ? null
              : props.selectedDraft.version == 1
              ? props.selectedDraft.id
              : props.selectedDraft.draftID,
          status: "Internal Draft Created",
          contractType: props.selectedDraft?.contractType
            ? props.selectedDraft?.contractType?.id
            : null,
          logs: {
            action: `${
              isInternal ? "Internal Draft Uploaded" : "External Draft Uploaded"
            }`,
            action_on: [user.id],
          },
        };
        if (show == true) {
          contract["is_internal"] = isInternal;
        }
        delete contract["id"];
        delete contract["created_by"];
        const response = await props.uploadContract(contract);

        if (response) {
          if (props.selectedDraft.version === 0) {
            axios.patch(
              process.env.REACT_APP_RIVERUS_CLM_API +
                `stylus/requisition_form/${props?.selectedDraft?.id}/`,
              {
                draft_created: true,
              },
              APIConfig()
            );
          }
          props.setselectedDraft(response.data);

          // props.getDrafts();
          setTimeout(() => {
            getVersions();
          }, 500);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const uploadSupportDocument = async (preSignedUrl: any) => {
    try {
      setloading(true);
      let payload = {
        file_name: preSignedUrl.fields.key,
        upload_type: "support_document",
        link: preSignedUrl.file_path,
        ref_draft: [props.selectedDraft?.draftID || props.selectedDraft?.id],
        document_type: preSignedUrl.type,
      };
      const response = await uploadSupportDoc(payload);
      if (response) {
        setTimeout(() => {
          getVersions();
        }, 500);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const openSupportDocDialog = () => {
    setopenSupportDoc(true);
  };

  const handleSelectAllDrafts = () => {
    if (selectedDrafts.length > 0) {
      setselectedDrafts([]);
      return;
    }
    let selected: string[] = [];
    selected = contractVersions.map((draft) => draft.id);
    setselectedDrafts([...selected]);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );
  const handleCloseShareBtn = () => {
    setopenModal("");
  };

  const isOwner = (clickedDraft: any) => {
    const user_data = getLocalStorage("user_profile");
    if (clickedDraft?.owner.id === user_data.id) {
      return true;
    }
    return false;
  };

  const isAllSigned = (contract: any) => {
    let allSigned = true;
    if (contract.signatories && contract.signatories.length) {
      contract.signatories.forEach((data: any) => {
        if (!data.isSigned) {
          allSigned = false;
        }
      });
    } else allSigned = false;
    return allSigned;
  };
  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <div className="container version-history">
        {loading && <CircularProgress className="loader" />}
        <div className="row align-items-center">
          <div className="col-md-4 df align-items-center">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft onClick={handleClose} />
            </IconContext.Provider>
            <p className="m-0 ml-3 fw">Version History</p>
          </div>
          <div className="col-md-8 text-align-end">
            <button
              type="button"
              disabled={
                props.selectedDraft.createFrom === "template"
                  ? props.selectedDraft.version === 0 ||
                    (props.selectedDraft.version === 1 &&
                      props.selectedDraft.status ===
                        draftStatus.DRAFT_CREATION_PENDING)
                    ? true
                    : false
                  : false
              }
              onClick={openCreateDraftDialog}
              className={`btn btn-warning create-btn ${
                props.selectedDraft.createFrom === "template"
                  ? props.selectedDraft.version === 0 ||
                    (props.selectedDraft.version === 1 &&
                      props.selectedDraft.status ===
                        draftStatus.DRAFT_CREATION_PENDING)
                    ? "disabled-buttons"
                    : ""
                  : ""
              }`}
            >
              Upload New
            </button>
          </div>
        </div>
        <div className="version-history__info mt-3 mb-3">
          <p className="fw">What is Version History ?</p>
          <p className="c-grey">
            Use Version History to upload a new version, compare previous
            versions and download previous and current versions.
          </p>
          <p className="c-grey">
            To compare two versions, start by selecting any two versions below
            and use the{" "}
            <span>
              <img src="/static_images/compare-icon.svg" alt="compare" />
            </span>{" "}
            icon to compare.
          </p>
        </div>
        <p className="c-grey">TOTAL VERSIONS: {totalVersion}</p>
        <div className="row justify-content-between" style={{ height: "33px" }}>
          <div className="col-md-8">
            <div
              className="row mt-1 mb-1 df align-items-center"
              style={{ marginLeft: "0" }}
            >
              <IconContext.Provider
                value={{
                  color: "#88305F",
                  className: "reload-icon cursor-pointer",
                }}
              >
                <AiOutlineReload onClick={getVersions} />
              </IconContext.Provider>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {selectedDraft.length === 2 && (
                <img
                  className="cp"
                  src="/static_images/compare-icon.svg"
                  alt="compare"
                  onClick={async () => {
                    const higherDraft = selectedDraft?.filter(
                      (item: any, index: any) => {
                        if (item[index] > item[index + 1]) {
                          return item.draftID;
                        } else {
                          return item.draftID;
                        }
                      }
                    );
                    const name1 = `V${selectedDraft[0]?.version}-${selectedDraft[0]?.contractName}`;
                    const name2 = `V${selectedDraft[1]?.version}-${selectedDraft[1]?.contractName}`;
                    const payload = {
                      draftID: higherDraft[0].draftID,
                      version: higherDraft[0].version,
                      compare_versions: {
                        data: selectedDraft,
                        visible_version: `V${selectedDraft[0].version}/V${selectedDraft[1].version}`,
                      },
                    };
                    await uploadCompareVersion(payload);
                    props.setCompareFileName([name1, name2]);
                    props.setComparisionDrawer(true);
                  }}
                />
              )}
              {selectedDrafts.length === 1 && (
                <img
                  src={"/static_images/Icon Library (2).svg"}
                  alt=""
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "10px ",
                    cursor: "pointer",
                  }}
                  onClick={
                    () => shareversion()
                    // this.setState({ openModal: "modalShare" })
                  }
                />
              )}
              {selectedDrafts.length == 1 && (
                <IconContext.Provider
                  value={{
                    color: "#88305F",
                    className: "svg-delete-icon cp",
                    style: { strokeWidth: "0.5" },
                  }}
                >
                  <BsDownload onClick={download} />
                </IconContext.Provider>
              )}
              {(selectedComparedDrafts.length > 0 ||
                selectedDrafts.length > 0) && (
                <IconContext.Provider
                  value={{
                    color: "#FF685F",
                    className: "svg-delete-icon cp",
                  }}
                >
                  <BsTrash onClick={handleOpenDeleteDialog} />
                </IconContext.Provider>
              )}
            </div>
          </div>
        </div>
        <div className="row list-view-header version-history__table-header">
          <div
            className="col-md-1 df align-items-center"
            style={{ maxWidth: "6%" }}
          >
            {(selectedDrafts.length == 0 ||
              selectedDrafts.length < totalVersion) && (
              <input
                onChange={(e) => handleSelectAllDrafts()}
                style={{ width: "15px", height: "15px" }}
                type="checkbox"
                name="check"
                id="check"
              />
            )}
            {selectedDrafts.length > 0 &&
              selectedDrafts.length == totalVersion && (
                <img
                  onClick={() => handleSelectAllDrafts()}
                  className="checked-icon"
                  style={{ top: "0" }}
                  src="/static_images/checked_icon.svg"
                  alt="checked"
                ></img>
              )}
          </div>
          <div
            className="col-md-1"
            style={{ maxWidth: "13%", flex: "0 0 13%" }}
          >
            Version
            <span>
              <img
                onClick={() => applySort("version", "number")}
                src="static_images/number_filter_inactive.svg"
              />
            </span>
          </div>
          <div
            className="col-md-2"
            style={{ maxWidth: "20%", flex: "0 0 20%" }}
          >
            Created Date{" "}
            <span>
              <img
                onClick={() => applySort("created_on", "date")}
                src="static_images/calender_filter_inactive.svg"
              />
            </span>
          </div>
          <div className="col-md-4" style={{ flex: "0 0 27%" }}>
            Created/Uploaded by
          </div>
          <div className="col-md-4">Deviation From Policy</div>
        </div>
        {contractVersions.length > 0 &&
          contractVersions.map((contract: any, index: any) => (
            <div
              key={index}
              className={`row version-history__table-list ${
                selectedDrafts.indexOf(contract.id) > -1 ? "selected-draft" : ""
              }`}
              style={{ backgroundColor: index % 2 != 0 ? "white" : "#FAFAFA" }}
            >
              {contract?.compare_versions ? (
                <div
                  className="col-md-1 df align-items-center"
                  style={{ maxWidth: "6%", padding: "0 10px" }}
                >
                  {selectedComparedDrafts.indexOf(contract.id) == -1 && (
                    <input
                      style={{ width: "15px", height: "15px" }}
                      onClick={() => {
                        selectComparedDrafts(contract.id);
                      }}
                      type="checkbox"
                      name="check"
                      id="check"
                    />
                  )}
                  {selectedComparedDrafts.indexOf(contract.id) > -1 && (
                    <img
                      className="checked-icon"
                      style={{ top: "0" }}
                      onClick={() => {
                        selectComparedDrafts(contract.id);
                      }}
                      src="/static_images/checked_icon.svg"
                      alt="checked"
                    ></img>
                  )}
                </div>
              ) : (
                <div
                  className="col-md-1 df align-items-center"
                  style={{ maxWidth: "6%", padding: "0 10px" }}
                >
                  {selectedDrafts.indexOf(contract.id) == -1 && (
                    <input
                      style={{ width: "15px", height: "15px" }}
                      onClick={() => {
                        if (!contract?.compare_versions) {
                          selectDraft(contract);
                          selectMultipleDrafts(contract.id);
                          selectMultipleDraftsLink(contract.id);
                        }
                      }}
                      type="checkbox"
                      name="check"
                      id="check"
                    />
                  )}
                  {selectedDrafts.indexOf(contract.id) > -1 && (
                    <img
                      className="checked-icon"
                      style={{ top: "0" }}
                      onClick={() => {
                        if (!contract?.compare_versions) {
                          selectDraft(contract);
                          selectMultipleDrafts(contract.id);
                          selectMultipleDraftsLink(contract.id);
                        }
                      }}
                      src="/static_images/checked_icon.svg"
                      alt="checked"
                    ></img>
                  )}
                </div>
              )}
              {contract?.compare_versions ? (
                <div
                  className="col-md-1 c-grey"
                  style={{ maxWidth: "13%", flex: "0 0 12%" }}
                  onClick={() => {
                    const link = contract?.compare_versions?.data?.map(
                      (item: any) => item.id
                    );
                    const name1 = `V${contract?.compare_versions?.data[0].version}-${contract?.compare_versions?.data[0].contractName}`;
                    const name2 = `V${contract?.compare_versions?.data[1].version}-${contract?.compare_versions?.data[1].contractName}`;
                    props.setCompareFileName([name1, name2]);
                    props.setselectedDraftsLink([...link]);
                    props.setComparisionDrawer(true);
                  }}
                >
                  <div className="row">
                    <img
                      src="/static_images/compare-disable.svg"
                      className="mr-2"
                    ></img>
                    {contract?.compare_versions?.visible_version}
                  </div>
                </div>
              ) : (
                <div
                  className="col-md-1 c-grey"
                  style={{ maxWidth: "13%", flex: "0 0 12%" }}
                  onClick={() => {
                    const owner = isOwner(props.selectedDraft);

                    const user_data = getLocalStorage("user_profile");

                    const approvalData = props.selectedDraft?.approvalTypes.map(
                      (item: any) => {
                        const approverFilteredData = item["approvers"].filter(
                          (data: any) => data?.id === user_data?.id
                        );
                        return approverFilteredData;
                      }
                    );
                    const collaboratorData =
                      props.selectedDraft?.collaborators.filter(
                        (item: any) => item?.id === user_data?.id
                      );
                    const signatoryData =
                      props.selectedDraft?.signatories.filter(
                        (item: any) => item?.id === user_data.id
                      );

                    if (
                      owner ||
                      approvalData[approvalData.length - 1]?.length > 0 ||
                      collaboratorData?.length > 0 ||
                      signatoryData?.length > 0
                    ) {
                      if (
                        (contract.version !== 0 &&
                          contract.version === 1 &&
                          contract.status !==
                            draftStatus.DRAFT_CREATION_PENDING) ||
                        (contract.version !== 1 && contract.version !== 0)
                      ) {
                        openDocViewer(contract);
                      } else if (
                        contract.version === 1 &&
                        contract.status ===
                          draftStatus.DRAFT_CREATION_PENDING &&
                        contract.createFrom === "template"
                      ) {
                        props.setTemplate(contract);
                      } else {
                        setopenapproval(true);
                        setversion(contract?.version);
                        setreqresponse(contract);
                        setFileData(contract.access_url);
                        // setFileData(contract.requisition_approver_link);
                      }
                    } else {
                      setopenSnackbar({
                        open: true,
                        msg: "You are not assigned any role for this draft",
                      });
                    }
                  }}
                >
                  {contract.version !== 0 && contract.is_internal == false && (
                    <img
                      className="checked-icon"
                      style={{ top: "0" }}
                      src="/static_images/emailIcon.svg"
                      alt="checked"
                    />
                  )}
                  V{contract.version}{" "}
                  {isAllSigned(contract) && (
                    <span>
                      <img src="/static_images/sub-section-success.svg" />
                    </span>
                  )}
                </div>
              )}
              <div className="col-md-2 c-grey">
                {new Date(contract.created_on).toLocaleDateString()}
              </div>
              <div className="col-md-4">
                <Avatar users={[contract?.created_by]} />
              </div>
              <div style={{ flex: "0 0 30%" }} className="col-md-4 p-0 c-grey">
                {isAllSigned(contract) ? "Fully executed contract" : ""}
              </div>
            </div>
          ))}
      </div>
      {openUploadNew && (
        <UploadFileDialog
          title="Upload Draft"
          fileType="Contract_Draft"
          uploadedFileResponse={uploadNewVersionDraft}
          open={openUploadNew}
          onClose={handleCloseEditDialog}
          contractData={contractData}
          show={show}
          contractType={[]}
          isInternal={isInternal}
          handleisinternal={handleisinternal}
          setisinternal={setisinternal}
        />
      )}
      {openSupportDoc && (
        <UploadFileDialog
          title="Upload Support Doc"
          fileType="support_document"
          uploadedFileResponse={uploadSupportDocument}
          open={openSupportDoc}
          onClose={handleCloseSupportDialog}
          contractData={contractData}
          show={false}
          contractType={[]}
          isInternal={isInternal}
          handleisinternal={handleisinternal}
          setisinternal={setisinternal}
        />
      )}
      {openModal && (
        <Shareversionmodal
          openModal={openModal}
          handleCloseShareBtn={handleCloseShareBtn}
          SelectedList={versiondata}
          setOpenSuccessShare={setOpenSuccessShare}
        />
      )}

      {openSuccessShare && (
        <ShareSuccessModal
          openModal={openSuccessShare}
          onClose={() => {
            setOpenSuccessShare(false);
            handleCloseShareBtn();
          }}
        />
      )}
      <DeleteDraftDialog
        open={openDeleteDialog}
        getDrafts={getVersions}
        onClose={handleCloseDeleteDialog}
        selectedDraft={deleteDrafts}
        for={"versionhistory"}
      />
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar.open}
        onClose={closeSnackbar}
        message={openSnackbar.msg}
        key="bottomCenter"
        action={action}
      />
      {openpdfviewr && (
        <PdfViewer
          open={openpdfviewr}
          onClose={handleCloseviewer}
          fileName={fileName}
          draftDetails={draftDetails}
        />
      )}
      {openapproval && (
        <ForApproval
          //   title="Upload Draft"
          //   action="Contract_Draft"
          //   uploadedFileResponse={uploadAndCreateDraft}
          draftcomponent=""
          reqres={reqresponse}
          openmain={setopenapproval}
          version={version}
          requisitionId={requisitionId}
          close={() => {
            props.setshowVersionHistory(false);
          }}
          show={false}
          open={openapproval}
          setopenapproval={setopenapproval}
          createLink={undefined}
          getDrafts={props.getDrafts}
        />
      )}
    </Drawer>
  );
};

export default VersionHistory;
