import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Box, CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

const deleteStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  minHeight: "260px",
  maxHeight: "340px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

const supportstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "996px",
  height: "482px",
  // width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  openModal: string;
  handleClosedelete: any;

  btn: any;
  p: any;
  selectedContracts: any;
  deleteContractLoader: boolean;
  deleteContracts: () => void;
}
interface State {
  position: any;
  value: any;
}

const MyDocumentModal: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div>
        <Modal
          open={props.openModal === "modalDelete"}
          className="deletemodal"
          onClose={() => props.handleClosedelete()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deleteStyle} className="position-relative">
            <Typography
              id="modal-modal-title"
              // variant="h6"
              // component="h2"
              className="myDocument-deleteFileTitle"
            >
              <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
                Confirm Delete
                <span
                  className="mr-0"
                  onClick={() => props.handleClosedelete()}
                >
                  <img
                    src={"/static_images/Group 3610.svg"}
                    alt=""
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#818181",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </Typography>
            <Typography>
              {props.deleteContractLoader && (
                <CircularProgress className="loader" />
              )}
              <div
                className="myDocument-deleteFileMessage"
                // dangerouslySetInnerHTML={{ __html: props.p }}
              >
                <p>
                  Are you sure you want to delete these contracts?
                  <br />
                  {props.selectedContracts.length > 0 &&
                    props.selectedContracts.map((contract: any, i: any) => (
                      <span key={i}>
                        {i + 1}. {contract.file_name}
                        {i < props.selectedContracts.length ? <br /> : null}
                      </span>
                    ))}
                </p>
                <p>You cannot undo the action</p>
              </div>
            </Typography>
            <div className="delete-modal-btn">
              <button
                className="myDocument-deleteFileGoBack cursor-pointer"
                onClick={() => props.handleClosedelete()}
              >
                Go back
              </button>
              <button
                className="myDocument-deleteFileFiled-tag cursor-pointer"
                onClick={() => props.deleteContracts()}
              >
                {props.btn}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentModal);
