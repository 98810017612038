import React, { Component } from "react";
import FileLink from "../../../UniversalComponents/FileLink/fileLink";
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory();

import { History } from "history";

interface Props {
  title: string;
  uniqueFileId: string;
  history: History;
}

export default class Title extends Component<Props> {
  render() {
    let { title, uniqueFileId } = this.props;
    return (
      <>
        <span
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
          onClick={() =>
            this.props.history.push(
              "/document/documentlibrary/" + btoa(uniqueFileId.toString())
            )
          }
        />
        &nbsp;&nbsp;
        <img
          onClick={() =>
            this.props.history.push(
              "/document/documentlibrary/" + btoa(uniqueFileId.toString())
            )
          }
          className="cursor-pointer"
          style={{ marginTop: "-3px" }}
          src="/static_images/new-tab-purple-big.svg"
          alt="new tab"
        />
      </>
    );
  }
}
