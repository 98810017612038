import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { Filter } from "../State/ApprovalState";
const APIPaths = {
    getApprovals: process.env.REACT_APP_RIVERUS_CLM_API + "stylus/approval/",
};

const APIConfig = () => ({
    headers: {
        Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
});

export const getApprovals = (filters: Filter) => {
    if (filters.applicable_for.length > 0 && filters.approval_type) {
        return axios.get(APIPaths.getApprovals + `?approval_type=${filters.approval_type}&applicable_for=${filters.applicable_for}`, APIConfig()).then((response) => {
            return response.data;
        }).catch((error) => {
            console.error('getApprovals error', error);
        })
    }
    if (filters.applicable_for.length > 0) {
        return axios.get(APIPaths.getApprovals + `?applicable_for=${filters.applicable_for}`, APIConfig()).then((response) => {
            return response.data;
        }).catch((error) => {
            console.error('getApprovals error', error);
        })
    }
    if (filters.approval_type) {
        return axios.get(APIPaths.getApprovals + `?approval_type=${filters.approval_type}`, APIConfig()).then((response) => {
            return response.data;
        }).catch((error) => {
            console.error('getApprovals error', error);
        })
    }
    return axios.get(APIPaths.getApprovals, APIConfig()).then((response) => {
        return response.data;
    }).catch((error) => {
        console.error('getApprovals error', error);
    })
}

export const deleteApproval = (fileId: string) => {
    return axios.delete(APIPaths.getApprovals + fileId + '/', APIConfig()).catch((error) => {
        console.error('deleteApproval error', error);
    })
}

export const postApprovals = (aprovalPostReq: any) => {
    return axios.post(APIPaths.getApprovals, aprovalPostReq, APIConfig()).then((response) => {
        return response.data;
    }).catch((error) => {
        console.error('postApprovals error', error);
    })
}

export const editApprovals = (aprovalPostReq: any, id: String) => {
    return axios.put(APIPaths.getApprovals + id + '/', aprovalPostReq, APIConfig()).then((response) => {
        return response.data;
    }).catch((error) => {
        console.error('editContractType error', error);
    })
}
