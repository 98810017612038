import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMoreOutlined";
import "./styles.css";
import { FieldName, initialFieldName } from "../../DataDictionaryState";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import axios from "axios";
import { isRiverusAdmin } from "../../DDUtils";

interface Props {
  SectionDetails: any;
  setSectionDetailsData: any;
  sectionDetailsData: any[];
  loading: boolean;
  addField: any | null;
  setselectedSection: (section: any) => void;
  setNewSectionData: any;
  setAddField: any;
  dataType: any;
  selectedSection: any;
  setAddSection: any;
  contract_typeId: any;
  reOrderSection: any;
  isSectionSort: any;
  tabName: string;
  isAdmin: boolean;
}

export const ContractTypeDetails = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [data, setData] = useState(props.sectionDetailsData);
  const [isEdit, setEdit] = useState<any>(false);

  const createNewField = (row: any) => {};

  useEffect(() => {}, [data, isEdit]);

  useEffect(() => {
    setData(props.sectionDetailsData);
  }, [props.sectionDetailsData]);
  const addNewSectionButton = () => {
    return (
      <div className="d-flex w-100">
        <div
          className={`w-50 ${
            props.tabName === "default"
              ? isRiverusAdmin()
                ? ""
                : "disabled-buttons"
              : props.isAdmin
              ? ""
              : "disabled-buttons"
          }`}
          style={{
            display: "flex",
            border: "1px solid #dedede",
            height: 70,
            justifyContent: "center",
            alignItems: "center",
          }}
          data-toggle="modal"
          data-target="#notAvailable"
        >
          <button
            className={`${
              props.tabName === "default"
                ? isRiverusAdmin()
                  ? ""
                  : "disabled-buttons"
                : props.isAdmin
                ? ""
                : "disabled-buttons"
            }`}
            disabled={
              props.tabName === "default"
                ? isRiverusAdmin()
                  ? false
                  : true
                : props.isAdmin
                ? false
                : true
            }
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: `${
                props.tabName === "default"
                  ? isRiverusAdmin()
                    ? "#88305F"
                    : "#DADADA"
                  : props.isAdmin
                  ? "#88305F"
                  : "#DADADA"
              }`,
            }}
            onClick={() => props.setAddSection(true)}
          >
            {" + Add New Section"}
          </button>
        </div>
        <div
          className={`w-50 ${
            props.tabName === "default"
              ? isRiverusAdmin()
                ? ""
                : "disabled-buttons"
              : props.isAdmin
              ? ""
              : "disabled-buttons"
          }`}
          style={{
            display: "flex",
            border: "1px solid #dedede",
            height: 70,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            disabled={
              props.tabName === "default"
                ? isRiverusAdmin()
                  ? false
                  : true
                : props.isAdmin
                ? false
                : true
            }
            className={`${
              props.tabName === "default"
                ? isRiverusAdmin()
                  ? ""
                  : "disabled-buttons"
                : props.isAdmin
                ? ""
                : "disabled-buttons"
            }`}
            data-toggle="modal"
            data-target="#notAvailable"
            onClick={() => {
              if (isEdit) {
                if (props.sectionDetailsData === data) {
                  setEdit(false);
                } else {
                  const orderList = data.map((item: any) => item.section_id);
                  const payload = {
                    order_list: orderList,
                  };
                  props.reOrderSection(
                    payload,
                    props.contract_typeId,
                    props.isSectionSort
                  );
                  setEdit(false);
                }
              } else {
                setEdit(true);
              }
            }}
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: `${
                props.tabName === "default"
                  ? isRiverusAdmin()
                    ? "#88305F"
                    : "#DADADA"
                  : props.isAdmin
                  ? "#88305F"
                  : "#DADADA"
              }`,
            }}
          >
            {isEdit ? "Save" : "Edit"}
          </button>
        </div>
      </div>
    );
  };

  const handleChange =
    (panel: string, row: any) => (event: any, isExpanded: boolean) => {
      if (row?.section_field_name.length === 0) {
        createNewField(row);
      }
      setExpanded(isExpanded ? panel : false);

      let section = isExpanded
        ? data.find((section) => section?.section_name == panel)
        : undefined;
      if (section) {
        if (section?.field_data.length !== 0) {
          const dataTypeData = props?.dataType?.filter(
            (item: any) =>
              item?.custom_data_type === section?.field_data[0]?.data_type
          );
          props.setAddField({
            data: {
              ...section?.field_data[0],
              section_id: section?.section_id,
              ui_component: dataTypeData?.length
                ? dataTypeData[0]?.ui_component
                : "",
            },
            isEdit: true,
          });
        } else {
          props.setAddField({
            data: { ...initialFieldName, section_id: section?.section_id },
            isEdit: false,
          });
        }
        props.setselectedSection(section);
      }
      if (!isExpanded) {
        props.setselectedSection(null);
      }
    };

  useEffect(() => {}, [props.addField]);

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: "white",
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(data, result.source.index, result.destination.index);
    setData(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={!isEdit}>
        {(provided: any, snapshot: any) => (
          <div id="contract-type">
            <div
              className="cls_main_side_1"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {data.map((row: any, index) => {
                return (
                  <Draggable
                    key={row.section_id}
                    draggableId={row.section_id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <Accordion
                          key={index}
                          style={{
                            borderBottom: "1px solid #e1e1e1",
                            margin: "0px",
                          }}
                          expanded={expanded === row.section_name}
                          onChange={handleChange(row.section_name, row)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="contract-summary"
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row.section_name}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              borderLeft: "3.5px solid #88305F",
                              display: "block",
                            }}
                          >
                            {row?.field_data.length > 0 &&
                              row?.field_data.map((row: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    onClick={() => {
                                      const dataTypeData =
                                        props?.dataType?.filter(
                                          (item: any) =>
                                            item?.custom_data_type ===
                                            row?.data_type
                                        );
                                      props.setAddField({
                                        data: {
                                          ...row,
                                          section_id:
                                            props.selectedSection?.section_id,
                                          ui_component: dataTypeData?.length
                                            ? dataTypeData[0]?.ui_component
                                            : "",
                                        },
                                        isEdit: true,
                                      });
                                    }}
                                  >
                                    <p
                                      className={`cursor-pointer ${
                                        props.addField?.data !==
                                        initialFieldName
                                          ? props.addField?.data
                                              ?.display_name ===
                                            row?.display_name
                                            ? "c-primary"
                                            : ""
                                          : ""
                                      }`}
                                    >
                                      {row?.display_name}
                                    </p>
                                  </div>
                                );
                              })}
                            <button
                              onClick={() => {
                                props.setAddField({
                                  data: {
                                    ...initialFieldName,
                                    section_id: row?.section_id,
                                  },
                                  isEdit: false,
                                });
                              }}
                              disabled={
                                props.tabName === "default"
                                  ? isRiverusAdmin()
                                    ? false
                                    : true
                                  : props.isAdmin
                                  ? false
                                  : true
                              }
                              className={`${
                                props.tabName === "default"
                                  ? isRiverusAdmin()
                                    ? ""
                                    : "disabled-buttons"
                                  : props.isAdmin
                                  ? ""
                                  : "disabled-buttons"
                              }`}
                              style={{
                                padding: "0",
                                fontSize: "14px",
                                backgroundColor: "white",
                                border: "none",
                                color: `${
                                  props.tabName === "default"
                                    ? isRiverusAdmin()
                                      ? "#88305F"
                                      : "#DADADA"
                                    : props.isAdmin
                                    ? "#88305F"
                                    : "#DADADA"
                                }`,
                              }}
                            >
                              {" + Add New Field"}
                            </button>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
            {addNewSectionButton()}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
