import {
  Box,
  CircularProgress,
  DialogContentText,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import { deleteTags, postTags } from "../../../ClauseLibrary/DA";
import { SectionDictionaryInfo } from "../../../ClauseLibrary/State/clauseLibraryState";
import { isRiverusAdmin } from "../../../DataDictionary/DDUtils";
import WarningPopup from "../../../Draft/Component/WarningPopup";
import CustomChip from "../../CustomChip/Component/CustomChip";
import AxiosGateWay from "../../HeimdallChild/HeimdallUtil/axiosUtils";

type Props = {
  closeModal: () => void;
  getSectionDictionary: () => void;
  sectionDictionaryData: SectionDictionaryInfo[];
  projectData: [];
  clauseMode: string;
  editClauseData: any;
  isAdmin?: boolean | null;
  createSectionClause: (
    section_name: string,
    clause_name: string,
    clause_type: string,
    clause_source: string,
    clause_text: string,
    description: string,
    creation_type: string,
    tags: any
  ) => void;
  updateClause: any;
  onRefresh: any;
  onAdd: any;
};

type State = {
  clauseType: string;
  sectionName: any;
  clauseName: string;
  clauseSource: string;
  clauseText: string;
  description: string;
  createdType: string;
  tags: any;
  warningPopup: any;
  toggleAddField: boolean;
  isLoading: boolean;
};

export default class CreateNewClauseModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clauseType: "recommended",
      tags: [],
      sectionName: "",
      clauseName: "",
      clauseSource: "",
      clauseText: "",
      description: "",
      createdType: "custom",
      warningPopup: {
        isShow: false,
        tagValue: "",
        type: "",
      },
      toggleAddField: false,
      isLoading: false,
    };
  }

  style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    borderRadius: 15,
  };
  componentDidMount(): void {
    this.props.getSectionDictionary();
    if (this.props.clauseMode === "edit") {
      this.setState({
        clauseType: this.props.editClauseData.clause_type,
        tags: this.props.editClauseData.tags,
        sectionName: this.props.editClauseData.section_name,
        clauseName: this.props.editClauseData.clause_name,
        clauseSource: "",
        clauseText: this.props.editClauseData.clause_text,
        description: this.props.editClauseData.description,
        createdType: this.props.editClauseData.creation_type,
      });
    } else {
      this.setState({
        clauseType: "recommended",
        tags: [],
        sectionName: "",
        clauseName: "",
        clauseSource: "",
        clauseText: "",
        description: "",
        createdType: "custom",
      });
    }
  }

  onCreateOrUpdate = () => {
    console.log(this.state.sectionName, "sectionName");

    let tagsArray: string[] = [];
    this.state.tags.map((item: any) => tagsArray.push(item.id));

    if (this.props.clauseMode === "edit") {
      const payload = {
        section_name: this.state.sectionName,
        clause_name: this.state.clauseName,
        clause_type: this.state.clauseType,
        clause_source:
          this.state.clauseSource === ""
            ? "No Reference"
            : this.state.clauseSource,
        clause_text: this.state.clauseText,
        description: this.state.description,
        tags: tagsArray,
        creation_type: isRiverusAdmin() ? "system" : "custom",
      };
      this.props.updateClause(this.props.editClauseData.id, payload);
    } else {
      this.props.createSectionClause(
        this.state.sectionName,
        this.state.clauseName,
        this.state.clauseType,
        this.state.clauseSource && "No Reference",
        this.state.clauseText,
        this.state.description,
        isRiverusAdmin() ? "system" : "custom",
        tagsArray
      );
    }
    this.props.onAdd();
    this.props.closeModal();
  };

  infoItems = [
    "1. Umbrella Contract <Provide Link>",
    "2. Stylus Draft <Provide Link>",
    "3. External Source <Provide Link>",
  ];
  tip = this.infoItems.join("\n");

  render() {
    let { closeModal, sectionDictionaryData, projectData } = this.props;
    let { clauseType, tags, clauseName, clauseText, description, sectionName } =
      this.state;
    return (
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={this.style}>
          {this.state.isLoading && <CircularProgress className="loader" />}
          <div className="modal-header">
            <p className="m-0">Create New Clause</p>
            <img
              className="cursor-pointer"
              onClick={() => closeModal()}
              src="/static_images/white-close-modal-icn.svg"
              alt="clause-folder"
            />
          </div>
          <div className="pt-4 pb-2">
            <div className="row m-0">
              <div className="col-md-4">
                <FormControl size="small" variant="outlined" fullWidth>
                  <DialogContentText className="riverus-field-title">
                    Select Section
                  </DialogContentText>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      this.props.clauseMode === "edit"
                        ? sectionName
                          ? sectionName
                          : sectionName
                        : this.state.sectionName
                    }
                    onChange={(e) => {
                      this.setState({
                        sectionName: e.target.value,
                      });
                    }}
                    fullWidth={true}
                  >
                    {sectionDictionaryData &&
                      sectionDictionaryData?.length &&
                      sectionDictionaryData.map((item: any) => {
                        return (
                          <MenuItem
                            style={{ whiteSpace: "initial" }}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-4">
                <DialogContentText className="riverus-field-title">
                  Clause Name
                </DialogContentText>
                <TextField
                  className="riverus-text-box"
                  size="small"
                  placeholder="Enter Clause Name"
                  value={clauseName}
                  onChange={(e) =>
                    this.setState({ clauseName: e.target.value })
                  }
                  fullWidth={true}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
              {this.props.clauseMode === "edit" && (
                <div className="col-md-4">
                  <label>Clause No.</label>
                  <p>{this.props.editClauseData.clause_no}</p>
                </div>
              )}
            </div>
            <div className="mt-4">
              <div className="row m-0">
                <div className="col-md-4">
                  <label>Clause Type</label>
                  <div className="d-flex align-items-center">
                    <img
                      className="cursor-pointer"
                      style={{ height: "24px", width: "24px" }}
                      onClick={() =>
                        this.setState({ clauseType: "recommended" })
                      }
                      src={`${
                        clauseType === "recommended"
                          ? "/static_images/radio-active.svg"
                          : "/static_images/radio-inactive.svg"
                      }`}
                      alt="radio-btn"
                    />
                    &nbsp;&nbsp;
                    <span className="d-flex align-items-center">
                      <img
                        className="cursor-pointer"
                        style={{ height: "25px" }}
                        src={"/static_images/sub-section-success.svg"}
                        alt="radio-btn"
                      />
                      <p
                        style={{ fontSize: "14px", color: "#4C4C4C" }}
                        className="m-0"
                      >
                        &nbsp; Recommended
                      </p>
                    </span>
                  </div>
                  <div className="mt-2 d-flex">
                    <img
                      className="cursor-pointer"
                      onClick={() => this.setState({ clauseType: "avoidable" })}
                      style={{ height: "24px", width: "24px" }}
                      src={`${
                        clauseType === "avoidable"
                          ? "/static_images/radio-active.svg"
                          : "/static_images/radio-inactive.svg"
                      }`}
                      alt="radio-btn"
                    />
                    &nbsp;&nbsp;
                    <span className="d-flex">
                      <img
                        className="cursor-pointer"
                        src={"/static_images/avoidable-icon.svg"}
                        alt="radio-btn"
                      />
                      <p
                        style={{ fontSize: "14px", color: "#4C4C4C" }}
                        className="m-0"
                      >
                        &nbsp; Avoidable
                      </p>
                    </span>
                  </div>
                </div>
                <div className="col-md-8" style={{ margin: "auto" }}>
                  <label>Clause Text</label>
                  <textarea
                    onChange={(e) =>
                      this.setState({ clauseText: e.target.value })
                    }
                    rows={6}
                    cols={40}
                    name="text"
                    value={clauseText}
                    placeholder="Enter relevant clause text"
                    maxLength={3000}
                    style={{
                      minHeight: "60px",
                      lineHeight: "15px",
                      width: "100%",
                      border: "1px solid #88305F",
                      borderRadius: "5px",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="row m-0">
                <div className="col-md-4" style={{ top: "-30px" }}>
                  <label>
                    Clause Source
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: "pre-line" }}>{this.tip}</div>
                      }
                      arrow
                    >
                      <img
                        style={{
                          right: "45px",
                          top: "5px",
                          position: "absolute",
                        }}
                        src="/static_images/dd-view.svg"
                        alt="clause-folder"
                      />
                    </Tooltip>
                  </label>
                  {/* <div className="section-modal-upload"> */}
                  {/* <p>No Reference</p> */}
                  <>
                    <input
                      className="section-modal-upload"
                      type="search"
                      list="mylist"
                      value={this.state.clauseSource}
                      onChange={(e) => {
                        if (e.target.value.split("::")[0] !== "No Reference") {
                          this.setState({ clauseSource: e.target.value });
                        } else {
                          this.setState({ clauseSource: "No Reference::" });
                        }
                      }}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #88305F",
                        borderRadius: "5px",
                      }}
                    />
                    <datalist id="mylist">
                      <option value="No Reference::" />
                      <option value="Umbrella Contract::" />
                      <option value="Stylus Template::" />
                      <option value="External Source::" />
                    </datalist>
                  </>
                </div>
                <div className="col-md-8" style={{ margin: "auto" }}>
                  <label>Description</label>
                  <textarea
                    value={description}
                    rows={7}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    cols={40}
                    name="text"
                    placeholder="Enter Usage Info"
                    maxLength={3000}
                    style={{
                      minHeight: "60px",
                      lineHeight: "15px",
                      width: "100%",
                      border: "1px solid #88305F",
                      borderRadius: "5px",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ top: "-70px" }}>
              <div className="d-flex justify-content-between">
                <label>Tags</label>
                <div className="d-flex">
                  <p
                    style={{ color: "#818181" }}
                    className="cursor-pointer"
                    onClick={() => this.setState({ tags: [] })}
                  >
                    Reset
                  </p>
                  <p
                    style={{ color: "#818181" }}
                    className="cursor-pointer ml-2"
                    onClick={() =>
                      this.setState({
                        toggleAddField: !this.state.toggleAddField,
                      })
                    }
                  >
                    Add New
                  </p>
                </div>
              </div>

              <FormControl size="small" variant="outlined" fullWidth={true}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={""}
                  onChange={(e: any) => {
                    const checkID = (element: any) =>
                      element.id === JSON.parse(e.target.value).id;
                    if (!tags.some(checkID)) {
                      this.setState({
                        tags: [...tags, JSON.parse(e.target.value)],
                      });
                    }
                  }}
                  fullWidth={true}
                >
                  {projectData &&
                    projectData?.length &&
                    projectData?.map((item: any) => {
                      return (
                        <MenuItem
                          style={{ whiteSpace: "initial" }}
                          value={JSON.stringify(item)}
                        >
                          <ListItemText
                            style={{ whiteSpace: "initial" }}
                            primary={item["name"]}
                          />
                          <ListItemIcon className="justify-content-end">
                            <AiOutlineDelete
                              color="red"
                              onClick={async () => {
                                this.setState({
                                  warningPopup: {
                                    tagValue: item.id,
                                    isShow: true,
                                    type: "delete",
                                  },
                                });
                              }}
                            />
                          </ListItemIcon>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {this.state.toggleAddField && (
                <div className="row mt-2 align-items-center">
                  <div className="col-md-10 mr-2">
                    <TextField
                      size="small"
                      value={this.state.warningPopup.tagValue}
                      onChange={(event: any) =>
                        this.setState({
                          warningPopup: {
                            ...this.state.warningPopup,
                            tagValue: event.target.value,
                          },
                        })
                      }
                      fullWidth={true}
                      id="outlined-basic"
                      style={{ width: "190px" }}
                      variant="outlined"
                    />
                  </div>
                  <div className="col-md-1 p-0">
                    <IconContext.Provider
                      value={{ color: "#88305F", size: "20" }}
                    >
                      <BsFillCheckCircleFill
                        onClick={async () => {
                          this.setState({
                            warningPopup: {
                              ...this.state.warningPopup,
                              isShow: true,
                              type: "add",
                            },
                          });
                        }}
                      />
                    </IconContext.Provider>
                  </div>
                </div>
              )}
            </div>
            {this.state.warningPopup.isShow && (
              <WarningPopup
                openWarningPopup={this.state.warningPopup.isShow}
                message={
                  this.state.warningPopup.type === "delete"
                    ? "Are you sure you want to delete the Tags?"
                    : "Are you sure you want to Add the Tags?"
                }
                onClose={() => {
                  this.setState({
                    warningPopup: {
                      isShow: false,
                      tagValue: "",
                      type: "",
                    },
                  });
                }}
                onClick={async () => {
                  this.setState({ isLoading: true });
                  if (this.state.warningPopup.type === "delete") {
                    const tagID = this.state.warningPopup.tagValue;
                    await deleteTags(this.state.warningPopup.tagValue);
                    this.props.onRefresh();
                    const data = this.state.tags.filter(
                      (item: any) => item.id !== tagID
                    );
                    this.setState({
                      tags: [...data],
                    });
                    this.setState({ isLoading: false });
                  } else {
                    const payload = {
                      name: this.state.warningPopup.tagValue,
                    };
                    const response = await postTags(payload);
                    if (response) {
                      this.props.onRefresh();
                      const checkID = (element: any) =>
                        element.id === response.id;
                      if (!tags.some(checkID)) {
                        this.setState({
                          tags: [...tags, response],
                        });
                      }
                      this.setState({ toggleAddField: false });
                    }
                    this.setState({ isLoading: false });
                  }
                }}
              />
            )}
            <div className="mt-4">
              <div className="row m-0">
                <div className="col-md-12" style={{ top: "-40px" }}>
                  <div className="row m-0">
                    {tags
                      ? tags?.length
                        ? tags?.map((item: any) => {
                            return (
                              <div
                                className="col-md-2 section-tag"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                <div className="d-flex justify-content-around align-items-center">
                                  <p className="mb-0 mr-2">{item["name"]}</p>
                                  <IconButton
                                    style={{ padding: "0" }}
                                    onClick={() => {
                                      const filteredTags = tags.filter(
                                        (items: any) => items.id !== item.id
                                      );
                                      this.setState({
                                        tags: [...filteredTags],
                                      });
                                    }}
                                    color="primary"
                                    aria-label="cancel button"
                                  >
                                    X
                                  </IconButton>
                                </div>
                              </div>
                            );
                          })
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-end mt-3 mr-3"
              onClick={() => this.onCreateOrUpdate()}
            >
              <span
                className="section-add-clause py-1"
                id="save-btn"
                style={{ fontSize: "13px", padding: "10px 25px" }}
                data-toggle="modal"
                data-target="#createClausePageModal"
              >
                {this.props.clauseMode === "edit"
                  ? "Update Clause"
                  : "Add Clause"}
              </span>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}
