import {
  Box,
  Checkbox,
  FormControl,
  InputAdornment,
  ListItemText,
  ListSubheader,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
// import SearchIcon from "@mui/icons-material/Search";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useMemo, useState } from "react";
import { appliedFilters, FilterApplied } from "../State";
import { getSelectedFilter } from "./Utils/docUtils";

interface Props {
  filterName: string;
  reset: () => void;
  onChange: (change: any) => void;
  values: any[];
  isMultiple: boolean;
  keyName: string;
  searchText: (search_text: any) => void;
  selectedValues: string[];
  checkselectedValues: (filterData: any, selected: boolean) => void;
  selected_filter: appliedFilters[];
  checkbox_present: boolean;
  checkbox_selected: any;
  handleChange: any;
  selected_chekbox_type: string;
  selected_checkbox: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200, //ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  autoFocus: false,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
const useStyles = makeStyles((theme) => ({
  listSubHeaderRoot: {
    backgroundColor: "#f8f9fa",
    color: "#252525",
  },
}));
const GenericFilter = (props: Props) => {
  const [selectedValues, setselectedValues] = useState<string[]>([]);
  const [selectedValue, setselectedValue] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (selectedValues.length > 0) {
      const ids = selectedValues.reduce<any[]>((acc, curr) => {
        const obj = props.values.find((item) => item[props.keyName] == curr);
        if (obj) {
          acc = [...acc, obj.id];
        }
        return acc;
      }, []);
      props.onChange(ids);
    } else props.onChange([]);
  }, [selectedValues]);

  const handleMultipleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    /* setselectedValues(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );*/
  };

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setselectedValue(value);

    props.onChange(value);
  };

  const onReset = () => {
    setselectedValues([]);
    props.reset();
  };
  return (
    <>
      <div className="row mb-2">
        <div className="col-md-8 f-14 filter-container-filter-header">
          {props.filterName}
        </div>
        <div
          onClick={onReset}
          className="col-md-4 filter-reset text-align-right cp"
        >
          Reset
        </div>
      </div>

      {props.checkbox_present ? (
        <div className="row mb-2">
          <div className="col-md-8" style={{ display: "none" }}>
            <input
              type="checkbox"
              className="myinput mr-1"
              checked={
                props.checkbox_selected.includes("Yes") ||
                props.checkbox_selected.includes("Capped")
              }
              onChange={(e) => {
                if (props.selected_chekbox_type == "limited_liability_amount") {
                  props.handleChange(e, "Capped", props.selected_chekbox_type);
                } else {
                  props.handleChange(e, "Yes", props.selected_chekbox_type);
                }
              }}
              style={{
                height: "16px",
                width: "16px",
                marginTop: "3px",
                color: "rgb(109, 109, 109)",
              }}
            />
            <label className="filter-container-filter-header mr-1">
              {props.selected_chekbox_type == "limited_liability_amount"
                ? "Capped"
                : "Yes"}
            </label>

            {props.selected_chekbox_type != "cexcept" ? (
              <>
                <input
                  type="checkbox"
                  className="myinput mr-1 ml-3"
                  checked={
                    props.checkbox_selected.includes("No") ||
                    props.checkbox_selected.includes("Uncapped")
                  }
                  onChange={(e) => {
                    if (
                      props.selected_chekbox_type == "limited_liability_amount"
                    ) {
                      props.handleChange(
                        e,
                        "Uncapped",
                        "limited_liability_amount"
                      );
                    } else {
                      props.handleChange(e, "No", props.selected_chekbox_type);
                    }
                  }}
                  style={{
                    height: "16px",
                    width: "16px",
                    marginTop: "3px",
                    color: "rgb(109, 109, 109)",
                  }}
                />
                <label className="filter-container-filter-header mr-1">
                  {props.selected_chekbox_type == "limited_liability_amount"
                    ? "Uncapped"
                    : "No"}
                </label>
              </>
            ) : null}
          </div>
          <div className="d-flex justify-content-start ml-3">
            <div>
              <input type="checkbox" className="myinput" style={{
                height: "16px",
                width: "16px",
                marginTop: "3px",
                color: "rgb(109, 109, 109)",
                marginRight: "5px"
              }}
                checked={
                  props.checkbox_selected.includes("Yes") ||
                  props.checkbox_selected.includes("Capped")
                }
                onChange={(e) => {
                  if (props.selected_chekbox_type == "limited_liability_amount") {
                    props.handleChange(e, "Capped", props.selected_chekbox_type);
                  } else {
                    props.handleChange(e, "Yes", props.selected_chekbox_type);
                  }
                }}
              />
            </div>
            <div className="filter-container-filter-header mr-4">{props.selected_chekbox_type == "limited_liability_amount"
              ? "Capped"
              : "Yes"}</div>
            <div>
              <input type="checkbox" className="myinput" style={{
                height: "16px",
                width: "16px",
                marginTop: "3px",
                color: "rgb(109, 109, 109)",
                marginRight: "5px"
              }}

                checked={
                  props.checkbox_selected.includes("No") ||
                  props.checkbox_selected.includes("Uncapped")
                }
                onChange={(e) => {
                  if (
                    props.selected_chekbox_type == "limited_liability_amount"
                  ) {
                    props.handleChange(
                      e,
                      "Uncapped",
                      "limited_liability_amount"
                    );
                  } else {
                    props.handleChange(e, "No", props.selected_chekbox_type);
                  }
                }}
              />
            </div>
            <div className="filter-container-filter-header mr-1">

              {props.selected_chekbox_type == "limited_liability_amount"
                ? "Uncapped"
                : "No"}
            </div>
          </div>
        </div>
      ) : null}

      <FormControl className="mb-3" fullWidth size="small" variant="standard">
        {props.isMultiple ? (
          <>
            <Select
              labelId="search-select-label"
              id="search-select"
              // id="demo-simple-select"
              placeholder="Select Dropdown"
              // onChange={handleMultipleChange}
              value={props.selectedValues == null ? "" : props.selectedValues}
              multiple={props.isMultiple}
              input={<OutlinedInput />}
              renderValue={(selected: any) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                  },
                },
                autoFocus: false,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              onClose={() => {
                props.searchText("");
              }}
              defaultValue={
                props.selectedValues == null ? "" : props.selectedValues
              }
            >
              <ListSubheader style={{ padding: "unset" }}>
                <TextField
                  classes={{ root: classes.listSubHeaderRoot }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  fullWidth
                  id="standard-bane"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    props.searchText(e.target.value.toLowerCase());
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>

              {props.values.map((item, key) => (
                <Tooltip
                  title={item[props.keyName]}
                  placement="right"
                  key={`$tool-tip${key}`}
                >
                  <MenuItem
                    key={key}
                    value={`${getSelectedFilter(item[props.keyName])}`}
                  >
                    <ListItemText
                      primary={`${getSelectedFilter(item[props.keyName])}`}
                      onClick={() => {
                        let index = props.selected_filter
                          .filter((option) =>
                            option.filterType
                              .toLowerCase()
                              .includes(item.filterType)
                          )
                          .findIndex(
                            (obj) =>
                              obj.id === item.id &&
                              obj.filterType === item.filterType
                          );
                        if (index == -1) {
                          props.checkselectedValues(item, true);
                        } else {
                          props.checkselectedValues(item, false);
                        }
                      }}
                    />

                    <Checkbox
                      checked={
                        props.selected_filter
                          .filter((option) =>
                            option.filterType
                              .toLowerCase()
                              .includes(item.filterType)
                          )
                          .findIndex(
                            (obj) =>
                              obj.id === item.id &&
                              obj.filterType === item.filterType
                          ) > -1
                      }
                      onChange={(event) => {
                        props.checkselectedValues(item, event.target.checked);
                      }}
                    />
                  </MenuItem>
                </Tooltip>
              ))}
            </Select>
          </>
        ) : (
          <Select
            labelId="search-select-label"
            id="search-select"
            // id="demo-simple-select"
            placeholder="Select Dropdown"
            // onChange={handleMultipleChange}
            value={props.selectedValues == null ? "" : props.selectedValues}
            multiple={props.isMultiple}
            input={<OutlinedInput />}
            renderValue={(selected: any) => selected.join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 250,
                },
              },
              autoFocus: false,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onClose={() => {
              props.searchText("");
            }}
            defaultValue={
              props.selectedValues == null ? "" : props.selectedValues
            }
          >
            {props.values.map((item, key) => (
              <MenuItem
                key={`${item.filterType}-${key}`}
                value={item[props.keyName]}
                onClick={() => {
                  let index = props.selected_filter
                    .filter((option) =>
                      option.filterType.toLowerCase().includes(item.filterType)
                    )
                    .findIndex(
                      (obj) =>
                        obj.id === item.id && obj.filterType === item.filterType
                    );
                  if (index == -1) {
                    props.checkselectedValues(item, true);
                  } else {
                    props.checkselectedValues(item, false);
                  }
                }}
              >
                {item[props.keyName]}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </>
  );
};

export default GenericFilter;
