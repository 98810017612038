import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@material-ui/core";
import React, { Component, createRef, useRef } from "react";
import { FilterData } from "../../../Templates/State/templateState";
import FilterInputModal from "../../../UniversalComponents/Filter/filterInputModal";

interface Props {
  data: any;
  updateSectionClauseSuccess: any;
  isSort: any;
  updateCheckedTitle: (id: string, title: string) => void;
  checkedTitle: any;
  tagsSorting: any;
  projectData: any;
  filterKeyVals: FilterData;
  applyFilter: (a: string[], b: string) => void;
  tags: string[];
}

interface State {
  type: string;
  isCreated: boolean;
  createdBy: string;
  isSource: boolean;
  fromSource: string;
  isPreview: boolean;
  preview: string;
  tags: boolean;
  filterKeyVals: any;
  filterControl: any;
  isClause: boolean;
  clause: string;
  updatedProjectList: string[];
  titlePop: boolean;
  titleText: string;
}

export default class SectionListHeader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: "ASC",
      isCreated: false,
      createdBy: "",
      isSource: false,
      fromSource: "",
      isPreview: false,
      preview: "",
      filterKeyVals: [],
      filterControl: [],
      tags: false,
      isClause: false,
      clause: "",
      updatedProjectList: [],
      titlePop: false,
      titleText: "",
    };
  }
  createdBy: any = createRef();
  fromSource: any = createRef();
  preview: any = createRef();
  clauseNo: any = createRef();
  tags: any = createRef();
  titleRef: any = createRef();

  onSearch = (searchString: any, type: any) => {
    let output: any = [];
    let filterData: any = [];
    if (type === "createdBy") {
      this.setState({ createdBy: searchString });
      filterData = this.props.data.map((item: any, index: number) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.section_name.created_by.first_name
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "source") {
      this.setState({ fromSource: searchString });
      filterData = this.props.data.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.clause_source
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "preview") {
      this.setState({ preview: searchString });
      filterData = this.props.data.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.description
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    } else if (type === "title") {
      this.setState({ titleText: searchString });
      filterData = this.props.data.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].filter((element: any) => {
          return element.section_name.section_name
            .toLowerCase()
            .includes(searchString.toLowerCase());
        });
      });
    }
    filterData.forEach((item: any) => {
      if (item.length) {
        output = [...output, ...item];
      }
    });
    const newOutput = output.map((item: any) => {
      const customObject: any = {
        [`${item.section_name.section_name}`]: [item],
        modified_on: item.created_on,
      };
      return customObject;
    });

    this.props.updateSectionClauseSuccess(newOutput);
    if (searchString.length > 0) {
      this.props.isSort(true);
    } else {
      this.props.isSort(false);
    }
  };

  sorting(data: any, type: any) {
    let a: any = [];
    data.forEach((item: any) => {
      a = [...a, Object.entries(item)];
    });
    if (type === "ASC") {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? 1 : -1));
    } else {
      a.sort((k: any, l: any) => (k[0][0] > l[0][0] ? -1 : 1));
    }
    const output = a.reduce((accumulator: any, data: any) => {
      return [
        ...accumulator,
        { [data[0][0]]: data[0][1], modified_on: data[1][1] },
      ];
    }, []);

    this.props.updateSectionClauseSuccess(output);
    this.props.isSort(true);
  }

  dateSorting(data: any, type: any) {
    if (type === "ASC") {
      data.sort((a: any, b: any) => (a.modified_on > b.modified_on ? 1 : -1));
    } else {
      data.sort((a: any, b: any) => (b.modified_on > a.modified_on ? 1 : -1));
    }

    this.props.updateSectionClauseSuccess(data);
    this.props.isSort(true);
  }

  handleClick = (event: any, placeHolder: string, filterKey: string) => {
    this.setState({
      filterControl: { ...this.state.filterControl, [filterKey]: true },
    });
  };

  handleClose = (key: string) => {
    this.setState({
      filterControl: { ...this.state.filterControl, [key]: false },
    });
  };

  render() {
    return (
      <div className="row clause-library-header-section m-0">
        <div className="col-md-5">
          <div className="row">
            <div style={{ paddingLeft: "10px" }}>
              <img
                onClick={() =>
                  this.props.updateCheckedTitle(this.props.data, "title")
                }
                className="clause-folder-icn"
                src={`${
                  this.props.checkedTitle.length
                    ? "/static_images/checkbox-section.svg"
                    : "/static_images/uncheck-section.svg"
                }`}
                alt="clause-folder"
              />
            </div>
            <div className="col-md-5 position-relative" ref={this.titleRef}>
              Title
              <img
                onClick={() => {
                  this.setState({ titlePop: !this.state.titlePop });
                }}
                className=" ml-2"
                src="/static_images/section-search.svg"
                alt="section-title"
              />
              {this.state.titlePop ? (
                <PopOver
                  fieldRef={this.titleRef}
                  closePop={() => this.setState({ titlePop: false })}
                  onSearch={this.onSearch}
                  value={this.state.titleText}
                  type={"title"}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-2 position-relative" ref={this.preview}>
          Preview
          <img
            onClick={() => {
              this.setState({ isPreview: !this.state.isPreview });
            }}
            className=" ml-2"
            src="/static_images/section-search.svg"
            alt="section-preview"
          />
          {this.state.isPreview ? (
            <PopOver
              fieldRef={this.preview}
              closePop={() => this.setState({ isPreview: false })}
              onSearch={this.onSearch}
              value={this.state.preview}
              type={"preview"}
            />
          ) : null}
        </div>
        <div className="col-md-2 position-relative" ref={this.createdBy}>
          Created by
          <img
            onClick={() => {
              this.setState({ isCreated: !this.state.isCreated });
            }}
            className=" ml-2"
            src="/static_images/section-search.svg"
            alt="section-created"
          />
          {this.state.isCreated ? (
            <PopOver
              fieldRef={this.createdBy}
              closePop={() => this.setState({ isCreated: false })}
              onSearch={this.onSearch}
              value={this.state.createdBy}
              type={"createdBy"}
            />
          ) : null}
        </div>

        <div
          className="col-md-1 p-0"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          onClick={() => {
            this.dateSorting(this.props.data, this.state.type);
            if (this.state.type === "ASC") {
              this.setState({ type: "DEC" });
            } else {
              this.setState({ type: "ASC" });
            }
          }}
        >
          Modified On
          <img
            onClick={() => {
              this.dateSorting(this.props.data, this.state.type);
              if (this.state.type === "ASC") {
                this.setState({ type: "DEC" });
              } else {
                this.setState({ type: "ASC" });
              }
            }}
            className=" ml-2"
            src="/static_images/section-modified.svg"
            alt="section-modified"
          />
        </div>
        <div className="col-md-2 position-relative" ref={this.tags}>
          Tags
          <img
            onClick={() => {
              this.setState({ tags: true });
              const ff = this.props.projectData.map((item: any) => item.name);
              this.setState({
                updatedProjectList: ff,
                filterControl: { ...this.state.filterControl, ["tags"]: true },
              });
            }}
            className=" ml-2"
            src="/static_images/section-tags.svg"
            alt="section-tags"
          />
          {this.state.filterControl["tags"] && this.state.tags && (
            <FilterInputModal
              filterKey="tags"
              filterKeyVals={this.props.filterKeyVals}
              open={this.state.filterControl["tags"]}
              anchorEl={this.tags.current}
              handleClose={() => this.handleClose("tags")}
              applyFilter={this.props.applyFilter}
              list={this.props.tags}
              selectedItem={this.props.filterKeyVals["tags"]}
            />
          )}
        </div>
      </div>
    );
  }
}

export const PopOver = (props: any) => {
  return (
    <Popover
      elevation={0}
      anchorEl={props.fieldRef?.current}
      open={true}
      onClose={() => props.closePop()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className="filter">
        <FormControl className="mb-2" fullWidth size="small" variant="outlined">
          <OutlinedInput
            placeholder={`Search ${props.type}`}
            id="outlined-adornment-password"
            value={props.value}
            onChange={(e) => props.onSearch(e.target.value, props.type)}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
          />
        </FormControl>
      </div>
    </Popover>
  );
};
