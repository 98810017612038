import React, { useEffect, useState } from "react";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import "../../UniversalComponents/Avatar/Design/Avatar.scss";
import { Tooltip } from "@material-ui/core";

interface Props {
  users: any[];
}
const Avatarfordraft = (props: Props) => {
  const [slicedata, setslicedata] = useState<any[]>([]);
  useEffect(() => {
    if (props.users.length > 3) {
      const slicedata = props.users.slice(0, 3);
      setslicedata(slicedata);
    }
  }, []);

  return (
    <div className="avatar-container position-relative">
      {slicedata.length === 3
        ? slicedata.map((user, index) => {
            const owner = user?.roles?.includes("owner");
            const collaborator = user?.assigned_role?.includes("Collaborators");
            const approver = user?.assigned_role?.includes("Approvers");
            const signatory = user?.assigned_role?.includes("Signatories");
            return (
              <Tooltip
                key={index}
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    {user?.first_name + " " + user?.last_name}
                  </div>
                }
              >
                <div
                  key={index}
                  style={{
                    border: `1px solid ${
                      owner
                        ? "#88305F"
                        : collaborator
                        ? "#EE5800"
                        : approver
                        ? "#00692A"
                        : signatory
                        ? "#392494"
                        : "#88305F"
                    }`,
                    fontSize: "14px",
                    padding: "5px",
                    color: `${
                      owner
                        ? "#88305F"
                        : collaborator
                        ? "#EE5800"
                        : approver
                        ? "#00692A"
                        : signatory
                        ? "#392494"
                        : "#88305F"
                    }`,
                    backgroundColor: `${
                      owner
                        ? "#FFEEF7"
                        : collaborator
                        ? "#FFEFE4"
                        : approver
                        ? "#D8F7E6"
                        : signatory
                        ? "#EEEDFF"
                        : "#FFEEF7"
                    }`,
                  }}
                  className="avatar-container_avatar fw d-flex align-items-center"
                >
                  <span className="initials">
                    {HeimdallUtil?.getNameForAvatar(user)}
                  </span>
                </div>
              </Tooltip>
            );
          })
        : props.users.map((user, index) => {
            const owner = user?.roles?.includes("owner");
            const approver = user?.assigned_role?.includes("Approvers");
            const collaborator = user?.assigned_role?.includes("Collaborators");
            const signatory = user?.assigned_role?.includes("Signatories");
            return (
              <div key={index}>
                <Tooltip title={user?.first_name + " " + user?.last_name}>
                  <div
                    style={{
                      border: `1px solid ${
                        owner
                          ? "#88305F"
                          : collaborator
                          ? "#EE5800"
                          : approver
                          ? "#00692A"
                          : signatory
                          ? "#392494"
                          : "#88305F"
                      }`,
                      fontSize: "14px",
                      padding: "5px",
                      color: `${
                        owner
                          ? "#88305F"
                          : collaborator
                          ? "#EE5800"
                          : approver
                          ? "#00692A"
                          : signatory
                          ? "#392494"
                          : "#88305F"
                      }`,
                      backgroundColor: `${
                        owner
                          ? "#FFEEF7"
                          : collaborator
                          ? "#FFEFE4"
                          : approver
                          ? "#D8F7E6"
                          : signatory
                          ? "#EEEDFF"
                          : "#FFEEF7"
                      }`,
                    }}
                    key={index}
                    className="avatar-container_avatar fw d-flex align-items-center"
                  >
                    <span className="initials">
                      {HeimdallUtil?.getNameForAvatar(user)}
                    </span>
                  </div>
                </Tooltip>
              </div>
            );
          })}
      {props.users.length > 4 && (
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line", width: "60px" }}>
              {props?.users?.map((tag: any) => tag.first_name).join(", ") || ""}
            </div>
          }
        >
          <div className="avatar-container_avatar fw d-flex align-items-center">
            <span className="initials">
              +{props.users.length - slicedata.length}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default Avatarfordraft;
