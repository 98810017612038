import * as React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { isNullOrUndefined } from "is-what";
import UploadFileDA from "../../UploadFile/DA";
import { Table, ProgressBar, Badge } from "react-bootstrap";

import AppActionGenerator from "../../App/Actions/actionGen";
import { History } from "history";
import { File } from "../../UploadFile/Constants/types";
import { connect } from "react-redux";
import { now } from "moment";
import { FileList } from "../State/uploadState";
import "./uploadmodal.scss";
import { truncateFileName } from "../../Utils/DataModifierUtil/dataModUtil";
import fileStatusCon from "../../UniversalComponents/Modals/FileStatusModal/Container/fileStatusCon";
import { IconContext } from "react-icons";
import { BsFillCheckCircleFill } from "react-icons/bs";
const SparkMD5 = require("spark-md5");

interface Props {
  history: History;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface Props {
  handleDialog: any;
  title: string;
  buttonTitle: string;
  openmodal: Boolean;
  handleShowBtn: Boolean;
  name: string;
  uploadsArray: FileList;
  file: FileList[];
  SelectedList: FileList[];
  fileTypeSelect: any;
  updateState: (name: string) => void;

  saveDeleteDetails: (documentName: string, uniqueFileId: string) => void;
}

const acceptedFileTypes: String[] = ["PDF", "DOC", "DOCX", "TXT"];
const Upload: React.FC<Props> = (props: Props) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [highlight, setHighlight] = useState(false);
  // const [fileTypeSelect ,setFileTypeSelect] = useState<any>("")

  const [uploadProgress, setUploadProgress] = useState<any>({});
  console.log(
    Object.values(uploadProgress).includes(100),
    "uploadedFilesuploadedFiles"
  );
  const [selectagain, setselectagain] = useState(false);
  const [rejected, setrejected] = useState<Array<string>>([]);

  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => setOpen(true);
  const inputref = React.useRef<HTMLInputElement>(null);
  const handleclick = () => {
    if (inputref.current !== null) {
      inputref.current.click();
    }
  };

  useEffect(() => {}, [uploadedFiles]);

  const [modal, setModal] = React.useState(false);

  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  const invokeHiddenInput = () => {
    let element = document.getElementById("file-input");
    if (!isNullOrUndefined(element)) {
      element.click();
    }
  };

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        function (e) {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    if (props.name === "Support Document") {
      props.updateState("Support Document");
    } else if (props.name === "Contract") {
      if (props.SelectedList.length > 0) {
        props.updateState("Amendment");
      } else {
        props.updateState("Contract");
      }
    }
  }, []);

  const handleOnChangeFileUpload = (files: any) => {
    // console.log(post,"post file");

    let files_ = uploadedFiles;
    console.log(files_, "jjjjjjjjjjj");

    // const files = post.target.files;

    const uploadProgress_ = uploadProgress;
    let a: Array<string> = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        let reader = new FileReader();
        console.log(props.SelectedList, "post");
        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            const hexHash = SparkMD5.hash(reader.result);
            console.log(file, "fileghjk");
            console.log(hexHash);

            files_.push({
              name: file.name,
              size: file.size,
              type: file.type,
              hash: hexHash,
              status: "uploading",
            });
            uploadProgress_[hexHash] = 0;
            setUploadProgress({ ...uploadProgress_ });
            const check = () => {
              console.log(props.fileTypeSelect, "fileTypeSelect");
              console.log(uploadedFiles, "uploadedFiles");
              console.log(
                props.SelectedList,
                "SelectedList",
                props.fileTypeSelect
              );

              if (props.fileTypeSelect === "Contract") {
                return {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  uploaded_type: "Contract",
                  refcontract_id: "",
                };
              } else if (props.fileTypeSelect === "Amendment") {
                return {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  uploaded_type: "Amendment",
                  refcontract_id: props?.SelectedList[0]?.id,
                };
              } else {
                return {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  uploaded_type: "Support Document",
                  refcontract_id: props?.SelectedList[0]?.id,
                };
              }
            };

            UploadFileDA.get_s3_presigned_url(check())
              .then((response) => {
                console.log("get_s3_presigned_url");
                const onHandleFileProgress = {
                  onUploadProgress: function (progressEvent: any) {
                    var percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    console.log(percentCompleted);
                    const uploadProgress_ = uploadProgress;
                    uploadProgress_[hexHash] = percentCompleted;
                    setUploadProgress({
                      ...uploadProgress_,
                    });
                  },
                };
                if (response && response.file_hash) {
                }

                if (response && response.presigned_url) {
                  const s3Info = response.presigned_url;
                  console.log(
                    "🚀 ~ file: uploadFileContainer.tsx ~ line 83 ~ .then ~ s3Info",
                    s3Info
                  );
                  UploadFileDA.upload_file_in_s3_bucket(
                    s3Info,
                    file,
                    onHandleFileProgress
                  );
                } else if (
                  response?.file_hash[0]?.includes(
                    "contract with this file hash already exists"
                  )
                ) {
                  setUploadedFiles([]);

                  setselectagain(true);
                  a = [...a, file.name];
                  setrejected(a);
                }
              })
              .catch((error) => {
                console.log(
                  "🚀 ~ file: uploadFileContainer.tsx ~ line 77 ~ handleOnChangeFileUpload ~ error",
                  error
                );

                // setUploadedFiles([]);
                setselectagain(true);
                a = [...a, file.name];
                setrejected(a);
              });
            setUploadedFiles([...files_]);
            console.log(
              // setUploadedFiles([...files_]),
              "setUploadedFiles([...files_]);"
            );
          };
        }
      }
    }
  };
  // console.log(rejected,"rejected");

  const displayDeleteModal = (name: string, fileId: string) => {
    props.saveDeleteDetails(name, fileId);
    let link = document.getElementById("deleteButton");
    !isNullOrUndefined(link) && link.click();
  };
  const onFileDrop = (event: any) => {
    let files = event.dataTransfer.files;
    console.log(files, "jkhjghf");
    // setUploadedFiles([...files]);

    handleOnChangeFileUpload(files);
    // handleOnChangeFileUpload({ target: { files } });
    setHighlight(false);
  };
  const onDragEnter = () => {
    setHighlight(true);
  };
  const onDragLeave = () => {
    setHighlight(false);
  };
  const a = (e: any) => {
    handleclick();
    props.updateState(e.target.name);

    // handleOnChangeFileUpload(e.target.files)
  };

  return (
    <div className="">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={true}
        // onClick={props.handleDialog}

        onClose={props.handleDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ width: "996px", height: "482px" }}>
          <div className="mb-2 d-flex justify-content-between">
            <span> {props.title}</span>
            <span className="mr-0" onClick={props.handleDialog}>
              <img
                src={"/static_images/cross-cancel-icn.svg"}
                alt=""
                style={{
                  height: "24px",
                  width: "24px",
                  color: "#818181",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
          <Typography
            id="modal-modal-title"
            // variant="h6"
            component="div"
            onDrop={onFileDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            style={{
              width: "100%",
              height: "325px",
              border: "1px dashed #D9D9D9",
              background: "rgba(217, 217, 217, 0.25)",
              borderColor: highlight ? "blue" : "#d9d9d9",
              // overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {" "}
            <>
              {uploadedFiles && uploadedFiles.length > 0 ? (
                <div>
                  {uploadedFiles.map((file, i) => {
                    return (
                      <div className="row w-100 mt-2 px-3">
                        <div
                          className="col-md-3 file-name-style-upload"
                          // style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          <span>{truncateFileName(file.name.toString())}</span>
                        </div>
                        <div
                          className="col-md-8 p-0"
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          <span className="">
                            {rejected.includes(file.name.toString()) ? (
                              `Already exist `
                            ) : (
                              <ProgressBar
                                // striped
                                animated
                                // variant="warning"
                                now={uploadProgress[file.hash.toString()]}
                                className="purple-progress-bar "
                                style={{
                                  height: "6px",
                                  borderRadius: "100px",
                                  marginTop: "8px",
                                  background: "#D9D9D9",
                                }}
                              />
                            )}
                          </span>
                        </div>
                        <div className="col-md-1 d-flex p-0px-2 ">
                          {rejected.includes(file.name.toString()) ? null : (
                            <span
                              style={{ fontSize: " 13px", marginRight: "10px" }}
                            >
                              {uploadProgress[file.hash.toString()] == 100 ? (
                                <>
                                  <IconContext.Provider
                                    value={{
                                      color: "#ffc107",
                                      size: "20",
                                    }}
                                  >
                                    <BsFillCheckCircleFill />
                                  </IconContext.Provider>
                                </>
                              ) : (
                                <> {uploadProgress[file.hash.toString()]}%</>
                              )}
                            </span>
                          )}
                          {uploadProgress[file.hash.toString()] == 100 ? (
                            <img
                              // onClick={() =>
                              //   displayDeleteModal(file.name, file.hash)
                              // }
                              style={{
                                cursor: "pointer",
                                height: "16px",
                                width: "16px",
                                display: "none",
                              }}
                              src="/static_images/Delete_upload.svg"
                              alt="delete-icn"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="d-flex  justify-content-center align-items-center flex-column "
                  style={{ marginTop: "7rem", gap: "20px" }}
                >
                  <div
                    className="uploadedby-style-upload"
                    style={{ display: "none" }}
                  >
                    <span className="">
                      {!selectagain
                        ? "Drag your contracts here or click the button to open file explorer"
                        : `Please select another file , ${
                            rejected.length > 0 ? rejected.map((e) => e) : ""
                          } is already exist`}
                    </span>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-warning navigate-btn "
                      onClick={handleclick}
                      // onChange={(e:any)=>handleOnChangeFileUpload(e.target.files)}
                    >
                      <span className="pr-2">
                        {" "}
                        <img src={"/static_images/addfiles-icn.svg"} alt="" />
                      </span>
                      {props.buttonTitle}
                    </button>
                  </div>
                </div>
              )}
            </>
          </Typography>
          <div className="row mt-4">
            {uploadedFiles && uploadedFiles?.length > 0 ? (
              <div className="uploadedby-style-upload col-md-12 justify-content-center">
                {/* <span>
                  Your contracts will be uploaded in the background, you can go
                  ahead and close this dialogue box.
                </span> */}
              </div>
            ) : (
              ""
            )}
            <div className="col-md-7">
              <div className="">
                {props?.handleShowBtn ? (
                  uploadedFiles && uploadedFiles.length > 0 ? (
                    " "
                  ) : (
                    <div className=" d-flex">
                      <span>
                        <input
                          type="radio"
                          className={`${
                            props.SelectedList.length < 0
                              ? ""
                              : "cursor-disabled"
                          }`}
                          name="Contract"
                          id=""
                          disabled={
                            props.SelectedList.length < 0 ? false : true
                          }
                          checked={props.fileTypeSelect === "Contract"}
                          onChange={(e: any) =>
                            props.updateState(e.target.name)
                          }
                        />{" "}
                        Upload Master Contracts
                      </span>
                      <span
                        className={`ml-4 ${
                          props.SelectedList.length > 0 ? "" : "cursor-disabled"
                        }`}
                      >
                        <input
                          type="radio"
                          disabled={
                            props.SelectedList.length > 0 ? false : true
                          }
                          checked={props.fileTypeSelect === "Amendment"}
                          name="Amendment"
                          className={`${
                            props.SelectedList.length > 0
                              ? ""
                              : "cursor-disabled"
                          }`}
                          id=""
                          // onChange={(e:any)=>{setFileTypeSelect(e.target.name)}}
                          onChange={(e: any) =>
                            props.updateState(e.target.name)
                          }
                        />{" "}
                        Upload Amendment Contract
                      </span>
                    </div>
                  )
                ) : (
                  " "
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div className="d-flex">
                <form encType="multipart/form-data">
                  <input
                    style={{ visibility: "hidden" }}
                    name="file"
                    id="file-input"
                    type="file"
                    ref={inputref}
                    className="form-control upload-yellow-btn"
                    multiple
                    accept=".pdf,.doc,.docx,.txt"
                    onChange={(e: any) =>
                      handleOnChangeFileUpload(e.target.files)
                    }
                  />
                </form>
                {uploadedFiles && uploadedFiles?.length > 0 ? (
                  Object?.values(uploadProgress).includes(100) ? (
                    <button
                      type="button"
                      className="btn btn-warning navigate-btn  "
                      onClick={() => {
                        props.handleDialog();
                        props.updateState("");
                        window.location.reload();
                      }}
                    >
                      Finish
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  <button
                    type="button"
                    className="btn btn-warning navigate-btn  "
                    name={
                      props.name === "Support Document"
                        ? props.name
                        : props.fileTypeSelect
                    }
                    // onClick={handleclick}
                    onClick={(e: any) => {
                      a(e);
                    }}
                    style={{ display: "none" }}
                    // onChange={(e: any) => props.updateState(e.target.name)}
                  >
                    <span className="pr-2">
                      {" "}
                      <img src={"/static_images/addfiles-icn.svg"} alt="" />
                    </span>
                    {props.buttonTitle}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
