/**
 * @author Aravindhakumar Raman
 * @function Fixture File / MockData File (No Data File)
 */

import React from "react";

interface Props {}

export const getFormValue = (props: Props) => {
  const ScreenTitle = "Data Dictionary";

  const TableHeader = [
    {
      id: "contractType",
      numeric: false,
      disablePadding: false,
      label: "Contract Type",
      align: "left",
    },
    {
      id: "displayName",
      numeric: true,
      disablePadding: false,
      label: "Display Name",
      align: "Center",
    },
    {
      id: "lastEditedBy",
      numeric: true,
      disablePadding: false,
      label: "Last Edited By",
      align: "Center",
    },
    {
      id: "lastEditedOn",
      numeric: true,
      disablePadding: false,
      label: "Last Edited On",
      align: "Center",
    },
    {
      id: "usedIn",
      numeric: true,
      disablePadding: false,
      label: "Used In",
      align: "Center",
    },
  ];

  const TableValues = [
    {
      contractType: "Lease Aggrement",
      displayName: "NDA",
    },
    {
      contractType: "NDA Aggrement",
      displayName: "NDA",
    },
  ];

  const contractCardContent = [
    {
      contractSectionTitle: "What are Contract Types?",
      contractSectionContent:
        "Contract type denotes the type of contract under which the contract is classified. This is not necessarily the title of your contract. This is because, contracts are often titled differently but belong to the same genre. Illustratively, a Non-Disclosure Agreement may be titled, NDA, Confidentiality Agreement, Sensitive Personal Data or Information Agreement or even a Secrecy Agreement. ",
    },
  ];

  const sectionCardContent = [
    {
      sectionTitle: "What are Section names?",
      sectionContent:
        "Section Name refers to the section to which a paragraph or sentence of the contract belongs. Sections express the main legal concept behind these paragraphs and sentences in a contract.  Paragraphs or sentences are often called clauses of a contract. A section may have several clauses and they may even be spread across the whole contract. For example, Under the Section called Payment, there may be several clauses dealing with payment of consideration, payment of interest, payment of costs, etc. and all these clauses may not come under the same heading. However, from the point of view of legal concept, all these clauses express an obligation or a right in relation to payment of monies.  Riverus provides a standard suite of 18+ sections and will keep adding more. You can use the sections to define your data dictionary or contract standards. You can also create custom section names that may be more relevant to one or more types of contracts frequently seen in your organization.",
    },
  ];

  const fieldCardContent = [
    {
      fieldCardTitle: "What are Field names?",
      fieldCardContent:
        "Fields refer to what you write within a clause. This could be actual text of a clause, or any variable content found within a clause. For example, under the Payment Section, you may have a field called 'Amount. This is equivalent to the actual 'Price' (i.e. Rs or Dollar value) of goods sold or bought. Apart from variable/pointed information like price, the actual text of a clause can be a field. Illustratively, the text of the payment clause is represented as paymentclausetext. You can create field names for sections and contracts relevant to your industry. For example, under the Section: Payment appearing in a contract for sale of apartment, you may create additional fields as saleconsiderationclausetext (Sale Consideration Clause Text), saleconsiderationamount (Sale Consideration Clause Amount), earnestmoneyclausetext (Earnest Money Clause Text), refundofearnestmoneytimeline (Refund of Earnest Money Timeline) refundofearnestmoneyinterest (Refund of Earnest Money Interest). You can be as expressive as you want in defining the field names and their character (as in text, numerical, combination, list, etc).",
    },
  ];

  const SectionDetails = [
    {
      section_title: "Basic Information",
      isRowSectionInputKey: false,
      isRowSectionDescriptionInputKey: false,
      section_Field_name: [
        {
          id: "",
          title: "Display Name",
          value: "Title",
        },
        {
          id: "",
          title: "Field Name",
          value: "Title",
        },
      ],
      section_description: "Basic info about contract and its parties",
      field_data: {
        section_title: "",
        section_details: [
          {
            id: "",
            title: "Display Name",
            value: "Title",
          },
          {
            title: "Field Name",
            value: "Title",
          },
          {
            title: "Required",
            value: true,
          },
          {
            title: "Data type",
            value: "text",
          },
          {
            title: "Field validation",
            value: "100",
          },
          {
            title: "Description",
            value: "Title in the Basic Info",
          },
          {
            title: "Set Recommended Value",
            value: "Jersica",
          },
        ],
        upload_from_clause_library: true,
        use_existing_field: false,
      },
    },
    {
      section_title: "Term Clause",
      section_Field_name: ["Title", "Party_Name_1", "Party_Name_2"],
      section_description: "Term Clause about contract and its parties",
      section_data: {
        section_title: "",
        section_details: [
          {
            title: "Display Name",
            value: "Title",
          },
          {
            title: "Field Name",
            value: "Title",
          },
          {
            title: "Required",
            value: true,
          },
          {
            title: "Data type",
            value: "text",
          },
          {
            title: "Field validation",
            value: "100",
          },
          {
            title: "Description",
            value: "Title in the Basic Info",
          },
          {
            title: "Set Recommended Value",
            value: "Jersica",
          },
        ],
        upload_from_clause_library: true,
        use_existing_field: false,
      },
    },
  ];

  return {
    TableHeader,
    ScreenTitle,
    TableValues,
    SectionDetails,
    contractCardContent,
    sectionCardContent,
    fieldCardContent,
  };
};

// contractSectionContent:"Contract type denotes the type of contract under which the contract is classified. This is not necessarily the title of your contract. This is because, contracts are often titled differently but belong to the same genre. Illustratively, a Non-Disclosure Agreement may be titled, NDA, Confidentiality Agreement, Sensitive Personal Data or Information Agreement or even a Secrecy Agreement. "
