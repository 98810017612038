export interface ClauseType {
  id: string;
  name: string;
  type: string;
}

export interface FilterType {
  id: string;
  name?: string;
  party?: String;
}

export interface FilterPayload {
  clause_present: string[];
  clause_absent: string[];
  payment: PaymentFilter;
  groups: string[];
  nature: string[];
  others: string[];
  parties: string[];
  start_date: DateFilter;
  end_date: DateFilter;
  payee: string[];
  payers: string[];
  extent_of_costs: string[];
}

export interface PaymentFilter {
  min: number;
  max: number;
  currency_type: string;
}

export interface DateFilter {
  min: string;
  max: string;
}

export const defaultFilterPayload: FilterPayload = {
  clause_absent: [],
  clause_present: [],
  payment: { min: 0, max: 0, currency_type: "" },
  groups: [],
  nature: [],
  others: [],
  parties: [],
  start_date: { max: "", min: "" },
  end_date: { max: "", min: "" },
  payee: [],
  payers: [],
  extent_of_costs: [],
};

export interface FilteredContract {
  contract_id: string;
  parties: string[];
  start_date: string;
  title: string;
}

export interface FilterApplied {
  clause_present: ClauseType[];
}

export const appliedFilter: FilterApplied = {
  clause_present: [],
};

export interface appliedFilters {
  id: string;
  name: string;
  type: string;
  filterType: string;
}

export interface SavedFilters {
  id: string;
  name: string;
  saved_data: any[];
  creation_type: string;
}
