import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  Select,
  Snackbar,
  Tab,
  Tabs,
  CircularProgress,
  FormControl,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { convertToObject } from "typescript";
import { useRef } from "react";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import { combineReducers } from "redux";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import WarningPopup from "./WarningPopup";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  minHeight: "400px",
  maxHeight: "400px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  openModal: string;
  handleCloseaddtagBtn: any;
  titleTag: any;
  addTagContractType: any;
  documentLibraryAddTagGetProjecetApi: any;
  projectData: any;
  projectDatastateUpdate: any;
  checkDocument: any;
  handleFavourites: (contracts: any, type: any) => void;
}
interface State {
  position: any;
  value: any;
  tagContractType: any;
  a: boolean;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AddFavourites: React.FC<Props> = (props: Props) => {
  const [value, setValue] = React.useState("HirarchyValueOne");

  const [parentDropdown, setParentdropdown] = React.useState();

  const [tagContractType, setTagContractType] = React.useState<any>([]);
  const [projectList, setprojectList] = React.useState<any>([]);

  const [openForm, setOpenForm] = React.useState(false);
  const [Formname, setFormName] = React.useState("");
  const [FormComment, setFormComment] = React.useState("");
  const [Formdisplayname, setFormdisplayname] = React.useState("");
  const [a, seta] = React.useState(false);
  const [loader, setloader] = useState(false);
  const [groupList, setGroupList] = React.useState<any>([]);
  const [showError, setshowError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [favouritesContracts, setfavouritesContracts] = useState([]);
  const [addfavouritesContracts, setaddfavouritesContracts] = useState([]);
  const [title, settitle] = useState<any>({
    modalRemoveFavourites: "Remove Favourites",
    modalAddFavourites: "Add Favourites",
  });

  useEffect(() => {
    setloader(false);
    if (props.checkDocument.length > 0) {
      const favouritesContracts = props.checkDocument?.filter(
        (val: any) => val.favourites == true
      );
      setfavouritesContracts(favouritesContracts);
      const addfavouritesContracts = props.checkDocument?.filter(
        (val: any) => val.favourites == false
      );
      setaddfavouritesContracts(addfavouritesContracts);
    }
  }, [props.checkDocument]);

  return (
    <>
      <Modal
        open={
          props.openModal === "modalAddFavourites" ||
          props.openModal === "modalRemoveFavourites"
        }
        className="deletemodal"
        onClose={() => props.handleCloseaddtagBtn()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={hierarchyStyle}
          className="position-relative"
          style={{ maxHeight: "unset" }}
        >
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              {/* {props.titleTag} */}
              {title[props.openModal]}
              <span
                className="mr-0"
                onClick={() => {
                  props.handleCloseaddtagBtn();
                }}
              >
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          </Typography>
          <Typography style={{ margin: "20px" }}>
            {loader && <CircularProgress className="loader" />}
            {props.openModal === "modalAddFavourites" && (
              <>
                <Scrollable maxHeight={200}>
                {addfavouritesContracts.length > 0 && (
                  <Typography
                    className="c-primary"
                    style={{ fontWeight: "900" }}
                  >
                    Are you sure you want to add these contracts to favourites?
                  </Typography>
                )}
                <div className="m-2"></div>
                
                <div className="row">
                  <div className="col-md-12">
                    
                      {addfavouritesContracts.length > 0 &&
                        addfavouritesContracts.map((contract: any, i: any) => (
                          <div className="row">
                            <div className="col-md-12">
                              <span key={i}>
                                {i + 1}. {contract.file_name}
                                {/* {contract.favourites? 'Already Exists':null} */}
                              </span>
                            </div>
                          </div>
                        ))}
                    
                    <div className="m-4"></div>
                    {favouritesContracts.length > 0 && (
                      <Typography
                        className="c-primary"
                        style={{ fontWeight: "900" }}
                      >
                        Below contracts are already in favourites.
                      </Typography>
                    )}
                    <div className="m-2"></div>
                   
                      {favouritesContracts.length > 0 &&
                        favouritesContracts.map((contract: any, i: any) => (
                          <div className="row">
                            <div className="col-md-12">
                              <span key={i}>
                                {i + 1}. {contract.file_name}
                                {/* {contract.favourites? 'Already Exists':null} */}
                              </span>
                            </div>
                          </div>
                        ))}
                    
                  </div>
                </div>
                </Scrollable>
              </>
            )}

            {props.openModal === "modalRemoveFavourites" && (
              <>
                <Typography className="c-primary" style={{ fontWeight: "900" }}>
                  Are you sure you want to remove contracts from favourites?
                </Typography>
                <div className="m-2"></div>
                <div className="row">
                  <div className="col-md-12">
                    <Scrollable maxHeight={350}>
                      {favouritesContracts.length > 0 &&
                        favouritesContracts.map((contract: any, i: any) => (
                          <div className="row">
                            <div className="col-md-12">
                              <span key={i}>
                                {i + 1}. {contract.file_name}
                              </span>
                            </div>
                          </div>
                        ))}
                    </Scrollable>
                  </div>
                </div>
              </>
            )}
          </Typography>

          <div className="delete-modal-btn">
            <button
              className="myDocument-deleteFileGoBack cursor-pointer"
              onClick={() => props.handleCloseaddtagBtn()}
              style={{background:"linear-gradient(180deg,#ffef5a,#fbce2f)"}}
            >
              Go back
            </button>
            {addfavouritesContracts.length > 0 && (
              <button
                className="myDocument-deleteFileFiled-tag cursor-pointer"
                // onClick={() => props.deleteContracts()}
                onClick={() => {
                  setloader(true);
                  props.handleFavourites(addfavouritesContracts, props.openModal);
                }}
                style={{background:"#88305f"}}
              >
                Add Favourites
                {/* {props.btn} */}
              </button>
            )}

            {favouritesContracts.length > 0 && props.openModal === "modalRemoveFavourites" && (
              <button
                className="myDocument-deleteFileFiled-tag cursor-pointer"
                // onClick={() => props.deleteContracts()}
                onClick={() => {
                  setloader(true);
                  props.handleFavourites(favouritesContracts, props.openModal);
                }}
                style={{background:"#88305f"}}
              >
                Remove Favourites
                {/* {props.btn} */}
              </button>
            )}

          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFavourites);
