import React, { useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
} from "@material-ui/core";
import { DefaultRenderSectionCard } from "../SectionCards/SectionCard";
import { EnhancedDataDictionaryTable } from "../Table/DataDictionaryTable";
import "./styles.css";
import { CircularProgress } from "@material-ui/core";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
import { IconContext } from "react-icons";
import { AiOutlineReload } from "react-icons/ai";
import { FilterData } from "../DataDictionary";

interface Props {
  ScreenTitle: String;
  TableHeader: any[];
  tableValue: any;
  setSelectedRow: any;
  selectedRow: any;
  isEditScreen: boolean;
  setIsEditScreen: any;
  history: any;
  SectionDetails: any;
  setSectionDetailsData: any;
  sectionDetailsData: any;
  selectedContractTypeId: string;
  setSelectedContractTypeId: any;
  getContractTypeBasedSectionDetails: any;
  setIsListView: any;
  isListView: string;
  contractCardContent: any;
  sectionCardContent: any;
  fieldCardContent: any;
  loading: boolean;
  setloading: any;
  modifiedDate: string;
  setIsSectionView: any;
  setIsFieldView: any;
  sorting: any;
  filteredList: any;
  setfilteredList: any;
  isAdmin: boolean;
  setContractDialog?: any | null;
  getContractTypeData?: any | null;
  applyFilter: (a: string[], b: string) => void;
  filterKeyVals: FilterData;
  userList: string[];
  updatePageNumber: (page: number) => void;
  totalCount: number;
  pageNumber: number;
  setTabName: any;
  tabName: any;
  getUserList: any;
}

export const DefaultListScreen = (props: Props) => {
  return (
    <div>
      <DefaultRenderHeader {...props} />
      <DefaultRenderSectionCard {...props} />
      <IconContext.Provider
        value={{ color: "#88305F", className: "reload-icon" }}
      >
        <AiOutlineReload
          className="ml-2"
          onClick={() => {
            props.getContractTypeData(props.pageNumber);
          }}
        />
      </IconContext.Provider>
      <DefaultRenderTable {...props} />
    </div>
  );
};

export const DefaultRenderHeader = (props: Props) => {
  const { ScreenTitle = "", modifiedDate } = props;
  return (
    <div className="cls_list_main_div">
      <div>
        <div className="stylus_section_header">{ScreenTitle}</div>
        <div className="cls_last_updated_details">
          {getDate(modifiedDate)
            ? `Last Updated on ${getDate(modifiedDate)}`
            : ""}
        </div>
      </div>
      {props.isAdmin && (
        <div>
          <span
            className="section-add-clause py-1"
            id="save-btn"
            style={{ fontSize: "13px", padding: "10px 20px" }}
            data-toggle="modal"
            data-target="#createClausePageModal"
            onClick={() => {
              props.setContractDialog(true);
            }}
          >
            <img
              src="/static_images/add-clause.svg"
              alt="refresh"
              className="mr-1"
            />{" "}
            Add New Contract Type
          </span>
        </div>
      )}
    </div>
  );
};

export const DefaultRenderTable = (props: Props) => {
  return (
    <>
      <EnhancedDataDictionaryTable {...props} />
    </>
  );
};
