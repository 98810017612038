import React from 'react'
import { ContractTypeRes } from '../State/ContractTypeState';
import ListViewHeader from './ListViewHeader';
import ListViewTable from './ListViewTable';

interface Props {
    contractTypes: ContractTypeRes[];
    getContractType: () => void;
}
const ListView = (props: Props) => {
    return (
        <div className="col-md-12 p-0" style={{}}>
            <div className="row" style={{ marginLeft: '0rem', marginRight: '0rem', backgroundColor: 'white' }}>
                <ListViewHeader />
                <ListViewTable getContractType={props.getContractType} contractTypes={props.contractTypes} />
            </div>
        </div>
    )
}

export default ListView;