import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

export default class ClauseLibraryAPI {
  public static getsectionDictionary(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static getFolderHeading(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static getFolderSubHeading(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static createFolderHeading(url: string, name: string) {
    return AxiosGateWay.post(url, { name: name })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static createFolderSubHeading(
    url: string,
    name: string,
    parent: string
  ) {
    return AxiosGateWay.post(url, {
      name: name,
      parent: parent,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static createSectionClause(
    url: string,
    section_name: string,
    clause_name: string,
    clause_type: string,
    clause_source: string,
    clause_text: string,
    description: string,
    creation_type: string,
    tags: any
  ) {
    return AxiosGateWay.post(url, {
      section_name: section_name,
      clause_name: clause_name,
      clause_type: clause_type,
      clause_source: clause_source,
      clause_text: clause_text,
      description: description,
      creation_type: creation_type,
      tags: tags,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }


  public static getSectionClause(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }

  public static createClause(
    url: string,
    clauseName: string,
    extractedText: string,
    userText: string,
    clauseType: string,
    sourceFileId: number,
    folderId: string,
    id: string
  ) {
    return AxiosGateWay.post(url, {
      name: clauseName,
      original_text: extractedText,
      text: userText,
      type: clauseType.toLowerCase(),
      // sourceFileId: sourceFileId,
      folder: id,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static editClause(
    url: string,
    clauseId: string,
    clauseName: string,
    userText: string,
    clauseType: string,
    folderId: string
  ) {
    return AxiosGateWay.put(url, {
      // clauseId: clauseId,
      name: clauseName,
      text: userText,
      original_text: userText,
      type: clauseType.toLowerCase(),
      folder: folderId,
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static deleteClause(url: string, clauseId: string) {
    return AxiosGateWay.deleteFile(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static deleteFolder(url: string, folderId: string) {
    // return AxiosGateWay.post(url, { folderId: folderId })
    // return AxiosGateWay.delete(url, {})
    return AxiosGateWay.deleteFile(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static getClauseLibraryData(url: string) {
    return AxiosGateWay.get(url)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error.response;
      });
  }
  public static getClausesData(url: string, clauseIds: string[]) {
    return (
      AxiosGateWay.get(url)
        // return AxiosGateWay.post(url, { clauseIds: clauseIds })
        .then((result) => {
          return result;
        })
        .catch((error) => {
          return error.response;
        })
    );
  }
}
