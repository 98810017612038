import {
  Box,
  CircularProgress,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabPanel } from "../../../Approvals/Component/TabPanel";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
import TemporaryDrawer from "../FieldDrawer";

type Props = {
  updateState: any;
  type: string;
  getData: any;
  data: any;
  loading: any;
  apiCall: any;
  dataType: any;
  viewAllFielUpdate: any;
};

export default function SectionViewAll(props: Props) {
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    props.getData();
  }, []);
  useEffect(() => {}, [props.data]);

  function fieldView(
    fieldName: string,
    mergeTag: string,
    displaySection: string,
    dataType: string,
    createdBy: string,
    createdOn: string,
    usedIn: string
  ) {
    return {
      fieldName,
      mergeTag,
      displaySection,
      dataType,
      createdBy,
      createdOn,
      usedIn,
    };
  }

  function sectionView(
    sectionName: string,
    sectionTag: string,
    createdBy: string,
    createdOn: string,
    usedIn: string
  ) {
    return {
      sectionName,
      sectionTag,
      createdBy,
      createdOn,
      usedIn,
    };
  }
  const [rows, setRowsData] = useState<any>([]);
  const [drawerState, setDrawerState] = React.useState(false);
  const [seletedData, setSeletedData] = useState<any>(null);
  const [type, setType] = useState<any>("");

  const setRows = (data: any, creationType: string) => {
    if (props.type === "section") {
      const defaultData = data.filter(
        (item: any) => item?.creation_type === creationType
      );
      let rows: any = [];
      defaultData.forEach((item: any) => {
        rows.push(
          sectionView(
            item?.section_name,
            item?.section_tag,
            item?.created_by?.username,
            item?.created_on,
            item?.used_id ? item?.used_id : "0 Templates/0 Drafts"
            // `${item?.used_in_templates} Templates / ${item?.used_in_drafts} Drafts`
          )
        );
      });

      setRowsData(rows);
    } else {
      const defaultData = data.filter(
        (item: any) => item?.creation_type === creationType
      );
      let rows: any = [];
      defaultData.forEach((item: any) => {
        rows.push(
          fieldView(
            item?.field_name,
            item?.section_dictionary?.section_tag,
            item?.display_name,
            item?.data_type?.data_type,
            item?.created_by?.first_name,
            item?.created_on,
            item?.used_id ? item?.used_id : "0 Templates/0 Drafts"
            // `${item?.used_in_templates} Templates / ${item?.used_in_drafts} Drafts`
          )
        );
      });
      setRowsData(rows);
    }
  };

  useEffect(() => {
    props.apiCall();

    if (props?.data?.length) {
      setRows(props.data, "system");
    }
  }, [props.data]);

  useEffect(() => {}, [rows]);
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setRows(props.data, "system");
    } else {
      setRows(props.data, "custom");
    }
  };

  useEffect(() => {}, [seletedData, props.dataType]);

  return (
    <div
      style={{
        height: "100%",
        marginLeft: "100px",
        width: "100%",
      }}
    >
      {!props?.loading ? (
        <div
          className="col-md-11 mt-5 p-0"
          style={{ zIndex: 1, maxWidth: "100%" }}
        >
          <div className="row h-100 w-100">
            <div className="mt-4 ml-3 d-flex flex-column">
              <div className="d-flex align-items-center">
                <div className="col-md-1">
                  <img
                    src="/static_images/back-arrow.svg"
                    className="cursor-pointer"
                    onClick={() => {
                      props.updateState(false);
                    }}
                    alt="close_icon"
                    style={{ width: "15px" }}
                  />
                </div>
                <div className="col-md-11">
                  <span style={{ fontSize: 22, fontWeight: 500 }}>
                    {`${
                      props.type === "section" ? "Section Names" : "Field Names"
                    }`}
                  </span>
                </div>
              </div>
              <p
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                  marginLeft: "20%",
                }}
              >
                Last Updated on 23 Nov 2022
              </p>
            </div>

            <div
              className="ml-5 h-100"
              style={{ width: `${props.type === "section" ? "92%" : "97%"}` }}
            >
              <Box
                sx={{ width: "100%", marginTop: "30px" }}
                className="view-all-card"
              >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Default" {...a11yProps(0)} />
                    <Tab label="Custom" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                  <div className="row m-0">
                    <div className="col-md-12 p-0">
                      <div className="col-md-12 p-0 view-all-card">
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              {props?.type === "section" ? (
                                <TableRow className="view-all-textalign">
                                  <TableCell>Section name</TableCell>
                                  <TableCell align="right">
                                    Section Tag
                                  </TableCell>
                                  <TableCell align="right">
                                    Created by{" "}
                                  </TableCell>
                                  <TableCell align="right">
                                    Created on{" "}
                                  </TableCell>
                                  <TableCell align="right">Used in </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow className="view-all-textalign">
                                  <TableCell>Field name</TableCell>
                                  <TableCell align="right">Merge tag</TableCell>
                                  <TableCell align="right">
                                    Display section
                                  </TableCell>
                                  <TableCell align="right">Data type</TableCell>
                                  <TableCell align="right">
                                    Created by{" "}
                                  </TableCell>
                                  <TableCell align="right">
                                    Created on{" "}
                                  </TableCell>
                                  <TableCell align="right">Used in </TableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            <TableBody>
                              {props?.type === "section"
                                ? rows?.map((row: any) => {
                                    return (
                                      <TableRow
                                        key={row?.sectionName}
                                        className="view-all-textalign"
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                          onClick={() => {
                                            const dd = props.data.filter(
                                              (item: any) =>
                                                item.section_name ===
                                                row.sectionName
                                            );
                                            setSeletedData(dd[0]);
                                            setDrawerState(true);
                                            setType("sectionName");
                                          }}
                                        >
                                          <div>{row?.sectionName}</div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#737373",
                                          }}
                                        >
                                          <div className="position-relative">
                                            <img
                                              className="view-icn position-absolute"
                                              style={{ left: "0px" }}
                                              src="/static_images/dd-view.svg"
                                              alt="clause-folder"
                                            />
                                            <div
                                              style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                marginLeft: "26px",
                                              }}
                                            >
                                              {row?.sectionTag}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          <div className="created-by-dd">
                                            {row?.createdBy[0]?.toUpperCase()}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#6D6D6D",
                                          }}
                                        >
                                          {getDate(row?.createdOn)}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row?.usedIn}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                : rows?.map((row: any) => {
                                    return (
                                      <TableRow
                                        key={row?.fieldName}
                                        className="view-all-textalign"
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const dd = props.data.filter(
                                                (item: any) =>
                                                  item.field_name ===
                                                  row.fieldName
                                              );
                                              setSeletedData(dd[0]);
                                              setDrawerState(true);
                                              setType("fieldName");
                                            }}
                                          >
                                            {row?.fieldName}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#737373",
                                          }}
                                        >
                                          <div className="position-relative">
                                            <img
                                              className="view-icn position-absolute"
                                              style={{ left: "0px" }}
                                              src="/static_images/dd-view.svg"
                                              alt="clause-folder"
                                            />
                                            <div
                                              style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                marginLeft: "26px",
                                              }}
                                            >
                                              {row?.mergeTag}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            color: "#737373",
                                          }}
                                          align="right"
                                        >
                                          {row?.displaySection}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#4E4E4E",
                                          }}
                                        >
                                          {row?.dataType}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          <div className="created-by-dd">
                                            {row?.createdBy[0]?.toUpperCase()}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#6D6D6D",
                                          }}
                                        >
                                          {/* {row.createdOn} */}
                                          {getDate(row?.createdOn)}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.usedIn}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <div className="row m-0">
                    <div className="col-md-12 p-0">
                      <div className="col-md-12 p-0 view-all-card">
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              {props.type === "section" ? (
                                <TableRow className="view-all-textalign">
                                  <TableCell>Section name</TableCell>
                                  <TableCell align="right">
                                    Section Tag
                                  </TableCell>
                                  <TableCell align="right">
                                    Created by{" "}
                                  </TableCell>
                                  <TableCell align="right">
                                    Created on{" "}
                                  </TableCell>
                                  <TableCell align="right">Used in </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow className="view-all-textalign">
                                  <TableCell>Field name</TableCell>
                                  <TableCell align="right">Merge tag</TableCell>
                                  <TableCell align="right">
                                    Display section
                                  </TableCell>
                                  <TableCell align="right">Data type</TableCell>
                                  <TableCell align="right">
                                    Created by{" "}
                                  </TableCell>
                                  <TableCell align="right">
                                    Created on{" "}
                                  </TableCell>
                                  <TableCell align="right">Used in </TableCell>
                                </TableRow>
                              )}
                            </TableHead>
                            <TableBody>
                              {props.type === "section"
                                ? rows?.map((row: any) => {
                                    return (
                                      <TableRow
                                        key={row?.sectionName}
                                        className="view-all-textalign"
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                          onClick={() => {
                                            const dd = props.data.filter(
                                              (item: any) =>
                                                item.section_name ===
                                                row.sectionName
                                            );
                                            setSeletedData(dd[0]);
                                            setDrawerState(true);
                                            setType("sectionName");
                                          }}
                                        >
                                          <div>{row?.sectionName}</div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#737373",
                                          }}
                                        >
                                          <div className="position-relative">
                                            <img
                                              className="view-icn position-absolute"
                                              style={{ left: "0px" }}
                                              src="/static_images/dd-view.svg"
                                              alt="clause-folder"
                                            />
                                            <div
                                              style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                marginLeft: "26px",
                                              }}
                                            >
                                              {row?.sectionTag}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          <div className="created-by-dd">
                                            {row?.createdBy[0]?.toUpperCase()}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#6D6D6D",
                                          }}
                                        >
                                          {getDate(row?.createdOn)}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row?.usedIn}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                : rows?.map((row: any) => {
                                    return (
                                      <TableRow
                                        key={row?.fieldName}
                                        className="view-all-textalign"
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              const dd = props.data.filter(
                                                (item: any) =>
                                                  item.field_name ===
                                                  row.fieldName
                                              );
                                              setSeletedData(dd[0]);
                                              setDrawerState(true);
                                              setType("fieldName");
                                            }}
                                          >
                                            {row?.fieldName}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#737373",
                                          }}
                                        >
                                          <div className="position-relative">
                                            <img
                                              className="view-icn position-absolute"
                                              style={{ left: "0px" }}
                                              src="/static_images/dd-view.svg"
                                              alt="clause-folder"
                                            />
                                            <div
                                              style={{
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                marginLeft: "26px",
                                              }}
                                            >
                                              {row?.mergeTag}
                                            </div>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            color: "#737373",
                                          }}
                                          align="right"
                                        >
                                          {row?.displaySection}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#4E4E4E",
                                          }}
                                        >
                                          {row?.dataType}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          <div className="created-by-dd">
                                            {row?.createdBy[0]?.toUpperCase()}
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            color: "#6D6D6D",
                                          }}
                                        >
                                          {/* {row.createdOn} */}
                                          {getDate(row?.createdOn)}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          className="c-primary"
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                          }}
                                        >
                                          {row.usedIn}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Box>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "570px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress className="loader" />
        </div>
      )}

      <TemporaryDrawer
        data={seletedData}
        dataType={props.dataType}
        setDrawerState={setDrawerState}
        drawerState={drawerState}
        viewAllFielUpdate={props.viewAllFielUpdate}
        type={type}
      />
    </div>
  );
}
