import React, { Component } from "react";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import { History } from "history";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import {
  DocumentHierarchyData,
  LibraryTagFilterStructure,
  LibraryTagData,
  TagInfo,
  BasicFileInfo,
  partyInfo,
  LibraryStatistics,
  LibraryTagDataA,
  documentLibraryInfo,
  ContractingPartiesInfo,
  DocumentTagInfo,
  BasicdocumentLibraryFileInfo,
} from "../State/documentLibraryState";
import DocumentLibraryHeader from "./documentLibraryHeader";
import HierarchyList from "./hierarchyList";
import {
  documentHierarchyTestJSON,
  editLibraryTagFilters,
  getAllFilesAtRoot,
  getAppliedTagFilterCountText,
  getFileIdsFromFiles,
  getTagIconPath,
  isFileArrayAtRootLevel,
  isTagAlreadySelected,
  sortTagsByFilters,
} from "./Utils/libraryUtils";
import HierarchyTagsList from "./hierarchyTagsList";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import AddTagModal from "../../UniversalComponents/Modals/AddTagModal/addTagModalCon";
import { isNullOrUndefined } from "is-what";
import AddHierarchyModal from "../../UniversalComponents/Modals/AddHierarchyModal/addHierarchyModalCon";
import { NoFiltersMatched } from "./TaggingComponents/noFiltersMatched";
import DocumentLibraryQuickLook from "./documentLibraryQuickLook";
import DocumentLibraryTablemodal from "../../UniversalComponents/Modals/DocumentLibraryModals/documentLibraryTableModal";
// import { Button, Modal, Table } from "react-bootstrap";
// import { Box } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { CircularProgress, IconButton, Snackbar, Tooltip, Typography } from "@material-ui/core";
import MyDocumentModal from "./mydocumentModal";
import AddHierarchy from "./AddHierarchy";
import AddTag from "./AddTag";
import SupportModal from "./SupportModal";
import FavModal from "./FavModal";
import ArchiveModal from "./ArchiveModal";
import SupportDocumentTableModal from "./SupportDocumentTableModal";

import ShareModal from "./ShareModal";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { contractPageSize } from "../../Configuration/global";
import ConnectedDocumentTableModal from "./ConnectedDocumentTableModal";
import AmendmentModal from "./AmendmentModal";
import AddFavourites from "./AddFavourites";


interface Props {
  pageWatcher: (page: string) => void;
  history: History;
  initialFileIds: string[];
  getLibraryFileId: (sort: string, order: string) => void;
  getLibraryStatistics: () => void;
  getUserUploads: () => void;
  getDocumentHierarchy: (
    sort: string,
    order: string,
    fileIds: string[]
  ) => void;
  libraryTags: LibraryTagData[];
  libraryTagsA: LibraryTagDataA[];
  getLibraryTags: () => void;
  getLibraryTagsA: () => void;
  documentLibraryLoader: boolean;
  documentHierarchy: DocumentHierarchyData[];
  savedMultipleSelectedFiles: BasicFileInfo[];
  saveMultipleSelectedFiles: (
    savedMultipleSelectionFiles: BasicFileInfo[]
  ) => void;
  savedCollapsedFileIds: string[];
  saveCollapsedFileIds: (savedCollapsedFileIds: string[]) => void;
  appliedLibraryTagFilters: LibraryTagFilterStructure[];
  applyLibraryTagFilters: (
    appliedLibraryTagFilters: LibraryTagFilterStructure[],
    initialFileIds: string[]
  ) => void;
  filteredFileIds: string[];
  selectedFile: BasicFileInfo | null;
  saveSelectedFile: (selectedFile: BasicFileInfo | null) => void;
  selectedLibraryAction: string;
  isBulkAction: boolean;
  saveSelectedLibraryAction: (
    selectedLibraryAction: string,
    isBulkAction: boolean
  ) => void;
  savedFileTagData: any[];
  saveFileTagData: (savedFileTagData: any[]) => void;
  getParentFileList: (
    selectedFileId: string,
    fileIds: string[],
    bulkFileIds: string[]
  ) => void;
  getChildrenFileList: (selectedFileId: string, fileIds: string[]) => void;
  getGeneralFilter: (fileIds: string[]) => void;
  generalFilters: any;
  generalFilterLoader: boolean;
  applyGeneralFilter: (
    fileIds: string[],
    filterType: string,
    sort: string,
    order: string
  ) => void;
  generalFilterFileIds: string[];
  documentLibrarystatistics: LibraryStatistics[];
  documentLibraryData: documentLibraryInfo[];
  getDocumentLibrarystatistics: () => void;
  documentselectedFile: BasicdocumentLibraryFileInfo | null;
  documentsaveSelectedFile: (
    documentselectedFile: BasicdocumentLibraryFileInfo | null
  ) => void;

  documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  documentsaveMultipleSelectedFiles: (
    documentsavedMultipleSelectionFiles: BasicdocumentLibraryFileInfo[]
  ) => void;
  documentfilteredFileIds: string[];
}

interface State {
  expandTags: boolean;
  documentHierarchyObj: DocumentHierarchyData[];
  tagSearchTerm: string;
  matchedTags: LibraryTagDataA[];
  seletedTagData: any[];
  filesAtRoot: BasicFileInfo[];
  selectedFilter: string;
  modalTitle: string;
  modalParties: partyInfo[];
  modalTags: TagInfo[];
  modalType: string;
  sort: string;
  order: string;
  titleOrder: boolean;
  dateOrder: boolean;
  modalContractParties: ContractingPartiesInfo[];
  modallibraryTags: DocumentTagInfo[];
  documentfilesAtRoot: BasicdocumentLibraryFileInfo[];
  fieldIds: string[];
  openModal: string;
  archiveBtn: boolean;
  hierarchyBtn: boolean;
  favouriteBtn: boolean;
  position: any;
  addtagBtn: any;
  supportBtn: any;
  archiveData: any;
  checkDocument: any;
  activeToggle: string;
  statistics: any;
  favouritedata: any;
  addTagContractType: any;
  projectData: any;
  deleteContractLoader: boolean;
  moodalContractprojects: any;
  supportDocumentData: any;
  hierarchyListLoader: boolean;
  MasterChecked: boolean;
  SelectedList: any;
  deleteItemSelect: any;
  List: any;
  supportid: any;
  FilterKeyVals: any;
  groupData: any;
  TableData: any;
  totalDocuments: number;
  page: any;
  page_size: number;
  selectedContractTypes: any;
  tagContractType: any;
  projectList: any;
  groupList:any;
  contract_type: any,
  projects_groups: any,
  filter_results: any,
  amendmentDocumentData: any;
  amendmentid: any;
  openSnackbar: any;
  pageNumber: any;
}

const deleteStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  height: "260px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

export default class DocumentLibrary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expandTags: false,
      documentHierarchyObj: documentHierarchyTestJSON,
      tagSearchTerm: "",
      matchedTags: this.props.libraryTagsA,
      seletedTagData: [],
      filesAtRoot: [],
      selectedFilter: "allDocuments",
      modalTitle: "",
      modalParties: [],
      modalTags: [],
      modalType: "",
      sort: "date",
      order: "descending", // date or title
      titleOrder: true, // false == descending or true == ascending
      dateOrder: false,
      modalContractParties: [],
      modallibraryTags: [],
      documentfilesAtRoot: [],
      fieldIds: this.props.initialFileIds,
      openModal: "",
      archiveBtn: false,
      hierarchyBtn: false,
      favouriteBtn: false,
      position: { vertical: "top", horizontal: "center" },
      addtagBtn: false,
      supportBtn: false,
      archiveData: [],
      checkDocument: [],
      activeToggle: "Active-contract",
      statistics: {},
      favouritedata: [],
      addTagContractType: [],
      projectData: [],
      deleteContractLoader: false,
      moodalContractprojects: [],
      supportDocumentData: [],
      hierarchyListLoader: false,
      MasterChecked: false,
      SelectedList: [],
      deleteItemSelect: [],
      List: [],
      supportid: "",
      FilterKeyVals: {},
      groupData: [],
      TableData: [],
      totalDocuments: -1,
      page: 1,
      page_size: contractPageSize,
      selectedContractTypes: [],
      tagContractType: [],
      projectList:[],
      groupList:[],
      contract_type: [],
      projects_groups: [],
      filter_results: [],
      amendmentDocumentData: [],
      amendmentid: "",
      openSnackbar: {
        open: false,
        msg: "",
        contracts: [],
        type: "",
      },
      pageNumber: 1,
    };
  }
  documentLibraryApi = (data: any) => {
    this.setState({pageNumber: this.state.page})
    let payload:any = data;
    payload["filter_parameter"] = "Group"
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API +
        `contracts/document-library/?page=${this.state.page}`,
        payload
    )
      .then((response) => {
        let parsed_data:any = response.data.results;
        let documentLibrayData:any[] = [];
        for(let i=0;i<response.data.results.length;i++)
        {
          let library_data:any = response.data.results[i];
          let projects_groups: any[] = [];
          for(let p=0;p<library_data.projects.length;p++){
            projects_groups.push({
              type: "projects",
              id: library_data.projects[p]['id'],
              name: library_data.projects[p]['displayName']
            })
          }
          for(let g=0;g<library_data.groups.length;g++){
            if(library_data.groups[g] !="/org" && library_data.groups[g] != '/file-manager'){
            projects_groups.push({
              type: "groups",
              id: library_data.groups[g]["id"],
              name: library_data.groups[g]["name"].split("/").pop()
            })
            }
          }
          library_data['projects_groups'] = projects_groups;
          
          documentLibrayData.push(library_data)
        }
        this.setState({
          archiveData: documentLibrayData,//response.data.results,
          hierarchyListLoader: false,
          TableData: documentLibrayData,//response.data.results,
          totalDocuments: response.data.count,
          filter_results: documentLibrayData
        });
      })
      .catch((error) => {
        this.setState({
          hierarchyListLoader: false,
        });
        console.log(error);
      });
  };
  documentstatistics = () => {
    AxiosGateWay.get(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/statistics/`
    )
      .then((response) => {
        this.setState({ statistics: response.data.contracts_statistic });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  documentLibraryArchiveApi = () => {
    const { checkDocument } = this.state;
    const id = checkDocument?.map((val: any) => val.id);
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/archive/`,
      { contract_id: id }
    )
      .then((response) => {
        this.setState({ checkDocument: [] });
        this.setState({ openModal: "" });	
        this.setState({	
          openSnackbar: {	
            open: true,	
            msg: "Contracts archived successfully",	
            contracts: [],	
          },	
        });	
        this.documentLibraryApi({ archive: true });	
        this.documentstatistics();
        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  documentLibraryUnArchiveApi = () => {
    const { checkDocument } = this.state;
    const id = checkDocument?.map((val: any) => val.id);
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/unarchive/`,
      { contract_id: id }
    )
      .then((response) => {
        this.setState({ checkDocument: [] });
        this.setState({ openModal: "" });	
        this.setState({	
          openSnackbar: {	
            open: true,	
            msg: "Contracts unarchived successfully",	
            contracts: [],	
          },	
        });	
        this.documentLibraryApi({ archive: false });	
        this.documentstatistics();
        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  documentLibraryFavouriteApi = (contract:any, type: any) => {
    const { checkDocument } = this.state;
    const id = contract?.map((val: any) => val.id);

    this.setState({ favouritedata: id });
    if(type == 'modalAddFavourites'){
    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/favourites/`,

      { contract_id: id }
    )
      .then((response) => {
        // this.setState({ archiveData: [] });
        this.documentLibraryApi({archive: false});
        this.documentstatistics();
        this.setState({ openModal: "modalFav",openSnackbar:{open:false, msg: "", contract: contract, type: 'modalRemoveFavourites'} });
        this.setState({ checkDocument: [] });
      })
      .catch((error) => {
        console.log(error);
      });
    }
    if(type == 'modalRemoveFavourites'){
      AxiosGateWay.patch(
        process.env.REACT_APP_RIVERUS_CLM_API + `contracts/favourites/`,
  
        { contract_id: id }
      )
        .then((response) => {
          // this.setState({ archiveData: [] });
          this.setState({ activeToggle: 'Favourite-contract' } )
          this.documentLibraryApi({favourites: true});
          this.documentstatistics();
          this.setState({ openModal: "" });
          this.setState({ checkDocument: [] });
          this.setState({openSnackbar: {
            open: true,
            msg: "Favourites contract removed successfully",
            contracts: []
          }})
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  documentLibraryAddTagContractApi = () => {
    const { checkDocument } = this.state;
    const id = checkDocument?.map((val: any) => val.id);
    AxiosGateWay.get(
      process.env.REACT_APP_RIVERUS_CLM_API + `admin/contracttype/`
    )
      .then((response) => {
        this.setState({ addTagContractType: response.data.results });

        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  documentLibraryAddTagGetProjecetApi = () => {
    AxiosGateWay.get(
      process.env.REACT_APP_RIVERUS_CLM_API + `umbrella/projects/`
    )
      .then((response) => {
        this.setState({ projectData: response.data.results });
        // this.setState({ addTagContractType: response.data.results });

        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  documentLibraryAddTagGetGroupApi = () => {
    AxiosGateWay.get(process.env.REACT_APP_RIVERUS_CLM_API + `users/groups/`)
      .then((response) => {
        this.setState({ groupData: response.data });
        // this.setState({ addTagContractType: response.data.results });

        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteContracts = () => {
    const { checkDocument } = this.state;
    this.setState({ deleteContractLoader: true });
    const id = checkDocument?.map((val: any) => val.id);
    axios
      .delete(
        process.env.REACT_APP_RIVERUS_CLM_API + `contracts/bulk-delete/`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
          },
          data: { id: id },
        }
      )
      .then((response) => {
        this.setState({
          deleteContractLoader: false,
          openModal: "",
          checkDocument: [],
          archiveData: [],
        });

        this.documentLibraryApi({archive: false});
        this.documentstatistics();
        this.documentLibraryAddTagContractApi();
        this.documentLibraryAddTagGetProjecetApi();
        this.documentLibraryAddTagGetGroupApi();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          deleteContractLoader: false,
          openModal: "",
          checkDocument: [],
        });
      });
  };

  updatedDocumentLibraryTagList = () => {
    let payload: any = {

    }
    if(this.state.activeToggle == 'Active-contract'){
        payload['archive'] = false;
    }
    if(this.state.activeToggle == 'Archived-contract'){
      payload['archive'] = true;
    }
    if(this.state.activeToggle == 'Favourite-contract'){
      payload['favourites'] = true;
    }
    this.setState({ checkDocument: [], hierarchyListLoader: true, contract_type:[], projects_groups: [] });
    this.documentLibraryApi(payload);
    this.documentLibraryAddTagGetProjecetApi();
    this.documentLibraryAddTagContractApi();
    this.documentstatistics();
  };

  componentDidMount() {
    let { pageWatcher, initialFileIds, getGeneralFilter, getUserUploads } =
      this.props;
    // getUserUploads();
    let { sort, order } = this.state;
    pageWatcher("documentlibrary");
    this.props.getLibraryFileId(sort, order);
    // this.props.getLibraryTags();
    // this.props.getLibraryStatistics();
    // this.props.getLibraryTagsA();
    // getGeneralFilter(initialFileIds);
    this.documentLibraryApi({archive: false});
    this.documentstatistics();
    this.documentLibraryAddTagContractApi();
    this.documentLibraryAddTagGetProjecetApi();
    this.documentLibraryAddTagGetGroupApi();
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.filteredFileIds !== null &&
      nextProps.filteredFileIds.length > 0 &&
      this.props.filteredFileIds !== nextProps.filteredFileIds
    ) {
      this.props.getDocumentHierarchy(
        this.state.sort,
        this.state.order,
        nextProps.filteredFileIds
      );
      if (nextProps.appliedLibraryTagFilters.length > 0) {
        this.props.getGeneralFilter(nextProps.filteredFileIds);
        this.setState({ selectedFilter: "allDocuments" });
      }

      if (
        nextProps.filteredFileIds !== null &&
        nextProps.filteredFileIds.length > 0 &&
        this.props.filteredFileIds !== nextProps.filteredFileIds &&
        this.props.generalFilterFileIds.length > 0
      ) {
        this.props.applyGeneralFilter(
          nextProps.filteredFileIds,
          "allDocuments",
          this.state.sort,
          this.state.order
        );
      }
    }
    if (
      this.props.initialFileIds !== nextProps.initialFileIds &&
      nextProps.initialFileIds !== null
    ) {
      this.props.getGeneralFilter(nextProps.initialFileIds);
      this.setState({ selectedFilter: "allDocuments" });
    }
    if (
      this.props.appliedLibraryTagFilters !== nextProps.appliedLibraryTagFilters
    ) {
      let sortedTags = sortTagsByFilters(
        nextProps.appliedLibraryTagFilters,
        nextProps.libraryTagsA
      );
      this.setState({ matchedTags: sortedTags });
    }

    if (
      nextProps.documentLibraryData !== null &&
      this.props.documentLibraryData !== nextProps.documentLibraryData &&
      nextProps.documentLibraryData !== undefined
    ) {
      if (nextProps.documentLibraryData.length > 0) {
        this.setState({
          filesAtRoot: getAllFilesAtRoot(nextProps.documentLibraryData),
        });
      } else {
        this.setState({ filesAtRoot: [] });
      }
    }
    if (
      this.props.libraryTagsA !== nextProps.libraryTagsA &&
      nextProps.libraryTagsA !== undefined
    ) {
      this.setState({ matchedTags: nextProps.libraryTagsA });
    }
  }

  editTagFilter(tag: LibraryTagDataA) {
    let { appliedLibraryTagFilters, initialFileIds } = this.props;
    let editedLibraryTagFilters = editLibraryTagFilters(
      appliedLibraryTagFilters,
      { tagId: tag.id, tagName: tag.name, tagCategory: "" }
    );

    this.props.applyLibraryTagFilters(editedLibraryTagFilters, initialFileIds);
  }

  getSuggestedTags(event: any) {
    let { expandTags, matchedTags } = this.state;
    let { libraryTags, libraryTagsA } = this.props;

    if (expandTags === true) {
      let searchTagName = event.target.value;
      this.setState({ tagSearchTerm: searchTagName });
      if (searchTagName === "") {
        this.setState({ matchedTags: libraryTagsA });
      } else {
        if (libraryTagsA !== null) {
          //filter mactched tags
          let tagsList = libraryTagsA;
          let filteredTags = tagsList.filter(
            (tag) =>
              tag.name.toLowerCase().indexOf(searchTagName.toLowerCase()) > -1
          );
          this.setState({ matchedTags: filteredTags });
        } else {
          this.setState({ matchedTags: libraryTagsA });
        }
      }
    }
  }

  addTagInBulk() {
    let { savedMultipleSelectedFiles, documentsavedMultipleSelectedFiles } =
      this.props;

    if (documentsavedMultipleSelectedFiles.length > 0) {
      let link = document.getElementById("addTagBulkButton");
      !isNullOrUndefined(link) && link.click();
      this.props.saveSelectedLibraryAction("tags", true);
    } else {
      //show error message, no files selected
    }
  }

  addParentInBulk() {
    let {
      savedMultipleSelectedFiles,
      initialFileIds,
      documentsavedMultipleSelectedFiles,
    } = this.props;

    if (
      savedMultipleSelectedFiles.length > 0 &&
      isFileArrayAtRootLevel(savedMultipleSelectedFiles) === true
    ) {
      let savedMultipleSelectedFilesIds = getFileIdsFromFiles(
        savedMultipleSelectedFiles
      );
      this.props.getParentFileList(
        "-1",
        initialFileIds,
        savedMultipleSelectedFilesIds
      );
      let link = document.getElementById("addParentBulkButton");
      !isNullOrUndefined(link) && link.click();
      this.props.saveSelectedLibraryAction("hierarchy", true);
    } else {
      //show error message, no files selected
    }
  }

  selectFilter = (filter: string) => {
    let { selectedFilter } = this.state;
    let {
      applyGeneralFilter,
      initialFileIds,
      filteredFileIds,
      generalFilterFileIds,
    } = this.props;
    if (selectedFilter !== filter) {
      this.setState({ selectedFilter: filter });
      applyGeneralFilter(
        filteredFileIds.length > 0 ? filteredFileIds : initialFileIds,
        filter,
        this.state.sort,
        this.state.order
      );
    } else {
      this.setState({ selectedFilter: "allDocuments" });
      applyGeneralFilter(
        filteredFileIds.length > 0 ? filteredFileIds : initialFileIds,
        "allDocuments",
        this.state.sort,
        this.state.order
      );
    }
  };

  handleClosedelete = () => this.setState({ openModal: "" });
  handleCloseArchive = () => this.setState({ openModal: "" });
  handleClosehierarchy = () => this.setState({ openModal: "" });

  handleClosefavouriteBtn = () => this.setState({ openModal: "" });

  handleCloseaddtagBtn = () => this.setState({ openModal: "" });

  handleClosesupportBtn = () => this.setState({ openModal: "" });

  handleCloseArchiveBtn = () => this.setState({ openModal: "" });
  handleCloseShareBtn = () => this.setState({ openModal: "" });
  handleCloseSupportDocumentTableBtn = () => this.setState({ openModal: "" });
  handleCloseAmendmentDocumenTableBtn = () => this.setState({ openModal: "" });
  handleCloseamendmentBtn = () => this.setState({ openModal: "" });

  handlecheckDocument = (e: any, data: any) => {
    if (e.target.checked) {
      this.setState({ checkDocument: [...this.state.checkDocument, data] });
    } else {
      const arr = [...this.state.checkDocument];
      let val = arr.filter((elem: any) => elem.id !== data.id);
      this.setState({ checkDocument: val });
    }
  };
  onMasterCheck = (e: any) => {
    const { checked } = e.target;
    let tempList = [...this.state.archiveData];
    const a = tempList.map((user: any) => (user.selected = checked));
    let checkedItem = tempList.map((arr) => {
      return { id: arr.id, name: arr.file_name };
    });
    this.setState({
      MasterChecked: e.target.checked,
      archiveData: tempList,
      checkDocument: this.state.archiveData.filter((e: any) => e.selected),
      deleteItemSelect: checkedItem,
    });
  };
  onItemCheck = (e: any, item: any) => {
    if (e.target.checked) {
      this.setState({
        deleteItemSelect: [
          ...this.state.deleteItemSelect,
          { name: item.file_name, id: item.id },
        ],
      });
    } else {
      let a = this.state.deleteItemSelect.filter((i: any) => i.id !== item.id);
      this.setState({ deleteItemSelect: a });
    }

    let tempList = [...this.state.archiveData];

    tempList?.map((user: any) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State

    const totalItems = this.state?.archiveData?.length;
    // console.log(totalItems, "totalItems");

    const totalCheckedItems = tempList.filter((e: any) => e.selected).length;

    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      checkDocument: this.state.archiveData.filter((e: any) => e.selected),
    });
  };

  handleTotalContracts = () => {
    this.setState({
      archiveData: [],
      hierarchyListLoader: true,
      checkDocument: [],
      page: 1,
      activeToggle: 'Active-contract'
    });
    this.documentLibraryApi({archive: false});
    this.documentstatistics();
    
  }

  handleTodaysUploads = () => {
    this.setState({
      archiveData: [],
      hierarchyListLoader: true,
      checkDocument: [],
      page: 1,
      activeToggle: 'Active-contract'
    });
    this.documentLibraryApi({newly_contract: true, archive: false});
    this.documentstatistics();
  }

  handleTask = () =>{
    this.setState({
      archiveData: [],
      hierarchyListLoader: true,
      checkDocument: [],
      page: 1,
      activeToggle: 'Active-contract'
    });
    this.documentLibraryApi({task: true, archive: false});
    this.documentstatistics();
       
  }

  handleToggle = (data: any) => {
    this.setState({
      archiveData: [],
      hierarchyListLoader: true,
      checkDocument: [],
      page: 1,
    });
    if (data == "Archived-contract") {
      this.setState({ activeToggle: data }, () => {
        this.documentLibraryApi({archive: true});
        this.documentstatistics();
      });
    }else if(data == "Favourite-contract"){
      this.setState({ activeToggle: data }, () => {
        this.documentLibraryApi({favourites: true});
        this.documentstatistics();
      });
    } else {
      this.setState({ activeToggle: data }, () => {
        this.documentLibraryApi({archive: false});
        this.documentstatistics();
      });
    }
  };
  handlesupportmodal = () => {
    this.setState({
      openModal: "modalSupportDocTable",
    });
  };

  handleAmendment = () => {
    this.setState({
      openModal: "modalAmendmentTable",
    });
  }

  handleopensupportmodal = () => {
    this.setState({ openModal: "modalSupport" });
  };
  handleSupportDocumentId = (id: any) => {
    this.setState({ supportid: id });
  };
  handleAmendmentId =  (id: any) => {
    this.setState({ amendmentid: id });
  };
  handleopenamendmentmodal = () => {
    this.setState({ openModal: "modalAmendment" });
  };

  // applySort = (nameSort: boolean, sortname: string) => {
  //   let { archiveData } = this.state;
  //   if (sortname === "file_name") {
  //     if (nameSort) {
  //       archiveData.sort((a: any, b: any) => {
  //         if (
  //           a.file_name.toLocaleLowerCase() > b?.file_name.toLocaleLowerCase()
  //         ) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     } else {
  //       archiveData.sort((a: any, b: any) => {
  //         if (
  //           a.file_name.toLocaleLowerCase() < b?.file_name.toLocaleLowerCase()
  //         ) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     }
  //   }
  //   if (sortname === "created_on") {
  //     if (nameSort) {
  //       archiveData.sort((a: any, b: any) => {
  //         if (a.support_document.length > b?.support_document.length) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     } else {
  //       archiveData.sort((a: any, b: any) => {
  //         if (a.support_document.length < b?.support_document.length) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     }
  //   }
  //   if (sortname === "file_size") {
  //     if (nameSort) {
  //       archiveData.sort((a: any, b: any) => {
  //         if (a.file_size > b?.file_size) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     } else {
  //       archiveData.sort((a: any, b: any) => {
  //         if (a.file_size < b?.file_size) {
  //           return 1;
  //         }
  //         return -1;
  //       });
  //       this.setState({ archiveData: archiveData });
  //     }
  //   }
  // };
  applySort = (nameSort: boolean, sortname: string) => {
    // console.log("apply sort", nameSort, sortname)
    let { archiveData } = this.state;
    if (sortname === "file_name") {
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          if (
            a.file_name.toLocaleLowerCase() > b?.file_name.toLocaleLowerCase()
          ) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          if (
            a.file_name.toLocaleLowerCase() < b?.file_name.toLocaleLowerCase()
          ) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      }
    }
    if (sortname === "support_document") {
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          if (a.support_document.length > b?.support_document.length) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          if (a.support_document.length < b?.support_document.length) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      }
    }
    if (sortname === "file_size") {
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          if (a.file_size > b?.file_size) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          if (a.file_size < b?.file_size) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      }
    }
    if(sortname === "end_date"){
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          if (a && a.end && a.end.length && b && b.end && b.end.length) {
            return new Date(a.end).getTime() - new Date(b.end).getTime()
          } else if (a && a.end && a.end.length) {
            return -1;
          } else if (b && b.end && b.end.length) {
            return 1;
          }
          return 0;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          if (a && a.end && a.end.length && b && b.end && b.end.length) {
            return new Date(b.end).getTime() - new Date(a.end).getTime()
          } else if (a && a.end && a.end.length) {
            return -1;
          } else if (b && b.end && b.end.length) {
            return 1;
          }
          return 0;
        });
        this.setState({ archiveData: archiveData });
      }
    }
    if (sortname === "amendment") {
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          if (a.amendment.length > b?.amendment.length) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          if (a.amendment.length < b?.amendment.length) {
            return 1;
          }
          return -1;
        });
        this.setState({ archiveData: archiveData });
      }
    }
    if (sortname === "connected_docs") {
      if (nameSort) {
        archiveData.sort((a: any, b: any) => {
          return a?.connected_docs - b?.connected_docs;
        });
        this.setState({ archiveData: archiveData });
      } else {
        archiveData.sort((a: any, b: any) => {
          return b?.connected_docs - a?.connected_docs;
        });
        this.setState({ archiveData: archiveData });
      }
    }
  };

  applyFilter = (searchKey: string[], filterKey: string) => {

    let { TableData, filter_results } = this.state;
    // console.log("filter_results", filter_results, filterKey)
    if (searchKey?.length) {
      if (filterKey == "project") {
        
        const data = filter_results.map((val: any) => {
          let a = val.projects_groups.filter((item: any) =>
            searchKey.includes((item.name).split('/').pop().toLowerCase())
          );
          if (a?.length) {
            return val;
          }
        });
        
        const updatData = data?.filter((val: any) => val !== undefined);
        this.setState({ archiveData: updatData, filter_results: updatData, projects_groups:searchKey  });
      } else if (filterKey == "contract_type") {
        
        const data = filter_results.map((val: any) => {
          let a = val.contract_type.filter((item: any) =>
            searchKey.includes(item.displayName.toLowerCase())
          );
          if (a?.length) {
            return val;
          }
        });
        const updatData = data?.filter((val: any) => val !== undefined);
        this.setState({ archiveData: updatData, filter_results: updatData, contract_type: searchKey });
      }
    } else {
      if(filterKey == "contract_type" && this.state.projects_groups.length > 0){
        const data = TableData.map((val: any) => {
          let a = val.projects_groups.filter((item: any) =>
          this.state.projects_groups.includes(item.name.toLowerCase())
          );
          if (a?.length) {
            return val;
          }
        });
        const updatData = data?.filter((val: any) => val !== undefined);
        this.setState({ archiveData: updatData, filter_results: updatData, contract_type:[]  });
      }
      else if(filterKey == "project" && this.state.contract_type.length > 0){
        const data = TableData.map((val: any) => {
          let a = val.contract_type.filter((item: any) =>
          this.state.contract_type.includes(item.displayName.toLowerCase())
          );
          if (a?.length) {
            return val;
          }
        });
        const updatData = data?.filter((val: any) => val !== undefined);
        this.setState({ archiveData: updatData, filter_results: updatData, projects_groups: [] });
      }else{
        this.setState({ archiveData: TableData, filter_results: TableData, projects_groups: [], contract_type: [] });
      }
    }

    this.setState({
      FilterKeyVals: {
        ...this.state.FilterKeyVals,
        [filterKey]: [...searchKey],
      },
    });
  
    
  };

  handleChange=(e:any)=>{
    
    if(isNaN(parseInt(e.target.value))){
   
      this.setState({page:""})
    }else{


      if(parseInt(e.target.value) > Math.ceil(
        this.state
          .totalDocuments /
          10)){
           
          this.setState({
            page: Math.ceil(
              this.state
                .totalDocuments /
                10)
          })
      }else{
      
        this.setState({page:parseInt(e.target.value)})
      }
  }
  }
  render() {
    let {
      history,
      initialFileIds,
      libraryTags,
      getLibraryTags,
      getLibraryTagsA,
      documentHierarchy,
      documentLibraryLoader,
      savedMultipleSelectedFiles,
      saveMultipleSelectedFiles,
      savedCollapsedFileIds,
      saveCollapsedFileIds,
      appliedLibraryTagFilters,
      applyLibraryTagFilters,
      selectedFile,
      saveSelectedFile,
      saveSelectedLibraryAction,
      savedFileTagData,
      saveFileTagData,
      getParentFileList,
      getChildrenFileList,
      filteredFileIds,
      getDocumentHierarchy,
      generalFilters,
      generalFilterFileIds,
      documentLibrarystatistics,
      documentLibraryData,
      documentselectedFile,
      documentsaveSelectedFile,
      documentsavedMultipleSelectedFiles,
      documentsaveMultipleSelectedFiles,
    } = this.props;

    // documentLibraryLoader = false;

    let {
      expandTags,
      tagSearchTerm,
      matchedTags,
      filesAtRoot,
      selectedFilter,
      modalTitle,
      modalParties,
      modalTags,
      modalType,
      modalContractParties,
      modallibraryTags,
      documentfilesAtRoot,
      statistics,
      moodalContractprojects,
    } = this.state;

    return (
      <div className="row">
        <div className="col-md-1" style={{ zIndex: 2 }}>
          <SideNavbar history={history} />
        </div>
        <div className="col-md-11 mt-5" style={{ zIndex: 1 }}>
          {documentLibraryLoader ? (
            <>
              <BarLoader />
              {/* <div className="row">
                <div className="col-md-12 mt-3">
                  <DocumentLibraryQuickLook
                    selectFilter={this.selectFilter}
                    selectedFilter={selectedFilter}
                    generalFilter={generalFilters}
                    initialFileidsCount={initialFileIds.length}
                    documentLibrarystatistics={documentLibrarystatistics}
                  />
                </div>
              </div> */}
            </>
          ) : (
            <>
              {/* {JSON.stringify(documentLibrarystatistics)} */}
              {
                // !(initialFileIds !== null && initialFileIds.length > 0) &&
                // !(documentHierarchy !== null && documentHierarchy.length > 0) ? (
                /*!(
                  documentLibrarystatistics !== null &&
                  documentLibrarystatistics.length > 0
                ) */
                !(initialFileIds !== null && initialFileIds.length > 0) ? (
                  <div className="row">
                    <div className="col-md-10 text-center mt-5 ml-5">
                      <div className="tagline">
                        Digitize your contracts and get instant insights!
                      </div>
                      <img
                        className="cursor-pointer"
                        src="/static_images/go-back-upload-img.svg"
                        onClick={() => (window.location.href = "/uploads")}
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <div className="row">
                          <div className="col-md-10 mt-3">
                            <div className="row pl-0">
                              <div className="col-md-12 pl-0 ">
                                <h4 className="myDocument-pageTitle">
                                  My Documents
                                </h4>
                              </div>
                              <div className="d-flex mt-3 pl-0" style={{cursor:"pointer"}} 
                                
                              >
                                <div className="mr-4" style={{cursor:"pointer"}}
                                onClick={()=>{
                                  this.handleTotalContracts()
                                }}
                                >
                                  <p className="myDocument-uploadNo">
                                    {statistics?.contracts_count}
                                  </p>
                                  <p className="myDocument-uploadtype">
                                    TOTAL CONTRACTS
                                  </p>
                                </div>
                                <div className="mr-4" style={{cursor:"pointer"}} onClick={
                                  ()=>{
                                    this.handleTodaysUploads()
                                  }
                                }>
                                  <p className="myDocument-uploadNo">
                                    {statistics?.newly_contracts_count}
                                  </p>
                                  <p className="myDocument-uploadtype">
                                    {/* NEWLY UPLOADED */}
                                    UPLOADED TODAY
                                  </p>
                                </div>
                                <div className="mr-4" style={{cursor:"pointer"}}
                                  onClick={()=>{
                                    this.handleTask()
                                  }}
                                >
                                  <p className="myDocument-uploadNo">
                                    {statistics?.contracts_with_task_count}
                                  </p>
                                  <p className="myDocument-uploadtype">
                                    WITH TASK
                                  </p>
                                </div>
                                <div className="mr-4" style={{cursor:"pointer"}}  
                                  onClick={() => {
                                    this.handleToggle("Active-contract");
                                  }}>
                                  <p className="myDocument-uploadNo">
                                    {statistics?.active_contracts_count}
                                  </p>
                                  <p className="myDocument-uploadtype">
                                    ACTIVE CONTRACTS
                                  </p>
                                </div>
                                <div className="mr-4" style={{cursor:"pointer"}}
                                 onClick={() =>
                                  this.handleToggle("Archived-contract")
                                }
                                >
                                  <p className="myDocument-uploadNo">
                                    {statistics?.archived_contracts_count}
                                  </p>
                                  <p className="myDocument-uploadtype">
                                    ARCHIVED CONTRACTS
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            className="col-md-2 mt-3"
                            style={{ paddingRight: "2.1rem" }}
                          >
                            <button
                              type="button"
                              className="upload-yellow-btn float-right"
                              style={{ padding: "10px 40px" }}
                              onClick={() =>
                                (window.location.href = "/uploads")
                              }
                            >
                              <img
                                src="/static_images/upload-btn-img.svg"
                                alt="btn-img"
                              />
                              &nbsp;Upload
                            </button>
                          </div> */}
                        </div>
                        <div
                          className="row"
                          style={{ marginTop: "10px", marginRight: "20px" }}
                        >
                          <div className="col-md-12 ">
                            <div className="row">
                              <div
                                className="col-md-12 pl-0  d-flex"
                                style={{
                                  backgroundColor: "#FFFFFF",
                                  height: "45px",
                                  borderRadius: "100px",
                                }}
                              >
                                <div
                                  className={`${
                                    this.state.activeToggle == "Active-contract"
                                      ? "myDocument-active cursor-pointer"
                                      : "myDocument-archive pr-4 cursor-pointer"
                                  }`}
                                  style={
                                    this.state.activeToggle == "Active-contract"
                                      ? {}
                                      : { marginTop: "15px" }
                                  }
                                  onClick={() => {
                                    this.handleToggle("Active-contract");
                                  }}
                                >
                                  {`Active Contracts (${statistics?.active_contracts_count})`}
                                </div>
                                <div
                                  className={`${
                                    this.state.activeToggle ==
                                    "Archived-contract"
                                      ? "myDocument-active cursor-pointer"
                                      : "myDocument-archive pl-4 cursor-pointer"
                                  }`}
                                  style={
                                    this.state.activeToggle ==
                                    "Archived-contract"
                                      ? {}
                                      : { marginTop: "15px" }
                                  }
                                  onClick={() =>
                                    this.handleToggle("Archived-contract")
                                  }
                                >
                                  {`Archived Contracts (${statistics?.archived_contracts_count})`}
                                </div>
                                <div
                                  className={`${
                                    this.state.activeToggle ==
                                    "Favourite-contract"
                                      ? "myDocument-active cursor-pointer ml-4"
                                      : "myDocument-archive pl-4 cursor-pointer"
                                  }`}
                                  style={
                                    this.state.activeToggle ==
                                    "Favourite-contract"
                                      ? {}
                                      : { marginTop: "15px" }
                                  }
                                  onClick={() =>
                                    this.handleToggle("Favourite-contract")
                                  }
                                >
                                  {`Favourite Contracts (${statistics?.favourite_contracts_count})`}
                                </div>
                              </div>
                            </div>

                            {/* <DocumentLibraryQuickLook
                              selectFilter={this.selectFilter}
                              selectedFilter={selectedFilter}
                              generalFilter={generalFilters}
                              initialFileidsCount={initialFileIds.length}
                              documentLibrarystatistics={
                                documentLibrarystatistics
                              }
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-12 d-flex  pl-0 mt-3"
                        style={{ paddingRight: "0px" }}
                      >
                        <div
                          className="row col-md-6  d-flex "
                          // style={{ marginLeft: "0px", paddingLeft: "0px" }}
                        >
                          {/* <input
                              className="checkbox ml-2"
                              style={{ width: "24px", height: "30px" }}
                              type="checkbox"
                              name="check"
                              id="check"
                              
                            /> */}

                          {/* <img
                            src={"/static_images/clause_drop.svg"}
                            alt=""
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                          /> */}

                          <div>
                            <img
                              src={"/static_images/uploadRefreshIcon.svg"}
                              alt=""
                              style={{
                                width: "24px",
                                height: "24px",
                                marginLeft: "10px ",
                              }}
                              onClick={() =>
                                this.updatedDocumentLibraryTagList()
                              }
                            />
                          </div>
                        </div>
                        {this.state.checkDocument &&
                        this.state.checkDocument.length ? (
                          <div className="col-md-6 d-flex justify-content-end">
                            {this.state.activeToggle == "Active-contract" ? (
                              <>
                              <Tooltip title="Upload Support Documents" placement="top">
                                <img
                                  src={"/static_images/Icon Library (6).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ openModal: "modalSupport" })
                                  }
                                />
                              </Tooltip>
                              <Tooltip title="Add Tag" placement="top">
                                <img
                                  src={"/static_images/Icon Library (5).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>{
                                    this.setState({ openModal: "modalAddTag" })
                                    
                                    if(this.state.checkDocument.length==1){
                                      let groups_data:any[]=[];
                                      for(let group_i=0;group_i<this.state.checkDocument[0].groups.length;group_i++){
                                        let index = this.state.groupData.findIndex((obj: any) => obj.name === this.state.checkDocument[0].groups[group_i]);
                                        if (index >-1) {
                                          groups_data.push(this.state.groupData[index])
                                        }
                                      }
                                     
                                      /*const data = this.state.addTagContractType?.filter(
                                        (val: any) => val.id == this.state.checkDocument[0].contract_type[0]['id']
                                      );*/
                                      this.setState({tagContractType:this.state.checkDocument[0].contract_type, 
                                        projectList: this.state.checkDocument[0].projects,
                                        groupList: this.state.checkDocument[0].groups
                                      })
                                      
                                    }else{
                                      this.setState({tagContractType:[], projectList:[], groupList: []})
                                    }
                                  }
                                  }
                                />
                              </Tooltip>
                              <Tooltip title="Add To Favourites" placement="top">
                                <img
                                  src={"/static_images/Icon Library (4).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.setState({ openModal: "modalAddFavourites" });
                                    // this.documentLibraryFavouriteApi();
                                  }}
                                />
                              </Tooltip>
                              </>
                            ) : (
                              ""
                            )}
                            {(this.state.activeToggle == "Active-contract" || this.state.activeToggle  == "Archived-contract") && (
                              <Tooltip title={this.state.activeToggle == "Active-contract"?'Add To Archive': 'Add To Unarchive'} placement="top">
                                <img
                                  src={"/static_images/Icon Library (3).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ openModal: "modalArchive" })
                                  }
                                />
                              </Tooltip>
                            )}
                            {this.state.activeToggle == "Active-contract" ? (
                              <>
                              <Tooltip title="Share Contracts" placement="top">
                                <img
                                  src={"/static_images/Icon Library (2).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({ openModal: "modalShare" })
                                  }
                                />
                                </Tooltip>
                                <Tooltip title="Add To Hierarchy" placement="top">
                                <img
                                  src={"/static_images/Icon Library (1).svg"}
                                  alt=""
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "10px ",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      openModal: "modalHierarchy",
                                    })
                                  }
                                />
                                </Tooltip>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.activeToggle == 'Favourite-contract' &&(
                              <Tooltip title="Remove Favourites" placement="top">
                              <img
                                src={"/static_images/Icon Library (4).svg"}
                                alt=""
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  marginLeft: "10px ",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.setState({ openModal: "modalRemoveFavourites" });
                                  // this.documentLibraryFavouriteApi();
                                }}
                              />
                            </Tooltip>
                            )}
                            <Tooltip title="Delete Contracts" placement="top">
                            <img
                              src={"/static_images/section-clause-delete.svg"}
                              alt=""
                              style={{
                                width: "24px",
                                height: "24px",
                                marginLeft: "10px ",
                                paddingRight: "0px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  openModal: "modalDelete",
                                  deleteContractLoader: false,
                                })
                              }
                            />
                            </Tooltip>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="row">
                          {/* <div className="col-md-5">
                            <div className="row">
                              <span className="mr-3">
                                <button
                                  type="button"
                                  className="library-yellow-btn"
                                  style={{ padding: "6px 15px" }}
                                  onClick={() => this.addTagInBulk()}
                                >
                                  <img
                                    src="/static_images/tag-btn-img.svg"
                                    alt="add-tag-bulk"
                                  />
                                  &nbsp;Add Tag
                                </button>
                              </span>
                              <span className="mr-2">
                                <button
                                  type="button"
                                  className={
                                    isFileArrayAtRootLevel(
                                      savedMultipleSelectedFiles
                                      // documentsavedMultipleSelectedFiles
                                    ) === true
                                      ? "library-yellow-btn"
                                      : "library-disable-btn"
                                  }
                                  style={{ padding: "7px 15px" }}
                                  onClick={() => this.addParentInBulk()}
                                >
                                  <img
                                    src="/static_images/hierarchy-btn-img.svg"
                                    alt="btn-img"
                                  />
                                  &nbsp;Add Parent
                                </button>
                              </span>
                            </div> 
                          </div> */}
                          <div className="col-md-4"></div>
                          <div
                            className="col-md-2 px-0 ml-5 filter-shadow-boxing cursor-pointer"
                            style={{ right: "-2.5vw" }}
                          >
                            <div className="row"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {filteredFileIds.length === 0 &&
                    appliedLibraryTagFilters.length > 0 ? (
                      <div className="col-md-11 px-0">
                        <div className="row">
                          <div className="col-md-12">
                            <NoFiltersMatched
                              getDocumentHierarchy={getDocumentHierarchy}
                              initialFileIds={initialFileIds}
                              applyLibraryTagFilters={applyLibraryTagFilters}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="row mr-5"
                        onClick={() => this.setState({ expandTags: false })}
                      >
                        <div className="col-md-12 px-0">
                          <div className="row">
                            {appliedLibraryTagFilters.length > 0 && (
                              <div
                                className="col-md-12 mt-2 ml-3 pl-0 filter-info-container"
                                style={{
                                  display: "inline-flex",
                                  boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <span className="ml-4">
                                  Showing{" "}
                                  {this.props.documentLibraryData.length}/
                                  {/* {generalFilters["allDocuments"]}/ */}
                                  {initialFileIds.length} Documents
                                </span>
                                &nbsp;
                                <span
                                  className="clear-filter-link mt-1"
                                  onClick={() => window.location.reload()}
                                >
                                  Clear filters
                                </span>
                              </div>
                            )}
                            <div
                              className="col-md-12 ml-3 pl-0"
                              // className="col-md-12 ml-3 pl-0 library-header-container"
                              // style={{
                              //   display: "inline-flex",
                              //   marginTop:
                              //     appliedLibraryTagFilters.length > 0
                              //       ? ""
                              //       : "0.5rem",
                              //   boxShadow:
                              //     appliedLibraryTagFilters.length > 0
                              //       ? ""
                              //       : "1px 2px 8px rgba(0, 0, 0, 0.15)",
                              // }}
                            >
                              <DocumentLibraryHeader
                                archiveData={this.state.archiveData}
                                onMasterCheck={this.onMasterCheck}
                                MasterChecked={this.state.MasterChecked}
                                applySort={this.applySort}
                                applyFilter={this.applyFilter}
                                FilterKeyVals={this.state.FilterKeyVals}
                                prodectData={this.state.projectData}
                                addTagContractType={
                                  this.state.addTagContractType
                                }
                                achiveUpdate={(val: any) =>
                                  this.setState({ archiveData: val })
                                }
                                TableData={this.state.TableData}
                                contractPageCount={this.state.page}
                                groupData={this.state.groupData}
                              />
                            </div>
                            <div className="col-md-12 ml-3 pl-0">
                              {/* <Scrollable
                                maxHeight={420}
                                minHeight={"50vh"}
                                key="hierarchyListscroll"
                              > */}
                                {this.state.hierarchyListLoader ? (
                                  <CircularProgress className="loader" />
                                ) : (
                                  <>
                                    <HierarchyList
                                      archiveData={this.state.archiveData}
                                      checkDocument={this.state.checkDocument}
                                      handlecheckDocument={
                                        this.handlecheckDocument
                                      }
                                      onItemCheck={this.onItemCheck}
                                      handleCloseSupportDocumentTableBtn={
                                        this.handleCloseSupportDocumentTableBtn
                                      }
                                      setModal={(
                                        type: string,
                                        title: string,
                                        parties: ContractingPartiesInfo[],
                                        projects: any[]
                                      ) =>
                                        this.setState({
                                          modalType: type,
                                          modalTitle: title,
                                          modalContractParties: parties,
                                          moodalContractprojects: projects,
                                        })
                                      }
                                      openModal={this.handlesupportmodal}
                                      handleSupportDocument={(
                                        contractData: any
                                      ) => {
                                        this.setState({
                                          supportDocumentData: [contractData],
                                        });
                                      }}
                                      handleSupportDocumentId={
                                        this.handleSupportDocumentId
                                      }
                                      applyFilter={this.applyFilter}

                                      openAmendmentModal={this.handleAmendment}
                                      handleAmendmentDocument={(
                                        contractData: any
                                      ) => {
                                        this.setState({
                                          amendmentDocumentData: [contractData],
                                        });
                                      }}
                                      handleAmendmentDocumentId={
                                        this.handleAmendmentId
                                      }
                                     
                                    />
                                    
                                  </>
                                )}
                                
                                {
                                  // <div
                                  //   className="row"
                                  //   style={{ background: "white" }}
                                  //   key="hierarchyList"
                                  // >
                                  //   <div className="row">
                                  //     <div className="col-bd-12">
                                  //
                                  //
                                  //       {/* <div className="col-md-3 px-0">
                                  //                                        {/* column for parties
                                  //                                       </div>
                                  //                                       <div className='col-md-1 px-0'>
                                  //                                        {/* column for start-date
                                  //                                        </div> */}
                                  //       {/* <div
                                  //         className="col-md-5 px-0"
                                  //         key="hierarchytaglist"
                                  //       >
                                  //         <HierarchyTagsList
                                  //           documentChildren={documentHierarchy}
                                  //           initialFileIds={initialFileIds}
                                  //           savedMultipleSelectedFiles={
                                  //             savedMultipleSelectedFiles
                                  //           }
                                  //           saveMultipleSelectedFiles={
                                  //             saveMultipleSelectedFiles
                                  //           }
                                  //           savedCollapsedFileIds={
                                  //             savedCollapsedFileIds
                                  //           }
                                  //           saveCollapsedFileIds={
                                  //             saveCollapsedFileIds
                                  //           }
                                  //           appliedLibraryTagFilters={
                                  //             appliedLibraryTagFilters
                                  //           }
                                  //           applyLibraryTagFilters={
                                  //             applyLibraryTagFilters
                                  //           }
                                  //           saveSelectedFile={saveSelectedFile}
                                  //           saveSelectedLibraryAction={
                                  //             saveSelectedLibraryAction
                                  //           }
                                  //           savedFileTagData={savedFileTagData}
                                  //           saveFileTagData={saveFileTagData}
                                  //           selectedFile={selectedFile}
                                  //           getParentFileList={
                                  //             getParentFileList
                                  //           }
                                  //           getChildrenFileList={
                                  //             getChildrenFileList
                                  //           }
                                  //           setModal={(
                                  //             type: string,
                                  //             title: string,
                                  //             tags: DocumentTagInfo[] //TagInfo[]
                                  //           ) =>
                                  //             this.setState({
                                  //               modalType: type,
                                  //               modalTitle: title,
                                  //               modallibraryTags: tags,
                                  //             })
                                  //           }
                                  //           documentLibraryData={
                                  //             documentLibraryData
                                  //           }
                                  //           documentselectedFile={
                                  //             documentselectedFile
                                  //           }
                                  //           documentsaveSelectedFile={
                                  //             documentsaveSelectedFile
                                  //           }
                                  //           initalLevel={0}
                                  //         />
                                  //       </div>*/}
                                  //     </div>
                                  //   </div>
                                  // </div>
                                }
                                {/* <p>
                                Displaying {this.state.page} to {this.state.TableData.length} of {this.state.totalDocuments} items
                              </p> */}
                              {/* </Scrollable> */}

                              <>
                                {this.state.totalDocuments > 0 ? (
                                      <>
                                        <br />
                                        <div className="d-flex flex-row-reverse">
                                          <div>
                                            <nav aria-label="Page navigation example">
                                              <ul className="pagination justify-content-end">
                                                <li
                                                  className="page-item"
                                                  onClick={() => {
                                                    if (
                                                      this.state.page != 1 &&
                                                      this.state
                                                        .totalDocuments != 0
                                                    ) {
                                                      this.setState(
                                                        {
                                                          page:
                                                          (isNaN(parseInt(this.state.page))?2:parseInt(this.state.page)) - 1,
                                                          hierarchyListLoader:
                                                            true,
                                                        },
                                                        () => {
                                                          if (
                                                            this.state
                                                              .activeToggle ==
                                                            "Archived-contract"
                                                          ) {
                                                            this.documentLibraryApi(
                                                              {archive: true}
                                                            );
                                                          } else {
                                                            this.documentLibraryApi(
                                                              {archive: false}
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <a
                                                    className="page-link"
                                                    aria-label="Previous"
                                                    style={{
                                                      // color: "#88305f",

                                                      backgroundColor:
                                                        "#88305f",
                                                      borderColor: "#88305f",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <span aria-hidden="true">
                                                      &laquo;
                                                    </span>
                                                    <span className="sr-only">
                                                      Previous
                                                    </span>
                                                  </a>
                                                </li>
                                                <li className="page-item ">
                                                <input type="number" 

                                                  style={{
                                                                                                        
                                                    height: "38px",
                                                    width: "45px",
                                                    borderTopColor: "#88305f",
                                                    borderBottomColor:
                                                      "#88305f",
                                                  }}
                                                
                                                  value={this.state.page}

                                                    maxLength={Math.ceil(
                                                      this.state
                                                        .totalDocuments /
                                                        this.state.page_size
                                                    )}
                                                    
                                                onKeyDown={(e)=>{
                                                  if(e.key == 'Enter'){
                                                  if(parseInt(this.state.page) > Math.ceil(
                                                    this.state
                                                      .totalDocuments /
                                                      10)){
                                                      this.setState({
                                                        page: Math.ceil(
                                                          this.state
                                                            .totalDocuments /
                                                            10),
                                                        hierarchyListLoader:true,
                                                      }, ()=>{
                                                        if (
                                                          this.state
                                                            .activeToggle ==
                                                          "Archived-contract"
                                                        ) {
                                                          this.documentLibraryApi(
                                                            {archive: true}
                                                          );
                                                        } else {
                                                          this.documentLibraryApi(
                                                            {archive: false}
                                                          );
                                                        }
                                                      })
                                                  }
                                                  else if(isNaN(parseInt(this.state.page))){
                                                    this.setState({page:1, hierarchyListLoader:true},()=>{
                                                      if (
                                                        this.state
                                                          .activeToggle ==
                                                        "Archived-contract"
                                                      ) {
                                                        this.documentLibraryApi(
                                                          {archive: true}
                                                        );
                                                      } else {
                                                        this.documentLibraryApi(
                                                          {archive: false}
                                                        );
                                                      }
                                                    })
                                                  }
                                                  else{
                                                    this.setState({
                                                      hierarchyListLoader:true
                                                    },()=>{
                                                      if (
                                                        this.state
                                                          .activeToggle ==
                                                        "Archived-contract"
                                                      ) {
                                                        this.documentLibraryApi(
                                                          {archive: true}
                                                        );
                                                      } else {
                                                        this.documentLibraryApi(
                                                          {archive: false}
                                                        );
                                                      }
                                                    })
                                                    
                                                      
                                                    
                                                  }
                                                }
                                                }}
                                                onChange={(e)=>{
                                                 
                                                  this.handleChange(e);
                                                
                                                }} />
                                                 
                                                </li>
                                                <li
                                                  className="page-item active"
                                                  style={{
                                                    backgroundColor: "#88305f",
                                                    borderColor: "#88305f",
                                                    color: "white",
                                                    display: "none",
                                                  }}
                                                >
                                                  <a
                                                    className="page-link"
                                                    style={{
                                                      backgroundColor:
                                                        "#88305f",
                                                      borderColor: "#88305f",
                                                      color: "white",
                                                    }}
                                                  >
                                                    {this.state.page}
                                                  </a>
                                                </li>
                                                <li
                                                  className="page-item"
                                                  onClick={() => {
                                                    if (
                                                      Math.ceil(
                                                        this.state
                                                          .totalDocuments /
                                                          this.state.page_size
                                                      ) != this.state.page &&
                                                      this.state
                                                        .totalDocuments != 0
                                                    ) {
                                                      this.setState(
                                                        {
                                                          page:
                                                          (isNaN(parseInt(this.state.page))?0:parseInt(this.state.page)) + 1,
                                                          hierarchyListLoader:
                                                            true,
                                                        },
                                                        () => {
                                                          if (
                                                            this.state
                                                              .activeToggle ==
                                                            "Archived-contract"
                                                          ) {
                                                            this.documentLibraryApi(
                                                              {archive: true}
                                                            );
                                                          } else {
                                                            this.documentLibraryApi(
                                                              {archive: false}
                                                            );
                                                          }
                                                        }
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <a
                                                    className="page-link"
                                                    aria-label="Next"
                                                    style={{
                                                      // color: "#88305f"
                                                      backgroundColor:
                                                        "#88305f",
                                                      borderColor: "#88305f",
                                                      color: "white",
                                                    }}
                                                  >
                                                    <span aria-hidden="true">
                                                      &raquo;
                                                    </span>
                                                    <span className="sr-only">
                                                      Next
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </nav>
                                          </div>
                                          <div>
                                            <p style={{ marginTop: "6px" }}>
                                              Displaying page{" "} {this.state.pageNumber} out of {Math.ceil(
                                                      this.state
                                                        .totalDocuments /
                                                        10
                                                    )} pages
                                              {/* {(this.state.page - 1) *
                                                this.state.page_size +
                                                1}{" "}
                                              to{" "}
                                              {(this.state.page - 1) *
                                                this.state.page_size +
                                                1 +
                                                (this.state.page_size - 1) >
                                              this.state.totalDocuments
                                                ? this.state.totalDocuments
                                                : (this.state.page - 1) *
                                                    this.state.page_size +
                                                  1 +
                                                  (this.state.page_size -
                                                    1)}{" "}
                                              of {this.state.totalDocuments}{" "}
                                              items
                                               */}
                                               &nbsp;&nbsp;
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                </>

                              <DocumentLibraryTablemodal
                                type={modalType}
                                title={modalTitle}
                                parties={modalContractParties}
                                tags={modallibraryTags}
                                applyLibraryTagFilters={applyLibraryTagFilters}
                                initialFileIds={initialFileIds}
                                projects={moodalContractprojects}
                              />

                              <button
                                style={{ display: "none" }}
                                type="button"
                                data-toggle="modal"
                                data-target="#documentLibraryTableModal"
                                id="documentLibraryTableButton"
                              ></button>
                              {/* <TaskManagementTablemodal title={title}
                                                        numberOfMembers={numberOfMembers} associateGroup={associateGroup} />
                                                        <button style={{ display: 'none' }}
                                                        type="button" data-toggle="modal"
                                                        data-target="#taskManagementTableModal"
                                                        id="taskManagementTableButton"></button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )
              }
            </>
          )}
        </div>
        {this.renderAddTagModal()}
        {this.renderHierarchyModal()}
        <button
          style={{ display: "none" }}
          type="button"
          id="addTagBulkButton"
          data-toggle="modal"
          data-target="#addTagModal"
        />
        <button
          style={{ display: "none" }}
          type="button"
          id="addParentBulkButton"
          data-toggle="modal"
          data-target="#addHierarchyModal"
        />
        <MyDocumentModal
          openModal={this.state.openModal}
          handleClosedelete={this.handleClosedelete}
          p={`<p>Are you sure you want to delete this contract? </p> <br /> <p>You cannot undo the action</p>`}
          btn={"Delete Contract"}
          a={"Do you wish to continue?"}
          selectedContracts={this.state.checkDocument}
          deleteContractLoader={this.state.deleteContractLoader}
          deleteContracts={() => this.deleteContracts()}
        />
        {this.state.openModal == "modalHierarchy" && (
          <AddHierarchy
            openModal={this.state.openModal}
            handleClosehierarchy={this.handleClosehierarchy}
            checkDocument={this.state.checkDocument}
            archiveData={this.state.archiveData}
            reloadDocumentLibray={(data:any)=>{
              this.setState({
                openSnackbar: {
                  open: true,
                  msg: data,
                  contracts: []
                }
              })
              this.handleClosehierarchy()
              this.updatedDocumentLibraryTagList()
            }}
          />
        )}
        <SupportModal
          openModal={this.state.openModal}
          handleClosedelete={this.handleClosedelete}
          handleClosesupportBtn={this.handleClosesupportBtn}
          supporttitle={"Upload Support Documents"}
          SelectedList={this.state.checkDocument?.map((val: any) => val.id)}
          supportid={this.state.supportid}
          documentLibraryApi={this.documentLibraryApi}
        />
        <FavModal
          openModal={this.state.openModal}
          handleClosefavouriteBtn={this.handleClosefavouriteBtn}
          favourite={this.state.favouritedata?.length}
          undoFavourites={()=>{
            this.documentLibraryFavouriteApi(this.state.openSnackbar.contract, this.state.openSnackbar.type)
            // this.setState({ openModal: "modalRemoveFavourites" });
          }}
        />
        <ShareModal
          openModal={this.state.openModal}
          handleCloseShareBtn={this.handleCloseShareBtn}
          SelectedList={this.state.checkDocument?.map((val: any) => val.id)}
        />
        
        <AddTag
          openModal={this.state.openModal}
          handleCloseaddtagBtn={this.handleCloseaddtagBtn}
          titleTag={"Add Tag"}
          addTagContractType={this.state.addTagContractType}
          documentLibraryAddTagGetProjecetApi={
            this.documentLibraryAddTagGetProjecetApi
          }
          projectData={this.state.projectData}
          projectDatastateUpdate={(data: any) =>
            this.setState({ projectData: data })
          }
          checkDocument={this.state.checkDocument}
          reloadDocumentLibray={this.updatedDocumentLibraryTagList}
          documentLibraryAddTagGetGroupApi={
            this.documentLibraryAddTagGetGroupApi
          }
          groupData={this.state.groupData}
          selectedContractTypes={this.state.selectedContractTypes}
          tagContractType={this.state.tagContractType}
          setTagContractType={(items:any)=>{
            this.setState({tagContractType: items})
          }}
          projectList={this.state.projectList}
          setprojectList={(e:any)=>{
            const data = this.state.projectData?.filter(
              (val: any) => val.id == e
            );
            if (data.length >= 0) {
              let index = this.state.projectList.findIndex((obj: any) => obj.id === data[0].id);
              if (index == -1) {
                this.setState({projectList:[...this.state.projectList, data[0]]});
              }
            }
          }}
          handleProjectType={(val:any)=>{
            // console.log("val", val)
            const arr = this.state.projectList;
            let arrFilter = arr?.filter((data: any) => data.id !== val.id);
            // console.log("val", arrFilter)
            this.setState({projectList:arrFilter})
          }}
          groupList={this.state.groupList}
          handleGroupType={(val:any)=>{
            const arr = this.state.groupList;
            let arrFilter = arr?.filter((data: any) => data.id !== val.id);
            this.setState({groupList:arrFilter});
          }}
          handleGroup={(e:any)=>{
            const data = this.state.groupData.filter(
              (val: any) => val.id == e.target.value
            );
            if (data.length >= 0) {
              let index = this.state.groupList.findIndex((obj: any) => obj.id === data[0].id);
              if (index == -1) {
                this.setState({groupList:[...this.state.groupList, data[0]]});
              } 
            }
          }}
          handleProjectsSet={(val:any)=>{
            this.documentLibraryAddTagGetGroupApi();
            this.setState({projectList:[...this.state.projectList, val]})
          }}
          resetGroups={()=>{
            this.setState({groupList:[]});
          }}
        />

        <ArchiveModal
          openModal={this.state.openModal}
          activeToggleState={this.state.activeToggle}
          handleCloseArchiveBtn={this.handleCloseArchiveBtn}
          a={"Do you wish to continue?"}
          bgcolor={"#FFEF5A"}
          color={"#626262"}
          btn={"Archive"}
          btn2={"Unarchive"}
          activeToggle={(data: any) => this.handleToggle(data)}
          pArchive={
            "Archiving an active contract would disable all Riverus insights for the document. However, the document will remain in the Riverus repository."
          }
          UnArchive={
            "After unarchiving the contract, the contract will be visible in the active list."
          }
          documentLibraryArchiveApi={this.documentLibraryArchiveApi}
          documentLibraryunArchiveApi={() => this.documentLibraryUnArchiveApi()}
        />

        <SupportDocumentTableModal
          openModal={this.state.openModal}
          handleCloseSupportDocumentTableBtn={
            this.handleCloseSupportDocumentTableBtn
          }
          handleopensupportmodal={this.handleopensupportmodal}
          contractData={this.state.supportDocumentData}
          refreshData={()=>{
            this.updatedDocumentLibraryTagList()
          }}
        />
        <ConnectedDocumentTableModal
          openModal={this.state.openModal}
          handleCloseAmendmentDocumenTableBtn={
            this. handleCloseAmendmentDocumenTableBtn
          }
          handleopenamendmentmodal={this.handleopenamendmentmodal}
          contractData={this.state.amendmentDocumentData}
          reloadDocumentLibray={this.updatedDocumentLibraryTagList}
        />
        <AmendmentModal
          openModal={this.state.openModal}
          handleClosedelete={this.handleClosedelete}
          handleCloseamendmentBtn={this.handleCloseamendmentBtn}
          amendmenttitle={"Upload Amendment Documents"}
          SelectedList={this.state.checkDocument?.map((val: any) => val.id)}
          amendmentid={this.state.amendmentid}
          documentLibraryApi={this.documentLibraryApi}
        />

        <AddFavourites
          openModal={this.state.openModal}
          handleCloseaddtagBtn={this.handleCloseaddtagBtn}
          titleTag={"Add Favourites"}
          checkDocument={this.state.checkDocument}
          handleFavourites={(contract:any, type:any)=>{
            // console.log("handle Favorites")
            this.documentLibraryFavouriteApi(contract, type)
          }}
        />

        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.openSnackbar.open}
          onClose={()=>{
          this.setState({
            openSnackbar: {
              open: false,
              msg: "",
              contracts: []
            }
          })
          }}
          message={this.state.openSnackbar.msg}
          key="bottomCenter"
        />
      </div>
    );
  }

  renderAddTagModal() {
    let {
      selectedFile,
      saveSelectedFile,
      selectedLibraryAction,
      isBulkAction,
      saveSelectedLibraryAction,
      getDocumentHierarchy,
      documentselectedFile,
      documentsaveSelectedFile,
      documentsavedMultipleSelectedFiles,
    } = this.props;

    return (
      <AddTagModal
        selectedFile={selectedFile}
        saveSelectedFile={saveSelectedFile}
        selectedLibraryAction={selectedLibraryAction}
        isBulkAction={isBulkAction}
        saveSelectedLibraryAction={saveSelectedLibraryAction}
        getDocumentHierarchy={getDocumentHierarchy}
        documentselectedFile={documentselectedFile}
        documentsaveSelectedFile={documentsaveSelectedFile}
        documentsavedMultipleSelectedFiles={documentsavedMultipleSelectedFiles}
      />
    );
  }

  renderHierarchyModal() {
    let {
      selectedFile,
      saveSelectedFile,
      selectedLibraryAction,
      isBulkAction,
      saveSelectedLibraryAction,
      saveMultipleSelectedFiles,
      getDocumentHierarchy,
      savedMultipleSelectedFiles,
      documentsaveMultipleSelectedFiles,
      documentsaveSelectedFile,
    } = this.props;

    let { sort, order } = this.state;
    return (
      <AddHierarchyModal
        selectedFile={selectedFile}
        saveSelectedFile={saveSelectedFile}
        selectedLibraryAction={selectedLibraryAction}
        isBulkAction={isBulkAction}
        saveSelectedLibraryAction={saveSelectedLibraryAction}
        saveMultipleSelectedFiles={saveMultipleSelectedFiles}
        getDocumentHierarchy={getDocumentHierarchy}
        sort={sort}
        order={order}
        documentsaveSelectedFile={documentsaveSelectedFile}
        documentsaveMultipleSelectedFiles={documentsaveMultipleSelectedFiles}
        reloadDocumentLibray={(data:any)=>{
          this.setState({
            openSnackbar: {
              open: true,
              msg: data,
              contracts: []
            }
          })
          this.handleClosehierarchy()
          this.updatedDocumentLibraryTagList()
        }}
      />
    );
  }
}
