import { Avatar, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { getLocalStorage } from '../../Authentication/Actions/authentication';
import HeimdallUtil from '../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil';
import { draftStatus } from '../State/DraftState';

interface Props {
    selectedDraft: any;
    addField: (type: string, point?: any, userId?: string) => void;
    dropPoint: any;
    addedSignatureField: boolean;
    openConfirmDialog: () => void;
    hideSignatureButton: boolean;
}
const SignaturesTab = (props:Props) => {
    const [draggableUserId, setdraggableUserId] = useState<string>('');
    useEffect(() => {
      console.log(props.selectedDraft);
    }, [props.selectedDraft])
    
    const dragStart = (e: any, id: string) => {
        e.target.style.opacity = 0.5;
        const copy = e.target.cloneNode(true);
        copy.id = 'form-build-drag-image-copy';
        copy.style.width = '250px';
        document.body.appendChild(copy);
        e.dataTransfer.setDragImage(copy, 125, 25);
        setdraggableUserId(id)
    };

    const dragEnd = (e: any, type: any) => {
        props.addField(type, props.dropPoint, draggableUserId);
        e.target.style.opacity = 1;
        document.body.removeChild(
            document.getElementById('form-build-drag-image-copy') as Node
        );
        e.preventDefault();
    };

    const isLoggedInUser = (id:string) => {
        const user_data = getLocalStorage("user_profile");
        if (id === user_data.id) {
          return true;
        }
        return false;
      }

      const isSignedUser = (item:any) => {
        if(item?.isSigned){
            return true
        }else return false
      }
    

  return (
      <div className='notify-card mt-4'>
          {props.selectedDraft?.signatories &&
              props.selectedDraft?.signatories?.length &&
              props.selectedDraft?.signatories?.map((item: any,index:number) => {
                console.log(item);
                  return (
                      <div key={index} className="d-flex align-items-center users-view mt-2">
                          {item?.isSigned ? (
                              <img
                                  src="/static_images/selectrighticon.svg"
                                  className="pl-1 w-10"
                              />
                          ) : (
                              <img src="/static_images/pending.svg" className="pl-1 w-10" />
                          )}
                          <Avatar
                              key={1}
                              style={{
                                  border: `1px solid #00692A`,
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                  padding: "5px",
                                  color: `#00692A`,
                                  backgroundColor: `#D8F7E6`,
                              }}
                          >
                              <span>{HeimdallUtil?.getNameForAvatar(item)}</span>
                          </Avatar>
                          <p className="mb-0 ml-2 cursor-pointer">
                              {item.first_name + " " + item.last_name}
                          </p>
                          {isLoggedInUser(item?.id) && !isSignedUser(item) && !props.hideSignatureButton && <div className="ml-2" draggable onDragStart={(e) => dragStart(e, item?.id)} onDragEnd={(e) => dragEnd(e, 'SIGNATURE')}>
                              <Tooltip title="Drag and Drop to place where you want to sign"><button className="btn create-btn" onClick={() => props.addField('SIGNATURE')}>Place Signature</button></Tooltip> 
                          </div>}
                          {isSignedUser(item) && <span>&nbsp;Signed on {new Date(item?.signed_date).toDateString()} at {new Date(item?.signed_date).toLocaleTimeString('en-US',{hour12:true})}</span>}
                      </div>
                  );
              })
          }
          {props.addedSignatureField  && <button
          type="button"
          className="btn btn-warning create-btn ml-2 mt-4"
          onClick={() => props.openConfirmDialog()}
        >
          Confirm Signature
        </button>}
      </div>
  )
}

export default SignaturesTab