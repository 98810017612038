
    export const signatureStatuses = [
        'Signature Pending',
        'counter party signature Pending',
        'contract executed successfully'
    ]

    export const approvalPendingStatuses = [
        'approval pending',
        'counter party approval Pending',
        'approval denied'
    ]
