import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import * as React from "react";
import { IconContext } from "react-icons";
import { BsArrowLeft } from "react-icons/bs";
import { isRiverusAdmin } from "../DDUtils";

type Anchor = "right";

type Props = {
  setDrawerState: any;
  drawerState: any;
  data: any;
  dataType: any;
  viewAllFielUpdate: any;
  type: string;
};
export default function TemporaryDrawer(props: Props) {
  const [valueState, setValueState] = React.useState<any>(null);
  const [isEdit, setEdit] = React.useState<any>(false);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      props.setDrawerState(open);
    };

  const setValues = (data: any) => {
    if (props.type === "sectionName") {
      if (data) {
        setValueState({
          section_name: data?.section_name,
        });
      }
    } else {
      if (data) {
        setValueState({
          field_name: data?.field_name,
          dataType: data?.data_type,
          field_validation: data?.field_validation,
          description: data?.description,
          required: data?.required,
          creation_type: isRiverusAdmin() ? "system" : "custom",
        });
      }
    }
  };
  React.useEffect(() => {
    setValues(props?.data);
  }, [props.data]);

  React.useEffect(() => {
    console.log(valueState?.dataType, "valueState");
  }, [valueState]);

  const list = (anchor: Anchor) => (
    <Box sx={{ width: 500 }} role="presentation">
      {props.type !== "sectionName" ? (
        <div>
          <div className="d-flex align-items-center field-header-2">
            <div className="ml-3 d-flex">
              <IconContext.Provider value={{ className: "back-arrow-icon" }}>
                <BsArrowLeft onClick={toggleDrawer(anchor, false)} />
              </IconContext.Provider>
            </div>
            <div className="col-md-11">
              <span className="fw">{`${
                props.type === "sectionName" ? "Edit Section" : "Edit Field"
              }`}</span>
            </div>
          </div>
          <div className="pl-1">
            <div
              className="row d-flex justify-content-around mt-5"
              style={{ paddingLeft: "5px" }}
            >
              <div className="col-md-7">
                <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                  Field Name
                </p>
                <input
                  disabled={isEdit ? false : true}
                  type="text"
                  className="form-control"
                  value={valueState?.field_name}
                  onChange={(e) =>
                    setValueState({ ...valueState, field_name: e.target.value })
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                  Required ?
                </p>
                <Switch
                  disabled={isEdit ? false : true}
                  value={valueState?.required}
                  checked={valueState?.required}
                  onChange={(e) => {
                    setValueState({
                      ...valueState,
                      required: e.target.checked,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row m-0 mt-4">
              <div className="col-md-8">
                <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                  Data Type
                </p>
                <FormControl className="col" size="small" variant="outlined">
                  <Select
                    disabled={isEdit ? false : true}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueState?.dataType?.data_type}
                    onChange={(e) => {
                      const dataTypeValue = props.dataType.filter(
                        (item: any) => item.data_type === e.target.value
                      );
                      setValueState({
                        ...valueState,
                        dataType: dataTypeValue[0],
                      });
                    }}
                  >
                    {props?.dataType?.map((item: any) => (
                      <MenuItem value={item.data_type}>
                        {item.data_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-8 mt-4">
                <div className="d-flex justify-content-between">
                  <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                    Field Validation
                  </p>
                  <img
                    className="view-icn"
                    style={{ left: "0px" }}
                    src="/static_images/dd-view.svg"
                    alt="clause-folder"
                  />
                </div>
                <input
                  disabled={isEdit ? false : true}
                  type="text"
                  className="form-control"
                  value={valueState?.field_validation}
                  onChange={(e) =>
                    setValueState({
                      ...valueState,
                      field_validation: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-8 mt-4">
                <div className="d-flex justify-content-between">
                  <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                    Description
                  </p>
                  <img
                    className="view-icn"
                    style={{ left: "0px" }}
                    src="/static_images/dd-view.svg"
                    alt="clause-folder"
                  />
                </div>
                <TextField
                  fullWidth
                  disabled={isEdit ? false : true}
                  id="outlined-multiline-static"
                  multiline
                  minRows={3}
                  variant="outlined"
                  value={valueState?.description}
                  onChange={(e) =>
                    setValueState({
                      ...valueState,
                      description: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-md-8 mt-4">
                <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                  Applied In
                </p>
                <FormControl className="col" size="small" variant="outlined">
                  <Select
                    disabled={isEdit ? false : true}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={""}
                    onChange={(e) => {}}
                  >
                    <MenuItem value={""}>Hello</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex align-items-center field-header-2">
            <div className="ml-3 d-flex">
              <IconContext.Provider value={{ className: "back-arrow-icon" }}>
                <BsArrowLeft onClick={toggleDrawer(anchor, false)} />
              </IconContext.Provider>
            </div>
            <div className="col-md-11">
              <span className="fw">{`${
                props.type === "sectionName" ? "Edit Section" : "Edit Field"
              }`}</span>
            </div>
          </div>
          <div className="row d-flex mt-5 pl-3" style={{ paddingLeft: "5px" }}>
            <div className="col-md-7">
              <p className="m-0 c-primary mb-1" style={{ fontWeight: 500 }}>
                Section Name
              </p>
              <input
                disabled={isEdit ? false : true}
                type="text"
                className="form-control"
                value={valueState?.section_name}
                onChange={(e) =>
                  setValueState({
                    ...valueState,
                    section_name: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          padding: "5px 30px 5px 30px",
          position: "absolute",
          bottom: "20px",
          right: "30px",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <button
          onClick={() => {
            setEdit(true);
          }}
          type="button"
          className="btn btn-warning create-btn"
        >
          Edit for All Contract Type
        </button>
        <button
          disabled={isEdit ? false : true}
          onClick={() => {
            props.setDrawerState(false);
            if (props.type === "sectionName") {
              const payload: any = {
                ...valueState,
                edit_for_all: true,
              };
              console.log(payload);

              props.viewAllFielUpdate(payload, props?.data?.id);
            } else {
              const payload: any = {
                ...valueState,
                edit_for_all: true,
                data_type: [valueState.dataType.id],
              };
              delete payload["dataType"];
              console.log(payload);

              props.viewAllFielUpdate(payload, props?.data?.id);
            }
          }}
          type="button"
          className="btn btn-warning create-btn"
        >
          Save
        </button>
      </div>
    </Box>
  );

  let anchor: Anchor = "right";
  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={props.drawerState}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
