import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  withStyles,
  Theme,
  createStyles,
  Switch,
  SwitchProps,
  SwitchClassKey,
  Snackbar,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { BsUpload } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { IconContext } from "react-icons";
import UploadFileDA from "../DA";
import { ProgressBar } from "react-bootstrap";
import { generateTagsFromUpload } from "../../Templates/Component/DocGenerate";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
import { getFieldName } from "../../Templates/DA";

interface Props {
  open: boolean;
  onClose: () => void;
  uploadedFileResponse: (presignedUrl: any) => void;
  fileType: string;
  title: string;
  contractData: any;
  show: any;
  contractType: any;
  isInternal: any;
  handleisinternal: (value: any) => void;
  setisinternal: any;
}

const SparkMD5 = require("spark-md5");
interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}
interface Propsss extends SwitchProps {
  classes: Styles;
}
const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#52d869",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.error.light,
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: Propsss) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export interface File {
  name: string;
  size: number;
  type: string;
  hash?: string;
  status?: string;
  template_type: string;
  file_type: string;
  contract_type:string[] | null;
  // progress: number;
}

const UploadFileDialog = (props: Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const fileInput = useRef<any>(null);
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState<any>({});
  const [highlight, setHighlight] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<any>({});
  const [fileHash, setFileHash] = useState<any>(null);
  const [error, seterror] = useState(null);
  const [documentType, setdocumentType] = useState("");
  // const [state,setstate]=useState(false)
  const [uploadType, setuploadType] = useState("template");
  const [templateType, setTemplateType] = useState("");
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [contractTypeList, setContractType] = useState<any>([]);

  useEffect(() => {
    if (props.contractType && props.contractType.length) {
      if (isRiverusAdmin()) {
        const data = props.contractType.filter(
          (item: any) => item.creation_type === "system"
        );
        setContractType(data);
      } else {
        setContractType(props.contractType);
      }
    }
  }, [props.contractType]);
  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        function (e) {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    setopen(props.open);
    if (!props.open) {
      setloading(false);
    }
  }, [props.open]);

  const handleTemplateType = (event: any) => {
    let contractType = contractTypeList.find((data:any) => data.id == event.target.value);
    if(contractType){
      setTemplateType(event.target.value);
    let fileInfo = fileData;
    fileInfo[0]["template_type"] = contractType.name;
    fileInfo[0]["contract_type"] =[event.target.value];
    setFileData(fileInfo);
    }
  };

  const handleClose = () => {
    setFileName("");
    setFileData({});
    setTemplateType("");
    setopen(false);
    props.onClose();
  };

  const handleUpload = () => {
    seterror(null);
    fileInput.current.click();
  };

  const clearFile = () => {
    setFileName("");
    setFileData({});
    setTemplateType("");
  };

  const handleOnChangeFileUpload = (post: any) => {
    try {
      let files_: File[] = [];
      const files = post.target.files;
      console.log(files, "files");
      const extension = files[0]?.name?.split(".");
      if (
        files[0].type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        extension[1] === "docx"
      ) {
        if (files && files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            let file = post.target.files[i];
            let reader = new FileReader();
            if (file) {
              reader.readAsDataURL(file);
              reader.onload = () => {
                files_.push({
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  template_type: "",
                  file_type: "Contract_Draft",
                  contract_type:null
                });
              };
            }
          }
        }
        setFileName(files[0].name);
        setFileData(files);
        console.log(files);
      } else {
        setopenSnackbar({ open: true, msg: "Only Docx files are supported" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadDoc = async () => {
    try {
      if (props.fileType == "template") {
        var tags = await generateTagsFromUpload(fileData[0]);
        if (Object.keys(tags).length == 0) {
          setopenSnackbar({ open: true, msg: "please upload valid template." });
          return;
        }
      }
      if (
        props.fileType == "template" &&
        fileData &&
        !fileData[0]["template_type"]
      ) {
        setopenSnackbar({ open: true, msg: "please add type" });
        return;
      }
      setloading(true);
      fileUpload(fileData, (response: any, error: any) => {
        if (response) {
          props.uploadedFileResponse({ ...response, type: documentType });
          handleClose();
          setloading(false);
        }
        if (error) {
          console.error(error);
          clearFile();
          if (error.request.status == 400) {
            seterror(error.response.data.file_hash[0]);
          }
        }
      });
    } catch (error) {
      console.error("upload file error", error);
      clearFile();
    } finally {
    }
  };

  const onFileDrop = (event: any) => {
    let files = event.dataTransfer.files;
    handleOnChangeFileUpload({ target: { files } });
    setHighlight(false);
  };

  const onDragEnter = () => {
    setHighlight(true);
  };
  const onDragLeave = () => {
    setHighlight(false);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const fileUpload = async (post: any, callback: Function) => {
    try {
      let uploadedFiles: File[] = [];
      let preSignedResponse: any = null;
      let files_ = uploadedFiles;
      const files: any = post;
      const uploadProgress_ = uploadProgress;
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let file = post[i];
          let reader = new FileReader();
          if (file) {
            reader.readAsDataURL(file);
            reader.onload = async () => {
              const hexHash = SparkMD5.hash(reader.result);
              console.log(file);
              console.log(hexHash);
              files_.push({
                name: file.name,
                size: file.size,
                type: file.type,
                hash: hexHash,
                status: "uploading",
                template_type: file["template_type"],
                contract_type:props.fileType == "template" ? file['contract_type'] : null,
                file_type:
                  props.fileType == "template" ? uploadType : props.fileType,
              });
              uploadProgress_[hexHash] = 0;
              setFileHash(hexHash);
              setUploadProgress({ ...uploadProgress_ });
              try {
                const response = await UploadFileDA.get_s3_presigned_url({
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file["template_type"],
                  contract_type:props.fileType == "template" ? file['contract_type'] : null,
                  file_type:
                    props.fileType == "template" ? uploadType : props.fileType,
                  creation_type: isRiverusAdmin() ? "system" : "custom",
                });
                if (response) {
                  const onHandleFileProgress = {
                    onUploadProgress: function (progressEvent: any) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      const uploadProgress_ = uploadProgress;
                      uploadProgress_[hexHash] = percentCompleted;
                      setUploadProgress({
                        ...uploadProgress_,
                      });
                    },
                  };
                  if (response && response.presigned_url) {
                    const s3Info = response.presigned_url;
                    console.log(
                      "🚀 ~ file: templatesContainer.tsx ~ line 83 ~ .then ~ s3Info",
                      s3Info
                    );
                    preSignedResponse = response;
                    try {
                      const response =
                        await UploadFileDA.upload_file_in_s3_bucket(
                          s3Info,
                          file,
                          onHandleFileProgress
                        );
                      if (response) {
                        console.log(
                          "🚀 ~ file: index.ts ~ line 45 ~ upload_file_in_s3_bucket ~ .then ~ response",
                          response
                        );
                        callback(s3Info, null);
                      }
                    } catch (error) {
                      console.log(error);
                      callback(null, error);
                    }
                  }
                }
                uploadedFiles = [...files_];
              } catch (error) {
                console.log(
                  "🚀 ~ file: templatesContainer.tsx ~ line 77 ~ handleOnChangeFileUpload ~ error",
                  error
                );
                callback(null, error);
              }
            };
          }
        }
      }
    } catch (error) {
      callback(null, error);
    }
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: "" });
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );

  const handleDocumentType = (value: any) => {
    if (props.fileType == "template") {
      let fileInfo = fileData;
      fileInfo[0]["template_type"] = value;
      setFileData(fileInfo);
    }
    setdocumentType(value);
  };
  return (
    <>
      <Dialog
        className="upload-dialog"
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <div className="row align-items-center">
            <div className="col-md-11">{props.title}</div>
            <IconContext.Provider
              value={{ color: "grey", className: "col-md-1 close-icn" }}
            >
              <AiOutlineClose onClick={handleClose} />
            </IconContext.Provider>
          </div>
        </DialogTitle>
        <DialogContent style={{ maxWidth: "950px", height: "480px" }}>
          <div
            className="upload-file__container"
            style={{
              borderColor: highlight ? "blue" : "#d9d9d9",
              overflow: "hidden",
            }}
          >
            {!fileName && (
              <div
                className="upload-file__action"
                onDrop={onFileDrop}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
              >
                <div
                  className="df"
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  {error && <p>{error}</p>}
                  <p className="c-grey">
                    Click the button to open file explorer
                  </p>
                  <Button
                    className="create-btn"
                    style={{ padding: "5px 15px" }}
                    onClick={handleUpload}
                  >
                    <IconContext.Provider value={{ className: "upload-icon" }}>
                      <BsUpload />
                    </IconContext.Provider>
                    Select from File Explorer
                  </Button>
                </div>
              </div>
            )}
            {fileName && (
              <div className="upload-file__list">
                <p className="c-primary fw">{fileName}</p>
                {(props.fileType == "support_document" ||
                  uploadType == "support_doc_template") &&
                  !loading && (
                    <div>
                      <FormControl
                        variant="outlined"
                        className="riverus-text-box"
                        size="small"
                        fullWidth={true}
                      >
                        <InputLabel>Select Type of Document</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          label="Select Type of Document"
                          value={documentType}
                          onChange={(e: any) =>
                            handleDocumentType(e.target.value)
                          }
                        >
                          <MenuItem value="Invoice">Invoice</MenuItem>
                          <MenuItem value="Purchase Order">
                            Purchase Order
                          </MenuItem>
                          <MenuItem value="Receipt">Receipt</MenuItem>
                          <MenuItem value="Correspondence">
                            Correspondence
                          </MenuItem>
                          <MenuItem value="Approval/Licenses">
                            Approval/Licenses
                          </MenuItem>
                          <MenuItem value="Litigation Papers">
                            Litigation Papers
                          </MenuItem>
                          <MenuItem value="GST / Trade Docs">
                            GST / Trade Docs
                          </MenuItem>
                          <MenuItem value="Reports">Reports</MenuItem>
                          <MenuItem value="Checklist">Checklist</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {props.fileType == "template" &&
                  uploadType == "template" &&
                  !loading && (
                    <div>
                      <FormControl
                        fullWidth={true}
                        variant="outlined"
                        className="riverus-text-box"
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Contract Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={templateType}
                          onChange={handleTemplateType}
                          label="Contract Type"
                        >
                          {contractTypeList?.map((type: any, index: any) => (
                            <MenuItem key={index} value={type.id}>
                              {type.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                {loading && (
                  <ProgressBar
                    animated
                    now={uploadProgress[fileHash]}
                    className="purple-progress-bar "
                    style={{
                      height: "6px",
                      borderRadius: "100px",
                      marginTop: "10px",
                      background: "#D9D9D9",
                      width: "75%",
                    }}
                  />
                )}
                <div onClick={clearFile} className="close-icn">
                  <img
                    src="/static_images/close-modal-icn.svg"
                    alt="close_icon"
                  />
                </div>
              </div>
            )}
            <form encType="multipart/form-data">
              <input
                ref={fileInput}
                style={{ visibility: "hidden", height: 0 }}
                name="file"
                id="file-input"
                type="file"
                accept=".docx"
                onChange={handleOnChangeFileUpload}
              />
            </form>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent:
              props.fileType == "template" ? "space-between" : "flex-end",
          }}
        >
          {props.fileType == "template" && (
            <FormControl style={{ marginLeft: "17px" }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={uploadType}
                onChange={(e) => setuploadType(e.target.value)}
              >
                <FormControlLabel
                  value="template"
                  control={<Radio />}
                  label="Upload Contract Template"
                />
                <FormControlLabel
                  value="support_doc_template"
                  control={<Radio />}
                  label="Upload Support Document Template"
                />
              </RadioGroup>
            </FormControl>
          )}
          {props.show && (
            <div style={{ marginLeft: "16px", width: "82%" }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    onChange={(e: any) =>
                      props.handleisinternal(e.target.checked)
                    }
                    // value={props.isInternal}
                    checked={props.isInternal}
                    // checked={props?.contractData?.createFrom=="counter_party" ||props?.contractData?.createFrom=="support_document"?false:true}
                    name="checkedB"
                  />
                }
                label={props.isInternal ? "Internal Draft" : "External Draft"}
              />
            </div>
          )}
          <Button
            className="create-btn"
            style={{ padding: "5px 15px" }}
            onClick={uploadDoc}
          >
            {props.title}
          </Button>
        </DialogActions>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackbar.open}
          onClose={closeSnackbar}
          message={openSnackbar.msg}
          key="bottomCenter"
          action={action}
        />
      </Dialog>
    </>
  );
};

export default UploadFileDialog;
