import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Box, Snackbar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

const deleteStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  minHeight: "260px",
  height: "600px",
  maxHeight: "340px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  openModal: string;
  bgcolor: any;
  color: any;

  a: any;
  handleCloseArchiveBtn: any;
  btn: any;
  pArchive: any;
  documentLibraryArchiveApi: any;
  activeToggle: any;
  action: any;
  handleClosefavouriteBtn: any;
  activeToggleState: any;
  btn2: any;
  documentLibraryunArchiveApi: any;
  UnArchive: any;
}

const handleClick = (props: any) => {
  if (props.activeToggleState == "Active-contract") {
    props.documentLibraryArchiveApi();
    props.handleCloseArchiveBtn();
    props.activeToggle("Archived-contract");
  } else {
    props.documentLibraryunArchiveApi();
    props.handleCloseArchiveBtn();
    props.activeToggle("Active-contract");
  }
};

const ArchiveModal: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div>
        <Modal
          open={props.openModal === "modalArchive"}
          className="deletemodal"
          onClose={() => props.handleCloseArchiveBtn()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={deleteStyle}
            className="position-relative"

            // style={{ height: props.height }}
          >
            <Typography
              id="modal-modal-title"
              // variant="h6"
              // component="h2"
              className="myDocument-deleteFileTitle"
            >
              <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
                Confirm  {props?.activeToggleState == "Active-contract"
                  ? props.btn
                  : props.btn2}
                <span
                  className="mr-0"
                  onClick={() => props.handleCloseArchiveBtn()}
                >
                  <img
                    src={"/static_images/Group 3610.svg"}
                    alt=""
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#818181",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </Typography>
            <Typography>
              <div className="myDocument-deleteFileMessage">
                <p>
                {props?.activeToggleState == "Active-contract"
                  ? props.pArchive: props.UnArchive}</p>

                <p>{props.a}</p>
              </div>
            </Typography>
            <div className="delete-modal-btn">
              <button
                className="myDocument-deleteFileGoBack"
                onClick={() => props.handleCloseArchiveBtn()}
              >
                Go back
              </button>
              <button
                className="myDocument-deleteFileFiled-tag"
                style={{
                  backgroundColor: props.bgcolor,
                  color: props.color,
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClick(props);
                }}
              >
                {props?.activeToggleState == "Active-contract"
                  ? props.btn
                  : props.btn2}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveModal);
