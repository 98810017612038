import React, { Component } from "react";

interface Props {
  setSortAndOrder: (sort: string, order: string) => void;
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
}

interface State {
  sort: string;
  titleOrder: boolean;
  fileNameOrder: boolean;
  dueOrder: boolean;
  progressNameOrder: boolean;
  stateNameOrder: boolean;
  clauseNameOrder: boolean;
}

export default class ListViewHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sort: "title",
      titleOrder: true,
      fileNameOrder: false,
      dueOrder: false,
      progressNameOrder: false,
      stateNameOrder: false,
      clauseNameOrder: false,
    };
  }

  setSortingAndOrder(sortBy: string) {
    let {
      titleOrder,
      fileNameOrder,
      dueOrder,
      progressNameOrder,
      stateNameOrder,
      clauseNameOrder,
    } = this.state;
    let { setSortAndOrder, getAllTasksData } = this.props;
    switch (sortBy) {
      case "title": {
        if (titleOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "file_name": {
        if (fileNameOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "contract__file_name": {
        if (fileNameOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "due_date": {
        if (dueOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "progress": {
        if (progressNameOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "status": {
        if (stateNameOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      case "contract_clause_type": {
        if (clauseNameOrder) {
          let order = "asc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        } else {
          let order = "desc";
          setSortAndOrder(sortBy, order);
          getAllTasksData("0", "0", sortBy, order, false, "");
        }
        break;
      }
      default:
        break;
    }
  }

  render() {
    let {
      titleOrder,
      fileNameOrder,
      dueOrder,
      progressNameOrder,
      stateNameOrder,
      clauseNameOrder,
      sort,
    } = this.state;
    return (
      <>
        <div className="col-md-12 p-0 ">
          <div className="row list-view-header ">
            <div
              className="col-md-2 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "title",
                    titleOrder: !titleOrder,
                    fileNameOrder: true,
                    dueOrder: true,
                    progressNameOrder: true,
                    stateNameOrder: true,
                    clauseNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("title");
                  }
                )
              }
            >
              All Tasks{" "}
              {sort === "title" ? (
                titleOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
            <div
              className="col-md-2 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "contract__file_name",
                    fileNameOrder: !fileNameOrder,
                    titleOrder: true,
                    dueOrder: true,
                    progressNameOrder: true,
                    stateNameOrder: true,
                    clauseNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("contract__file_name");
                  }
                )
              }
            >
              Contract{" "}
              {sort === "contract__file_name" ? (
                fileNameOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
            <div
              className="col-md-2 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "contract_clause_type",
                    clauseNameOrder: !clauseNameOrder,
                    titleOrder: true,
                    dueOrder: true,
                    progressNameOrder: true,
                    stateNameOrder: true,
                    fileNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("contract_clause_type");
                  }
                )
              }
              style={{ paddingLeft: "1.3rem" }}
            >
              Clause{" "}
              {sort === "contract_clause_type" ? (
                clauseNameOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-2" style={{ paddingLeft: "0.4rem" }}>
              Assigned to
            </div>
            <div
              className="col-md-1 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "due_date",
                    dueOrder: !dueOrder,
                    titleOrder: true,
                    clauseNameOrder: true,
                    progressNameOrder: true,
                    stateNameOrder: true,
                    fileNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("due_date");
                  }
                )
              }
              style={{ right: "2vw", paddingLeft: "1.5rem" }}
            >
              Date{" "}
              {sort === "due_date" ? (
                dueOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
            <div
              className="col-md-1 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "status",
                    stateNameOrder: !stateNameOrder,
                    titleOrder: true,
                    clauseNameOrder: true,
                    progressNameOrder: true,
                    dueOrder: true,
                    fileNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("status");
                  }
                )
              }
              style={{ right: "2vw", paddingLeft: "0.5rem" }}
            >
              Status{" "}
              {sort === "status" ? (
                stateNameOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
            <div
              className="col-md-2 sorting-option"
              onClick={() =>
                this.setState(
                  {
                    sort: "progress",
                    progressNameOrder: !progressNameOrder,
                    titleOrder: true,
                    clauseNameOrder: true,
                    stateNameOrder: true,
                    dueOrder: true,
                    fileNameOrder: true,
                  },
                  () => {
                    this.setSortingAndOrder("progress");
                  }
                )
              }
              style={{ right: "2vw", paddingLeft: "1.2rem" }}
            >
              Progress{" "}
              {sort === "progress" ? (
                progressNameOrder === true ? (
                  <img src="/static_images/down-arrow.svg" />
                ) : (
                  <img src="/static_images/up-arrow.svg" />
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
