import { Button, Snackbar, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../Design/draft.scss";
import { ContractRes, draftStatus, FilterData } from "../State/DraftState";
import CustomChip from "../../UniversalComponents/CustomChip/Component/CustomChip";
import PdfViewer from "./PdfViewerDialog";
import ForApproval from "./ForApproval";
import ApprovalModalView from "./approvalModalsList";
import Avatarfordraft from "./AvatarForDraft";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { getdraftbyid, getreqbyidbyid } from "../DA";
interface Props {
  draftContractData: ContractRes[];
  selectedDraft: string[];
  selectMultipleDrafts: (id: string) => void;
  openVersionHistory: (draft: string) => void;
  openSupportDoc: (id: string, alldata: any) => void;
  openDocViewerDialog: (draft: ContractRes) => void;
  openAssignedTo: any;
  setSpecificDraft: any;
  onPatch: any;
  setTemplate: any;
  getDrafts: any;
  createLink: any;
  applyFilter: (a: string[], b: string, c?: string) => void;
  filterKeyVals: FilterData;
  openApprovalStatus: any;
  allspreaded: any;
  uploadFile: (post: any, fileType: any, callback: Function) => void;
}
const ListViewTable = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [draftLink, setDraftLink] = useState("");
  const [draftContracts, setDraftContracts] = useState<ContractRes[]>([]);
  const [fileName, setFileName] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState({ open: false, msg: "" });
  const [draftDetails, setdraftDetails] = useState<ContractRes>();
  const [requisitionid, setRequisitionId] = useState<any>("");
  const [requisitionlink, setRequisitionlink] = useState<any>("");
  const [openapproval, setopenapproval] = useState<any>(false);
  const [version, setversion] = useState<any>("");
  const [reqresponse, setreqresponse] = useState<any[]>([]);
  const [draft_details, setdraft_details] = useState<ContractRes>();
  const [supportdoc, setsupportdoc] = useState<any>([]);
  let user_profile: any = {};

  useEffect(() => {
    setDraftContracts(props.draftContractData);
  }, [props.draftContractData]);
  useEffect(() => {
    const current_user: any = localStorage.getItem("user_profile");
    if (typeof current_user == "object") {
      user_profile = JSON.parse(current_user);
    }
  }, [localStorage.getItem("user_profile")]);

  const openDocViewer = async (draft: ContractRes) => {
    if (draft.status == draftStatus.DRAFT_CREATION_PENDING) {
      props.openDocViewerDialog(draft);
    } else {
      if (draft.link && draft.link != "") {
        const response = await getdraftbyid(draft?.id);
        setdraftDetails(response);
        setDraftLink(response.access_url);
        setFileName(draft.contractName);
        setsupportdoc(draft?.support_document);
        setOpen(true);
      } else {
        setOpenSnackBar({ open: true, msg: "Draft link not present" });
      }
    }
  };
  const openforapproval = async (draftContract: any) => {
    const response = await getreqbyidbyid(draftContract?.id);
    setRequisitionId(draftContract?.id);
    setRequisitionlink(response.access_url);
    setversion(draftContract?.version);
    setreqresponse(draftContract);
    setopenapproval(true);
  };

  const handleClose = (res: any) => {
    if (res) {
      setOpenSnackBar({ open: true, msg: "Signature is completed" });
    }
    props.getDrafts();
    setOpen(false);
  };

  const handleSnackBarClose = () => {
    setOpenSnackBar({ open: false, msg: "" });
  };

  const showVersionHistory = (event: any, id: string) => {
    event.stopPropagation();
    props.openVersionHistory(id);
  };

  const showSupportDocs = (event: any, id: string, alldata: any) => {
    event.stopPropagation();
    props.openSupportDoc(id, alldata);
  };

  const showAssignedTo = (event: any, id: string, draftContract: any) => {
    // ========Need Clarification on this========

    // let payload = {
    //   approvalTypes: [
    //     draftContract.approvalTypes[0],
    //     {
    //       approval_sequence: "All",
    //       approval_type_name: "Adhoc",
    //       approvers: [],
    //     },
    //     {
    //       approval_sequence: "All",
    //       approval_type_name: "Conditional",
    //       approvers: [],
    //     },
    //   ],
    // };
    // if (draftContract.approvalTypes?.length == 1) {
    //   const type = draftContract.docID.split("");
    //   props.onPatch(draftContract.id, payload, type[0] + type[1] + type[2]);
    // event.stopPropagation();
    // props.openAssignedTo(id);
    // } else {
    event.stopPropagation();
    props.openAssignedTo(id);
  };

  const showApprovalStatus = (event: any, id: string, draftContract: any) => {
    event.stopPropagation();
    props.openApprovalStatus(id);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleSnackBarClose}>
        OK
      </Button>
    </React.Fragment>
  );

  const isOwner = (clickedDraft: any) => {
    const user_data = getLocalStorage("user_profile");
    if (clickedDraft?.owner.id === user_data.id) {
      return true;
    }
    return false;
  };

  return (
    <div className="draft-list-view-container">
      {draftContracts.map((draftContract: any, key: number) => {
        // setcollabsign([...draftContract?.collaborators,...draftContract?.signatories])
        let spreadboth: any = [];
        const signatoriesstart = draftContract?.signatories
          ? draftContract?.signatories.map((i: any) => {
              i["assigned_role"] = ["Signatories"];
              return i;
            })
          : [];
        const colabratorstart = draftContract?.collaborators
          ? draftContract?.collaborators.map((i: any) => {
              i["assigned_role"] = ["Collaborators"];
              return i;
            })
          : [];

        const presignatoriesstart = draftContract?.pre_signatories
          ? draftContract?.pre_signatories?.map((i: any) => {
              i["assigned_role"] = ["Pre Signatories"];
              return i;
            })
          : [];

        // if(props.allspreaded.length==0 && draftContract.approvalTypes.length===1){
        spreadboth = [
          ...colabratorstart,
          ...signatoriesstart,
          ...presignatoriesstart,
        ];
        let spreadeddata: any = [];
        const collabrator = draftContract.collaborators.map((i: any) => {
          i["assigned_role"] = ["Collaborators"];
          return i;
        });
        const signatories = draftContract.signatories.map((i: any) => {
          i["assigned_role"] = ["Signatories"];
          return i;
        });

        const presignatories = draftContract?.pre_signatories?.map((i: any) => {
          i["assigned_role"] = ["Pre Signatories"];
          return i;
        });
        if (draftContract?.approvalTypes[2]) {
          if (presignatories && collabrator && signatories) {
            spreadeddata = [
              ...collabrator,
              ...draftContract?.approvalTypes[2]?.approvers,
              ...signatories,
              ...presignatories,
            ];
          }
        }

        return (
          <div key={key}>
            <div className="row m-0 p-0 df">
              <div
                className={`draft-list-view col-md-12 ${
                  props.selectedDraft.indexOf(draftContract.id) > -1
                    ? "selected-draft"
                    : ""
                }`}
                style={{ backgroundColor: key % 2 != 0 ? "white" : "#FAFAFA" }}
              >
                <div className="row template-list-view-table mb-2 mt-2">
                  <div
                    className="col-md-1"
                    style={{
                      maxWidth: "2%",
                      paddingLeft: "8px",
                      marginTop: "7px",
                    }}
                  >
                    {props.selectedDraft.indexOf(draftContract.id) == -1 && (
                      <input
                        onChange={() =>
                          props.selectMultipleDrafts(draftContract.id)
                        }
                        style={{ width: "15px", height: "15px" }}
                        type="checkbox"
                        name="check"
                        id="check"
                      />
                    )}
                    {props.selectedDraft.indexOf(draftContract.id) > -1 && (
                      <img
                        className="checked-icon"
                        onClick={() =>
                          props.selectMultipleDrafts(draftContract.id)
                        }
                        src="/static_images/checked_icon.svg"
                        alt="checked"
                      ></img>
                    )}
                  </div>
                  <div className="col-md-1">
                    <Tooltip
                      title={draftContract.docID || draftContract.id}
                      arrow
                    >
                      <div
                        className="text-overflow"
                        onClick={() => {
                          const owner = isOwner(draftContract);
                          const user_data = getLocalStorage("user_profile");

                          const approvalData = draftContract?.approvalTypes.map(
                            (item: any) => {
                              const approverFilteredData = item[
                                "approvers"
                              ].filter(
                                (data: any) => data?.id === user_data?.id
                              );
                              return approverFilteredData;
                            }
                          );
                          const collaboratorData =
                            draftContract?.collaborators.filter(
                              (item: any) => item?.id === user_data?.id
                            );
                          const signatoryData =
                            draftContract?.signatories.filter(
                              (item: any) => item?.id === user_data.id
                            );

                          const presignatoryData =
                            draftContract?.pre_signatories.filter(
                              (item: any) => item?.id === user_data.id
                            );

                          if (
                            owner ||
                            approvalData[approvalData.length - 1]?.length > 0 ||
                            presignatoryData?.length > 0 ||
                            collaboratorData?.length > 0 ||
                            signatoryData?.length > 0
                          ) {
                            if (draftContract.version !== 0) {
                              openDocViewer(draftContract);
                            }
                          } else {
                            setOpenSnackBar({
                              open: true,
                              msg: "You are not assigned any role for this draft",
                            });
                          }
                        }}
                      >
                        {draftContract?.docID || draftContract?.requisition_id}
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className="col-md-1 text-overflow c-primary"
                    style={{
                      paddingLeft: "4%",
                      maxWidth: "12%",
                      flex: "0 0 12%",
                    }}
                  >
                    <Tooltip title={draftContract.contractName} arrow>
                      <div
                        className="text-overflow c-primary"
                        onClick={() => {
                          const owner = isOwner(draftContract);
                          const user_data = getLocalStorage("user_profile");

                          const approvalData = draftContract?.approvalTypes.map(
                            (item: any) => {
                              const approverFilteredData = item[
                                "approvers"
                              ].filter(
                                (data: any) => data?.id === user_data?.id
                              );
                              return approverFilteredData;
                            }
                          );
                          const collaboratorData =
                            draftContract?.collaborators.filter(
                              (item: any) => item?.id === user_data?.id
                            );
                          const signatoryData =
                            draftContract?.signatories.filter(
                              (item: any) => item?.id === user_data.id
                            );

                          const presignatoryData =
                            draftContract?.pre_signatories?.filter(
                              (item: any) => item?.id === user_data.id
                            );
                          if (
                            owner ||
                            approvalData[approvalData.length - 1]?.length > 0 ||
                            collaboratorData?.length > 0 ||
                            presignatoryData?.length > 0 ||
                            signatoryData?.length > 0
                          ) {
                            if (
                              (draftContract.version !== 0 &&
                                draftContract.version === 1 &&
                                draftContract.status !==
                                  draftStatus.DRAFT_CREATION_PENDING) ||
                              (draftContract.version !== 1 &&
                                draftContract.version !== 0)
                            ) {
                              openDocViewer(draftContract);
                            } else if (
                              draftContract.version === 1 &&
                              draftContract.status ===
                                draftStatus.DRAFT_CREATION_PENDING &&
                              draftContract.createFrom === "template"
                            ) {
                              props.setTemplate(draftContract);
                            } else {
                              openforapproval(draftContract);
                            }
                          } else {
                            setOpenSnackBar({
                              open: true,
                              msg: "You are not assigned any role for this draft",
                            });
                          }
                        }}
                      >
                        {(draftContract.contractName === null ||
                          draftContract.contractName === "") && (
                          <img src="/static_images/empty-dash.svg"></img>
                        )}

                        {draftContract.contractName !== null &&
                          draftContract.contractName}
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className="col-md-1 c-primary"
                    onClick={(event: any) =>
                      showSupportDocs(event, draftContract.id, draftContract)
                    }
                  >
                    {draftContract?.support_document &&
                      draftContract?.support_document.length}
                  </div>
                  <div
                    onClick={(event) => {
                      showVersionHistory(event, draftContract.id);
                      props.setSpecificDraft(draftContract);
                    }}
                    className="col-md-1 c-primary"
                    style={{ maxWidth: "12%" }}
                  >
                    {"V" + draftContract.version}
                  </div>

                  <div className="col-md-2 p-0">
                    <Tooltip
                      title={draftContract.contractType?.displayName}
                      arrow
                    >
                      <div className="text-overflow m-0">
                        <CustomChip
                          data={[draftContract.contractType?.displayName]}
                          showItems={1}
                          addFilter={(type) =>
                            props.applyFilter(
                              [type.toLowerCase()],
                              "contractType"
                            )
                          }
                          isCancel={
                            props.filterKeyVals["contractType"]
                              ? props.filterKeyVals["contractType"].length
                                ? true
                                : false
                              : false
                          }
                          cancelAction={(remove) =>
                            props.applyFilter([], "contractType", remove)
                          }
                        />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-md-2 p-0">
                    <div className="row">
                      <Tooltip
                        title={draftContract?.projectNames?.join(", ")}
                        arrow
                      >
                        <div className="text-overflow m-0">
                          <CustomChip
                            backgroundColor="#D8F7E6"
                            textColor="#00692A"
                            data={draftContract?.projectNames}
                            showItems={1}
                            addFilter={(name) =>
                              props.applyFilter(
                                [name.toLowerCase()],
                                "projectNames"
                              )
                            }
                            isCancel={
                              props.filterKeyVals["projectNames"]
                                ? props.filterKeyVals["projectNames"].length
                                  ? true
                                  : false
                                : false
                            }
                            cancelAction={(remove) =>
                              props.applyFilter([], "projectNames", remove)
                            }
                          />
                        </div>
                      </Tooltip>
                      {draftContract?.groups &&
                        draftContract?.groups.length && (
                          <Tooltip
                            title={draftContract?.groups?.join(", ")}
                            arrow
                          >
                            <div className="text-overflow m-0">
                              <CustomChip
                                backgroundColor="rgb(255, 239, 228)"
                                textColor="rgb(238, 88, 0)"
                                data={draftContract?.groups}
                                showItems={1}
                                addFilter={(name) =>
                                  props.applyFilter(
                                    [name.toLowerCase()],
                                    "groups"
                                  )
                                }
                                isCancel={
                                  props.filterKeyVals["groups"]
                                    ? props.filterKeyVals["groups"].length
                                      ? true
                                      : false
                                    : false
                                }
                                cancelAction={(remove) =>
                                  props.applyFilter([], "groups", remove)
                                }
                              />
                            </div>
                          </Tooltip>
                        )}
                    </div>
                  </div>
                  <div
                    className="col-md-2 c-primary"
                    style={{
                      paddingLeft: "10px",
                      flex: "0 0 17%",
                      maxWidth: "17%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip title={draftContract.status} arrow>
                      <div
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        onClick={(event) => {
                          showApprovalStatus(
                            event,
                            draftContract.id,
                            draftContract
                          );
                        }}
                      >
                        {draftContract.approval_status !== null &&
                        draftContract.hasOwnProperty("approval_status") ? (
                          <span
                            className="cursor-pointer"
                            onClick={(event) => {
                              //showApprovalStatus(event, draftContract.id, draftContract);
                              /* setdraft_details(draftContract);
                        let link = document.getElementById(
                          "approvalStatusTableButton"
                        );
                        !isNullOrUndefined(link) && link.click();*/
                            }}
                          >
                            {draftContract.approval_status.split(",")[0]}
                          </span>
                        ) : (
                          <>
                            {draftContract.version == 0 ? (
                              draftContract.status === null ? (
                                <img src="/static_images/empty-dash.svg"></img>
                              ) : draftContract.status.toLowerCase() !=
                                  "approved" ||
                                draftContract.status !=
                                  "Requisition Approved" ||
                                draftContract.status == "" ? (
                                draftContract?.approvalTypes[0]?.approvers.filter(
                                  (val: any) => val.id == user_profile.id
                                ).length ? (
                                  draftContract?.approvalTypes[0]?.approvers.filter(
                                    (val: any) => val.id == user_profile.id
                                  )[0].approval_status
                                ) : (
                                  draftContract.status
                                )
                              ) : (
                                draftContract.status
                              )
                            ) : draftContract.status === null ||
                              draftContract.status === "" ? (
                              <img src="/static_images/empty-dash.svg"></img>
                            ) : (
                              draftContract.status
                            )}
                          </>
                        )}
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    className="col-md-1 d-flex"
                    style={{ padding: "0", marginTop: "0", cursor: "pointer" }}
                    onClick={(event) => {
                      if (draftContract?.approvalTypes?.length) {
                        if (draftContract.version !== 0) {
                          showAssignedTo(
                            event,
                            draftContract.id,
                            draftContract
                          );
                          props.setSpecificDraft(draftContract);
                        } else {
                          setOpenSnackBar({
                            open: true,
                            msg: "Assignee is not supported for requisition",
                          });
                        }
                      }
                    }}
                  >
                    {draftContract?.approvalTypes?.length &&
                    draftContract?.approvalTypes[2]?.approvers?.length ? (
                      <Avatarfordraft users={spreadeddata} />
                    ) : draftContract?.collaborators?.length ||
                      draftContract?.signatories?.length ? (
                      <Avatarfordraft users={spreadboth} />
                    ) : (
                      <div
                        className="fw custom-chip m-0"
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#88305f",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Start Assigning
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      {open && (
        <PdfViewer
          open={open}
          onClose={handleClose}
          fileName={fileName}
          draftDetails={draftDetails}
          openAssignedTo={props.openAssignedTo}
          setSpecificDraft={props.setSpecificDraft}
          setdraftDetails={setdraftDetails}
          uploadFile={props.uploadFile}
        />
      )}
      <Snackbar
        open={openSnackBar.open}
        autoHideDuration={2000}
        message={openSnackBar.msg}
        onClose={handleSnackBarClose}
        key="bottomCenter"
        action={action}
      />
      {openapproval && requisitionlink !== null && (
        <ForApproval
          //   title="Upload Draft"
          //   action="Contract_Draft"
          version={version}
          openmain={setopenapproval}
          draftcomponent={props.draftContractData}
          //   uploadedFileResponse={uploadAndCreateDraft}
          show={false}
          reqres={reqresponse}
          requisitionId={requisitionid}
          close={() => {}}
          createLink={props.createLink}
          open={openapproval}
          setopenapproval={setopenapproval}
          getDrafts={props.getDrafts}
        />
      )}

      <ApprovalModalView draft_details={draft_details} />

      <button
        style={{ display: "none" }}
        type="button"
        data-toggle="modal"
        data-target="#documentLibraryTableModal"
        id="approvalStatusTableButton"
      ></button>
    </div>
  );
};

export default ListViewTable;
