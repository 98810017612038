import React, { Component } from "react";

interface Props {
  title: string;
  subTitle: string;
  selectedFilter: string;
  selectFilter: (selectedFilter: string) => void;
  isClickable: boolean;
  source?: string;
}

export const SubTitle: any = {
  totalUploads: "No. of Contracts",
  processed: "Processed",
  uploadSize: "Used Space",
  all: "Document",
  noForceMajeure: "No Force Majeure",
  noAssignment: "No Assignment",
  totalDocuments: "Total Documents",
  // "allDocuments": "Total Documents",
  unreviewed: "Unreviewed",
  reviewed: "Reviewed",
  withTasks: "With Tasks",
  withoutTasks: "Without Tasks",
  supportDocuments: "No of Support Documents",
};

export default class UploadCard extends Component<Props> {
  selectFilter = (filter: string) => {
    let { isClickable, selectFilter } = this.props;
    if (isClickable) {
      selectFilter(filter);
    }
  };
  render() {
    let { title, subTitle, selectedFilter, selectFilter, isClickable, source } =
      this.props;
    return (
      <div className="row">
        <div
          className={
            !isClickable
              ? "col-md-12 quick-look-card-container-disable-upload"
              : selectedFilter === subTitle
              ? "col-md-12 quick-look-card-container-active-upload"
              : "col-md-12 quick-look-card-container"
          }
          onClick={() => this.selectFilter(subTitle)}
          style={
            source === "documentLibrary"
              ? { maxWidth: "58%", padding: "2% 3%" }
              : {}
          }
        >
          <div className="row">
            <div className="col-md-12 quick-look-sub-title-upload mt-5 w-100">
              {SubTitle[subTitle]}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 quick-look-title mt-4">{title}</div>
          </div>
        </div>
      </div>
    );
  }
}
