import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Drawer, Snackbar } from "@material-ui/core";
import { BsArrowLeft } from "react-icons/bs";
import { IconContext } from "react-icons";
import { AiOutlineReload } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import {
  getRequestApprovals,
  sendemailtoapproval,
} from "../DA";
import { ContractRes, Sort } from "../State/DraftState";
import Avatar from "../../UniversalComponents/Avatar/Component/Avatar";
import saveAs from "file-saver";
import PdfViewer from "./PdfViewerDialog";
import { isNullOrUndefined } from "is-what";
interface Props {
  open: boolean;
  onClose: () => void;
  selectedDraft: any | null;
  getContractDraft: () => void;
  uploadContract: (contract: any) => Promise<any> | undefined;
  selectedDraftsLink: any;
  setselectedDraftsLink: any;
  getDrafts: any;
}
const ApprovalStatusDialog = (props: Props) => {
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(false);
  const [openUploadNew, setopenUploadNew] = useState(false);
  const [contractVersions, setcontractVersions] = useState<ContractRes[]>([]);
  const [selectedDrafts, setselectedDrafts] = useState<string[]>([]);
  const [selectedComparedDrafts, setselectedComparedDrafts] = useState<any>([]);
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteDrafts, setdeleteDrafts] = useState<ContractRes[]>([]);
  const [openSnackbar, setopenSnackbar] = useState({ open: false, msg: "" });
  const [draftId, setDraftID] = useState("");
  const [latestVersion, setlatestVersion] = useState(0);
  const [openSupportDoc, setopenSupportDoc] = useState(false);
  const [openapproval, setopenapproval] = useState(false);
  const [fileData, setFileData] = useState("");
  const [requisitionId, setRequisitionId] = useState<any>("");
  const [version, setversion] = useState<any>("");
  const [reqresponse, setreqresponse] = useState<any[]>([]);
  const [contractData, setcontractData] = useState<any>({});
  const [openpdfviewr, setOpenpdfviewr] = useState(false);
  const [draftLink, setDraftLink] = useState("");
  const [draftContracts, setDraftContracts] = useState<ContractRes[]>([]);
  const [fileName, setFileName] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [draftDetails, setdraftDetails] = useState<ContractRes>();
  const [selectedDraft, setSelectedDraft] = useState<any>([]);
  const [totalVersion, setTotalVersion] = useState<any>(0);

  const [latestDraftDetails, setlatestDraftDetails] = useState<ContractRes>();
  const [isInternal, setisinternal] = useState<any>(true);
  const handleClose = () => {
    setopen(false);
    props.onClose();
  };
  const [show, setShow] = useState(true);
  const [approvals, setapprovals] = React.useState<any>({});
  const [approvalstatistics, setapprovalstatistics] = React.useState<any>({
    approved_approvers: 0,
    pending_approvers: 0,
    rejected_approvers: 0,
    reworked_approvers: 0,
    total_approvers: 0,
  });
  const [request_approvals, setrequest_approvals] = React.useState<any>([]);
  const [selectedRequestAprovals, setselectedRequestAprovals] = useState<
    string[]
  >([]);
  useEffect(() => {}, [
    selectedDraft,
    totalVersion,
    selectedRequestAprovals,
    request_approvals,
    openSnackbar,
  ]);

  const setSorting = (contracts: any[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...contracts];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "number") {
          if (sortData.sortType == "asc") {
            return a[sortData.sortKey] - b[sortData.sortKey];
          }
          if (sortData.sortType == "desc") {
            return b[sortData.sortKey] - a[sortData.sortKey];
          }
        }
        if (sortData.valueType == "string" && sortData.sortType == "asc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return +1;
          } else return -1;
        }
        if (sortData.valueType == "string" && sortData.sortType == "desc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return -1;
          } else return +1;
        }
        if (sortData.valueType == "date") {
          if (sortData.sortType == "asc") {
            return (
              new Date(a[sortData.sortKey]).getTime() -
              new Date(b[sortData.sortKey]).getTime()
            );
          }
          if (sortData.sortType == "desc") {
            return (
              new Date(b[sortData.sortKey]).getTime() -
              new Date(a[sortData.sortKey]).getTime()
            );
          }
        }
        return sorted;
      });
    }
  };

  useEffect(() => {
    console.log("props.selectedDraft", props.selectedDraft)
    if (props.selectedDraft && props.selectedDraft.id) {
      setDraftID(props.selectedDraft.id);
      //   getVersions();
      setDraftLink(props.selectedDraft.link)
      setFileName(props.selectedDraft.contractName)
      setdraftDetails(props.selectedDraft)

      
      get_RequestApprovals();
    }
    setopen(props.open);
    return () => {
      setcontractVersions([]);
    };
  }, [props.selectedDraft, props.open]);

  useEffect(() => {
    setSorting(contractVersions);
  }, [sortData]);

  const get_RequestApprovals = async () => {
    try {
      setloading(true);
      const response = await getRequestApprovals(
        props.selectedDraft.id
      );
      if (response && response.results) {
        let response_data: any = response.results;
        setapprovals(response_data);
        setapprovalstatistics(response.statistics);
        let request_approval_data: any[] = [];
        Object.keys(response_data).map((item: any) => {
          {
            response_data[item].map((requestApproval: any) => {
              request_approval_data.push(requestApproval);
            });
          }
        });
        setrequest_approvals(request_approval_data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  };

  const sendbulkMail = async () => {
    let approval_details = request_approvals.filter(
      (approvals: { id: string }) => {
        return selectedRequestAprovals.indexOf(approvals.id) !== -1;
      }
    );

    let approvers: any[] = [];
    for (let i = 0; i < approval_details.length; i++) {
      approvers.push(approval_details[i]["approvers"]["id"]);
    }
    if (approval_details.length > 0) {
      let created_by = "";
      const user_data: string | null = localStorage.getItem("user_profile");

      if (user_data) {
        let userdata = JSON.parse(user_data);
        created_by = userdata.first_name + " " + userdata.last_name;
      }
      let mail_subject =
        created_by + " seeks Approval for " + props.selectedDraft?.contractName;
      let link = process.env.REACT_APP_RIVERUS_CLM_APP_HOST + `draftingreview${props.selectedDraft.id}`;

      let email_payload: any = {
        user_ids: approvers,
        body_html:
          "<h2>Approvals for Draft</h2><h4>" +
          mail_subject +
          "<br><a href='" +
          link +
          "'>click here to see the draft</a></h4>",
        body_text: mail_subject,
        subject:
          created_by +
          " seeks Approval for " +
          props.selectedDraft?.contractName,
      };

      if (!isNullOrUndefined(created_by)) {
        const response = await sendemailtoapproval(email_payload);

        if (response) {
          setopenSnackbar({
            open: true,
            msg: "Reminder sent successfully",
          });
        }
      }
    }
  };

  const selectRequestApprovals = (id: string) => {
    let selected = [...selectedRequestAprovals];
    let index = selected.indexOf(id);
    if (index > -1) {
      selected.splice(index, 1);
    } else {
      selected.push(id);
    }
    setselectedRequestAprovals([...selected]);
  };

  useEffect(() => {}, [props.selectedDraftsLink, selectedComparedDrafts]);
  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
    if (sortData.sortType == "asc") {
      let data: any[] = [];
      if (sortData.sortKey == "modified_on") {
        data = request_approvals.sort((a: any, b: any) =>
          a.modified_on > b.modified_on ? 1 : -1
        );
      } else {
        data = request_approvals.sort((a: any, b: any) =>
          a.created_on > b.created_on ? 1 : -1
        );
      }
      setrequest_approvals(data);
    } else {
      let data: any[] = [];
      if (sortData.sortKey == "modified_on") {
        data = request_approvals.sort((a: any, b: any) =>
          a.modified_on > b.modified_on ? -1 : 1
        );
      } else {
        data = request_approvals.sort((a: any, b: any) =>
          a.created_on > b.created_on ? -1 : 1
        );
        setrequest_approvals(data);
      }
    }
  };

  const download = () => {
    let drafts = selectedDrafts.reduce<ContractRes[]>((acc, curr) => {
      let drafts = contractVersions.find((value) => value.id == curr);
      if (drafts) {
        acc = [...acc, drafts];
      }
      return acc;
    }, []);
    if (drafts.length == 1) {
      let name = drafts[0].link.split("/").pop();
      saveAs(drafts[0].link, name);
    }
  };
  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });
  };

  const handleSelectAllRequestApprovals = () => {
    let selected: string[] = [];
    if (selectedRequestAprovals.length > 0) {
      setselectedRequestAprovals([]);
      return;
    }
    selected = request_approvals.map(
      (request_approval: { id: any }) => request_approval.id
    );
    setselectedRequestAprovals([...selected]);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <div className="container version-history" style={{ width: "780px" }}>
        {loading && <CircularProgress className="loader" />}
        <div className="row align-items-center">
          <div className="col-md-4 df align-items-center">
            <IconContext.Provider value={{ className: "back-arrow-icon" }}>
              <BsArrowLeft
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </IconContext.Provider>
            <p className="m-0 ml-3 fw">Approval Record</p>
          </div>
          <div className="col-md-8 text-align-end">
            <button
              type="button"
              onClick={sendbulkMail}
              className="btn btn-warning create-btn"
              style={{ fontWeight: "500" }}
            >
              <img
                src="/static_images/bulk-reminder.svg"
                alt="refresh"
                className="mr-1"
              />
              Send Bulk Reminder
            </button>
          </div>
        </div>
        <div className="d-flex mt-3 pl-0">
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {approvalstatistics["total_approvers"]}
            </p>
            <p className="myDocument-uploadtype">APPROVAL CONFIGURED</p>
          </div>
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {approvalstatistics["pending_approvers"]}
            </p>
            <p className="myDocument-uploadtype">APPROVALS PENDING</p>
          </div>
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {approvalstatistics["approved_approvers"]}
            </p>
            <p className="myDocument-uploadtype">APPROVALS APPROVED</p>
          </div>
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {approvalstatistics["rejected_approvers"]}
            </p>
            <p className="myDocument-uploadtype">APPROVALS REJECTED</p>
          </div>
          <div className="mr-4">
            <p
              className="myDocument-uploadNo"
              style={{ lineHeight: "unset", height: "30px" }}
            >
              {approvalstatistics["reworked_approvers"]}
            </p>
            <p className="myDocument-uploadtype">APPROVALS REWORK</p>
          </div>
        </div>

        <div className="row justify-content-between" style={{ height: "33px" }}>
          <div className="col-md-8">
            <div
              className="row mt-1 mb-1 df align-items-center"
              style={{ marginLeft: "0" }}
            >
              <IconContext.Provider
                value={{
                  color: "#88305F",
                  className: "reload-icon cursor-pointer",
                }}
              >
                <AiOutlineReload onClick={get_RequestApprovals} />
              </IconContext.Provider>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="df mt-1" style={{ justifyContent: "end" }}>
              {selectedDrafts.length == 1 && (
                <IconContext.Provider
                  value={{
                    color: "#88305F",
                    className: "svg-delete-icon cp",
                    style: { strokeWidth: "0.5" },
                  }}
                >
                  <BsDownload onClick={download} />
                </IconContext.Provider>
              )}
            </div>
          </div>
        </div>
        <div className="row list-view-header version-history__table-header">
          <div
            className="col-md-1 df align-items-center"
            // style={{ maxWidth: "6%" }}
          >
            {selectedRequestAprovals.length == 0 && (
              <input
                onChange={(e) => handleSelectAllRequestApprovals()}
                // style={{ width: "15px", height: "15px" }}
                type="checkbox"
                name="check"
                id="check"
              />
            )}
            {selectedRequestAprovals.length > 0 && (
              <img
                onClick={() => handleSelectAllRequestApprovals()}
                className="checked-icon"
                style={{ top: "0" }}
                src="/static_images/checked_icon.svg"
                alt="checked"
              ></img>
            )}
          </div>
          <div
            className="col-md-2"
            // style={{ maxWidth: "13%", flex: "0 0 13%" }}
          >
            Approval Type
          </div>
          <div
            className="col-md-3"
            style={{
              maxWidth: "18%",
              //  flex: "0 0 20%"
            }}
          >
            Approval Sequence
          </div>
          <div
            className="col-md-1"
            style={{
              maxWidth: "45%",
              // flex: "0 0 27%"
            }}
          >
            Approvers
          </div>
          <div className="col-md-1">Status</div>
          <div className="col-md-2">
            Assigned Date
            <span>
              <img
                onClick={() => applySort("created_on", "date")}
                src="static_images/calender_filter_inactive.svg"
              />
            </span>
          </div>

          <div className="col-md-2">
            Approved Date{" "}
            <span>
              <img
                onClick={() => applySort("modified_on", "date")}
                src="static_images/calender_filter_inactive.svg"
              />
            </span>
          </div>
        </div>
        {/* {JSON.stringify(approvals)} */}

        {/* {Object.keys(approvals).map((item: any) => { */}
        {/* return (<> */}
        {/* {approvals[item].map((requestApproval: any) => { */}
        {request_approvals.map((requestApproval: any) => {
          return (
            <div
              key={requestApproval["id"]}
              className={`row version-history__table-list`}
              //   ${
              //     selectedDrafts.indexOf(contract.id) > -1 ? "selected-draft" : ""
              //   }`}
              //   style={{ backgroundColor: index % 2 != 0 ? "white" : "#FAFAFA" }}
            >
              <div
                className="col-md-1 df align-items-center"
                style={
                  {
                    // maxWidth: "6%", padding: "0 10px"
                  }
                }
              >
                <input
                  style={{ width: "15px", height: "15px" }}
                  onClick={() => {
                    selectRequestApprovals(requestApproval["id"]);
                  }}
                  type="checkbox"
                  name="check"
                  id="check"
                  checked={selectedRequestAprovals.includes(
                    requestApproval["id"]
                  )}
                />
              </div>
              <div
                className="col-md-2 df align-items-center"
                style={
                  {
                    // maxWidth: "6%", padding: "0 10px"
                  }
                }
                onClick={()=>{
                  setOpenpdfviewr(true);
                }}
              >
                {isNullOrUndefined(requestApproval["approval"])
                  ? "Adhoc"
                  : "Conditional"}
              </div>
              <div
                className="col-md-2 df align-items-center"
                style={{
                  maxWidth: "18%",
                  padding: "0 10px",
                }}
              >
                All
              </div>
              <div
                className="col-md-1 df align-items-center"
                style={{ maxWidth: "45%" }}
              >
                <Avatar users={[requestApproval["approvers"]]} />
              </div>
              <div className="col-md-1 df align-items-center">
                {requestApproval["status"] == "InProgress"
                  ? "Pending"
                  : requestApproval["status"]}
              </div>
              <div
                className="col-md-2 df align-items-center"
                style={{ paddingLeft: "5%" }}
              >
                {new Date(requestApproval["created_on"]).toLocaleDateString()}
              </div>
              <div
                className="col-md-2 df align-items-center"
                style={{ paddingLeft: "5%" }}
              >
                {requestApproval["created_on"] ==
                requestApproval["modified_on"] ? (
                  "-"
                ) : (
                  <>
                    {new Date(
                      requestApproval["modified_on"]
                    ).toLocaleDateString()}
                  </>
                )}
              </div>
            </div>
          );
        })}
        {/* </> */}
        {/* ) */}
        {/* // })} */}
      </div>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar.open}
        onClose={closeSnackbar}
        message={openSnackbar.msg}
        key="bottomCenter"
        action={action}
      />
      {openpdfviewr && (
        <PdfViewer
          open={openpdfviewr}
          onClose={()=>{
            setOpenpdfviewr(false)
          }}
          fileName={fileName}
          draftDetails={draftDetails}
        />
      )}
     {/*  {openapproval && (
        <ForApproval
          //   title="Upload Draft"
          //   action="Contract_Draft"
          //   uploadedFileResponse={uploadAndCreateDraft}
          draftcomponent=""
          reqres={reqresponse}
          openmain={setopenapproval}
          version={version}
          requisitionId={requisitionId}
          fileLink={fileData}
          close={() => ""}
          show={false}
          open={openapproval}
          setopenapproval={setopenapproval}
          createLink={undefined}
          getDrafts={props.getDrafts}
        />
      )} */}
    </Drawer>
  );
};

export default ApprovalStatusDialog;
