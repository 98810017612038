import { IconButton } from "@material-ui/core";
import React from "react";
import "../Design/CustomChip.scss";
interface Props {
  textColor?: string;
  backgroundColor?: string;
  data: any[];
  showItems: number;
  isCancel?:boolean;
  cancelAction?:(item:string) => void;
  addFilter?:(data:string) => void;
  displayAdditionalItem?:() => void;
}
const CustomChip = (props: Props) => {
  const getItem = (index:number,item:any) => {
    if(index < props?.showItems){
      if(props.isCancel){
        return <div
                  className="fw custom-chip m-0"
                  style={{
                    color: props?.textColor ? props.textColor : "",
                    backgroundColor: props.backgroundColor
                      ? props.backgroundColor
                      : "",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {item} {props.isCancel ? <IconButton style={{padding:'0'}} onClick={() => props.cancelAction ? props.cancelAction(item) : ''} color="primary" aria-label="cancel button">X</IconButton> : ''}
              </div>
      }else {
        return <div
                  onClick={() => {return props.addFilter ? props.addFilter(item): ""}}
                  className="fw custom-chip m-0"
                  style={{
                    color: props?.textColor ? props.textColor : "",
                    backgroundColor: props.backgroundColor
                      ? props.backgroundColor
                      : "",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {item}
              </div>
      }
    }
  }
  return (
    <div className="df m-0 align-items-center">
      {props?.data?.length > 0 &&
        props?.data?.map((item, index) => (
          <div key={index} className="df m-0 mr-1" style={{whiteSpace:'pre-wrap'}}>
            {getItem(index,item)}
          </div>
        ))}
      {props.data.length - props.showItems > 0 && (
        <span onClick={() => {return props.displayAdditionalItem ? props.displayAdditionalItem() : "" }}>+{props.data.length - props.showItems}</span>
      )}
    </div>
  );
};

export default CustomChip;