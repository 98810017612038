import * as React from "react";

import { SITE_API_BY_REALM_NAME } from "../../../../Configuration/global";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../../../Authentication/Actions/authentication";
import Tooltip from "@material-ui/core/Tooltip";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";

import { connect } from "react-redux";
import {
  ClauseErrorData,
  ClauseErrorList,
} from "../State/clauseErrorManagementState";
import Scrollable from "../../../Scrollable/scrollable";
import BarLoader from "../../../Loader/barLoader";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

interface Props {
  clauseErrorData: ClauseErrorList[];
  editMode?: boolean;
  fileID: string;
  //   getClauseErrorData: () => void;
}

interface State {}

interface SelectedBIType {
  value: string;
}

export default class clauseErrorModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.props.getTaskProgress();
    // this.props.getUserData();
    // this.props.getClauseErrorData();
  }

  dismissModal = () => {
    // let dismissBtn = document.getElementById('request-close-btn');
    // setTimeout(() => { dismissBtn?.click() }, 1500);
    this.setState({});
  };

  render() {
    // let fileID = this.props;

    let clauseErrorData = this.props;
    return (
      <>
        <br />
        <br />
        <br />
        <div className="col-md-12 col-12">
          <div
            className="row modal"
            id="clauseErrorModal"
            aria-labelledby="clauseErrorModal"
          >
            <div
              className="col-md-12 modal-dialog"
              style={{
                height: "100vh",
                marginTop: "0",
                marginBottom: "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div className="row">
                <div className="col-md-12 modal-content">
                  <div className="row">
                    <div className="col-md-12 modal-body">
                      <span
                        data-dismiss="modal"
                        style={{ float: "right", cursor: "pointer" }}
                        id="file-hierarchy-modal-close-btn"
                        onClick={() => this.dismissModal()}
                      >
                        <img
                          src="/static_images/close-modal-icn.svg"
                          alt="close"
                        />
                      </span>
                    </div>

                    <div
                      className="col-md-12 modal-title"
                      style={{ color: "#88305f" }}
                    >
                      {clauseErrorData.clauseErrorData.length == 0 ? (
                        ""
                      ) : (
                        <>Auto extraction failed. Manually review</>
                      )}
                    </div>

                    <div className="modal-body modal-subtitle">
                      {clauseErrorData.clauseErrorData.length == 0 ? (
                        <BarLoader />
                      ) : (
                        <>
                          {clauseErrorData.clauseErrorData.map((data, i) => (
                            <React.Fragment key={i}>
                              <div className="document-tree-container">
                                <div className="document-tree">
                                  <span
                                    className="document-tree-title-text"
                                    style={{
                                      background: "none",
                                      textDecoration: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {data.label}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="col-md-11 toc-row toc-row-default"
                                style={{ display: "none" }}
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-12">
                                        {data.label}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                  {clauseErrorData.clauseErrorData.length == 0 ? (
                    ""
                  ) : (
                    <div className="modal-footer float-right">
                      <button
                        type="button"
                        className="upload-yellow-btn ml-4"
                        data-dismiss="modal"
                      >
                        OK
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12" style={{ display: "none" }}>
          <div className="row modal" id="taskModal" aria-labelledby="taskModal">
            <div className="col-md-12 modal-dialog large-modal-dialog">
              <div className="row">
                <div className="col-md-12 modal-content">
                  <div className="row">
                    <div
                      className="col-md-1"
                      style={{
                        borderTop: "1px solid #996C84",
                        marginTop: "1.2rem",
                      }}
                    ></div>
                    <div
                      className="col-md-4 modal-title"
                      style={{ marginTop: "0.6rem", textAlign: "center" }}
                    >
                      {this.props.editMode === true
                        ? "Edit Task or Event"
                        : "Add Task or Event"}
                    </div>
                    <div
                      className="col-md-6"
                      style={{
                        borderTop: "1px solid #996C84",
                        marginTop: "1.2rem",
                      }}
                    ></div>
                    <div
                      className="col-md-1"
                      onClick={() => this.dismissModal()}
                    >
                      <span
                        data-dismiss="modal"
                        style={{
                          float: "right",
                          cursor: "pointer",
                          marginTop: "0.5rem",
                        }}
                        id="request-close-btn"
                      >
                        <img
                          src="/static_images/close-modal-icn.svg"
                          alt="close"
                        />
                      </span>
                    </div>
                    <div className="col-md-12 modal-body">
                      <h6>
                        These are the below clauses are not properly parsed.
                      </h6>
                      {clauseErrorData.clauseErrorData.map((data, i) => (
                        <React.Fragment key={i}>
                          <div className="col-md-11 toc-row toc-row-default">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-12">{data.label}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    {/* ______________________________________-- */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
