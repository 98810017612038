import React from 'react'
import '../Design/contractType.scss';
const ListViewHeader = () => {
    return (
        <div className="col-md-12 p-0">
            <div className="row list-view-header">
                <div className="col-md-3" >
                    Contract Type Name
                </div>
                <div className="col-md-3">
                    Display Name
                </div>
                <div className="col-md-3">
                    Comments
                </div>
                <div className="col-md-3" >
                    Actions
                </div>
            </div>
        </div>
    )
}

export default ListViewHeader
