import { Avatar, Card, CardContent, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import React, { useEffect, useState } from 'react'
import { User } from '../../Draft/State/DraftState';
import HeimdallUtil from '../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil';
import { Approval, FieldValue } from '../State/ApprovalState';
import CreateApprovalDialog from './CreateApprovalDialog';
import DeleteApprovalDialog from './DeleteApprovalDialog';
import useConditionBuilder from './UseConditionBuilder';
import CustomChip from '../../UniversalComponents/CustomChip/Component/CustomChip';
import Avatarfordraft from '../../Draft/Component/AvatarForDraft';
interface Props {
    index: number;
    approval: Approval;
    getApprovals: () => void;
    currentUser: string;
    getColor:(type:string,role:any,isBackGroundColor:boolean) => void;
    removeApprover:(approverID:string,approval:any) => void;
}
const ApprovalsCard = (props: Props) => {
    const [isEditable, setisEditable] = useState(false);
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedMergeTag1, setSelectedMergeTag1] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<any>('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [duration, setduration] = useState<string>('');
    const {
        sectionList,
        mergeTagList,
        conditionList,
        defaultValueList,
        selectedDataType }: FieldValue = useConditionBuilder(selectedSection, selectedMergeTag1);
    const [openDeleteDialog, setopenDeleteDialog] = useState(false);
    const [openEditDialog, setopenEditDialog] = useState(false);

    useEffect(() => {
        setSelectedSection(props.approval.section_tag);
    }, [sectionList, props.approval]);

    useEffect(() => {
        setSelectedMergeTag1(props.approval.field_name);
    }, [mergeTagList, props.approval]);

    useEffect(() => {
        setSelectedCondition(props.approval.conditions);
    }, [conditionList, props.approval]);

    useEffect(() => {
        setSelectedValue(props.approval.value);
        setduration(props.approval.optional_data);
    }, [defaultValueList, props.approval]);

    const editApproval = () => {
        setopenEditDialog(true);
    }

    const handleCloseEditDialog = () => {
        setopenEditDialog(false);
    }

    const selectSection = (event: any) => {
        setSelectedSection(event.target.value);
    }

    const selectMergeTag1 = (event: any) => {
        setSelectedMergeTag1(event.target.value);
    }

    const handleValue = (event: any) => {
        setSelectedValue(event.target.value);
    }

    const selectCondition = (event: any) => {
        setSelectedCondition(event.target.value);
    }

    const handleDuration = (event: any) => {
        setduration(event.target.value);
    }

    const getApprovalCount = (count: number): string => {
        if (count < 10) {
            return `#00${count}`;
        }
        if (count < 100) {
            return `#0${count}`;
        }
        return `#${count}`;
    }


    const handleClose = () => {
        setopenDeleteDialog(false);
    }

    const handleDelete = () => {
        setopenDeleteDialog(true);
    }


    return (
        <div>
            <Card className='mb-3' elevation={3}>
                <CardContent style={{ padding: '0' }}>
                    <div className='card-title'>
                        <div className="row">
                            <div className="col-md-2">
                                Approval {getApprovalCount(props.approval.approval_number)}
                            </div>
                            <div className="col-md-3">
                                Approval Name: {props.approval.approval_name}
                            </div>
                            <div className="col-md-6">
                                Approval Type: {props.approval.approval_type}
                            </div>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="row align-items-center mb-3">
                            <div className="col-md-1" style={{ flex: '0' }}>IF</div>
                            <div className="col-md-2">
                                <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                    <InputLabel>Section Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth={true}
                                        label='Section Name'
                                        disabled={!isEditable}
                                        onChange={selectSection}
                                        value={selectedSection}
                                    >
                                        {sectionList.map((value, index) => (
                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-2">
                                <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                    <InputLabel>Field Name</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth={true}
                                        label='Field Name'
                                        disabled={!isEditable}
                                        value={selectedMergeTag1}
                                        onChange={selectMergeTag1}
                                    >
                                        {mergeTagList.map((value, index) => (
                                            <MenuItem key={index} value={value.mergeTag}>{value.mergeTag}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-2" style={{ flex: '0 0 13%' }}>
                                <FormControl size='small' variant="outlined" className="formControl" fullWidth={true}>
                                    {/* <InputLabel>Conditions</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth={true}
                                        label='Merge Tag'
                                        disabled={!isEditable}
                                        value={selectedCondition}
                                        onChange={selectCondition}
                                    >
                                        {!(props.approval.scope && props.approval.scope.toLowerCase() == 'all sections') && conditionList.map((value, index) => (
                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                        ))}
                                        {(props.approval.scope && props.approval.scope.toLowerCase() == 'all sections') && ['is present','is absent','contains','does not contain','is','is not'].map((value,index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))}
                                    </Select> */}
                                    <TextField
                                    fullWidth={true}
                                    disabled
                                    size='small'
                                    id="outlined-basic"
                                    label="Conditions"
                                    variant="outlined"
                                    value={selectedCondition}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-md-2" style={{ flex: '0 0 20%', maxWidth: '20%' }}>
                                {selectedDataType == 'duration' && <div className="row">
                                    <div className="col-md-5">
                                        <TextField fullWidth={true} disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)} size='small' id="outlined-basic" label="Value" variant="outlined" value={selectedValue} onChange={handleValue} />
                                    </div>
                                    <div className="col-md-7">
                                        <FormControl fullWidth={true} size='small' variant="outlined">
                                            <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={duration}
                                                label="Duration"
                                                onChange={handleDuration}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                            >
                                                <MenuItem value={'Month(s)'}>Month(s)</MenuItem>
                                                <MenuItem value={'Year(s)'}>Year(s)</MenuItem>
                                                <MenuItem value={'Days'}>Day(s)</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>}

                                {selectedDataType == 'text' && <TextField
                                    fullWidth={true}
                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                    size='small'
                                    id="outlined-basic"
                                    label="Value"
                                    variant="outlined"
                                    value={selectedValue}
                                    onChange={handleValue} />}

                                {selectedDataType == '' && <TextField
                                    fullWidth={true}
                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                    size='small'
                                    id="outlined-basic"
                                    label="Value"
                                    variant="outlined"
                                    value={selectedValue}
                                    onChange={handleValue} />}

                                {selectedDataType == 'numeric' && <TextField
                                    type='number'
                                    fullWidth={true}
                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                    size='small'
                                    id="outlined-basic"
                                    label="Value"
                                    variant="outlined"
                                    value={selectedValue}
                                    onChange={handleValue} />}

                                {selectedDataType == 'text-box' && <TextField
                                    fullWidth={true}
                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                    size='small'
                                    id="outlined-multiline-flexible"
                                    label="Value"
                                    variant="outlined"
                                    multiline
                                    maxRows={10}
                                    value={selectedValue}
                                    onChange={handleValue}
                                />}

                                {selectedDataType == 'currency' && <FormControl size='small' fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount">Value</InputLabel>
                                    <OutlinedInput
                                        type='number'
                                        disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                        id="outlined-adornment-amount"
                                        value={selectedValue}
                                        onChange={handleValue}
                                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                                        label="Value"
                                    />
                                </FormControl>}

                                {selectedDataType == 'date' && <TextField
                                    type='date'
                                    fullWidth={true}
                                    disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                    size='small'
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={selectedValue}
                                    onChange={handleValue} />}

                                {selectedDataType == 'percentage' && <FormControl size='small' fullWidth variant="outlined">
                                    <InputLabel htmlFor="standard-adornment-amount">Value</InputLabel>
                                    <OutlinedInput
                                        type='number'
                                        disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                        id="outlined-adornment-amount"
                                        value={selectedValue}
                                        onChange={handleValue}
                                        startAdornment={<InputAdornment position="start">%</InputAdornment>}
                                        label="Value"
                                    />
                                </FormControl>}

                                {selectedDataType == 'select-default' && <div className="row">
                                    <div className="col-md-5">
                                        <TextField fullWidth={true} disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)} size='small' id="outlined-basic" label="Value" variant="outlined" value={selectedValue} onChange={handleValue} />
                                    </div>
                                    <div className="col-md-7">
                                        <FormControl fullWidth={true} size='small' variant="outlined">
                                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={duration}
                                                label="Select"
                                                onChange={handleDuration}
                                                disabled={(selectedCondition == 'is present' || selectedCondition == 'is absent' || !isEditable)}
                                            >
                                                {defaultValueList.map((val: string, index) => (
                                                    <MenuItem key={index} value={val}>{val}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-md-2">
                                then trigger approval from
                            </div>
                            <div className="col-md-1" style={{ position: 'relative', right: '15px', flex: '0 0 10%', maxWidth: '10%' }}>
                                <div className="row">
                                    {props.approval.approvers.length > 0 && props.approval.approvers.map((approver:any, index) => (
                                        <Avatarfordraft users={props.approval.approvers} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="col-md-auto">
                                Applicable For :
                            </div>
                            {props.approval.applicable_for.length > 0 && props.approval.applicable_for.map((contractType, index) => (
                                <div key={index} className="col-md-auto">
                                    <div className="approval-chip">
                                        {contractType?.name}
                                        <span onClick={() => props.removeApprover(contractType.id,props.approval)} className='hover ml-1'><img src="/static_images/close-modal-icn.svg" alt="close_icon" /></span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row align-items-center mb-3">
                            <div className="row col-md-4">
                                <div className="col-md-4">
                                    Created By :
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-auto">
                                        <Avatarfordraft users={[props.approval.created_by]} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-auto">
                                        Created on:
                                    </div>
                                    <div className="col-md-auto">
                                        {new Date(props.approval.created_on).toLocaleDateString()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className='row justify-content-end'>
                                    <div onClick={editApproval} style={{ padding: '0' }} className="col-md-1 text-purple hover">
                                        <img src="/static_images/edit_icn.svg"></img>
                                    </div>
                                    <div className="col-md-2 text-purple hover">
                                        <img onClick={handleDelete} height={19} src="/static_images/delete-icon.svg"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
            <DeleteApprovalDialog approvalName={props.approval.approval_name} id={props.approval.id} open={openDeleteDialog} count={props.index} onClose={handleClose} getApprovals={props.getApprovals} />
            {openEditDialog && <CreateApprovalDialog
                open={openEditDialog}
                onClose={handleCloseEditDialog}
                count={props.index}
                edit={true}
                approvalData={props.approval}
                getApprovals={props.getApprovals}
                currentUser={props.currentUser}
                getColor={props.getColor}
            />}
        </div>
    )
}

export default ApprovalsCard