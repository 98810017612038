import React, { useEffect, useState } from "react";
import PageNavigation from "../../UniversalComponents/PageNavigation/Component/PageNavigation";
import {
  ContractData,
  ContractRes,
  FilterData,
  Sort,
} from "../State/DraftState";
import ListViewHeader from "./listViewHeader";
import ListViewTable from "./ListViewTable";

interface Props {
  draftContractData: ContractRes[];
  selectedDraft: string[];
  selectMultipleDrafts: (id: string) => void;
  openVersionHistory: (draftId: string) => void;
  openSupportDoc: (draftId: string, alldata: any) => void;
  contractTypeList: any[];
  projectList: any[];
  openDocViewerDialog: (draft: ContractRes) => void;
  openAssignedTo: any;
  setSpecificDraft: any;
  onPatch: any;
  setTemplate: any;
  getDrafts: any;
  createLink: any;
  selectAllDraft: () => void;
  updatePageNumber: (pageNumber: number) => void;
  openApprovalStatus: any;
  pageNumber: number;
  pageCount: number;
  allspreaded:any;
  statusList:any[];
  uploadFile: (post: any, fileType: any, callback: Function) => void;
}

const DraftListView = (props: Props) => {
  const [filterData, setFilterData] = useState<ContractRes[]>([]);
  const [filterKeyVals, setFilterKeyVals] = useState<FilterData>({});
  const [sortData, setSortData] = useState<Sort>({
    sortKey: "",
    sortType: "",
    valueType: "",
  });
  useEffect(() => {
    if (Object.values(filterKeyVals).length > 0) {
      setFiter(props.draftContractData);
    } else if (sortData.sortKey) {
      setSorting(props.draftContractData);
    } else setFilterData(props.draftContractData);
  }, [props.draftContractData]);

  useEffect(() => {
    setFiter(props.draftContractData);
  }, [filterKeyVals]);

  useEffect(() => {
    setSorting(props.draftContractData);
  }, [sortData]);

  const setFiter = (contracts: any[]) => {
    if (Object.values(filterKeyVals).length > 0) {
      let filteredList = [...contracts];
      Object.keys(filterKeyVals).forEach((data) => {
        const detail: any = filteredList.find((a: any) => a[data]);
        const searchKey = filterKeyVals[data];
        if (searchKey.length) {
          if (typeof detail?.[data] === "string") {
            filteredList = filteredList.filter((a: any) =>
              searchKey.includes(a[data].toLowerCase())
            );
          } else {
            filteredList = filteredList.filter((a: any) => {
              if (Object.keys(filterKeyVals)[0] === "groups") {
                let isAvailable = a[data]?.filter((groups: string) => {
                  return searchKey.includes(groups.toLowerCase());
                });
                if (isAvailable?.length > 0) {
                  return true;
                } else return false;
              } else {
                if (data === "contractType") {
                  const isAvailable = searchKey.includes(
                    a[data].displayName.toLowerCase()
                  );
                  return isAvailable;
                } else {
                  let isAvailable = a[data]?.filter((projectName: string) => {
                    return searchKey.includes(projectName?.toLowerCase());
                  });
                  if (isAvailable && isAvailable.length > 0) {
                    return true;
                  } else return false;
                }
              }
            });
          }
        }
      });
      setFilterData(filteredList);
    }
  };

  const setSorting = (contracts: any[]) => {
    if (sortData.sortKey) {
      let filteredList: any[] = [...contracts];
      filteredList = filteredList.sort((a: any, b: any) => {
        let sorted = 0;
        if (sortData.valueType == "number") {
          if (sortData.sortType == "asc") {
            return a[sortData.sortKey] - b[sortData.sortKey];
          }
          if (sortData.sortType == "desc") {
            return b[sortData.sortKey] - a[sortData.sortKey];
          }
        }
        if (sortData.valueType == "string" && sortData.sortType == "asc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return +1;
          } else return -1;
        }
        if (sortData.valueType == "string" && sortData.sortType == "desc") {
          if (a[sortData.sortKey].toLowerCase() === b[sortData.sortKey].toLowerCase()) {
            return 0;
          }
          if (a[sortData.sortKey].toLowerCase() > b[sortData.sortKey].toLowerCase()) {
            return -1;
          } else return +1;
        }
        return sorted;
      });
      setFilterData([...filteredList]);
    }
  };

  const applyFilter = (searchKey: string[], filterKey: string,removeKey?:string) => {
    let searchKeys = [...searchKey]
    if(removeKey){
      let removeIndex = filterKeyVals[filterKey].findIndex((data) => data === removeKey);
      if(removeIndex || removeIndex>-1){
        let filterKeys = [...filterKeyVals[filterKey]];
        filterKeys.splice(removeIndex,1);
        searchKeys = [...filterKeys];
      }
    }
    setFilterKeyVals((prev) => ({
      ...prev,
      [filterKey]: searchKeys,
    }));
  };

  const applySort = (sortKey: string, valueType: string) => {
    if (sortData.sortKey == sortKey) {
      switch (sortData.sortType) {
        case "asc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "desc",
            valueType,
          });
          break;
        case "desc":
          setSortData({
            sortKey: sortData.sortKey,
            sortType: "asc",
            valueType,
          });
          break;
      }
    } else setSortData({ sortKey: sortKey, sortType: "asc", valueType });
  };

  return (
    <div className="p-0 container-fluid" style={{ width: "100%" }}>
      <div
        style={{
          marginLeft: "0rem",
          marginRight: "0rem",
          backgroundColor: "white",
        }}
      >
        <ListViewHeader
          contractTypes={props.contractTypeList}
          projectList={props.projectList}
          applySorting={applySort}
          applyFilter={applyFilter}
          filterKeyVals={filterKeyVals}
          selectedDraft={props.selectedDraft}
          selectAllDraft={props.selectAllDraft}
          totalCount={props.draftContractData.length}
          statusList={props.statusList}
        />
        <ListViewTable
          openDocViewerDialog={props.openDocViewerDialog}
          openSupportDoc={props.openSupportDoc}
          openVersionHistory={props.openVersionHistory}
          selectedDraft={props.selectedDraft}
          selectMultipleDrafts={props.selectMultipleDrafts}
          draftContractData={filterData}
          openAssignedTo={props.openAssignedTo}
          setSpecificDraft={props.setSpecificDraft}
          onPatch={props.onPatch}
          setTemplate={props.setTemplate}
          getDrafts={props.getDrafts}
          createLink={props.createLink}
          applyFilter={applyFilter}
          filterKeyVals={filterKeyVals}
          openApprovalStatus={props.openApprovalStatus}
          allspreaded={props.allspreaded}
          uploadFile={props.uploadFile}
        />
        <PageNavigation
          pageNumber={props.pageNumber}
          pageSize={10}
          totalCount={props.pageCount}
          updatePageNumber={props.updatePageNumber}
        />
      </div>
    </div>
  );
};

export default DraftListView;
