import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react'

interface Props {
    open: boolean;
    msg:string;
    onClose:(res:boolean) => void;
    title:string;
}
const ConfirmationPopup = (props:Props) => {
    const [open, setopen] = useState(false);

    useEffect(() => {
        setopen(props.open);
      }, [props.open]);

    const handleClose = () => {
        setopen(false);
        props.onClose(false);
    };
    const handleSubmit = () => {
        setopen(false);
        props.onClose(true);
    }
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle className="riverus-dialog-header">
        <div className="row">
          <div className="col-md-11">{props.title}</div>
          <div onClick={handleClose} className="col-md-1 close-icn">
            <img
              src="/static_images/close-analysismodal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-md-12">
            <p>{props.msg}</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{ backgroundColor: "rgba(0, 0, 0, 0.04)" }}
        >
          Cancel
        </Button>
        <Button className="create-btn" onClick={handleSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationPopup