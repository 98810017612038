import { connect } from "react-redux";
import { StoreTree } from "../../../../Utils/MainReducer/mainReducer";
import ClauseErrorManagementActionGenerator from "../Action/gen";

import ClauseErrorModal from "../Component/clauseErrorModal";

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    clauseErrorData: appState.clauserrorManagement.clauseErrorData,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getClauseErrorData: (fileiID: string) =>
      dispatch(
        ClauseErrorManagementActionGenerator.getClauseErrorData(fileiID)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClauseErrorModal);
