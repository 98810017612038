import {
  Accordion,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Typography,
  Box,
  Tab,
  Tabs,
  AppBar
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { isNullOrUndefined } from "is-what";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import { TabPanel } from "../../Approvals/Component/TabPanel";

interface Props {
  setModalVisible: any;
  draft_id: any;
  copiedText: string;
  setcopiedText: () => void;
  setselectedClause: (clause: string) => void;
  deviationPolicyData: any;
  deviationStatistics: any;
  setdeviationDataPoint: (kdpDataPoint: string) => void;
  setrecommendedValue: (clause: string) => void;
  setnodeId: (clause: string) => void;
  setparaId: (clause: string) => void;
  setdeviationapprovalId: (deviationapprovalId: string) => void;
  deletedeviation: (clause: any) => void;
  askforApproval: () => void;
  setapprovalType: (type: string) => void;
  refreshRulesAndRegulation: () => void;
  rulesdeviationLoader: boolean;
  addManually: (clause: any) => void;
  compliantdeviationPolicyData: any;
  setcompliantdeviationDatapoint: any;
  selectedSection: any;
  setselectedSection: (section:any) => void;
  setdraftselectedSection: (section:any) => void;
  draftselectedSection: any;
  setvalidationPopup: ()=> void
}

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

interface State {}

const ApprovalTab: React.FC<Props> = (props: Props) => {
  const [deviationPolicyData, setdeviationPolicyData] = React.useState([]);
  const [deviationStatistics, setdeviationStatistics] = React.useState({
    deviation_count: 0,
    total_approvals: 0,
    file_name: "",
  });
  const [open, setOpen] = React.useState(false);
  const [deviationNo, setdeviationNo] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const [deviationIds, setdeviationIds] = React.useState([]);
  React.useEffect(() => {
    // getDeviationPolicyFromApprovals();
  }, []);
  const [redchecked, setredChecked] = React.useState(true);
  const [amberchecked, setamberChecked] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);

  const getDeviationPolicyFromApprovals = () => {
    const URL =
      process.env.REACT_APP_RIVERUS_CLM_API +
      `contracts/deviation-policy/${props.draft_id}/`;

    return axios
      .get(URL, APIConfig())
      .then((response) => {
        if (response.status == 200) {
          let deviationData = response.data.queryResult.filter(
            (option: any) => option["deviation"] === true
          );
          setdeviationPolicyData(deviationData);
          setdeviationStatistics({
            deviation_count: response.data.deviation_count,
            total_approvals: response.data.approval_count,
            file_name: response.data.file_name,
          });
          setloader(false);
        }
        // ClauseData(response.data.results);
      })
      .catch((error) => {});
  };

  const showDraftData = (deviation_no: string) => {
    setOpen(!open);
    let link = document.getElementById(deviation_no);
    if (!isNullOrUndefined(link)) {
      if (open) {
        link.style.display = "block";
      } else {
        link.style.display = "none";
      }
      setdeviationNo(deviation_no);
    }
  };

  const handleredDeviation = (event: any) => {
    setredChecked(event.target.checked);
  };

  const handleamberDeviation = (event: any) => {
    setamberChecked(event.target.checked);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTabValue(newValue);
  };
  return (
    <>
      {props.rulesdeviationLoader ? (
        <CircularProgress className="loader" />
      ) : null}
      <Scrollable maxHeight={window.screen.height} padding={true}>
      <div className="" id="RulesDeviation">
        {/* Tab Component Start */}
        <div className="row col-12 mt-4">

        </div>
        <div className="ROOT">
          <AppBar position="static" className="mb-2">
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="simple tabs example"
            >
             <Tab disableRipple label="Deviations" {...a11yProps(0)} />
             <Tab disableRipple label="Compliant" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
            <div></div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div></div>
          </TabPanel>
          </AppBar>
          
        </div>
        {/* Tab Component End*/}
        {tabValue == 0?<>
        <div
          className="row align-items-center"
          //  style={{paddingTop:"50px"}}
        >
          <div className="col-9 col-md-9 d-flex checkbox-labal">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={redchecked}
                    onChange={handleredDeviation}
                  />
                }
                label={`Show Red Deviations (${props.deviationStatistics.red_deviation})`}
                style={{ color: "#4C4C4C", fontSize: "14px" }}
                className="rule-check-1"
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={amberchecked}
                    onChange={handleamberDeviation}
                  />
                }
                label={`Show Amber Deviations (${props.deviationStatistics.amber_deviation})`}
                style={{ color: "#4C4C4C", fontSize: "14px" }}
                className="rule-check-2"
              />
            </FormGroup>
          </div>
          <div className="col-3 col-md-3" style={{display:"none"}}>
            <div
              className="d-flex justify-content-center"
              style={{ gap: "25px" }}
            >
              <img
                src="/static_images/skip-previous-outline .svg"
                className="pl-1 w-10"
                style={{ cursor: "pointer" }}
              />
              <img
                src="/static_images/horizontal-scroll-arrow-left.svg"
                className="pl-1 w-10"
                style={{ cursor: "pointer" }}
              />
              <img
                src="/static_images/horizontal-scroll-arrow-right.svg"
                className="pl-1 w-10"
                style={{ cursor: "pointer" }}
              />
              <img
                src="/static_images/skip-next-outline.svg"
                className="pl-1 w-10"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
        <div className=" row col-12 p-0">
          <div className="col-md-4">
            {props.deviationStatistics.deviation_count}{" "}
            <span style={{ color: "#D20319" }}>policy deviations</span> found
            out of {props.deviationStatistics.total_approvals} applicable
            policies
          </div>
          <div
            className="col-md-8 d-flex justify-content-xl-end"
            style={{
              gap: "15px",
              fontSize: "14px",
              color: "#88305F",
            }}
          >
            <span
              className="d-flex align-items-center"
              style={{ gap: "6px", cursor: "pointer" }}
              onClick={() => {
                props.refreshRulesAndRegulation();
              }}
            >
              Refresh
              <img
                src="/static_images/uploadRefreshIcon.svg"
                className="pl-1 w-10"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
            </span>
            <span
              className="d-flex align-items-center"
              style={{ gap: "6px", cursor: "pointer" }}
              onClick={() => {
                console.log("Ask For approval");
                props.setapprovalType("amber-deviation");
                props.askforApproval();
              }}
            >
              Ask for Approval
              <img
                src="/static_images/logTabicon.svg"
                className="pl-1 w-10"
                style={{
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                }}
              />
            </span>
          </div>
        </div>
        {props.deviationPolicyData?.map((val: any, index: number) => {
          return val["deviation"] ? (
            <>
              {(val["type"] == "Red" && redchecked) ||
              (val["type"] == "Amber" && amberchecked) ? (
                <div
                  className="d-flex flex-column mt-3"
                  style={{ gap: "20px", display: "none" }}
                  id={`deviation-${index + 1}`}
                >
                  <Card
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #D20319",
                    }}
                  >
                    <CardContent>
                      <Typography>
                        <div
                          className="d-flex flex-column"
                          style={{ gap: "16px" }}
                        >
                          <div className=" row col-12 p-0">
                            <div className="col-10">
                              <span style={{ color: "#D20319" }}>
                                Deviation No. {index + 1}-{val["deviation_no"]}
                                {/* 2132518 */}
                              </span>{" "}
                              {val["type"] == "Red" ? (
                                <>
                                  in{" "}
                                  <span style={{ color: "#996C84" }}>
                                    &lt;{val["section_name"]}&gt;
                                  </span>
                                </>
                              ) : null}
                            </div>
                            <div
                              className="col-2 d-flex pr-0 justify-content-xl-end"
                              style={{
                                gap: "15px",
                                fontSize: "14px",
                                color: "#FBCE2F",
                              }}
                            >
                              {val["type"]}
                            </div>
                          </div>
                          {val["type"] == "Red" ? (
                            <div>
                              <div className=" row col-12 p-0">
                                <div className="col-6">In Policy</div>
                                <div
                                  className="col-6 pr-0 d-flex justify-content-xl-end"
                                  style={{
                                    gap: "15px",
                                    fontSize: "14px",
                                    color: "#88305F",
                                    width: "16px",
                                    height: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open("/approvals", "_blank")
                                  }
                                >
                                  View Approval Matrix Policy
                                  <img
                                    src="/static_images/new-tab-purple-big.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div
                                className="text-container mr-1"
                                style={{ backgroundColor: "#F1F7FF" }}
                              >
                                <p
                                  className="p-2"
                                  dangerouslySetInnerHTML={{
                                    __html: val["policy_statement"],
                                  }}
                                >
                                  {/* It is a long established fact that a reader
                                                will be distracted by the readable content
                                                of a page when looking at its layout. The
                                                point of using Lorem Ipsum is that it has a
                                                more-or-less normal distribu..
                                                <span style={{ color: "#88305F" }}>
                                                  Show more
                                                </span> */}
                                </p>
                              </div>
                              <div>
                                Supporting document:{" "}
                                <span style={{ color: "#88305F" }}>
                                  {/* Untitled.pdf{" "} */}
                                  {/* {props.deviationStatistics.file_name} */}
                                  NA
                                </span>
                                <img
                                  src="/static_images/new-tab-purple-big.svg"
                                  className="pl-1 w-14"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div>
                            <div>In Draft</div>
                            {val["id"].length==0?
                            <div className="text-container mr-1" style={{backgroundColor:"rgb(239, 239, 255)"}}>
                            <p className="p-2 " style={{fontWeight:"bolder"}}>Cannot automatically determine non-compliant language</p>
                            </div>
                            :null}
                            {/* <div
                          className="text-container mr-1"
                          style={{ backgroundColor: "#FFE5E8" }}
                        > */}
                            {/* <p className="p-2"> */}
                            {/* <span className="font-weight-bold">
                                                  Purchase Orders.
                                                </span>{" "} */}
                            {/* Hello world will submit all orders for
                                                Products to Amazon Grace India in writing to
                                                Amazon Grace India's address listed in the
                                                introduction to this agreement, or as Amazon
                                                Grace... */}
                            {/* <Accordion>
                                                <AccordionSummary
                                                      // expandIcon={<ExpandMoreIcon />}
                                                      aria-controls="panel1a-content"
                                                      id="panel1a-header"
                                                    >
                                                      <Typography> <span style={{ color: "#88305F" }}>
                                                                                              Show more
                                                                                            </span></Typography>
                                                      </AccordionSummary>
                                                                                              </Accordion> */}
                            {/* <span style={{ color: "#88305F" }} onClick={()=>{
                                                  showDraftData(val['deviation_no'])
                                                }}>
                                                  {open?'Hide': 'Show More'}
                                                </span> */}
                            {/* </p> */}
                            {/* <div
                            className="p-1"
                            key={val["deviation_no"]}
                            id={val["deviation_no"]}

                            // style={{display: "none"}}
                          > */}
                            <Scrollable maxHeight={150} padding={true}>
                              {val["children"].map(
                                (childvalues: any, j: number) => (
                                  <>
                                    {childvalues["childValue"].map(
                                      (values: any, k: number) => (
                                        <>
                                          <div
                                            className="text-container mr-1 "
                                            style={{
                                              backgroundColor: "#FFE5E8",
                                              marginBottom: "7px",
                                            }}
                                            key={`maindiv ${j}-${k}`}
                                          >
                                            <div
                                              className="p-1"
                                              key={`submaindiv ${val["deviation_no"]}-${j}-${k}`}
                                              id={val["deviation_no"]}

                                              // style={{display: "none"}}
                                            >
                                              <div className="row">
                                                <div className="col-md-11">
                                                  <p
                                                    // className="p-2"
                                                    key={`childvalue ${j}-${k}`}
                                                    style={{
                                                      // marginLeft: "12px",
                                                      marginRight: "12px",
                                                      paddingLeft: "0.5rem",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      props.setdraftselectedSection(val['section_name'])
                                                      props.setdeviationDataPoint(
                                                        values["dataPoints"][
                                                          "highlightedText"
                                                        ].replace(/\n/g, " ")
                                                      );
                                                      props.setnodeId(
                                                        values["dataPoints"][
                                                          "id"
                                                        ]
                                                      );
                                                      props.setparaId(
                                                        values["dataPoints"][
                                                          "paraId"
                                                        ]
                                                      );
                                                      props.setdeviationapprovalId(
                                                        val["approval_id"]
                                                      );
                                                    }}
                                                  >
                                                    {k + 1}
                                                    {"."}
                                                    {values["dataPoints"][
                                                      "highlightedText"
                                                    ].length > 250 ? (
                                                      <>
                                                        {
                                                          values["dataPoints"][
                                                            "highlightedText"
                                                          ]
                                                        }
                                                        {/* // .substring(0, 250)}{" "} */}
                                                        {/* // {"..."} */}
                                                      </>
                                                    ) : (
                                                      values["dataPoints"][
                                                        "highlightedText"
                                                      ]
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-md-1">
                                                  <img
                                                    className="cursor-pointer"
                                                    src="/static_images/delete-insight-icn.svg"
                                                    // alt="delete-insight" data-toggle="modal" data-target="#deleteInsightModal"
                                                    // style="z-index: 2;"
                                                    onClick={() => {
                                                      console.log("deleet")
                                                      props.deletedeviation({
                                                        nodeID: [
                                                          values["dataPoints"][
                                                            "id"
                                                          ],
                                                        ],
                                                        type: "Delete",
                                                        clause_text: [values["dataPoints"][
                                                          "highlightedText"
                                                        ]]
                                                      });
                                                    }}
                                                    style={{
                                                      paddingTop: "5px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        // )
                                      )
                                    )}
                                  </>
                                )
                              )}
                            </Scrollable>
                            {/* </div> */}
                            {/* </div> */}
                          </div>

                          <div className="d-flex" style={{ gap: "20px" }}>
                            {val["type"] == "Red" ? (
                              <>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    if(val['section_name'] == props.draftselectedSection){
                                      props.setapprovalType("red-deviation");
                                      props.setModalVisible("Request Approval");
                                      props.setselectedSection(val["section_name"]);
                                    }
                                    else{
                                      props.setvalidationPopup();
                                    }
                                  }}
                                >
                                  <img
                                    src="/static_images/tick-filter-icn.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Request Approval
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    if(val['section_name'] == props.draftselectedSection){
                                    props.setModalVisible("Replace with CL");
                                    // props.setselectedClause(val["label"].replace("Deviation in ", ""))
                                    props.setselectedClause(
                                      val["section_name"]
                                    );
                                    props.setrecommendedValue(
                                      val["set_recommended_value"]
                                    );
                                    }else{
                                      props.setvalidationPopup()
                                    }
                                  }}
                                >
                                  <img
                                    src="/static_images/texticon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Replace with CL
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    props.deletedeviation({
                                      nodeID: val["id"],
                                      type: "Not a Deviation",
                                      clause_text: val["clause_text"]
                                    });

                                    props.setModalVisible("Not a Deviation");
                                  }}
                                >
                                  <img
                                    src="/static_images/rulesbtnicon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Not a Deviation
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    //props.setapprovalType("red-deviation");
                                    //props.setModalVisible("Request Approval");
                                    props.addManually(val);
                                    console.log("Add Manually", val);
                                  }}
                                >
                                  <img
                                    src="/static_images/add_manual_deviation.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Add Manually
                                  </span>
                                </button>
                              </>
                            ) : (
                              <button
                                style={{ cursor: "pointer" }}
                                className="addtag-addHierarchy-addbtn"
                                onClick={() => {
                                  props.deletedeviation({
                                    nodeID: val["id"],
                                    type: "Delete",
                                    clause_text: val["clause_text"]
                                  });
                                }}
                              >
                                <img
                                  src="/static_images/tick-filter-icn.svg"
                                  className="pl-1 w-10"
                                  style={{ cursor: "pointer" }}
                                />
                                <span className="addtag-addHierarchy-addbtn-title ml-2">
                                  Ignore For Approval
                                </span>
                              </button>
                            )}
                          </div>

                          {val["section_name"] == "Confidentiality" ||
                          val["section_name"] == "Indemnity" ? (
                            <div className="custom-box">
                              <div className=" row col-12 p-0">
                                <div className="col-6 font-weight-bold">
                                  <img
                                    src="/static_images/stariconblack.svg"
                                    className="pl-1 w-10 mr-1"
                                    style={{ cursor: "pointer" }}
                                  />
                                  Similar Clauses from Executed Contracts
                                </div>
                                <div
                                  className="col-6 pr-0 d-flex justify-content-xl-end"
                                  style={{
                                    gap: "15px",
                                    fontSize: "14px",
                                    color: "#88305F",
                                    width: "16px",
                                    height: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open("/approvals", "_blank")
                                  }
                                >
                                  View Approval Matrix Policy
                                  <img
                                    src="/static_images/new-tab-purple-big.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div
                                className="text-container mr-1"
                                style={{ backgroundColor: "#efefff" }}
                              >
                                <p className="p-2">
                                  {/* <span className="font-weight-bold">
                              Purchase Orders.
                            </span>{" "} */}
                                  {/* Hello world will submit all orders for Products to
                            Amazon Grace India in writing to Amazon Grace
                            India's address listed in the introduction to this
                            agreement, or as Amazon Grace... */}
                                  {val["section_name"] == "Confidentiality"
                                    ? "The Service Provider shall, in relation to Personal Data, fully comply with all requirements of the PDPA, including the requirements concerning the collection, use and disclosure of Personal Data."
                                    : null}
                                  {val["section_name"] == "Indemnity"
                                    ? "Indemnification for Negligence, Gross Negligence, etc. HDBFS shall indemnify only in the event of actual loss suffered or incurred due to gross negligence, intentional or willful misconduct or fraud committed by HDBFS in the course of acting as the recipient of services under this Agreement."
                                    : null}
                                  {val["section_name"] == "Confidentiality" ||
                                  val["section_name"] == "Indemnity" ? null : (
                                    <>
                                      Hello world will submit all orders for
                                      Products to Amazon Grace India in writing
                                      to Amazon Grace India's address listed in
                                      the introduction to this agreement, or as
                                      Amazon Grace...
                                    </>
                                  )}
                                  {/* <span style={{ color: "#88305F" }}>Show more</span> */}
                                </p>
                              </div>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                >
                                  <img
                                    src="/static_images/staricongray.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Replace with Suggested Alternative
                                  </span>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              ) : null}
            </>
          ) : null;
        })}
        </>:''}

        {tabValue == 1? <>
          {props.compliantdeviationPolicyData?.map((val: any, index: number) => {
          return val["compliant"] ? ( 
            <>
             
                <div
                  className="d-flex flex-column mt-3"
                  style={{ gap: "20px", display: "none" }}
                  id={`deviation-${index + 1}`}
                >
                  <Card
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #43C888",
                    }}
                  >
                    <CardContent>
                      <Typography>
                        <div
                          className="d-flex flex-column"
                          style={{ gap: "16px" }}
                        >
                          <div className=" row col-12 p-0">
                            <div className="col-10">
                              <span style={{ color: "#43C888" }}>
                              Compliant No. {index + 1}-{val["deviation_no"]}
                                {/* 2132518 */}
                              </span>{" "}
                              {val["type"] == "Red" ? (
                                <>
                                  in{" "}
                                  <span style={{ color: "#996C84" }}>
                                    &lt;{val["section_name"]}&gt;
                                  </span>
                                </>
                              ) : null}
                            </div>
                            {/* <div
                              className="col-2 d-flex pr-0 justify-content-xl-end"
                              style={{
                                gap: "15px",
                                fontSize: "14px",
                                color: "#FBCE2F",
                                display: "none !important"
                              }}
                            >
                              {val["type"]}
                            </div> */}
                          </div>
                          {val["type"] == "Red" ? (
                            <div>
                              <div className=" row col-12 p-0">
                                <div className="col-6">In Policy</div>
                                <div
                                  className="col-6 pr-0 d-flex justify-content-xl-end"
                                  style={{
                                    gap: "15px",
                                    fontSize: "14px",
                                    color: "#88305F",
                                    width: "16px",
                                    height: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open("/approvals", "_blank")
                                  }
                                >
                                  View Approval Matrix Policy
                                  <img
                                    src="/static_images/new-tab-purple-big.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div
                                className="text-container mr-1"
                                style={{ backgroundColor: "#F1F7FF" }}
                              >
                                <p
                                  className="p-2"
                                  dangerouslySetInnerHTML={{
                                    __html: val["policy_statement"],
                                  }}
                                >
                                  {/* It is a long established fact that a reader
                                                will be distracted by the readable content
                                                of a page when looking at its layout. The
                                                point of using Lorem Ipsum is that it has a
                                                more-or-less normal distribu..
                                                <span style={{ color: "#88305F" }}>
                                                  Show more
                                                </span> */}
                                </p>
                              </div>
                              <div>
                                Supporting document:{" "}
                                <span style={{ color: "#88305F" }}>
                                  {/* Untitled.pdf{" "} */}
                                  {/* {props.deviationStatistics.file_name} */}
                                  NA
                                </span>
                                <img
                                  src="/static_images/new-tab-purple-big.svg"
                                  className="pl-1 w-14"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div>
                            <div>In Draft</div>
                            <div className="text-container mr-1" style={{backgroundColor:"rgb(239, 239, 255)"}}>
                            <p className="p-2 " style={{fontWeight:"bolder"}}>No non-compliant language found</p>
                            </div>
                            {/* <div
                          className="text-container mr-1"
                          style={{ backgroundColor: "#FFE5E8" }}
                        > */}
                            {/* <p className="p-2"> */}
                            {/* <span className="font-weight-bold">
                                                  Purchase Orders.
                                                </span>{" "} */}
                            {/* Hello world will submit all orders for
                                                Products to Amazon Grace India in writing to
                                                Amazon Grace India's address listed in the
                                                introduction to this agreement, or as Amazon
                                                Grace... */}
                            {/* <Accordion>
                                                <AccordionSummary
                                                      // expandIcon={<ExpandMoreIcon />}
                                                      aria-controls="panel1a-content"
                                                      id="panel1a-header"
                                                    >
                                                      <Typography> <span style={{ color: "#88305F" }}>
                                                                                              Show more
                                                                                            </span></Typography>
                                                      </AccordionSummary>
                                                                                              </Accordion> */}
                            {/* <span style={{ color: "#88305F" }} onClick={()=>{
                                                  showDraftData(val['deviation_no'])
                                                }}>
                                                  {open?'Hide': 'Show More'}
                                                </span> */}
                            {/* </p> */}
                            {/* <div
                            className="p-1"
                            key={val["deviation_no"]}
                            id={val["deviation_no"]}

                            // style={{display: "none"}}
                          > */}
                            <Scrollable maxHeight={150} padding={true}>
                              {val["children"].map(
                                (childvalues: any, j: number) => (
                                  <>
                                    {childvalues["childValue"].map(
                                      (values: any, k: number) => (
                                        <>
                                          <div
                                            className="text-container mr-1 "
                                            style={{
                                              backgroundColor: "#FFE5E8",
                                              marginBottom: "7px",
                                            }}
                                            key={`maindiv ${j}-${k}`}
                                          >
                                            <div
                                              className="p-1"
                                              key={`submaindiv ${val["deviation_no"]}-${j}-${k}`}
                                              id={val["deviation_no"]}

                                              // style={{display: "none"}}
                                            >
                                              <div className="row">
                                                <div className="col-md-11">
                                                  <p
                                                    // className="p-2"
                                                    key={`childvalue ${j}-${k}`}
                                                    style={{
                                                      // marginLeft: "12px",
                                                      marginRight: "12px",
                                                      paddingLeft: "0.5rem",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      props.setdeviationDataPoint(
                                                        values["dataPoints"][
                                                          "highlightedText"
                                                        ].replace(/\n/g, " ")
                                                      );
                                                      props.setnodeId(
                                                        values["dataPoints"][
                                                          "id"
                                                        ]
                                                      );
                                                      props.setparaId(
                                                        values["dataPoints"][
                                                          "paraId"
                                                        ]
                                                      );
                                                      props.setdeviationapprovalId(
                                                        val["approval_id"]
                                                      );
                                                      
                                                    }
                                                    
                                                  }
                                                    
                                                  >
                                                    {k + 1}
                                                    {"."}
                                                    {values["dataPoints"][
                                                      "highlightedText"
                                                    ].length > 250 ? (
                                                      <>
                                                        {
                                                          values["dataPoints"][
                                                            "highlightedText"
                                                          ]
                                                        }
                                                        {/* // .substring(0, 250)}{" "} */}
                                                        {/* // {"..."} */}
                                                      </>
                                                    ) : (
                                                      values["dataPoints"][
                                                        "highlightedText"
                                                      ]
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="col-md-1">
                                                  <img
                                                    className="cursor-pointer"
                                                    src="/static_images/delete-insight-icn.svg"
                                                    // alt="delete-insight" data-toggle="modal" data-target="#deleteInsightModal"
                                                    // style="z-index: 2;"
                                                    onClick={() => {
                                                      console.log("deleet")
                                                      props.deletedeviation({
                                                        nodeID: [
                                                          values["dataPoints"][
                                                            "id"
                                                          ],
                                                        ],
                                                        type: "Delete",
                                                        clause_text: [values["dataPoints"][
                                                          "highlightedText"
                                                        ]]
                                                      });
                                                    }}
                                                    style={{
                                                      paddingTop: "5px",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                        // )
                                      )
                                    )}
                                  </>
                                )
                              )}
                            </Scrollable>
                            {/* </div> */}
                            {/* </div> */}
                          </div>

                          <div className="d-flex flex-row-reverse" style={{ gap: "20px" }}>
                            {val["type"] == "Red" ? (
                              <>
                                <button
                                  style={{ cursor: "pointer",display: "none" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    props.setapprovalType("red-deviation");
                                    props.setModalVisible("Request Approval");
                                  }}
                                >
                                  <img
                                    src="/static_images/tick-filter-icn.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Request Approval
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer",display: "none" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    props.setModalVisible("Replace with CL");
                                    // props.setselectedClause(val["label"].replace("Deviation in ", ""))
                                    props.setselectedClause(
                                      val["section_name"]
                                    );
                                    props.setrecommendedValue(
                                      val["set_recommended_value"]
                                    );
                                  }}
                                >
                                  <img
                                    src="/static_images/texticon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Replace with CL
                                  </span>
                                </button>
                                {val['id'].length >0?
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                   /* props.deletedeviation({
                                      nodeID: val["id"],
                                      type: "Deviation",
                                      clause_text: val["clause_text"]
                                    });*/
                                    console.log("Node Id", val['id'])
                                    props.setcompliantdeviationDatapoint({
                                      highlightedText: val["clause_text"],
                                      nodeId: val["id"],
                                      field_name: val['field_name']
                                    });
                                    props.setModalVisible("Deviation");
                                  }}
                                >
                                  <img
                                    src="/static_images/rulesbtnicon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Mark as a Deviation
                                  </span>
                                </button>:<>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    //props.setapprovalType("red-deviation");
                                    //props.setModalVisible("Request Approval");
                                    props.addManually(val);
                                    console.log("Add Manually", val);
                                  }}
                                >
                                  <img
                                    src="/static_images/add_manual_deviation.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Add Manually
                                  </span>
                                </button>
                                </>
                            }
                                <button
                                  style={{ cursor: "pointer", display:"none" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() => {
                                    //props.setapprovalType("red-deviation");
                                    //props.setModalVisible("Request Approval");
                                    props.addManually(val);
                                    console.log("Add Manually", val);
                                  }}
                                >
                                  <img
                                    src="/static_images/add_manual_deviation.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Add Manually
                                  </span>
                                </button>
                              </>
                            ) : (
                              <button
                                style={{ cursor: "pointer" }}
                                className="addtag-addHierarchy-addbtn"
                                onClick={() => {
                                  props.deletedeviation({
                                    nodeID: val["id"],
                                    type: "Delete",
                                    clause_text: val["clause_text"]
                                  });
                                }}
                              >
                                <img
                                  src="/static_images/tick-filter-icn.svg"
                                  className="pl-1 w-10"
                                  style={{ cursor: "pointer" }}
                                />
                                <span className="addtag-addHierarchy-addbtn-title ml-2">
                                  Ignore For Approval
                                </span>
                              </button>
                            )}
                          </div>

                          {val["section_name"] == "Confidentiality" ||
                          val["section_name"] == "Indemnity" ? (
                            <div className="custom-box" style={{display:"none"}}>
                              <div className=" row col-12 p-0">
                                <div className="col-6 font-weight-bold">
                                  <img
                                    src="/static_images/stariconblack.svg"
                                    className="pl-1 w-10 mr-1"
                                    style={{ cursor: "pointer" }}
                                  />
                                  Similar Clauses from Executed Contracts
                                </div>
                                <div
                                  className="col-6 pr-0 d-flex justify-content-xl-end"
                                  style={{
                                    gap: "15px",
                                    fontSize: "14px",
                                    color: "#88305F",
                                    width: "16px",
                                    height: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    window.open("/approvals", "_blank")
                                  }
                                >
                                  View Approval Matrix Policy
                                  <img
                                    src="/static_images/new-tab-purple-big.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div
                                className="text-container mr-1"
                                style={{ backgroundColor: "#efefff", }}
                              >
                                <p className="p-2">
                                  {/* <span className="font-weight-bold">
                              Purchase Orders.
                            </span>{" "} */}
                                  {/* Hello world will submit all orders for Products to
                            Amazon Grace India in writing to Amazon Grace
                            India's address listed in the introduction to this
                            agreement, or as Amazon Grace... */}
                                  {val["section_name"] == "Confidentiality"
                                    ? "The Service Provider shall, in relation to Personal Data, fully comply with all requirements of the PDPA, including the requirements concerning the collection, use and disclosure of Personal Data."
                                    : null}
                                  {val["section_name"] == "Indemnity"
                                    ? "Indemnification for Negligence, Gross Negligence, etc. HDBFS shall indemnify only in the event of actual loss suffered or incurred due to gross negligence, intentional or willful misconduct or fraud committed by HDBFS in the course of acting as the recipient of services under this Agreement."
                                    : null}
                                  {val["section_name"] == "Confidentiality" ||
                                  val["section_name"] == "Indemnity" ? null : (
                                    <>
                                      Hello world will submit all orders for
                                      Products to Amazon Grace India in writing
                                      to Amazon Grace India's address listed in
                                      the introduction to this agreement, or as
                                      Amazon Grace...
                                    </>
                                  )}
                                  {/* <span style={{ color: "#88305F" }}>Show more</span> */}
                                </p>
                              </div>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                >
                                  <img
                                    src="/static_images/staricongray.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Replace with Suggested Alternative
                                  </span>
                                </button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
           
            </>
          ) : null;
        })}
        </>:null}
        {/* <div
                      className="d-flex flex-column mt-6"
                      style={{ gap: "20px", display: "none" }}

                    >
                      <Card
                        style={{
                          borderRadius: "8px",
                          border: "1px solid #D20319",
                        }}
                      >
                        <CardContent>
                          <Typography>
                            <div
                              className="d-flex flex-column"
                              style={{ gap: "16px" }}
                            >
                              <div className=" row col-12 p-0">
                                <div className="col-10">
                                  <span style={{ color: "#D20319" }}>
                                    Deviation No. 2132518
                                  </span>{" "}
                                  in{" "}
                                  <span style={{ color: "#996C84" }}>
                                    &lt;Payment Obligations&gt;
                                  </span>
                                </div>
                                <div
                                  className="col-2 d-flex pr-0 justify-content-xl-end"
                                  style={{
                                    gap: "15px",
                                    fontSize: "14px",
                                    color: "#FBCE2F",
                                  }}
                                >
                                  Amber
                                </div>
                              </div>
                              <div>
                                <div className=" row col-12 p-0">
                                  <div className="col-6">In Policy</div>
                                  <div
                                    className="col-6 pr-0 d-flex justify-content-xl-end"
                                    style={{
                                      gap: "15px",
                                      fontSize: "14px",
                                      color: "#88305F",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  >
                                    View Approval Matrix Policy
                                    <img
                                      src="/static_images/new-tab-purple-big.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="text-container mr-1"
                                  style={{ backgroundColor: "#F1F7FF" }}
                                >
                                  <p className="p-2">
                                    It is a long established fact that a reader
                                    will be distracted by the readable content
                                    of a page when looking at its layout. The
                                    point of using Lorem Ipsum is that it has a
                                    more-or-less normal distribu..
                                    <span style={{ color: "#88305F" }}>
                                      Show more
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  Supporting document:{" "}
                                  <span style={{ color: "#88305F" }}>
                                    Untitled.pdf{" "}
                                  </span>
                                  <img
                                    src="/static_images/new-tab-purple-big.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div>
                                <div>In Draft</div>
                                <div
                                  className="text-container mr-1"
                                  style={{ backgroundColor: "#FFE5E8" }}
                                >
                                  <p className="p-2">
                                    <span className="font-weight-bold">
                                      Purchase Orders.
                                    </span>{" "}
                                    Hello world will submit all orders for
                                    Products to Amazon Grace India in writing to
                                    Amazon Grace India's address listed in the
                                    introduction to this agreement, or as Amazon
                                    Grace...
                                    <span style={{ color: "#88305F" }}>
                                      Show more
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex" style={{ gap: "20px" }}>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() =>
                                    props.setModalVisible("Request Approval")
                                  }
                                >
                                  <img
                                    src="/static_images/tick-filter-icn.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Request Approval
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() =>
                                    props.setModalVisible("Replace with CL")
                                  }
                                >
                                  <img
                                    src="/static_images/texticon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Replace with CL
                                  </span>
                                </button>
                                <button
                                  style={{ cursor: "pointer" }}
                                  className="addtag-addHierarchy-addbtn"
                                  onClick={() =>
                                    props.setModalVisible("Not a Deviation")
                                  }
                                >
                                  <img
                                    src="/static_images/rulesbtnicon.svg"
                                    className="pl-1 w-10"
                                    style={{ cursor: "pointer" }}
                                  />
                                  <span className="addtag-addHierarchy-addbtn-title ml-2">
                                    Not a Deviation
                                  </span>
                                </button>
                              </div>

                              <div className="custom-box">
                                <div className=" row col-12 p-0">
                                  <div className="col-6 font-weight-bold">
                                    <img
                                      src="/static_images/stariconblack.svg"
                                      className="pl-1 w-10 mr-1"
                                      style={{ cursor: "pointer" }}
                                    />
                                    Riverus Suggestion
                                  </div>
                                  <div
                                    className="col-6 pr-0 d-flex justify-content-xl-end"
                                    style={{
                                      gap: "15px",
                                      fontSize: "14px",
                                      color: "#88305F",
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  >
                                    View Approval Matrix Policy
                                    <img
                                      src="/static_images/new-tab-purple-big.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="text-container mr-1"
                                  style={{ backgroundColor: "#FFE5E8" }}
                                >
                                  <p className="p-2">
                                    <span className="font-weight-bold">
                                      Purchase Orders.
                                    </span>{" "}
                                    Hello world will submit all orders for
                                    Products to Amazon Grace India in writing to
                                    Amazon Grace India's address listed in the
                                    introduction to this agreement, or as Amazon
                                    Grace...
                                    <span style={{ color: "#88305F" }}>
                                      Show more
                                    </span>
                                  </p>
                                </div>
                                <div className="d-flex" style={{ gap: "20px" }}>
                                  <button
                                    style={{ cursor: "pointer" }}
                                    className="addtag-addHierarchy-addbtn"
                                  >
                                    <img
                                      src="/static_images/staricongray.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <span className="addtag-addHierarchy-addbtn-title ml-2">
                                      Replace with Suggested Alternative
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Typography>
                        </CardContent>
                      </Card>
                    </div> */}
      </div>
      </Scrollable>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalTab);
