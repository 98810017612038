import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import Modal from "@material-ui/core/Modal";
import {
  Box,
  Checkbox,
  DialogContentText,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "300px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface Props {
  rejectionOpen: any;
  close: any;
  reqres: any;
  user: any;
  setopenSnackbar: any;
}

const RejectionPopup: React.FC<Props> = (props: Props) => {
  const [comment, setComment] = React.useState<string>("");
  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });
  

  const handlesetStatus = (val: any) => {
    const data: any = val?.approvalTypes[0];
    if (data.approval_sequence == "All") {
      const checkStatus = data?.approvers?.some(
        (item: any) => item.approval_status == "Rejected"
      );
      if (checkStatus) {
        axios
          .patch(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${props?.reqres?.id}/`,
            {
              status: "Rejected",
              // logs: {
              //   action: `${props.user.first_name} ${props.user.last_name} rejected the approval for requisition raised by ${props.reqres.owner.first_name} ${props.reqres.owner.last_name}`,
              // action_on: [props.user.id]
              // }
            },
            APIConfig()
          )
          .then((response) => {})
          .catch((err) => console.log(err));
      } else {
        return;
      }
    } else if (data.approval_sequence == "Any") {
      const checkStatus = data?.approvers?.every(
        (item: any) => item.approval_status == "Rejected"
      );
      if (checkStatus) {
      axios
        .patch(
          process.env.REACT_APP_RIVERUS_CLM_API +
            `stylus/requisition_form/${props?.reqres?.id}/`,
          {
            status: "Rejected",
            // logs: {
            //   action: `${props.user.first_name} ${props.user.last_name} rejected the approval for requisition raised by ${props.reqres.owner.first_name} ${props.reqres.owner.last_name}`,
            // action_on: [props.user.id]
            // },
          },
          APIConfig()
        )
        .then((response) => {})
        .catch((err) => console.log(err));
      }
    } else if (data.approval_sequence == "In Sequence"){
      const checkStatus = data?.approvers?.some(
        (item: any) => item.approval_status == "Rejected"
      );if (checkStatus) {
        axios
          .patch(
            process.env.REACT_APP_RIVERUS_CLM_API +
              `stylus/requisition_form/${props?.reqres?.id}/`,
            {
              status: "Rejected",
              // logs: {
              //   action: `${props.user.first_name} ${props.user.last_name} rejected the approval for requisition raised by ${props.reqres.owner.first_name} ${props.reqres.owner.last_name}`,
              // action_on: [props.user.id]
              // }
            },
            APIConfig()
          )
          .then((response) => {})
          .catch((err) => console.log(err));
      } else {
        return;
      }
    }else {
    }
  };

  const handlereject = () => {
    let newData: any = [...props?.reqres?.approvalTypes[0]?.approvers];
    const filter = newData?.findIndex(
      (item: any, index: any) => item?.id === props.user.id
    );
    newData[filter].approval_status = "Rejected";
    newData[filter].approved_date = new Date().toLocaleDateString();
    newData[filter]["comment"] = comment;
    axios
      .patch(
        process.env.REACT_APP_RIVERUS_CLM_API +
          `stylus/requisition_form/${props?.reqres?.id}/`,
        {
          approvalTypes: [
            {
              approval_sequence:
                props?.reqres?.approvalTypes[0]?.approval_sequence,
              approval_type_name:
                props?.reqres?.approvalTypes[0]?.approval_type_name,
              approvers: newData,
            },
          ],
          logs: {
            action: ` rejected the approval for requisition raised by ${props.reqres.owner.first_name} ${props.reqres.owner.last_name}`,
          action_on: [props.user.id]
          }
        },
        APIConfig()
      )
      .then((res: any) => {
        handlesetStatus(res.data);
            props.close();
            props.setopenSnackbar();
        // setrequisitionlog(response.data)
        axios
          .post(
            process.env.REACT_APP_RIVERUS_CLM_API + `sending_email/`,
            {
              user_ids: newData?.map((item: any) => item.id),
              body_html: `<h2>Hello ${res.data.owner.first_name},</h2><p>I have reviewed your request for requisition approval for a new contract requisition on Riverus Contracts Platform at <a href="${window.location.origin}/draftingreview/${res.data.id}">${window.location.origin}/draftingreview/${res.data.id}</a> . However, I am constrained to reject  the requisition.</p><p>Kindly note the my reasons for rejections below and take suitable actions.</p><p>${comment}</p><h4>Regards,</h4><br/><h4>${props.user.first_name}</h4>`,
              body_text: `${res.data.owner.first_name} raised requisition approval for ${props?.reqres?.contractName}. Kindly review and approve the same.`,
              subject: `Requisition rejection raised for ${props?.reqres?.contractName}`,
            },
            APIConfig()
          )
          .then((response) => {
            // handlesetStatus(res.data);
            // props.close();
            // props.setopenSnackbar();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Modal
        open={props.rejectionOpen}
        className="deletemodal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={hierarchyStyle} className="position-relative">
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              Confirm Rejection
              <span className="mr-0">
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                  onClick={props.close}
                />
              </span>
            </div>
          </Typography>
          <Typography
            style={{ margin: "20px", gap: "10px" }}
            className="d-flex flex-column"
          >
            <div
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#626262",
                width: "100%",
              }}
            >
              Are you sure you want to reject the creation of this contract? You
              cannot undo this action.
            </div>
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="col-12">
                  <div className="mt-1">
                    <FormControl style={{ width: "100%" }} className="m-2">
                      <DialogContentText className="riverus-field-title">
                        Optional comment
                      </DialogContentText>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="outlined-multiline-flexible"
                        multiline
                        onChange={(e: any) => setComment(e.target.value)}
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <div className="row col-12 pb-3">
              <div className="col-6 d-flex align-items-center justify-content-center">
                <button
                  style={{ cursor: "pointer", width: "198px", height: "24px" }}
                  className="btn-deviation"
                  onClick={props.close}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 d-flex align-items-center justify-content-center">
                <button
                  style={{ cursor: "pointer", width: "198px", height: "24px" }}
                  className="btn-rejection"
                  onClick={handlereject}
                >
                  Reject Draft
                </button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectionPopup);
