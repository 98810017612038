import {
  Button,
  CircularProgress,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import { History } from "history";
import "../Design/filter.scss";
import DateRangeFilter from "./DateRangeFilter";
import GenericFilter from "./GenericFilter";
import FilterList from "./FilterList";
import useFilters from "./useFilters";
import {
  defaultFilterPayload,
  FilterApplied,
  FilteredContract,
  FilterPayload,
  appliedFilter,
  appliedFilters,
  SavedFilters,
} from "../State";
import { isArray } from "is-what";
import { FilterAPI } from "../DA";
import Tooltip from "@material-ui/core/Tooltip";
import { getSelectedToolTip } from "./Utils/docUtils";
interface Props {
  history: History;
  pageWatcher: (page: string) => void;
}
const currencyTypes = [
  { currencyTypeId: 1, currencyName: "€ Euro", currencyType: "€" },
  { currencyTypeId: 2, currencyName: "₹ Indian Rupee", currencyType: "₹" },
  { currencyTypeId: 3, currencyName: "$ Dollar", currencyType: "$" },
];

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    color: "black",
  },
});
const FilterComponent = (props: Props) => {
  const [contractResults, setcontractResults] = useState<FilteredContract[]>(
    []
  );
  const classes = useStyles();
  const {
    locked_in_period,
    clauseTypes,
    currency,
    groups,
    nature,
    others,
    parties,
    count,
    payee,
    payers,
    extent_of_costs,
    triggering_events,
    confidentiality_nature,
    termination_events,
    force_majeure_events,
    jurisdictions,
    dispute_res_venue,
    dispute_res_act_statute,
    dispute_res_panel,
    territory,
    authority,
    change_of_control,
    termination_at_convenience,
    dispute_res_other,
    dispute_res_arbitration,
    dispute_res_negoation,
    dispute_res_concillation,
    dispute_res_mediation,
    consent_clausetype,
    subletting_consent,
    subletting_notice,
    events_of_default,
    limited_liability_amount,
    non_compete_duration,
    non_solicitation_duration,
    indemnity_cap,
    confidentiality_duration,
    cexcept,
  } = useFilters();
  const [filterPayload, setfilterPayload] =
    useState<FilterPayload>(defaultFilterPayload);

  const [filterApplied, setfilterApplied] =
    useState<FilterApplied>(appliedFilter);

  const [selectedValues, setselectedValues] = useState<string[]>([]);

  const [selected_filter, setselected_filter] = useState<appliedFilters[]>([]);

  const [applied_filter, setapplied_filter] = useState<appliedFilters[]>([]);

  const [loading, setloading] = useState(false);
  const [search_text, setsearch_text] = useState("");
  const [date_change, setdate_change] = useState({
    startDate: {
      min: "",
      max: "",
    },
    endDate: {
      min: "",
      max: "",
    },
  });

  const [savedfilters, setsavedfilters] = useState<SavedFilters[]>([]);
  const [payload, setPayload] = useState<FilterPayload>(defaultFilterPayload);
  const [filterlabel, setfilterlabel] = useState("Filter By");
  const [selective_filter, setselective_filter] = useState<any[]>([]);
  const [capped_amount, setcapped_amount] = useState<any[]>([]);
  const [cexpt_selected, setcexpt_selected] = useState<any[]>([]);
  const [confidential_duration_selected, setconfidential_duration_selected] =
    useState<any[]>([]);
  const [selected_checkbox, setselected_checkbox] = useState("");

  const [selected_checkboxItem, setselected_checkboxItem] = useState<any>({
    capped_amount: [],
    cexcept: [],
    confidentiality_duration: [],
    non_compete_duration: [],
    non_solicitation_duration: [],
  });

  const [non_solicitation_duration_check, setnon_solicitation_duration_check] =
    useState<any[]>([]);
  const [non_compete_duration_check, setnon_compete_duration_check] = useState<
    any[]
  >([]);
  const [confidentiality_duration_check, setconfidentiality_duration_check] =
    useState<any[]>([]);
  const [cexcept_check, setcexcept_check] = useState<any[]>([]);
  const [capped_check, setcapped_check] = useState<any[]>([]);

  useEffect(() => {
    props.pageWatcher("filter");
    getSavedFilterList();
  }, []);

  useEffect(() => {}, [
    capped_amount,
    cexpt_selected,
    confidential_duration_selected,
    selected_checkbox,
  ]);

  useEffect(() => {}, [
    selected_checkboxItem,
    non_compete_duration_check,
    non_compete_duration_check,
    confidentiality_duration_check,
    cexcept_check,
    capped_check,
  ]);

  const getSavedFilterList = async () => {
    try {
      const savedfilters = await FilterAPI.getSavedFilters();
      setsavedfilters([...savedfilters]);
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const handleClausePresent = (change: any[]) => {
    // setselectedValues({ ...selectedValues, clause_present: change });
    setfilterPayload({ ...filterPayload, clause_present: change });
  };

  const handleClauseAbsent = (change: string[]) => {
    setfilterPayload({ ...filterPayload, clause_absent: change });
  };
  const handleGroup = (change: string[]) => {
    setfilterPayload({ ...filterPayload, groups: change });
  };
  const handleNature = (change: string[]) => {
    setfilterPayload({ ...filterPayload, nature: change });
  };
  const handleOthers = (change: string[]) => {
    setfilterPayload({ ...filterPayload, others: change });
  };
  const handleParties = (change: string[]) => {
    setfilterPayload({ ...filterPayload, parties: change });
  };

  const handleStartDateChange = (change: any) => {
    setdate_change({
      ...date_change,
      startDate: {
        min: change.min,
        max: change.max,
      },
    });
    setfilterPayload({ ...filterPayload, start_date: change });
  };

  const handleEndDateChange = (change: any) => {
    setdate_change({
      ...date_change,
      endDate: {
        min: change.min,
        max: change.max,
      },
    });
    setfilterPayload({ ...filterPayload, end_date: change });
  };

  const applyFilterSet = async (filter_payload: any) => {
    setfilterlabel("Filter Applied");
    try {
      setloading(true);
      let filterTemp = filter_payload; //JSON.parse(JSON.stringify(filterPayload));
      let selective_filter: any[] = [];

      const filters = Object.keys(filterTemp).reduce<any>((acc, curr) => {
        if (isArray(filterTemp[curr]) && filterTemp[curr].length > 0) {
          acc = { ...acc, [curr]: filterTemp[curr] };
        }
        if (curr == "payment" && filterTemp[curr].currency_type !== "") {
          acc = { ...acc, [curr]: filterTemp[curr] };

          selective_filter.push({
            min: filterTemp[curr].min,
            max: filterTemp[curr].max,
            currency_type: filterTemp[curr].currency_type,
            filterType: "payment",
          });
        }
        if (
          curr == "start_date" &&
          filterTemp[curr].min !== "" &&
          filterTemp[curr].max !== ""
        ) {
          acc = { ...acc, [curr]: filterTemp[curr] };
          selective_filter.push({
            min: filterTemp[curr].min,
            max: filterTemp[curr].max,
            filterType: "start_date",
          });
        }
        if (
          curr == "end_date" &&
          filterTemp[curr].min !== "" &&
          filterTemp[curr].max !== ""
        ) {
          acc = { ...acc, [curr]: filterTemp[curr] };
          selective_filter.push({
            min: filterTemp[curr].min,
            max: filterTemp[curr].max,
            filterType: "end_date",
          });
        }
        if (
          curr == "cexcept" ||
          curr == "confidentiality_duration" ||
          curr == "non_solicitation_duration" ||
          curr == "non_compete_duration" ||
          curr == "limited_liability_amount"
        ) {
          acc = { ...acc, [curr]: filterTemp[curr] };
        }
        return acc;
      }, {});

      if (
        confidentiality_duration_check.includes("Yes") &&
        !filters.hasOwnProperty("confidentiality_duration")
      ) {
        filters["confidentiality_duration"] = {
          yes: [],
        };
      }
      if (
        confidentiality_duration_check.includes("No") &&
        !filters.hasOwnProperty("confidentiality_duration")
      ) {
        filters["confidentiality_duration"] = {
          no: [],
        };
      }

      if (
        non_compete_duration_check.includes("Yes") &&
        !filters.hasOwnProperty("non_compete_duration")
      ) {
        filters["non_compete_duration"] = {
          yes: [],
        };
      }
      if (
        non_compete_duration_check.includes("No") &&
        !filters.hasOwnProperty("non_compete_duration")
      ) {
        filters["non_compete_duration"] = {
          no: [],
        };
      }

      if (
        non_solicitation_duration_check.includes("Yes") &&
        !filters.hasOwnProperty("non_solicitation_duration")
      ) {
        filters["non_solicitation_duration"] = {
          yes: [],
        };
      }
      if (
        non_solicitation_duration_check.includes("No") &&
        !filters.hasOwnProperty("non_solicitation_duration")
      ) {
        filters["non_solicitation_duration"] = {
          no: [],
        };
      }

      if (
        capped_check.includes("Capped") &&
        !filters.hasOwnProperty("limited_liability_amount")
      ) {
        filters["limited_liability_amount"] = {
          Capped: [],
        };
      }
      if (
        capped_check.includes("Uncapped") &&
        !filters.hasOwnProperty("limited_liability_amount")
      ) {
        filters["limited_liability_amount"] = {
          Uncapped: [],
        };
      }

      if (cexcept_check.includes("Yes") && !filters.hasOwnProperty("cexcept")) {
        filters["cexcept"] = {
          yes: [],
        };
      }

      setselective_filter(selective_filter);
      setPayload(filters);
      const results = await FilterAPI.getContracts(filters);
      setcontractResults([...results]);
    } catch (error) {
      setloading(false);
    } finally {
      setloading(false);
    }
  };

  const savedapplyFilter = async (savedFilters: any) => {
    setnon_compete_duration_check([]);
    setnon_solicitation_duration_check([]);
    setconfidentiality_duration_check([]);
    setcexcept_check([]);

    if (savedFilters.hasOwnProperty("non_solicitation_duration")) {
      if (savedFilters["non_solicitation_duration"].hasOwnProperty("yes")) {
        setnon_solicitation_duration_check(["Yes"]);
      }

      if (savedFilters["non_solicitation_duration"].hasOwnProperty("no")) {
        setnon_solicitation_duration_check(["No"]);
      }
    }

    if (savedFilters.hasOwnProperty("non_compete_duration")) {
      if (savedFilters["non_compete_duration"].hasOwnProperty("yes")) {
        setselected_checkboxItem({
          ...selected_checkboxItem,
          non_compete_duration: ["Yes"],
        });
        setnon_compete_duration_check(["Yes"]);
      } else {
        if (savedFilters["non_compete_duration"].hasOwnProperty("no")) {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            non_compete_duration: ["No"],
          });
          setnon_compete_duration_check(["No"]);
        }
      }
    }

    if (savedFilters.hasOwnProperty("confidentiality_duration")) {
      if (savedFilters["confidentiality_duration"].hasOwnProperty("yes")) {
        setconfidentiality_duration_check(["Yes"]);
      } else {
        if (savedFilters["confidentiality_duration"].hasOwnProperty("no")) {
          setconfidentiality_duration_check(["No"]);
        }
      }
    }

    if (savedFilters.hasOwnProperty("cexcept")) {
      if (savedFilters["cexcept"].hasOwnProperty("yes")) {
        setcexcept_check(["Yes"]);
      } else {
        if (savedFilters["cexcept"].hasOwnProperty("no")) {
          setcexcept_check(["No"]);
        }
      }
    }

    if (savedFilters.hasOwnProperty("limited_liability_amount")) {
      if (savedFilters["limited_liability_amount"].hasOwnProperty("Capped")) {
        setcapped_check(["Capped"]);
      } else {
        if (
          savedFilters["limited_liability_amount"].hasOwnProperty("Uncapped")
        ) {
          setcapped_check(["Uncapped"]);
        }
      }
    }

    let filterSet: appliedFilters[] = savedFilters.clauses;
    let filter_payload: any = [];
    Object.entries(filterSet).map(([key, value]) => {
      if (filter_payload.hasOwnProperty(value.filterType)) {
        if (
          value.filterType == "cexcept" ||
          value.filterType == "confidentiality_duration" ||
          value.filterType == "non_compete_duration" ||
          value.filterType == "non_solicitation_duration" ||
          value.filterType == "limited_liability_amount"
        ) {
          filter_payload[value.filterType].push({
            name: value.name,
            type: value.type,
          });
        } else {
          filter_payload[value.filterType].push(value.name);
        }
      } else {
        if (
          value.filterType == "cexcept" ||
          value.filterType == "confidentiality_duration" ||
          value.filterType == "non_compete_duration" ||
          value.filterType == "non_solicitation_duration" ||
          value.filterType == "limited_liability_amount"
        ) {
          filter_payload[value.filterType] = [
            {
              name: value.name,
              type: value.type,
            },
          ];
        } else {
          filter_payload[value.filterType] = [value.name];
        }
      }
    });
    filter_payload["payment"] = savedFilters.payment;
    filter_payload["start_date"] = savedFilters.start_date;
    filter_payload["end_date"] = savedFilters.end_date;

    setapplied_filter(filterSet);
    // setapplied_all_filter()

    if (filter_payload.hasOwnProperty("cexcept")) {
      if (cexcept_check.includes("Yes")) {
        filter_payload["cexcept"]["yes"] = [];
      }
    }

    if (filter_payload.hasOwnProperty("confidentiality_duration")) {
      let confidentilaity_duration_present_data: any[] = filter_payload[
        "confidentiality_duration"
      ].filter((option: any) => option.type === "yes");
      let confidentilaity_duration_absent_data: any[] = filter_payload[
        "confidentiality_duration"
      ].filter((option: any) => option.type === "no");
      if (
        confidentilaity_duration_present_data.length > 0 &&
        confidentilaity_duration_absent_data.length > 0
      ) {
        filter_payload["confidentiality_duration"] = {
          yes: confidentilaity_duration_present_data,
          no: confidentilaity_duration_absent_data,
        };
      } else if (
        confidentilaity_duration_present_data.length > 0 &&
        confidentilaity_duration_absent_data.length == 0
      ) {
        filter_payload["confidentiality_duration"] = {
          yes: confidentilaity_duration_present_data,
        };
      } else if (
        confidentilaity_duration_present_data.length == 0 &&
        confidentilaity_duration_absent_data.length > 0
      ) {
        filter_payload["confidentiality_duration"] = {
          no: confidentilaity_duration_absent_data,
        };
      }
    }
    if (filter_payload.hasOwnProperty("non_compete_duration")) {
      let non_compete_duration_present_data: any[] = filter_payload[
        "non_compete_duration"
      ].filter((option: any) => option.type === "yes");
      let non_compete_duration_absent_data: any[] = filter_payload[
        "non_compete_duration"
      ].filter((option: any) => option.type === "no");
      if (
        non_compete_duration_present_data.length > 0 &&
        non_compete_duration_absent_data.length > 0
      ) {
        filter_payload["non_compete_duration"] = {
          yes: non_compete_duration_present_data,
          no: non_compete_duration_absent_data,
        };
      } else if (
        non_compete_duration_present_data.length > 0 &&
        non_compete_duration_absent_data.length == 0
      ) {
        filter_payload["non_compete_duration"] = {
          yes: non_compete_duration_present_data,
        };
      } else if (
        non_compete_duration_present_data.length == 0 &&
        non_compete_duration_absent_data.length > 0
      ) {
        filter_payload["non_compete_duration"] = {
          no: non_compete_duration_absent_data,
        };
      }
    }
    if (filter_payload.hasOwnProperty("non_solicitation_duration")) {
      let non_solicitation_duration_present_data: any[] = filter_payload[
        "non_solicitation_duration"
      ].filter((option: any) => option.type === "yes");
      let non_solicitation_duration_absent_data: any[] = filter_payload[
        "non_solicitation_duration"
      ].filter((option: any) => option.type === "no");
      if (
        non_solicitation_duration_present_data.length > 0 &&
        non_solicitation_duration_absent_data.length > 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          yes: non_solicitation_duration_present_data,
          no: non_solicitation_duration_absent_data,
        };
      } else if (
        non_solicitation_duration_present_data.length > 0 &&
        non_solicitation_duration_absent_data.length == 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          yes: non_solicitation_duration_present_data,
        };
      } else if (
        non_solicitation_duration_present_data.length == 0 &&
        non_solicitation_duration_absent_data.length > 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          no: non_solicitation_duration_absent_data,
        };
      }
    }

    if (filter_payload.hasOwnProperty("limited_liability_amount")) {
      let limited_liability_amount_present_data: any[] = filter_payload[
        "limited_liability_amount"
      ].filter((option: any) => option.type === "Capped");
      let limited_liability_amount_absent_data: any[] = filter_payload[
        "limited_liability_amount"
      ].filter((option: any) => option.type === "Uncapped");
      if (
        limited_liability_amount_present_data.length > 0 &&
        limited_liability_amount_absent_data.length > 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Capped: limited_liability_amount_present_data,
          Uncapped: limited_liability_amount_absent_data,
        };
      } else if (
        limited_liability_amount_present_data.length > 0 &&
        limited_liability_amount_absent_data.length == 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Capped: limited_liability_amount_present_data,
        };
      } else if (
        limited_liability_amount_present_data.length == 0 &&
        limited_liability_amount_absent_data.length > 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Uncapped: limited_liability_amount_absent_data,
        };
      }
    }

    if (filter_payload.hasOwnProperty("cexcept")) {
      let cexcept_present_data: any[] = filter_payload["cexcept"].filter(
        (option: any) => option.type === "Yes"
      );

      if (cexcept_present_data.length > 0) {
        filter_payload["cexcept"] = {
          yes: cexcept_present_data,
        };
      }
    }

    applyFilterSet(filter_payload);
  };

  const applyFilter = async (filterSet: appliedFilters[]) => {
    let filter_payload: any = [];
    Object.entries(filterSet).map(([key, value]) => {
      if (filter_payload.hasOwnProperty(value.filterType)) {
        if (
          value.filterType == "cexcept" ||
          value.filterType == "confidentiality_duration" ||
          value.filterType == "non_compete_duration" ||
          value.filterType == "non_solicitation_duration" ||
          value.filterType == "limited_liability_amount"
        ) {
          filter_payload[value.filterType].push({
            name: value.name,
            type: value.type,
          });
        } else {
          filter_payload[value.filterType].push(value.name);
        }
      } else {
        if (
          value.filterType == "cexcept" ||
          value.filterType == "confidentiality_duration" ||
          value.filterType == "non_compete_duration" ||
          value.filterType == "non_solicitation_duration" ||
          value.filterType == "limited_liability_amount"
        ) {
          filter_payload[value.filterType] = [
            {
              name: value.name,
              type: value.type,
            },
          ];
        } else {
          filter_payload[value.filterType] = [value.name];
        }
      }
    });

    if (filter_payload.hasOwnProperty("cexcept")) {
      let c_except_no_data: any[] = filter_payload["cexcept"].filter(
        (option: any) => option.type === "No"
      );
      let c_except_yes_data: any[] = filter_payload["cexcept"].filter(
        (option: any) => option.type === "Yes"
      );

      if (cexcept_check.includes("Yes") && c_except_yes_data.length > 0) {
        filter_payload["cexcept"] = {
          yes: c_except_yes_data,
        };
      } else {
        filter_payload["cexcept"] = {
          yes: [],
        };
      }
    } else {
    }

    if (filter_payload.hasOwnProperty("confidentiality_duration")) {
      let confidentilaity_duration_present_data: any[] = filter_payload[
        "confidentiality_duration"
      ].filter((option: any) => option.type === "yes");
      let confidentilaity_duration_absent_data: any[] = filter_payload[
        "confidentiality_duration"
      ].filter((option: any) => option.type === "no");
      if (
        confidentilaity_duration_present_data.length > 0 &&
        confidentilaity_duration_absent_data.length > 0
      ) {
        filter_payload["confidentiality_duration"] = {
          yes: confidentilaity_duration_present_data,
          no: confidentilaity_duration_absent_data,
        };
      } else if (
        confidentilaity_duration_present_data.length > 0 &&
        confidentilaity_duration_absent_data.length == 0
      ) {
        filter_payload["confidentiality_duration"] = {
          yes: confidentilaity_duration_present_data,
        };
      } else if (
        confidentilaity_duration_present_data.length == 0 &&
        confidentilaity_duration_absent_data.length > 0
      ) {
        filter_payload["confidentiality_duration"] = {
          no: confidentilaity_duration_absent_data,
        };
      }
    }
    if (filter_payload.hasOwnProperty("non_compete_duration")) {
      let non_compete_duration_present_data: any[] = filter_payload[
        "non_compete_duration"
      ].filter((option: any) => option.type === "yes");
      let non_compete_duration_absent_data: any[] = filter_payload[
        "non_compete_duration"
      ].filter((option: any) => option.type === "no");
      if (
        non_compete_duration_present_data.length > 0 &&
        non_compete_duration_absent_data.length > 0
      ) {
        filter_payload["non_compete_duration"] = {
          yes: non_compete_duration_present_data,
          no: non_compete_duration_absent_data,
        };
      } else if (
        non_compete_duration_present_data.length > 0 &&
        non_compete_duration_absent_data.length == 0
      ) {
        filter_payload["non_compete_duration"] = {
          yes: non_compete_duration_present_data,
        };
      } else if (
        non_compete_duration_present_data.length == 0 &&
        non_compete_duration_absent_data.length > 0
      ) {
        filter_payload["non_compete_duration"] = {
          no: non_compete_duration_absent_data,
        };
      }
    }
    if (filter_payload.hasOwnProperty("non_solicitation_duration")) {
      let non_solicitation_duration_present_data: any[] = filter_payload[
        "non_solicitation_duration"
      ].filter((option: any) => option.type === "yes");
      let non_solicitation_duration_absent_data: any[] = filter_payload[
        "non_solicitation_duration"
      ].filter((option: any) => option.type === "no");
      if (
        non_solicitation_duration_present_data.length > 0 &&
        non_solicitation_duration_absent_data.length > 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          yes: non_solicitation_duration_present_data,
          no: non_solicitation_duration_absent_data,
        };
      } else if (
        non_solicitation_duration_present_data.length > 0 &&
        non_solicitation_duration_absent_data.length == 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          yes: non_solicitation_duration_present_data,
        };
      } else if (
        non_solicitation_duration_present_data.length == 0 &&
        non_solicitation_duration_absent_data.length > 0
      ) {
        filter_payload["non_solicitation_duration"] = {
          no: non_solicitation_duration_absent_data,
        };
      }
    }

    if (filter_payload.hasOwnProperty("limited_liability_amount")) {
      let limited_liability_amount_present_data: any[] = filter_payload[
        "limited_liability_amount"
      ].filter((option: any) => option.type === "Capped");
      let limited_liability_amount_absent_data: any[] = filter_payload[
        "limited_liability_amount"
      ].filter((option: any) => option.type === "Uncapped");
      if (
        limited_liability_amount_present_data.length > 0 &&
        limited_liability_amount_absent_data.length > 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Capped: limited_liability_amount_present_data,
          Uncapped: limited_liability_amount_absent_data,
        };
      } else if (
        limited_liability_amount_present_data.length > 0 &&
        limited_liability_amount_absent_data.length == 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Capped: limited_liability_amount_present_data,
        };
      } else if (
        limited_liability_amount_present_data.length == 0 &&
        limited_liability_amount_absent_data.length > 0
      ) {
        filter_payload["limited_liability_amount"] = {
          Uncapped: limited_liability_amount_absent_data,
        };
      }
    }

    filter_payload["payment"] = filterPayload.payment;
    filter_payload["start_date"] = date_change.startDate; //filterPayload.start_date;
    filter_payload["end_date"] = date_change.endDate; //filterPayload.end_date;

    setapplied_filter(filterSet);
    applyFilterSet(filter_payload);
  };

  const applySelctiveFilter = async (
    filterSet: appliedFilters[],
    filterType: string
  ) => {
    let filter_payload: any = [];

    Object.entries(filterSet).map(([key, value]) => {
      if (filter_payload.hasOwnProperty(value.filterType)) {
        filter_payload[value.filterType].push(value.name);
      } else {
        filter_payload[value.filterType] = [value.name];
      }
    });
    if (filterType == "payment") {
      resetPayment();
      filter_payload["payment"] = defaultFilterPayload.payment;
      filter_payload["start_date"] = date_change.startDate;
      filter_payload["end_date"] = date_change.endDate;
    } else if (filterType == "start_date") {
      resetStartDateFilter();
      filter_payload["payment"] = filterPayload.payment;
      filter_payload["start_date"] = defaultFilterPayload.start_date;
      filter_payload["end_date"] = date_change.endDate;
    } else if (filterType == "end_date") {
      resetEndDateFilter();
      filter_payload["payment"] = filterPayload.payment;
      filter_payload["start_date"] = date_change.startDate;
      filter_payload["end_date"] = defaultFilterPayload.end_date;
    } else {
      filter_payload["payment"] = filterPayload.payment;
      filter_payload["start_date"] = date_change.startDate;
      filter_payload["end_date"] = date_change.endDate;
    }
    setapplied_filter(filterSet);
    applyFilterSet(filter_payload);
  };

  const handleCurrencyType = (event: any) => {
    setfilterPayload({
      ...filterPayload,
      payment: { ...filterPayload.payment, currency_type: event.target.value },
    });
  };

  const handleMinPaymentValue = (event: any) => {
    setfilterPayload({
      ...filterPayload,
      payment: { ...filterPayload.payment, min: Number(event.target.value) },
    });
  };

  const handleMaxPaymentValue = (event: any) => {
    setfilterPayload({
      ...filterPayload,
      payment: { ...filterPayload.payment, max: Number(event.target.value) },
    });
  };

  const resetPayment = () => {
    setfilterPayload({
      ...filterPayload,
      payment: { ...defaultFilterPayload.payment },
    });
  };

  const resetStartDateFilter = () => {
    setdate_change({
      ...date_change,
      startDate: {
        min: "",
        max: "",
      },
    });
    setfilterPayload({
      ...filterPayload,
      start_date: defaultFilterPayload.start_date,
    });
  };

  const resetEndDateFilter = () => {
    setdate_change({
      ...date_change,
      endDate: {
        min: "",
        max: "",
      },
    });
    setfilterPayload({
      ...filterPayload,
      start_date: defaultFilterPayload.end_date,
    });
  };

  const clearAll = () => {
    resetPayment();
    resetStartDateFilter();
    resetEndDateFilter();
    setdate_change({
      startDate: {
        min: "",
        max: "",
      },
      endDate: {
        min: "",
        max: "",
      },
    });
    date_change.startDate = {
      min: "",
      max: "",
    };
    setselected_checkboxItem({
      ...selected_checkboxItem,
      capped_amount: [],
      cexcept: [],
      confidentiality_duration: [],
      non_compete_duration: [],
      non_solicitation_duration: [],
    });
    setfilterPayload({ ...defaultFilterPayload });
    setselected_filter([]);
    setnon_solicitation_duration_check([]);
    setnon_compete_duration_check([]);
    setconfidentiality_duration_check([]);
    setcexcept_check([]);
  };

  const filterSearch = (search_text: any) => {
    setsearch_text(search_text);
  };

  const recheck = (clause: any, selected: boolean) => {
    if (selected_filter.length != 0) {
      if (selected) {
        setselected_filter([...selected_filter, clause]);
      } else {
        let index = selected_filter.findIndex(
          (obj) => obj.id === clause.id && obj.filterType === clause.filterType
        );
        if (index !== -1) {
          let array = [...selected_filter];
          array.splice(index, 1);
          setselected_filter(array);
        }
      }
    } else {
      setselected_filter([...selected_filter, clause]);
      setfilterPayload({ ...filterPayload, clause_present: [clause.id] });
    }
  };

  const recheck_yesno = (clause: any, selected: boolean) => {
    let array = selected_filter.filter(
      (option) => option.filterType !== clause.filterType
    );
    array.push(clause);
    setselected_filter(array);
  };

  const selectedFilter = (type: string) => {
    let filter_data = selected_filter.filter((option) =>
      option.filterType.toLowerCase().includes(type)
    );
    let filteredIds: string[] = [];
    let filteredName: string[] = [];
    for (var i = 0; i < filter_data.length; i++) {
      filteredIds.push(filter_data[i].id);
      filteredName.push(filter_data[i].name);
    }
    return filteredName;
  };

  const selectedFilterIds = (type: string) => {
    let filter_data = selected_filter.filter((option) =>
      option.filterType.toLowerCase().includes(type)
    );
    let filteredIds: string[] = [];
    let filteredName: string[] = [];
    for (var i = 0; i < filter_data.length; i++) {
      filteredIds.push(filter_data[i].id);
      filteredName.push(filter_data[i].name);
    }
    return filteredIds;
  };

  const handleChange = (event: any, type: string, event_type: string) => {
    setselected_checkbox(event_type);

    if (event_type == "limited_liability_amount") {
      if (event.target.checked) {
        setcapped_amount([...capped_amount, type]);
        setcapped_check([type]);
      } else {
        const newList = capped_amount.filter((item) => item !== type);
        setcapped_amount(newList);
        if (capped_check.includes(type)) {
          setcapped_check([]);
        } else {
          setcapped_check([type]);
        }
      }
    }
    if (event_type == "cexcept") {
      if (event.target.checked) {
        setselected_checkboxItem({ ...selected_checkboxItem, cexcept: [type] });
        setcexcept_check([type]);
        // setcexpt_selected([...cexpt_selected, type])
      } else {
        // const newList = cexpt_selected.filter((item) => item !== type);
        setselected_checkboxItem({ ...selected_checkboxItem, cexcept: [] });
        setcexcept_check([]);
        // setcexpt_selected(newList);
      }
    }
    if (event_type == "confidentiality_duration") {
      if (event.target.checked) {
        setselected_checkboxItem({
          ...selected_checkboxItem,
          confidentiality_duration: [type],
        });
        setconfidentiality_duration_check([type]);
        // setconfidential_duration_selected([...confidential_duration_selected, type])
      } else {
        if (selected_checkboxItem["confidentiality_duration"].includes(type)) {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            confidentiality_duration: [],
          });
          setconfidentiality_duration_check([]);
        } else {
          // const newList = confidential_duration_selected.filter((item) => item !== type);
          setselected_checkboxItem({
            ...selected_checkboxItem,
            confidentiality_duration: [type],
          });
          setconfidentiality_duration_check([type]);
          // setconfidential_duration_selected(newList);
        }
      }
    }
    if (event_type == "non_compete_duration") {
      if (event.target.checked) {
        setselected_checkboxItem({
          ...selected_checkboxItem,
          non_compete_duration: [type],
        });
        setnon_compete_duration_check([type]);
      } else {
        if (selected_checkboxItem["non_compete_duration"].includes(type)) {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            non_compete_duration: [],
          });
          setnon_compete_duration_check([]);
        } else {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            non_compete_duration: [type],
          });
          setnon_compete_duration_check([type]);
        }
      }
    }
    if (event_type == "non_solicitation_duration") {
      if (event.target.checked) {
        setselected_checkboxItem({
          ...selected_checkboxItem,
          non_solicitation_duration: [type],
        });
        setnon_solicitation_duration_check([type]);
      } else {
        if (selected_checkboxItem["non_solicitation_duration"].includes(type)) {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            non_solicitation_duration: [],
          });
          setnon_solicitation_duration_check([]);
        } else {
          setselected_checkboxItem({
            ...selected_checkboxItem,
            non_solicitation_duration: [type],
          });
          setnon_solicitation_duration_check([type]);
        }
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-1" style={{ zIndex: 2 }}>
        <SideNavbar history={props.history} />
      </div>
      <div className="col-md-11 mt-6" style={{ height: "calc(100vh - 66px)" }}>
        {loading && <CircularProgress className="loader" />}
        <div className="row">
          <div
            className="col-md-3"
            style={{ flex: "0 0 28%", maxWidth: "28%" }}
          >
            <div className="filter-container">
              <div className="row mb-3">
                <div className="col filter-heading f-14">
                  <div className={classes.root}>
                    {applied_filter.filter(
                      (option) =>
                        option.filterType !== "start_date" &&
                        option.filterType !== "payment" &&
                        option.filterType !== "end_date"
                    ).length != 0 ? (
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: "pre-line" }}>
                            {getSelectedToolTip(
                              applied_filter,
                              selective_filter,
                              currency
                            )}
                          </span>
                        }
                        placement="right-start"
                      >
                        <span>{filterlabel}</span>
                      </Tooltip>
                    ) : (
                      <span>{filterlabel}</span>
                    )}
                  </div>
                </div>
                {/* <div className="col filter-heading f-14 cp">Apply</div> */}
                <Button
                  // onClick={applyFilter}
                  onClick={() => {
                    applyFilter(selected_filter);
                  }}
                  className="col"
                  style={{ padding: "0", lineHeight: "1" }}
                >
                  <span
                    className="filter-heading f-14"
                    style={{ textTransform: "capitalize" }}
                  >
                    Apply
                  </span>
                </Button>
                <div
                  className="col filter-heading f-14 text-align-right cp"
                  onClick={clearAll}
                >
                  Clear All
                </div>
              </div>

              <GenericFilter
                isMultiple={true}
                filterName="Clauses Present"
                onChange={handleClausePresent}
                reset={() => {
                  setfilterPayload({ ...filterPayload, clause_absent: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "clause_present"
                    )
                  );
                }}
                values={clauseTypes
                  .map((o) => ({
                    ...o,
                    filterType: "clause_present",
                  }))
                  .filter((option) =>
                    option.name.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("clause_present")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Clauses Absent"
                onChange={handleClauseAbsent}
                reset={() => {
                  setfilterPayload({ ...filterPayload, clause_absent: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "clause_absent"
                    )
                  );
                }}
                values={clauseTypes
                  .map((o) => ({
                    ...o,
                    filterType: "clause_absent",
                  }))
                  .filter((option) =>
                    option.name.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("clause_absent")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <Divider className="mb-4" />
              <div className="row mb-2">
                <div className="col-md-7 f-14 filter-container-filter-header">
                  Payment Obligation
                </div>
                <div
                  className="col filter-reset text-align-right cp"
                  onClick={resetPayment}
                >
                  Reset
                </div>
              </div>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                className="mb-2"
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Select Dropdown"
                  onChange={handleCurrencyType}
                  value={filterPayload.payment.currency_type}
                >
                  {/* {currencyTypes.map((currency, index) => ( */}
                  {currency.map((currency:any, index) => (
                    <MenuItem key={index} value={currency.currencyType}>
                     {currency.currencyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="row m-0 justify-content-between mb-3">
                <input
                  onChange={handleMinPaymentValue}
                  value={filterPayload.payment.min}
                  placeholder="Min"
                  type="number"
                  className="form-control col-md-6"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  style={{ maxWidth: "49%" }}
                />
                <div></div>
                <input
                  onChange={handleMaxPaymentValue}
                  value={filterPayload.payment.max}
                  placeholder="Max"
                  type="number"
                  className="form-control col-md-6"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  style={{ maxWidth: "49%" }}
                />
              </div>
              <p className="f-16 filter-container-filter-header">Term Clause</p>

              <DateRangeFilter
                filterName="Start Date"
                onChange={handleStartDateChange}
                reset={() => {
                  resetStartDateFilter();
                }}
                filterType="start_date"
                dateChange={date_change.startDate}
              />
              <DateRangeFilter
                filterName="End Date"
                onChange={handleEndDateChange}
                reset={() => {
                  resetEndDateFilter();
                }}
                filterType="end_date"
                dateChange={date_change.endDate}
              />
              <p className="f-16 filter-container-filter-header">
                Basic Information
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Group"
                onChange={handleGroup}
                reset={() => {
                  setfilterPayload({ ...filterPayload, groups: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "groups"
                    )
                  );
                }}
                values={groups
                  .map((o) => ({
                    ...o,
                    filterType: "groups",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("groups")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Nature"
                onChange={handleNature}
                reset={() => {
                  setfilterPayload({ ...filterPayload, nature: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "nature"
                    )
                  );
                }}
                values={nature
                  .map((o) => ({
                    ...o,
                    filterType: "nature",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("nature")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Others"
                onChange={handleOthers}
                reset={() => {
                  setfilterPayload({ ...filterPayload, others: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "others"
                    )
                  );
                }}
                values={others
                  .map((o) => ({
                    ...o,
                    filterType: "others",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("others")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />

              <GenericFilter
                isMultiple={true}
                filterName="Party Name"
                onChange={handleParties}
                reset={() => {
                  setfilterPayload({ ...filterPayload, parties: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "parties"
                    )
                  );
                }}
                values={parties
                  .map((o) => ({
                    ...o,
                    filterType: "parties",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("parties")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />

              <p className="f-16 filter-container-filter-header">Indemnity</p>
              <GenericFilter
                isMultiple={true}
                filterName="Payee"
                onChange={() => {}}
                reset={() => {
                  setfilterPayload({ ...filterPayload, payee: [] });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "payee"
                    )
                  );
                }}
                values={payee
                  .map((o) => ({
                    ...o,
                    filterType: "payee",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("payee")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Payer"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "payer"
                    )
                  );
                }}
                values={payers
                  .map((o) => ({
                    ...o,
                    filterType: "payer",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("payer")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Extent of Costs"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "extent_of_costs"
                    )
                  );
                }}
                values={extent_of_costs
                  .map((o) => ({
                    ...o,
                    filterType: "extent_of_costs",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("extent_of_costs")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Triggering Events"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "triggering_events"
                    )
                  );
                }}
                values={triggering_events
                  .map((o) => ({
                    ...o,
                    filterType: "triggering_events",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("triggering_events")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Amount"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "indemnity_cap"
                    )
                  );
                }}
                values={indemnity_cap
                  .map((o) => ({
                    ...o,
                    filterType: "indemnity_cap",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("indemnity_cap")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Confidentiality
              </p>

              <GenericFilter
                isMultiple={true}
                filterName="Nature"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "confidentiality_nature"
                    )
                  );
                }}
                values={confidentiality_nature
                  .map((o) => ({
                    ...o,
                    filterType: "confidentiality_nature",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("confidentiality_nature")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />

              <GenericFilter
                isMultiple={true}
                filterName="Post Contract Duration"
                onChange={() => {}}
                reset={() => {
                  setselected_checkboxItem({
                    ...selected_checkboxItem,
                    confidentiality_duration: [],
                  });
                  setconfidentiality_duration_check([]);
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "confidentiality_duration"
                    )
                  );
                }}
                values={confidentiality_duration
                  .filter((option: any) => {
                    if (
                      option.type === "yes" &&
                      confidentiality_duration_check.includes("Yes")
                    ) {
                      return option;
                    }
                    if (
                      option.type === "no" &&
                      confidentiality_duration_check.includes("No")
                    ) {
                      return option;
                    }
                    if (confidentiality_duration_check.length == 0) {
                      return option;
                    }
                  })
                  .map((o) => ({
                    ...o,
                    filterType: "confidentiality_duration",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("confidentiality_duration")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  {
                    recheck(filterData, selected);
                  }
                }}
                selected_filter={selected_filter}
                checkbox_present={true}
                checkbox_selected={confidentiality_duration_check}
                handleChange={handleChange}
                selected_chekbox_type={"confidentiality_duration"}
                selected_checkbox={selected_checkbox}
              />

              <GenericFilter
                isMultiple={true}
                filterName="Exception to Confidentiality"
                onChange={() => {}}
                reset={() => {
                  setcexcept_check([]);
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "cexcept"
                    )
                  );
                }}
                values={cexcept
                  .filter((cexcept_data: any) => {
                    if (
                      cexpt_selected.length == 1 &&
                      cexpt_selected.includes("No")
                    ) {
                    } else {
                      return cexcept_data;
                    }
                  })
                  .map((o) => ({
                    ...o,
                    filterType: "cexcept",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("cexcept")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={true}
                checkbox_selected={cexcept_check}
                handleChange={handleChange}
                selected_chekbox_type={"cexcept"}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Change of Control
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Intersection"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "change_of_control"
                    )
                  );
                }}
                values={change_of_control
                  .map((o) => ({
                    ...o,
                    filterType: "change_of_control",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("change_of_control")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">Termination</p>
              <GenericFilter
                isMultiple={true}
                filterName="At Convenience"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "termination_at_convenience"
                    )
                  );
                }}
                values={termination_at_convenience
                  .map((o) => ({
                    ...o,
                    filterType: "termination_at_convenience",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("termination_at_convenience")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Lock in Period"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "locked_in_period"
                    )
                  );
                }}
                values={locked_in_period
                  .map((o) => ({
                    ...o,
                    filterType: "locked_in_period",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("locked_in_period")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Event"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "termination_events"
                    )
                  );
                }}
                values={termination_events
                  .map((o) => ({
                    ...o,
                    filterType: "termination_events",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("termination_events")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Events of Default
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Event"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "events_of_default"
                    )
                  );
                }}
                values={events_of_default
                  .map((o) => ({
                    ...o,
                    filterType: "events_of_default",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("events_of_default")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Limitation Of Liability
              </p>

              <GenericFilter
                isMultiple={true}
                filterName="Amount"
                onChange={() => {}}
                reset={() => {
                  setcapped_check([]);
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "limited_liability_amount"
                    )
                  );
                }}
                values={limited_liability_amount
                  .filter((capped: any) => {
                    if (
                      capped.type == "Capped" &&
                      capped_check.includes("Capped")
                    ) {
                      return capped;
                    }
                    if (
                      capped.type == "Uncapped" &&
                      capped_check.includes("Uncapped")
                    ) {
                      return capped;
                    }
                    if (capped_check.length == 0) {
                      return capped;
                    }
                  })
                  .map((o) => ({
                    ...o,
                    filterType: "limited_liability_amount",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("limited_liability_amount")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={true}
                checkbox_selected={capped_check}
                handleChange={handleChange}
                selected_chekbox_type={"limited_liability_amount"}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Force Majeure
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Event"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "force_majeure_events"
                    )
                  );
                }}
                values={force_majeure_events
                  .map((o) => ({
                    ...o,
                    filterType: "force_majeure_events",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("force_majeure_events")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Governing Law
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Jurisdiction"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "jurisdictions"
                    )
                  );
                }}
                values={jurisdictions
                  .map((o) => ({
                    ...o,
                    filterType: "jurisdictions",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("jurisdictions")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Dispute Resolution
              </p>
              <GenericFilter
                isMultiple={false}
                filterName="Others"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "dispute_res_other"
                    )
                  );
                }}
                values={dispute_res_other
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_other",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_other")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={false}
                filterName="Arbitration"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "dispute_res_arbitration"
                    )
                  );
                }}
                values={dispute_res_arbitration
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_arbitration",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_arbitration")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Venue"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "dispute_res_venue"
                    )
                  );
                }}
                values={dispute_res_venue
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_venue",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_venue")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Act/Statute"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "dispute_res_act_statute"
                    )
                  );
                }}
                values={dispute_res_act_statute
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_act_statute",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_act_statute")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Panel"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "dispute_res_panel"
                    )
                  );
                }}
                values={dispute_res_panel
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_panel",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_panel")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={false}
                filterName="Negotiation"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "dispute_res_negoation"
                    )
                  );
                }}
                values={dispute_res_negoation
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_negoation",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_negoation")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={false}
                filterName="Conciliation"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "dispute_res_concillation"
                    )
                  );
                }}
                values={dispute_res_concillation
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_concillation",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_concillation")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={false}
                filterName="Mediation"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "dispute_res_mediation"
                    )
                  );
                }}
                values={dispute_res_mediation
                  .map((o) => ({
                    ...o,
                    filterType: "dispute_res_mediation",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("dispute_res_mediation")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">Non Compete</p>
              <GenericFilter
                isMultiple={true}
                filterName="Territory"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "territory"
                    )
                  );
                }}
                values={territory
                  .map((o) => ({
                    ...o,
                    filterType: "territory",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("territory")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />

              <GenericFilter
                isMultiple={true}
                filterName="Post Contract Duration"
                onChange={() => {}}
                reset={() => {
                  setnon_compete_duration_check([]);
                  setselected_checkboxItem({
                    ...selected_checkboxItem,
                    non_compete_duration: [],
                  });
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "non_compete_duration"
                    )
                  );
                }}
                values={non_compete_duration
                  .filter((option: any) => {
                    if (
                      option.type === "yes" &&
                      non_compete_duration_check.includes("Yes")
                    ) {
                      return option;
                    }
                    if (
                      option.type === "yes" &&
                      non_compete_duration_check.includes("Yes")
                    ) {
                      return option;
                    }
                    if (non_compete_duration_check.length == 0) {
                      return option;
                    }
                  })
                  .map((o) => ({
                    ...o,
                    filterType: "non_compete_duration",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("non_compete_duration")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={true}
                checkbox_selected={non_compete_duration_check}
                handleChange={handleChange}
                selected_chekbox_type={"non_compete_duration"}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Non Solicitation
              </p>

              <GenericFilter
                isMultiple={true}
                filterName="Post Contract Duration"
                onChange={() => {}}
                reset={() => {
                  setnon_solicitation_duration_check([]);
                  setselected_checkboxItem({
                    ...selected_checkboxItem,
                    non_solicitation_duration: [],
                  });
                  setselected_filter(
                    selected_filter.filter(
                      (option) =>
                        option.filterType !== "non_solicitation_duration"
                    )
                  );
                }}
                values={non_solicitation_duration
                  .filter((option: any) => {
                    if (
                      option.type === "yes" &&
                      non_solicitation_duration_check.includes("Yes")
                    ) {
                      return option;
                    }
                    if (
                      option.type === "no" &&
                      non_solicitation_duration_check.includes("No")
                    ) {
                      return option;
                    }
                    if (non_solicitation_duration_check.length == 0) {
                      return option;
                    }
                  })
                  .map((o) => ({
                    ...o,
                    filterType: "non_solicitation_duration",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("non_solicitation_duration")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={true}
                checkbox_selected={non_solicitation_duration_check}
                handleChange={handleChange}
                selected_chekbox_type={"non_solicitation_duration"}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Consent Clause
              </p>
              <GenericFilter
                isMultiple={true}
                filterName="Type"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "consent_clausetype"
                    )
                  );
                }}
                values={consent_clausetype
                  .map((o) => ({
                    ...o,
                    filterType: "consent_clausetype",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("consent_clausetype")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={true}
                filterName="Authority"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "authority"
                    )
                  );
                }}
                values={authority
                  .map((o) => ({
                    ...o,
                    filterType: "authority",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("authority")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <p className="f-16 filter-container-filter-header">
                Subletting/Assignment
              </p>
              <GenericFilter
                isMultiple={false}
                filterName="Consent"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "subletting_consent"
                    )
                  );
                }}
                values={subletting_consent
                  .map((o) => ({
                    ...o,
                    filterType: "subletting_consent",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("subletting_consent")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
              <GenericFilter
                isMultiple={false}
                filterName="Notice"
                onChange={() => {}}
                reset={() => {
                  setselected_filter(
                    selected_filter.filter(
                      (option) => option.filterType !== "subletting_notice"
                    )
                  );
                }}
                values={subletting_notice
                  .map((o) => ({
                    ...o,
                    filterType: "subletting_notice",
                  }))
                  .filter((option) =>
                    option.name?.toLowerCase().includes(search_text)
                  )}
                keyName="name"
                searchText={(search_text: any) => {
                  filterSearch(search_text);
                }}
                selectedValues={selectedFilter("subletting_notice")}
                checkselectedValues={(filterData: any, selected: boolean) => {
                  recheck_yesno(filterData, selected);
                }}
                selected_filter={selected_filter}
                checkbox_present={false}
                checkbox_selected={[]}
                handleChange={handleChange}
                selected_chekbox_type={""}
                selected_checkbox={selected_checkbox}
              />
            </div>
          </div>
          <div
            className="col-md-9 pl-0"
            style={{ flex: "0 0 72%", maxWidth: "72%" }}
          >
            {/* {filterlabel == "Filter Applied" ? ( */}
            {/* // {contractResults.length >= 0 ? ( */}
            <FilterList
              count={count}
              results={contractResults}
              filterPayload={payload}
              filterApplied={filterApplied}
              selected_filter={applied_filter}
              removeChip={(index: number) => {
                // remove_chip(index);
                let array = [...selected_filter];
                array.splice(index, 1);
                setselected_filter(array);
                applyFilter(array);
              }}
              savedfilters={savedfilters}
              getSavedFilterList={getSavedFilterList}
              chooseSavedFilter={(saved_filters: any) => {
                setselected_checkboxItem({
                  ...selected_checkboxItem,
                  confidentiality_duration: [],
                  non_compete_duration: [],
                  non_solicitation_duration: [],
                });
                setfilterPayload({
                  ...filterPayload,
                  start_date: saved_filters["start_date"],
                  end_date: saved_filters["end_date"],
                  payment: saved_filters["payment"],
                });
                setdate_change({
                  startDate: {
                    min: saved_filters["start_date"].min,
                    max: saved_filters["start_date"].max,
                  },
                  endDate: {
                    min: saved_filters["end_date"].min,
                    max: saved_filters["end_date"].max,
                  },
                });
                setselected_filter(saved_filters["clauses"]);
                // applyFilter(saved_filters["clauses"]);
                savedapplyFilter(saved_filters);
              }}
              selective_filter={selective_filter}
              removeSelectiveChip={(index: number) => {
                applySelctiveFilter(
                  applied_filter,
                  selective_filter[index]["filterType"]
                );
              }}
              filterlabel={filterlabel}
              selected_checkboxItem={selected_checkboxItem}
              non_compete_duration_check={non_compete_duration_check}
              non_solicitation_duration_check={non_solicitation_duration_check}
              confidentiality_duration_check={confidentiality_duration_check}
              cexcept_check={cexcept_check}
              capped_check={capped_check}
              currency={currency}
            />
            {/* // ) : (
             
            // )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
