import {
  GetClauseErrorData,
  GetClauseErrorDataSuccess,
  GetClauseErrorDataFailure,
  GETCLAUSEERRORDATA,
  GETCLAUSEERRORDATA_SUCCESS,
  GETCLAUSEERRORDATA_FAILURE,
} from "../Action/def";
import ClauseErrorData, {
  ClauseErrorList,
} from "../State/clauseErrorManagementState";

export default class ClauseErrorManagementActionGenerator {
  public static getClauseErrorData(fileID: string): GetClauseErrorData {
    return {
      type: GETCLAUSEERRORDATA,
      payload: {
        fileId: fileID,
      },
    };
  }

  public static getClauseErrorDataSuccess(
    clauseErrorData: ClauseErrorList[]
  ): GetClauseErrorDataSuccess {
    return {
      type: GETCLAUSEERRORDATA_SUCCESS,
      payload: {
        clauseErrorData: clauseErrorData,
      },
    };
  }

  public static getClauseErrorDataFailure(): GetClauseErrorDataFailure {
    return {
      type: GETCLAUSEERRORDATA_FAILURE,
    };
  }
}
