import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import HistoryActionGenerator from "../../History/Actions/gen";
import { StoreTree } from "../../Utils/MainReducer/mainReducer";
import DocumentLibraryGenerator from "../Actions/gen";
import DocumentLibrary from "../Component/documentLibrary";
import {
  BasicFileInfo,
  LibraryTagFilterStructure,
  TagInfo,
  LibraryStatistics,
  BasicdocumentLibraryFileInfo,
} from "../State/documentLibraryState";

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    initialFileIds: appState.library.initialFileIds,
    libraryTags: appState.library.libraryTags,
    libraryTagsA: appState.library.libraryTagsA,
    documentHierarchy: appState.library.documentHierarchy,
    savedMultipleSelectedFiles: appState.library.savedMultipleSelectedFiles,
    savedCollapsedFileIds: appState.library.savedCollapsedFileIds,
    documentLibraryLoader: appState.library.documentLibraryLoader,
    appliedLibraryTagFilters: appState.library.appliedLibraryTagFilters,
    filteredFileIds: appState.library.filteredFileIds,
    selectedFile: appState.library.selectedFile,
    selectedLibraryAction: appState.library.selectedLibraryAction,
    isBulkAction: appState.library.isBulkAction,
    editTagsLoader: appState.library.editTagsLoader,
    savedFileTagData: appState.library.savedFileTagData,
    generalFilters: appState.library.generalFilters,
    generalFilterLoader: appState.library.generalFilterLoader,
    generalFilterFileIds: appState.library.generalFilterFileIds,
    documentLibrarystatistics: appState.library.documentLibrarystatistics,
    documentLibraryData: appState.library.documentLibraryData,
    documentselectedFile: appState.library.documentselectedFile,
    documentsavedMultipleSelectedFiles:
      appState.library.documentsavedMultipleSelectedFiles,
    documentfilteredFileIds: appState.library.documentfilteredFileIds,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getUserUploads: () => dispatch(HistoryActionGenerator.getUserUploads(1)),
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
    getLibraryTagsA: () => dispatch(DocumentLibraryGenerator.getLibraryTagsA()),
    getLibraryTags: () => dispatch(DocumentLibraryGenerator.getLibraryTags()),

    getLibraryStatistics: () =>
      dispatch(DocumentLibraryGenerator.getLibraryStatistics()),
    getLibraryFileId: (sort: string, order: string) =>
      dispatch(DocumentLibraryGenerator.getLibraryFileId(sort, order)),
    getHierarchy: (sort: string, order: string, fileIds: string[]) =>
      dispatch(
        DocumentLibraryGenerator.getDocumentHierarchy(sort, order, fileIds)
      ),
    saveMultipleSelectedFiles: (savedMultipleSelectedFiles: BasicFileInfo[]) =>
      dispatch(
        DocumentLibraryGenerator.saveMultipleSelectedFiles(
          savedMultipleSelectedFiles
        )
      ),
    documentsaveMultipleSelectedFiles: (
      documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[]
    ) =>
      dispatch(
        DocumentLibraryGenerator.documentsaveMultipleSelectedFiles(
          documentsavedMultipleSelectedFiles
        )
      ),
    saveCollapsedFileIds: (savedCollapsedFileIds: string[]) =>
      dispatch(
        DocumentLibraryGenerator.saveCollapsedFileIds(savedCollapsedFileIds)
      ),
    applyLibraryTagFilters: (
      appliedLibraryTagFilters: LibraryTagFilterStructure[],
      initialFileIds: string[]
    ) =>
      dispatch(
        DocumentLibraryGenerator.applyLibraryTagFilters(
          appliedLibraryTagFilters,
          initialFileIds
        )
      ),
    getDocumentHierarchy: (sort: string, order: string, fileIds: string[]) =>
      dispatch(
        DocumentLibraryGenerator.getDocumentHierarchy(sort, order, fileIds)
      ),
    saveSelectedFile: (selectedFile: BasicFileInfo | null) =>
      dispatch(DocumentLibraryGenerator.saveSelectedFile(selectedFile)),
    documentsaveSelectedFile: (
      documentselectedFile: BasicdocumentLibraryFileInfo | null
    ) =>
      dispatch(
        DocumentLibraryGenerator.documentsaveSelectedFile(documentselectedFile)
      ),
    saveSelectedLibraryAction: (
      selectedLibraryAction: string,
      isBulkAction: boolean
    ) =>
      dispatch(
        DocumentLibraryGenerator.saveSelectedLibraryAction(
          selectedLibraryAction,
          isBulkAction
        )
      ),
    saveFileTagData: (savedFileTagData: any[]) =>
      dispatch(DocumentLibraryGenerator.saveFileTagData(savedFileTagData)),
    getParentFileList: (
      selectedFileId: string,
      fileIds: string[],
      bulkFileIds: string[]
    ) =>
      dispatch(
        DocumentLibraryGenerator.getParentFileList(
          selectedFileId,
          fileIds,
          bulkFileIds
        )
      ),
    getChildrenFileList: (selectedFileId: string, fileIds: string[]) =>
      dispatch(
        DocumentLibraryGenerator.getChildrenFileList(selectedFileId, fileIds)
      ),
    getGeneralFilter: (fileIds: string[]) =>
      dispatch(DocumentLibraryGenerator.getGeneralFilterDL(fileIds)),
    applyGeneralFilter: (
      fileIds: string[],
      filterType: string,
      sort: string,
      order: string
    ) =>
      dispatch(
        DocumentLibraryGenerator.applyGeneralFilterDL(
          fileIds,
          filterType,
          sort,
          order
        )
      ),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DocumentLibrary);
