import { appliedFilters, FilterType } from "../../State";

export const FilterSet: any = {
  clause_present: "Clause Present",
  clause_absent: "Clause Absent",
  groups: "Group",
  nature: "Nature",
  others: "Others",
  parties: "Parties",
  payee: "Payee",
  payer: "Payer",
  extent_of_costs: "Extent Of Costs",
  triggering_events: "Triggering Events",
  confidentiality_nature: "Confidentiality Nature",
  change_of_control: "Change Of Control",
  termination_at_convenience: "Termination At Convience",
  locked_in_period: "Termination Locked in Period",
  termination_events: "Termination Events",
  events_of_default: "Events Of Defaults",
  force_majeure_events: "Force Majeure Events",
  jurisdictions: "Jurisdictions",
  dispute_resolution: "Dispute Resolution",
  dispute_res_arbitration: "Arbitration",
  dispute_res_venue: "Venue",
  dispute_res_act_statute: "Act Statute",
  dispute_res_panel: "Panel",
  dispute_res_negoation: "Negotiation",
  dispute_res_concillation: "Conciliation",
  dispute_res_other: "Dispute Resolution Other",
  dispute_res_mediation: "Mediation",
  territory: "Territory",
  consent_clausetype: "Consent Clause",
  authority: "Authority",
  subletting_consent: "Subletting Consent",
  subletting_notice: "Subletting Notice",
  limited_liability_amount: "Limitation of liability Amount",
  non_compete_duration: "Non Compete Post Contract Duration",
  non_solicitation_duration: "Non Solicitation Post Contract Duration",
  indemnity_cap: "Indemnity Amount",
  confidentiality_duration: "Confidentiality Post Contract Duration",
  cexcept: "Exception to Confidentiality"
};

export function getClauseFilters(filter_set: appliedFilters[]) {
  let filter_data = "";
  for (let i = 0; i < filter_set.length; i++) {
    if (i == 0) {
      filter_data = filter_set[i].name;
    } else {
      filter_data = filter_data + ", " + filter_set[i].name;
    }
  }
  return filter_data;
}

export function getSelectedToolTip(selected_applied_filter: any[], selective_filter: any[], currency: any[]) {
  let tooltip_text = "";
  let applied_filter: any[] = selected_applied_filter.filter(
    (option) =>
      option.filterType !== "start_date" &&
      option.filterType !== "payment" &&
      option.filterType !== "end_date"
  );
  let new_line = 0;
  for(let j=0;j<selective_filter.length;j++){
    new_line = 1;
    if(j==0)
    {
      if(selective_filter[j]["filterType"] == "payment")
      {
        let currency_type:any[]= currency.filter(
          (option) =>
            option.currencyType === selective_filter[j]["currency_type"]
        );
        console.log("currency", currency_type)
        if(currency_type.length > 0){
          let currency_displayName = currency_type[0]['currencyName'];
          tooltip_text = "Payment Obligation("+currency_displayName+":"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
        }
        // tooltip_text = "Payment Obligation("+CurrencyTypes[selective_filter[j]["currency_type"]]+":"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
      }else if(selective_filter[j]["filterType"] == "start_date"){
        tooltip_text = "Term Clause(Start Date:"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
      }else if(selective_filter[j]["filterType"] == "start_date"){
        tooltip_text = "Term Clause(End Date:"+ selective_filter[j]["min"]+" | "+selective_filter[j]["max"]+")";
      }
    }
    else{
      if(selective_filter[j]["filterType"] == "payment")
      {
        let currency_type:any[]= currency.filter(
          (option) =>
            option.currencyType === selective_filter[j]["currency_type"]
        );
        console.log("currency11", currency_type)
        if(currency_type.length > 0){
          let currency_displayName = currency_type[0]['currencyName'];
          tooltip_text = tooltip_text+"\n"+"Payment Obligation("+currency_displayName+":"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
        }
        // tooltip_text = tooltip_text+"\n"+"Payment Obligation("+CurrencyTypes[selective_filter[j]["currency_type"]]+":"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
      }else if(selective_filter[j]["filterType"] == "start_date"){
        tooltip_text = tooltip_text+"\n"+"Term Clause(Start Date:"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
      }else if(selective_filter[j]["filterType"] == "start_date"){
        tooltip_text = tooltip_text+"\n"+"Term Clause(End Date:"+ selective_filter[j]["min"]+" - "+selective_filter[j]["max"]+")";
      }
    }
  }
  for (let i = 0; i < applied_filter.length; i++) {
    if (i == 0 && new_line == 0) {
      if (applied_filter[i].name != "Yes" && applied_filter[i].name != "No") {
        tooltip_text = FilterSet[applied_filter[i].filterType]+ " | "+getSelectedFilter(applied_filter[i].name);
      } else {
        tooltip_text =
          FilterSet[applied_filter[i].filterType] +
          " | " +
          getSelectedFilter(applied_filter[i].name);
      }
    }
    
    else {
      if (applied_filter[i].name != "Yes" && applied_filter[i].name != "No") {
        tooltip_text = tooltip_text + "\n" + FilterSet[applied_filter[i].filterType]+" | "+getSelectedFilter(applied_filter[i].name);
      } else {
        tooltip_text =
          tooltip_text +
          "\n" +
          FilterSet[applied_filter[i].filterType] +
          " | " +
          getSelectedFilter(applied_filter[i].name);
      }
    }
  }
  return tooltip_text;
}

export function getSelectedFilter(filter: string){
  if(filter.length > 25)
  {
    return filter.substring(0, 25)+"...";
  }else{
    return filter;
  }
}

export const CurrencyTypes: any={
  "€": "€ Euro",
  "₹": "₹ Indian Rupee",
  "$": "$ Dollar"
}