import * as React from "react";
import { isNullOrUndefined } from "is-what";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { draftStatus } from "../State/DraftState";

interface Props {
  open: boolean;
  onClose: any;
  specificDraft: any;
  onPatch: any;
}
// interface State {
// selectedUser: any;
// filteredUser: any;
// error: any;
// openSnackbar: any;
// }
// constructor(props: Props) {
//   super(props);
//   state = {
// selectedUser: {
// role: "",
// userID: "",
// },
//   error: false,
//   filteredUser: [],
//   openSnackbar: {
//   open: false,
//   msg: "",
//   },
//   };
//   }

function AddNewPersonDialog(props: Props) {
  const [selectedUser, setselectedUser] = React.useState<any>({
    role: "",
    userID: "",
  });

  const [error, seterror] = React.useState<any>(false);
  const [filteredUser, setfilteredUser] = React.useState<any>([]);
  const [openSnackbar, setopenSnackbar] = React.useState<any>({
    open: false,
    msg: "",
  });
  const [specificDraft, setspecificDraft] = React.useState<any>({});
  React.useEffect(() => {
    document.addEventListener("mouseup", handleOutsideClick, false);
  }, []);
  const handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("deleteModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("delete-btn-outside-click")?.click();
        window.location.reload();
      }
    }
  };
  React.useEffect(() => {
    setspecificDraft(props.specificDraft);
  }, [props.specificDraft]);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    // p: 4,
    borderRadius: 15,
  };
  function camelize(str: any) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: any, index: any) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  const onChange = (role: any) => {
    const data: any = localStorage.getItem("userData");
    const userDatanew = JSON.parse(data);
    const dd = userDatanew.users.filter((item: any) => {
      if (item.roles.includes(role)) {
        return item;
      }
    });

    setfilteredUser(dd);
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: "" });
  };
  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </React.Fragment>
  );
  const checkcollaborationData = () => {
    const collaborationData = [...specificDraft.collaborators];
    if (collaborationData.length) {
      const filterData = collaborationData.filter(
        (val: any) => val == selectedUser.userID.id
      );
      if (filterData.length) {
        let collaborators = collaborationData.map((val: any) => val.id);
        return collaborators;
      } else {
        const data = collaborationData.map((val: any) => val.id);
        let collaborators = [...data, selectedUser.userID.id];
        return collaborators;
      }
    } else {
      let collaborators = [selectedUser.userID.id];
      return collaborators;
    }
  };
  const checkSignatories = () => {
    const SignatoriesData: any = [...specificDraft.signatories];
    const filteruser: any =
      SignatoriesData != null || SignatoriesData.length
        ? SignatoriesData?.filter(
            (val: any) => val.id == selectedUser.userID.id
          )
        : [];
    if (SignatoriesData.length) {
      const filterData: any = SignatoriesData.filter(
        (val: any) => val.id == selectedUser.userID.id
      );
      if (filterData.length) {
        let signatories = [...SignatoriesData];
        return signatories;
      } else {
        let signatories = [...SignatoriesData, ...filteruser];
        return signatories;
      }
    } else {
      let signatories = filteruser;
      return signatories;
    }
  };
  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-header">
            <p className="m-0">Add New Assignee</p>
            <img
              className="cursor-pointer"
              src="/static_images/white-close-modal-icn.svg"
              alt="clause-folder"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <div>
              <Box>
                <FormControl
                  className="cls_section_name"
                  fullWidth
                  style={{
                    marginLeft: "0px",
                    marginTop: "20px",
                  }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: "#88305F" }}
                  >
                    Select role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select role"
                    value={selectedUser.role}
                    onChange={(val) => {
                      setselectedUser({
                        ...selectedUser,
                        role: val.target.value,
                      });
                      onChange(val.target.value);
                    }}
                  >
                    <MenuItem value={"Collaborators"}>
                      {"Collaborators"}
                    </MenuItem>
                    <MenuItem value={"Approvers"}>{"Approvers"}</MenuItem>
                    <MenuItem value={"Pre Signatories"}>
                      {"Pre-Signatories"}
                    </MenuItem>
                    <MenuItem value={"Signatories"}>{"Signatories"}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl
                  className="cls_section_name"
                  fullWidth
                  style={{
                    marginLeft: "0px",
                    marginTop: "20px",
                  }}
                  size="small"
                  variant="outlined"
                  disabled={selectedUser.role === "" ? true : false}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: "#88305F" }}
                  >
                    Select user
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedUser.userID}
                    label="Select user"
                    onChange={(val) => {
                      setselectedUser({
                        ...selectedUser,
                        userID: val.target.value,
                      });
                    }}
                  >
                    {filteredUser?.map((item: any) => (
                      <MenuItem value={item}>{item.username}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <div className="d-flex justify-content-end mt-3">
                <div
                  className="row justify-content-end"
                  style={{ marginRight: "20px" }}
                >
                  <button
                    onClick={() => {
                      props.onClose();
                    }}
                    type="button"
                    className="btn btn-warning create-btn"
                  >
                    Cancel
                  </button>
                </div>
                <div
                  className="row justify-content-end"
                  style={{ marginRight: "20px" }}
                >
                  <button
                    onClick={() => {
                      let payload: any = {};
                      let error = false;
                      if (selectedUser.role === "Approvers") {
                        const newapproverdataforapprover = {
                          approval_status: "Requisition Approval Pending",
                          approved_date: new Date().toLocaleDateString(),
                          assigned_date: new Date().toLocaleDateString(),
                          email: selectedUser.userID.email,
                          first_name: selectedUser.userID.first_name,
                          groups: selectedUser.userID.groups,
                          id: selectedUser.userID.id,
                          last_name: selectedUser.userID.last_name,
                          assigned_role: [selectedUser.role],
                          roles: selectedUser.userID.roles,
                          username: selectedUser.userID.username,
                        };
                        if (props.specificDraft?.approvalTypes.length === 1) {
                          const approvers =
                            props.specificDraft?.approvalTypes[0].approvers;
                          const collaborators =
                            props.specificDraft?.collaborators;
                          const signatories = props.specificDraft?.signatories;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const filterdata = approvers?.filter((item: any) =>
                            item.roles.includes(selectedUser.role)
                          );
                          if (filterdata.length === 0) {
                            error = true;
                          } else {
                            payload = {
                              approvalTypes: [
                                props.specificDraft?.approvalTypes[0],
                                {
                                  approval_sequence: "All",
                                  approval_type_name: "Adhoc",
                                  approvers: [newapproverdataforapprover],
                                },
                                {
                                  approval_sequence: "All",
                                  approval_type_name: "Conditional",
                                  approvers: [newapproverdataforapprover],
                                },
                              ],
                              collaborators: collobraterid,
                            };
                          }
                        } else {
                          const approvers =
                            props.specificDraft?.approvalTypes[2].approvers;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const collaborators =
                            props.specificDraft?.collaborators;
                          const signatories = props.specificDraft?.signatories;
                          const filterdata = approvers?.filter(
                            (item: any) =>
                              item.roles.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            const spreadprevious = [
                              ...props.specificDraft?.approvalTypes[2]
                                .approvers,
                              newapproverdataforapprover,
                            ];
                            payload = {
                              approvalTypes: [
                                props.specificDraft?.approvalTypes[0],
                                {
                                  approval_sequence: "All",
                                  approval_type_name: "Adhoc",
                                  approvers: spreadprevious,
                                },
                                {
                                  approval_sequence: "All",
                                  approval_type_name: "Conditional",
                                  approvers: spreadprevious,
                                },
                              ],
                              collaborators: collobraterid,
                            };
                          }
                        }
                      } else if (selectedUser.role === "Collaborators") {
                        const newapproverdatafocollabrator = {
                          approval_status: "Requisition Approval Pending",
                          approved_date: new Date().toLocaleDateString(),
                          assigned_date: new Date().toLocaleDateString(),
                          email: selectedUser.userID.email,
                          first_name: selectedUser.userID.first_name,
                          groups: selectedUser.userID.groups,
                          id: selectedUser.userID.id,
                          last_name: selectedUser.userID.last_name,
                          assigned_role: [selectedUser.role],
                          roles: selectedUser.userID.roles,
                          username: selectedUser.userID.username,
                        };
                        if (props.specificDraft?.collaborators.length !== 0) {
                          const collaborators =
                            props.specificDraft?.collaborators;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const filterdata = collaborators?.filter(
                            (item: any) =>
                              item.roles.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                ...props.specificDraft?.collaborators,
                                newapproverdatafocollabrator,
                                ...props.specificDraft?.signatories,
                              ];
                              payload = {
                                collaborators: [
                                  ...collobraterid,
                                  selectedUser.userID.id,
                                ],
                              };
                            } else {
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                collaborators: [
                                  ...collobraterid,
                                  selectedUser.userID.id,
                                ],
                              };
                            }
                          }
                        } else {
                          const collaborators =
                            props.specificDraft?.collaborators;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const filterdata = collaborators?.filter(
                            (item: any) =>
                              item.roles.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                ...props.specificDraft?.collaborators,
                                newapproverdatafocollabrator,
                                ...props.specificDraft?.signatories,
                              ];
                              payload = {
                                collaborators: [
                                  ...collobraterid,
                                  selectedUser.userID.id,
                                ],
                              };
                            } else {
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                collaborators: [
                                  ...collobraterid,
                                  selectedUser.userID.id,
                                ],
                              };
                            }
                          }
                        }
                      } else if (selectedUser.role === "Signatories") {
                        const newapproverdataforsignatory = {
                          assigned_date: new Date().toLocaleDateString(),
                          email: selectedUser.userID.email,
                          first_name: selectedUser.userID.first_name,
                          groups: selectedUser.userID.groups,
                          id: selectedUser.userID.id,
                          last_name: selectedUser.userID.last_name,
                          assigned_role: [selectedUser.role],
                          roles: selectedUser.userID.roles,
                          signed_date: null,
                          username: selectedUser.userID.username,
                        };
                        if (props.specificDraft?.signatories.length !== 0) {
                          const signatories = props.specificDraft?.signatories;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const filterdata = signatories?.filter(
                            (item: any) =>
                              item.assigned_role.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                newapproverdataforsignatory,
                                ...props.specificDraft.collaborators,
                                ...props.specificDraft?.signatories,
                              ];
                              payload = {
                                signatories: [
                                  ...signatories,
                                  newapproverdataforsignatory,
                                ],
                              };
                            } else {
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                signatories: [
                                  ...signatories,
                                  newapproverdataforsignatory,
                                ],
                                collaborators: collobraterid,
                              };
                            }
                          }
                        } else {
                          const signatories = props.specificDraft?.signatories;
                          const filterdata = signatories?.filter(
                            (item: any) =>
                              item.roles.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                ...props.specificDraft?.signatories,
                                newapproverdataforsignatory,
                                ...props.specificDraft.collaborators,
                              ];
                              payload = {
                                signatories: [
                                  ...signatories,
                                  newapproverdataforsignatory,
                                ],
                              };
                            } else {
                              const collobraterid =
                                props?.specificDraft?.collaborators.map(
                                  (i: any) => i.id
                                );
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                signatories: [
                                  ...signatories,
                                  newapproverdataforsignatory,
                                ],
                                collaborators: collobraterid,
                              };
                            }
                          }
                        }
                      } else {
                        const newapproverdataforsignatory = {
                          assigned_date: new Date().toLocaleDateString(),
                          email: selectedUser.userID.email,
                          first_name: selectedUser.userID.first_name,
                          groups: selectedUser.userID.groups,
                          id: selectedUser.userID.id,
                          last_name: selectedUser.userID.last_name,
                          assigned_role: [selectedUser.role],
                          roles: selectedUser.userID.roles,
                          signed_date: null,
                          username: selectedUser.userID.username,
                          approval_status:
                            draftStatus.PRESIGNATORIES_APRROVAL_PENDING,
                        };
                        if (
                          props.specificDraft?.pre_signatories &&
                          props.specificDraft?.pre_signatories.length !== 0
                        ) {
                          const pre_signatories =
                            props.specificDraft?.pre_signatories;
                          const collobraterid =
                            props?.specificDraft?.collaborators.map(
                              (i: any) => i.id
                            );
                          const filterdata = pre_signatories?.filter(
                            (item: any) =>
                              item.assigned_role.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata && filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                newapproverdataforsignatory,
                                ...props.specificDraft.collaborators,
                                ...props.specificDraft?.pre_signatories,
                              ];
                              payload = {
                                pre_signatories: [
                                  ...pre_signatories,
                                  newapproverdataforsignatory,
                                ],
                              };
                            } else {
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                pre_signatories: [
                                  ...pre_signatories,
                                  newapproverdataforsignatory,
                                ],
                                collaborators: collobraterid,
                              };
                            }
                          }
                        } else {
                          const pre_signatories =
                            props.specificDraft?.pre_signatories;
                          const filterdata = pre_signatories?.filter(
                            (item: any) =>
                              item.roles.includes(selectedUser.role) &&
                              item.username === selectedUser.userID.username
                          );
                          if (filterdata.length !== 0) {
                            error = true;
                          } else {
                            if (
                              props.specificDraft?.approvalTypes.length === 1
                            ) {
                              const spreadprevious = [
                                ...props.specificDraft?.pre_signatories,
                                newapproverdataforsignatory,
                                ...props.specificDraft.collaborators,
                              ];
                              payload = {
                                pre_signatories: [
                                  ...pre_signatories,
                                  newapproverdataforsignatory,
                                ],
                              };
                            } else {
                              const collobraterid =
                                props?.specificDraft?.collaborators.map(
                                  (i: any) => i.id
                                );
                              const spreadprevious = [
                                ...props.specificDraft?.approvalTypes[2]
                                  .approvers,
                              ];
                              payload = {
                                approvalTypes: [
                                  props.specificDraft?.approvalTypes[0],
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Adhoc",
                                    approvers: spreadprevious,
                                  },
                                  {
                                    approval_sequence: "All",
                                    approval_type_name: "Conditional",
                                    approvers: spreadprevious,
                                  },
                                ],
                                pre_signatories: [
                                  ...pre_signatories,
                                  newapproverdataforsignatory,
                                ],
                                collaborators: collobraterid,
                              };
                            }
                          }
                        }
                      }
                      const type = props.specificDraft.docID.split("");
                      if (selectedUser.userID.username) {
                        if (error) {
                          setopenSnackbar({
                            open: true,
                            msg: "User already has the selected role",
                          });
                        } else {
                          props.onPatch(
                            props.specificDraft.id,
                            payload,
                            type[0] + type[1] + type[2]
                          );
                          props.onClose();
                        }
                      }
                    }}
                    type="button"
                    className="btn btn-warning create-btn"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackbar.open}
            onClose={closeSnackbar}
            message={openSnackbar.msg}
            key="bottomCenter"
            action={action}
          />
        </Box>
      </Modal>
    </>
  );
}

export default AddNewPersonDialog;
