import React, { Component, createRef } from "react";
import { getContractTypes } from "../../ContractTypes/DA";
import { FilterData } from "../../History/State/historyState";
import { getUserUploads } from "../../UniversalComponents/Filter/DA";
import FilterInputModal from "../../UniversalComponents/Filter/filterInputModal";
interface State {
  sort: string;
  titleOrder: boolean;
  fileNameOrder: boolean;
  dueOrder: boolean;
  progressNameOrder: boolean;
  stateNameOrder: boolean;
  clauseNameOrder: boolean;
  nameSort: boolean;
  filterDetails: any;
  filterControl: any;
  contractList: any;
  uploadStatus:any
}
interface Props {
  applySort: any;
  applyFilter: any;
  FilterKeyVals: FilterData;
  onMasterCheck: any;
  MasterChecked: boolean;
  contractPageCount: number;
}
export default class UploadHeader extends Component<Props, State> {
  uploadBy = createRef<HTMLInputElement>();
  uploadSize = createRef<HTMLInputElement>();
  uploadStatus = createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      sort: "title",
      titleOrder: true,
      fileNameOrder: false,
      dueOrder: false,
      progressNameOrder: false,
      stateNameOrder: false,
      clauseNameOrder: false,

      nameSort: true,
      filterDetails: {},
      filterControl: {},
      contractList: [],
      uploadStatus:[]
    };
  }

  setSortingAndOrder(sortBy: string) {
    let {} = this.state;
  }
  handleClick = (event: any, placeHolder: string, filterKey: string) => {
    this.setState({ filterDetails: { placeHolder, filterKey } });
    this.setState({
      filterControl: { ...this.state.filterControl, [filterKey]: true },
    });
  };

  handleClose = (key: string) => {
    this.setState({
      filterControl: { ...this.state.filterControl, [key]: false },
    });
  };

  fetchContract = async () => {
    try {
      const response = await getUserUploads(this.props.contractPageCount);
      
   
      if (response && response.results.length > 0) {
        const uploadBy = response.results.map(
          (result: any) => result.created_by
        );
        
        uploadBy.filter((item: any, index: number) => {
          if (uploadBy.includes(item)) {
            this.setState({
              contractList: [item],
            });
          }
        });

        const uploadStatus =   response.results.map(
          (result: any) => result.status
        );
        // console.log(uploadStatus,"uploadStatus");
        
        uploadStatus.filter((item: any, index: number) => {
          if (uploadStatus.includes(item)) {
            this.setState({
              uploadStatus: [item],
            });
          }
        });



      } else if (response && response.results.length == 0) {
        this.setState({ contractList: [] });
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  componentDidMount() {
    this.fetchContract();
  }

  render() {
    let {} = this.state;
    let { onMasterCheck, MasterChecked } = this.props;
    return (
      <div className="row upload-header-container mt-3 pl-0 " style={{ borderBottom: "1px solid grey"}}>
        <div className="col-md-2">
          <input
            type="checkbox"
            id="mastercheck"
            className="mr-3 myinput"
            style={{ color: "#1D1D1D", height: "16px", width: "16px" }}
            onChange={(e: any) => onMasterCheck(e)}
            checked={MasterChecked}
            //  onClick={() => getSelectedRows()}
          />
          File name
          {this.state.nameSort === true ? (
            <img
              src="/static_images/text_filter_active.svg"
              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "file_name");
                })
              }
              className="pl-1 w-10"
              style={{ cursor: "pointer" }}
            />
          ) : (
            <img
              src="/static_images/namedec.svg"
              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "file_name");
                })
              }
              className="pl-1 w-10"
            />
          )}
        </div>
        <div
          className="col-md-2 d-flex justify-content-center align-items-center"
          ref={this.uploadBy}
        >
          Uploaded by
          <img
            src="/static_images/filter Icon.svg"
            className="pl-1 w-10"
            onClick={(e) => {
              this.handleClick(e, "Created By", "created_by");
            }}
            style={{cursor:"pointer"}}
          />
          <FilterInputModal
            list={this.state.contractList}
            filterKey="created_by"
            filterKeyVals={this.props.FilterKeyVals}
            open={this.state.filterControl["created_by"]}
            anchorEl={this.uploadBy?.current}
            handleClose={() => this.handleClose("created_by")}
            applyFilter={this.props.applyFilter}
          />
        </div>
        <div className="col-md-2 d-flex justify-content-center align-items-center">
          Uploaded on
          {this.state.nameSort === true ? (
            <img
              src="/static_images/calender_filter_active.svg"
              className="pl-1 w-10"
              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "created_on");
                })
              }
              style={{cursor:"pointer"}}
            />
          ) : (
            <img
              src="/static_images/sortDate1.svg"
              className="pl-1 w-10"
              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "created_on");
                })
              }
              style={{cursor:"pointer"}}
            />
          )}
        </div>
        <div
          className="col-md-1 d-flex justify-content-center align-items-center"
          ref={this.uploadSize}
        >
          Size
          {this.state.nameSort === true ? (
            <img
              src="/static_images/number_filter_active.svg"
              className="pl-1 w-10"
              // onClick={(e) => this.handleClick(e, "file size", "file_size")}

              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "file_size");
                })
              }
              style={{cursor:"pointer"}}
            />
          ) : (
            <img
              src="/static_images/sizedec.svg"
              className="pl-1 w-10"
              // onClick={(e) => this.handleClick(e, "file size", "file_size")}

              onClick={() =>
                this.setState({ nameSort: !this.state.nameSort }, () => {
                  this.props.applySort(this.state.nameSort, "file_size");
                })
              }
              style={{cursor:"pointer"}}
            />
          )}
          {/* <FilterInputModal
            list={this.state.contractList}
            filterKey="file_size"
            filterKeyVals={this.props.FilterKeyVals}
            open={this.state.filterControl["file_size"]}
            anchorEl={this.uploadSize?.current}
            handleClose={() => this.handleClose("file_size")}
            applyFilter={this.props.applyFilter}
          /> */}
        </div>
        <div
          className="col-md-2 d-flex justify-content-center align-items-center"
          ref={this.uploadStatus}
        >
          Status
          <img
            src="/static_images/filter Icon.svg"
            className="pl-1 w-10"
            onClick={(e) => this.handleClick(e, "file status", "status")}
            style={{cursor:"pointer"}}
          />
            <FilterInputModal
              list={this.state.uploadStatus}
              filterKey="status"
              filterKeyVals={this.props.FilterKeyVals}
              open={this.state.filterControl["status"]}
              anchorEl={this.uploadStatus?.current}
              handleClose={() => this.handleClose("status")}
              applyFilter={this.props.applyFilter}
            />
        </div>
        <div className="col-md-1 d-flex justify-content-center align-items-center">Actions</div>
        <div className="col-md-2  d-flex justify-content-center align-items-center" style={{paddingRight:"2px"}}>Quality</div>
      </div>
    );
  }
}
