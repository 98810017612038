import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import { Box, Snackbar, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { File } from "../../UploadFile/Constants/types";
import UploadFileDA from "../../UploadFile/DA";
import { truncateFileName } from "../../Utils/DataModifierUtil/dataModUtil";
import { ProgressBar } from "react-bootstrap";
import Uploadsupporttypeselect from "./Uploadsupporttypeselect";
const SparkMD5 = require("spark-md5");

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface Props {
  openModal: string;
  supportBtn: any;
  handleClosesupportBtn: any;
  supporttitle: any;
  SelectedList: any;
  supportid:any;
  documentLibraryApi:any;
}

const SupportModal: React.FC<Props> = (props: Props) => {
  const inputref = React.useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<any>({});
  const [selectagain, setselectagain] = useState(false);
  const [rejected, setrejected] = useState<Array<string>>([]);
  const [highlight, setHighlight] = useState(false);
  const [childrenmodal, setchildrenmodal] = useState(false);
  const [selectedfiles, setselectedfiles] = useState<Array<string>>([]);
  const handleclick = () => {
    if (inputref.current !== null) {
      inputref.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener(
      "dragover",
      function (e) {
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e.preventDefault();
      },
      false
    );

    return () => {
      window.removeEventListener(
        "dragover",
        function (e) {
          e.preventDefault();
        },
        false
      );
      window.removeEventListener(
        "drop",
        function (e) {
          e.preventDefault();
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    setselectagain(false);
  }, [props.SelectedList])

  const onFileDrop = (event: any) => {
    let files = event.dataTransfer.files;

    handleOnChangeFileUpload(files);
    // handleOnChangeFileUpload({ target: { files } });
    setHighlight(false);
  };
  const onDragEnter = () => {
    setHighlight(true);
  };
  const onDragLeave = () => {
    setHighlight(false);
  };

  const handleOnChangeFileUpload = (files: any) => {
    let files_ = uploadedFiles;

    // const files = post.target.files;

    const uploadProgress_ = uploadProgress;
    let a: Array<string> = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        

        let reader = new FileReader();

        if (file) {
          reader.readAsDataURL(file);
          reader.onload = () => {
            const hexHash = SparkMD5.hash(reader.result);

            files_.push({
              name: file.name,
              size: file.size,
              type: file.type,
              hash: hexHash,
              status: "uploading",
            });
            uploadProgress_[hexHash] = 0;
            setUploadProgress({ ...uploadProgress_ });

            const check = () => {
              let ref_contract_id = props.SelectedList[0] || props.supportid;
              return {
                file_hash: hexHash,
                file_name: file.name,
                file_size: file.size,
                uploaded_type: "Support Document",
                refcontract_id: ref_contract_id,
              };
            };

            UploadFileDA.get_s3_presigned_url(check())
              .then((response) => {
                a = [...a, response?.contract];
                setselectedfiles(a);
                const onHandleFileProgress = {
                  onUploadProgress: function (progressEvent: any) {
                    var percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );

                    const uploadProgress_ = uploadProgress;
                    uploadProgress_[hexHash] = percentCompleted;
                    setUploadProgress({
                      ...uploadProgress_,
                    });
                  },
                };
                if (response && response.file_hash) {
                }

                if (response && response.presigned_url) {
                  const s3Info = response.presigned_url;
                  
                  UploadFileDA.upload_file_in_s3_bucket(
                    s3Info,
                    file,
                    onHandleFileProgress
                  );
                } else if (
                  response?.file_hash[0]?.includes(
                    "contract with this file hash already exists"
                  )
                ) {
                  setUploadedFiles([]);
                  setUploadProgress([])
                  setselectagain(true);
                  a = [...a, file.name];
                  setrejected(a);
                }
              })
              .catch((error) => {
                
                setselectagain(true);
                a = [...a, file.name];
                setrejected(a);
              });
            setUploadedFiles([...files_]);
          };
        }
      }
    }
  };

  const closeparent = () => {
    props.handleClosesupportBtn()
    setUploadedFiles([])
    setUploadProgress([])
    setchildrenmodal(true);
  };
  const checkFinishBtn = () => {
    const val = Object?.values(uploadProgress).filter((val) => val == 0);
    return val && val.length > 0 ? false : true;
  };

  return (
    <>
      <div className="">
        <Modal
          open={props.openModal === "modalSupport"}
          // onClick={props.handleDialog}

          onClose={()=>{
            props.handleClosesupportBtn();
            setUploadedFiles([])
            setUploadProgress([])
            setselectagain(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ width: "996px", height: "482px" }}>
            <div className="mb-2 d-flex justify-content-between">
              <span> {props.supporttitle}</span>
              <span className="mr-0" onClick={()=>{
                props.handleClosesupportBtn()
                setUploadedFiles([])
                setUploadProgress([])
                setselectagain(false);
                props.documentLibraryApi({archive: false})
                }}>
                <img
                  src={"/static_images/cross-cancel-icn.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
            <Typography
              id="modal-modal-title"
              // variant="h6"
              component="div"
              onDrop={onFileDrop}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              style={{
                width: "100%",
                height: "325px",
                border: "1px dashed #D9D9D9",
                background: "rgba(217, 217, 217, 0.25)",
                // borderColor: highlight ? "blue" : "#d9d9d9",
                // overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {uploadedFiles && uploadedFiles.length > 0 ? (
                <div>
                  {uploadedFiles.map((file, i) => {
                    return (
                      <div className="row w-100 mt-2 px-3">
                        <div
                          className="col-md-3 file-name-style-upload"
                          // style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        >
                          <span>{truncateFileName(file.name.toString())}</span>
                        </div>
                        <div
                          className="col-md-8 p-0"
                          style={{
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          <span className="">
                          {rejected.includes(file.name.toString())?`Already exist `:
                            <ProgressBar
                              // striped
                              animated
                              // variant="warning"
                              now={uploadProgress[file.hash.toString()]}
                              className="purple-progress-bar "
                              style={{
                                height: "6px",
                                borderRadius: "100px",
                                marginTop: "8px",
                                background: "#D9D9D9",
                              }}
                            />
                          }
                          </span>
                        </div>
                        <div className="col-md-1 d-flex p-0px-2 ">
                        {rejected.includes(file.name.toString())?null:
                          <span
                            style={{ fontSize: " 13px", marginRight: "10px" }}
                          >
                            {uploadProgress[file.hash.toString()]}%
                          </span>
                        }
                          {uploadProgress[file.hash.toString()] == 100 ? (
                            <img
                              // onClick={() =>
                              //   displayDeleteModal(file.name, file.hash)
                              // }
                              style={{
                                cursor: "pointer",
                                height: "16px",
                                width: "16px",
                                display: "none"
                              }}
                              src="/static_images/Delete_upload.svg"
                              alt="delete-icn"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="d-flex  justify-content-center align-items-center flex-column "
                  style={{ marginTop: "7rem", gap: "20px" }}
                >
                  <div className="uploadedby-style-upload">
                    <span className="" style={{display:"none"}}>
                      {!selectagain
                        ? "Drag your support document here or click the button to upload support documents"
                        : `Please select another file , ${
                            rejected.length > 0 ? rejected.map((e) => e) : ""
                          } is already exist`}
                    </span>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-warning navigate-btn "
                      onClick={(e: any) => handleclick()}
                    >
                      <span className="pr-2">
                        {" "}
                        <img src={"/static_images/addfiles-icn.svg"} alt="" />
                      </span>
                      Upload Support Documents
                    </button>
                  </div>
                </div>
              )}
            </Typography>

            <div className="row mt-4">
              {uploadedFiles && uploadedFiles?.length > 0 ? (
                <div className="uploadedby-style-upload col-md-12 justify-content-center">
                  <span>
                    {/* Your support document will be uploaded in the background,
                    you can go ahead and close this dialogue box. */}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div className="uploadedby-style-upload col-md-12 justify-content-end">
                <div className="col-md-5">
                  <div className="d-flex">
                    <form encType="multipart/form-data">
                      <input
                        style={{ visibility: "hidden" }}
                        name="file"
                        id="file-input"
                        type="file"
                        ref={inputref}
                        className="form-control upload-yellow-btn"
                        multiple
                        accept=".pdf,.doc,.docx,.txt"
                        onChange={(e: any) =>
                          handleOnChangeFileUpload(e.target.files)
                        }
                      />
                    </form>

                    {uploadedFiles && uploadedFiles?.length > 0 ? (
                      checkFinishBtn() ? (
                        <button
                          type="button"
                          className="btn btn-warning navigate-btn  "
                          onClick={() => {
                            closeparent();
                          }}
                        >
                          Finish
                        </button>
                      ) : (
                        ""
                      )
                    ) : (
                      <button
                        type="button"
                        className="btn btn-warning navigate-btn  "
                        // name={
                        //   props.name === "Support Document"
                        //     ? props.name
                        //     : props.fileTypeSelect
                        // }
                        // onClick={   handleclick()}
                        // onClick={(e: any) => {
                        //   a(e);
                        // }}
                        onClick={(e: any) => handleclick()}
                        style={{display:"none"}}

                        // onChange={(e: any) => props.updateState(e.target.name)}
                      >
                        <span>
                          <img src={"/static_images/addfiles-icn.svg"} alt="" />
                        </span>
                        {/* {props.buttonTitle} */}
                        Upload Support Documents
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <Uploadsupporttypeselect
        supporttitle={"Upload Supporting Documents"}
        selectedfile={selectedfiles}
        openchildren={childrenmodal}
        handleclose={()=>{
          setchildrenmodal(false)
        props.documentLibraryApi({archive: false})
        }}
        
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportModal);
