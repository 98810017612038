import React, { Component } from "react";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import {
  InsightsInterface,
  Children,
  BiPointDataMode,
  QuickIntel,
  DurationType,
  Child,
  CurrencyType,
  DeviationPolicyInterface,
  Approval,
  PolicyInterface,
} from "../State/documentState";
import { isNullOrUndefined } from "is-what";
import EditFeature from "./EditFeature/Container/cont";
import LinesLoader from "../../UniversalComponents/Loader/linesLoader";
import InsightComponents from "./InsightComponents/insightComponents";
import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import wordsToNumbers from "words-to-numbers";

import {
  simulateScroll,
  getParentHighlights,
  getChildHighlights,
  AuxArrayInterface,
  detectMultipleInstances,
  highlight_yellow,
  createChildDataPointsAggregate,
  tag_tooltip,
  unClickableBI,
  separateComponent,
  editableComponent,
  resetAllBiPointDataModes,
  disputeResolutionModeBi,
  separateUnclickableBI,
  nature_tag_color,
  type_tag_color,
  others_tag_color,
  alternateNewBIs,
  omitBIBackground,
  hideBIValue,
  groups_tag_color,
} from "./Utils/docUtils";

import Tooltip from "@material-ui/core/Tooltip";
import { History } from "history";
import DataReset from "./dataReset";
import { CurrentTask } from "../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";
import axios, { AxiosRequestConfig } from "axios";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import ClausesInsights from "./InsightComponents/clausesInsights";
import SwitchClausesInsights from "./InsightComponents/switchClausesInsights";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { truncateString } from "../../Utils/DataModifierUtil/dataModUtil";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
export const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#393939",
    // color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    marginTop: 0,
  },
}))(Tooltip);

interface Props {
  insightsData: InsightsInterface[];
  saveDataPoints: (
    dataPoints: string[],
    state: boolean,
    tempParaIndex: number,
    type: string
  ) => void;
  scrolledChildIndex: number;
  superImposeChildIndex: (childIndex: number) => void;
  fileId: string;
  sentenceLoader: boolean;
  editLoader: boolean;
  location: any;
  history: History;
  editOptionSelected: (editOptionSelected: boolean) => void;
  storedBiPointDataModes: BiPointDataMode[];
  saveBiPointDataMode: (storedBiPointDataModes: BiPointDataMode[]) => void;
  saveSelectedInsightPoint: (selectedInsightPoint: string) => void;
  setCurrentScrollIndex: (currentScrollIndex: number) => void;
  currentScrollIndex: number;
  currentTask: CurrentTask;
  setCurrentTask: (name: string, value: string, contractName: string) => void;
  fileEncoded: string;
  setTaskPage: (hideTaskPage: boolean) => void;
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  clauseModeStatus: boolean;
  setTaskOrigin: (origin: string) => void;
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  saveInsightToDelete: (
    insightToDelete: any,
    childLabelToDelete: string
  ) => void;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  deviationpolicyData: DeviationPolicyInterface[];
  approvalData: Approval[];
}

interface State {
  clickedMenu: string;
  clickedSubMenu: string;
  clickedInstanceIndex: number;
  highlightedYellow: string[];
  highlightedLilac: string[];
  savedParentDataPoints: string[];
  toBeEdited: string;
  editOptionSelected: boolean;
  quickIntel: QuickIntel;
  hideTaskPage: boolean;
  clickedTaskMenu: string;
  childInEditId: number;
  currentEditIsGroupedData: boolean;
  currentEditParentClause: Child[];
  currentEditParentClauseId: number;
  currentEditParentLabel: string;
  deviationData: InsightsInterface[];
  deviationReportOpen: boolean;
}

export default class DeviationPolicy extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clickedMenu: "",
      clickedSubMenu: "",
      highlightedYellow: [],
      highlightedLilac: [],
      clickedInstanceIndex: 0,
      savedParentDataPoints: [],
      toBeEdited: "",
      editOptionSelected: false,
      quickIntel: {
        fileName: "",
        metaData: [],
      },
      hideTaskPage: true,
      clickedTaskMenu: "",
      childInEditId: -1,
      currentEditIsGroupedData: false,
      currentEditParentClause: [],
      currentEditParentClauseId: -1,
      currentEditParentLabel: "",
      deviationData: [],
      deviationReportOpen: false,
    };
  }

  setCurrentTask() {}

  componentDidMount() {
    let contract_id = atob(this.props.fileEncoded);
    let url = SITEAPI + "" + contract_id + "/";
    let metaData = {
      fileName: "",
      metaData: [],
    };
    // AxiosGateWay.get(SITEAPI + "document/meta/" + this.props.fileEncoded)
    AxiosGateWay.get(url)
      .then((result) => {
        if (result.data.length != 0) {
          metaData.fileName = result.data.file_name;
        }
        let parsed = metaData as QuickIntel; //result.data as QuickIntel;
        this.setState({ quickIntel: parsed });
      })
      .catch((error) => {});

    let { saveBiPointDataMode, insightsData } = this.props;

    if (!isNullOrUndefined(this.props.location.state)) {
      this.setState({
        editOptionSelected: true,
        toBeEdited: this.props.location.state.bi,
        clickedMenu: this.props.location.state.clause,
      });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    /* if (nextProps.scrolledChildIndex !== this.props.scrolledChildIndex) {
            this.setState({ clickedInstanceIndex: nextProps.scrolledChildIndex });
        } */
    if (this.props.editLoader !== nextProps.editLoader) {
      this.props.editLoader && this.setState({ editOptionSelected: false });
    }
    if (
      !isNullOrUndefined(this.props.location.state) &&
      this.props.location.state === nextProps.location.state
    ) {
      this.props.history.replace(this.props.location.pathname);
    } /* 
        if(this.props.insightsData !== nextProps.insightsData){
            nextProps.saveBiPointDataMode(resetAllBiPointDataModes(nextProps.insightsData));
        } */
    if (this.props.currentScrollIndex !== nextProps.currentScrollIndex) {
      if (this.state.clickedInstanceIndex !== nextProps.currentScrollIndex) {
        this.setState({
          clickedInstanceIndex: nextProps.currentScrollIndex,
        });
      }
    }
  }

  onClickCreateTask = (name: string, value: string, contractName: string) => {
    let {
      setCurrentTask,
      setTaskPage,
      getAllTasksData,
      fileId,
      setTaskOrigin,
    } = this.props;
    let { hideTaskPage, clickedTaskMenu } = this.state;
    getAllTasksData(fileId.toString(), "0", "", "", false, name);
    setCurrentTask(name, value, contractName);
    setTaskPage(hideTaskPage);
    setTaskOrigin("insight");
  };

  onClickDataPoint = (data: InsightsInterface) => {
    let { saveSelectedInsightPoint } = this.props;
    let { clickedMenu } = this.state;
    saveSelectedInsightPoint(data.label);
    this.scrollToDataPoint(
      "clause",
      data.label,
      clickedMenu === data.label ? [] : getParentHighlights(data.dataPoints)
    );
  };
  handleOpen = () => {
    this.setState({
      deviationReportOpen: true,
    });
  };
  handleClose = () => {
    this.setState({
      deviationReportOpen: false,
    });
  };

  render() {
    let {
      insightsData,
      sentenceLoader,
      setCurrentTask,
      clauseModeStatus,
      deviationpolicyData,
      approvalData,
    } = this.props;
    let { clickedMenu, hideTaskPage, clickedTaskMenu, deviationData } =
      this.state;
    return (
      <div className="col-md-12 table-container" style={{ marginTop: "5rem" }}>
        <div>
          {/* <div className="row">
                        <div className="col-md-9 offset-md-1 my-3 document-insights-heading">
                            <img alt='insights' src='/static_images/document-insights-icn.svg' />&nbsp;
                            <span>Insights</span>
                        </div>
                    </div> */}

          {sentenceLoader ? (
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <LinesLoader
                    animatedLines={[
                      {
                        width: 100,
                        height: 42,
                        marginTop: "5px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                      {
                        width: 100,
                        height: 42,
                        marginTop: "15px",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Scrollable
              maxHeight={
                window.screen.width < 1300
                  ? 650
                  : window.screen.width > 1300 && window.screen.width < 1500
                  ? 750
                  : 580
              }
            >
              <div style={{ marginBottom: "350px" }}>
                {deviationpolicyData.map(
                  (data: DeviationPolicyInterface, i: number) => (
                    <div className="row" key={i}>
                      <div
                        className={
                          clickedMenu === "Deviation in Contract Duration" &&
                          data.label === "Deviation in Contract Duration"
                            ? "col-md-11 toc-row toc-row-active1"
                            : clickedMenu === data.label
                            ? "col-md-11 toc-row toc-row-active1"
                            : "col-md-11 toc-row toc-row-default"
                        }
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              <div
                                className="col-md-10"
                                onClick={() => this.onClickDataPoint(data)}
                              >
                                {i + 1}. {data.label}{" "}
                              </div>

                              <div
                                className="col-md-1"
                                onClick={() => this.onClickDataPoint(data)}
                                style={
                                  window.screen.width < 1300
                                    ? {
                                        marginLeft: "1.7rem",
                                      }
                                    : window.screen.width > 1300 &&
                                      window.screen.width < 1500
                                    ? {
                                        marginLeft: "2rem",
                                      }
                                    : {
                                        marginLeft: "2.2rem",
                                      }
                                }
                              >
                                <img
                                  alt="toggle"
                                  src="/static_images/toggle-icn.svg"
                                  style={{
                                    transform:
                                      clickedMenu === data.label
                                        ? "none"
                                        : "rotate(180deg)",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {clickedMenu === data.label && (
                        <div
                          className="col-md-11"
                          style={{
                            padding: "0px",
                            marginLeft: "12px",
                          }}
                        >
                          <div
                            className="row"
                            id="childDiv"
                            style={{
                              marginLeft: "8px",
                              width: "inherit",
                            }}
                          >
                            <div className="col-md-12">
                              {data.children.map((child, j) => (
                                <div key={j}>
                                  {this.childrenRender(
                                    child,
                                    j,
                                    false,
                                    [],
                                    -1,
                                    "",
                                    data.label,
                                    insightsData,
                                    approvalData
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </Scrollable>
          )}
        </div>
        {clauseModeStatus && (
          <div
            className="col-md-12"
            style={{
              zIndex: 10,
              background: "rgba(0, 0, 0, 0.15)",
              backdropFilter: "blur(2px)",
              position: "absolute",
              top: "-10vh",
              left: "0vw",
              height: "100vh",
              width: "50vw",
            }}
          />
        )}
      </div>
    );
  }

  policyStatementRender(dataType: string) {
    switch (dataType) {
      case "Deviation in Contract Duration":
        // return "Contract Duration";
        return "Term Clause";
      case "Deviation in Auto Renewal":
        // return "Renewal";
        return "Renewal Clause";
      case "Deviation in Change of Control":
        // return "change of control";
        return "Change of Control";
      case "Deviation in Indemnity":
        return "Indemnity";
      case "Deviation in Confidentiality":
        return "Confidentiality";
      default:
        return null;
    }
  }

  policyChildrenRender(approvalData: Approval[], dataType: string) {
    let data_type = this.policyStatementRender(dataType);

    let policyStatement = approvalData
      .filter((policy) => policy.section_tag === data_type)
      .map((policy, i) => {
        return (
          <>
            If in {policy.section_tag}{" "}
            <b>
              {policy.field_name} {policy.conditions}
            </b>{" "}
            {policy.value} {policy.optional_data} then trigger approval from{" "}
            <>
              {policy.approvers.map((approvers, a) => {
                return (
                  <>
                    {a != 0 ? "," : ""}
                    {approvers.username}
                  </>
                );
              })}
            </>
          </>
        );
      });
    return policyStatement;
  }

  policy_statement(approvalData: Approval[], data_type: string) {
    let policy_data = "";
    let policy_approver = "";
    let policyStatement = approvalData
      .filter((policy) => policy.approval_name === data_type)
      .map((policy, i) => {
        policy_data = `If in ${policy.section_tag} ${policy.field_name} ${policy.conditions}
        ${policy.value} ${policy.optional_data} then trigger approval from`;
      });
    return policy_data;
  }

  aboutClauseRender(dataType: string) {
    switch (dataType) {
      case "Deviation in Contract Duration":
        return (
          <>
            Contract duration is a term used to describe the period through
            which a contract is effective. This can also be understood as the
            period between the contract effective date and the contract end date
            - both of which are typically outlined within a contract and will be
            agreed to by both parties.
          </>
        );
      case "Deviation in Auto Renewal":
        return (
          <>
            Auto renewal is a type of clause which enables a contract to be
            renewed automatically at the end of its duration.
          </>
        );
      case "Deviation in Change of Control":
        return (
          <>
            An acquisition of the Company by another entity by means of any
            transaction or series of related transactions (including, without
            limitation, any reorganization, merger or consolidation but
            excluding any merger effected exclusively for the purpose of
            changing the domicile of the Company.
          </>
        );
      case "Deviation in Indemnity":
        return (
          <>
            Indemnity provider's name or how an indemnity provider is described
            in the contract.
          </>
        );
      case "Deviation in Confidentiality":
        return (
          <>
            A non-disclosure agreement is a legal contract or part of a contract
            between at least two parties that outlines confidential material,
            knowledge, or information that the parties wish to share with one
            another for certain purposes, but wish to restrict access to. The
            confidentiality clause is part of the contract.
          </>
        );
      default: {
        return <></>;
      }
    }
  }
  deviationPolicy(
    dataType: string,
    child: Children,
    approvalData: Approval[],
    insightsData: InsightsInterface[]
  ) {
    let valid_contract = 1;
    let valid_policy = 0;
    let approval_flag = "0";
    let comment_flag = "0";
    let approval_policy = {
      comment_flag: "0",
      approval_flag: "0",
    } as PolicyInterface;
    let nanValue = NaN;
    if (dataType == "Deviation in Contract Duration") {
      let data_type = this.policyStatementRender(dataType);
      let policy_data = approvalData.filter(
        (item) => item.section_tag == data_type
      ) as Approval[];
      if (policy_data.length != 0 && child.childValue.length != 0) {
        let approval_duration = policy_data[0].value;
        let approval_optional_data = policy_data[0].optional_data;
        let approval_condition = policy_data[0].conditions;

        if (approval_duration != null && approval_optional_data != null) {
          if (approval_optional_data.toLowerCase().includes("year")) {
            if (parseInt(approval_duration)) {
              approval_duration = (parseInt(approval_duration) * 12).toString();
            } else {
              let wordtonumber: any = wordsToNumbers(approval_duration);
              if (wordtonumber) {
                approval_duration = (parseInt(wordtonumber) * 12).toString();
              } else {
                comment_flag = "1";
              }
            }
          }
        }
        let highlightedText = "";
        if (!isNullOrUndefined(child.childValue[0].dataPoints.term_duration)) {
          highlightedText = child.childValue[0].dataPoints.term_duration; //child.childValue[0].dataPoints.highlightedText;
        }
        var contract_matches = highlightedText.match(/(\d+)/);
        let contract_duration = null;
        let total_month = 0;

        if (contract_matches != null) {
          contract_duration = contract_matches[0];
          if (highlightedText.toLowerCase().includes("years")) {
            total_month = parseInt(contract_matches[0]) * 12;
          }
          if (highlightedText.toLowerCase().includes("month")) {
            total_month = parseInt(contract_matches[0]);
          }
        } else {
          if (highlightedText.includes("year")) {
            let wordNumber = highlightedText.split("year");
            if (wordNumber) {
              wordNumber = wordNumber[0].split(" ");
              if (wordNumber) {
                if (wordNumber[wordNumber.length - 2]) {
                  let wordtonumber: any = wordsToNumbers(
                    wordNumber[wordNumber.length - 2]
                  );

                  if (wordtonumber) {
                    total_month = wordtonumber * 12;

                    if (isNaN(total_month)) {
                      comment_flag = "1";
                    }
                  } else {
                    comment_flag = "1";
                  }
                }
              }
            }
          }
        }

        if (approval_condition != null) {
          if (approval_condition == ">") {
            if (total_month > parseInt(approval_duration)) {
              approval_flag = "1";
            }
          } else if (approval_condition == "<") {
            if (total_month < parseInt(approval_duration)) {
              approval_flag = "1";
            }
          } else if (approval_condition == "=") {
            if (total_month == parseInt(approval_duration)) {
              approval_flag = "1";
            }
          } else if (approval_condition == ">=") {
            if (total_month >= parseInt(approval_duration)) {
              approval_flag = "1";
            }
          } else if (approval_condition == "<=") {
            if (total_month <= parseInt(approval_duration)) {
              approval_flag = "1";
            }
          }
        } else {
          approval_flag = "0";
        }

        approval_policy.comment_flag = comment_flag;
        approval_policy.approval_flag = approval_flag;
      } else {
        approval_policy.comment_flag = "1";
      }
      return approval_policy;
    } else if (dataType == "Deviation in Auto Renewal") {
      let policy_data = approvalData.filter(
        (item) => item.approval_name == "Renewal"
      ) as Approval[];
      if (policy_data.length != 0 && child.childValue.length != 0) {
        let conditions = policy_data[0].conditions;
        let auto_renewal_deviation = "0";
        if (insightsData[2].children[2].childValue.length > 0) {
          auto_renewal_deviation = "1";
        } else if (insightsData[2].children[3].childValue.length > 0) {
          auto_renewal_deviation = "1";
        } else if (insightsData[2].children[4].childValue.length > 0) {
          auto_renewal_deviation = "1";
        } else {
          auto_renewal_deviation = "0";
        }
        if (conditions == "is present" && auto_renewal_deviation == "1") {
          approval_policy.approval_flag = "1";
        } else if (conditions == "is absent" && auto_renewal_deviation == "1") {
          approval_policy.approval_flag = "0";
        } else if (conditions == "is absent" && auto_renewal_deviation == "0") {
          approval_policy.approval_flag = "1";
        } else {
          approval_policy.approval_flag = "0";
        }
      } else {
        approval_policy.comment_flag = "1";
      }
      return approval_policy;
    } else if (dataType == "Deviation in Change of Control") {
      let policy_data = approvalData.filter(
        (item) => item.approval_name == "change of control"
      ) as Approval[];
      if (policy_data.length != 0 && child.childValue.length != 0) {
        let conditions = policy_data[0].conditions;
        if (conditions == "is present") {
          approval_policy.approval_flag = "1";
        } else if (conditions == "is absent") {
          approval_policy.approval_flag = "0";
        } else {
          approval_policy.approval_flag = "0";
        }
      } else {
        approval_policy.comment_flag = "1";
      }
      return approval_policy;
    } else if (dataType == "Deviation in Indemnity") {
      let policy_data = approvalData.filter(
        (item) => item.approval_name == "Indemnity"
      ) as Approval[];
      if (policy_data.length != 0 && child.childValue.length != 0) {
        let value = policy_data[0].value;
        for (var i = 0; i < child.childValue.length; i++) {
          if (child.childValue[i].dataPoints.highlightedText.includes(value)) {
            approval_policy.approval_flag = "1";
            break;
          } else {
            approval_policy.approval_flag = "0";
          }
        }
      } else {
        approval_policy.comment_flag = "1";
      }
      return approval_policy;
    } else if (dataType == "Deviation in Confidentiality") {
      let policy_data = approvalData.filter(
        (item) => item.approval_name == "Confidentiality"
      ) as Approval[];
      if (policy_data.length != 0 && child.childValue.length != 0) {
        let conditions = policy_data[0].conditions;
        let value = policy_data[0].value;

        let conf_flag = 0;
        for (var i = 0; i < child.childValue.length; i++) {
          if (child.childValue[i].dataPoints.highlightedText.includes(value)) {
            conf_flag = 1;
          }
        }
        if (conditions == "does not contain") {
          if (conf_flag == 1) {
            approval_policy.approval_flag = "1";
          } else {
            approval_policy.approval_flag = "0";
          }
        }
        if (conditions == "contains") {
          if (conf_flag == 1) {
            approval_policy.approval_flag = "0";
          } else {
            approval_policy.approval_flag = "1";
          }
        }
      } else {
        approval_policy.comment_flag = "1";
      }
      return approval_policy;
    } else {
      return approval_policy;
    }
  }

  childrenRender(
    child: Children,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string,
    dataType: string,
    insightsData: InsightsInterface[],
    approvalData: Approval[]
  ) {
    let {
      clickedSubMenu,
      clickedInstanceIndex,
      toBeEdited,
      editOptionSelected,
      clickedMenu,
      childInEditId,
      currentEditIsGroupedData,
      currentEditParentClause,
      currentEditParentClauseId,
      currentEditParentLabel,
    } = this.state;
    let {
      fileId,
      editDataPoint,
      saveInsightToDelete,
      durationList,
      currencyList,
    } = this.props;
    let unClickableIndex = unClickableBI.findIndex((el) => {
      return el === child.childLabel;
    });
    let comment_flag = null;
    let approval_flag = null;
    let approval_res = this.deviationPolicy(
      dataType,
      child,
      approvalData,
      insightsData
    );

    comment_flag = approval_res.comment_flag;
    approval_flag = approval_res.approval_flag;

    return (
      <>
        <div
          className={
            clickedSubMenu === child.childLabel
              ? "row toc-content "
              : "row toc-content "
          }
          style={{
            background:
              child.childAlias === "Present" || child.childAlias === "Clauses"
                ? "linear-gradient(90deg, rgba(255, 248, 223, 0.95) 0%, #FFFFFF 98.92%)"
                : omitBIBackground.indexOf(child.childAlias) === -1
                ? ""
                : "",
            marginBottom:
              child.childLabel === "Consent Regulatory"
                ? "1px"
                : child.childLabel ===
                  ("Consent Authority" || "Payment Obligations Nature")
                ? "10px"
                : "",
          }}
          key={child.childLabel}
        >
          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background:
                    "linear-gradient(89.98deg, rgb(255, 241, 253) 0.66%, rgb(255, 255, 255) 99.99%)",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 bi-label">In Policy&nbsp;</div>
                    <div
                      className="col-md-6 bi-name"
                      style={{ fontWeight: "600" }}
                    ></div>
                    <div className="col-md-1 p-0 mt-2"></div>
                    <div
                      className="col-md-1"
                      style={{ margin: "auto", paddingTop: "0.7%" }}
                    ></div>
                    <div className="col-md-1" style={{ margin: "auto" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background: "white",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="col-md-12 bi-label"
                      style={{ fontSize: "13px" }}
                    >
                      <label style={{ fontWeight: "bold" }}>About Clause</label>
                      <br />
                      {this.aboutClauseRender(dataType)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background: "white",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div
                      className="col-md-12 bi-label"
                      style={{ fontSize: "13px" }}
                    >
                      <label style={{ fontWeight: "bold" }}>
                        Policy Statement
                      </label>
                      <br />
                      {this.policyChildrenRender(approvalData, dataType)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background:
                    "linear-gradient(89.98deg, rgb(255, 241, 253) 0.66%, rgb(255, 255, 255) 99.99%)",
                  // "linear-gradient(90deg, rgba(255, 248, 223, 0.95) 0%, rgb(255, 255, 255) 98.92%)",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 bi-label">In Contract&nbsp;</div>
                    <div
                      className="col-md-6 bi-name"
                      style={{ fontWeight: "600" }}
                    ></div>
                    <div className="col-md-1 p-0 mt-2"></div>
                    <div
                      className="col-md-1"
                      style={{ margin: "auto", paddingTop: "0.7%" }}
                    ></div>
                    <div className="col-md-1" style={{ margin: "auto" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <Scrollable maxHeight={250} padding={false}>
              {child.childValue.map((childvalues, j) => (
                <div className="col-md-12" key={`policy${j}`}>
                  <div>
                    <div
                      className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                      style={{
                        background: "white",
                      }}
                    >
                      <div className="col-md-12">
                        <div className="row">
                          <div
                            className="col-md-12 bi-label"
                            style={{ fontSize: "13px" }}
                          >
                            {j + 1}.{" "}
                            {childvalues.dataPoints.highlightedText.substring(
                              0,
                              250
                            )}
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Scrollable>
          </div>

          {child.childValue.length == 0 ? (
            <div className="col-md-12">
              <div>
                <div
                  className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                  style={{
                    background: "white",
                  }}
                >
                  <div className="col-md-12">
                    <div className="row">
                      <div
                        className="col-md-12 bi-label"
                        style={{ fontSize: "13px" }}
                      >
                        Relevant clause not found
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(255, 248, 223, 0.95) 0%, rgb(255, 255, 255) 98.92%)",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 bi-label" style={{}}>
                      Deviation Found&nbsp;
                    </div>
                    <div
                      className="col-md-6 bi-name"
                      style={{
                        fontWeight:
                          (dataType == "Deviation in Contract Duration" &&
                            comment_flag == "1") ||
                          (dataType == "Deviation in Confidentiality" &&
                            comment_flag == "1") ||
                          (dataType == "Deviation in Indemnity" &&
                            comment_flag == "1") ||
                          (dataType == "Deviation in Change of Control" &&
                            comment_flag == "1") ||
                          (dataType == "Deviation in Auto Renewal" &&
                            comment_flag == "1")
                            ? ""
                            : "600",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div
                              className="col-md-8"
                              style={{
                                width: "30vw",
                                textAlign: "right",
                                color: "red",
                              }}
                            >
                              {dataType == "Deviation in Contract Duration" ? (
                                <>
                                  {comment_flag == "1" ? (
                                    "Could not automatically determine approval status"
                                  ) : (
                                    <>{approval_flag == "0" ? "NO" : "YES"}</>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {dataType == "Deviation in Auto Renewal" ? (
                                <>
                                  {comment_flag == "1" ? (
                                    "Could not automatically determine approval status"
                                  ) : (
                                    <> {approval_flag == "0" ? "NO" : "YES"}</>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {dataType == "Deviation in Change of Control" ? (
                                <>
                                  {comment_flag == "1" ? (
                                    "Could not automatically determine approval status"
                                  ) : (
                                    <> {approval_flag == "0" ? "NO" : "YES"}</>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {dataType == "Deviation in Indemnity" ? (
                                <>
                                  {" "}
                                  {comment_flag == "1" ? (
                                    "Could not automatically determine approval status"
                                  ) : (
                                    <>{approval_flag == "0" ? "NO" : "YES"}</>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {dataType == "Deviation in Confidentiality" ? (
                                <>
                                  {comment_flag == "1" ? (
                                    "Could not automatically determine approval status"
                                  ) : (
                                    <>{approval_flag == "1" ? "NO" : "YES"}</>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-1 p-0 mt-2"></div>
                    <div
                      className="col-md-1"
                      style={{ margin: "auto", paddingTop: "0.7%" }}
                    ></div>
                    <div className="col-md-1" style={{ margin: "auto" }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row my-2 align-right">
              <div className="col-md-12 align-right">
                {/* <span
                  className="add-datapoint"
                  style={{ textDecoration: "none", marginLeft: " 8rem" }}
                > */}
                <span
                  className="upload-yellow-btn ml-4"
                  id="save-btn"
                  style={{ pointerEvents: "none" }}
                >
                  Approve
                </span>
                <span
                  className="upload-yellow-btn ml-4"
                  id="save-btn"
                  // style={{ pointerEvents: "none" }}
                  data-toggle="modal"
                  data-target="#deviationPolicyModal"
                  onClick={() => this.handleOpen()}
                >
                  Report
                </span>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="col-md-12">
            <div>
              <div
                className="row toc-content toc-content-default animate__animated animate__fadeInDown"
                style={{
                  background:
                    "linear-gradient(89.98deg, rgb(255, 241, 253) 0.66%, rgb(255, 255, 255) 99.99%)",
                  // "linear-gradient(90deg, rgba(255, 248, 223, 0.95) 0%, rgb(255, 255, 255) 98.92%)",
                }}
              >
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 bi-label">Comment&nbsp;</div>
                    <div
                      className="col-md-9 bi-name"
                      style={{ fontWeight: "600" }}
                    >
                      {comment_flag == "1" ? (
                        <textarea
                          style={{ width: "100%", margin: "10px" }}
                          key={key}
                          defaultValue={
                            "Please verify Contract Language manually"
                          }
                        ></textarea>
                      ) : (
                        <>
                          <textarea
                            style={{ width: "100%", margin: "10px" }}
                            key={key}
                            defaultValue={""}
                          ></textarea>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ____new design__end___*/}
        </div>
        {/* _____________ */}
      </>
    );
  }

  goToEditFeature(
    toBeEdited: string,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string
  ) {
    this.props.setCurrentScrollIndex(0);
    this.scrollToChildDataPoint("bi", toBeEdited, [], 0, true);
    this.setState({
      toBeEdited: toBeEdited,
      editOptionSelected: true,
      childInEditId: key,
      currentEditIsGroupedData: isGroupedData,
      currentEditParentClause: parentClause,
      currentEditParentClauseId: parentClauseId,
      currentEditParentLabel: parentLabel,
    });
  }

  /*  getNextChild(child: Children) {
         let { clickedInstanceIndex } = this.state;
         if (clickedInstanceIndex !== child.childValue.length - 1) {
             let index = clickedInstanceIndex + 1;
             this.props.superImposeChildIndex(index);
             this.setState({ clickedInstanceIndex: index });
             this.scrollToChildDataPoint('bi', child.childLabel, getChildHighlights(child.childValue), index, false)
         }
     }
 
     getPrevChild(child: Children) {
         let { clickedInstanceIndex } = this.state;
         if (clickedInstanceIndex !== 0) {
             let index = clickedInstanceIndex - 1;
             this.props.superImposeChildIndex(index);
             this.setState({ clickedInstanceIndex: index });
             this.scrollToChildDataPoint('bi', child.childLabel, getChildHighlights(child.childValue), index, false)
         }
     } */

  setClickedItem(clickedItem: string, type: string, unselectChild: boolean) {
    let {
      highlightedYellow,
      highlightedLilac,
      clickedMenu,
      clickedSubMenu,
      clickedInstanceIndex,
    } = this.state;
    if (clickedItem === "clause") {
      this.setState({ clickedSubMenu: "", clickedInstanceIndex: 0 });
      clickedMenu === type
        ? this.setState({ clickedMenu: "" })
        : this.setState({ clickedMenu: type });
      for (let i = 0; i < highlightedYellow.length; i++) {
        //For clearing highlighted background color
        let clauseHeader = document.getElementById(highlightedYellow[i]);
        if (!isNullOrUndefined(clauseHeader)) {
          clauseHeader.style.background = "none";
        }
      }
      for (let i = 0; i < highlightedLilac.length; i++) {
        for (let j = 0; j < highlightedLilac[i].length; j++) {
          let biHeader = document.getElementById(highlightedLilac[i][j]);
          if (!isNullOrUndefined(biHeader)) {
            biHeader.style.background = "none";
          }
        }
      }
    } else if (clickedItem === "bi") {
      // this.setState({ clickedInstanceIndex: 0 });
      if (unselectChild) {
        clickedSubMenu === type
          ? this.setState({ clickedSubMenu: "" })
          : this.setState({ clickedSubMenu: type });
      } else {
        clickedInstanceIndex >= 0 && this.setState({ clickedSubMenu: type });
      }
      let index = highlightedYellow.findIndex((el) => {
        return el == highlightedLilac[0];
      });
      if (index !== -1) {
        //When parent and child both highlight same set of datapoints, highlight parent again when child is unclicked.
        for (let i = 0; i < highlightedYellow.length; i++) {
          let paraHeader = document.getElementById(highlightedYellow[i]);
          if (!isNullOrUndefined(paraHeader)) {
            paraHeader.style.background = highlight_yellow;
          }
        }
      } else {
        for (let i = 0; i < highlightedLilac.length; i++) {
          for (let j = 0; j < highlightedLilac[i].length; j++) {
            let biHeader = document.getElementById(highlightedLilac[i][j]);
            if (!isNullOrUndefined(biHeader)) {
              biHeader.style.background = "none";
            }
          }
        }
      }
    }
    return clickedItem;
  }

  scrollToDataPoint(insight: string, type: string, dataPoints: string[]) {
    this.props.superImposeChildIndex(0);
    let typeClicked = this.setClickedItem(insight, type, false); //Set clicked menu or submenu tempArray.push('p' + paraId[i]);
    let paraHeader = document.getElementById(dataPoints[0]);
    !isNullOrUndefined(paraHeader) &&
      paraHeader.scrollIntoView({ block: "center" });
    if (dataPoints.length > 0) {
      this.setState({ highlightedYellow: dataPoints });
      simulateScroll(dataPoints, typeClicked);
      this.props.saveDataPoints(dataPoints, true, 0, "clause");
      this.props.setCurrentScrollIndex(0);
    } else {
      this.setState({ highlightedYellow: [] });
      this.props.saveDataPoints(dataPoints, false, 0, "clause");
      this.props.setCurrentScrollIndex(0);
    }
    this.setState({ savedParentDataPoints: dataPoints });
  }

  scrollToChildDataPoint = (
    insight: string,
    type: string,
    children: AuxArrayInterface[],
    index: number,
    unselectChild: boolean
  ) => {
    let { savedParentDataPoints } = this.state;
    let aggregateDataPoints = createChildDataPointsAggregate(children);
    let typeClicked = this.setClickedItem(insight, type, unselectChild);
    let tempParaIndex = -1;
    let tempParaId = "";
    let paraHeader = document.getElementById(savedParentDataPoints[0]);
    !isNullOrUndefined(paraHeader) &&
      paraHeader.scrollIntoView({ block: "center" });
    let aggregateDataArray: string[] = [];
    if (children.length > 0) {
      let multipleInstances: AuxArrayInterface[] = [];
      multipleInstances =
        type === "Termination at Convenience" ||
        disputeResolutionModeBi.indexOf(type) > -1
          ? detectMultipleInstances(children)
          : children;
      let paraHeader = document.getElementById(
        multipleInstances[index].dataPoints[index]
      );
      !isNullOrUndefined(paraHeader) &&
        paraHeader.scrollIntoView({ block: "center" });
      this.setState({ highlightedLilac: aggregateDataPoints });
      simulateScroll(aggregateDataPoints, typeClicked);

      //For making scroll button act according to the child data point index in parent array. //Experimental
      tempParaId = children[index].dataPoints[0];
      for (let i = 0; i < aggregateDataPoints.length; i++) {
        aggregateDataArray.push(aggregateDataPoints[i][0]);
      }
      tempParaIndex = aggregateDataArray.findIndex((el) => {
        return el === tempParaId;
      });
    }
    this.props.saveDataPoints(
      aggregateDataArray.length === 0
        ? savedParentDataPoints
        : aggregateDataArray,
      true,
      tempParaIndex !== -1 ? tempParaIndex : 0,
      aggregateDataArray.length === 0 ? "clause" : "bi"
    );
    if (!isNullOrUndefined(tempParaId) && !(tempParaId === "")) {
      let paraHeader = document.getElementById(tempParaId);
      !isNullOrUndefined(paraHeader) &&
        paraHeader.scrollIntoView({ block: "center" });
    }
  };
}
