import {
  // Chip,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  // makeStyles,
  MenuItem,
  OutlinedInput,
  // Paper,
  Select,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  appliedFilters,
  FilterApplied,
  FilteredContract,
  FilterPayload,
  SavedFilters,
} from "../State";
import SaveFilterDialog from "./SaveFilterDialog";
import { AiOutlineEnter } from "react-icons/ai";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import { FilterSet, getSelectedFilter, CurrencyTypes } from "../Components/Utils/docUtils";

interface Props {
  results: FilteredContract[];
  count: number;
  filterPayload: FilterPayload;
  filterApplied: FilterApplied;
  selected_filter: appliedFilters[];
  removeChip: (index: number) => void;
  savedfilters: SavedFilters[];
  getSavedFilterList: () => void;
  chooseSavedFilter: (saved_filter: any) => void;
  selective_filter: any[];
  removeSelectiveChip: (index: number) => void;
  filterlabel: string;
  selected_checkboxItem:any;

  non_compete_duration_check:any;
  non_solicitation_duration_check:any;
  confidentiality_duration_check:any;
  cexcept_check:any;
  capped_check:any;
  currency:any;
}

interface ChipData {
  key: number;
  label: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    marginBottom: "25px",
    "& .MuiChip-deleteIcon": {
      backgroundColor: "#FFEEF7",
      color: "#88305F",
      fontWeight: 600,
    },

    "& .MuiChip-root:focus": {
      backgroundColor: "none",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: "#FFEEF7",
    color: "#88305F",
    fontWeight: 600,
    "& .MuiChip-root:focus": {
      backgroundColor: "none",
    },
  },
}));

const FilterList = (props: Props) => {
  const [openSaveFilter, setopenSaveFilter] = useState(false);
  const [searchText, setsearchText] = useState("");

  const [selectedSavedfilter, setselectedSavedfilter] = useState<SavedFilters>({
    id: "saved_filters",
    name: "Saved Filter Sets",
    saved_data: [],
    creation_type: "",
  });
  const [selected_filter, setselected_filter] = useState("");
  const [saved_type, setsaved_type] = useState("save");
  const [label, setlabel] = useState("Save Filter Set");
  const [saved_filter_name, setsaved_filter_name] = useState("");
  const classes = useStyles();

  const handleClose = () => {
    setopenSaveFilter(false);
  };

  const openSaveFilterDialog = () => {
    setopenSaveFilter(true);
  };

  const handleSearch = (event: any) => {
    let search_text = event.target.value;
    setsearchText(search_text.toLowerCase());
  };

  const chipDelete = (chipToDelete: { key: number }) => () => {
    props.removeChip(chipToDelete.key);
  };

  const chipDeleteSelectiveFilter = (chipToDelete: { key: string }) => () => {
    let index = props.selective_filter.findIndex(
      (obj) => obj.filterType === chipToDelete.key
    );
    props.removeSelectiveChip(index)
  };
  return (
    <>
      {props.filterlabel == "Filter Applied" ?
        <div className="filter-list-container" key="filterlist">
          {/* {props.results.length == 0 ? null : */}
          <div className="search-bar mb-2">
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Search in Contracts
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                onChange={handleSearch}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSearch}
                      onMouseDown={handleSearch}
                      edge="end"
                    >
                      <AiOutlineEnter size={25} />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search in Contracts"
              />
            </FormControl>
          </div>
          {/* } */}
          {props.selected_filter.length > 0 || props.selective_filter.length > 0 ? (
            <Paper
              component="ul"
              className={classes.root}
              elevation={0}
              style={{ backgroundColor: "unset" }}
              key="saved_results"
            >
              {
                props.selective_filter.map((values, index) => {
                  return (<>
                    {values['filterType'] == "start_date" ? <li key={`start-date-${index}`}>
                      <Chip
                        label={
                          `Term Clause(Start Date: ${values['min']} - ${values['max']})`
                        }
                        disabled={false}
                        onDelete={chipDeleteSelectiveFilter({ key: values['filterType'] })}
                        className={classes.chip}
                        deleteIcon={<CloseIcon />}
                        key={values['filterType']}
                      />
                    </li> : null}
                    {values['filterType'] == "end_date" ? <li key={`start-date-${index}`}>
                      <Chip
                        label={
                          `Term Clause(End Date: ${values['min']} - ${values['max']})`
                        }
                        disabled={false}
                        onDelete={chipDeleteSelectiveFilter({ key: values['filterType'] })}
                        className={classes.chip}
                        deleteIcon={<CloseIcon />}
                        key={values['filterType']}
                      />
                    </li> : null}
                    {values['filterType'] == "payment" ? <li key={`start-date-${index}`}>
                      <>
                      <Chip
                        label={
                          `Payment Obligation(${props.currency.filter((option:any) =>option.currencyType === values['currency_type'])[0]["currencyName"]}: ${values['min']} - ${values['max']})`
                        }
                        disabled={false}
                        onDelete={chipDeleteSelectiveFilter({ key: values['filterType'] })}
                        className={classes.chip}
                        deleteIcon={<CloseIcon />}
                        key={values['filterType']}
                      />
                      </>
                    </li> : null}
                  </>)
                })
              }
              {props.selected_filter.map((data, index) => {
                return (
                  <li key={`list-${data.id}-${data.filterType}`}>
                    <Tooltip
                      title={data.name}
                      placement="right-start"
                    >
                      <Chip
                        label={
                          data.name != "Yes" && data.name != "No"
                            ? `${FilterSet[data.filterType]} | ${getSelectedFilter(data.name)}`
                            : `${FilterSet[data.filterType]} | ${getSelectedFilter(data.name)}`
                        }
                        disabled={false}
                        onDelete={chipDelete({ key: index })}
                        className={classes.chip}
                        deleteIcon={<CloseIcon />}
                      // key={`listchips-${data.id}-${data.filterType}`}
                      />
                    </Tooltip>
                  </li>
                );
              })}
            </Paper>
          ) : null}
          <div className="row mb-2">
            <div className="col-md-4">
              <span className="fw">
                {
                  props.results.filter((option) =>
                    option.title.toLowerCase().includes(searchText)
                  ).length
                }{" "}
                documents in the system
              </span>
            </div>
            <div className="col-md-8">
              <div className="d-flex flex-row-reverse bd-highlight">
                <div className="">
                  <button
                    onClick={openSaveFilterDialog}
                    type="button"
                    className="btn btn-warning create-btn fw"
                    style={{
                      paddingTop: "9px",
                      paddingBottom: "9px",
                    }}
                  >
                    <img alt="SaveFilter" src="/static_images/savefilter.svg" />
                    &nbsp; {label}
                    {/* {selectedSavedfilter.id == "saved_filters" ? "Save Filter Set" : "Amend Filter Set"} */}
                  </button>
                </div>
                <div className="" style={{ marginRight: "12px", width: "30%" }}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth={true}
                      onChange={(event: any) => {
                        setselected_filter(event.target.value);
                      }}
                      value={selectedSavedfilter.name}
                    >
                      {/* {saved_type == "edit" || saved_type == "saveas" ? null : ( */}
                      <MenuItem
                        key="saved_filter_id"
                        value="Saved Filter Sets"
                        onClick={() => {
                          setselectedSavedfilter({
                            id: "",
                            name: "Saved Filter Sets",
                            saved_data: [],
                            creation_type: "",
                          });
                          setsaved_type("save");
                          setlabel("Save Filter Set");
                          setsaved_filter_name("");
                        }}
                      >
                        Saved Filter Sets{" "}({props.savedfilters.length})
                      </MenuItem>
                      {/* )} */}

                      {props.savedfilters.map((item, key) => (
                        <MenuItem
                          key={item.id}
                          value={item.name}
                          onClick={() => {
                            if (item.creation_type == "custom") {
                              setsaved_type("edit");
                              setlabel("Amend Filter Set");
                            } else if (item.creation_type == "system") {
                              setsaved_type("saveas");
                              setlabel("Save as Custom Filter Set");
                            } else {
                            }

                            setsaved_filter_name(item.name);
                            setselectedSavedfilter(item);

                            props.chooseSavedFilter(item.saved_data);
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" row mb-2 justify-content-between"
            style={{ display: "none" }}
          >
            <div className="col-md-8">
              <span className="fw">
                {
                  props.results.filter((option) =>
                    option.title.toLowerCase().includes(searchText)
                  ).length
                }{" "}
                documents in the system
              </span>
            </div>
            <div className="row col-md-4 ml-auto">
              <div className="col-md-7">
                <FormControl fullWidth size="small" variant="outlined">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    fullWidth={true}
                    onChange={(event: any) => {
                      setselected_filter(event.target.value);
                    }}
                    value={selectedSavedfilter.name}
                  >
                    {saved_type == "edit" || saved_type == "saveas" ? null : (
                      <MenuItem
                        key="saved_filter_id"
                        value="Saved Filter Sets"
                        onClick={() => {
                          setselectedSavedfilter({
                            id: "",
                            name: "Saved Filter Sets",
                            saved_data: [],
                            creation_type: "",
                          });
                          setsaved_type("save");
                          setlabel("Saved Filter Sets");
                          setsaved_filter_name("");
                        }}
                      >
                        Saved Filter Sets{" "}({props.savedfilters.length})
                      </MenuItem>
                    )}

                    {props.savedfilters.map((item, key) => (
                      <MenuItem
                        key={item.id}
                        value={item.name}
                        onClick={() => {
                          if (item.creation_type == "custom") {
                            setsaved_type("edit");
                            setlabel("Amend Filter Sets");
                          } else if (item.creation_type == "system") {
                            setsaved_type("saveas");
                            setlabel("Save as Custom Filter Set");
                          } else {
                          }

                          setsaved_filter_name(item.name);
                          setselectedSavedfilter(item);
                          props.chooseSavedFilter(item.saved_data);
                        }}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-5">
                <button
                  onClick={openSaveFilterDialog}
                  type="button"
                  className="btn btn-warning create-btn fw"
                  style={{
                    float: "right",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  <img alt="SaveFilter" src="/static_images/savefilter.svg" />
                  &nbsp; {label}
                  {/* {selectedSavedfilter.id == "saved_filters" ? "Save Filter Set" : "Amend Filter Set"} */}
                </button>
              </div>
            </div>
          </div>
          {props.results.length == 0 ? <div className="filter-background">
            <img
              alt="filter-background"
              src="static_images/filter-no-results.svg"
            />
          </div> : <> <div className="row list-view-header filter-list-header">
            <div className="col-md-5 fw">Title</div>
            <div className="col-md-2 fw">Start Date</div>
            <div className="col-md-5 fw">Parties</div>
          </div>
          {props.results
              .filter((option) => option.title.toLowerCase().includes(searchText)).length==0?<div className="filter-background"
              style={{height:"50vh"}}
              >
              <img
                alt="filter-background"
                src="static_images/filter-no-results.svg"
              />
            </div>:null}
            {props.results
              .filter((option) => option.title.toLowerCase().includes(searchText))
              .map((result) => (
                //   {props.results.map((result) => (
                <div
                  className="template-list-view"
                  style={{
                    boxShadow: "0px 1px 1px 1px #eaeaea",
                    backgroundColor: "white",
                    marginBottom: "1px",
                  }}
                  key={result.contract_id}
                >
                  <div className="row template-list-view-table filter-table">
                    <div
                      style={{ fontWeight: "700" }}
                      className="col-md-5 text-purple"
                      onClick={() => {
                        window.open(
                          "/document/documentlibrary/" + btoa(result.contract_id),
                          "_blank"
                        );
                      }}
                    >
                      {result.title}
                    </div>
                    <div className="col-md-2">{result.start_date}</div>
                    <div className="col-md-5 ">
                      <Tooltip title={result.parties.join(", ")} arrow>
                        <div className="text-overflow">{result.parties}</div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))}</>}


          <SaveFilterDialog
            handleClose={handleClose}
            open={openSaveFilter}
            selected_filter={props.selected_filter}
            search_text={searchText}
            filterPayload={props.filterPayload}
            getSavedFilterList={props.getSavedFilterList}
            key="savefilter-dialog"
            savedType={saved_type}
            savedFilterName={saved_filter_name}
            selectedSavedfilter={selectedSavedfilter}
            label={label}
            updateMenuItem={(value: string) => {
              setselectedSavedfilter({ ...selectedSavedfilter, name: value });
            }}
            selective_filter={props.selective_filter}
            selected_checkboxItem={props.selected_checkboxItem}
            non_compete_duration_check={props.non_compete_duration_check}
            non_solicitation_duration_check={props.non_solicitation_duration_check}
            confidentiality_duration_check={props.confidentiality_duration_check}
            cexcept_check={props.cexcept_check}
            capped_check={props.capped_check}
          />
        </div> :
        <div className="filter-list-container" key="filterlist">
          {props.count ? <div className="row mb-2">
            <div className="col-md-4">
              <span className="fw">
                {
                  props.count
                }{" "}
                documents in the system
              </span>
            </div>
            <div className="col-md-8">
              <div className="d-flex flex-row-reverse bd-highlight">

                <div className="" style={{ marginRight: "12px", width: "30%" }}>
                  <FormControl fullWidth size="small" variant="outlined">
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth={true}
                      onChange={(event: any) => {
                        setselected_filter(event.target.value);
                      }}
                      value={selectedSavedfilter.name}
                    >
                      {/* {saved_type == "edit" || saved_type == "saveas" ? null : ( */}
                      <MenuItem
                        key="saved_filter_id"
                        value="Saved Filter Sets"
                        onClick={() => {
                          setselectedSavedfilter({
                            id: "",
                            name: "Saved Filter Sets",
                            saved_data: [],
                            creation_type: "",
                          });
                          setsaved_type("save");
                          setlabel("Save Filter Set");
                          setsaved_filter_name("");
                        }}
                      >
                        Saved Filter Sets{" "}({props.savedfilters.length})
                      </MenuItem>
                      {/* )} */}

                      {props.savedfilters.map((item, key) => (
                        <MenuItem
                          key={item.id}
                          value={item.name}
                          onClick={() => {
                            if (item.creation_type == "custom") {
                              setsaved_type("edit");
                              setlabel("Amend Filter Set");
                            } else if (item.creation_type == "system") {
                              setsaved_type("saveas");
                              setlabel("Save as Custom Filter Set");
                            } else {
                            }

                            setsaved_filter_name(item.name);
                            setselectedSavedfilter(item);

                            props.chooseSavedFilter(item.saved_data);
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div> : null}

          <div className="filter-background">
            <img
              alt="filter-background"
              src="static_images/filter_background.svg"
            />
          </div>
        </div>
      }
    </>

  );
};

export default FilterList;
