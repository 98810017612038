import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Popover,
} from "@material-ui/core";
import React, { Component, createRef, useRef } from "react";
import FilterInputModal from "../../../UniversalComponents/Filter/filterInputModal";
import { FilterData } from "../DataDictionary";
interface Props {
  data: any;
  sorting: any;
  setTableData: any;
  isAdmin: boolean;
  applyFilter: (a: string[], b: string) => void;
  filterKeyVals: FilterData;
  userList: string[];
  filteredList: any[];
  getData: any;
}

interface State {
  isCreated: boolean;
  lastEdited: string;

  filterKeyVals: any;
  filterControl: any;

  updatedProjectList: string[];
  sortType: string;
  contentType: string;
  isContentType: boolean;
}

export default class DataDictionaryHeader extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCreated: false,
      lastEdited: "",

      filterKeyVals: [],
      filterControl: [],

      updatedProjectList: [],
      sortType: "ASC",
      contentType: "",
      isContentType: false,
    };
  }
  lastEditedRef: any = createRef();
  contentType: any = createRef();

  onSearch = (data: any, keyword: any, type: string) => {
    if (keyword === "") {
      this.props.getData();
    }
    if (type === "Display Name") {
      this.setState({ contentType: keyword });
      const outputData = data?.filter((item: any) =>
        item.displayName.toLowerCase().includes(keyword.toLowerCase())
      );
      this.props.setTableData([...outputData]);
    } else {
      this.setState({ lastEdited: keyword });
      if (keyword.length > 0) {
        const outputData = data?.filter((item: any) => {
          return item.last_edited_by
            ? item?.last_edited_by?.username
                ?.toLowerCase()
                ?.includes(keyword?.toLowerCase())
            : item?.created_by?.username
                ?.toLowerCase()
                ?.includes(keyword?.toLowerCase());
        });
        this.props.setTableData([...outputData]);
      } else {
        this.props.setTableData([...data]);
      }
    }
  };

  render() {
    const { filterKeyVals, applyFilter, userList } = this.props;
    return (
      <div className="row clause-library-header-section m-0">
        <div className="col-md-2">
          <label className={"cursor-pointer"}>Contract Type</label>
          <img
            onClick={() => {
              this.props.sorting(
                this.props.filteredList,
                this.state.sortType,
                true
              );
              if (this.state.sortType === "ASC") {
                this.setState({ sortType: "DESC" });
              } else {
                this.setState({ sortType: "ASC" });
              }
            }}
            className=" cursor-pointer ml-2"
            src="/static_images/section-title.svg"
            alt="section-title"
          />
        </div>
        <div className="col-md-2 position-relative" ref={this.contentType}>
          Display Name
          <img
            onClick={() => {
              this.setState({ isContentType: !this.state.isContentType });
            }}
            className="ml-2"
            src="/static_images/section-search.svg"
            alt="section-title"
          />
          {this.state.isContentType ? (
            <PopOver
              fieldRef={this.contentType}
              closePop={() => this.setState({ isContentType: false })}
              onSearch={this.onSearch}
              value={this.state.contentType}
              type={"Display Name"}
              data={this.props.filteredList}
            />
          ) : null}
        </div>
        <div className="col-md-2 position-relative" ref={this.lastEditedRef}>
          Last Edited By
          <img
            onClick={() => {
              this.setState({ isCreated: !this.state.isCreated });
            }}
            className="ml-2"
            src="/static_images/section-tags.svg"
            alt="section-created"
          />
          {this.state.isCreated && (
            <FilterInputModal
              filterKey="users"
              filterKeyVals={filterKeyVals}
              open={this.state.isCreated}
              anchorEl={this.lastEditedRef.current}
              handleClose={() => this.setState({ isCreated: false })}
              applyFilter={applyFilter}
              list={userList}
              selectedItem={filterKeyVals["users"]}
            />
          )}
        </div>
        <div
          className={`${
            this.props.isAdmin ? "col-md-2 " : "col-md-3 "
          } position-relative`}
        >
          Last Edited On
          <img
            onClick={() => {
              this.props.sorting(
                this.props.filteredList,
                this.state.sortType,
                false
              );
              if (this.state.sortType === "ASC") {
                this.setState({ sortType: "DESC" });
              } else {
                this.setState({ sortType: "ASC" });
              }
            }}
            className=" cursor-pointer ml-2"
            src="/static_images/section-modified.svg"
            alt="section-source"
          />
        </div>
        <div className={`${this.props.isAdmin ? "col-md-2" : "col-md-3"} p-0`}>
          Used In
          {/* <img
            onClick={() => {}}
            className="ml-2"
            src="/static_images/number_filter_inactive.svg"
            alt="section-modified"
          /> */}
        </div>
        {this.props.isAdmin && <div className="col-md-2 pl-4">Actions</div>}
      </div>
    );
  }
}

export const PopOver = (props: any) => {
  return (
    <Popover
      elevation={0}
      anchorEl={props.fieldRef?.current}
      open={true}
      onClose={() => props.closePop()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="filter">
        <FormControl className="mb-2" fullWidth size="small" variant="outlined">
          <OutlinedInput
            placeholder={`Search ${props.type}`}
            id="outlined-adornment-password"
            value={props.value}
            onChange={(e) =>
              props.onSearch(props.data, e.target.value, props.type)
            }
            endAdornment={<InputAdornment position="end"></InputAdornment>}
          />
        </FormControl>
      </div>
    </Popover>
  );
};
