import * as React from "react";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { Box, Snackbar, Tab, Tabs } from "@material-ui/core";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

interface Props {
  openchildren: boolean;
  supporttitle: string;
  selectedfile: any;
  handleclose: any;
}
const Uploadsupporttypeselect: React.FC<Props> = (props: Props) => {
  const [btnvisible,setbtnVisible]=React.useState<any>([]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const selecttypedocument = [
    "Invoice",
    "Purchase Order",
    "Receipt",
    "Correspondence",
    "Approval/Licenses",
    "Litigation Papers",
    "GST / Trade Docs",
    "Reports",
    "Checklist",
  ];
  const handleSelect = (val: any, data: any,index:any) => {
    const arr=[...btnvisible]
    const Dataindex=arr.findIndex((val:any)=>val.id==index)
    if(Dataindex>-1){
      arr[Dataindex]={
        id:index,
        item:val.target.value
      }
      setbtnVisible(arr)
    }else{
      setbtnVisible([...btnvisible,{id:index,item:val.target.value}])
    }
    AxiosGateWay.patch(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/${data.id}/`,
      {
        support_document_type: val.target.value,
      }
    )
      .then((response) => {
        console.log(response, "aaaaaaaaafcdhgsvhvdhjs");
        // this.setState({
        //   archiveData: response.data.results,
        // });
      })
      .catch((error) => {
        console.log(error);
      });

  };

  return (
    <>
      <Modal
        open={props.openchildren}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ width: "996px", height: "482px" }}>
          <div className="mb-2 d-flex justify-content-between">
            <span> {props.supporttitle}</span>
            <span className="mr-0" onClick={() => {
              props.handleclose()
              setbtnVisible([])
              }}>
              <img
                src={"/static_images/cross-cancel-icn.svg"}
                alt=""
                style={{
                  height: "24px",
                  width: "24px",
                  color: "#818181",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
          <Typography
            id="modal-modal-title"
            // variant="h6"
            component="div"
            //   onDrop={onFileDrop}
            //   onDragEnter={onDragEnter}
            //   onDragLeave={onDragLeave}
            style={{
              width: "100%",
              height: "325px",
              border: "1px dashed #D9D9D9",
              background: "rgba(217, 217, 217, 0.25)",
              // borderColor: highlight ? "blue" : "#d9d9d9",
              // overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            <div className="container">
              <div className="row mt-3">
                {props.selectedfile?.map((i: any,index:any) => {
                  return (
                    <>
                      <div className="col-4">
                        <p
                          style={{
                            color: "#88305F",
                            fontWeight: "700",
                            fontSize: "14px",
                            fontFamily: "Inter",
                          }}
                        >
                          {i?.file_name}
                        </p>
                      </div>
                      <div className="col-4">
                        <select
                          style={{ width: "60%" }}
                          className="custom-Select"
                          onChange={(val: any) => handleSelect(val, i,index)}
                        >
                          <option value={""}>Select</option>
                          {selecttypedocument?.map((i: any) => {
                            return (
                              <>
                                <option value={i}>{i}</option>
                              </>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-4 text-right">
                        <img
                          // onClick={() =>
                          //   displayDeleteModal(file.name, file.hash)
                          // }
                          style={{
                            cursor: "pointer",
                            height: "16px",
                            width: "16px",
                          }}
                          src="/static_images/Delete_upload.svg"
                          alt="delete-icn"
                        />
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Typography>

          <div className="row mt-4">
            <div className="uploadedby-style-upload col-md-12 justify-content-end">
              <div className="col-md-5">
                <div className="d-flex">
                  <form encType="multipart/form-data">
                    <input
                      style={{ visibility: "hidden" }}
                      name="file"
                      id="file-input"
                      type="file"
                      // ref={inputref}
                      className="form-control upload-yellow-btn"
                      multiple
                      accept=".pdf,.doc,.docx,.txt"
                      // onChange={(e: any) =>
                      // //   handleOnChangeFileUpload(e.target.files)
                      // }
                    />
                  </form>
                </div>
              </div>
              <div className="col-md-5 d-flex justify-content-end">
                {props.selectedfile?.length == btnvisible?.length ?
                <button
                  type="button"
                  className="btn btn-warning navigate-btn"
                  onClick={()=>{
                    setbtnVisible([])
                   props.handleclose()
                  }}
                >
                  Finish
                </button>
                :
                  ""
              }
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Uploadsupporttypeselect;
