import React, { Component, useState } from "react";
import { History } from "history";
import SectionListHeader from "./SectionList/SectionListHeader";
import SectionListView from "./SectionList/SectionListView";
import CreateNewClauseModal from "../../UniversalComponents/Modals/ClauseSectionModal/CreateNewClauseModal";
import EditClauseModal from "../../UniversalComponents/Modals/ClauseSectionModal/EditClauseModal";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import {
  SectionClauseInfo,
  SectionDictionaryInfo,
} from "../State/clauseLibraryState";
import { getProjects, getContractsTags } from "../../Draft/DA";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import ClauseLibraryActionGenerator from "../Actions/gen";
import { put } from "redux-saga/effects";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import SelectSection from "../../UniversalComponents/Modals/ClauseSectionModal/SelectSection";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import AddTag from "../../DocumentLibrary/Component/AddTag";
import AddTagClause from "../../UniversalComponents/Modals/ClauseSectionModal/AddTags";
import { FilterData } from "../../Templates/State/templateState";
import PageNavigation from "../../UniversalComponents/PageNavigation/Component/PageNavigation";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
import WarningPopup from "../../Draft/Component/WarningPopup";
import ClauseLibraryAPI from "../Actions/API";
import SimilarClauseModal from "../../UniversalComponents/Modals/ClauseSectionModal/SimilarClause";

interface Props {
  pageWatcher: (page: string) => void;
  sectionDictionaryData: SectionDictionaryInfo[];
  history: History;
  clauseLibraryLoader: boolean;
  sectionClauseList: SectionClauseInfo[];
  updateSectionClauseList: SectionClauseInfo[];
  getSectionDictionary: () => void;
  getSectionClauseData: (page: number, type: string) => void;
  updateSectionClauseSuccess: (output: any) => void;
  createSectionClause: (
    section_name: string,
    clause_name: string,
    clause_type: string,
    clause_source: string,
    clause_text: string,
    description: string,
    creation_type: string,
    tags: string
  ) => void;
  totalCount: number;
}

interface State {
  showCreateModal: boolean;
  showAddFolderOption: boolean;
  folderNameText: string;
  selectedFolderId: string;
  selectedFolderName: string;
  projectData: [];
  showEditModal: boolean;
  selectedClauseData: string;
  clauseMode: string;
  editClauseData: string;
  checkedSection: string[];
  checkedClause: string[];
  checkedTitle: string[];
  isSort: boolean;
  isMove: boolean;
  isCopy: boolean;
  addTag: boolean;
  filterKeyVals: FilterData;
  tags: string[];
  pageNumber: number;
  tabValue: number;
  showWarning: boolean;
  isSimilar: boolean;
  type: string;
  clause_id: string[];
}

export default class ClauseLibrary2 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCreateModal: false,
      showAddFolderOption: false,
      folderNameText: "",
      selectedFolderId: "",
      selectedFolderName: "",
      projectData: [],
      showEditModal: false,
      selectedClauseData: "",
      clauseMode: "add",
      editClauseData: "",
      checkedSection: [],
      checkedClause: [],
      isSort: false,
      checkedTitle: [],
      isMove: false,
      isCopy: false,
      addTag: false,
      filterKeyVals: {},
      tags: [],
      pageNumber: 1,
      tabValue: 0,
      showWarning: false,
      isSimilar: false,
      type: "system",
      clause_id: [],
    };
  }

  selectedClause = (data: any) => {
    this.setState({ selectedClauseData: data });
    this.setState({ showEditModal: true });
  };

  getProjectData = async () => {
    try {
      let response = await getContractsTags();
      if (response && response.results.length > 0) {
        this.setState({ projectData: response.results });
        let uniqueTags: string[] = Array.from(
          new Set(response.results.map((tag: any) => tag.name))
        );
        this.setState({ tags: uniqueTags });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // setRows = (data: any, creationType: string) => {
  //   const defaultData = data.map((item: any, index: number) => {
  //     return item[`${Object.keys(item)[0]}`].filter((element: any) => {
  //       return element?.creation_type
  //         ? element?.creation_type === creationType
  //         : creationType == "system";
  //     });
  //   });
  //   const newData = defaultData.filter((item: any) => item.length);

  //   const creationData = newData.reduce((accumulator: any, current: any) => {
  //     return [
  //       ...accumulator,
  //       {
  //         [current[0]?.section_name?.section_name]: [...current],
  //         modified_on: current[0]?.created_on,
  //       },
  //     ];
  //   }, []);
  //   this.setState({ isSort: true });
  //   this.props.updateSectionClauseSuccess(creationData);
  // };

  componentDidMount() {
    let { pageWatcher } = this.props;
    pageWatcher("clauselibrary");
    this.props.getSectionClauseData(this.state.pageNumber, this.state.type);
    this.setState({ isSort: false });
    this.getProjectData();
  }

  showCreateModal = () => {
    this.setState({ showCreateModal: true });
  };

  changeClauseMode = (data: any) => {
    this.setState({ clauseMode: "edit", editClauseData: data });
  };

  updateCheckedSection = (id: any) => {
    if (this.state.checkedSection.length) {
      if (!this.state.checkedSection.includes(id)) {
        this.setState({ checkedSection: [...this.state.checkedSection, id] });
      } else {
        const newData = this.state.checkedSection.filter((item) => item !== id);
        this.setState({ checkedSection: [...newData] });
      }
    } else {
      if (!this.state.checkedSection.includes(id)) {
        this.setState({ checkedSection: [id] });
      }
    }

    const selected_section = this.props.sectionClauseList.filter(
      (item: any) => {
        return Object.keys(item)[0] === id;
      }
    );
    selected_section.forEach((item: any) => {
      const clause_id = item[Object.keys(item)[0]].map(
        (element: any) => element.id
      );
      if (
        this.state.clause_id.some((data: any) => clause_id.indexOf(data) >= 0)
      ) {
        const newData = this.state.clause_id.filter(
          (data: any) => !(clause_id.indexOf(data) >= 0)
        );
        this.setState({ clause_id: [...newData] });
      } else {
        this.setState({ clause_id: [...this.state.clause_id, ...clause_id] });
      }
    });
  };

  updateCheckedClause = (id: any) => {
    if (this.state.checkedClause.length) {
      if (!this.state.checkedClause.includes(id)) {
        this.setState({ checkedClause: [...this.state.checkedClause, id] });
      } else {
        const newData = this.state.checkedClause.filter((item) => item !== id);
        this.setState({ checkedClause: [...newData] });
      }
    } else {
      if (!this.state.checkedClause.includes(id)) {
        this.setState({ checkedClause: [id] });
      }
    }
  };

  updateCheckedTitle = (data: any, type: any) => {
    const idList: any = [];
    if (type === "title") {
      if (this.state.checkedTitle.length === 0) {
        if (data.length) {
          data.forEach((item: any) => {
            let newData = {
              section_name: Object.keys(item),
              data: item[`${Object.keys(item)[0]}`],
            };
            if (!idList.includes(newData.data[0].section_name?.id)) {
              idList.push(newData.data[0].section_name?.id);
            }
          });
          this.setState({ checkedTitle: idList, checkedSection: idList });
        }
      } else {
        this.setState({ checkedTitle: [], checkedSection: [] });
      }
    } else {
      if (this.state.checkedTitle.length === 0) {
        if (data.length) {
          data.forEach((item: any) => {
            let newData = {
              section_name: Object.keys(item),
              data: item[`${Object.keys(item)[0]}`],
            };
            if (!idList.includes(newData.data[0].section_name?.id)) {
              idList.push(newData.data[0].section_name?.id);
            }
          });
          this.setState({ checkedTitle: idList, checkedSection: idList });
        }
      } else {
        this.setState({ checkedTitle: [] });
      }
    }
  };

  tagsSorting(
    name: any,
    data: any,
    updateSectionClauseSuccess: any,
    isSort: any
  ) {
    let tagSortData: any = [];
    if (typeof name === "object") {
      tagSortData = data.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].map((element: any) => {
          if (
            element.tags.some((objs: any) =>
              name.includes(objs.name.toLowerCase())
            )
          ) {
            return item;
          }
        });
      });
    } else {
      tagSortData = data.map((item: any) => {
        return item[`${Object.keys(item)[0]}`].map((element: any) => {
          if (element.tags.some((objs: any) => objs.name === name)) {
            return item;
          }
        });
      });
    }
    const output = tagSortData.reduce((accumulator: any, data: any) => {
      return [...accumulator, ...data];
    }, []);
    let ds: any = [];
    ds = output.filter((items: any, index: any) => {
      if (items) {
        if (output.indexOf(items) === index) {
          return items;
        }
      }
    });
    updateSectionClauseSuccess(ds);
    if (name.length !== 0) {
      isSort(true);
    } else {
      isSort(false);
    }
  }

  updateClause = (id: any, payload: any) => {
    axios
      .patch(
        process.env.REACT_APP_RIVERUS_CLM_API +
          `stylus/custom-clauselibrary/${id}/`,
        payload
      )
      .then((response) => {
        this.props.getSectionClauseData(this.state.pageNumber, this.state.type);
        this.setState({
          isMove: false,
          checkedClause: [],
          checkedSection: [],
          checkedTitle: [],
        });
      })
      .catch((error) => {
        console.log(error, "errorerrorerror");
      });
  };

  bulkUpdateClause = (payload: any) => {
    AxiosGateWay.patch(
      process.env.REACT_APP_RIVERUS_CLM_API +
        `stylus/custom-clauselibrary/bulk-update/`,
      payload
    )
      .then((response) => {
        this.props.getSectionClauseData(this.state.pageNumber, this.state.type);
        this.setState({
          isMove: false,
          checkedClause: [],
          checkedSection: [],
          checkedTitle: [],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  duplicateClause = () => {
    let payload: any = [];
    this.props.sectionClauseList.forEach((item: any) => {
      item[`${Object.keys(item)[0]}`].forEach((element: any) => {
        if (this.state.checkedClause.includes(element.id)) {
          // payload.push(element);
          const data = {
            section_name: element.section_name.id,
            clause_name: element.clause_name,
            clause_type: element.clause_type,
            clause_source: element.clause_source,
            clause_text: element.clause_text,
            description: element.description,
            creation_type: element.creation_type,
            tags: element.tags.map((item: any) => item.id),
          };
          payload.push(data);
        }
      });
    });

    AxiosGateWay.post(
      process.env.REACT_APP_RIVERUS_CLM_API +
        `stylus/custom-clauselibrary/bulk-insert/`,
      payload
    )
      .then((response) => {
        this.props.getSectionClauseData(this.state.pageNumber, this.state.type);
        this.setState({
          isMove: false,
          checkedClause: [],
          checkedSection: [],
          checkedTitle: [],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteClause = () => {
    let payload: any = {};

    if (this.state.checkedSection.length !== 0) {
      payload = {
        section_name: this.state.checkedSection,
      };
    } else {
      payload = {
        clause_id: this.state.checkedClause,
      };
    }
    axios
      .delete(
        process.env.REACT_APP_RIVERUS_CLM_API +
          `stylus/custom-clauselibrary/delete/`,
        {
          headers: {
            Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
          },
          data: payload,
        }
      )
      .then(() => {
        this.props.getSectionClauseData(this.state.pageNumber, this.state.type);
        this.setState({
          isSort: false,
          isMove: false,
          checkedClause: [],
          checkedSection: [],
          checkedTitle: [],
          clause_id: [],
        });
      })
      .catch((error) => console.log(error));
  };

  applyFilter = (searchKey: string[], filterKey: string) => {
    this.setState({
      filterKeyVals: {
        ...this.state.filterKeyVals,
        [filterKey]: [...searchKey],
      },
    });
  };

  updatePageNumber = (number: number) => {
    this.props.getSectionClauseData(number, this.state.type);
    this.setState({ pageNumber: number });
  };

  isAdmin = false;

  render() {
    let {
      history,
      getSectionDictionary,
      sectionDictionaryData,
      // createSectionClause,
      sectionClauseList,
      updateSectionClauseList,
      clauseLibraryLoader,
    } = this.props;
    let {
      showCreateModal,
      projectData,
      showEditModal,
      selectedClauseData,
      editClauseData,
      clauseMode,
      checkedSection,
      checkedClause,
    } = this.state;
    let filteredList =
      this.state.filterKeyVals["tags"] &&
      this.state.filterKeyVals["tags"].length
        ? []
        : [...sectionClauseList];
    if (
      this.state.filterKeyVals["tags"] &&
      this.state.filterKeyVals["tags"].length
    ) {
      const searchKey = this.state.filterKeyVals["tags"];
      sectionClauseList.forEach((section) => {
        let sectionObject: any = { ...section };
        const { modified_on, ...rest } = sectionObject;
        let list = [];
        if (rest[Object.keys(rest)[0]].length > 0) {
          list = rest[Object.keys(rest)[0]].filter((a: any) => {
            let isAvailable = a["tags"].filter((projectName: any) => {
              return searchKey.includes(projectName.name.toLowerCase());
            });
            if (isAvailable.length > 0) {
              return true;
            } else return false;
          });
        }
        if (list.length > 0) {
          sectionObject[Object.keys(rest)[0]] = [...list];
          filteredList.push(sectionObject);
        }
      });
    }
    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: any) => {
      this.setState({ tabValue: newValue });
      if (newValue === 0) {
        this.setState({ type: "system", isSort: false, filterKeyVals: {} });
        this.props.getSectionClauseData(1, "system");
      } else {
        this.setState({ type: "custom", isSort: false, filterKeyVals: {} });
        this.props.getSectionClauseData(1, "custom");
      }
    };

    const createSectionClause = async (
      section_name: string,
      clause_name: string,
      clause_type: string,
      clause_source: string,
      clause_text: string,
      description: string,
      creation_type: string,
      tags: any
    ) => {
      try {
        let URL =
          process.env.REACT_APP_RIVERUS_CLM_API +
          "stylus/custom-clauselibrary/";
        const response = await ClauseLibraryAPI.createSectionClause(
          URL,
          section_name,
          clause_name,
          clause_type,
          clause_source,
          clause_text,
          description,
          creation_type,
          tags
        );
        if (response.status === 400) {
          this.setState({ isSimilar: true });
        }
        this.setState({ showCreateModal: false });
      } catch (error) {
        console.log(error, "createSectionClause");
      }
    };

    return (
      <div className="row m-0">
        <div
          className="col-md-1 p-0"
          style={{ zIndex: 2, flex: "0 0 6.333333%" }}
        >
          <SideNavbar history={history} />
        </div>
        <div className="col-md-11 mt-6" style={{ zIndex: 2 }}>
          <div className="row m-0">
            <div className="col-md-12 mt-0 pl-1">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="stylus_section_header">Clause Library</p>
                </div>
                <button
                  disabled={
                    this.state.tabValue === 0
                      ? isRiverusAdmin()
                        ? false
                        : true
                      : this.isAdmin
                      ? false
                      : true
                  }
                  onClick={() => {
                    this.setState({ clauseMode: "add" });
                    this.showCreateModal();
                  }}
                  style={{ fontSize: "13px", padding: "10px 20px" }}
                  className={`section-add-clause py-1  ${
                    this.state.tabValue === 0
                      ? isRiverusAdmin()
                        ? ""
                        : "disabled-buttons"
                      : this.isAdmin
                      ? ""
                      : "disabled-buttons"
                  }`}
                >
                  <img src="/static_images/add-clause.svg" alt="refresh" />
                  Add a Clause
                </button>
              </div>
              <div className="d-flex justify-content-between ml-1 mt-3">
                <div>
                  <img
                    onClick={() => {
                      this.props.getSectionClauseData(
                        this.state.pageNumber,
                        this.state.type
                      );
                      this.setState({ isSort: false });
                    }}
                    style={{ height: "30px" }}
                    src="/static_images/clause_refresh.svg"
                    alt="refresh"
                    className="ml-3"
                  />
                </div>
                {this.state.checkedClause.length ||
                this.state.checkedSection.length ||
                this.state.checkedTitle.length ? (
                  <div
                    style={{
                      width: `${
                        this.state.checkedClause.length ? "16%" : "10%"
                      }`,
                    }}
                    className="d-flex justify-content-around"
                  >
                    {this.state.checkedSection.length > 0 ? (
                      <img
                        style={{ height: "25px" }}
                        src="/static_images/section-filter.svg"
                        alt="refresh"
                        className={`${
                          this.state.tabValue === 0
                            ? isRiverusAdmin()
                              ? ""
                              : "disabled-buttons"
                            : this.isAdmin
                            ? ""
                            : "disabled-buttons"
                        }`}
                        onClick={() => {
                          if (
                            (this.state.tabValue === 1 && this.isAdmin) ||
                            isRiverusAdmin()
                          ) {
                            this.setState({ addTag: true });
                          }
                        }}
                      />
                    ) : null}
                    {this.state.checkedClause.length === 1 ? (
                      <img
                        onClick={() => {
                          if (
                            (this.state.tabValue === 1 && this.isAdmin) ||
                            isRiverusAdmin()
                          ) {
                            filteredList.map((item: any) => {
                              item[`${Object.keys(item)[0]}`].forEach(
                                (ite: any) => {
                                  if (checkedClause[0] === ite.id) {
                                    navigator.clipboard.writeText(
                                      ite.clause_text
                                    );
                                  }
                                }
                              );
                            });
                            this.setState({ isCopy: true });
                          }
                        }}
                        style={{ height: "25px" }}
                        src="/static_images/clause_copy.svg"
                        alt="refresh"
                        className={`${
                          this.state.tabValue === 0
                            ? isRiverusAdmin()
                              ? ""
                              : "disabled-buttons"
                            : this.isAdmin
                            ? ""
                            : "disabled-buttons"
                        }`}
                      />
                    ) : null}
                    {this.state.checkedClause.length ? (
                      <>
                        <img
                          onClick={() => {
                            if (
                              (this.state.tabValue === 1 && this.isAdmin) ||
                              isRiverusAdmin()
                            ) {
                              this.setState({ isMove: true });
                            }
                          }}
                          style={{ height: "25px" }}
                          src="/static_images/clause-section.svg"
                          alt="refresh"
                          className={`${
                            this.state.tabValue === 0
                              ? isRiverusAdmin()
                                ? ""
                                : "disabled-buttons"
                              : this.isAdmin
                              ? ""
                              : "disabled-buttons"
                          }`}
                        />
                        <img
                          onClick={() => {
                            if (
                              (this.state.tabValue === 1 && this.isAdmin) ||
                              isRiverusAdmin()
                            ) {
                              this.duplicateClause();
                            }
                          }}
                          style={{ height: "25px" }}
                          src="/static_images/clause_move.svg"
                          alt="refresh"
                          className={`${
                            this.state.tabValue === 0
                              ? isRiverusAdmin()
                                ? ""
                                : "disabled-buttons"
                              : this.isAdmin
                              ? ""
                              : "disabled-buttons"
                          }`}
                        />
                      </>
                    ) : null}
                    <img
                      onClick={() => {
                        if (
                          (this.state.tabValue === 1 && this.isAdmin) ||
                          isRiverusAdmin()
                        ) {
                          this.bulkUpdateClause({
                            clause_id:
                              this.state.clause_id.length > 0
                                ? this.state.clause_id
                                : this.state.checkedClause,
                            clause_type: "recommended",
                          });
                        }
                      }}
                      style={{ height: "25px" }}
                      src="/static_images/sub-section-success.svg"
                      alt="refresh"
                      className={`${
                        this.state.tabValue === 0
                          ? isRiverusAdmin()
                            ? ""
                            : "disabled-buttons"
                          : this.isAdmin
                          ? ""
                          : "disabled-buttons"
                      }`}
                    />
                    <img
                      onClick={() => {
                        if (
                          (this.state.tabValue === 1 && this.isAdmin) ||
                          isRiverusAdmin()
                        ) {
                          this.bulkUpdateClause({
                            clause_id:
                              this.state.clause_id.length > 0
                                ? this.state.clause_id
                                : this.state.checkedClause,
                            clause_type: "avoidable",
                          });
                        }
                      }}
                      style={{ height: "25px" }}
                      src="/static_images/avoidable-icon.svg"
                      alt="refresh"
                      className={`${
                        this.state.tabValue === 0
                          ? isRiverusAdmin()
                            ? ""
                            : "disabled-buttons"
                          : this.isAdmin
                          ? ""
                          : "disabled-buttons"
                      }`}
                    />
                    <img
                      onClick={() => {
                        if (
                          (this.state.tabValue === 1 && this.isAdmin) ||
                          isRiverusAdmin()
                        ) {
                          this.setState({ showWarning: true });
                        }
                      }}
                      style={{ height: "25px" }}
                      src="/static_images/section-clause-delete.svg"
                      alt="refresh"
                      className={`${
                        this.state.tabValue === 0
                          ? isRiverusAdmin()
                            ? ""
                            : "disabled-buttons"
                          : this.isAdmin
                          ? ""
                          : "disabled-buttons"
                      }`}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {clauseLibraryLoader === true ? (
            <BarLoader />
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                style={{
                  background: "white",
                  boxShadow:
                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                  borderRadius: "4px",
                }}
              >
                <Tabs
                  value={this.state.tabValue}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Default" {...a11yProps(0)} onClick={() => {}} />
                  {isRiverusAdmin() ? null : (
                    <Tab label="Custom" {...a11yProps(1)} onClick={() => {}} />
                  )}
                </Tabs>
              </Box>
              <Paper style={{ width: "100%", marginBottom: 2 }}>
                <div className="row m-0">
                  <div className="col-md-12 mt-3 p-0">
                    <div className="col-md-12 clause-folder-view p-0">
                      <SectionListHeader
                        checkedTitle={this.state.checkedTitle}
                        isSort={(data: any) => this.setState({ isSort: data })}
                        updateSectionClauseSuccess={
                          this.props.updateSectionClauseSuccess
                        }
                        data={filteredList}
                        updateCheckedTitle={this.updateCheckedTitle}
                        tagsSorting={this.tagsSorting}
                        projectData={projectData}
                        filterKeyVals={this.state.filterKeyVals}
                        applyFilter={this.applyFilter}
                        tags={this.state.tags}
                      />
                      {this.state.isSort ? (
                        updateSectionClauseList ? (
                          updateSectionClauseList.length ? (
                            updateSectionClauseList.map((item: any) => {
                              let newData = {
                                section_name: Object.keys(item)[0],
                                data: item[`${Object.keys(item)[0]}`],
                                modified_on: item["modified_on"],
                              };

                              return (
                                <SectionListView
                                  updateCheckedTitle={this.updateCheckedTitle}
                                  projectData={projectData}
                                  sectionClauseData={newData["data"]}
                                  selectedClause={this.selectedClause}
                                  updateCheckedSection={
                                    this.updateCheckedSection
                                  }
                                  checkedSection={checkedSection}
                                  updateCheckedClause={this.updateCheckedClause}
                                  checkedClause={checkedClause}
                                  sectionName={newData["section_name"]}
                                  modifiedDate={newData["modified_on"]}
                                  checkedTitle={this.state.checkedTitle}
                                  wholeData={updateSectionClauseList}
                                  isSort={(data: boolean) =>
                                    this.setState({ isSort: data })
                                  }
                                  updateSectionClauseSuccess={
                                    this.props.updateSectionClauseSuccess
                                  }
                                  tagsSorting={this.tagsSorting}
                                  filterKeyVals={this.state.filterKeyVals}
                                  applyFilter={this.applyFilter}
                                />
                              );
                            })
                          ) : (
                            <span
                              className="d-flex align-items-center justify-content-center h-100"
                              style={{ fontSize: "20px" }}
                            >
                              No data
                            </span>
                          )
                        ) : null
                      ) : filteredList ? (
                        filteredList.length ? (
                          filteredList.map((item: any) => {
                            let newData = {
                              section_name: Object.keys(item)[0],
                              data: item[`${Object.keys(item)[0]}`],
                              modified_on: item["modified_on"],
                            };
                            return (
                              <SectionListView
                                updateCheckedTitle={this.updateCheckedTitle}
                                checkedTitle={this.state.checkedTitle}
                                projectData={projectData}
                                sectionClauseData={newData["data"]}
                                selectedClause={this.selectedClause}
                                updateCheckedSection={this.updateCheckedSection}
                                checkedSection={checkedSection}
                                updateCheckedClause={this.updateCheckedClause}
                                checkedClause={checkedClause}
                                modifiedDate={newData["modified_on"]}
                                sectionName={newData["section_name"]}
                                wholeData={filteredList}
                                isSort={(data: boolean) =>
                                  this.setState({ isSort: data })
                                }
                                updateSectionClauseSuccess={
                                  this.props.updateSectionClauseSuccess
                                }
                                tagsSorting={this.tagsSorting}
                                filterKeyVals={this.state.filterKeyVals}
                                applyFilter={this.applyFilter}
                              />
                            );
                          })
                        ) : (
                          <span
                            className="d-flex align-items-center justify-content-center h-100"
                            style={{ fontSize: "20px" }}
                          >
                            No data
                          </span>
                        )
                      ) : null}
                      <PageNavigation
                        pageSize={10}
                        updatePageNumber={this.updatePageNumber.bind(this)}
                        totalCount={this.props.totalCount}
                        pageNumber={this.state.pageNumber}
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            </Box>
          )}
        </div>
        {showCreateModal ? (
          <CreateNewClauseModal
            updateClause={this.updateClause}
            sectionDictionaryData={sectionDictionaryData}
            getSectionDictionary={getSectionDictionary}
            closeModal={() => {
              this.props.getSectionClauseData(
                this.state.pageNumber,
                this.state.type
              );
              this.setState({ showCreateModal: false });
            }}
            projectData={projectData}
            createSectionClause={createSectionClause}
            editClauseData={editClauseData}
            clauseMode={clauseMode}
            onRefresh={() => {
              this.getProjectData();
            }}
            onAdd={() => {
              this.props.getSectionClauseData(
                this.state.pageNumber,
                this.state.type
              );
            }}
          />
        ) : null}
        {showEditModal ? (
          <EditClauseModal
            selectedClauseData={selectedClauseData}
            closeModal={() => {
              this.setState({ showEditModal: false });
            }}
            showCreateModal={this.showCreateModal}
            changeClauseMode={this.changeClauseMode}
            onCopy={() => {
              this.setState({ isCopy: true });
            }}
            isAdmin={this.isAdmin}
            tabName={this.state.tabValue}
          />
        ) : null}
        {/* {this.state.isSimilar && <SimilarClauseModal />} */}
        {this.state.isMove ? (
          <SelectSection
            sectionDictionaryData={sectionDictionaryData}
            onClose={() => this.setState({ isMove: false })}
            getSectionDictionary={getSectionDictionary}
            bulkUpdateClause={this.bulkUpdateClause}
            selectedClause={this.state.checkedClause}
          />
        ) : null}
        <AddTagClause
          addTag={this.state.addTag}
          onClose={() => this.setState({ addTag: false })}
          tagsList={projectData}
          updateClause={this.bulkUpdateClause}
          selectedSectionID={checkedSection}
          data={this.state.isSort ? updateSectionClauseList : sectionClauseList}
        />
        {this.state.showWarning && (
          <WarningPopup
            openWarningPopup={this.state.showWarning}
            message={"Are you sure you want to delete the Clause?"}
            onClose={() => this.setState({ showWarning: false })}
            onClick={this.deleteClause}
          />
        )}
        <Snackbar
          open={this.state.isCopy || this.state.isSimilar}
          message={
            this.state.isSimilar
              ? "Please use another clause name. Clause name can't be same"
              : "Copied to Clipboard"
          }
          onClick={() => {
            this.setState({ isSimilar: false, isCopy: false });
          }}
          action={
            <React.Fragment>
              <Button
                style={{ color: "#88305F", border: "1px solid #88305F" }}
                size="small"
                onClick={() => {}}
              >
                Ok
              </Button>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}
