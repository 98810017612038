import React, { Component } from "react";
import { isNullOrUndefined } from "is-what";
import { CheckList } from "../State/taskManagementState";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import ChildFileListModal from "./childFileListModal";
import { Button, Row, Col } from "react-bootstrap";
import { GrAttachment } from "react-icons/gr";

interface Props {
  checkList: CheckList[];
  insertCheckListItem: (item: string) => void;
  updateCheckListItem: (
    title: string,
    flag: boolean,
    updateType: string,
    id: string,
    editedTitle: string,
    index: number,
    attachment?: any[]
  ) => void;
  userData: any[];
  childFileList: any[];
  updateCheckListAttachment: (index: number, attachment: any[]) => void;
}

interface State {
  isCompleted: boolean;
  currentTitle: string;
  enableEditMode: boolean;
  editTitle: string;
  editId: string;
  editIndex: number;

  showFileListModal: boolean;
  currentAttachedFiles: any[];
  editAttachedFiles: any[];
  attachmentEditIndex: number;
}

class Checklist extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCompleted: false,
      currentTitle: "",
      editTitle: "",
      editId: "0",
      enableEditMode: false,
      editIndex: 0,
      currentAttachedFiles: [],
      editAttachedFiles: [],
      showFileListModal: false,
      attachmentEditIndex: -1,
    };
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleOutsideClick, false);
  }

  mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ["@"],
    source: async (searchTerm: any, renderList: any, mentionChar: any) => {
      let values = [];
      if (mentionChar === "@") {
        values = this.props.userData ? this.props.userData : [];
      }
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
  };

  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("checklist-input");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target !== outsideDiv) {
        if (this.state.enableEditMode === true) {
          this.props.updateCheckListItem(
            "",
            false,
            "title",
            this.state.editId,
            this.state.editTitle,
            this.state.editIndex
          );
          this.setState({
            editTitle: "",
            enableEditMode: false,
            editId: "0",
            editIndex: 0,
          });
        } else {
          if (this.state.currentTitle.length > 0) {
            this.props.insertCheckListItem(this.state.currentTitle);
            this.setState({ currentTitle: "" });
          }
        }
      }
    }
  };

  handleKeyBoard = (e: React.KeyboardEvent) => {
    let { currentTitle, isCompleted } = this.state;
    let { insertCheckListItem } = this.props;

    if (e.keyCode === 13) {
      if (currentTitle.length > 0) {
        insertCheckListItem(currentTitle);
        this.setState({ currentTitle: "" });
      }
    }
  };

  handleKeyBoardForEditMode = (e: React.KeyboardEvent, index: number) => {
    let { editTitle, editId } = this.state;
    let { updateCheckListItem } = this.props;
    if (e.keyCode === 13) {
      if (editTitle.length > 0) {
        updateCheckListItem("", false, "title", editId, editTitle, index);
        this.setState({ editTitle: "", enableEditMode: false, editId: "0" });
      }
    }
  };

  setShowFileListModal = (modalFlag: boolean) => {
    this.setState({ showFileListModal: modalFlag });
  };
  handleAttachFileListChange = (e: any[]) => {
    if (this.state.attachmentEditIndex >= 0) {
      this.props.updateCheckListAttachment(this.state.attachmentEditIndex, e);
    }
    // if (this.state.enableEditMode) {
    //     this.setState({ editAttachedFiles: e });
    // } else {
    //     this.setState({ currentAttachedFiles: e });
    // }
  };

  //checklist-delete-icn
  render() {
    let { checkList, insertCheckListItem, updateCheckListItem, childFileList } =
      this.props;
    let {
      isCompleted,
      currentTitle,
      enableEditMode,
      editTitle,
      editId,
      editIndex,
    } = this.state;

    return (
      <div className="col-md-12" id="checklist-container">
        <div className="col-md-12 pl-0">
          <span className="label">Checklist</span>
        </div>
        <ChildFileListModal
          showModal={this.state.showFileListModal}
          setShowModal={this.setShowFileListModal}
          childFileList={childFileList}
          handleAttachFileListChange={this.handleAttachFileListChange}
          fileValues={
            this.state.enableEditMode
              ? this.state.editAttachedFiles
              : this.state.currentAttachedFiles
          }
        />
        {checkList?.length > 0 &&
          checkList.map(
            (item: CheckList, index: number) =>
              item.title !== null &&
              item.delete === false && (
                <div className="col-md-12 pl-0 mt-2" style={{}} key={index}>
                  <div className="col-md-12 round-checkbox">
                    <div className="col-md-1 p-0">
                      <input
                        type="checkbox"
                        id={"checkbox" + index}
                        onClick={(e) =>
                          updateCheckListItem(
                            item.title,
                            (e.target as HTMLInputElement).checked,
                            "checkbox",
                            "0",
                            "",
                            index
                          )
                        }
                        checked={item.flag}
                      />
                      <label htmlFor={"checkbox" + index}></label>
                    </div>
                    {/* <div className="col-md-11 p-0" style={{left: '-10px'}}>
                                    <input type="text" id="checklist-input"
                                    className="task-title-input float-left mr-1"
                                    placeholder="Add an item" 
                                    value={editTitle === '' ? item.title : editTitle} 
                                    onKeyDown={(e: any) => this.handleKeyBoard(e)} 
                                    onChange={(e) => this.setState({ editTitle: e.currentTarget.value })}
                                    />
                                </div> */}

                    <div
                      className="col-md-9 p-0 checklist-quill"
                      style={{ left: "-10px" }}
                    >
                      {enableEditMode === true ? (
                        editIndex === index ? (
                          <ReactQuill
                            className="task-title-input float-left mr-1 task-checklist-show-quill"
                            placeholder="Add an item" 
                            value={editTitle}
                            onKeyDown={(e: any) =>
                              this.handleKeyBoardForEditMode(e, index)
                            }
                            onChange={(e) => this.setState({ editTitle: e })}
                            theme="snow"
                            modules={{
                              mention: this.mentionModule,
                              toolbar: false,
                            }}
                          />
                        ) : (
                          <ReactQuill
                            value={item.title}
                            readOnly={true}
                            theme={"bubble"}
                          />
                        )
                      ) : (
                        <div
                          style={{ width: "100%" }}
                          onClick={() =>
                            this.setState(
                              {
                                enableEditMode: !enableEditMode,
                                editId: item.id,
                                editTitle: item.title,
                                editIndex: index,
                              },
                              () =>
                                document
                                  .getElementById("checklist-input")
                                  ?.focus()
                            )
                          }
                        >
                          <ReactQuill
                            id="checklist-item"
                            value={item.title}
                            readOnly={true}
                            theme={"bubble"}
                            className="task-title-input float-left mr-1"
                            style={{
                              textDecoration:
                                item.flag === true ? "line-through" : "none",
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className="col-md-1 cursor-pointer"
                      onClick={(e) => {
                        updateCheckListItem(
                          item.title,
                          (e.target as HTMLInputElement).checked,
                          "delete",
                          item.id,
                          "",
                          index
                        );
                      }}
                    >
                      <img
                        src="/static_images/checklist-delete-icn.svg"
                        alt="close"
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        this.setState({ attachmentEditIndex: index });
                        this.setShowFileListModal(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="close" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    {item.attachment &&
                      item.attachment.length > 0 &&
                      item.attachment.map((file, i) => {
                        return (
                          <Col key={i}>
                            <a
                              href={`/document/uploads/${getBase64(
                                file.value
                              )}`}
                              target="_blank"
                            >
                              {file.label}
                            </a>
                          </Col>
                        );
                      })}
                  </div>
                </div>
              )
          )}
        <div className="col-md-12 pl-0 mt-2" style={{}}>
          <div className="col-md-12 round-checkbox">
            <div className="col-md-1 p-0">
              <input type="checkbox" id="checkbox" disabled />
              <label htmlFor="checkbox"></label>
            </div>
            <div
              className="col-md-10 p-0 checklist-quill"
              style={{ left: "-10px" }}
            >
              {/* <input
                type="text"
                id="checklist-input"
                className="task-title-input float-left mr-1"
                placeholder="Add an item"
                value={currentTitle}
                onKeyDown={(e: any) => this.handleKeyBoard(e)}
                onChange={(e) =>
                  this.setState({ currentTitle: e.currentTarget.value })
                }
              /> */}
              <ReactQuill
                style={{ width: "100%" }}
                className="task-title-input float-left mr-1 task-checklist-show-quill"
                placeholder="Add an item"
                value={currentTitle}
                onKeyDown={(e: any) => this.handleKeyBoard(e)}
                onChange={(e) => {
                  this.setState({ currentTitle: e });
                }}
                theme="snow"
                modules={{ mention: this.mentionModule, toolbar: false }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checklist;
const getBase64 = (id: number) => {
  return "MTAx";
};
