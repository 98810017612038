import React, { Component } from "react";
import { isNullOrUndefined } from "is-what";
import { clauseTypeList } from "../../../../ClauseLibrary/Component/Utils/clauseLibraryUtils";
import { FolderHeadingInfo, SectionDictionaryInfo } from "../../../../ClauseLibrary/State/clauseLibraryState";
import Scrollable from "../../../Scrollable/scrollable";

interface Props {
  selectedClauseText: string;
  currentFileId: string;
  folderHeadingList: FolderHeadingInfo[];
  folderSubHeadingList: FolderHeadingInfo[];
  createClause: (
    clauseName: string,
    extractedText: string,
    userText: string,
    clauseType: string,
    sourceFileId: number,
    folderId: string,
    id: string
  ) => void;
  getFolderSubHeading: (parentId: string) => void;
  closeModal: (type: string) => void;
  sectionDictionaryData: SectionDictionaryInfo[];
  projectData: [];
  createSectionClause: (
    section_name: string,
    clause_name: string,
    clause_type: string,
    clause_source: string,
    clause_text: string,
    description: string,
    creation_type: string,
    tags: any
  ) => void;
  getSectionClauseData: (page:number) => void;
}

interface State {
  expandHeading: boolean;
  expandSubHeading: boolean;
  expandClauseType: boolean;
  searchHeading: string;
  searchSubHeading: string;
  selectedHeading: FolderHeadingInfo;
  selectedSubHeading: FolderHeadingInfo;
  selectedClauseType: string;
  relevantClauseText: string;
  clauseName: string;
  clauseType: string;
  clauseSource: string;
  description: string;
  tags: any;
  sectionName: any;
  createdType: string;
  sourceLink: string;
  
}

export default class CreateClauseModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expandHeading: false,
      expandSubHeading: false,
      expandClauseType: false,
      searchHeading: "",
      searchSubHeading: "",
      selectedHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedSubHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedClauseType: "",
      relevantClauseText: this.props.selectedClauseText,
      clauseName: "",
      clauseType: "recommended",
      clauseSource: "Umbrella Contract::"+process.env.REACT_APP_RIVERUS_CLM_APP_HOST+"document/documentlibrary/"+btoa(this.props.currentFileId),
      description: "",
      tags: [],
      sectionName: "",
      createdType: "custom",
      sourceLink: "",
    };
  }

  handleOutsideClick = (e: MouseEvent) => {
    let outsideDiv = document.getElementById("createClauseModal");
    if (!isNullOrUndefined(outsideDiv)) {
      if (e.target === outsideDiv) {
        document.getElementById("create-clause-modal-close-btn")?.click();
        //window.location.reload();
      }
    }
  };

  componentDidUpdate(prevProps: Props) {
    
    if (this.props.selectedClauseText !== prevProps.selectedClauseText) {
      this.setState({ relevantClauseText: this.props.selectedClauseText });
    }
  }

  onCreateOrUpdate = () => {
    let tagsArray: string[] = [];
    this.state.tags.map((item: any) => tagsArray.push(item.id));
      this.props.createSectionClause(
        this.state.sectionName,
        this.state.clauseName,
        this.state.clauseType,
        this.state.clauseSource,
        this.state.relevantClauseText,
        this.state.description,
        this.state.createdType,
        tagsArray
      );
     this.props.closeModal('create-clause');
     this.props.getSectionClauseData(1);
  };


  render() {
    let { selectedClauseText, folderHeadingList, folderSubHeadingList, sectionDictionaryData, projectData } =
      this.props;
    let {
      expandHeading,
      expandSubHeading,
      expandClauseType,
      relevantClauseText,
      selectedHeading,
      selectedSubHeading,
      selectedClauseType,
      clauseName,
      clauseType,
      description,
      tags
    } = this.state;
    return (
      <>
       <div className="row section-modal" style={{
        background: "rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(5px)",       
       }}>
        <div className="create-section-modal">
          <div className="modal-header">
            <p className="m-0">Create New Clause</p>
            <img
              className="cursor-pointer"
              onClick={() => this.props.closeModal('close-modal')}
              src="/static_images/white-close-modal-icn.svg"
              alt="clause-folder"
            />
          </div>
          <div className="pt-4 pb-2">
            <div className="row m-0">
              <div className="col-md-4">
                <label>Select Section</label>
              
                <select
                  onChange={(e) => {
                    this.setState({
                      sectionName: e.target.value,
                    });
                  }}
                  style={{
                    width: "100%",
                    height: "45%",
                    background: "transparent",
                    border: "1px solid #DDDDDD",
                  }}
                  placeholder="Select Dropdown"
                >
                  <option
                    value={
                      ""
                    }
                    defaultValue={""}
                    selected
                    hidden
                    key={"section_dictionary_0"}
                  >
                    {"Select Dropdown"}
                  </option>
                  {sectionDictionaryData &&
                    sectionDictionaryData?.length &&
                    sectionDictionaryData.map((item) => {
                      return (
                        <option key={`section-dict${item.id}`} value={item.id}>{item?.section_name}</option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-4">
                <label>Clause Name</label>
                <input
                  onChange={(e) =>
                    this.setState({ clauseName: e.target.value })
                  }
                  value={clauseName}
                  placeholder="Placeholder"
                  style={{ fontSize: "14px", border: "1px solid #DDDDDD" }}
                ></input>
              </div>
              {/* {this.props.clauseMode === "edit" && (
                <div className="col-md-4">
                  <label>Clause No.</label>
                  <p>{this.props.editClauseData.clause_no}</p>
                </div>
              )} */}
            </div>
            <div className="mt-4">
              <div className="row m-0">
                <div className="col-md-4">
                  <label>Clause Type</label>
                  <div className="d-flex">
                    <img
                      className="cursor-pointer"
                      style={{ height: "24px", width: "24px" }}
                      onClick={() =>
                        this.setState({ clauseType: "recommended" })
                      }
                      src={`${
                        clauseType === "recommended"
                          ? "/static_images/radio-active.svg"
                          : "/static_images/radio-inactive.svg"
                      }`}
                      alt="radio-btn"
                    />
                    &nbsp;&nbsp;
                    <span className="d-flex">
                      <img
                        className="cursor-pointer"
                        style={{ height: "25px" }}
                        src={"/static_images/sub-section-success.svg"}
                        alt="radio-btn"
                      />
                      <p
                        style={{ fontSize: "14px", color: "#4C4C4C" }}
                        className="m-0"
                      >
                        &nbsp; Recommended
                      </p>
                    </span>
                  </div>
                  <div className="mt-2 d-flex">
                    <img
                      className="cursor-pointer"
                      onClick={() => this.setState({ clauseType: "avoidable" })}
                      style={{ height: "24px", width: "24px" }}
                      src={`${
                        clauseType === "avoidable"
                          ? "/static_images/radio-active.svg"
                          : "/static_images/radio-inactive.svg"
                      }`}
                      alt="radio-btn"
                    />
                    &nbsp;&nbsp;
                    <span className="d-flex">
                      <img
                        className="cursor-pointer"
                        src={"/static_images/avoidable-icon.svg"}
                        alt="radio-btn"
                      />
                      <p
                        style={{ fontSize: "14px", color: "#4C4C4C" }}
                        className="m-0"
                      >
                        &nbsp; Avoidable
                      </p>
                    </span>
                  </div>
                </div>
                <div className="col-md-8" style={{ margin: "auto" }}>
                  <label>Clause Text</label>
                  <textarea
                    // onChange={(e) =>
                    //   // this.setState({ clauseText: e.target.value })
                    // }
                    onChange={(e) => this.setRelevantText(e)}
                    rows={6}
                    cols={40}
                    name="text"
                    value={relevantClauseText}
                    placeholder="Enter relevant clause text"
                    maxLength={3000}
                    style={{
                      minHeight: "60px",
                      lineHeight: "15px",
                      width: "100%",
                      border: "1px solid #DDDDDD",
                    }}
                 
                  ></textarea>

                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="row m-0">
                <div className="col-md-4" style={{ top: "-30px" }}>
                  <label>Clause Source</label>
                  {/* <div className="section-modal-upload"> */}
                  {/* <p>No Reference</p> */}
                  <>
                    <input
                      className="section-modal-upload"
                      type="search"
                      list="mylist"
                      value={this.state.clauseSource}
                      onChange={(e) => {
                        if (e.target.value.split("::")[0] !== "No Reference") {
                          this.setState({ clauseSource: e.target.value });
                        } else {
                          this.setState({ clauseSource: "No Reference::" });
                        }
                      }}
                      style={{
                        fontSize: "14px",
                        border: "1px solid #DDDDDD",
                      }}
                    />
                    <datalist id="mylist">
                      <option value="No Reference::" />
                      <option value="Umbrella Contract::" />
                      <option value="Stylus Template::" />
                      <option value="External Source::" />
                    </datalist>
                  </>
                  <div
                    style={{
                      fontSize: "11px",
                      color: "#818181",
                      marginTop: "5px",
                     
                    }}
                  >
                    <p className="m-0">{"Hint:"}</p>
                    <p className="m-0">
                      {"1. Umbrella Contract <Provide Link>"}
                    </p>
                    <p className="m-0">{"2. Stylus Draft <Provide Link>"}</p>
                    <p className="m-0">{"3. External Source <Provide Link>"}</p>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-md-8" style={{ margin: "auto" }}>
                  <label>Description</label>
                  <textarea
                    value={description}
                    rows={7}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    cols={40}
                    name="text"
                    placeholder="Enter Usage Info"
                    maxLength={3000}
                    style={{
                      minHeight: "60px",
                      lineHeight: "15px",
                      width: "100%",
                      border: "1px solid #DDDDDD",
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ top: "-40px" }}>
              <div className="d-flex justify-content-between">
                <label>Tags</label>
                <p
                  style={{ color: "#818181" }}
                  className="cursor-pointer"
                  onClick={() => this.setState({ tags: [] })}
                >
                  Reset
                </p>
              </div>
              <select
                style={{
                  width: "100%",
                  height: "27%",
                  background: "transparent",
                  border: "1px solid #DDDD",
                }}
                onChange={(e) => {
                  const checkID = (element: any) =>
                    element.id === JSON.parse(e.target.value).id;
                  if (!tags.some(checkID)) {
                    this.setState({
                      tags: [...tags, JSON.parse(e.target.value)],
                    });
                  }
                }}
              >
                <option key={"contract-tags_0"} value="" selected hidden>
                  Select Dropdown
                </option>
                {projectData &&
                  projectData?.length &&
                  projectData?.map((item) => {
                    return (
                      <option key={`contract-tags${item["id"]}`} value={JSON.stringify(item)}>
                        {item["name"]}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="mt-4">
              <div className="row m-0">
                <div className="col-md-12" style={{ top: "-40px" }}>
                  <div className="row m-0">
                    {tags
                      ? tags?.length
                        ? tags?.map((item: any) => {
                            return (
                              <div className="col-md-2 section-tag" key={`contract-tag${item['id']}`}>
                                {item["name"]}
                              </div>
                            );
                          })
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-end mt-3 mr-3"
              onClick={() => this.onCreateOrUpdate()}
            >
              <span
                className="section-add-clause py-1"
                id="save-btn"
                style={{ fontSize: "13px", padding: "10px 25px" }}
                data-toggle="modal"
                data-target="#createClausePageModal"
              >
                {"Add Clause"}
              </span>
            </div>
          </div>
          </div>
          </div>

      <div className="col-md-12 col-12"  style={{display: "none"}}>
        <div
          className="row modal"
          id="createClauseModal"
          aria-labelledby="createClauseModal"
        >
          <div className="col-md-12 modal-dialog">
            <div className="row">
              <div className="col-md-12 modal-content">
                <div className="row">
                  <div className="col-md-12 modal-body">
                    <span
                      data-dismiss="modal"
                      style={{ float: "right", cursor: "pointer" }}
                      id="create-clause-modal-close-btn"
                      onClick={() => this.closeModal()}
                    >
                      <img
                        src="/static_images/close-modal-icn.svg"
                        alt="close"
                      />
                    </span>
                  </div>
                  <div
                    className="col-md-1"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "0.2rem",
                    }}
                  ></div>
                  <div className="col-md-4 modal-title modal-header" >
                    {"Create a new Clause"}
                  </div>
                  <div
                    className="col-md-7"
                    style={{
                      borderTop: "1px solid #996C84",
                      marginTop: "0.2rem",
                    }}
                  ></div>
                  <div className="modal-body modal-subtitle">
                    <div className="row">
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-12 clause-modal-heading">
                            Clause Name
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12 align-left">
                                <span
                                  className="clause-input"
                                  style={{
                                    background: "white",
                                    border: "1px solid #DDDDDD",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="clause-input"
                                    placeholder="Enter clause name"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      background: "white",
                                    }}
                                    value={clauseName}
                                    onChange={(e) => this.setClauseName(e)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-12 clause-modal-heading">
                            Clause Heading
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12 align-left">
                                <span
                                  className="clause-input"
                                  style={{
                                    background: "white",
                                    border: "1px solid #DDDDDD",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      expandHeading: !this.state.expandHeading,
                                    })
                                  }
                                >
                                  <input
                                    type="text"
                                    readOnly
                                    className="clause-input"
                                    placeholder="Choose one option"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      background: "white",
                                    }}
                                    value={selectedHeading.folderName}
                                  />
                                  <img
                                    src="/static_images/new-tag-dropdown-active.svg"
                                    alt="dropdown"
                                    style={{
                                      transform: expandHeading
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            {expandHeading && (
                              <div className=" col-md-12 clause-options-container">
                                <div className="row ">
                                  <div
                                    className="col-md-12 clause-autocomplete-container align-left"
                                    style={{ margin: 0 }}
                                  >
                                    <Scrollable maxHeight={90}>
                                      {folderHeadingList.map((heading, i) => (
                                        <div
                                          className="clause-input-suggestion cursor-pointer align-left"
                                          style={{ fontSize: "14px" }}
                                          key={i}
                                          onClick={() =>
                                            this.setHeading(heading)
                                          }
                                        >
                                          {heading.folderName}
                                        </div>
                                      ))}
                                    </Scrollable>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-12 clause-modal-heading">
                            Sub-Heading
                          </div>
                          {folderSubHeadingList.length > 0 ? (
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-12 align-left">
                                  <span
                                    className="clause-input"
                                    style={{
                                      background: "white",
                                      border: "1px solid #DDDDDD",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        expandSubHeading:
                                          !this.state.expandSubHeading,
                                      })
                                    }
                                  >
                                    <input
                                      type="text"
                                      readOnly
                                      className="clause-input"
                                      placeholder="Choose one option"
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        background: "white",
                                      }}
                                      value={selectedSubHeading.folderName}
                                    />
                                    <img
                                      src="/static_images/new-tag-dropdown-active.svg"
                                      alt="dropdown"
                                      style={{
                                        transform: expandSubHeading
                                          ? "rotate(180deg)"
                                          : "none",
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              {expandSubHeading && (
                                <div className=" col-md-12 clause-options-container">
                                  <div className="row ">
                                    <div
                                      className="col-md-12 clause-autocomplete-container align-left"
                                      style={{ margin: 0 }}
                                    >
                                      <Scrollable maxHeight={90}>
                                        {folderSubHeadingList.map(
                                          (subHeading, i) => (
                                            <div
                                              className="clause-input-suggestion cursor-pointer align-left"
                                              style={{ fontSize: "14px" }}
                                              key={i}
                                              onClick={() =>
                                                this.setSubHeaing(subHeading)
                                              }
                                            >
                                              {subHeading.folderName}
                                            </div>
                                          )
                                        )}
                                      </Scrollable>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-12 align-left">
                                  <span
                                    className="clause-input"
                                    style={{
                                      background: "#BEBEBE",
                                      border: "1px solid #DDDDDD",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      readOnly
                                      className="clause-input"
                                      placeholder="No Sub-Headings present"
                                      style={{
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        background: "#BEBEBE",
                                      }}
                                      value={selectedSubHeading.folderName}
                                    />
                                    <img
                                      src="/static_images/new-tag-dropdown-active.svg"
                                      alt="dropdown" /* style={{ transform: expandSubHeading ? 'rotate(180deg)' : 'none' }} */
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-12 clause-modal-heading">
                            Clause Type
                          </div>
                          <div className="col-md-8">
                            <div className="row">
                              <div className="col-md-12 align-left">
                                <span
                                  className="clause-input"
                                  style={{
                                    background: "white",
                                    border: "1px solid #DDDDDD",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      expandClauseType:
                                        !this.state.expandClauseType,
                                    })
                                  }
                                >
                                  <input
                                    type="text"
                                    readOnly
                                    className="clause-input"
                                    placeholder="Choose one option"
                                    style={{
                                      width: "100%",
                                      border: "none",
                                      outline: "none",
                                      background: "white",
                                    }}
                                    value={selectedClauseType.toUpperCase()}
                                  />
                                  <img
                                    src="/static_images/new-tag-dropdown-active.svg"
                                    alt="dropdown"
                                    style={{
                                      transform: expandClauseType
                                        ? "rotate(180deg)"
                                        : "none",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            {expandClauseType && (
                              <div className=" col-md-12 clause-options-container">
                                <div className="row ">
                                  <div
                                    className="col-md-12 clause-autocomplete-container align-left"
                                    style={{ margin: 0 }}
                                  >
                                    <Scrollable maxHeight={90}>
                                      {clauseTypeList.map((clauseType, i) => (
                                        <div
                                          className="clause-input-suggestion cursor-pointer align-left"
                                          style={{ fontSize: "14px" }}
                                          key={i}
                                          onClick={() =>
                                            this.setClauseType(clauseType)
                                          }
                                        >
                                          {clauseType}
                                        </div>
                                      ))}
                                    </Scrollable>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-12 clause-modal-heading">
                            Relevant Clause
                          </div>
                          <div className="col-md-12">
                            <textarea
                              rows={6}
                              cols={70}
                              name="text"
                              className="clause-input custom-scrollbar-checkbox"
                              placeholder={"Enter relevant clause text"}
                              value={relevantClauseText}
                              style={{ minHeight: "100px", lineHeight: "15px" }}
                              onChange={(e) => this.setRelevantText(e)}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 my-3">
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-7">
                            <span
                              className="upload-yellow-btn py-1 mb-4"
                              id="save-btn"
                              data-dismiss="modal"
                              // onClick={() => this.createClause()}
                            >
                              Create
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }

  setClauseName(event: any) {
    event.preventDefault();
    let clauseName = event.target.value;
    this.setState({ clauseName: clauseName });
  }

  setHeading(heading: FolderHeadingInfo) {
    console.log("setHeading ", heading);
    this.setState({ selectedHeading: heading, expandHeading: false });
    this.props.getFolderSubHeading(heading.folderId);
  }

  setSubHeaing(subHeading: FolderHeadingInfo) {
    this.setState({ selectedSubHeading: subHeading, expandSubHeading: false });
  }

  setClauseType(clauseType: string) {
    this.setState({ selectedClauseType: clauseType, expandClauseType: false });
  }

  setRelevantText(event: any) {
    event.preventDefault();
    let relevantText = event.target.value;
    this.setState({ relevantClauseText: relevantText });
  }

  /*createClause() {
    let {
      folderHeadingList,
      folderSubHeadingList,
      selectedClauseText,
      currentFileId,
    } = this.props;
    let {
      selectedHeading,
      selectedSubHeading,
      clauseName,
      selectedClauseType,
      relevantClauseText,
    } = this.state;
    let folderId: string = "-1";
    let id: string = "-1";
    if (folderSubHeadingList !== undefined && folderSubHeadingList.length > 0) {
      if (
        selectedHeading.folderId !== "-1" &&
        selectedSubHeading.folderId !== "-1"
      ) {
        folderId = selectedSubHeading.folderId;
      } else if (selectedHeading.folderId !== "-1") {
        folderId = selectedHeading.folderId;
      }
    } else {
      if (folderHeadingList.length > 0 && selectedHeading.folderId !== "-1") {
        folderId = selectedHeading.folderId;
      }
    }

    if (folderId !== "-1") {
      this.props.createClause(
        clauseName,
        selectedClauseText,
        relevantClauseText,
        selectedClauseType,
        currentFileId,
        folderId,
        id
      );
    } else {
      //show error, please select a folder
    }
    this.setState({
      expandHeading: false,
      expandSubHeading: false,
      expandClauseType: false,
      searchHeading: "",
      searchSubHeading: "",
      selectedHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedSubHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedClauseType: "",
      relevantClauseText: this.props.selectedClauseText,
      clauseName: "",
    });
  }*/

  closeModal = () => {
    this.setState({
      expandHeading: false,
      expandSubHeading: false,
      expandClauseType: false,
      searchHeading: "",
      searchSubHeading: "",
      selectedHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedSubHeading: {
        folderId: "-1",
        folderName: "",
        parentId: "-1",
        id: "-1",
        name: "",
      },
      selectedClauseType: "",
      relevantClauseText: this.props.selectedClauseText,
      clauseName: "",
    });
  };
}
