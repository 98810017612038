import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import moment from "moment";
import { isNullOrUndefined } from "is-what";
import ApprovalReworkPopup from "./ApprovalReworkPopup";
import ApprovalRejectionPopup from "./ApprovalRejectPopup";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
interface Props {
  setapprovals: () => void;
  approvals: any;
  updateStatus: (data: any) => void;
  get_Kdpdata: (clauseText: any) => void;
  approvalsLoader: boolean;
}
interface State {}

const ApprovalTab: React.FC<Props> = (props: Props) => {
  const [rejectionOpen, setRejectionOpen] = React.useState(false);
  const [reworkopen, setReworkOpen] = React.useState(false);
  const [user, setUser] = React.useState<any>();
  const [openSnackbar, setopenSnackbar] = React.useState({
    open: false,
    msg: "",
  });
  const [requestapproval, setrequestapproval] = React.useState<any>({
    status: "",
    id: "",
    commnts: "",
  });

  // React.useEffect(() => {
  //   props.setapprovals();
  // }, [props.approvals]);
  React.useEffect(() => {
    const user_data: string | null = localStorage.getItem("user_profile");
    if (user_data) {
      setUser(JSON.parse(user_data));
    }
  }, []);
  const updateApproveStatus = (data: any) => {
    props.updateStatus(data);
  };

  const closeSnackbar = () => {
    setopenSnackbar({ open: false, msg: openSnackbar.msg });

    //props.close();
  };

  const action = (
    <>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        OK
      </Button>
    </>
  );
  return (
    <>
      <Scrollable maxHeight={window.screen.height} padding={true}>
        <div className="d-flex flex-column mt-5" style={{ gap: "16px" }}>
          <Card style={{ display: "none" }}>
            <CardContent style={{ borderRadius: "8px" }}>
              <div>
                <div className="d-flex flex-column" style={{ gap: "16px" }}>
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0">
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                          width: "28px",
                          height: "30px",
                          background: "#FFEFE4",
                          borderRadius: "100px",
                          border: "solid",
                          borderColor: "#EE5800",
                          borderWidth: "1px",
                        }}
                      >
                        <span
                          className="fw-600"
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#EE5800",
                          }}
                        >
                          WW
                        </span>
                      </div>
                    </div>
                    <div className="col-8 p-0 m-0">
                      <span className="fw-600" style={{ color: "#818181" }}>
                        <span style={{ color: "#88305f" }}>
                          Anuradha Chandrasekaran seeks
                        </span>{" "}
                        Approval
                      </span>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-end p-0 m-0"
                      style={{ color: "#818181" }}
                    >
                      on 30/10/2022
                    </div>
                  </div>
                  {/* next line */}
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0">
                      <img
                        src="/static_images/selectrighticon.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-8 p-0 m-0">
                      <span
                        className="fw-600 d-flex align-items-center"
                        style={{ color: "#818181", gap: "5px" }}
                      >
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{
                            width: "28px",
                            height: "30px",
                            background: "#D8F7E6",
                            borderRadius: "100px",
                            border: "solid",
                            borderColor: "#00692A",
                            borderWidth: "1px",
                          }}
                        >
                          <span
                            className="fw-600"
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#00692A",
                            }}
                          >
                            DB
                          </span>
                        </div>
                        <span style={{ color: "#00692A" }}>
                          Dipankar Bandyopadhyay
                        </span>
                      </span>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-end p-0 m-0"
                      style={{ color: "#818181" }}
                    >
                      on 30/10/2022
                    </div>
                  </div>
                  {/* next line */}
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0">
                      <img
                        src="/static_images/crossicon.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-8 p-0 m-0">
                      <span
                        className="fw-600 d-flex align-items-center"
                        style={{ color: "#818181", gap: "5px" }}
                      >
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{
                            width: "28px",
                            height: "30px",
                            background: "#EEEDFF",
                            borderRadius: "100px",
                            border: "solid",
                            borderColor: "#392494",
                            borderWidth: "1px",
                          }}
                        >
                          <span
                            className="fw-600"
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#392494",
                            }}
                          >
                            KC
                          </span>
                        </div>
                        <span style={{ color: "#392494" }}>
                          Kartik Chhabria
                        </span>
                        with Comment
                      </span>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-end p-0 m-0"
                      style={{ color: "#818181" }}
                    >
                      on 30/10/2022
                    </div>
                  </div>
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0"></div>
                    <div className="col-11 p-0 m-0">
                      <div
                        className="mt-3 text-container ml-1 mr-1"
                        style={{ backgroundColor: "#E9FBFF" }}
                      >
                        <p className="p-2" style={{ fontSize: "14px" }}>
                          This is a comment. It’s a pretty big one that too.
                          This is to explain how a coment looks and feels.
                          <span style={{ color: "#EE5800" }}>
                            @Lakshmi Boggaram
                          </span>{" "}
                          is the UI styling for how a name looks when a name
                          appears in the comment. Name colour to follow Avatar
                          colour.{" "}
                          <span style={{ color: "#88305F" }}>
                            #SupportingDocName
                          </span>
                          is the styling for a name of a document in the Riverus
                          System.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* next line */}
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0">
                      <img
                        src="/static_images/crossicon.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-8 p-0 m-0">
                      <span
                        className="fw-600 d-flex align-items-center"
                        style={{ color: "#818181", gap: "5px" }}
                      >
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{
                            width: "28px",
                            height: "30px",
                            background: "#FFEFE4",
                            borderRadius: "100px",
                            border: "solid",
                            borderColor: "#EE5800",
                            borderWidth: "1px",
                          }}
                        >
                          <span
                            className="fw-600"
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#EE5800",
                            }}
                          >
                            LB
                          </span>
                        </div>
                        <span style={{ color: "#EE5800" }}>
                          Lakshmi Boggaram
                        </span>
                      </span>
                    </div>
                    <div
                      className="col-3 d-flex justify-content-end p-0 m-0"
                      style={{ color: "#818181" }}
                    >
                      on 30/10/2022
                    </div>
                  </div>
                  {/* next line */}
                  <div className=" row col-12 p-0 m-0">
                    <div className="col-1 p-0 m-0">
                      <img
                        src="/static_images/timeclockicon.svg"
                        className="pl-1 w-10"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="col-8 p-0 m-0">
                      <span
                        className="fw-600 d-flex align-items-center"
                        style={{ color: "#818181", gap: "5px" }}
                      >
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          style={{
                            width: "28px",
                            height: "30px",
                            background: "#FFEFE4",
                            borderRadius: "100px",
                            border: "solid",
                            borderColor: "#EE5800",
                            borderWidth: "1px",
                          }}
                        >
                          <span
                            className="fw-600"
                            style={{
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontSize: "12px",
                              lineHeight: "16px",
                              color: "#EE5800",
                            }}
                          >
                            MK
                          </span>
                        </div>
                        <span style={{ color: "#EE5800" }}>Mohit Kumar</span>
                      </span>
                    </div>
                    <div className="col-3 d-flex justify-content-end p-0 m-0"></div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {props.approvalsLoader ? (
            <CircularProgress className="loader" />
          ) : null}
          {Object.keys(props.approvals).map((item: any) => {
            return (
              <>
                <Card
                  onClick={() => {
                    props.get_Kdpdata(
                      props.approvals[item][0]["clause_text"].replace(
                        /\n/g,
                        " "
                      )
                    );
                  }}
                >
                  <CardContent style={{ borderRadius: "8px" }}>
                    <div>
                      <div
                        className="d-flex flex-column"
                        style={{ gap: "16px" }}
                      >
                        <div className=" row col-12 p-0 m-0">
                          <div className="col-1 p-0 m-0">
                            <div
                              className="d-flex flex-column justify-content-center align-items-center"
                              style={{
                                width: "28px",
                                height: "30px",
                                background: "#FFEFE4",
                                borderRadius: "100px",
                                border: "solid",
                                borderColor: "#EE5800",
                                borderWidth: "1px",
                              }}
                            >
                              <span
                                className="fw-600"
                                style={{
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontSize: "12px",
                                  lineHeight: "16px",
                                  color: "#EE5800",
                                }}
                              >
                                {HeimdallUtil.getNameForAvatar2(
                                  props.approvals[item][0]["created_by"] !==
                                    null
                                    ? `${props.approvals[item][0]["created_by"]["first_name"]} ${props.approvals[item][0]["created_by"]["last_name"]}`
                                    : ""
                                )}

                                {/* WW */}
                              </span>
                            </div>
                          </div>
                          <div className="col-8 p-0 m-0">
                            <span
                              className="fw-600"
                              style={{ color: "#818181" }}
                            >
                              <span style={{ color: "#88305f" }}>
                                {`${props.approvals[item][0]["created_by"]["first_name"]} ${props.approvals[item][0]["created_by"]["last_name"]}`}{" "}
                                seeks
                              </span>{" "}
                              Approval
                            </span>
                          </div>
                          <div
                            className="col-3 d-flex justify-content-end p-0 m-0"
                            style={{ color: "#818181" }}
                          >
                            on{" "}
                            {moment(
                              props.approvals[item][0]["created_on"]
                            ).format("DD/MM/YYYY")}
                            {/* 30/10/2022 */}
                          </div>
                        </div>
                        {!isNullOrUndefined(
                          props.approvals[item][0]["comments"]
                        ) && props.approvals[item][0]["comments"] !== "" ? (
                          <>
                            <div className=" row col-12 p-0 m-0">
                              <div className="col-1 p-0 m-0"></div>
                              <div className="col-11 p-0 m-0">
                                <div
                                  className="mt-3 text-container ml-1 mr-1"
                                  style={{ backgroundColor: "#E9FBFF" }}
                                >
                                  <p
                                    className="p-2"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {props.approvals[item][0]["comments"]}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}

                        {props.approvals[item].map((requestApproval: any) => {
                          return (
                            <>
                              {/* next line */}
                              <div className=" row col-12 p-0 m-0">
                                <div className="col-1 p-0 m-0">
                                  {requestApproval["status"] == "InProgress" ? (
                                    <img
                                      src="/static_images/timeclockicon.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {requestApproval["status"] == "Approved" ? (
                                    <img
                                      src="/static_images/selectrighticon.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {requestApproval["status"] == "Rejected" ? (
                                    <img
                                      src="/static_images/crossicon.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  {requestApproval["status"] == "Rework" ? (
                                    <img
                                      src="/static_images/iconsettingfile.svg"
                                      className="pl-1 w-10"
                                      style={{ cursor: "pointer" }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-6 p-0 m-0">
                                  <div
                                    className="fw-600 d-flex align-items-center"
                                    style={{ color: "#818181", gap: "5px" }}
                                  >
                                    <div
                                      className="d-flex flex-column justify-content-center align-items-center"
                                      style={{
                                        width: "28px",
                                        height: "30px",
                                        background: "#D8F7E6",
                                        borderRadius: "100px",
                                        border: "solid",
                                        borderColor: "#00692A",
                                        borderWidth: "1px",
                                      }}
                                    >
                                      <span
                                        className="fw-600"
                                        style={{
                                          fontFamily: "Inter",
                                          fontStyle: "normal",
                                          fontSize: "12px",
                                          lineHeight: "16px",
                                          color: "#00692A",
                                        }}
                                      >
                                        {/* DB */}
                                        {HeimdallUtil.getNameForAvatar2(
                                          requestApproval["approvers"] !== null
                                            ? `${requestApproval["approvers"]["first_name"]} ${requestApproval["approvers"]["last_name"]}`
                                            : ""
                                        )}
                                      </span>
                                    </div>
                                    <span style={{ color: "#00692A" }}>
                                      {/* Dipankar Bandyopadhyay */}
                                      {`${requestApproval["approvers"]["first_name"]} ${requestApproval["approvers"]["last_name"]}`}{" "}
                                    </span>

                                    {isNullOrUndefined(
                                      requestApproval["approval_comments"]
                                    ) ? (
                                      <>{"with Comment"}</>
                                    ) : null}
                                  </div>
                                </div>

                                <div
                                  className="col-5 d-flex justify-content-end p-0 m-0"
                                  style={{ color: "#818181" }}
                                >
                                  {!isNullOrUndefined(
                                    localStorage.getItem("user_id")
                                  ) ? (
                                    <>
                                      {requestApproval["approvers"]["id"] ==
                                      localStorage.getItem("user_id") ? (
                                        <>
                                          {requestApproval["status"] ==
                                          "InProgress" ? (
                                            <>
                                              {" "}
                                              <div className="col-1 p-0 m-0">
                                                <img
                                                  src="/static_images/selectrighticon.svg"
                                                  className="pl-1 w-10"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    updateApproveStatus({
                                                      status: "Approved",
                                                      id: requestApproval["id"],
                                                    });
                                                  }}
                                                />{" "}
                                              </div>
                                              <div className="col-1 p-0 m-0">
                                                <img
                                                  src="/static_images/crossicon.svg"
                                                  className="pl-1 w-10"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setRejectionOpen(true);
                                                    setrequestapproval({
                                                      ...requestapproval,
                                                      status: "Rejected",
                                                      id: requestApproval["id"],
                                                    });

                                                    /*updateApproveStatus({
                                                    status: "Rejected",
                                                    id: requestApproval["id"],
                                                  });
                                                  */
                                                  }}
                                                />{" "}
                                              </div>
                                              <div className="col-1 p-0 m-0">
                                                <img
                                                  src="/static_images/iconsettingfile.svg"
                                                  className="pl-1 w-10"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setReworkOpen(true);
                                                    setrequestapproval({
                                                      ...requestapproval,
                                                      status: "Rework",
                                                      id: requestApproval["id"],
                                                    });
                                                    /*updateApproveStatus({
                                                    status: "Rework",
                                                    id: requestApproval["id"],
                                                  });*/
                                                  }}
                                                />{" "}
                                              </div>
                                            </>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}{" "}
                                  on{" "}
                                  {moment(requestApproval["created_on"]).format(
                                    "DD/MM/YYYY"
                                  )}
                                </div>
                              </div>
                              {!isNullOrUndefined(
                                requestApproval["approval_comments"]
                              ) ? (
                                <>
                                  <div className=" row col-12 p-0 m-0">
                                    <div className="col-1 p-0 m-0"></div>
                                    <div className="col-11 p-0 m-0">
                                      <div
                                        className="mt-3 text-container ml-1 mr-1"
                                        style={{ backgroundColor: "#E9FBFF" }}
                                      >
                                        <p
                                          className="p-2"
                                          style={{ fontSize: "14px" }}
                                        >
                                          {requestApproval["approval_comments"]}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </>
            );
          })}
        </div>
      </Scrollable>
      {reworkopen && (
        <ApprovalReworkPopup
          reworkopen={reworkopen}
          user={user}
          reqres={props.approvals}
          setopenSnackbar={() => {
            setopenSnackbar({ open: true, msg: "rework required" });
          }}
          close={() => setReworkOpen(false)}
          handlerework={(comment: string) => {
            updateApproveStatus({
              status: "Rework",
              id: requestapproval.id,
              comment: comment,
            });
          }}
        />
      )}

      {rejectionOpen && (
        <ApprovalRejectionPopup
          reqres={props.approvals}
          user={user}
          rejectionOpen={rejectionOpen}
          setopenSnackbar={() => {
            /*setopenSnackbar({
               open: true,
               msg: "Approval Reject sucessfully",
             });*/
          }}
          handlerejection={(comment: string | null) => {
            updateApproveStatus({
              status: requestapproval.status,
              id: requestapproval.id,
              comment: comment,
            });
          }}
          close={() => setRejectionOpen(false)}
        />
      )}
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar.open}
        onClose={closeSnackbar}
        message={openSnackbar.msg}
        key="bottomCenter"
        action={action}
      />
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalTab);
