import * as React from "react";
import { connect } from "react-redux";
import AppActionGenerator from "../../App/Actions/actionGen";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { AnyARecord } from "dns";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import internal from "stream";
import { ObjectLiteralElementLike } from "typescript";
import {
  getexternalgroups,
  getinternalgroups,
  sendemailtoapproval,
} from "../DA";

const hierarchyStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  minHeight: "420px",
  maxHeight: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: " 8px 8px 8px 8px",
};

interface Props {
  openModal: string;
  handleCloseShareBtn: any;
  SelectedList: any;
  setOpenSuccessShare: any;
}

const ShareversionModal: React.FC<Props> = (props: Props) => {
  const [internalData, setInternal] = useState([]);
  const [externalData, setExternal] = useState([]);
  const [internalName, setInternalName] = React.useState<any>([]);
  const [externalName, setExternalName] = React.useState<any>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    handleInternalSelect();
    handleExternalSelect();
    const user_data: string | null = localStorage.getItem("user_profile");
    if (user_data) {
      setUser(JSON.parse(user_data));
    }
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const handleInternalSelect = async () => {
    // AxiosGateWay.get(
    //   process.env.REACT_APP_RIVERUS_CLM_API +
    //     `users/groups/users/?group_type=internal`
    // )
    //   .then((response) => {
    //     setInternal(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const response = await getinternalgroups();
    setInternal(Array.from(new Set(response)));
  };
  const handleExternalSelect = async () => {
    // AxiosGateWay.get(
    //   process.env.REACT_APP_RIVERUS_CLM_API +
    //     `users/groups/users/?group_type=external`
    // )
    //   .then((response) => {
    //     setExternal(Array.from(new Set(response.data)));
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    const response = await getexternalgroups();
    setExternal(Array.from(new Set(response)));
  };
  const handleInternal = (event: any) => {
    const {
      target: { value },
    } = event;
    setInternalName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleExternal = (event: any) => {
    const {
      target: { value },
    } = event;
    setExternalName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleCLick = () => {
    const externalSelectID = externalData
      ?.filter((val: any) => externalName?.includes(val.first_name))
      ?.map((data: any) => data);
    const internalSelectID = internalData
      ?.filter((val: any) => internalName?.includes(val.first_name))
      ?.map((data: any) => data);
    const shared_to = Array.from(
      new Set(externalSelectID.concat(internalSelectID))
    );

    shared_to.forEach((i: any) => {
      let payload = {
        user_ids: [i.id],
        body_html: `<h2>Hello ${i.username},<br/></h2><p>${user.first_name} has shared a  draft  with you for your reference.</p><p>Best Regards,</p><p>${user.first_name}</p>`,
        body_text: `${user.first_name} has shared a draft with you for your reference.`,
        subject: `Riverus CLM Shared a Draft`,
        link: props.SelectedList[0]?.requisition_approver_link
          ? props.SelectedList[0]?.requisition_approver_link
          : props.SelectedList[0]?.link,
      };
      try {
        sendemailtoapproval(payload);
      } catch (err) {
        console.log(err, "err");
      }
    });
    props.setOpenSuccessShare(true);
  };

  return (
    <>
      <div>
        <Modal
          open={props.openModal === "modalShare"}
          className="deletemodal"
          onClose={() => props.handleCloseShareBtn()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={hierarchyStyle} className="position-relative">
            <Typography
              id="modal-modal-title"
              // variant="h6"
              // component="h2"
              className="myDocument-deleteFileTitle"
            >
              <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
                Share With
                <span
                  className="mr-0"
                  onClick={() => props.handleCloseShareBtn()}
                >
                  <img
                    src={"/static_images/Group 3610.svg"}
                    alt=""
                    style={{
                      height: "24px",
                      width: "24px",
                      color: "#818181",
                      cursor: "pointer",
                    }}
                  />
                </span>
              </div>
            </Typography>
            <Typography style={{ margin: "20px" }}>
              <div className="row">
                <div className="col-md-7">
                  <div>
                    <div className="d-flex justify-content-md-between">
                      <span className="addTag-title ">Internal Users</span>
                      <span className="addtag-reset">Reset</span>
                    </div>

                    <div className="mt-1">
                      <FormControl style={{ width: "272px" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={internalName}
                          onChange={(val) => handleInternal(val)}
                          // style={{height:"26px"}}
                          input={<OutlinedInput />}
                          renderValue={(selected: any) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 240,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {internalData?.map((name: any, index: any) => {
                            return (
                              <MenuItem key={index} value={name?.first_name}>
                                <Checkbox
                                  checked={
                                    internalName.indexOf(name?.first_name) > -1
                                  }
                                />
                                <ListItemText primary={name?.first_name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="mt-6">
                    <div className="d-flex justify-content-md-between">
                      <span className="addTag-title ">External Users</span>
                      <span className="addtag-reset">Reset</span>
                    </div>

                    <div className="mt-1">
                      <FormControl style={{ width: "272px" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={externalName}
                          onChange={(val) => handleExternal(val)}
                          // style={{height:"26px"}}
                          input={<OutlinedInput />}
                          renderValue={(selected: any) => selected.join(", ")}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 240,
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {externalData?.map((name: any, index: any) => {
                            return (
                              <MenuItem key={index} value={name?.first_name}>
                                <Checkbox
                                  className="checkbox-custom"
                                  checked={
                                    externalName.indexOf(name?.first_name) > -1
                                  }
                                />
                                <ListItemText primary={name?.first_name} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </Typography>
            <div
              className=" d-flex justify-content-end   "
              style={{ paddingTop: "50px" }}
            >
              <button
                className="addtag-addHierarchy-addbtn mr-4"
                onClick={() => handleCLick()}
              >
                <span className="addtag-addHierarchy-addbtn-title">Share</span>
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    pageWatcher: (page: string) =>
      dispatch(AppActionGenerator.pageWatcher(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareversionModal);
