import React from "react";
import { OverlayTrigger, Button, Tooltip } from "react-bootstrap";
import { MdInsights } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { BsListNested } from "react-icons/bs";
import { MdRawOn } from "react-icons/md";
import { BiCommentDetail } from "react-icons/bi";
import { VscVersions, VscLayersActive } from "react-icons/vsc";
import { getParentHighlights } from "./Utils/docUtils";
interface Props {
  windowMenu: {
    topMenu: boolean;
    selectedTopMenu: string;
    rightMenu: boolean;
    selectedRightMenu: string;
  };
  handleWindowMenu: (
    menuType: string,
    menuTypeValue: boolean,
    selectedMenu: string
  ) => void;
}
const RightMenu = (props: Props) => {
  const { windowMenu, handleWindowMenu } = props;
  const { rightMenu, selectedRightMenu } = windowMenu;
  return (
    <div className="row">
      <div
        className="col-md-1 pl-0 sidenav-layout"
        style={{
          right: 0,
          paddingTop: 100,
          width: "min-content",
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-4 sidenav-theme">
            {rightButtons.map((btn, i) => (
              <OverlayTrigger
                key={i}
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip({ title: btn.text })}
              >
                <Button
                  style={{ marginBottom: 5 }}
                  className="actionButton"
                  variant={
                    selectedRightMenu === btn.name
                      ? "warning"
                      : "outline-warning"
                  }
                  size="sm"
                  onClick={() => {
                    selectedRightMenu === btn.name
                      ? handleWindowMenu("right", false, "")
                      : handleWindowMenu("right", true, btn.name);
                  }}
                >
                  {btn.icon}
                </Button>
              </OverlayTrigger>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightMenu;
const rightButtons = [
  {
    icon: <MdInsights size={16} />,
    name: "viewInsights",
    text: "View Insights",
  },
  {
    icon: <FaRegEdit size={16} />,
    name: "editInsights",
    text: "Edit Insights",
  },
  // {
  //     icon: <BsListNested size={24} />,
  //     name: 'toc',
  //     text: 'Table Of Content',
  // },
  /*{
    icon: <MdRawOn size={16} />,
    name: "raw",
    text: "Original File",
  },
  {
    icon: <VscLayersActive size={16} />,
    name: "assessments",
    text: "Issues",
  },
  {
    icon: <VscVersions size={16} />,
    name: "versions",
    text: "All Edited Versions",
  },*/
];
const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    {props.title ? props.title : "NA"}
  </Tooltip>
);
