import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "../Design/PageNavigation.scss";

interface Props {
  pageSize: number;
  totalCount: number;
  updatePageNumber: (pageNumber: number) => void;
  pageNumber: number;
}
const PageNavigation = (props: Props) => {
  const [pageNumber, setpageNumber] = useState(1);
  useEffect(() => {
    if (props.pageNumber) {
      setpageNumber(props.pageNumber);
    }
    return () => {
      setpageNumber(1);
    };
  }, [props.pageNumber]);

  return (
    <div className="page_navigation mt-2 df mb-2">
      <div className="df">
        <div className="page_navigation__container df">
          <Pagination
            count={Math.ceil(
              Number(props.totalCount) / Number(props.pageSize)
            )}
            page={pageNumber}
            onChange={(event, value) => {
              setpageNumber(value);
              props.updatePageNumber(value);
            }}
            variant="outlined"
            shape="rounded"
            className="mr-2 pagination-background"
          />
        </div>
      </div>
    </div>
  );
};

export default PageNavigation;
