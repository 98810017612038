import React from "react";
import { ContractRes } from "../../Draft/State/DraftState";
import ListViewHeader from "./ListViewHeader";
import ListViewTable from "./ListViewTable";

interface Props {
    getApprovals: () => void;
    draftContractData: ContractRes[];
}

const MyApprovalListView = (props: Props) => {
    const setSortAndOrder = () => { };
    const getAllTasksData = () => { return [] };
    return (
        <div className="col-md-12 p-0" style={{}}>
            <div className="row" style={{ marginLeft: '0rem', marginRight: '0rem', backgroundColor: 'white' }}>
                <ListViewHeader />
                <ListViewTable getDrafts={props.getApprovals} draftContractData={props.draftContractData} />
            </div>
        </div>
    )

}

export default MyApprovalListView;