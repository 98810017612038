import { useEffect, useState } from "react";
import HeimdallUtil from "../../UniversalComponents/HeimdallChild/HeimdallUtil/heimdallUtil";
import { getGroups, getUserFromId, getUsers } from "../DA";
import { User } from "../State/DraftState";

function useMultipleUsers() {
  const [approverList, setapprovers] = useState<User[]>([]);
  const [collaboratorList, setcollaborators] = useState<User[]>([]);
  const [signatoriesList, setsignatoriesList] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState("");
  const [groupList, setgroups] = useState<any[]>([]);

  useEffect(() => {
    getUserData();
    getCurrentUser();
  }, []);

  const getUserData = async () => {
    getUsers("Collaborators")
      .then((response) => response.results)
      .then((collab) => {
        setcollaborators(collab);
      });
    getUsers("Approvers")
      .then((response) => response.results)
      .then((approv) => {
        setapprovers(approv);
      });
    getUsers("Signatories")
      .then((response) => response.results)
      .then((sign) => {
        setsignatoriesList(sign);
      });
    getGroups().then((res) => {
      const data = res.filter((item: any) => {
        if (item.name === "/org" || item.name === "/file-manager") {
          return;
        } else {
          return item;
        }
      });
      setgroups(data);
    });
  };

  const getCurrentUser = async () => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      const user = await getUserFromId(user_id);
      if (user != null && user) {
        setCurrentUser(HeimdallUtil.getNameForAvatar(user));
      }
    }
  };
  return {
    approverList,
    collaboratorList,
    currentUser,
    signatoriesList,
    groupList,
  };
}

export default useMultipleUsers;
