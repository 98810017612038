import React, { Component } from "react";
import { History } from "history";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import BarLoader from "../../UniversalComponents/Loader/barLoader";
import SideNavbar from "../../UniversalComponents/SideNavbar/Container/sideNavBarCon";
import UploadHeader from "../../Upload/Component/uploadHeader";
import { FileList } from "../../Upload/State/uploadState";
import FileListComponent from "../../Upload/Component/fileListComponent";
import QuickLook from "../../Upload/Component/quickLook";
import DeleteFileModal from "../../UniversalComponents/Modals/DeleteFileModal/deleteFileCon";

//------------------
import {
  contractPageSize,
  SITE_API_BY_REALM_NAME,
} from "../../Configuration/global";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../Authentication/Actions/authentication";
import Upload from "../../Upload/Component/Upload";
import moment from "moment";

import { ContractRes } from "../../Draft/State/DraftState";
import { FilterData, Sort } from "../State/historyState";
import { Checkbox, Tooltip } from "@material-ui/core";
import { isNullOrUndefined } from "is-what";
import fileStatusCon from "../../UniversalComponents/Modals/FileStatusModal/Container/fileStatusCon";
import axios from "axios";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
//-----

let url = SITEAPI;
const options = {
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    Origin: process.env.REACT_APP_RIVERUS_CLM_APP_HOST,
  },
};

interface Props {
  pageWatcher: (page: string) => void;
  getUserUploads: (page: number) => void;
  changeStatus: () => void;
  userName: string;
  userUploads: FileList[];
  historyLoader: boolean;
  history: History;
  saveDeleteDetails: (documentName: string, uniqueFileId: string) => void;
  // list:any[]
  contractCount: any;
}

interface Props {
  draftContractData: ContractRes[];
  selectedDraft: string[];
  selectMultipleDrafts: (id: string) => void;
  openVersionHistory: (draftId: string) => void;
}

interface State {
  selectedFilter: string;
  uploadsArray: FileList[];
  showContractdialog: Boolean;
  showSupportingContractdialog: Boolean;
  lastDate: FileList[];
  FilterKeyVals: FilterData;
  List: FileList[];
  SelectedList: FileList[];
  MasterChecked: boolean;
  openSelect: boolean;
  fileTypeSelect: any;
  deleteItemSelect: any;
  page: any;
  page_size: number;
  totalDocuments: number;
  contract_statistics: any;
  pageNumber: any;
  deleteLoader: boolean;
}
interface Children {
  a: any;
}
export default class HistoryContainer extends Component<
  Props,
  State,
  Children
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedFilter: "totalUploads",
      uploadsArray: props.userUploads,
      showContractdialog: false,
      showSupportingContractdialog: false,
      lastDate: [],
      FilterKeyVals: {},
      List: props.userUploads,
      MasterChecked: false,
      SelectedList: [],
      openSelect: false,
      fileTypeSelect: "Contract",
      deleteItemSelect: [],
      page: 1,
      page_size: contractPageSize,
      totalDocuments: props.contractCount,
      contract_statistics: null,
      pageNumber:1,
      deleteLoader: false,
    };
  }

  selectFilter = (filter: string) => {
    let { selectedFilter } = this.state;
    let { userUploads } = this.props;
    /* if (selectedFilter !== filter) {
      this.setState({ selectedFilter: filter });
      switch (filter) {
        case "totalUploads": {
          this.setState({ uploadsArray: userUploads });
          break;
        }
        case "processed": {
          let uploadsArray: FileInfo[] = [];
          userUploads.map((uploads) => {
            if (uploads.fileState.progressState.process === DONE) {
              uploadsArray.push(uploads);
            }
          });
          this.setState({ uploadsArray: uploadsArray });
          break;
        }
        case "uploadSize": {
          break;
        }
        default: {
          this.setState({ uploadsArray: [] });
          break;
        }
      }
    } else {
      this.setState({ selectedFilter: filter });
    }*/
  };
  documentstatistics = () => {
    AxiosGateWay.get(
      process.env.REACT_APP_RIVERUS_CLM_API + `contracts/statistics/`
    )
      .then((response) => {
        this.setState({
          contract_statistics: response.data.contracts_statistic,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    let user_id = "";
    const userId = localStorage.getItem("user_id");
    if (userId) {
      user_id = userId;
    }

    let ws = new WebSocket(
      process.env.REACT_APP_RIVERUS_CLM_WS_API +
        `ws/contracts/?token=${user_id}`
      // "ws://localhost:8000/ws/contracts/?token=" + user_id
      // "ws://localhost:8000/ws/contracts/?token=ea741f40-a001-4c3f-9a38-ef2d59de456d"
    );
    let { getUserUploads, pageWatcher, changeStatus } = this.props;
    
    this.documentstatistics();
    getUserUploads(1);
    pageWatcher("uploads");
    changeStatus();

    ws.onopen = () => {
      console.log("Opened");
    };
    ws.onclose = () => {
      ws = new WebSocket(
        process.env.REACT_APP_RIVERUS_CLM_WS_API +
          `ws/contracts/?token=${user_id}`
        // "ws://localhost:8000/ws/contracts/?token=" + user_id
        // "ws://localhost:8000/ws/contracts/?token=ea741f40-a001-4c3f-9a38-ef2d59de456d"
      );
      console.log("Closed");
    };

    ws.onmessage = (event) => {
      let contract_status_data: any = JSON.parse(event.data)["message"];

      let uploaded_contracts: any = this.state.uploadsArray;
      for (let i = 0; i < contract_status_data.length; i++) {
        let index = this.state.uploadsArray.findIndex(
          (obj: any) => obj.id === contract_status_data[i]["id"]
        );

        if (index > -1) {
          uploaded_contracts[index].status = contract_status_data[i]["status"];
          uploaded_contracts[index].percentage =
            contract_status_data[i]["percentage"];
          this.setState({
            uploadsArray: uploaded_contracts,
          });
        }
      }

      console.log("Got Mesaage: ", JSON.parse(event.data));
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.userUploads !== nextProps.userUploads) {
      this.setState({
        uploadsArray: nextProps.userUploads,
        totalDocuments: nextProps.contractCount,
      });
    }
  }

  handleDialog = () => {
    this.setState({ showContractdialog: !this.state.showContractdialog });
  };

  handleSupportDialog = () => {
    this.setState({
      showSupportingContractdialog: !this.state.showSupportingContractdialog,
    });
  };
  applySort = (nameSort: boolean, sortname: string) => {
    let { uploadsArray } = this.state;
    if (sortname === "file_name") {
      if (nameSort) {
        uploadsArray.sort((a, b) => {
          if (
            a.file_name.toLocaleLowerCase() > b?.file_name.toLocaleLowerCase()
          ) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      } else {
        uploadsArray.sort((a, b) => {
          if (
            a.file_name.toLocaleLowerCase() < b?.file_name.toLocaleLowerCase()
          ) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      }
    }
    if (sortname === "created_on") {
      if (nameSort) {
        uploadsArray.sort((a, b) => {
          if (a.created_on > b?.created_on) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      } else {
        uploadsArray.sort((a, b) => {
          if (a.created_on < b?.created_on) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      }
    }
    if (sortname === "file_size") {
      if (nameSort) {
        uploadsArray.sort((a, b) => {
          if (a.file_size > b?.file_size) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      } else {
        uploadsArray.sort((a, b) => {
          if (a.file_size < b?.file_size) {
            return 1;
          }
          return -1;
        });
        this.setState({ uploadsArray: uploadsArray });
      }
    }
  };

  applyFilter = (searchKey: string[], filterKey: string) => {
    this.setState({
      FilterKeyVals: {
        ...this.state.FilterKeyVals,
        [filterKey]: [...searchKey],
      },
    });
  };

  onMasterCheck = (e: any) => {
    const { checked } = e.target;
    let tempList = [...this.state.uploadsArray];
    const a = tempList.map((user: any) => (user.selected = checked));
    let checkedItem = tempList.map((arr) => {
      return { id: arr.id, name: arr.file_name };
    });
    this.setState({
      MasterChecked: e.target.checked,
      uploadsArray: tempList,
      SelectedList: this.state.uploadsArray.filter((e: any) => e.selected),
      deleteItemSelect: checkedItem,
    });
  };

  onItemCheck = (e: any, item: any) => {
    if (e.target.checked) {
      this.setState({
        deleteItemSelect: [
          ...this.state.deleteItemSelect,
          { name: item.file_name, id: item.id },
        ],
      });
    } else {
      let a = this.state.deleteItemSelect.filter((i: any) => i.id !== item.id);
      this.setState({ deleteItemSelect: a });
    }

    let tempList = [...this.state.uploadsArray];

    tempList?.map((user: any) => {
      if (user.id === item.id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State

    const totalItems = this.state?.uploadsArray?.length;
    

    const totalCheckedItems = tempList.filter((e: any) => e.selected).length;

    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      List: tempList,
      SelectedList: this.state.uploadsArray.filter((e: any) => e.selected),
    });
  };

  mostRecentMemory = (uploadsArray: any) => {
    if (Array.isArray(uploadsArray) && uploadsArray.length > 0) {
      const memory = uploadsArray.reduce((a, b) =>
        a.created_on > b.created_on ? a : b
      );
      return memory.created_on;
    }
  };

  updateState = (name: any) => {
    this.setState({ fileTypeSelect: name });
  };

  handleDeleteSelectedList = () => {
    
    if (this.state.deleteItemSelect && this.state.deleteItemSelect.length) {
      const id = this.state.deleteItemSelect?.map((val: any) => val.id);
      this.setState({deleteLoader:true, SelectedList: []})
      // this.state.deleteItemSelect.forEach((val: any) => {
        // this.props.saveDeleteDetails(val.name,val.id)
        axios
          .delete(
            process.env.REACT_APP_RIVERUS_CLM_API + `contracts/bulk-delete/`,
            {
              headers: {
                Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
              },
              data: { id: id },
            }
          )
          .then((res) => {
            this.setState({
              deleteLoader:false,
              pageNumber:1,
              page: 1,
              SelectedList: [],
            },()=>{
              this.props.getUserUploads(1);
              this.documentstatistics();
            })
          })
          .catch((error) => console.log(error));
      // });
      //window.location.reload();
    }
  };

  handleChange=(e:any)=>{
    
    if(isNaN(parseInt(e.target.value))){
   
      this.setState({page:""})
    }else{

      

      if(parseInt(e.target.value) > Math.ceil(
        this.state
          .totalDocuments /
          10)){
           
          this.setState({
            page: Math.ceil(
              this.state
                .totalDocuments /
                10)
          },()=>{
            
          })
      }else{
      
        this.setState({page:parseInt(e.target.value)})
      }
  }
  }

  switchRender() {
    let { historyLoader, history, saveDeleteDetails, getUserUploads } =
      this.props;
    let { uploadsArray } = this.state;

    if (historyLoader || this.state.deleteLoader) {
      return <BarLoader />;
    } else {
      return (
        <>
          {uploadsArray.length > 0 && (
            <div className="row">
              <div className="col-md-12 mt-2">
                <UploadHeader
                  applySort={this.applySort}
                  applyFilter={this.applyFilter}
                  FilterKeyVals={this.state.FilterKeyVals}
                  onMasterCheck={this.onMasterCheck}
                  MasterChecked={this.state.MasterChecked}
                  contractPageCount={this.state.page}
                />
              </div>
            </div>
          )}

          {uploadsArray.length > 0 && (
            <>
              <div className="row FileListComponent">
                <div className="col-md-12 upload-list">
                  {/* <Scrollable maxHeight={370} minHeight={"50vh"}> */}
                    {uploadsArray.map((file, i) => (
                      <React.Fragment key={i}>
                        <FileListComponent
                          file={file}
                          // isCheckedall={this.state.isCheckedAll}
                          history={history}
                          SelectedList={this.state.SelectedList}
                          from={"uploads"}
                          saveDeleteDetails={saveDeleteDetails}
                          onItemCheck={this.onItemCheck}
                        />
                      </React.Fragment>
                    ))}
                    <br />
                    <div className="d-flex flex-row-reverse">
                      <div>
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-end">
                            <li
                              className="page-item"
                              onClick={() => {
                                if (
                                  this.state.page != 1 &&
                                  this.state.totalDocuments != 0
                                ) {
                                  this.setState(
                                    {
                                      page: (isNaN(parseInt(this.state.page))?2:parseInt(this.state.page)) - 1,
                                      pageNumber: (isNaN(parseInt(this.state.page))?2:parseInt(this.state.page)) - 1,
                                      // hierarchyListLoader: true,
                                    },
                                    () => {}
                                  );
                                  getUserUploads((isNaN(parseInt(this.state.page))?2:parseInt(this.state.page)) - 1);
                                }
                              }}
                            >
                              <a
                                className="page-link"
                                aria-label="Previous"
                                style={{
                                  // color: "#88305f",

                                  backgroundColor: "#88305f",
                                  borderColor: "#88305f",
                                  color: "white",
                                }}
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                              </a>
                            </li>
                            <li className="page-item ">
                              <input
                                // disabled={true}
                                type={"number"}
                                // defaultValue={this.state.page}
                                value={this.state.page}
                                style={{
                                  height: "38px",
                                  width: "45px",
                                  borderTopColor: "#88305f",
                                  borderBottomColor: "#88305f",
                                }}
                                minLength={1}
                                maxLength={Math.ceil(
                                  this.state.totalDocuments /
                                    this.state.page_size
                                )}
                                onKeyDown={(e)=>{
                                  if(e.key == 'Enter'){
                                  if(parseInt(this.state.page) > Math.ceil(
                                    this.state
                                      .totalDocuments /
                                      10)){
                                      this.setState({
                                        page: Math.ceil(
                                          this.state
                                            .totalDocuments /
                                            10),
                                        pageNumber: Math.ceil(
                                          this.state
                                            .totalDocuments /
                                            10)
                                      }, ()=>{
                                        getUserUploads(parseInt(this.state.page));
                                      })
                                  }
                                  else if(isNaN(parseInt(this.state.page))){
                                    this.setState({page:1,pageNumber: 1},()=>{
                                      getUserUploads(parseInt(this.state.page));
                                    })
                                  }
                                  else{
                                    this.setState({
                                      pageNumber: this.state.page
                                    },()=>{
                                      getUserUploads(parseInt(this.state.page));
                                    })
                                    
                                      
                                    
                                  }
                                  }
                                }}
                                
                                onChange={(e)=>{
                                  
                                  this.handleChange(e);
                                
                                }}
                                /*onChange={(e) => {
                                  if (
                                    parseInt(e.target.value) > 0 &&
                                    parseInt(e.target.value) <=
                                      Math.ceil(
                                        this.state.totalDocuments /
                                          this.state.page_size
                                      )
                                  ) {
                                    this.setState(
                                      {
                                        page: parseInt(e.target.value),
                                      },
                                      () => {}
                                    );
                                    getUserUploads(parseInt(e.target.value));
                                  }
                                }}*/
                              />
                            </li>
                            <li
                              className="page-item"
                              onClick={() => {
                               

                                if (
                                  Math.ceil(
                                    this.state.totalDocuments /
                                      this.state.page_size
                                  ) != this.state.page &&
                                  this.state.totalDocuments != 0
                                ) {
                                  this.setState(
                                    {
                                      page: (isNaN(parseInt(this.state.page))?0:parseInt(this.state.page)) + 1,
                                      pageNumber: (isNaN(parseInt(this.state.page))?0:parseInt(this.state.page)) + 1,
                                    },
                                    () => {}
                                  );
                                  getUserUploads((isNaN(parseInt(this.state.page))?0:parseInt(this.state.page)) + 1);
                                }
                              }}
                            >
                              <a
                                className="page-link"
                                aria-label="Next"
                                style={{
                                  // color: "#88305f"
                                  backgroundColor: "#88305f",
                                  borderColor: "#88305f",
                                  color: "white",
                                }}
                              >
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                      <div>
                        <p style={{ marginTop: "6px" }}>
                          Displaying page{" "} {this.state.pageNumber} out of {Math.ceil(
                                                      this.state
                                                        .totalDocuments /
                                                        10
                                                    )} pages
                          {/* {(this.state.page - 1) * this.state.page_size + 1} to{" "}
                          {(this.state.page - 1) * this.state.page_size +
                            1 +
                            (this.state.page_size - 1) >
                          this.state.totalDocuments
                            ? this.state.totalDocuments
                            : (this.state.page - 1) * this.state.page_size +
                              1 +
                              (this.state.page_size - 1)}{" "}
                          of {this.state.totalDocuments} items*/}
                            &nbsp;&nbsp;
                        </p>
                      </div>
                    </div>
                  {/* </Scrollable> */}
                </div>
              </div>
            </>
          )}
          {/* {uploadsArray.length === 0 && (
            <div className="row">
              <div className="col-md-12 text-center mt-5">
                <div className="tagline">
                  Digitize your contracts and get instant insights!
                </div>
                <img
                  className="cursor-pointer"
                  src="/static_images/go-back-upload-img.svg"
                  onClick={() => (window.location.href = "/addfiles")}
                />
              </div>
            </div>
          )} */}
        </>
      );
    }
  }

  render() {
    let { userUploads, getUserUploads, history, saveDeleteDetails } =
      this.props;
    let {
      selectedFilter,
      showContractdialog,
      showSupportingContractdialog,
      uploadsArray,
      SelectedList,
    } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-md-1 mt-5" style={{ zIndex: 2 }}>
            <SideNavbar history={history} />
          </div>
          <div className="col-md-11 mt-5 pr-5">
            <div className="row pr-4">
              <div className="col-md-3 mt-3 pl-0">
                <h3>Upload Contracts</h3>
                <p>
                  Last Updated on
                  {uploadsArray && uploadsArray.length
                    ? moment(this.mostRecentMemory(uploadsArray)).format(
                        " DD MMM, YYYY"
                      )
                    : ""}
                </p>
              </div>
              <div
                className="col-md-6"
                style={{
                  marginTop: "26px",
                  // paddingLeft: "50px",
                  fontWeight: "400",
                  color: "#6d6d6d",
                }}
              >
                {" "}
                {/* Select Contract to Upload an Amendment Document OR Supporting
                Document */}
              </div>
              <div className="col-md-3 mt-3 pr-1">
                <div
                  className="d-flex justify-content-end"
                  style={{ gap: "10px" }}
                >
                  <button
                    type="button"
                    className={`btn btn-warning navigate-btn mb-2  ${
                      SelectedList[0]?.uploaded_type === "Support Document"
                        ? "cursor-disabled"
                        : ""
                    }`}
                    onClick={() => {
                      // gotoStyllus();
                      // this.props.history.push("/templates");
                      this.handleDialog();
                    }}
                    disabled={
                      SelectedList[0]?.uploaded_type === "Support Document"
                        ? true
                        : false
                    }
                  >
                    <Tooltip title={"Select Contract to Upload an Amendment Document OR Support Document"} placement={"top"}>
                      <div>
                        <span className="pr-2">
                          {" "}
                          <img src={"/static_images/addfiles-icn.svg"} alt="" />
                        </span>
                        Upload Contract
                      </div>
                    </Tooltip>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-warning navigate-btn mb-2 ${
                      SelectedList.length > 0
                        ? SelectedList[0]?.uploaded_type === "Support Document"
                          ? "cursor-disabled"
                          : ""
                        : "cursor-disabled"
                    }`}
                    disabled={
                      SelectedList.length > 0
                        ? SelectedList[0]?.uploaded_type === "Support Document"
                          ? true
                          : false
                        : true
                    }
                    onClick={() => {
                      // gotoStyllus();
                      // this.props.history.push("/templates");
                      this.handleSupportDialog();
                    }}
                  >
                    <span className="pr-0">
                      {" "}
                      <img src={"/static_images/addfiles-icn.svg"} alt="" />
                    </span>
                    Upload Support Documents
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-warning navigate-btn mb-2"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <span>
                      {" "}
                      <img src={"/static_images/Refresh.svg"} alt="" />
                    </span>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="">
              <QuickLook
                fileInfo={userUploads}
                selectFilter={this.selectFilter}
                selectedFilter={selectedFilter}
                contract_statistics={this.state.contract_statistics}
              />
            </div>
            <div className="row pr-4">
              <div className="col-md-12">
                <div className="row ">
                  <div
                    className="row col-md-6 mt-4 d-flex "
                    style={{ marginLeft: "0", paddingLeft: "0px" }}
                  >
                    {/* <input
                      className="checkbox ml-2"
                      style={{ width: "24px", height: "30px" }}
                      type="checkbox"
                      name="check"
                      id="check"
                      onClick={() =>
                        this.setState({ openSelect: !this.state.openSelect })
                      }
                    /> */}

                    <div>
                      <img
                        src={"/static_images/uploadRefreshIcon.svg"}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          marginLeft: "10px ",
                        }}
                        onClick={() => getUserUploads(1)}
                      />
                    </div>
                  </div>

                  <div
                    className="row  col-md-6 justify-content-end d-flex mt-4   "
                    style={{ marginRight: "0px", paddingRight: "0px" }}
                  >
                    {SelectedList.length > 1 && (
                      <div className="d-flex">
                        <div className="">
                          {/* <img
                            src="/static_images/edit-task-icn.svg"
                            alt=""
                            style={{
                              width: "24px",
                              height: "30px",
                              marginRight: "8px",
                            }}
                          /> */}
                        </div>
                        <div className="">
                          <Tooltip title={"Delete file"} placement="right-end">
                            <img
                              onClick={() => {
                                this.handleDeleteSelectedList();
                              }}
                              style={{
                                width: "24px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              src="/static_images/Delete_upload.svg"
                              alt="delete-icn"
                            />
                          </Tooltip>
                          {/* {uploadsArray.map((i) => {
                            return (
                              <div className=" tooltip ">
                                {i.status === "Not implemented" ? (
                                  <Tooltip
                                    title={"Delete file"}
                                    placement="right-end"
                                  >
                                    <img
                                     style={{
                                      width: "24px",
                                      height: "30px",
                                      cursor: "pointer",
                                    }}
                                      onClick={() =>
                                        this.displayDeleteModal(
                                          i.file_name,
                                          i.id
                                        )
                                      }
                                    
                                      src="/static_images/Delete_upload.svg"
                                      alt="delete-icn"
                                    />
                                  </Tooltip>
                                ) : (
                                  <img
                                    src="/static_images/Delete_upload.svg"
                                    alt="delete-icn"
                                  />
                                )}
                              </div>
                              
                            );
                          })} */}

                          <button
                            style={{ display: "none" }}
                            type="button"
                            data-toggle="modal"
                            data-target="#deleteModal"
                            id="deleteButton"
                          ></button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <ul className="p-2" style={{border:"2px solid #DDDDDD", display:"inline-block ", color:"#4C4C4C"}} >
                    <li >All</li>
                    <li>None</li>
                    <li>Read</li>
                    <li>Unread</li>
                    <li>Favourited</li>
                    <li> NOt Favourited</li>
                  </ul> */}

                {/* {userUploads.length > 0 && (
                <div
                className="col-md-3 message-link"
                onClick={() => (window.location.href = "/addfiles")}
                >
                Go back to uploading files
                </div>
              )} */}
                {this.switchRender()}
                <DeleteFileModal />
              </div>
            </div>
          </div>
        </div>

        {this.state.showContractdialog && (
          <Upload
            handleDialog={this.handleDialog}
            openmodal={this.state.showContractdialog}
            handleShowBtn={true}
            SelectedList={SelectedList}
            uploadsArray={uploadsArray}
            name="Contract"
            saveDeleteDetails={saveDeleteDetails}
            title={"Upload Contract"}
            buttonTitle={"Upload Master/Amendment Contract(s)"}
            fileTypeSelect={this.state.fileTypeSelect}
            updateState={this.updateState}
          />
        )}
        {this.state.showSupportingContractdialog && (
          <Upload
            handleDialog={this.handleSupportDialog}
            openmodal={this.state.showSupportingContractdialog}
            handleShowBtn={false}
            SelectedList={SelectedList}
            uploadsArray={uploadsArray}
            name="Support Document"
            title={"Upload Support Documents"}
            buttonTitle={"Upload Support Documents"}
            fileTypeSelect={this.state.fileTypeSelect}
            updateState={this.updateState}
          />
        )}
      </>
    );
  }
}
