import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";

const APIPaths = {
  tags: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/tags/",
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

export const deleteTags = (tagID: string) => {
  return axios
    .delete(APIPaths.tags + `${tagID}/`, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("deleteContractType error", error);
    });
};

export const postTags = (paylaod: any) => {
  return axios
    .post(APIPaths.tags, paylaod, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("postContractType error", error);
    });
};
