import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
import {
  cloneContractType,
  editContractType,
  getContractTypes,
  postContractType,
} from "../DA";
import {
  ContractType,
  ContractTypeRes,
  defaultContractType,
} from "../State/ContractTypeState";

interface Props {
  open: boolean;
  onClose: () => void;
  contractType: ContractTypeRes | null;
  isEdit: boolean;
  getContractType: () => void;
  isClone: boolean;
}
function AddContractDialog(props: Props) {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [contractType, setcontractType] =
    useState<ContractType>(defaultContractType);
  const [contractTypeId, setContractTypeId] = useState("");

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.isEdit == true && props.contractType != null) {
      setcontractType({
        name: props.contractType.name,
        displayName: props.contractType.displayName
      });
      setContractTypeId(props.contractType.id);
    } else {
      setcontractType(defaultContractType);
    }
  }, [props.contractType, props.isEdit, props.isClone]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleSubmit = async () => {
    try {
      if (props.isClone) {
        let contractTypePayload = {
          ...contractType,
          contract_type: props?.contractType?.id,
        };

        let response = null;
        setLoading(true);
        response = await cloneContractType(contractTypePayload);
        if (response) {
          setTimeout(() => {
            props.getContractType();
          }, 500);
          handleClose();
        }
      } else {
        const contractTypePayload = {
          ...contractType,
          creation_type: isRiverusAdmin() ? "system" : "custom",
        };

        let response = null;
        setLoading(true);
        if (props.isEdit) {
          response = await editContractType(
            contractTypePayload,
            contractTypeId
          );
        } else {
          response = await postContractType(contractTypePayload);
        }
        if (response) {
          setTimeout(() => {
            props.getContractType();
          }, 500);
          handleClose();
        }
      }
    } catch (error) {
      console.error("handleSubmit error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleContractTypeName = (event: any) => {
    setcontractType({ ...contractType, name: event.target.value });
  };
  const handleDisplayName = (event: any) => {
    setcontractType({ ...contractType, displayName: event.target.value });
  };
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {isLoading && <CircularProgress className="loader" />}
      <div className="modal-header-2 ">
        <div className="d-flex justify-content-between align-items-center w-100 px-3">
          <p
            style={{
              fontSize: "16px",
              margin: "0px",
            }}
          >
            {props.isClone
              ? "Clone Contract Type"
              : props.isEdit
              ? "Edit Contract Type"
              : "Add Contract Type"}
          </p>
          <div onClick={handleClose}>
            <img
              className="cursor-pointer"
              src="/static_images/white-close-modal-icn.svg"
              alt="close_icon"
            />
          </div>
        </div>
      </div>
      <DialogContent dividers>
        <div className="row mb-3 justify-content-md-center">
          <div className="col-md-9">
            <DialogContentText>
              {props.isClone
                ? "Name of the Clone Contract Type"
                : "Name of the Contract Type"}
            </DialogContentText>
            <TextField
              value={contractType.name}
              onChange={handleContractTypeName}
              fullWidth={true}
              disabled={props.isClone}
              id="outlined-basic"
              variant="outlined"
            />
          </div>
        </div>
        <div className="row mb-3 justify-content-md-center">
          <div className="col-md-9">
            <DialogContentText>Display Name</DialogContentText>
            <TextField
              value={contractType.displayName}
              onChange={handleDisplayName}
              fullWidth={true}
              id="outlined-basic"
              variant="outlined"
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button className="cancel-btn" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-warning create-btn save-btn"
        >
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default AddContractDialog;
