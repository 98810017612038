import { connect } from 'react-redux';
import AppActionGenerator from '../../App/Actions/actionGen';
import DataDictionary from '../Component/DataDictionary';

export function mapStateToProps(appState: any, ownProps: any) {
    return {
    }
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
    return {
        pageWatcher: (page: string) => dispatch(AppActionGenerator.pageWatcher(page)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DataDictionary);