import { Box, CircularProgress, Modal, Typography } from "@material-ui/core";
import axios from "axios";
import { data } from "jquery";
import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";

interface Props {
  addTag: boolean;
  onClose: any;
  tagsList: [];
  updateClause: any;
  selectedSectionID: any;
  data: any;
}
export default function AddTagClause(props: Props) {
  const hierarchyStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    minHeight: "220px",
    maxHeight: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: " 8px 8px 8px 8px",
  };

  const [isAdd, setAdd] = useState(false);
  const [tags, setTagsData] = useState<any>([]);
  const [tagValue, setTagValue] = useState<any>("");
  const [applyTag, setApplyTag] = useState<any>("");
  const [clauseID, setClauseID] = useState<any>(null);

  useEffect(() => {
    if (props.addTag) {
      const data = props?.data?.map((item: any) => {
        return item[`${Object.keys(item)[0]}`];
      });
      const ff = data?.map((item: any) => {
        return item.filter((val: any) => {
          if (props?.selectedSectionID?.includes(val?.section_name?.id)) {
            return val.id;
          }
        });
      });
      const dd = ff?.map((item: any) => {
        if (item?.length) {
          return item?.map((element: any) => {
            return element?.id;
          });
        }
      });
      const hh = dd?.filter((item: any) => {
        if (item || item?.length) {
          return item;
        }
      });
      setClauseID(hh.flat(1));
    }
  }, [props.addTag]);

  useEffect(() => {
    console.log(clauseID, "clauseID");
  }, [clauseID]);

  const APIConfig = () => ({
    headers: {
      Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
    },
  });

  const addTags = (payload: any) => {
    const URL = process.env.REACT_APP_RIVERUS_CLM_API + "contracts/tags/";
    axios
      .post(URL, payload, APIConfig())
      .then((response) => {
        console.log(response, "APIConfig");
        const data = response.data.map((item: any) => {
          return item.id;
        });
        let payload: any = [];
        if (applyTag) {
          payload = {
            clause_id: clauseID,
            tags: [...data, applyTag],
          };
        } else {
          payload = {
            clause_id: clauseID,
            tags: [...data],
          };
        }
        props.onClose();
        props.updateClause(payload);
      })
      .catch((error) => {});
  };

  return (
    <>
      <Modal
        open={props.addTag}
        className="deletemodal"
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={hierarchyStyle} className="position-relative">
          <Typography
            id="modal-modal-title"
            // variant="h6"
            // component="h2"
            className="myDocument-deleteFileTitle"
          >
            <div className="myDocument-deleteFileTitle-content d-flex justify-content-between">
              Add Tags
              <span
                className="mr-0"
                onClick={() => {
                  props.onClose();
                }}
              >
                <img
                  src={"/static_images/Group 3610.svg"}
                  alt=""
                  style={{
                    height: "24px",
                    width: "24px",
                    color: "#818181",
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          </Typography>
          <Typography style={{ margin: "20px" }}>
            {false && <CircularProgress className="loader" />}
            <div className="row">
              <div className="col-md-12" style={{ marginTop: "20px" }}>
                <div className="col-6">
                  <div className="d-flex justify-content-md-between">
                    <span className="addTag-title ">Tags</span>
                    <span className="addtag-reset" onClick={() => setAdd(true)}>
                      +Add NEW
                    </span>
                  </div>
                  <div className="mt-1">
                    <select
                      className="addtag-selectBox"
                      onChange={(e) => {
                        setApplyTag(e.target.value);
                      }}
                    >
                      <option
                        selected
                        className="addtag-selectBox-title"
                        disabled
                      >
                        Multiselect Dropdown{" "}
                      </option>
                      {props?.tagsList?.map((item: any) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginLeft: "16px",
                  }}
                  className="d-flex align-items-center"
                >
                  {tags.map((val: any, index: number) => {
                    return (
                      <div key={index}>
                        <div className="project-type">
                          {val}
                          <img
                            src={"/static_images/mdi_close.svg"}
                            alt=""
                            style={{
                              height: "16px",
                              width: "16px",
                              color: "#88305F",
                              cursor: "pointer",
                              marginLeft: "7px",
                            }}
                            onClick={() => {
                              const tagedData = tags.filter(
                                (item: any) => item !== val
                              );

                              setTagsData(tagedData);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {isAdd ? (
                  <form
                    className="col-md-9"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div
                      className="row"
                      style={{
                        gap: "15px",
                        marginLeft: "0px",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="text"
                        name="name"
                        onChange={(val) => setTagValue(val.target.value)}
                        value={tagValue}
                        placeholder="Tag Name"
                        style={{ border: "1px solid #dddddd", width: "250px" }}
                      />
                      <button
                        onClick={() => {
                          setTagsData([...tags, tagValue]);
                          setTagValue("");
                        }}
                        type="submit"
                        className=" form-submit-btn"
                        style={{ height: "25px" }}
                      >
                        <img
                          src={"/static_images/tick-filter-icn.svg"}
                          alt=""
                          style={{
                            height: "8px",
                            // width: "16px",
                            color: "#88305F",
                            cursor: "pointer",
                          }}
                        />
                      </button>
                    </div>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Typography>
          <div
            className=" d-flex justify-content-end cursor-pointer  "
            style={{ paddingTop: "10px" }}
          >
            <img
              src={"/static_images/Main-Button.svg"}
              onClick={() => {
                const payload = tags.map((item: any) => {
                  return { name: item };
                });
                addTags(payload);
              }}
              alt=""
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}
