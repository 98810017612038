// import { useKeycloak } from "../lib";
import { useKeycloak } from "@react-keycloak/web";
import Nav from "../Component/nav";
import axios from "axios";
import {
  getKeyCloakRealmFromLS,
  removeKeyCloakRealmOnLS,
} from "../../Authentication/Actions/authentication";

import { useEffect, useState } from "react";
import BarLoader from "../../UniversalComponents/Loader/barLoader";

// import {} from "../../UniversalComponents/Notification/Saga/notificationSaga";
import HeimdallActionGen from "../../UniversalComponents/HeimdallChild/Actions/actionGen";
import {
  notificationWatcher,
  getData,
} from "../../UniversalComponents/Notification/Saga/notificationSaga";

import {
  getLocalStorage,
  setUserId,
  getUserId,
  setLocalStorage,
} from "../../Authentication/Actions/authentication";
import createSagaMiddleware from "@redux-saga/core";
import { useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
const sagaMiddleWare = createSagaMiddleware();

const PrivateRoute = ({
  handleKeycloakRealmNameChange,
  children,
  setAdmin,
}) => {
  const { keycloak } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const keycloakRealmName = getKeyCloakRealmFromLS();
  const isLoggedIn = keycloak.authenticated;
  const dispatch = useDispatch();
  const [loadingTime, setLoadingTime] = useState(0);
  const handleKeycloakRedirection = () => {
    if (keycloak && !isLoggedIn) {
      // console.log(keycloak);
      // console.log("keycloak login data", keycloak);
      keycloak.login();
    }
  };
  // const handleRealmNameReset = () => {

  // };

  useEffect(() => {
    if (keycloak.authenticated) {
      dispatch(HeimdallActionGen.login());
    }
  }, [keycloak.authenticated]);

  async function getUserId() {
    const options = {
      headers: {
        Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
        // Origin: process.env.REACT_APP_RIVERUS_CLM_APP_HOST,
      },
    };
    // const url_data =
    //   process.env.REACT_APP_RIVERUS_CLM_API + "contracts/useridbytoken/";
    /*const url_data =
      process.env.REACT_APP_RIVERUS_CLM_API + "contracts/userprofile/";*/
    const url_data = process.env.REACT_APP_RIVERUS_CLM_API + "users/profile/";

    axios
      .get(url_data, options)
      .then(function (response) {
        if (response.status >= 400) {
          throw new Error("Bad response from server");
        }

        if (response.status == 200) {
          setUserId("user_id", response.data.id);
          setLocalStorage("user_profile", response.data);
          console.log(
            "🚀 ~ file: privateRoute.ts ~ line 151 ~ User Profile",
            response
          );

          // if(window.location.pathname!=="/admin/dataDictionaryAdmin" && window.location.pathname!=="/admin/clauseLibraryAdmin"){
          if (response.data.roles.includes("admin")) {
            setAdmin(true);
          }
          // }
        }
        return response;
        // return response.json();
      })
      .then(function (data) {
        if (data === "success") {
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  useEffect(() => {
    console.log("Private Route", loadingTime, isLoggedIn);
    if (isLoggedIn === true) {
      getUserId();
      // notificationWatcher();
      // sagaMiddleWare.run(notificationWatcher);
    } else if (loadingTime === 1 && isLoggedIn === false) {
      console.log("Error connection. Check your client id again!");
    } else if (loadingTime === 1) {
      console.log("Error connection. Check your client id again!");
      removeKeyCloakRealmOnLS();
      window.location.reload();
    }
  }, [loadingTime]);
  if (isLoggedIn === true && localStorage.getItem("accessToken") != undefined) {
    return children;
  } else if (isLoggedIn === false) {
    return (
      <RedirectToKeycloak
        keycloakRealmName={keycloakRealmName}
        handleKeycloakRedirection={handleKeycloakRedirection}
        handleKeycloakRealmNameChange={handleKeycloakRealmNameChange}
      />
    );
  } else {
    setTimeout(() => {
      setLoadingTime(1);
    }, 10000);

    return (
      <div className="">
        <BarLoader />
      </div>
    );
  }
};

const RedirectToKeycloak = (props) => {
  // props.handleKeycloakRedirection();
  const [realmName, setRealmName] = useState(props.keycloakRealmName);
  const [isOrgAvailable, setIsOrgAvailable] = useState(true);

  return (
    <div className="cover-background">
      <div className="row" style={{ height: "100%", width: "100%" }}>
        <div className="col-md-8 image-background responsive-background"></div>
        <div
          className="col-md-4 align-self-center position-right responsive-login"
          style={{ maxWidth: "480px" }}
        >
          <div className="mb-5 align-center">
            <img
              src="static_images/web-menubar-logo.svg"
              className="riverus-logo-login"
            ></img>
          </div>
          <div className="realm-input-view mb-4">
            {isOrgAvailable && (
              <div>
                <img
                  className="mb-4 back-btn"
                  style={{ width: "24px" }}
                  src="static_images/back-arrow.svg"
                  onClick={() => {
                    setIsOrgAvailable(false);
                  }}
                />
                <p className="f-16 fw c-blue">You are logging onto</p>
                <p className="f-16 fw c-blue">{props.keycloakRealmName}</p>
                <p
                  className="f-14 underline c-grey"
                  onClick={() => {
                    setIsOrgAvailable(false);
                  }}
                >
                  Change organization?
                </p>
                <div
                  className="org-continue-btn fw"
                  onClick={() => {
                    props.handleKeycloakRedirection();
                  }}
                >
                  Continue
                </div>
              </div>
            )}
            {!isOrgAvailable && (
              <div>
                <p className="org-name-heading">Name of Organization</p>
                <TextField
                  className="org-input mb-5"
                  size="small"
                  value={realmName}
                  onChange={(e) => {
                    setRealmName(e.target.value);
                  }}
                  fullWidth={true}
                  id="outlined-basic"
                  variant="outlined"
                />
                <div
                  className="org-continue-btn"
                  onClick={() => {
                    console.log("click");
                    props.handleKeycloakRealmNameChange(realmName);
                    setIsOrgAvailable(true);
                  }}
                >
                  Continue
                </div>
              </div>
            )}
          </div>
          <p className="f-14 fw c-grey align-center">
            <a
              href="https://www.riverus.co"
              target="_blank"
              className="link c-grey"
            >
              Home
            </a>{" "}
            |{" "}
            <a
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              className="c-grey link"
            >
              Terms of Service
            </a>{" "}
            |{" "}
            <a
              href="https://www.riverus.co/privacy-policy"
              target="_blank"
              className="c-grey link"
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivateRoute;
