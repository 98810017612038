import React from "react";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";
import { ContractDetails, ContractParentDetails } from "../State/documentState";
import LinesLoader from "../../UniversalComponents/Loader/linesLoader";
import DocumentPdftronView from "./documentPdftronView";
import { isNullOrUndefined } from "is-what";
import FileSaver from "file-saver";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
interface Props {
  fileId: any;
  contractData: ContractDetails;
}
interface State {
  // contractData: ContractDetails[];
  amendment_contract: ContractParentDetails[];
  support_document: ContractParentDetails[];
  isLoader: boolean;
  fileView: string;
  documentList: any;
  selectedContract: string;
  selectedMenu: {
    selectedContractMenu: string;
    contract_id: string;
    pdfview: boolean;
  };
  value: string;
  contractName: string;
}
export default class DocumentsView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // contractData: [],
      amendment_contract: [],
      support_document: [],
      isLoader: false,
      fileView: "",
      documentList: [],
      selectedContract: "",
      selectedMenu: {
        selectedContractMenu: "Document View",
        contract_id: "",
        pdfview: true,
      },
      value: "coconut",
      contractName: "",
    };
    // this.setState({isLoader:true})
  }
  componentDidMount() {
    let selectedMenu_ = this.state.selectedMenu;
    selectedMenu_.contract_id = atob(this.props.fileId);
    this.setState({ selectedMenu: selectedMenu_ });
    this.setState({ value: this.props.contractData.file_name });
  }

  handleSelectedMenu = (contract_id: string, selectedContractMenu: string) => {
    let selectedMenu_ = this.state.selectedMenu;
    selectedMenu_.contract_id = contract_id;
    selectedMenu_.selectedContractMenu = selectedContractMenu;
    selectedMenu_.pdfview = true;
    this.setState({ selectedMenu: selectedMenu_ });
  };

  downloadFile(id: string) {
    AxiosGateWay.get(SITEAPI + "download/" + id + "/")
      .then((result) => {
        if (result && result.data) {
          let blob = fetch(result.data.location).then((r) => {
            r.blob();
            FileSaver.saveAs(result.data.location, result.data.file_name);
          });
        }
      })
      .catch((error) => {});
  }
  handleChange = (event: any) => {
    let selectedMenu_ = this.state.selectedMenu;
    selectedMenu_.pdfview = false;
    this.setState({ selectedMenu: selectedMenu_ });

    this.setState({ value: event.target.value });
    this.handleSelectedMenu(event.target.value, "PDF View");
  };
  render() {
    let {
      // contractData,
      isLoader,
      amendment_contract,
      support_document,
      fileView,
      selectedMenu,
    } = this.state;
    let { contractData } = this.props;
    return (
      <div className="col-md-12 table-container" style={{ marginTop: "5rem" }}>
        {/* Original Document Section */}
        {/* {JSON.stringify(this.props.contractData)} */}
        {isLoader ? (
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <LinesLoader
                  animatedLines={[
                    {
                      width: 100,
                      height: 42,
                      marginTop: "5px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                    {
                      width: 100,
                      height: 42,
                      marginTop: "15px",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            {selectedMenu.selectedContractMenu == "Document View" ? (
              <>
                {contractData.id != "" ? (
                  <>
                    {" "}
                    {this.documentDetails("Original Document")}
                    {this.documentDetails("Parent Document")}
                    {this.documentDetails("Child Documents")}
                    {this.documentDetails("Amendment Documents")}
                    {this.documentDetails("Support Documents")}
                  </>
                ) : null}
              </>
            ) : null}
            {selectedMenu.selectedContractMenu == "PDF View" ? (
              <>
                <div
                  className="d-flex flex-row-reverse"
                  style={{ maxHeight: "0.01px", cursor: "pointer" }}
                >
                  <div
                    className="col-md-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.handleSelectedMenu(
                        atob(this.props.fileId),
                        "Document View"
                      );
                    }}
                  >
                    <img
                      src="/static_images/remove-document-view.svg"
                      onClick={() => {
                        this.handleSelectedMenu(
                          atob(this.props.fileId),
                          "Document View"
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="group-3831">
                    <span className="text ml-3">Change Document</span>
                  </div>
                </div>

                <div className="row">
                  <div className="">
                    <select
                      defaultValue={this.state.selectedMenu.contract_id}
                      // defaultValue={this.state.contractName}
                      onChange={this.handleChange}
                      className="ml-3"
                    >
                      <option value={"Select Document"}>Select Document</option>

                      {this.documentList()}
                    </select>
                  </div>
                </div>
                <div className="mt-20">
                  {this.state.selectedMenu.pdfview ? (
                    <DocumentPdftronView
                      fileId={this.props.fileId}
                      selectedMenu={this.state.selectedMenu}
                      key={this.state.selectedMenu.contract_id}
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  }

  documentDetails = (documentType: string) => {
    let {
      // contractData,
      isLoader,
      amendment_contract,
      support_document,
      fileView,
    } = this.state;
    let { contractData } = this.props;

    switch (documentType) {
      case "Original Document": {
        return (
          <>
            <div className="row documents-label border-line">
              <span className="original-document">{documentType}</span>
            </div>
            {contractData.id == "" ? (
              this.emptyDocument()
            ) : (
              <>
                <div className="row frame-3854 border-line">
                  <div className="table-row-element col-md-10 py-2">
                    <span className="text">{contractData.file_name}</span>
                  </div>
                  <div className="col-md-2">
                    {this.viewDocument(contractData.id, contractData.file_name)}
                  </div>
                </div>
              </>
            )}
          </>
        );
      }
      case "Parent Document": {
        return (
          <>
            <div className="row documents-label border-line mt-2">
              <span className="original-document">{documentType}</span>
            </div>
            {isNullOrUndefined(contractData.parent) ? (
              this.emptyDocument()
            ) : (
              <>
                <div className="row frame-3854 border-line">
                  <div className="table-row-element col-md-10 py-2">
                    <span className="text">
                      {contractData.parent?.file_name}
                    </span>
                  </div>
                  <div className="col-md-2">
                    {this.viewDocument(
                      contractData.parent?.id,
                      contractData.parent?.file_name
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        );
      }
      case "Child Documents": {
        return (
          <>
            <div className="row documents-label border-line mt-2">
              <span className="original-document">{documentType}</span>
            </div>
            {contractData.children.length == 0 ? (
              this.emptyDocument()
            ) : (
              <>
                {contractData.children.map((label, i) => (
                  <>
                    {" "}
                    <div className="row frame-3854 border-line" key={label.id}>
                      <div className="table-row-element col-md-10 py-2">
                        <span className="text">{label.file_name}</span>
                      </div>
                      <div className="col-md-2">
                        {this.viewDocument(label.id, label.file_name)}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        );
      }
      case "Amendment Documents": {
        return (
          <>
            <div className="row documents-label border-line mt-2">
              <span className="original-document">{documentType}</span>
            </div>
            {contractData.amendment.length == 0 ? (
              this.emptyDocument()
            ) : (
              <>
                {contractData.amendment.map((label, i) => (
                  <>
                    {" "}
                    <div className="row frame-3854 border-line" key={label.id}>
                      <div className="table-row-element col-md-10 py-2">
                        <span className="text">{label.file_name}</span>
                      </div>
                      <div className="col-md-2">
                        {this.viewDocument(label.id, label.file_name)}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        );
      }
      case "Support Documents": {
        return (
          <>
            <div className="row documents-label border-line mt-2">
              <span className="original-document">{documentType}</span>
            </div>
            {contractData.support_document.length == 0 ? (
              this.emptyDocument()
            ) : (
              <>
                {contractData.support_document.map((label, i) => (
                  <>
                    {" "}
                    <div className="row frame-3854 border-line" key={label.id}>
                      <div className="table-row-element col-md-10 py-2">
                        <span className="text">{label.file_name}</span>
                      </div>
                      <div className="col-md-2">
                        {this.viewDocument(label.id, label.file_name)}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        );
      }
    }
    return <></>;
  };

  viewDocument = (id: string, file_name: string) => {
    return (
      <>
        <div className="d-flex flex-row-reverse">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.handleSelectedMenu(id, "PDF View");
              this.setState({ fileView: id, selectedContract: file_name });
            }}
          >
            <img
              src="/static_images/document-view-icn.svg"
              style={{ float: "right", paddingTop: "10px" }}
            />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.downloadFile(id);
            }}
          >
            <img
              src="/static_images/file-download.svg"
              alt="download"
              style={{
                float: "right",
                paddingTop: "10px",
                marginRight: "7px",
                height: "25px",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      </>
    );
  };

  emptyDocument = () => {
    return (
      <>
        <div className="row frame-3854 border-line">
          <div className="table-row-element col-md-10 py-2">
            <span className="text">-</span>
          </div>
          <div className="col-md-2"></div>
        </div>
      </>
    );
  };

  documentList = () => {
    let {
      // contractData,
      isLoader,
      amendment_contract,
      support_document,
      fileView,
      selectedContract,
    } = this.state;
    let { contractData } = this.props;
    if (contractData.id == "") {
      return null;
    } else {
      return (
        <>
          <option value={contractData.id} selected>
            {contractData.file_name}
          </option>
          {isNullOrUndefined(contractData.parent) ? (
            ""
          ) : (
            <option value={contractData.parent.id}>
              {contractData.parent.file_name}
            </option>
          )}
          {contractData.children.length > 0 ? (
            <>
              {contractData.children.map((label, i) => (
                <option value={label.id}>{label.file_name}</option>
              ))}
            </>
          ) : (
            ""
          )}
          {contractData.amendment.length > 0 ? (
            <>
              {contractData.amendment.map((label, i) => (
                <option value={label.id}>{label.file_name}</option>
              ))}
            </>
          ) : (
            ""
          )}
          {contractData.support_document.length > 0 ? (
            <>
              {contractData.support_document.map((label, i) => (
                <option value={label.id}>{label.file_name}</option>
              ))}
            </>
          ) : (
            ""
          )}
        </>
      );
    }
  };
}
