import { Box, Modal } from "@material-ui/core";
import React from "react";
import { isRiverusAdmin } from "../../../DataDictionary/DDUtils";
import { getDate } from "../../../Utils/DataModifierUtil/dataModUtil";
import CustomChip from "../../CustomChip/Component/CustomChip";
import HeimdallUtil from "../../HeimdallChild/HeimdallUtil/heimdallUtil";
import Scrollable from "../../Scrollable/scrollable";

export default function EditClauseModal(props: any) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    bgcolor: "background.paper",
    borderRadius: 15,
  };
  return (
    <Modal
      open={true}
      onClose={props.closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="modal-header">
          <p className="m-0">View/Edit Clause</p>
          <img
            className="cursor-pointer"
            onClick={() => props.closeModal()}
            src="/static_images/white-close-modal-icn.svg"
            alt="clause-folder"
          />
        </div>
        <div className="pt-4 pb-2">
          <div className="row m-0">
            <div className="col-md-4">
              <label>Section</label>
              <p>{props.selectedClauseData.section_name}</p>
            </div>
            <div className="col-md-4">
              <label>Clause Name</label>
              <p>{props.selectedClauseData.clause_name}</p>
            </div>
            <div className="col-md-4">
              <label>Clause No.</label>
              <p>{props.selectedClauseData.clause_no}</p>
            </div>
          </div>
          <div className="mt-4">
            <div className="row m-0">
              <div className="col-md-4">
                <label>Clause Type</label>
                <div className="mt-2">
                  <span style={{ fontSize: "14px" }}>
                    <img
                      className="cursor-pointer"
                      src={`${
                        props.selectedClauseData.clause_type === "recommended"
                          ? "/static_images/sub-section-success.svg"
                          : "/static_images/avoidable-icon.svg"
                      }`}
                      alt="radio-btn"
                    />
                    &nbsp;{" "}
                    {props.selectedClauseData.clause_type
                      .toString()
                      .toUpperCase()}
                  </span>
                </div>
              </div>
              <div className="col-md-8" style={{ margin: "auto" }}>
                <div>
                  <label>Clause Text</label>
                  <img
                    onClick={() => {
                      props.onCopy();
                      navigator.clipboard.writeText(
                        props.selectedClauseData.clause_text
                      );
                    }}
                    className="ml-4 cursor-pointer"
                    style={{ height: "20px" }}
                    src="/static_images/clause_copy.svg"
                    alt="refresh"
                  />
                </div>
                <Scrollable maxHeight={100}>
                  <p>{props.selectedClauseData.clause_text}</p>
                </Scrollable>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="row m-0">
              <div className="col-md-6">
                <label>Clause Source</label>
                <div
                  className="d-flex"
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <p className="m-0">
                    {props.selectedClauseData.clause_source}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="row m-0">
              <div className="col-md-4">
                <label>Tags</label>
                <div className="row m-0">
                  <CustomChip
                    backgroundColor="#D8F7E6"
                    textColor="#00692A"
                    data={props?.selectedClauseData?.tags.map(
                      (tag: any) => tag.name
                    )}
                    showItems={1}
                  />
                </div>
              </div>
              <div className="col-md-8" style={{ margin: "auto" }}>
                <label>Description</label>
                <Scrollable maxHeight={100}>
                  <p>{props.selectedClauseData.description}</p>
                </Scrollable>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="row m-0">
              <div className="col-md-9">
                <p
                  className="m-0"
                  style={{ fontSize: "14px", color: "#818181" }}
                >
                  Created on: {getDate(props.selectedClauseData.created_on)} |
                  Added by:{" "}
                  {props.selectedClauseData.created_by.first_name +
                    " " +
                    props.selectedClauseData.created_by.last_name}
                </p>
              </div>
              <button
                disabled={
                  props.tabName === 0
                    ? isRiverusAdmin()
                      ? false
                      : true
                    : props.isAdmin
                    ? false
                    : true
                }
                onClick={() => {
                  if (
                    (props.tabName === 1 && props.isAdmin) ||
                    isRiverusAdmin()
                  ) {
                    props.closeModal();
                    props.showCreateModal();
                    props.changeClauseMode(props.selectedClauseData);
                  }
                }}
                style={{ fontSize: "13px", padding: "10px 20px" }}
                className={`section-add-clause mb-4 ml-5  ${
                  props.tabName === 0
                    ? isRiverusAdmin()
                      ? ""
                      : "disabled-buttons"
                    : props.isAdmin
                    ? ""
                    : "disabled-buttons"
                }`}
              >
                Edit Clause
              </button>
              {/* <div className="d-flex justify-content-end mb-2">
                <span
                  className="section-add-clause py-1"
                  id="save-btn"
                  style={{ fontSize: "13px", padding: "10px 25px" }}
                  data-toggle="modal"
                  data-target="#createClausePageModal"
                >
                  Edit Clause
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
