import React from "react";
import { History } from "history";
import { isNullOrUndefined } from "is-what";
// import {
//   CDropdown,
//   CDropdownItem,
//   CDropdownMenu,
//   CDropdownToggle,
// } from "@coreui/react";
import { OutlinedInput } from "@material-ui/core";

interface Props {
  history: History;
  match: any;
  term: string;
  visibility: boolean;
  filterType: string;
  textChange: (term: string, filterType: string) => void;
  toggleSearchBarVisibility: (visibility: boolean) => void;
}

interface State {
  searchTerm: string;
  hideSearchOptions: boolean;
  searchcontractOption: string[];
  searchSelected: string;
  searchOptionSvg: string[];
  searchSVGSelected: string;
}

export default class SearchBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: props.term,
      hideSearchOptions: true,
      searchcontractOption: ["Contracts", "Clauses"],
      searchOptionSvg: [
        "static_images/search-in-contracts.svg",
        "static_images/search-in-clauses.svg",
      ],
      searchSelected: "Contracts",
      searchSVGSelected: "static_images/search-in-contracts.svg",
    };
    // this.getTerm = this.getTerm.bind(this);
  }
  componentDidMount() {}
  componentWillMount() {
    let { match } = this.props;
    if (match.params.query) {
      this.setState({ searchTerm: match.params.query });
    }
  }
  componentWillReceiveProps(nextProps: Props) {
    var elem = document.getElementById("searchbar-input-box");

    if (!isNullOrUndefined(elem)) {
      elem.focus();
    }
  }

  search = (term: string) => {
    let { searchTerm, searchSelected } = this.state;
    this.setState({ searchTerm: term }); //To delete previous search term when clicking on search again.
    let { toggleSearchBarVisibility, textChange } = this.props;
    let url = "/";

    if (searchTerm.length > 0) {
      url = "/search/" + btoa(searchTerm);
      toggleSearchBarVisibility(false);
      textChange(searchTerm, searchSelected);
    }
    this.props.history.push(url);
  };

  handleKeyBoard = (e: React.KeyboardEvent) => {
    let { searchTerm, searchSelected } = this.state;
    let { toggleSearchBarVisibility, term, textChange } = this.props;
    let url = "/";
    if (e.keyCode === 13) {
      if (searchTerm.length > 0) {
        url = "/search/" + btoa(searchTerm);
        toggleSearchBarVisibility(false);
        textChange(searchTerm, searchSelected);
        this.setState({ searchTerm: term }); //To delete previous search term when clicking on search again.
      }
      this.props.history.push(url);
    }
  };

  render() {
    let {
      searchTerm,
      hideSearchOptions,
      searchcontractOption,
      searchSelected,
      searchOptionSvg,
      searchSVGSelected,
    } = this.state;
    let { visibility, term, textChange } = this.props;
    return (
      <>
        {visibility && (
          <div className="row">
            <div className="col-md-8 offset-md-2 searchbar-container">
              <div className="row">
                <div className="col-md-12 input-bar-padding">
                  {/* <img src="/static_images/search-inline-icn.svg" alt="icon" /> */}
                  {/* &nbsp;&nbsp; */}
                  {/* <div className="row"> */}
                  {/* {background-color: white;outline: none;} */}
                  {/* <CDropdown>
                    <CDropdownToggle
                      color="default"
                      style={{
                        backgroundColor: "white", boxShadow: "unset", fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontFamily: 'Inter'
                      }}
                    >
                      <img src={`${process.env.REACT_APP_RIVERUS_CLM_APP_HOST}${searchSVGSelected}`}></img><span style={{ paddingRight: "10px" }}></span> Search in {searchSelected}<span style={{ paddingRight: "30px" }}></span>
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {Object.keys(searchcontractOption).map((options, key) => {
                        return (
                          <>
                            <CDropdownItem
                              style={{
                                paddingTop: "0.25rem",
                                // paddingRight: "1.5rem",
                                paddingBottom: "0.25rem",
                                paddingLeft: "0.5rem",
                                fontWeight: "400",
                                fontSize: "16px",
                                lineHeight: "24px",
                                fontFamily: 'Inter',
                                marginRight: "unset"
                              }}
                              href="#"
                              onClick={() => {
                                this.setState({
                                  searchSelected: searchcontractOption[key],
                                  searchSVGSelected: searchOptionSvg[key],
                                });
                                textChange(
                                  searchTerm,
                                  searchcontractOption[key]
                                );
                              }}
                            ><div className="row">
                                <div className="col-md-1">
                                  <img src={`${process.env.REACT_APP_RIVERUS_CLM_APP_HOST}${searchOptionSvg[key]}`}></img>
                                </div>
                                <div className="col-md-10" style={{ paddingRight: "unset" }}>
                                  Search in {searchcontractOption[key]}
                                  {searchcontractOption[key] == searchSelected ? <img style={{ paddingTop: "6px", float: "right" }} src={`${process.env.REACT_APP_RIVERUS_CLM_APP_HOST}static_images/search-selected-chk.svg`} /> : ''}

                                </div>
                              </div>

                            </CDropdownItem>
                          </>
                        );
                      })}
                    </CDropdownMenu>
                  </CDropdown> */}
                  <span style={{ paddingRight: "20px" }}></span>
                  {/* <div
                    className="col-md-2 repetition"
                    id="repetition-input"
                    style={{ paddingTop: "10px" }}
                    onClick={() => {
                      this.setState({
                        hideSearchOptions: !hideSearchOptions,
                      });
                    }}
                  >
                    Search in Contracts
                    <span className="float-right">
                      <img
                        src="/static_images/tag-dropdown-active.svg"
                        alt="dropdown"
                      />
                    </span>
                  </div> */}
                  {/* </div> */}
                  {/* <div className="col-md-4"> */}
                  <input
                    autoFocus={true}
                    placeholder="Search for files, phrases, keywords etc."
                    onKeyDown={(e: any) => this.handleKeyBoard(e)}
                    className="input"
                    type="search"
                    id="searchbar-input-box"
                    onChange={(e) =>
                      this.setState({ searchTerm: e.currentTarget.value })
                    }
                    defaultValue={this.props.term}
                  />

                  <button
                    id="search-btn"
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      border: "unset",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => this.search(term)}
                  >
                    <img
                      style={{ width: "25px" }}
                      src={`${process.env.REACT_APP_RIVERUS_CLM_APP_HOST}static_images/arrow-return-left.svg`}
                    />
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
