import React, { useState } from "react";
import { Card, Badge, Table, Row, Col, Button } from "react-bootstrap";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

// scrollToDatapoint={scrollToDatapoint}
// UpDownButtonsRender={UpDownButtonsRender}
// scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
// getCustomChildLabelName={getCustomChildLabelName}
// getHighlightedTextFromDP={getHighlightedTextFromDP}
// getHighlightedPara={getHighlightedPara}
// getSmallSentence={getSmallSentence}

const KDPRender = (props: any) => {
  const { label, children, dataPoints } = props.insight;
  const {
    scrollToDatapoint,
    UpDownButtonsRender,
    scrollToHighlightedDatapoint,
    getCustomChildLabelName,
    getHighlightedTextFromDP,
    getHighlightedPara,
    getSmallSentence,
  } = props;

  const totalInstances = dataPoints.length;
  const [currentInstance, setCurrentInstance] = useState(1);

  const handleInstanceChange = (type: string) => {
    if (type === "up" && currentInstance > 1) {
      setCurrentInstance(currentInstance - 1);
      scrollToDatapoint(dataPoints[currentInstance - 2]);
    }
    if (type === "down" && currentInstance < totalInstances) {
      setCurrentInstance(currentInstance + 1);
      scrollToDatapoint(dataPoints[currentInstance]);
    }
  };
  const handleViewFirstInstance = () => {
    scrollToDatapoint(dataPoints[0]);
  };
  let aggregatedGroupedBiData: any = [];
  for (let k = 0; k < children.length; k++) {
    let child = children[k];

    if (child.childValue && child.childValue.length > 0) {
      for (let i = 0; i < child.childValue.length; i++) {
        if (
          child.childValue[i]?.dataPoints?.groupedBiData &&
          child.childValue[i].dataPoints.groupedBiData.length > 0
        )
          aggregatedGroupedBiData = [
            ...aggregatedGroupedBiData,
            ...child.childValue[i].dataPoints.groupedBiData,
          ];
      }
    }
  }

  let idx = 1;
  return (
    <Card>
      <Card.Header
        className={
          "small-card-header light-pink-background card-toc-row toc-row-default " +
          (totalInstances === 0 ? "zero-instance-header" : "")
        }
        style={{ marginLeft: 0 }}
      >
        <Row style={{ paddingRight: 7 }}>
          <Col className="capitalize" style={{ fontSize: 15 }} md={8}>
            {label}&nbsp;
            {totalInstances > 0 && (
              <Badge className="riverus-yellow-btn ">{totalInstances}</Badge>
            )}
          </Col>

          {totalInstances ? (
            totalInstances > 1 ? (
              <Col style={{ textAlign: "right", paddingLeft: 0 }}>
                <span
                  className="riverus-yellow-btn"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    handleInstanceChange("up");
                  }}
                >
                  <AiOutlineArrowUp size={16} />
                </span>
                &nbsp;{currentInstance}/{totalInstances}&nbsp;
                <span
                  className="riverus-yellow-btn"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    handleInstanceChange("down");
                  }}
                >
                  <AiOutlineArrowDown size={16} />
                </span>
              </Col>
            ) : (
              <Col style={{ textAlign: "right" }}>
                <span
                  className="riverus-yellow-btn"
                  style={{ fontSize: "13px" }}
                  onClick={() => {
                    handleViewFirstInstance();
                  }}
                >
                  View
                </span>
              </Col>
            )
          ) : (
            <Col style={{ textAlign: "right" }}>
              <Badge className="riverus-yellow-btn">{totalInstances}</Badge>
            </Col>
          )}
        </Row>
      </Card.Header>

      {totalInstances > 0 && (
        <Card.Body style={{ paddingBottom: 10 }}>
          {children.map((child: any, i: number) => {
            if (
              child.childLabel !== label &&
              child.childLabel !== "Confidentiality" &&
              child.childLabel !== "Subletting/Assignment" &&
              child.childLabel !== "eventsOfDefault"
            ) {
              const groupedInsights: any = {};

              if (child.childValue && child.childValue.length > 0) {
                child.childValue.map((clause: any) => {
                  const clauseName =
                    clause.name && clause.name.toLowerCase() === "yes"
                      ? clause.dataPoints.highlightedText
                        ? getSmallSentence(clause.dataPoints.highlightedText)
                        : getSmallSentence(
                            getHighlightedPara(clause.dataPoints)
                          )
                      : getSmallSentence(clause.name);
                  if (
                    Array.isArray(
                      groupedInsights[
                        clauseName as keyof typeof groupedInsights
                      ]
                    )
                  ) {
                    groupedInsights[clauseName].push(clause.dataPoints);
                  } else {
                    groupedInsights[clauseName] = [];
                    groupedInsights[clauseName].push(clause.dataPoints);
                  }
                });
              }

              if (label === "Termination clause") {
              }

              return (
                <React.Fragment key={i}>
                  {child.childValue && child.childValue.length > 0 && (
                    <Row>
                      <Col className="capitalize">
                        <b>{getCustomChildLabelName(child.childLabel)}</b>
                      </Col>
                      {child.childValue && child.childValue.length > 1 && (
                        <Col style={{ textAlign: "right" }}>
                          <b> {child.childValue.length}</b> Instance Found
                        </Col>
                      )}
                    </Row>
                  )}
                  {child.childValue && child.childValue.length > 0 && (
                    <Row>
                      <Col>
                        <Table striped hover responsive size="sm">
                          <tbody>
                            {Object.keys(groupedInsights).map((gi, i) => {
                              if (gi.toLowerCase() !== "no") {
                                return (
                                  <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{gi}</td>

                                    <td
                                      style={{ whiteSpace: "nowrap" }}
                                      className="right-align"
                                    >
                                      {groupedInsights[gi].length > 1 ? (
                                        <UpDownButtonsRender
                                          dataPoints={groupedInsights[gi]}
                                        />
                                      ) : (
                                        <Button
                                          onClick={() => {
                                            scrollToDatapoint(
                                              groupedInsights[gi][0]
                                            );
                                            scrollToHighlightedDatapoint(
                                              groupedInsights[gi][0]
                                            );
                                          }}
                                          className="riverus-yellow-btn"
                                          style={{ fontSize: "13px" }}
                                        >
                                          View
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              } else
                                return (
                                  <React.Fragment key={i}></React.Fragment>
                                );
                            })}
                            {groupedInsights["No"] &&
                            groupedInsights["No"].length > 0 ? (
                              <tr key={i}>
                                <td>{Object.keys(groupedInsights).length}</td>
                                <td>No</td>

                                <td
                                  style={{ whiteSpace: "nowrap" }}
                                  className="right-align"
                                >
                                  {groupedInsights["No"].length > 1 ? (
                                    <UpDownButtonsRender
                                      dataPoints={groupedInsights["No"]}
                                    />
                                  ) : (
                                    <>
                                      {groupedInsights["No"][0] &&
                                        groupedInsights["No"][0].paraId > 0 && (
                                          <Button
                                            onClick={() => {
                                              scrollToDatapoint(
                                                groupedInsights["No"][0]
                                              );
                                              scrollToHighlightedDatapoint(
                                                groupedInsights["No"][0]
                                              );
                                            }}
                                            className="riverus-yellow-btn"
                                            style={{
                                              fontSize: "13px",
                                            }}
                                          >
                                            View
                                          </Button>
                                        )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={i}></React.Fragment>;
            }
          })}

          <RenderGroupedBiData
            groupedBiData={aggregatedGroupedBiData}
            scrollToDatapoint={scrollToDatapoint}
            UpDownButtonsRender={UpDownButtonsRender}
            scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
            getCustomChildLabelName={getCustomChildLabelName}
            getHighlightedTextFromDP={getHighlightedTextFromDP}
            getHighlightedPara={getHighlightedPara}
            getSmallSentence={getSmallSentence}
          />
        </Card.Body>
      )}
    </Card>
  );
};

const RenderGroupedBiData = (props: any) => {
  const { groupedBiData } = props;
  const {
    scrollToDatapoint,
    UpDownButtonsRender,
    scrollToHighlightedDatapoint,
    getCustomChildLabelName,
    getHighlightedTextFromDP,
    getHighlightedPara,
    getSmallSentence,
  } = props;
  let groupedBiDataObject: any = {
    Timeline: [],
    Amount: [],
    Regulatory: [],
    Authority: [],
  };
  for (let i = 0; i < groupedBiData.length; i++) {
    if (groupedBiData[i].childValue && groupedBiData[i].childValue.length > 0) {
      let childAlias: string = groupedBiData[i].childAlias;
      groupedBiDataObject[childAlias as keyof typeof groupedBiDataObject].push(
        groupedBiData[i]
      );
    }
  }
  const nameArray = ["Amount", "Timeline", "Regulatory", "Authority"];
  return (
    <>
      {nameArray.map((nameValue, i) => {
        return (
          <SingleGroupedBiDataRender
            key={i}
            clauseName={nameValue}
            clauseData={groupedBiDataObject[nameValue]}
            scrollToDatapoint={scrollToDatapoint}
            UpDownButtonsRender={UpDownButtonsRender}
            scrollToHighlightedDatapoint={scrollToHighlightedDatapoint}
            getCustomChildLabelName={getCustomChildLabelName}
            getHighlightedTextFromDP={getHighlightedTextFromDP}
            getHighlightedPara={getHighlightedPara}
            getSmallSentence={getSmallSentence}
          />
        );
      })}
    </>
  );
};
const SingleGroupedBiDataRender = (props: any) => {
  const { clauseName, clauseData } = props;
  const {
    scrollToDatapoint,
    UpDownButtonsRender,
    scrollToHighlightedDatapoint,
    getCustomChildLabelName,
    getHighlightedTextFromDP,
    getHighlightedPara,
    getSmallSentence,
  } = props;
  const groupedInsights: any = {};

  if (clauseData.length > 0) {
    clauseData.map((clause: any) => {
      let clauseChild = clause.childValue[0];
      const clauseName = clauseChild.name
        ? clauseChild.name === "Yes"
          ? getSmallSentence(getHighlightedPara(clauseChild.dataPoints))
          : clauseChild.name
        : getHighlightedTextFromDP(clauseChild.dataPoints);
      if (
        Array.isArray(
          groupedInsights[clauseName as keyof typeof groupedInsights]
        )
      ) {
        groupedInsights[clauseName].push(clauseChild.dataPoints);
      } else {
        groupedInsights[clauseName] = [];
        groupedInsights[clauseName].push(clauseChild.dataPoints);
      }
    });
    let idx = 1;
    return (
      <>
        <Row>
          <Col className="capitalize">
            <b>{getCustomChildLabelName(clauseName)}</b>
          </Col>
          {clauseData && clauseData.length > 1 && (
            <Col style={{ textAlign: "right" }}>
              <b> {clauseData.length}</b> Instance Found
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <Table striped hover responsive size="sm">
              <tbody>
                {Object.keys(groupedInsights).map((gi, i) => {
                  if (gi.toLowerCase() !== "no") {
                    return (
                      <tr key={i}>
                        <td>{idx++}</td>
                        <td>{gi}</td>
                        {/* <td className="debug">{groupedInsights[gi].length}</td> */}
                        <td
                          style={{ whiteSpace: "nowrap" }}
                          className="right-align"
                        >
                          {groupedInsights[gi].length > 1 ? (
                            <UpDownButtonsRender
                              dataPoints={groupedInsights[gi]}
                            />
                          ) : (
                            <Button
                              onClick={() => {
                                scrollToDatapoint(groupedInsights[gi][0]);
                                scrollToHighlightedDatapoint(
                                  groupedInsights[gi][0]
                                );
                              }}
                              className="riverus-yellow-btn"
                              style={{ fontSize: "13px" }}
                            >
                              View
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  } else return <React.Fragment key={i}></React.Fragment>;
                })}
                {groupedInsights["No"] && groupedInsights["No"].length > 0 ? (
                  <tr>
                    <td>{Object.keys(groupedInsights).length}</td>
                    <td>No</td>
                    {/* <td className="debug">{groupedInsights[gi].length}</td> */}
                    <td
                      style={{ whiteSpace: "nowrap" }}
                      className="right-align"
                    >
                      {groupedInsights["No"].length > 1 ? (
                        <UpDownButtonsRender
                          dataPoints={groupedInsights["No"]}
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            scrollToDatapoint(groupedInsights["No"][0]);
                            scrollToHighlightedDatapoint(
                              groupedInsights["No"][0]
                            );
                          }}
                          className="riverus-yellow-btn"
                          style={{ fontSize: "13px" }}
                        >
                          View
                        </Button>
                      )}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </>
    );
  } else return <></>;
};

export default KDPRender;
